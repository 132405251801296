<template>
    <v-content>
        <v-layout style="background-color: #FAF5F2;">
            <v-flex>
                <v-layout my-3>
                    <v-flex class="text-center" style="color: #3E7662;font-weight: bold;font-size: 28px">
                        <span>แบบฟอร์ม</span>
                    </v-flex>
                </v-layout>

                <v-layout style="margin-left: 100px;margin-right: 100px;">
                    <v-flex>
                        <v-layout mt-3 style="background-color: #3E7662;">
                            <v-flex my-8 ml-8 style="text-align: left;font-weight: bold;font-size: 28px;color: white">
                                <span>แบบรายงานการแก้ไขโครงร่างการวิจัย</span>
                            </v-flex>
                            <v-flex ml-8 pt-8 pr-8 style="text-align: right;font-weight: bold;font-size: 13px;color: white">
                                <span>RF 02_2560</span>
                            </v-flex>
                        </v-layout>

                        <v-card class="mb-12 px-12">
                            <!-- row 1 -->
                            <v-layout pt-8 px-12 justify-space-between>
                                <v-flex xs3 class="checkbox-edit">
                                    <v-checkbox color="#3E7662" v-model="checkEdit" label="ปรับปรุงแก้ไข เพื่อนำเข้าพิจารณาใหม่"></v-checkbox>
                                </v-flex>
                                <v-flex class="checkbox-edit" xs3 pt-4 pl-6>
                                    <v-layout>
                                        <v-flex xs3 mr-2 class="d-flex align-center text-end font-weight-bold">
                                            <span>ครั้งที่</span>
                                        </v-flex>
                                        <v-flex>
                                            <textfield-form
                                                    hide-detail
                                                    :model="reportForm.for_reconsider_no"
                                                    style="font-weight: bold"
                                                    @update-data="updateData('for_reconsider_no', $event)"
                                            ></textfield-form>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                                <v-flex class="checkbox-edit" xs3 pt-4 pl-6>
                                    <textfield-form
                                        :model="reportForm.for_reconsider_version"
                                        style="font-weight: bold"
                                        label="Version"
                                        @update-data="updateData('for_reconsider_version', $event)"
                                    ></textfield-form>
                                </v-flex>
                                <v-flex class="checkbox-edit" xs3 pt-4 pl-6>
                                    <date-input-form
                                            :model="dateChange1"
                                            style="font-weight: bold"
                                            label="Date"
                                            @update-data="updateDate('for_reconsider_date', $event)"
                                    ></date-input-form>
                                </v-flex>
                            </v-layout>

                            <!-- row 2 -->
                            <v-layout px-12 justify-space-between>
                                <v-flex xs3 class="checkbox-edit">
                                    <v-checkbox color="#3E7662" v-model="checkEdit" label="ปรับปรุงแก้ไขเพื่อรับรอง"></v-checkbox>
                                </v-flex>
                                <v-flex class="checkbox-edit" xs3 pt-4 pl-6>
                                    <v-layout>
                                        <v-flex xs3 mr-2 class="d-flex align-center text-end font-weight-bold">
                                            <span>ครั้งที่</span>
                                        </v-flex>
                                        <v-flex>
                                            <textfield-form
                                                    hide-detail
                                                    :model="reportForm.for_guarantee_no"
                                                    style="font-weight: bold"
                                                    @update-data="updateData('for_guarantee_no', $event)"
                                            ></textfield-form>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                                <v-flex class="checkbox-edit" xs3 pt-4 pl-6>
                                    <textfield-form
                                        :model="reportForm.for_guarantee_version"
                                        style="font-weight: bold"
                                        label="Version"
                                        @update-data="updateData('for_guarantee_version', $event)"
                                    ></textfield-form>
                                </v-flex>
                                <v-flex class="checkbox-edit" xs3 pt-4 pl-6>
                                    <date-input-form
                                            :model="dateChange2"
                                            style="font-weight: bold"
                                            label="Date"
                                            @update-data="updateDate('for_guarantee_date', $event)"
                                    ></date-input-form>
                                </v-flex>
                            </v-layout>

                            <!-- row 3 -->
                            <v-layout py-12>
                                <v-flex>
                                    <v-layout justify-center>
                                        <v-flex xs2 mr-4 pt-1 style="text-align: right;font-weight: bold;">
                                            <span>ชื่อโครงการวิจัย</span>
                                        </v-flex>
                                        <v-flex xs4>
                                            <textfield-form
                                                    :model="reportForm.name_research"
                                                    style="font-weight: bold"
                                                    @update-data="updateData('name_research', $event)"
                                            ></textfield-form>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout justify-center>
                                        <v-flex xs2 mr-4 pt-1 style="text-align: right;font-weight: bold;">
                                            <span>ผู้วิจัย</span>
                                        </v-flex>
                                        <v-flex xs4>
                                            <textfield-form
                                                    :model="reportForm.name_researcher"
                                                    style="font-weight: bold"
                                                    @update-data="updateDataRow3('name_researcher', $event)"
                                            ></textfield-form>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout justify-center>
                                        <v-flex xs2 mr-4 pt-1 style="text-align: right;font-weight: bold;">
                                            <span>เบอร์โทรศัพท์</span>
                                        </v-flex>
                                        <v-flex xs4>
                                            <textfield-form
                                                    :model="reportForm.tel"
                                                    style="font-weight: bold"
                                                    @update-data="updateDataRow3('tel', $event)"
                                            ></textfield-form>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout justify-center>
                                        <v-flex xs2 mr-4 pt-1 style="text-align: right;font-weight: bold;">
                                            <span>สังกัด</span>
                                        </v-flex>
                                        <v-flex xs4>
                                            <textfield-form
                                                    :model="reportForm.party"
                                                    style="font-weight: bold"
                                                    @update-data="updateDataRow3('party', $event)"
                                            ></textfield-form>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                            </v-layout>
                        </v-card>

                        <!-- Table โครงร่างการวิจัยเดิม -->
                        <v-layout pb-12>
                            <v-flex>
                                <form-table
                                        name-table="โครงร่างการวิจัยเดิม"
                                        :headers="researchAllHeaders"
                                        :items="itemResearchAll"
                                ></form-table>
                            </v-flex>
                        </v-layout>


                        <!-- Table โครงร่างการวิจัยเดิม -->
                        <v-layout pb-12>
                            <v-flex>
                                <form-table
                                        name-table="การแก้ไขเพิ่มเติม"
                                        :headers="researchAllHeaders"
                                        :items="itemResearchAll"
                                ></form-table>
                            </v-flex>
                        </v-layout>

                        <v-layout pt-12 pb-8 mb-12 style="background-color: white;padding-left: 100px;padding-right: 100px;">
                            <v-flex>
                                <v-layout>
                                    <v-flex shrink pt-1 d-flex justify-start>
                                        <span>ผู้วิจัย</span>
                                    </v-flex>
                                    <v-flex xs3 pl-3 d-flex justify-start class="font-weight-bold">
                                        <v-text-field
                                                dense
                                                hide-details
                                        ></v-text-field>
                                    </v-flex>
                                    <v-spacer></v-spacer>
                                    <v-flex xs3 d-flex justify-end>
                                        <date-input-form
                                                :model="dateChange3"
                                                style="font-weight: bold"
                                                label="Date"
                                                @update-data="updateDate('date_report', $event)"
                                        ></date-input-form>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                        </v-layout>

                        <v-layout>
                            <v-flex mb-12 style="text-align: right;">
                                <v-layout>
                                    <v-flex>
                                        <v-btn style="color: #3E7662" class="mx-4" depressed  color="#ffffff">ย้อนกลับ</v-btn>
                                        <v-btn depressed  dark color="#3E7662">
                                            <v-icon class="mr-3">add</v-icon>
                                            <span>บันทึกและถัดไป</span>
                                        </v-btn>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>


            </v-flex>
        </v-layout>

    </v-content>
</template>

<script>
    import TextfieldForm from "../../components/input/TextfieldForm";
    import DateInputForm from "../../components/input/DateInputForm";
    import FormTable from "../../components/table/FormTable";

    export default {
        name: "FormRF02",
        components: {
            TextfieldForm,
            DateInputForm,
            FormTable,
        },
        data: () => ({
            checkEdit: false,
            reportForm: {
                for_reconsider_no: '',
                for_reconsider_version: '',
                for_reconsider_date: '',
                for_guarantee_no: '',
                for_guarantee_version: '',
                for_guarantee_date: '',
                name_research: '',
                name_researcher: '',
                tel: '',
                party: '',
                date_report: '',
                name_researchers: '',
            },
            researchAllHeaders: [
                {text: 'Version', value: 'version', align: "center"},
                { text: 'หน้า / บรรทัด', value: 'page', align: "center" },
                { text: 'ข้อมูลเดิม / ข้อเสนอแนะ', value: 'data', align: "center" },
            ],
            itemResearchAll: [
                {
                    version: '01',
                    page: 'การสำรวจข้อมูลพื้นฐานประชากรและความชุกของปัจจัยเสี่ยงทางด้านสุขภาพ',
                    data: 'เสร็จสิ้น',
                },
                {
                    version: '02',
                    page: 'การศึกษาความชุกและปัจจัยเสี่ยงของโรคติดเชื้อปรสิตในลำไส้ในเด็กนักเรียน',
                    data: 'รอดำเนินการ',
                },
            ],
            dateChange1: '',
            dateChange2: '',
            dateChange3: '',
        }),
        methods:{
            updateData(name, val) {
                this.reportForm[name] = val;
            },
            updateDate(name,val) {
                if (name === "for_reconsider_date") {
                    this.reportForm.for_reconsider_date = val;
                    this.dateChange1 = this.changeDate(val);
                }
                if (name === "for_guarantee_date") {
                    this.reportForm.for_guarantee_date = val;
                    this.dateChange2 = this.changeDate(val);
                }
                if (name === "date_report") {
                    this.reportForm.date_report = val;
                    this.dateChange3 = this.changeDate(val);
                }
            },
            changeDate(val) {
                //change date start
                let sub_year = val.substring(0,4);
                let sub_month = val.substring(5,7);
                let sub_day = val.substring(8,10);
                sub_year = parseInt(sub_year)+543;
                let changeDate = sub_day + '/' + sub_month + '/' + sub_year;
                return changeDate;
            },
            updateDataRow3(name, val) {
                this.reportForm[name] = val;
            },
        },
    }
</script>

<style lang="scss">
    .checkbox-edit {

    }
</style>
