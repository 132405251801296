import Service from '../Service'
import AuthService from '../AuthService'

const Budget = {
    getBudgetResourceList(page) {
        return AuthService.get("/api/get-all-budget-resource?page="+page).then(res => {
            return res;
        });
    },
    getBudget(id) {
        return AuthService.get(`/api/get-budget-resource/${id}`).then(res => {
            return res;
        });
    },
    createBudget(data) {
        return AuthService.post("/api/create-budget-resource", data).then(res => {
            return res;
        });
    },
    deleteBudget(id) {
        return AuthService.delete(`/api/delete-budget-resource/${id}`,).then(res => {
            return res;
        });
    },
    updateBudget(data,id) {

        return AuthService.put(`/api/update-budget-resource/${id}`,data,{
        }).then(res => {
            return res;
        });
    },
}

export default Budget;
