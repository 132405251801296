import Service from "../Service";
import AuthService from "../AuthService";

const Auth = {
  login(data) {
    return Service.post("/api/login", data).then((res) => {
      return res;
    });
  },
  forceLogin(data) {
    return Service.post("/api/force-login", data).then((res) => {
      return res;
    });
  },
  resetPassword(data) {
    return Service.post("/api/reset-password", data).then((res) => {
      return res;
    });
  },
  register(data) {
    let formData = new FormData();
    for (let key in data) {
      formData.append(key, this.checkFormDataValue(data[key]))
    }
    formData.append("_method", "POST");
    return Service.post("/api/register", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((res) => res);
  },
  checkFormDataValue(data){
    return data === null ? '' : data === undefined ? '' : data === 'null' ? '' : data
  },
  update(data) {
    let formData = new FormData();
    for (let key in data) {
        formData.append(key, this.checkFormDataValue(data[key]))
    }
    formData.append("_method", "POST");
    return AuthService.post(`/api/update-user/${data.id_user}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((res) => res);
  },
  getUser() {
    return AuthService.get("/api/show-profile").then((res) => res);
  },
  changePassword(id, data) {
    return AuthService.post(`/api/change-password/${id}`, data).then(
      (res) => res
    );
  },
  changePasswordByEmail(data) {
    return Service.post(`/api/change-password-by-email`, data).then(
      (res) => res
    );
  },
};

export default Auth;
