<template>
  <v-content>
    <v-layout style="background-color: #FAF5F2;">
      <v-flex>
        <v-layout mb-3 mt-6 class="text-center">
          <v-flex style="color: #3E7662;font-weight: bold;font-size: 28px">
            <span>แบบฟอร์ม</span>
          </v-flex>
        </v-layout>

        <v-layout style="margin-left: 100px;margin-right: 100px;">
          <v-flex>
            <v-layout mt-3 style="background-color: #3E7662;">
              <v-flex my-8 ml-8 style="text-align: left;font-size: 28px;color: white">
                <div style="font-weight: bold">
                  แบบรายงานการไม่ปฏิบัติตามข้อกำหนด
                </div>
                <div style="font-size: 18px;">
                  Non-compliance / Protocol Deviation / Prootocol Protocol
                  Report
                </div>
              </v-flex>
              <v-flex ml-8 pt-8 pr-8 style="text-align: right;font-weight: bold;font-size: 13px;color: white">
                <span>RF 05_2563</span>
              </v-flex>
            </v-layout>
            <!-- blog 1 -->
            <v-card flat class="mb-12 px-12">
              <v-layout py-12 pr-8 mr-12 style="font-size: 16px;">
                <v-flex>
                  <!-- row 1 -->
                  <v-layout pt-8>
                    <v-flex xs2 mr-4 pt-2 style="text-align: right;font-weight: bold;">
                      <span>รหัสโครงร่างการวิจัย</span>
                    </v-flex>
                    <v-flex xs4>
                      <textfield-form :model="form.researchCode"
                        @update-data="updateData('researchCode', $event)"></textfield-form>
                    </v-flex>
                  </v-layout>
                  <!-- row 2 -->
                  <v-layout>
                    <v-flex xs2 mr-4 pt-2 style="text-align: right;font-weight: bold;">
                      <span>ชื่อผู้วิจัยหลัก</span>
                    </v-flex>
                    <v-flex xs4>
                      <textfield-form :model="form.firstName"
                        @update-data="updateData('firstName', $event)"></textfield-form>
                    </v-flex>
                    <v-flex xs2 pr-4 pt-2 style="text-align: right;font-weight: bold;">
                      <span>นามสกุล</span>
                    </v-flex>
                    <v-flex xs4>
                      <textfield-form :model="form.lastName"
                        @update-data="updateData('lastName', $event)"></textfield-form>
                    </v-flex>
                  </v-layout>
                  <!-- row 3 -->
                  <v-layout pb-6>
                    <v-flex xs2 mr-4 pt-2 style="text-align: right;font-weight: bold;">
                      <span>ชื่อโครงการวิจัย (ไทย)</span>
                    </v-flex>
                    <v-flex xs10>
                      <textfield-form :model="form.researchName"
                        @update-data="updateData('researchName', $event)"></textfield-form>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>

            <!--blog 2-->
            <v-card flat class="mb-12">
              <v-layout py-12 px-8>
                <v-flex>
                  <!-- row 1 -->
                  <v-layout pt-8>
                    <v-flex xs4>
                      <v-layout>
                        <v-flex xs4 mr-2 pt-2 style="text-align: right;font-weight: bold;">
                          <span>จำนวนรายงาน</span>
                        </v-flex>
                        <v-flex xs5>
                          <textfield-form :model="form.amountOfReport"
                            @update-data="updateData('amountOfReport', $event)"></textfield-form>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex>
                      <v-layout>
                        <v-flex class="align-center d-flex justify-end mr-2 ml-n6" style="font-weight: bold;">
                          <span>จำนวนอาสาสมัครที่มีการไม่ปฏิบัติตามข้อกำหนด</span>
                        </v-flex>
                        <v-flex xs6>
                          <textfield-form hide-detail :model="form.amountOfNotFollow" @update-data="
                            updateData('amountOfNotFollow', $event)
                            "></textfield-form>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>

                  <v-layout mb-6>
                    <v-spacer></v-spacer>
                    <v-flex shrink>
                      <v-btn dark style="width: 50px;background-color: #3E7662" @click="add">
                        <v-icon>add</v-icon>
                        <span class="font-weight-bold">เพิ่ม</span>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                  <!-- row 2 -->
                  <div class="mb-8" v-for="(input, k) in subjectData" :key="k">
                    <v-layout>
                      <v-flex xs4>
                        <v-layout>
                          <v-flex class="align-center d-flex justify-end mr-2" style="font-weight: bold;">
                            <span>Subject No.</span>
                          </v-flex>
                          <v-flex xs8>
                            <textfield-form hide-detail :model="input.subjectNumber" @update-data="
                              updateSubjectData('subjectNumber', k, $event)
                              "></textfield-form>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs8>
                        <v-layout justify-space-around>
                          <v-flex class="align-center d-flex justify-end mr-2" style="font-weight: bold;">
                            <span>Date of Events</span>
                          </v-flex>
                          <v-flex xs2>
                            <date-input-form hide-detail :model="dateChangeArray[k].dateOfEvents" @update-data="
                              updateDateArray('dateOfEvents', k, $event)
                              "></date-input-form>
                          </v-flex>
                          <v-flex class="align-center d-flex justify-end mr-2" style="font-weight: bold;">
                            <span>Date of Acknowledgement</span>
                          </v-flex>
                          <v-flex xs2>
                            <date-input-form hide-detail :model="dateChangeArray[k].dateOfAcknowledgement" @update-data="
                              updateDateArray(
                                'dateOfAcknowledgement',
                                k,
                                $event
                              )
                              "></date-input-form>
                          </v-flex>
                          <v-flex shrink class="ml-3">
                            <v-btn style="background-color: #fff; color: #CC3D45;width: 50px;" @click="remove(k)"
                              :disabled="subjectData.length <= 1">
                              <v-icon color="#CC3D45">remove</v-icon>
                              <span class="font-weight-bold">ลบ</span>
                            </v-btn>
                            <!--                                                    <v-btn-->
                            <!--                                                            dark-->
                            <!--                                                            style="width: 80px;background-color: #3E7662"-->
                            <!--                                                            @click="add(k)"-->
                            <!--                                                            v-if="k == subjectData.length-1"-->
                            <!--                                                    >-->
                            <!--                                                        <v-icon>add</v-icon>-->
                            <!--                                                        <span>เพิ่ม</span>-->
                            <!--                                                    </v-btn>-->
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </div>
                </v-flex>
              </v-layout>
            </v-card>

            <!--blog 3-->

            <v-layout mt-3 style="background-color: #3E7662;">
              <v-flex my-8 ml-8 style="text-align: center;font-size: 18px;color: white">
                <div style="font-weight: bold">Type of Report</div>
              </v-flex>
            </v-layout>
            <v-card flat class="mb-12 px-12 py-12">
              <!--group 1-->
              <v-layout px-12>
                <v-flex xs3 class="text-start">
                  <v-checkbox :readonly="$route.query.read == 1 ? true : false" v-model="checkbox1"
                    label="Protocol Deviation" color="#3E7662" hide-details></v-checkbox>
                </v-flex>
                <v-flex xs3 class="text-start">
                  <v-checkbox :readonly="$route.query.read == 1 ? true : false" v-model="checkbox2"
                    label="Protocol Violation" color="#3E7662" hide-details></v-checkbox>
                </v-flex>
                <v-spacer></v-spacer>
              </v-layout>
              <v-layout mt-2 style="padding-left: 100px">
                <v-flex>
                  <!-- row 1 -->
                  <v-layout>
                    <v-flex mt-n4 shrink :class="!form.hasRandom ? '' : 'color-label-checkbox'">
                      <v-checkbox :disabled="!checkbox1 && !checkbox2" v-model="form.hasRandom" hide-details
                        color="#3E7662"
                        label="สุ่มอาสาสมัครที่ไม่มีคุณสมบัติเหมาะสม (Randomization of ineligible subjects)"></v-checkbox>
                    </v-flex>
                    <v-flex xs1>
                      <textfield-form-append hide-detail="" :disabled="!form.hasRandom" :model="form.randomEventNumber"
                        @update-data="updateData('randomEventNumber', $event)" />
                    </v-flex>
                    <v-flex>
                      <span>เหตุการณ์</span>
                    </v-flex>
                  </v-layout>
                  <!-- row 2 -->
                  <v-layout mt-4>
                    <v-flex mt-n4 shrink :class="!form.hasEnrollment ? '' : 'color-label-checkbox'">
                      <v-checkbox :disabled="!checkbox1 && !checkbox2" v-model="form.hasEnrollment" hide-details
                        color="#3E7662"
                        label="รับอาสาสมัครที่เข้าข่ายคัดออกจากโครงการ (Enrollment of excluded subjects)"></v-checkbox>
                    </v-flex>
                    <v-flex xs1>
                      <textfield-form-append hide-detail="" :disabled="!form.hasEnrollment"
                        :model="form.enrollmentEventNumber" @update-data="
                          updateData('enrollmentEventNumber', $event)
                          " />
                    </v-flex>
                    <v-flex>
                      <span>เหตุการณ์</span>
                    </v-flex>
                  </v-layout>
                  <!-- row 3 -->
                  <v-layout mt-4>
                    <v-flex mt-n4 shrink :class="!form.hasNotScreening ? '' : 'color-label-checkbox'
                      ">
                      <v-checkbox :disabled="!checkbox1 && !checkbox2" v-model="form.hasNotScreening" hide-details
                        color="#3E7662"
                        label="ไม่ได้ทำตามขั้นตอนการคัดกรองที่ระบุ (Do not perform screening procedures required by protocol)"></v-checkbox>
                    </v-flex>
                    <v-flex xs1>
                      <textfield-form-append hide-detail="" :disabled="!form.hasNotScreening"
                        :model="form.notScreeningEventNumber" @update-data="
                          updateData('notScreeningEventNumber', $event)
                          " />
                    </v-flex>
                    <v-flex>
                      <span>เหตุการณ์</span>
                    </v-flex>
                  </v-layout>
                  <!-- row 4 -->
                  <v-layout mt-4>
                    <v-flex mt-n4 shrink :class="!form.hasScreening ? '' : 'color-label-checkbox'">
                      <v-checkbox :disabled="!checkbox1 && !checkbox2" hide-details color="#3E7662"
                        v-model="form.hasScreening"
                        label="ดำเนินการคัดกรองหรือขั้นตอนการวิจัยนอกระยะเวลาที่กำหนด (Perform screening or procedures outside the time required by protocal)"></v-checkbox>
                    </v-flex>
                    <v-flex xs1>
                      <textfield-form-append hide-detail="" :disabled="!form.hasScreening"
                        :model="form.screeningEventNumber" @update-data="
                          updateData('screeningEventNumber', $event)
                          " />
                    </v-flex>
                    <v-flex>
                      <span>เหตุการณ์</span>
                    </v-flex>
                  </v-layout>
                  <!--row 5-->
                  <v-layout mt-4>
                    <v-flex mt-n4 shrink :class="!form.hasFailureTherapy ? '' : 'color-label-checkbox'
                      ">
                      <v-checkbox :disabled="!checkbox1 && !checkbox2" hide-details color="#3E7662"
                        v-model="form.hasFailureTherapy"
                        label="ให้การรักษา หรือให้ยาไม่ตรงตามที่ระบุ (Failure to provide therapy or mendication by protocal)"></v-checkbox>
                    </v-flex>
                    <v-flex xs1>
                      <textfield-form-append hide-detail="" :disabled="!form.hasFailureTherapy"
                        :model="form.failureTherapyEventNumber" @update-data="
                          updateData('failureTherapyEventNumber', $event)
                          " />
                    </v-flex>
                    <v-flex>
                      <span>เหตุการณ์</span>
                    </v-flex>
                  </v-layout>
                  <!--row6-->
                  <v-layout mt-4>
                    <v-flex mt-n4 shrink :class="!form.hasFailureAccomplish ? '' : 'color-label-checkbox'
                      ">
                      <v-checkbox :disabled="!checkbox1 && !checkbox2" hide-details color="#3E7662"
                        v-model="form.hasFailureAccomplish"
                        label="ทำหัตถการวิจัยที่ระบุไว้ไม่ครบถ้วน (Failure to accomplish procedures required by protocal)"></v-checkbox>
                    </v-flex>
                    <v-flex xs1>
                      <textfield-form-append hide-detail="" :disabled="!form.hasFailureAccomplish"
                        :model="form.failureAccomplishEventNumber" @update-data="
                          updateData('failureAccomplishEventNumber', $event)
                          " />
                    </v-flex>
                    <v-flex>
                      <span>เหตุการณ์</span>
                    </v-flex>
                  </v-layout>
                  <!--row7-->
                  <v-layout mt-4>
                    <v-flex mt-n4 shrink :class="!form.hasFollowUp ? '' : 'color-label-checkbox'">
                      <v-checkbox :disabled="!checkbox1 && !checkbox2" hide-details color="#3E7662"
                        v-model="form.hasFollowUp"
                        label="นัดหมายไม่ตรงตามระบุ (Follow-up visit outside the time schedule required by protocal"></v-checkbox>
                    </v-flex>
                    <v-flex xs1>
                      <textfield-form-append hide-detail="" :disabled="!form.hasFollowUp"
                        :model="form.followUpEventNumber" @update-data="updateData('followUpEventNumber', $event)" />
                    </v-flex>
                    <v-flex>
                      <span>เหตุการณ์</span>
                    </v-flex>
                  </v-layout>
                  <!--row8-->
                  <v-layout>
                    <v-flex shrink :class="!form.hasOther1 ? '' : 'color-label-checkbox'">
                      <v-checkbox :disabled="!checkbox1 && !checkbox2" hide-details color="#3E7662"
                        v-model="form.hasOther1" label="อื่นๆ (Others) ระบุ"
                        @change="clearCheckbox('hasOther1')"></v-checkbox>
                    </v-flex>
                    <v-flex mt-2 ml-3 xs8>
                      <textfield-form-append :disabled="!form.hasOther1" :model="form.other1"
                        @update-data="updateData('other1', $event)" />
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>

              <!--group2-->

              <v-layout px-12 pt-12>
                <v-flex xs3 class="text-start">
                  <v-checkbox :readonly="$route.query.read == 1 ? true : false" v-model="checkbox3" label="Non-Compliance"
                    color="#3E7662" hide-details></v-checkbox>
                </v-flex>
              </v-layout>
              <v-layout mt-2 style="padding-left: 100px">
                <v-flex>
                  <!-- row 1 -->
                  <v-layout mt-4>
                    <v-flex mt-n4 shrink :class="!form.hasDelaySAEReport ? '' : 'color-label-checkbox'
                      ">
                      <v-checkbox :disabled="!checkbox3" v-model="form.hasDelaySAEReport" hide-details color="#3E7662"
                        label="ส่งรายงานเหตุการณ์ไม่พึงประสงค์ชนิดร้ายแรงล่าช้า (Delay sending SAE report)"></v-checkbox>
                    </v-flex>
                    <v-flex xs1>
                      <textfield-form-append hide-detail="" :disabled="!form.hasDelaySAEReport"
                        :model="form.delaySAEReportEventNumber" @update-data="
                          updateData('delaySAEReportEventNumber', $event)
                          " />
                    </v-flex>
                    <v-flex>
                      <span>เหตุการณ์</span>
                    </v-flex>
                  </v-layout>
                  <!-- row 2 -->
                  <v-layout mt-4>
                    <v-flex mt-n4 shrink :class="!form.hasDelayContinuingReport
                      ? ''
                      : 'color-label-checkbox'
                      ">
                      <v-checkbox :disabled="!checkbox3" v-model="form.hasDelayContinuingReport" hide-details
                        color="#3E7662"
                        label="ส่งรายงานความก้าวหน้าของการวิจัยล่าช้า (Delay sending continuing report)"></v-checkbox>
                    </v-flex>
                    <v-flex xs1>
                      <textfield-form-append hide-detail="" :disabled="!form.hasDelayContinuingReport"
                        :model="form.delayContinuingReportEventNumber" @update-data="
                          updateData('delayContinuingReportEventNumber', $event)
                          " />
                    </v-flex>
                    <v-flex>
                      <span>เหตุการณ์</span>
                    </v-flex>
                  </v-layout>
                  <!-- row 3 -->
                  <v-layout mt-4>
                    <v-flex mt-n4 shrink :class="!form.hasUseUnstampedInformed
                      ? ''
                      : 'color-label-checkbox'
                      ">
                      <v-checkbox :disabled="!checkbox3" v-model="form.hasUseUnstampedInformed" hide-details
                        color="#3E7662"
                        label="ใช้เอกสารชี้แจงข้อมูลแก่อาสาสมัครฉบับที่ไม่ประทับตรายางของคณะกรรมการกลางฯ (Use unstamped informed consent form)"></v-checkbox>
                    </v-flex>
                    <v-flex xs1>
                      <textfield-form-append hide-detail="" :disabled="!form.hasUseUnstampedInformed"
                        :model="form.useUnstampedInformedEventNumber" @update-data="
                          updateData('useUnstampedInformedEventNumber', $event)
                          " />
                    </v-flex>
                    <v-flex>
                      <span>เหตุการณ์</span>
                    </v-flex>
                  </v-layout>
                  <!-- row 3 -->
                  <v-layout mt-4>
                    <v-flex mt-n4 shrink :class="!form.hasAccountabilityDrug
                      ? ''
                      : 'color-label-checkbox'
                      ">
                      <v-checkbox :disabled="!checkbox3" hide-details color="#3E7662" v-model="form.hasAccountabilityDrug"
                        label="การบริหารจัดการผลิตภัณฑ์ยาที่ใช้ในการวิจัยที่เหมาะสม (Impropor drug accountability)"></v-checkbox>
                    </v-flex>
                    <v-flex xs1>
                      <textfield-form-append hide-detail="" :disabled="!form.hasAccountabilityDrug"
                        :model="form.accountabilityDrugEventNumber" @update-data="
                          updateData('accountabilityDrugEventNumber', $event)
                          " />
                    </v-flex>
                    <v-flex>
                      <span>เหตุการณ์</span>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex shrink :class="!form.hasOther2 ? '' : 'color-label-checkbox'">
                      <v-checkbox :disabled="!checkbox3" hide-details color="#3E7662" v-model="form.hasOther2"
                        label="อื่นๆ (Others) ระบุ" @change="clearCheckbox('hasOther2')"></v-checkbox>
                    </v-flex>
                    <v-flex mt-2 ml-3 xs8>
                      <textfield-form-append :disabled="!form.hasOther2" :model="form.other2"
                        @update-data="updateData('other2', $event)" />
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>

            <v-layout mt-3 style="background-color: #3E7662;">
              <v-flex my-8 ml-8 style="text-align: center;font-size: 18px;color: white">
                <div style="font-weight: bold">
                  รายละเอียดของการไม่ปฏิบัติตามระเบียบข้อกำหนด (Narrative
                  summary of the event
                </div>
              </v-flex>
            </v-layout>
            <!-- blog 4 -->
            <v-card flat class="mb-12 px-12">
              <v-layout py-12 style="font-size: 16px;">
                <v-flex>
                  <textarea-form :model="form.summaryOfTheEvent" :rows="10" background-color="#FBF6F3"
                    @update-data="updateData('summaryOfTheEvent', $event)"></textarea-form>
                </v-flex>
              </v-layout>
            </v-card>

            <v-layout mt-3 style="background-color: #3E7662;">
              <v-flex my-8 ml-8 style="text-align: center;font-size: 18px;color: white">
                <div style="font-weight: bold">การตอบสนอง (Action Taken)</div>
              </v-flex>
            </v-layout>
            <!-- blog 5 -->
            <v-card flat class="mb-12 px-12">
              <v-layout py-12 mt-2 style="padding-left: 70px">
                <v-flex>
                  <!-- row 1 -->
                  <v-layout>
                    <v-flex shrink :class="!form.hasSubjectWithdraw ? '' : 'color-label-checkbox'
                      ">
                      <v-checkbox v-model="form.hasSubjectWithdraw" hide-details color="#3E7662"
                        label="ถอนอาสาสมัครการวิจัย (Subject withdrawn)"></v-checkbox>
                    </v-flex>
                  </v-layout>
                  <!-- row 2 -->
                  <v-layout>
                    <v-flex :class="!form.hasSubjectWithdrawWithFollowUp
                      ? ''
                      : 'color-label-checkbox'
                      ">
                      <v-checkbox v-model="form.hasSubjectWithdrawWithFollowUp" hide-details color="#3E7662"
                        label="ถอนอาสาสมัครการวิจัย และติดตาม (Subject withdrawn with follow up visit required)"></v-checkbox>
                    </v-flex>
                  </v-layout>
                  <!--row 3-->
                  <v-layout>
                    <v-flex :class="!form.hasSubjectRemainButFollow
                      ? ''
                      : 'color-label-checkbox'
                      ">
                      <v-checkbox v-model="form.hasSubjectRemainButFollow" hide-details color="#3E7662"
                        label="อาสาสมัครยังคงอยู่ในการวิจัย แต่ติดตามสังเกตอาการอสสาสมัครอย่างใกล้ชิด (Subject remains on the but data analysis will be modified)"></v-checkbox>
                    </v-flex>
                  </v-layout>
                  <!-- row 3 -->
                  <v-layout>
                    <v-flex :class="!form.hasSubjectRemainButStudy
                      ? ''
                      : 'color-label-checkbox'
                      ">
                      <v-checkbox v-model="form.hasSubjectRemainButStudy" hide-details color="#3E7662"
                        label="อาสาสมัครยังคงอยู่ในการวิจัย แต่ปรับแก้ไขการวิเคราะห์ข้อมูลทางสถิติ (Subject remains on the study but data analysis will be modified)"></v-checkbox>
                    </v-flex>
                  </v-layout>
                  <!-- row 4 -->
                  <v-layout>
                    <v-flex shrink :class="!form.hasSponsorNotified ? '' : 'color-label-checkbox'
                      ">
                      <v-checkbox hide-details color="#3E7662" v-model="form.hasSponsorNotified"
                        label="แจ้งผู้สนับสนุนทุนวิจัยหรือผู้วิจัยหลัก Sponsor or Principle Investigator notified on date"></v-checkbox>
                    </v-flex>
                    <v-flex mt-2 class="text-start" ml-3 xs3>
                      <date-input-form-append :disabled="!form.hasSponsorNotified" :model="sponsorNotifiedOnDate"
                        style="color: #3E7662;" @update-data="
                          updateDate('sponsorNotifiedOnDate', $event)
                          "></date-input-form-append>
                    </v-flex>
                  </v-layout>

                  <v-layout>
                    <v-flex shrink :class="!form.hasOther3 ? '' : 'color-label-checkbox'">
                      <v-checkbox hide-details color="#3E7662" v-model="form.hasOther3" label="อื่นๆ (Others) ระบุ"
                        @change="clearCheckbox('hasOther3')"></v-checkbox>
                    </v-flex>
                    <v-flex mt-2 ml-3 xs8>
                      <textfield-form-append :disabled="!form.hasOther3" :model="form.other3"
                        @update-data="updateData('other3', $event)" />
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>

            <!--blog 6-->
            <v-card flat class="mb-12 px-12">
              <v-layout py-12 pl-12 pr-8 mr-12 style="font-size: 16px;">
                <v-flex>
                  <!-- row 1 -->
                  <v-layout>
                    <v-flex mr-4 pt-2 style="text-align: left;font-weight: bold;">
                      <span>การปฏิบัติตามข้อกำหนดมีผลเพิ่มความเสี่ยงหรือเกิดอันตรายต่ออาสาสมัคร
                        หรือไม่</span>
                    </v-flex>
                  </v-layout>
                  <!-- row 2 -->
                  <v-layout>
                    <v-flex mr-4 style="text-align: left;">
                      <span>Does it have or post a significant rick or substantive
                        harm to research subjects?</span>
                    </v-flex>
                  </v-layout>
                  <!-- row 3 -->
                  <v-layout pb-6>
                    <v-flex mr-4 pt-2 style="text-align: right;font-weight: bold;">
                      <v-radio-group v-model="form.hasIncreaseRisk" row>
                        <v-radio color="#3E7662" style="padding-right: 100px" label="มี" value="0"></v-radio>
                        <v-radio @click.native="clearRadio('hasScreening')" color="#3E7662" label="ไม่มี"
                          value="1"></v-radio>
                      </v-radio-group>
                    </v-flex>
                  </v-layout>
                  <!--row 4-->
                  <v-layout>
                    <v-flex pt-6 mr-4 style="text-align: left;font-weight: bold;">
                      <span>การปฏิบัติตามข้อกำหนดมีผลต่อความน่าเชื่อถือของงานวิจัย
                        หรือไม่</span>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex mr-4 pt-2 style="text-align: left;">
                      <span>Does it cause damage to the scientific integrity of
                        data collected? หรือไม่</span>
                    </v-flex>
                  </v-layout>
                  <!-- row 5 -->
                  <v-layout pb-6>
                    <v-flex mr-4 pt-2 style="text-align: right;font-weight: bold;">
                      <v-radio-group v-model="form.hasCauseDamage" row>
                        <v-radio color="#3E7662" style="padding-right: 100px" label="มี" value="0"></v-radio>
                        <v-radio @click.native="clearRadio('hasScreening')" color="#3E7662" label="ไม่มี"
                          value="1"></v-radio>
                      </v-radio-group>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>

            <v-layout mt-3 style="background-color: #3E7662;">
              <v-flex my-8 ml-8 style="text-align: center;font-size: 18px;color: white">
                <div style="font-weight: bold">
                  มีการดำเนินการหรือมาตรการที่จะป้องกันการเกิดเหตุการณ์ซ้ำหรือไม่?
                  <br />
                </div>
                <div>
                  Which corrective actions or plans to prevent the future
                  events?
                </div>
              </v-flex>
            </v-layout>
            <!-- blog 7 -->
            <v-card flat class="mb-12 px-12">
              <v-layout pt-12 mt-2 style="padding-left: 70px">
                <v-flex>
                  <!-- row 1 -->
                  <v-layout>
                    <v-flex :class="!form.hasRetraining ? '' : 'color-label-checkbox'">
                      <v-checkbox v-model="form.hasRetraining" hide-details color="#3E7662"
                        label="อบรบทีมวิจัยเกี่ยวกับวิธีดำเนินการวิจัยหรือหัตถการในโครงการวิจัย (retraining the research team about the research procedure on date "
                        @change="clearCheckbox('hasRetraining')"></v-checkbox>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs3 ml-3>
                      <!--<textfield-form-append-->
                      <!--hide-detail-->
                      <!--:disabled="!form.hasRetraining"-->
                      <!--:model="form.retraining"-->
                      <!--@update-data="updateData('retraining', $event)"-->
                      <!--/>-->
                      <date-input-form-append :disabled="!form.hasRetraining" :model="retraining" style="color: #3E7662;"
                        @update-data="updateDate('retraining', $event)"></date-input-form-append>
                    </v-flex>
                  </v-layout>
                  <!-- row 2 -->
                  <v-layout>
                    <v-flex shrink :class="!form.hasGCPTraining ? '' : 'color-label-checkbox'
                      ">
                      <v-checkbox v-model="form.hasGCPTraining" hide-details color="#3E7662"
                        label="อบรบการปฏิบัติการทางคลินิกที่ดี (ICH GCP training on"
                        @change="clearCheckbox('hasGCPTraining')"></v-checkbox>
                    </v-flex>
                    <v-flex xs3 mt-2 class="text-start" ml-3>
                      <!--<textfield-form-append-->
                      <!--hide-detail-->
                      <!--:disabled="!form.hasGCPTraining"-->
                      <!--:model="form.GCPTraining"-->
                      <!--@update-data="updateData('GCPTraining', $event)"-->
                      <!--/>-->
                      <date-input-form-append :disabled="!form.hasGCPTraining" :model="GCPTraining"
                        style="color: #3E7662;" @update-data="updateDate('GCPTraining', $event)"></date-input-form-append>
                    </v-flex>
                    <v-flex mt-5 shrink>
                      <span>)</span>
                    </v-flex>
                  </v-layout>
                  <!--row 3-->
                  <v-layout>
                    <v-flex shrink :class="!form.hasHireStaff ? '' : 'color-label-checkbox'">
                      <v-checkbox v-model="form.hasHireStaff" hide-details color="#3E7662"
                        label="เพิ่มเจ้าหน้าที่หรือผู้ร่วมวิจัย (Hire more staffs or co-researchers)"></v-checkbox>
                    </v-flex>
                  </v-layout>
                  <!-- row 3 -->
                  <v-layout>
                    <v-flex shrink :class="!form.hasIncreaseSecurity ? '' : 'color-label-checkbox'
                      ">
                      <v-checkbox v-model="form.hasIncreaseSecurity" hide-details color="#3E7662"
                        label="เพิ่มความปลอดภัยของข้อมูล (Increase sata security by)"
                        @change="clearCheckbox('hasIncreaseSecurity')"></v-checkbox>
                    </v-flex>
                    <v-flex mt-2 class="text-start" ml-3>
                      <textfield-form-append hide-detail :disabled="!form.hasIncreaseSecurity"
                        :model="form.increaseSecurity" @update-data="updateData('increaseSecurity', $event)" />
                    </v-flex>
                    <v-flex mt-5 shrink>
                      <span>)</span>
                    </v-flex>
                  </v-layout>
                  <!-- row 4 -->
                  <v-layout>
                    <v-flex shrink :class="!form.hasFixOldMachine ? '' : 'color-label-checkbox'
                      ">
                      <v-checkbox hide-details color="#3E7662" v-model="form.hasFixOldMachine"
                        label="ซ่อมหรือจัดหาเครื่องมือทดแทน (Fix or Replace the old machine)"></v-checkbox>
                    </v-flex>
                  </v-layout>

                  <v-layout>
                    <v-flex shrink :class="!form.hasOther4 ? '' : 'color-label-checkbox'">
                      <v-checkbox hide-details color="#3E7662" v-model="form.hasOther4" label="อื่นๆ (Others) ระบุ"
                        @change="clearCheckbox('hasOther4')"></v-checkbox>
                    </v-flex>
                    <v-flex mt-2 class="text-start" ml-3>
                      <textfield-form-append hide-detail :disabled="!form.hasOther4" :model="form.other4"
                        @update-data="updateData('other4', $event)" />
                    </v-flex>
                    <v-flex mt-5 shrink>
                      <span>)</span>
                    </v-flex>
                  </v-layout>

                  <v-layout style="padding-top: 5rem;">
                    <v-flex>
                      <v-layout class="mb-8">
                        <v-flex>
                          <v-layout>
                            <v-flex shrink pt-2 pr-4 style="text-align: left;">
                              <span style="font-weight: bold;font-size: 16px;">หนังสือของหน่วยงาน
                              </span>
                            </v-flex>
                            <v-flex>
                              <v-file-input accept=".pdf" prepend-icon="" ref="agencyBooks" id="agencyBook"
                                :model="form.agencyBook" outlined dense clearable background-color="#FBF6F3"
                                color="#3E7662" item-color="#3E7662" @change="onUpload" hide-details></v-file-input>
                              <div v-if="agencyBook" class="mt-1 text-start" style="font-size: 12px;color: #CC3D45;">
                                <span>ไฟล์ที่อัพโหลด: {{ agencyBook }}</span>
                              </div>
                            </v-flex>
                            <v-flex shrink pl-4 style="text-align: left">
                              <v-btn color="#3E7662" dark @click="onClickBtn">
                                <v-icon class="pr-3">cloud_download</v-icon>
                                <span>Upload</span>
                              </v-btn>
                            </v-flex>
                            <v-flex ml-4><span style="color: red">*ชื่อไฟล์ต้องเป็นภาษาอังกฤษเท่านั้น (a-z,0-9)
                                ห้ามมีตัวอักษรอื่น </span></v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                      <v-layout>
                        <v-flex xs6>
                          <v-layout>
                            <v-flex class="d-flex align-center mr-4 font-weight-bold"
                              shrink>หน่วยงานที่ออกหนังสือ</v-flex>
                            <v-flex>
                              <textfield-form hide-detail :model="form.instituteBook"
                                @update-data="updateData('instituteBook', $event)"></textfield-form>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                      <v-layout mt-6>
                        <v-flex xs6>
                          <v-layout>
                            <v-flex class="d-flex align-center mr-4 font-weight-bold" shrink>เลขหนังสือ</v-flex>
                            <v-flex>
                              <textfield-form hide-detail :model="form.bookNumber"
                                @update-data="updateData('bookNumber', $event)"></textfield-form>
                            </v-flex>
                          </v-layout>
                        </v-flex>

                        <v-flex xs6>
                          <v-layout>
                            <v-flex class="d-flex align-center mx-4 font-weight-bold" shrink>วันที่หนังสือ</v-flex>
                            <v-flex>
                              <DateInputForm id="orderDate" hide-detail :model="bookDate"
                                @update-data="updateDate('bookDate', $event)"></DateInputForm>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <v-layout style="margin-top: 30px">
                    <v-flex ml-8 my-5 style="text-align: left;">
                      <span style="font-weight: bold">Note</span>
                      <span style="color: red"> *รายการที่ต้องแก้ไข</span>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex style="padding-left: 32px; padding-right: 32px">
                      <textarea-form readonly :model="form.noteDetail" :rows="5"></textarea-form>
                    </v-flex>
                  </v-layout>

                  <v-layout px-12 mt-8>
                    <!-- row 1-->
                    <v-flex xs6 style="font-weight: bold;font-size: 18px" d-flex justify-start>
                      <span>เอกสารแนบเพิ่มเติม</span>
                    </v-flex>
                  </v-layout>
                  <v-layout px-12 my-4>
                    <v-flex shrink mr-4>
                      <v-btn dark style="width: 80px;background-color: #3E7662" @click="addDynamicFile('fileOther')">
                        <v-icon>add</v-icon>
                        <span class="font-weight-bold">เพิ่ม</span>
                      </v-btn>
                    </v-flex>
                    <v-flex>
                      <span style="color: #CC3D45;">*ให้ผู้วิจัยระบุชื่่อ file / เวอร์ชั่น / วันที่
                        ให้ตรงกับ บันทึกข้อความ และห้ามมีอักขระพิเศษ
                        (!@#$%^&*)</span>
                    </v-flex>
                  </v-layout>
                  <div v-for="(input, k) in form.fileOther" :key="'d' + k">
                    <v-layout>
                      <v-flex>
                        <v-layout>
                          <v-flex shrink mr-4 pt-2 style="text-align: right;font-weight: bold;">
                            <span>ชื่อไฟล์</span>
                          </v-flex>
                          <v-flex xs5>
                            <textfield-form hide-detail :model="input.title" @update-data="
                              updateDynamicFileData('fileOther', 'title', k, $event)"></textfield-form>
                          </v-flex>
                          <v-flex shrink pt-2 px-4 style="text-align: left;">
                            <span style="font-weight: bold;font-size: 16px;">ไฟล์</span>
                          </v-flex>
                          <v-flex>
                            <v-file-input accept=".pdf" prepend-icon="" :id="'fileProtocolModify' + k" :model="input.file"
                              outlined dense background-color="#FBF6F3" color="#3E7662" item-color="#3E7662" @change="
                                updateDynamicFileData('fileOther', 'file', k, $event)"></v-file-input>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex ml-4>
                        <v-btn dark style="background-color: #CC3D45; width: 80px"
                          @click="removeDynamicFile('fileOther', k)" :disabled="form.fileOther.length < 1">
                          <v-icon>remove</v-icon>
                          <span class="font-weight-bold">ลบ</span>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                    <v-flex v-if="input.filePath"><a :href="input.filePath" target="_blank"
                        style="color: #3e7662;text-align: center" class="link-hover">
                        <p>ไฟล์ที่อัพโหลด: {{ input.file }}</p>
                      </a>
                    </v-flex>
                  </div>

                  <v-layout pb-8 mb-8 style="background-color: white;padding-right: 70px;padding-top: 200px">
                    <v-flex>
                      <v-layout>
                        <v-flex xs6 pl-3 mr-6 class="text-field-center">
                          <textfield-form-append :model="form.signature" css="font-weight: bold;" label="ผู้วิจัย"
                            hide-detail @update-data="updateData('signature', $event)"></textfield-form-append>
                        </v-flex>
                        <v-flex class="justify-end d-flex align-center shrink mr-2 font-weight-bold">
                          <span>วันที่</span>
                        </v-flex>
                        <v-flex xs4>
                          <date-input-form hide-detail :model="dateChange1"
                            @update-data="updateDate('reportDate', $event)"></date-input-form>
                        </v-flex>
                      </v-layout>
                      <v-layout mt-3 px-12>
                        <v-flex xs6>
                          <v-layout justify-center>
                            <v-flex shrink style="font-weight: bold">
                              <span>(</span>
                            </v-flex>
                            <v-flex xs8 mx-2 mt-n3 class="text-field-center">
                              <textfield-form-append :model="form.signature"
                                @update-data="updateData('signature', $event)" />
                            </v-flex>
                            <v-flex shrink style="font-weight: bold">
                              <span>)</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>

                      <v-layout ml-n12 style="padding-top: 150px">
                        <v-flex style="font-weight: bold" d-flex justify-start>
                          <span>สำนักงานคณะอนุกรรมการพิจารณาโครงการวิจัย พบ.</span>
                        </v-flex>
                        <v-flex style="font-weight: bold" d-flex justify-end>
                          <span>หน้า 1 จาก 1 หน้า</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
            <!--save button-->
            <v-layout>
              <v-flex mb-12 style="text-align: right;">
                <v-layout>
                  <v-flex>
                    <v-btn style="color: #3E7662" class="mx-4" @click="saveDraft" depressed
                      color="#ffffff">บันทึกร่าง</v-btn>
                    <!--<v-btn style="color: #3E7662" class="mx-4" depressed  color="#ffffff" @click="$router.go(-1)">ย้อนกลับ</v-btn>-->
                    <v-btn depressed dark color="#3E7662" @click="save">
                      <v-icon class="mr-3">add</v-icon>
                      <span>บันทึกข้อมูล</span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <loading color="#527281" :active.sync="isLoading" :can-cancel="false" :is-full-pageAndLine="fullpageAndLine">
            </loading>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-content>
</template>

<script>
import TextfieldForm from "../../components/input/TextfieldForm";
import DateInputForm from "../../components/input/DateInputForm";
import TextareaForm from "../../components/input/TextareaForm";
import Swal from "sweetalert2";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import TextfieldFormAppend from "../../components/input/TextfieldFormAppend";
import DateInputFormAppend from "../../components/input/DateInputFormAppend";
import CheckboxForm from "../../components/input/CheckboxForm";
// import editAdd from "../../components/table/editAdd";

export default {
  name: "FormRF05",
  components: {
    // CheckboxForm,
    TextfieldForm,
    DateInputForm,
    TextareaForm,
    Loading,
    TextfieldFormAppend,
    DateInputFormAppend,
    // editAdd,
  },
  data: () => ({
    isOnCreated: true,
    isLoading: false,
    fullpageAndLine: true,
    selected: [],
    dialog: false,
    form: {
      noteDetail: "",
      fileOther: [],
      researchID: "",
      researchCode: "",
      firstName: "",
      lastName: "",
      researchName: "",
      amountOfReport: "",
      amountOfNotFollow: "",
      hasDeviation: false,
      hasProtocol: false,
      hasRandom: false,
      randomEventNumber: "",
      hasEnrollment: false,
      enrollmentEventNumber: "",
      hasNotScreening: false,
      notScreeningEventNumber: "",
      hasScreening: false,
      screeningEventNumber: "",
      hasFailureTherapy: false,
      failureTherapyEventNumber: "",
      hasFailureAccomplish: false,
      failureAccomplishEventNumber: "",
      hasFollowUp: false,
      followUpEventNumber: "",
      hasOther1: false,
      other1: "",
      hasNonCompliance: false,
      hasDelaySAEReport: false,
      delaySAEReportEventNumber: "",
      hasDelayContinuingReport: false,
      delayContinuingReportEventNumber: "",
      hasUseUnstampedInformed: false,
      useUnstampedInformedEventNumber: "",
      hasAccountabilityDrug: false,
      accountabilityDrugEventNumber: "",
      hasOther2: false,
      other2: "",
      summaryOfTheEvent: "",
      hasSubjectWithdraw: false,
      hasSubjectWithdrawWithFollowUp: false,
      hasSubjectRemainButFollow: false,
      hasSubjectRemainButStudy: false,
      hasSponsorNotified: false,
      sponsorNotifiedOnDate: "",
      hasOther3: false,
      other3: "",
      hasIncreaseRisk: false,
      hasCauseDamage: false,
      hasRetraining: false,
      retraining: "",
      hasGCPTraining: false,
      GCPTraining: "",
      hasHireStaff: false,
      hasIncreaseSecurity: false,
      increaseSecurity: "",
      hasFixOldMachine: false,
      hasOther4: false,
      other4: "",
      agencyBook: "",
      bookNumber: "",
      bookDate: "",
      signature: "",
      reportDate: "",
      instituteBook: "",
    },
    subjectData: [
      {
        subjectNumber: "",
        dateOfEvents: "",
        dateOfAcknowledgement: "",
      },
    ],
    dynamicDateChange: {
      fileOther: []
    },
    researchAllHeaders: [
      { text: "Version", value: "version", align: "center" },
      { text: "หน้า / บรรทัด", value: "pageAndLine", align: "center" },
      { text: "ข้อมูลเดิม / ข้อเสนอแนะ", value: "suggestion", align: "center" },
      { text: "ลบ", value: "delete", align: "center" },
    ],
    dateChange1: "",
    dateChangeArray: [
      {
        dateOfEvents: "",
        dateOfAcknowledgement: "",
      },
    ],
    agencyBook: "",
    bookDate: "",
    sponsorNotifiedOnDate: "",
    retraining: "",
    GCPTraining: "",

    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
  }),
  // watch: {
  //   checkbox1: function (val) {
  //     if (val && !this.isOnCreated) {
  //       this.checkbox2 = false;
  //       this.checkbox3 = false;
  //       this.form.hasDeviation = true;
  //       this.form.hasProtocol = false;
  //       this.form.hasNonCompliance = false;
  //       this.form.hasRandom = false;
  //       this.form.randomEventNumber = "";
  //       this.form.hasEnrollment = false;
  //       this.form.enrollmentEventNumber = "";
  //       this.form.hasNotScreening = false;
  //       this.form.notScreeningEventNumber = "";
  //       this.form.hasScreening = false;
  //       this.form.screeningEventNumber = "";
  //       this.form.hasFailureTherapy = false;
  //       this.form.failureTherapyEventNumber = "";
  //       this.form.hasFailureAccomplish = false;
  //       this.form.failureAccomplishEventNumber = "";
  //       this.form.hasFollowUp = false;
  //       this.form.followUpEventNumber = "";
  //       this.form.hasOther1 = false;
  //       this.form.other1 = "";
  //     } else {
  //       this.form.hasRandom = false;
  //       this.form.randomEventNumber = "";
  //       this.form.hasEnrollment = false;
  //       this.form.enrollmentEventNumber = "";
  //       this.form.hasNotScreening = false;
  //       this.form.notScreeningEventNumber = "";
  //       this.form.hasScreening = false;
  //       this.form.screeningEventNumber = "";
  //       this.form.hasFailureTherapy = false;
  //       this.form.failureTherapyEventNumber = "";
  //       this.form.hasFailureAccomplish = false;
  //       this.form.failureAccomplishEventNumber = "";
  //       this.form.hasFollowUp = false;
  //       this.form.followUpEventNumber = "";
  //       this.form.hasOther1 = false;
  //       this.form.other1 = "";
  //     }
  //   },
  //   checkbox2: function (val) {
  //     if (val && !this.isOnCreated) {
  //       this.checkbox1 = false;
  //       this.checkbox3 = false;
  //       this.form.hasProtocol = true;
  //       this.form.hasNonCompliance = false;
  //       this.form.hasDeviation = false;
  //       this.form.hasRandom = false;
  //       this.form.randomEventNumber = "";
  //       this.form.hasEnrollment = false;
  //       this.form.enrollmentEventNumber = "";
  //       this.form.hasNotScreening = false;
  //       this.form.notScreeningEventNumber = "";
  //       this.form.hasScreening = false;
  //       this.form.screeningEventNumber = "";
  //       this.form.hasFailureTherapy = false;
  //       this.form.failureTherapyEventNumber = "";
  //       this.form.hasFailureAccomplish = false;
  //       this.form.failureAccomplishEventNumber = "";
  //       this.form.hasFollowUp = false;
  //       this.form.followUpEventNumber = "";
  //       this.form.hasOther1 = false;
  //       this.form.other1 = "";

  //       this.form.hasNonCompliance = false;
  //       this.form.hasDelaySAEReport = false;
  //       this.form.delaySAEReportEventNumber = "";
  //       this.form.hasDelayContinuingReport = false;
  //       this.form.delayContinuingReportEventNumber = "";
  //       this.form.hasUseUnstampedInformed = false;
  //       this.form.useUnstampedInformedEventNumber = "";
  //       this.form.hasAccountabilityDrug = false;
  //       this.form.accountabilityDrugEventNumber = "";
  //       this.form.hasOther2 = false;
  //       this.form.other2 = "";
  //     }
  //   },
  //   checkbox3: function (val) {
  //     if (val && !this.isOnCreated) {
  //       this.checkbox1 = false;
  //       this.checkbox2 = false;

  //       this.form.hasDeviation = false;
  //       this.form.hasProtocol = false;
  //       this.form.hasNonCompliance = true;
  //       this.form.hasDelaySAEReport = false;
  //       this.form.delaySAEReportEventNumber = "";
  //       this.form.hasDelayContinuingReport = false;
  //       this.form.delayContinuingReportEventNumber = "";
  //       this.form.hasUseUnstampedInformed = false;
  //       this.form.useUnstampedInformedEventNumber = "";
  //       this.form.hasAccountabilityDrug = false;
  //       this.form.accountabilityDrugEventNumber = "";
  //       this.form.hasOther2 = false;
  //       this.form.other2 = "";
  //     } else {
  //       this.form.hasDelaySAEReport = false;
  //       this.form.delaySAEReportEventNumber = "";
  //       this.form.hasDelayContinuingReport = false;
  //       this.form.delayContinuingReportEventNumber = "";
  //       this.form.hasUseUnstampedInformed = false;
  //       this.form.useUnstampedInformedEventNumber = "";
  //       this.form.hasAccountabilityDrug = false;
  //       this.form.accountabilityDrugEventNumber = "";
  //       this.form.hasOther2 = false;
  //       this.form.other2 = "";
  //     }
  //   },
  // },
  async created() {
    this.form.researchCode = this.$route.query.researchCode;
    this.form.researchName = this.$route.query.nameResearch;
    this.form.signature = this.$route.query.thName;
    await this.updateDate("reportDate", new Date().toISOString().slice(0, 10));
    if (this.$route.query.thName) {
      let text = this.$route.query.thName.split(" ");
      this.form.firstName = text[0];
      this.form.lastName = text[1];
    }
    if (this.$route.query.rfID) {
      await this.getRF05(this.$route.query.rfID);
      this.isOnCreated = false;
    }
    // }
  },
  methods: {
    addDynamicFile(name) {
      this.form[name].push({ title: "", file: null });
    },
    updateDynamicFileData(name, type, index, value) {
      if (type === "file") {
        this.isLoading = true;
        this.$store
          .dispatch("Common/uploadPdf", { form: "rf-05other-file", file: value })
          .then((res) => {
            this.form[name][index].file = res.fileName;
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
          });
      } else {
        this.form[name][index][type] = value;
      }
    },
    removeDynamicFile(name, index) {
      this.form[name].splice(index, 1);
      this.$forceUpdate();
    },
    updateData(name, val) {
      this.form[name] = val;
    },
    updateSubjectData(name, i, val) {
      if (name === "subjectNumber") {
        this.subjectData[i].subjectNumber = val;
      }
      if (name === "dateOfEvents") {
        this.subjectData[i].dateOfEvents = val;
      }
      if (name === "dateOfAcknowledgement") {
        this.subjectData[i].dateOfAcknowledgement = val;
      }
    },
    updateDate(name, val) {
      if (name === "reportDate") {
        this.form.reportDate = val;
        this.dateChange1 = this.changeDate(val);
      }
      if (name === "bookDate") {
        this.form.bookDate = val;
        this.bookDate = this.changeDate(val);
      }
      if (name === "sponsorNotifiedOnDate") {
        this.form.sponsorNotifiedOnDate = val;
        this.sponsorNotifiedOnDate = this.changeDate(val);
      }
      if (name === "retraining") {
        this.form.retraining = val;
        this.retraining = this.changeDate(val);
      }
      if (name === "GCPTraining") {
        this.form.GCPTraining = val;
        this.GCPTraining = this.changeDate(val);
      }
    },
    updateDateArray(name, index, val) {
      if (name === "dateOfAcknowledgement") {
        this.subjectData[index].dateOfAcknowledgement = val;
        this.dateChangeArray[index].dateOfAcknowledgement = this.changeDate(
          val
        );
      }
      if (name === "dateOfEvents") {
        this.subjectData[index].dateOfEvents = val;
        this.dateChangeArray[index].dateOfEvents = this.changeDate(val);
      }
    },
    changeDate(val) {
      if (val != null) {
        //change date start
        let sub_year = val.substring(0, 4);
        let sub_month = val.substring(5, 7);
        let sub_day = val.substring(8, 10);
        sub_year = parseInt(sub_year) + 543;
        let changeDate = sub_day + "/" + sub_month + "/" + sub_year;
        return changeDate;
      } else {
        return null;
      }
    },
    async getRF05(id) {
      this.isLoading = true;
      await this.$store.dispatch("FormRF/getRF05", id)
        .then((res) => {
          if (res.status !== "อนุมัติ") {
            this.form.instituteBook = res.instituteBook;
            this.form.fileOther = res.fileOther ?? [];
            this.form.noteDetail = res.noteDetail;
            this.form.amountOfReport = res.amountOfReport;
            this.form.amountOfNotFollow = res.amountOfNotFollow;
            this.form.hasDeviation = res.hasDeviation;
            this.checkbox1 = res.hasDeviation == 1;
            this.form.hasProtocol = res.hasProtocol;
            this.checkbox2 = res.hasProtocol == 1;
            this.form.hasOther1 = res.hasOther1 === 1;
            this.form.other1 = res.other1;
            this.form.hasNonCompliance = res.hasNonCompliance;
            this.checkbox3 = res.hasNonCompliance == 1;
            this.form.other2 = res.other2;
            this.form.randomEventNumber = res.randomEventNumber;
            this.form.enrollmentEventNumber = res.enrollmentEventNumber;
            this.form.notScreeningEventNumber = res.notScreeningEventNumber;
            this.form.screeningEventNumber = res.screeningEventNumber;
            this.form.failureTherapyEventNumber = res.failureTherapyEventNumber;
            this.form.failureAccomplishEventNumber = res.failureAccomplishEventNumber;
            this.form.followUpEventNumber = res.followUpEventNumber;
            this.form.delaySAEReportEventNumber = res.delaySAEReportEventNumber;
            this.form.delayContinuingReportEventNumber = res.delayContinuingReportEventNumber;
            this.form.useUnstampedInformedEventNumber = res.useUnstampedInformedEventNumber;
            this.form.accountabilityDrugEventNumber = res.accountabilityDrugEventNumber;
            this.form.summaryOfTheEvent = res.summaryOfTheEvent;
            this.form.sponsorNotifiedOnDate = res.sponsorNotifiedOnDate;
            if (res.sponsorNotifiedOnDate) this.updateDate("sponsorNotifiedOnDate", res.sponsorNotifiedOnDate);
            this.form.hasOther3 = res.hasOther3 == 1;
            this.form.other3 = res.other3;
            this.form.hasIncreaseRisk = res.hasIncreaseRisk;
            this.form.hasCauseDamage = res.hasCauseDamage;
            this.form.hasRetraining = res.hasRetraining == 1;
            this.form.retraining = res.retraining;
            if (res.retraining) this.updateDate("retraining", res.retraining);
            this.form.hasGCPTraining = res.hasGCPTraining == 1;
            this.form.GCPTraining = res.GCPTraining;
            if (res.GCPTraining) this.updateDate("GCPTraining", res.GCPTraining);
            this.form.hasHireStaff = res.hasHireStaff == 1;
            this.form.hasIncreaseSecurity = res.hasIncreaseSecurity == 1;
            this.form.increaseSecurity = res.increaseSecurity;
            this.form.hasFixOldMachine = res.hasFixOldMachine == 1;
            this.form.hasOther4 = res.hasOther4 == 1;
            this.form.other4 = res.other4;
            this.form.agencyBook = res.agencyBook;
            this.agencyBook = res.agencyBook;
            this.form.bookNumber = res.bookNumber;
            if (res.bookDate) this.updateDate("bookDate", res.bookDate);
            this.form.signature = res.signature;
            if (res.reportDate) this.updateDate("reportDate", res.reportDate);
            this.subjectData = res.deviation_report_subject_data;
            res.deviation_report_subject_data.forEach((data, i) => {
              this.dateChangeArray.push({
                dateOfEvents: "",
                dateOfAcknowledgement: "",
              });
              this.updateDateArray("dateOfEvents", i, data.dateOfEvents);
              this.updateDateArray(
                "dateOfAcknowledgement",
                i,
                data.dateOfAcknowledgement
              );
            });

            this.form.hasSubjectWithdraw = res.hasSubjectWithdraw == 1;
            this.form.hasSubjectWithdrawWithFollowUp = res.hasSubjectWithdrawWithFollowUp == 1;
            this.form.hasSubjectRemainButFollow = res.hasSubjectRemainButFollow == 1;
            this.form.hasSubjectRemainButStudy = res.hasSubjectRemainButStudy == 1;
            this.form.hasSponsorNotified = res.hasSponsorNotified == 1;
            this.form.hasDelaySAEReport = res.hasDelaySAEReport == 1;
            this.form.hasDelayContinuingReport = res.hasDelayContinuingReport == 1;
            this.form.hasUseUnstampedInformed = res.hasUseUnstampedInformed == 1;
            this.form.hasAccountabilityDrug = res.hasAccountabilityDrug == 1;
            this.form.hasOther2 = res.hasOther2 == 1;
            this.form.hasRandom = res.hasRandom == 1;
            this.form.hasEnrollment = res.hasEnrollment == 1;
            this.form.hasNotScreening = res.hasNotScreening == 1;
            this.form.hasScreening = res.hasScreening == 1;
            this.form.hasFailureTherapy = res.hasFailureTherapy == 1;
            this.form.hasFailureAccomplish = res.hasFailureAccomplish == 1;
            this.form.hasFollowUp = res.hasFollowUp == 1;
          }
          this.isLoading = false;
        })
        .catch((e) => {
          this.isLoading = false;
        });
    },
    save() {
      Swal.fire({
        title: "คุณต้องการบันทึกหรือไม่?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3E7662",
        cancelButtonColor: "rgb(189, 189, 189)",
        confirmButtonText: "บันทึก",
        cancelButtonText: "ยกเลิก",
      })
        .then((result) => {
          if (result.value) {
            this.form["hasDeviation"] = this.checkbox1;
            this.form["hasProtocol"] = this.checkbox2;
            this.form["hasNonCompliance"] = this.checkbox3;
            this.form["subjectData"] = this.subjectData;
            this.form["researchID"] = this.$route.query.researchID;
            if (this.$route.query.rfID) {
              this.form["methods"] = "edit";
              this.form["rfID"] = this.$route.query.rfID;
            } else {
              this.form["methods"] = "save";
            }
            this.isLoading = true;
            this.$store
              .dispatch("FormRF/saveRF05", this.form)
              .then((res) => {
                this.isLoading = false;
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "บันทึกสำเร็จ",
                  showConfirmButton: false,
                  timer: 1500,
                }).then(() => {
                  this.$router.push("/profile");
                });
              })
              .catch((e) => {
                this.isLoading = false;
                if (e.response.data.errors) {
                  for (var k in e.response.data.errors) {
                    Swal.fire({
                      title: "เกิดข้อผิดพลาด",
                      text: e.response.data.errors[k][0],
                      icon: "warning",
                      confirmButtonColor: "#3E7662",
                    }).then(() => {
                      this.$vuetify.goTo(`#${k}`);
                    });
                  }
                }
                // Swal.fire({
                //     title: 'Save failed!',
                //     text: 'บันทึกไม่สำเร็จ',
                //     icon: 'warning',
                // }).then(() => {
                //     this.isLoading = false;
                // })
              });
          }
          this.isLoading = false;
        })
        .catch((e) => {
          this.isLoading = false;
        });
    },
    saveDraft() {
      Swal.fire({
        title: "คุณต้องการบันทึกแบบร่างหรือไม่?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3E7662",
        cancelButtonColor: "rgb(189, 189, 189)",
        confirmButtonText: "บันทึก",
        cancelButtonText: "ยกเลิก",
      })
        .then((result) => {
          if (result.value) {
            this.form["subjectData"] = this.subjectData;
            this.form["researchID"] = this.$route.query.researchID;
            if (this.$route.query.rfID) {
              this.form["methods"] = "edit";
              this.form["rfID"] = this.$route.query.rfID;
            } else {
              this.form["methods"] = "save";
              this.form["status"] = "บันทึกแบบร่าง";
            }
            this.isLoading = true;
            this.$store
              .dispatch("FormRF/saveRF05", this.form)
              .then((res) => {
                this.isLoading = false;
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "บันทึกแบบร่างสำเร็จ",
                  showConfirmButton: false,
                  timer: 1500,
                }).then(() => {
                  this.$router.push("/profile");
                });
              })
              .catch((e) => {
                this.isLoading = false;
                if (e.response.data.errors) {
                  for (var k in e.response.data.errors) {
                    Swal.fire({
                      title: "เกิดข้อผิดพลาด",
                      text: e.response.data.errors[k][0],
                      icon: "warning",
                      confirmButtonColor: "#3E7662",
                    }).then(() => {
                      this.$vuetify.goTo(`#${k}`);
                    });
                  }
                }
                // Swal.fire({
                //     title: 'Save failed!',
                //     text: 'บันทึกไม่สำเร็จ',
                //     icon: 'warning',
                // }).then(() => {
                //     this.isLoading = false;
                // })
              });
          }
          this.isLoading = false;
        })
        .catch((e) => {
          this.isLoading = false;
        });
    },
    otherChangeCheckbox() {
      if (!this.form.hasOtherChange) {
        this.form.otherChange = "";
      }
    },
    clearCheckbox(name) {
      if (name === "hasDeviation") {
        if (!this.form.hasDeviation) {
          this.form.hasRandom = false;
          this.form.hasEnrollment = false;
          this.form.hasNotScreening = false;
          this.form.hasScreening = false;
          this.form.hasFailureTherapy = false;
          this.form.hasFailureAccomplish = false;
          this.form.hasFollowUp = false;
          this.form.hasOther1 = false;
          this.form.other1 = "";
        }
      }
      if (name === "hasOther1") {
        if (!this.form.hasOther1) {
          this.form.other1 = "";
        }
      }
      if (name === "hasNonCompliance") {
        if (!this.form.hasNonCompliance) {
          this.form.hasDelaySAEReport = false;
          this.form.hasDelayContinuingReport = false;
          this.form.hasUseUnstampedInformed = false;
          this.form.hasAccountabilityDrug = false;
          this.form.hasOther2 = false;
          this.form.other2 = "";
        }
      }
      if (name === "hasOther2") {
        if (!this.form.hasOther2) {
          this.form.other2 = "";
        }
      }
      if (name === "hasOther3") {
        if (!this.form.hasOther3) {
          this.form.other3 = "";
        }
      }
      if (name === "hasOther4") {
        if (!this.form.hasOther4) {
          this.form.other4 = "";
        }
      }
      if (name === "hasRetraining") {
        if (!this.form.hasRetraining) {
          this.form.retraining = "";
        }
      }
      if (name === "hasGCPTraining") {
        if (!this.form.hasGCPTraining) {
          this.form.GCPTraining = "";
        }
      }
      if (name === "hasIncreaseSecurity") {
        if (!this.form.hasIncreaseSecurity) {
          this.form.increaseSecurity = "";
        }
      }
    },
    clearRadio(name) {
      if (name === "hasAddOperate") {
        this.form.addOperate = "";
        this.form.hasAddOperate = "1";
      }
      if (name === "hasExpandTime") {
        this.form.expandTime = "";
        this.form.hasExpandTime = "1";
      }
      if (name === "hasAdjustOtherMethod") {
        this.form.adjustOtherMethod = "";
        this.form.hasAdjustOtherMethod = "1";
      }
      if (name === "hasAdjustMethod") {
        this.form.adjustMethod = "";
        this.form.hasAdjustMethod = "1";
      }
      if (name === "hasScreening") {
        this.form.screening = "";
        this.form.hasScreening = "1";
      }
    },
    add(index) {
      this.subjectData.push({
        subjectNumber: "",
        dateOfEvents: "",
        dateOfAcknowledgement: "",
      });
      this.dateChangeArray.push({
        dateOfAcknowledgement: "",
        dateOfEvents: "",
      });
    },
    remove(index) {
      this.subjectData.splice(index, 1);
    },
    onUpload(e) {
      this.$store
        .dispatch("Common/uploadPdf", { form: "rf05", file: e })
        .then((res) => {
          this.form.agencyBook = res.fileName;
        })
        .catch((error) => { });
    },
    onClickBtn() {
      document.getElementById("agencyBook").click();
    },
  },
};
</script>

<style lang="scss">
label.v-label.theme--light {
  color: #544a44;
}

.color-label-checkbox {
  label.v-label.theme--light {
    color: #3e7662;
  }
}

.v-text-field.v-input--dense:not(.v-text-field--outlined) input {
  text-align: center;
  padding: 4px 0 2px;
}
</style>
