<template>
  <v-content>
    <v-layout style="background-color: #FAF5F2;">
      <v-flex>
        <v-layout mb-3 mt-6>
          <v-flex style="color: #3E7662;font-weight: bold;font-size: 28px" class="text-center">
            <span>แบบฟอร์ม</span>
          </v-flex>
        </v-layout>

        <v-layout style="margin-left: 100px;margin-right: 100px;">
          <v-flex>
            <v-layout mt-3 style="background-color: #3E7662;">
              <v-flex my-8 ml-8 style="text-align: left;font-size: 28px;color: white">
                <div style="font-weight: bold">
                  แบบรายงานเหตุการณ์ไม่พึงประสงค์ (SAE)
                </div>
                <div style="font-size: 18px;">Adverse Event Report</div>
              </v-flex>
              <v-flex ml-8 pt-8 pr-8 style="text-align: right;font-weight: bold;font-size: 13px;color: white">
                <span>RF 04_2_2563</span>
              </v-flex>
            </v-layout>

            <!-- blog 1 -->
            <v-card flat class="py-12" style="padding-right: 80px;padding-left: 80px">
              <v-layout>
                <v-flex>
                  <!-- row 1 -->
                  <v-layout pb-3>
                    <v-flex style="text-align: left;font-weight: bold;">
                      <span>1. รหัสโครงการ</span>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex>
                      <textfield-form :model="form.researchCode"
                        @update-data="updateData('researchCode', $event)"></textfield-form>
                    </v-flex>
                  </v-layout>

                  <!-- row 2 -->
                  <v-layout pb-3>
                    <v-flex style="text-align: left;font-weight: bold;">
                      <span>2. ชื่อโครงการวิจัย</span>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex>
                      <textfield-form :model="form.researchName"
                        @update-data="updateData('researchName', $event)"></textfield-form>
                    </v-flex>
                  </v-layout>

                  <!-- row 3 -->
                  <v-layout pb-3>
                    <v-flex style="text-align: left;font-weight: bold;">
                      <span>3. ชื่อผู้วิจัยหลัก</span>
                    </v-flex>
                  </v-layout>
                  <v-layout px-4>
                    <v-flex style="color: #3E7662">
                      <textfield-form label="ชื่อ" :model="form.firstName"
                        @update-data="updateData('firstName', $event)"></textfield-form>
                    </v-flex>
                    <v-flex ml-3 style="color: #3E7662">
                      <textfield-form label="นามสกุล" :model="form.lastName"
                        @update-data="updateData('lastName', $event)"></textfield-form>
                    </v-flex>
                  </v-layout>

                  <!-- row 4 -->
                  <v-layout pb-3>
                    <v-flex style="text-align: left;font-weight: bold;">
                      <span>4. สังกัด / เบอร์โทรศัพท์ติดต่อ</span>
                    </v-flex>
                  </v-layout>
                  <v-layout pb-3>
                    <v-flex>
                      <textfield-form :model="form.affiliationAndPhoneNumber" @update-data="
                        updateData('affiliationAndPhoneNumber', $event)
                        "></textfield-form>
                    </v-flex>
                  </v-layout>

                  <!-- row 5 -->
                  <v-layout pb-3 justify-space-between>
                    <v-flex mr-6 shrink d-flex align-center style="text-align: left;font-weight: bold;">
                      <span>5. อาสาสมัคร</span>
                    </v-flex>
                    <v-flex shrink style="text-align: left;font-weight: bold;margin-top: -12px">
                      <v-checkbox color="#3E7662" v-model="form.volunteer_checkbox" @change="volunteerChangeCheckbox()"
                        hide-details />
                    </v-flex>
                    <v-flex mr-6 shrink d-flex align-center style="text-align: left;font-weight: bold;color: #CC3D45">
                      <span>ในสถาบัน *ระบุหน่วย</span>
                    </v-flex>
                    <v-flex d-flex align-center>
                      <textfield-form :background-color="!form.volunteer_checkbox ? 'gray' : '#FBF6F3'
                        " :disabled="!form.volunteer_checkbox" hide-detail :model="form.volunteerDepartment"
                        @update-data="updateData('volunteerDepartment', $event)"></textfield-form>
                    </v-flex>
                  </v-layout>

                  <!-- row 6 -->
                  <v-layout pt-6 justify-space-between>
                    <v-flex mr-10 shrink d-flex align-center style="text-align: left;font-weight: bold;">
                      <span>6. ชื่อย่อ/รหัสอาสาสมัคร Patient Initial</span>
                    </v-flex>
                    <v-flex d-flex align-center>
                      <textfield-form hide-detail :model="form.volunteerCode"
                        @update-data="updateData('volunteerCode', $event)"></textfield-form>
                    </v-flex>
                  </v-layout>

                  <!-- row 7 -->
                  <v-layout pt-10>
                    <v-flex xs2 mr-10 d-flex align-center style="text-align: left;font-weight: bold;">
                      <span>7. วันเกิด</span>
                    </v-flex>
                    <v-flex xs2 d-flex align-center>
                      <date-input-form hide-detail :model="dateChange1"
                        @update-data="updateDate('birthDate', $event)"></date-input-form>
                    </v-flex>
                    <v-flex justify-end xs2 d-flex align-center>
                      <span style="text-align: left;font-weight: bold;">8. อายุ</span>
                    </v-flex>
                    <v-flex xs2 mx-4 d-flex align-center>
                      <textfield-form hide-detail :model="form.age"
                        @update-data="updateData('age', $event)"></textfield-form>
                    </v-flex>
                    <v-flex xs2 d-flex align-center>
                      <span style="text-align: left;font-weight: bold;">ปี</span>
                    </v-flex>
                  </v-layout>

                  <!-- row 8 -->
                  <v-layout mt-4>
                    <v-flex xs2 mr-10 d-flex align-center style="text-align: left;font-weight: bold;">
                      <span>9. เพศ</span>
                    </v-flex>
                    <v-flex d-flex align-center style="text-align: left;">
                      <v-radio-group v-model="form.gender" row>
                        <v-radio color="#3E7662" style="padding-right: 40px" label="ชาย" value="0"></v-radio>
                        <v-radio color="#3E7662" label="หญิง" value="1"></v-radio>
                      </v-radio-group>
                    </v-flex>
                  </v-layout>

                  <!-- row 9 -->
                  <v-layout mt-4>
                    <v-flex xs2 mr-10 d-flex align-center style="text-align: left;font-weight: bold;">
                      <span>10.1 วันที่เกิดอาการ</span>
                    </v-flex>
                    <v-flex xs4 d-flex align-center>
                      <date-input-form hide-detail :model="dateChange2"
                        @update-data="updateDate('date', $event)"></date-input-form>
                    </v-flex>
                  </v-layout>
                  <!-- row 9 -->
                  <v-layout mt-4>
                    <v-flex xs2 mr-10 d-flex align-center style="text-align: left;font-weight: bold;">
                      <span>10.2 วันที่ผู้วิจัยทราบ</span>
                    </v-flex>
                    <v-flex xs4 d-flex align-center>
                      <date-input-form hide-detail :model="dateChange4"
                        @update-data="updateDate('dateKnow', $event)"></date-input-form>
                    </v-flex>
                  </v-layout>

                  <!-- row 9 -->
                  <v-layout mb-3 mt-10>
                    <v-flex style="text-align: left;font-weight: bold;">
                      <span>11.รายละเอียดเหตุการณ์ไม่พึงประสงค์
                        (รวมทั้งการตรวจทางห้องปฏิบัติการ)</span>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex>
                      <textarea-form hide-detail :model="form.detail" @update-data="updateData('detail', $event)"
                        :rows="8" />
                    </v-flex>
                  </v-layout>

                  <!-- row 10 -->
                  <v-layout mb-3 mt-10>
                    <v-flex style="text-align: left;font-weight: bold;">
                      <span>12. ชนิดของการรายงาน</span>
                    </v-flex>
                  </v-layout>
                  <v-layout ml-4>
                    <v-flex>
                      <v-flex>
                        <v-radio-group v-model="form.type" row>
                          <v-radio color="#3E7662" style="padding-right: 100px" label="ครั้งแรก Initial report"
                            value="0"></v-radio>
                          <v-radio color="#3E7662" label="เพื่อติดตาม Follow-up report" value="1"></v-radio>
                        </v-radio-group>
                      </v-flex>
                    </v-flex>
                  </v-layout>

                  <!-- row 11 -->
                  <v-layout mb-3 mt-4>
                    <v-flex style="text-align: left;font-weight: bold;">
                      <span>13. ความสัมพันธ์กับการวิจัย (Relation to the
                        study)</span>
                    </v-flex>
                  </v-layout>
                  <v-layout ml-4>
                    <v-flex>
                      <v-radio-group v-model="form.relation">
                        <v-radio color="#3E7662" label="1. ไม่เกี่ยวข้อง (Not related)" value="1"></v-radio>
                        <v-radio color="#3E7662" label="2. อาจเกี่ยวข้อง (Possibly related)" value="2"></v-radio>
                        <v-radio color="#3E7662" label="3. น่าจะเกี่ยวข้อง (Probably related)" value="3"></v-radio>
                        <v-radio color="#3E7662" label="4. เกี่ยวข้องแน่นอน (Definitely related)" value="4"></v-radio>
                        <v-radio color="#3E7662" label="5. ไม่รู้ (Unknown)" value="5"></v-radio>
                      </v-radio-group>
                    </v-flex>
                  </v-layout>

                  <!-- row 12 -->

                  <v-layout pt-10>
                    <v-flex xs11 style="text-align: left;font-size: 14px; color: #CC3D45">
                      <span>
                        *ถ้าเป็นเหตุการณ์ไม่พึงประสงค์ชนิดร้ายแรง (ก 1-5)
                        ผู้วิจัยควรส่งรายงานแก่คณะอนุกรรมการพิจารณาโครงการวิจัย
                        พบ. ภายใน 7 วัน หลังทราบเหตุการณ์
                      </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>

            <!-- blog 2 -->
            <v-layout mt-12 py-12 style="background-color: white;padding-right: 80px;padding-left: 80px">
              <v-flex>
                <!-- row 1 -->
                <v-layout mt-8 mb-3>
                  <v-flex style="text-align: left;font-weight: bold;">
                    <span>ก. ตรวจสอบความร้ายแรง (Seriousness)</span>
                  </v-flex>
                </v-layout>
                <v-layout ml-4>
                  <v-flex>
                    <v-radio-group v-model="form.severity">
                      <v-radio color="#3E7662" label="1. ตาย (Death)" value="1"></v-radio>
                      <v-radio color="#3E7662" label="2. รุนแรงและอาจทำให้เสียชีวิต (Life threatening)"
                        value="2"></v-radio>
                      <v-radio color="#3E7662"
                        label="3. ต้องรักษาในโรงพยาบาล (Hospitalization / prolonged hospitalization)" value="3"></v-radio>
                      <v-radio color="#3E7662"
                        label="4. พิการหรือทุพลภาพ (Persistent or significant disability / incapacity)"
                        value="4"></v-radio>
                      <v-radio color="#3E7662" label="5. ทารกพิการแต่กำเนิด (Congenital anomaly / birth defect)"
                        value="5"></v-radio>
                      <v-radio color="#3E7662" label="6. ไม่ร้ายแรง" value="6"></v-radio>
                    </v-radio-group>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout mt-12 py-12 style="background-color: white;padding-right: 80px;padding-left: 80px">
              <v-flex>
                <!-- row 4 -->
                <v-layout mt-8 mb-3>
                  <v-flex style="text-align: left;font-weight: bold;">
                    <span>ก. การดำเนินเหตุการณ์ (Progression)</span>
                  </v-flex>
                </v-layout>
                <v-layout ml-4>
                  <v-flex>
                    <v-radio-group v-model="form.progression">
                      <v-radio color="#3E7662" label="1. แก้ไขแล้ว (Resolved)" value="1"></v-radio>
                      <v-radio color="#3E7662" label="2. ยังคงมีปัญหา (Ongoing)" value="2"></v-radio>
                      <v-radio color="#3E7662" label="3. อื่นๆ (Others)" value="3"></v-radio>
                      <textfield-form v-if="form.progression === '3'" label="ระบุ" :model="form.otherProgression"
                        @update-data="updateData('otherProgression', $event)"></textfield-form>
                    </v-radio-group>
                  </v-flex>
                </v-layout>

                <!-- row 5 -->
                <v-layout mt-8 mb-3>
                  <v-flex style="text-align: left;font-weight: bold;">
                    <span>ข. การเปลี่ยนแปลงโครงร่างการวิจัย</span>
                  </v-flex>
                </v-layout>
                <v-layout ml-4>
                  <v-flex>
                    <v-radio-group v-model="form.hasChangeResearch">
                      <v-radio color="#3E7662" label="1. ไม่เปลี่ยน" value="1"></v-radio>
                      <v-radio color="#3E7662"
                        label="2. เปลี่ยน (ส่งรายงานส่วนแก้ไขเพิ่มเติมโครงร่าง การวิจัย RF 12_2563 ให้คณะอนุกรรมการฯ)"
                        value="2"></v-radio>
                    </v-radio-group>
                  </v-flex>
                </v-layout>

                <!-- row 6 -->
                <v-layout mt-8 mb-3>
                  <v-flex style="text-align: left;font-weight: bold;">
                    <span>ค. การเปลี่ยนแปลงเอกสารชี้แจงข้อมูลอาสาสมัคร</span>
                  </v-flex>
                </v-layout>
                <v-layout ml-4>
                  <v-flex>
                    <v-radio-group v-model="form.hasChangeDocument">
                      <v-radio color="#3E7662" label="1. ไม่เปลี่ยน" value="1"></v-radio>
                      <v-radio color="#3E7662"
                        label="2. เปลี่ยน (ส่งรายงานส่วนแก้ไขเพิ่มเติมโครงร่าง การวิจัย RF 12_2563 ให้คณะอนุกรรมการฯ)"
                        value="2"></v-radio>
                    </v-radio-group>
                  </v-flex>
                </v-layout>

                <v-layout pt-10>
                  <v-flex xs11 style="text-align: left;font-size: 14px; color: #CC3D45">
                    <span>
                      *ถ้าเป็นเหตุการณ์ไม่พึงประสงค์ชนิดร้ายแรง (ก 1-5)
                      ผู้วิจัยควรส่งรายงานแก่คณะอนุกรรมการพิจารณาโครงการวิจัย
                      พบ. ภายใน 7 วัน หลังทราบเหตุการณ์
                    </span>
                  </v-flex>
                </v-layout>

                <v-layout class="my-12">
                  <v-flex>
                    <v-layout class="mb-8">
                      <v-flex>
                        <v-layout>
                          <v-flex shrink pt-2 pr-4 style="text-align: left;">
                            <span style="font-weight: bold;font-size: 16px;">หนังสือของหน่วยงาน
                            </span>
                          </v-flex>
                          <v-flex>
                            <v-file-input accept=".pdf" prepend-icon="" ref="agencyBooks" id="agencyBook"
                              :model="form.agencyBook" outlined dense clearable background-color="#FBF6F3" color="#3E7662"
                              item-color="#3E7662" @change="onUpload" hide-details></v-file-input>
                            <div v-if="agencyBook" class="mt-1 text-start" style="font-size: 12px;color: #CC3D45;">
                              <span>ไฟล์ที่อัพโหลด: {{ agencyBook }}</span>
                            </div>
                          </v-flex>
                          <v-flex shrink pl-4 style="text-align: left">
                            <v-btn color="#3E7662" dark @click="onClickBtn">
                              <v-icon class="pr-3">cloud_download</v-icon>
                              <span>Upload</span>
                            </v-btn>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs3 ml-4><span style="color: red">*ชื่อไฟล์ต้องเป็นภาษาอังกฤษเท่านั้น (a-z,0-9)
                          ห้ามมีตัวอักษรอื่น
                        </span></v-flex>
                    </v-layout>

                    <v-layout>
                      <v-flex xs6>
                        <v-layout>
                          <v-flex class="d-flex align-center mr-4 font-weight-bold" shrink>หน่วยงานที่ออกหนังสือ</v-flex>
                          <v-flex>
                            <textfield-form hide-detail :model="form.instituteBook"
                              @update-data="updateData('instituteBook', $event)"></textfield-form>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                    <v-layout mt-6>
                      <v-flex xs6>
                        <v-layout>
                          <v-flex class="d-flex align-center mr-4 font-weight-bold" shrink>เลขหนังสือ</v-flex>
                          <v-flex>
                            <textfield-form hide-detail :model="form.bookNumber"
                              @update-data="updateData('bookNumber', $event)"></textfield-form>
                          </v-flex>
                        </v-layout>
                      </v-flex>

                      <v-flex xs6>
                        <v-layout>
                          <v-flex class="d-flex align-center mx-4 font-weight-bold" shrink>วันที่หนังสือ</v-flex>
                          <v-flex>
                            <DateInputForm id="orderDate" hide-detail :model="bookDate"
                              @update-data="updateDate('bookDate', $event)"></DateInputForm>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>


                    <v-layout style="margin-top: 30px">
                      <v-flex ml-8 my-5 style="text-align: left;">
                        <span style="font-weight: bold">Note</span>
                        <span style="color: red"> *รายการที่ต้องแก้ไข</span>
                      </v-flex>
                    </v-layout>
                    <v-layout>
                      <v-flex style="padding-left: 32px; padding-right: 32px">
                        <textarea-form readonly :model="form.noteDetail" :rows="5"></textarea-form>
                      </v-flex>
                    </v-layout>





                    <v-layout px-12 mt-8>
                      <!-- row 1-->
                      <v-flex xs6 style="font-weight: bold;font-size: 18px" d-flex justify-start>
                        <span>เอกสารแนบเพิ่มเติม</span>
                      </v-flex>
                    </v-layout>
                    <v-layout px-12 my-4>
                      <v-flex shrink mr-4>
                        <v-btn dark style="width: 80px;background-color: #3E7662" @click="addDynamicFile('fileOther')">
                          <v-icon>add</v-icon>
                          <span class="font-weight-bold">เพิ่ม</span>
                        </v-btn>
                      </v-flex>
                      <v-flex>
                        <span style="color: #CC3D45;">*ให้ผู้วิจัยระบุชื่่อ file / เวอร์ชั่น / วันที่
                          ให้ตรงกับ บันทึกข้อความ และห้ามมีอักขระพิเศษ
                          (!@#$%^&*)</span>
                      </v-flex>
                    </v-layout>
                    <div v-for="(input, k) in form.fileOther" :key="'d' + k">
                      <v-layout>
                        <v-flex>
                          <v-layout>
                            <v-flex shrink mr-4 pt-2 style="text-align: right;font-weight: bold;">
                              <span>ชื่อไฟล์</span>
                            </v-flex>
                            <v-flex xs5>
                              <textfield-form hide-detail :model="input.title" @update-data="
                                updateDynamicFileData('fileOther', 'title', k, $event)"></textfield-form>
                            </v-flex>
                            <v-flex shrink pt-2 px-4 style="text-align: left;">
                              <span style="font-weight: bold;font-size: 16px;">ไฟล์</span>
                            </v-flex>
                            <v-flex>
                              <v-file-input accept=".pdf" prepend-icon="" :id="'fileProtocolModify' + k"
                                :model="input.file" outlined dense background-color="#FBF6F3" color="#3E7662"
                                item-color="#3E7662" @change="
                                  updateDynamicFileData('fileOther', 'file', k, $event)"></v-file-input>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex ml-4>
                          <v-btn dark style="background-color: #CC3D45; width: 80px"
                            @click="removeDynamicFile('fileOther', k)" :disabled="form.fileOther.length < 1">
                            <v-icon>remove</v-icon>
                            <span class="font-weight-bold">ลบ</span>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                      <v-flex v-if="input.filePath"><a :href="input.filePath" target="_blank"
                          style="color: #3e7662;text-align: center" class="link-hover">
                          <p>ไฟล์ที่อัพโหลด: {{ input.file }}</p>
                        </a>
                      </v-flex>
                    </div>









                    <v-layout mb-3 style="margin-top: 3rem">
                      <v-flex style="text-align: left;font-weight: bold;">
                        <span>ลงนามผู้วิจัย</span>
                      </v-flex>
                    </v-layout>
                    <v-layout ml-4>
                      <v-flex>
                        <TextfieldForm style="color: #3E7662" label="ชื่อ" :model="form.signatureFirstName" @update-data="
                          updateData('signatureFirstName', $event)
                          " />
                      </v-flex>
                      <v-flex ml-12>
                        <TextfieldForm style="color: #3E7662" label="นามสกุล" :model="form.signatureLastName"
                          @update-data="updateData('signatureLastName', $event)" />
                      </v-flex>
                    </v-layout>

                    <!-- row 13 -->
                    <v-layout mt-4 mb-6>
                      <v-flex shrink mr-10 d-flex align-center style="text-align: left;font-weight: bold;">
                        <span>วันที่รายงาน</span>
                      </v-flex>
                      <v-flex xs4 d-flex align-center>
                        <date-input-form hide-detail :model="dateChange3"
                          @update-data="updateDate('reportDate', $event)"></date-input-form>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>

                <v-layout pt-12 justify-space-between style="font-weight:bold;margin-left: -30px;margin-right: -30px">
                  <v-flex d-flex justify-start>
                    <span>สำนักงานคณะอนุกรรมการพิจารณาโครงการวิจัย พบ.</span>
                  </v-flex>
                  <v-flex d-flex justify-end>
                    <span>หน้า 1 จาก 1 หน้า</span>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>

            <!-- Button Save -->
            <v-layout mt-6>
              <v-flex mb-12 style="text-align: right;">
                <v-layout>
                  <v-flex>
                    <v-btn style="color: #3E7662" class="mx-4" depressed @click="saveDraft"
                      color="#ffffff">บันทึกร่าง</v-btn>
                    <!--<v-btn style="color: #3E7662" class="mx-4" depressed  color="#ffffff" @click="$router.push('/profile')">ย้อนกลับ</v-btn>-->
                    <v-btn depressed dark color="#3E7662" @click="save">
                      <v-icon class="mr-3">add</v-icon>
                      <span>บันทึกข้อมูล</span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <loading color="#527281" :active.sync="isLoading" :can-cancel="false" :is-full-page="fullPage"></loading>
      </v-flex>
    </v-layout>
  </v-content>
</template>

<script>
import TextfieldForm from "../../components/input/TextfieldForm";
import DateInputForm from "../../components/input/DateInputForm";
import TextareaForm from "../../components/input/TextareaForm";
import Swal from "sweetalert2";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "FormRF06",
  components: {
    TextfieldForm,
    DateInputForm,
    TextareaForm,
    Loading,
    // TextfieldFormAppend,
    // DateInputFormAppend,
    // FormTable,
  },
  data: () => ({
    isLoading: false,
    fullPage: true,
    form: {
      noteDetail: "",
      fileOther: [],
      researchCode: "",
      researchName: "",
      firstName: "",
      lastName: "",
      affiliationAndPhoneNumber: "",
      volunteer_checkbox: false,
      volunteerDepartment: "",
      volunteerCode: "",
      birthDate: "",
      age: "",
      gender: "",
      date: "",
      dateKnow: "",
      detail: "",
      type: "",
      relation: "",
      signatureFirstName: "",
      signatureLastName: "",
      reportDate: "",
      severity: "",
      hasCure: "",
      hasRecurrentAfterStop: "",
      progression: "",
      otherProgression: "",
      hasChangeResearch: "",
      hasChangeDocument: "",
      agencyBook: "",
      bookNumber: "",
      bookDate: "",
      instituteBook: "",
    },
    dynamicDateChange: {
      fileOther: []
    },
    researchAllHeaders: [
      { text: "Version", value: "version", align: "center" },
      { text: "หน้า / บรรทัด", value: "page", align: "center" },
      { text: "ข้อมูลเดิม / ข้อเสนอแนะ", value: "data", align: "center" },
    ],
    itemResearchAll: [
      {
        version: "01",
        page:
          "การสำรวจข้อมูลพื้นฐานประชากรและความชุกของปัจจัยเสี่ยงทางด้านสุขภาพ",
        data: "เสร็จสิ้น",
      },
      {
        version: "02",
        page:
          "การศึกษาความชุกและปัจจัยเสี่ยงของโรคติดเชื้อปรสิตในลำไส้ในเด็กนักเรียน",
        data: "รอดำเนินการ",
      },
    ],
    dateChange1: "",
    dateChange2: "",
    dateChange3: "",
    dateChange4: "",
    agencyBook: "",
    bookDate: "",
  }),
  created() {
    // Check Permission
    // if (
    //   !this.$route.query.researchID &&
    //   this.$route.query.status !== "อนุมัติ"
    // ) {
    //   this.$router.go(-1);
    // } else {
    // Set data User
    this.form.researchCode = this.$route.query.researchCode;
    this.form.researchName = this.$route.query.nameResearch;
    this.form.affiliationAndPhoneNumber =
      this.$route.query.affiliation + " / " + this.$route.query.mobilePhone;
    this.updateDate("reportDate", new Date().toISOString().slice(0, 10));
    if (this.$route.query.thName) {
      let text = this.$route.query.thName.split(" ");
      this.form.firstName = text[0];
      this.form.signatureFirstName = text[0];
      this.form.lastName = text[1];
      this.form.signatureLastName = text[1];
    }
    if (this.$route.query.rfID) {
      this.getRF04_2(this.$route.query.rfID);
    }
    // }
  },
  methods: {
    addDynamicFile(name) {
      this.form[name].push({ title: "", file: null });
    },
    updateDynamicFileData(name, type, index, value) {
      if (type === "file") {
        this.isLoading = true;
        console.log("1");
        this.$store
          .dispatch("Common/uploadPdf", { form: "rf-04-2other-file", file: value })
          .then((res) => {

            console.log("fileName: ", res.fileName);
            this.form[name][index].file = res.fileName;
            this.isLoading = false;
          })
          .catch((error) => { });
      } else {
        console.log("3");
        this.form[name][index][type] = value;
        this.isLoading = false;
      }
    },
    removeDynamicFile(name, index) {
      this.form[name].splice(index, 1);
      this.$forceUpdate();
    },
    updateData(name, val) {
      this.form[name] = val;
    },
    clearData() {
      if (!this.form.volunteer_checkbox) {
        this.form.volunteer = "";
      }
    },
    updateDate(name, val) {
      if (name === "birthDate") {
        this.form.birthDate = val;
        this.dateChange1 = this.changeDate(val);
        let today = new Date();
        let birthDay = new Date(val);
        this.form.age = today.getFullYear() - birthDay.getFullYear();
        if (today.getMonth() <= birthDay.getMonth()) {
          if (today.getDay() <= birthDay.getDay()) {
            this.form.age--;
          }
        }
      }
      if (name === "date") {
        this.form.date = val;
        this.dateChange2 = this.changeDate(val);
      }
      if (name === "reportDate") {
        this.form.reportDate = val;
        this.dateChange3 = this.changeDate(val);
      }
      if (name === "bookDate") {
        this.form.bookDate = val;
        this.bookDate = this.changeDate(val);
      }
      if (name === "dateKnow") {
        this.form.dateKnow = val;
        this.dateChange4 = this.changeDate(val);
      }
    },
    changeDate(val) {
      if (val != null) {
        //change date start
        let sub_year = val.substring(0, 4);
        let sub_month = val.substring(5, 7);
        let sub_day = val.substring(8, 10);
        sub_year = parseInt(sub_year) + 543;
        let changeDate = sub_day + "/" + sub_month + "/" + sub_year;
        return changeDate;
      } else {
        return null;
      }
    },
    getRF04_2(id) {
      this.isLoading = true;
      this.$store
        .dispatch("FormRF/getRF04_2", id)
        .then((res) => {
          if (res.status !== "อนุมัติ") {
            this.form.instituteBook = res.instituteBook;
            this.form.noteDetail = res.noteDetail;
            this.form.volunteer_checkbox = res.volunteer_checkbox;
            this.form.volunteerDepartment = res.volunteerDepartment;
            this.form.volunteerCode = res.volunteerCode;
            this.updateDate("birthDate", res.birthDate);
            this.form.age = res.age;
            this.form.gender = res.gender;
            this.updateDate("date", res.date);
            this.updateDate("dateKnow", res.dateKnow);
            this.form.detail = res.detail;
            this.form.type = res.type.toString();
            this.form.relation = res.relation.toString();
            this.form.signatureFirstName = res.signatureFirstName;
            this.form.signatureLastName = res.signatureLastName;
            this.updateDate("reportDate", res.reportDate);
            this.form.severity = res.severity.toString();
            this.form.fileOther = res.fileOther ?? [];
            // this.form.hasCure = res.hasCure.toString();
            // this.form.hasRecurrentAfterStop = res.hasRecurrentAfterStop.toString();
            this.form.progression = res.progression.toString();
            this.form.otherProgression = res.otherProgression;
            this.form.hasChangeResearch = res.hasChangeResearch.toString();
            this.form.hasChangeDocument = res.hasChangeDocument.toString();
            this.form.agencyBook = res.agencyBook;
            this.agencyBook = res.agencyBook;
            this.form.bookNumber = res.bookNumber;
            this.updateDate("bookDate", res.bookDate);
          }
          this.isLoading = false;
        })
        .catch((e) => {
          this.isLoading = false;
        });
    },
    saveDraft() {
      Swal.fire({
        title: "คุณต้องการบันทึกแบบร่างหรือไม่?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3E7662",
        cancelButtonColor: "rgb(189, 189, 189)",
        confirmButtonText: "บันทึก",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.value) {
          this.form["researchID"] = this.$route.query.researchID;
          if (this.$route.query.rfID) {
            this.form["methods"] = "edit";
            this.form["rfID"] = this.$route.query.rfID;
          } else {
            this.form["status"] = "บันทึกแบบร่าง";
            this.form["methods"] = "save";
          }
          this.isLoading = true;
          this.$store
            .dispatch("FormRF/saveRF04_2", this.form)
            .then((res) => {
              this.isLoading = false;
              Swal.fire({
                position: "center",
                icon: "success",
                title: "บันทึกแบบร่างสำเร็จ",
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                this.$router.push("/profile");
              });
            })
            .catch((e) => {
              this.isLoading = false;
              if (e.response.data.errors) {
                for (var k in e.response.data.errors) {
                  Swal.fire({
                    title: "เกิดข้อผิดพลาด",
                    text: e.response.data.errors[k][0],
                    icon: "warning",
                    confirmButtonColor: "#3E7662",
                  }).then(() => {
                    this.$vuetify.goTo(`#${k}`);
                  });
                }
              }
              // Swal.fire({
              //     title: 'Save failed!',
              //     text: 'บันทึกไม่สำเร็จ',
              //     icon: 'warning',
              // }).then(() => {
              //     this.isLoading = false;
              // })
            });
        }
        this.isLoading = false;
      });
    },
    save() {
      Swal.fire({
        title: "คุณต้องการบันทึกหรือไม่?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3E7662",
        cancelButtonColor: "rgb(189, 189, 189)",
        confirmButtonText: "บันทึก",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.value) {
          this.form["researchID"] = this.$route.query.researchID;
          if (this.$route.query.rfID) {
            this.form["methods"] = "edit";
            this.form["rfID"] = this.$route.query.rfID;
          } else {
            this.form["methods"] = "save";
          }
          this.isLoading = true;
          this.$store
            .dispatch("FormRF/saveRF04_2", this.form)
            .then((res) => {
              this.isLoading = false;
              Swal.fire({
                position: "center",
                icon: "success",
                title: "บันทึกสำเร็จ",
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                this.$router.push("/profile");
              });
            })
            .catch((e) => {
              this.isLoading = false;
              if (e.response.data.errors) {
                for (var k in e.response.data.errors) {
                  Swal.fire({
                    title: "เกิดข้อผิดพลาด",
                    text: e.response.data.errors[k][0],
                    icon: "warning",
                    confirmButtonColor: "#3E7662",
                  }).then(() => {
                    this.$vuetify.goTo(`#${k}`);
                  });
                }
              }
              // Swal.fire({
              //     title: 'Save failed!',
              //     text: 'บันทึกไม่สำเร็จ',
              //     icon: 'warning',
              // }).then(() => {
              //     this.isLoading = false;
              // })
            });
        }
        this.isLoading = false;
      });
    },
    volunteerChangeCheckbox() {
      if (!this.form.volunteer_checkbox) {
        this.form.volunteerDepartment = "";
      }
    },
    onUpload(e) {
      this.$store
        .dispatch("Common/uploadPdf", { form: "rf04_2", file: e })
        .then((res) => {
          this.form.agencyBook = res.fileName;
        })
        .catch((error) => { });
    },
    onClickBtn() {
      document.getElementById("agencyBook").click();
    },
    setData() {
      this.form.volunteer_checkbox = true;
      this.form.volunteerDepartment = "Test";
      this.form.volunteerCode = "Test";
      this.form.birthDate = new Date().toISOString().substr(0, 10);
      this.form.age = "25";
      this.form.gender = "0";
      this.form.date = new Date().toISOString().substr(0, 10);
      this.form.detail =
        "รายละเอียดเหตุการณ์ไม่พึงประสงค์ (รวมทั้งการตรวจทางห้องปฏิบัติการ)";
      this.form.type = "1";
      this.form.relation = "1";
      this.form.signatureFirstName = "จาป";
      this.form.signatureLastName = "ชาวสวน";
      this.form.reportDate = new Date().toISOString().substr(0, 10);
      this.form.severity = "1";
      this.form.hasCure = "1";
      this.form.hasRecurrentAfterStop = "1";
      this.form.progression = "1";
      this.form.hasChangeResearch = "1";
      this.form.hasChangeDocument = "1";
    },
  },
};
</script>

<style lang="scss">
label.v-label.theme--light {
  color: #544a44;
}
</style>
