<template>
    <v-content>

        <v-layout>
            <v-flex px-12 pt-12 pb-4 style="text-align: left;">
                <span style="font-size: 24px;color: #3E7662;font-weight: bold;">
                   แบบฟอร์ม RTA Version 6.0 (แก้ไขล่าสุดเดือน พฤศจิกายน 2563)
                </span>
            </v-flex>
        </v-layout>

        <v-layout>
            <v-flex px-12 pb-12>
                <v-card color="#FFFFFF">
                    <v-layout>
                        <v-flex pa-12 style="text-align: left">
                            <v-layout>
                                <v-flex xs10>
                                    RTA 00_รายการ วิธีดำเนินการมาตรฐาน ฉบับที่ 6
                                </v-flex>
                                <v-flex xs2 style="text-align: right">
                                    <v-btn color="#3E7662" dark @click="downloadForm('rta00')">
                                        <v-icon class="pr-3">cloud_download</v-icon>
                                        <span>Download</span>
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex py-3>
                                    <v-divider style="background-color: white"></v-divider>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex xs10 class="d-flex align-center">
                                    RTA 01_2563 Preparation of SOPs
                                </v-flex>
                                <v-flex xs2 style="text-align: right">
                                    <v-btn color="#3E7662" dark @click="downloadForm('rta01')">
                                        <v-icon class="pr-3">cloud_download</v-icon>
                                        <span>Download</span>
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex py-3>
                                    <v-divider style="background-color: white"></v-divider>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex xs10 class="d-flex align-center">
                                    RTA 02_2563 Constitution of IRB
                                </v-flex>
                                <v-flex xs2 style="text-align: right">
                                    <v-btn color="#3E7662" dark @click="downloadForm('rta02')">
                                        <v-icon class="pr-3">cloud_download</v-icon>
                                        <span>Download</span>
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex py-3>
                                    <v-divider style="background-color: white"></v-divider>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex xs10 class="d-flex align-center">
                                    RTA 03_1_2563 Confidentiality agreement
                                </v-flex>
                                <v-flex xs2 style="text-align: right">
                                    <v-btn color="#3E7662" dark @click="downloadForm('rta03')">
                                        <v-icon class="pr-3">cloud_download</v-icon>
                                        <span>Download</span>
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex py-3>
                                    <v-divider style="background-color: white"></v-divider>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex xs10 class="d-flex align-center">
                                    RTA 04_1_2563 Management of Protocols for Review
                                </v-flex>
                                <v-flex xs2 style="text-align: right">
                                    <v-btn color="#3E7662" dark @click="downloadForm('rta04')">
                                        <v-icon class="pr-3">cloud_download</v-icon>
                                        <span>Download</span>
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex py-3>
                                    <v-divider style="background-color: white"></v-divider>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex xs10 class="d-flex align-center">
                                    RTA 05_2563 Full board review
                                </v-flex>
                                <v-flex xs2 style="text-align: right">
                                    <v-btn color="#3E7662" dark @click="downloadForm('rta05')">
                                        <v-icon class="pr-3">cloud_download</v-icon>
                                        <span>Download</span>
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex py-3>
                                    <v-divider style="background-color: white"></v-divider>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex xs10 class="d-flex align-center">
                                    RTA 06_1_2563 Expedited review
                                </v-flex>
                                <v-flex xs2 style="text-align: right">
                                    <v-btn color="#3E7662" dark @click="downloadForm('rta06')">
                                        <v-icon class="pr-3">cloud_download</v-icon>
                                        <span>Download</span>
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex py-3>
                                    <v-divider style="background-color: white"></v-divider>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex xs10 class="d-flex align-center">
                                    RTA 07_1_2563 Exemption
                                </v-flex>
                                <v-flex xs2 style="text-align: right">
                                    <v-btn color="#3E7662" dark @click="downloadForm('rta07')">
                                        <v-icon class="pr-3">cloud_download</v-icon>
                                        <span>Download</span>
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex py-3>
                                    <v-divider style="background-color: white"></v-divider>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex xs10 class="d-flex align-center">
                                    RTA 08_1_2563 Guideline of Review Process
                                </v-flex>
                                <v-flex xs2 style="text-align: right">
                                    <v-btn color="#3E7662" dark @click="downloadForm('rta08')">
                                        <v-icon class="pr-3">cloud_download</v-icon>
                                        <span>Download</span>
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex py-3>
                                    <v-divider style="background-color: white"></v-divider>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex xs10 class="d-flex align-center">
                                    RTA 09_2563 Review of protocol amendment
                                </v-flex>
                                <v-flex xs2 style="text-align: right">
                                    <v-btn color="#3E7662" dark @click="downloadForm('rta09')">
                                        <v-icon class="pr-3">cloud_download</v-icon>
                                        <span>Download</span>
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex py-3>
                                    <v-divider style="background-color: white"></v-divider>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex xs10 class="d-flex align-center">
                                    RTA 10_2563 Renewal of Study Protocols
                                </v-flex>
                                <v-flex xs2 style="text-align: right">
                                    <v-btn color="#3E7662" dark @click="downloadForm('rta10')">
                                        <v-icon class="pr-3">cloud_download</v-icon>
                                        <span>Download</span>
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex py-3>
                                    <v-divider style="background-color: white"></v-divider>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex xs10 class="d-flex align-center">
                                    RTA 11_1_2563 Review SAE report
                                </v-flex>
                                <v-flex xs2 style="text-align: right">
                                    <v-btn color="#3E7662" dark @click="downloadForm('rta11')">
                                        <v-icon class="pr-3">cloud_download</v-icon>
                                        <span>Download</span>
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex py-3>
                                    <v-divider style="background-color: white"></v-divider>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex xs10 class="d-flex align-center">
                                    RTA 12_1_2563 Protocol deviation
                                </v-flex>
                                <v-flex xs2 style="text-align: right">
                                    <v-btn color="#3E7662" dark @click="downloadForm('rta12')">
                                        <v-icon class="pr-3">cloud_download</v-icon>
                                        <span>Download</span>
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex py-3>
                                    <v-divider style="background-color: white"></v-divider>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex xs10 class="d-flex align-center">
                                    RTA 13_2563 Final report
                                </v-flex>
                                <v-flex xs2 style="text-align: right">
                                    <v-btn color="#3E7662" dark @click="downloadForm('rta13')">
                                        <v-icon class="pr-3">cloud_download</v-icon>
                                        <span>Download</span>
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex py-3>
                                    <v-divider style="background-color: white"></v-divider>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex xs10 class="d-flex align-center">
                                    RTA 14_1_2563 Study termination
                                </v-flex>
                                <v-flex xs2 style="text-align: right">
                                    <v-btn color="#3E7662" dark @click="downloadForm('rta14')">
                                        <v-icon class="pr-3">cloud_download</v-icon>
                                        <span>Download</span>
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex py-3>
                                    <v-divider style="background-color: white"></v-divider>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex xs10 class="d-flex align-center">
                                    RTA 15_2563 Meeting agenda and Minutes
                                </v-flex>
                                <v-flex xs2 style="text-align: right">
                                    <v-btn color="#3E7662" dark @click="downloadForm('rta15')">
                                        <v-icon class="pr-3">cloud_download</v-icon>
                                        <span>Download</span>
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex py-3>
                                    <v-divider style="background-color: white"></v-divider>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex xs10 class="d-flex align-center">
                                    RTA 16_2563 Response to Subject’s Queries, Requests, and Compliaints
                                </v-flex>
                                <v-flex xs2 style="text-align: right">
                                    <v-btn color="#3E7662" dark @click="downloadForm('rta16')">
                                        <v-icon class="pr-3">cloud_download</v-icon>
                                        <span>Download</span>
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex py-3>
                                    <v-divider style="background-color: white"></v-divider>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex xs10 class="d-flex align-center">
                                    RTA 17_2563 Study Files Management Process
                                </v-flex>
                                <v-flex xs2 style="text-align: right">
                                    <v-btn color="#3E7662" dark @click="downloadForm('rta17')">
                                        <v-icon class="pr-3">cloud_download</v-icon>
                                        <span>Download</span>
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex py-3>
                                    <v-divider style="background-color: white"></v-divider>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex xs10 class="d-flex align-center">
                                    RTA 18_2563 Site monitoring visit
                                </v-flex>
                                <v-flex xs2 style="text-align: right">
                                    <v-btn color="#3E7662" dark @click="downloadForm('rta18')">
                                        <v-icon class="pr-3">cloud_download</v-icon>
                                        <span>Download</span>
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex py-3>
                                    <v-divider style="background-color: white"></v-divider>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex xs10 class="d-flex align-center">
                                    RTA 19_2563 CREC
                                </v-flex>
                                <v-flex xs2 style="text-align: right">
                                    <v-btn color="#3E7662" dark @click="downloadForm('rta19')">
                                        <v-icon class="pr-3">cloud_download</v-icon>
                                        <span>Download</span>
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex py-3>
                                    <v-divider style="background-color: white"></v-divider>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex xs10 class="d-flex align-center">
                                    RTA 20_2563 Review of Medical Device Study
                                </v-flex>
                                <v-flex xs2 style="text-align: right">
                                    <v-btn color="#3E7662" dark @click="downloadForm('summary')">
                                        <v-icon class="pr-3">cloud_download</v-icon>
                                        <span>Download</span>
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex py-3 style="text-align: right">
                                    <v-divider style="background-color: white"></v-divider>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                </v-card>
            </v-flex>
        </v-layout>
    </v-content>
</template>

<script>
    export default {
        name: "DownloadForm",
        methods: {
            downloadForm(name) {
                switch (name) {
                    case 'rta00' : window.open('https://service.irbrta-research.com/storage/sop2024/RTA 00_รายการ วิธีดำเนินการมาตรฐาน ฉบับที่ 6.pdf');
                        break;
                    case 'rta01' : window.open('https://service.irbrta-research.com/storage/sop2024/RTA 01_2563 Preparation of SOPs.pdf');
                        break;
                    case 'rta02' : window.open('https://service.irbrta-research.com/storage/sop2024/RTA 02_2563 Constitution of IRB.pdf');
                        break;
                    case 'rta03' : window.open('https://service.irbrta-research.com/storage/sop2024/RTA 03_2563 Confidentiality agreement.pdf');
                        break;
                    case 'rta04' : window.open('https://service.irbrta-research.com/storage/sop2024/RTA 04_2563 Management of Protocols for Review.pdf');
                        break;
                    case 'rta05' : window.open('https://service.irbrta-research.com/storage/sop2024/RTA 05_2563 Full board review.pdf');
                        break;
                    case 'rta06' : window.open('https://service.irbrta-research.com/storage/sop2024/RTA 06_2563 Expedited review.pdf');
                        break;
                    case 'rta07' : window.open('https://service.irbrta-research.com/storage/sop2024/RTA 07_2563 Exemption.pdf');
                        break;
                    case 'rta08' : window.open('https://service.irbrta-research.com/storage/sop2024/RTA 08_2563 Guideline of Review Process.pdf');
                        break;
                    case 'rta09' : window.open('https://service.irbrta-research.com/storage/sop2024/RTA 09_2563 Review of protocol amendment.pdf');
                        break;
                    case 'rta10' : window.open('https://service.irbrta-research.com/storage/sop2024/RTA 10_2563 Renewal of Study Protocols.pdf');
                        break;
                    case 'rta11' : window.open('https://service.irbrta-research.com/storage/sop2024/RTA 11_2563 Review SAE report.pdf');
                        break;
                    case 'rta12' : window.open('https://service.irbrta-research.com/storage/sop2024/RTA 12_2563 Protocol deviation.pdf');
                        break;
                    case 'rta13' : window.open('https://service.irbrta-research.com/storage/sop2024/RTA 13_2563 Final report.pdf');
                        break;
                    case 'rta14' : window.open('https://service.irbrta-research.com/storage/sop2024/RTA 14_2563 Study termination.pdf');
                        break;
                    case 'rta15' : window.open('https://service.irbrta-research.com/storage/sop2024/RTA 15_2563 Meeting agenda and Minutes.pdf');
                        break;
                    case 'rta16' : window.open('https://service.irbrta-research.com/storage/sop2024/RTA 16_2563 Response to Subject’s Queries, Requests, and Compliaints.pdf');
                        break;
                    case 'rta17' : window.open('https://service.irbrta-research.com/storage/sop2024/RTA 17_2563 Study Files Management Process.pdf');
                        break;
                    case 'rta18' : window.open('https://service.irbrta-research.com/storage/sop2024/RTA 18_2563 Site monitoring visit.pdf');
                        break;
                    case 'rta19' : window.open('https://service.irbrta-research.com/storage/sop2024/RTA 19_2563 CREC.pdf');
                        break;
                    case 'summary' : window.open('https://service.irbrta-research.com/storage/sop2024/RTA 20_2563 Review of Medical Device Study.pdf');
                        break;
                }
            },
        }
    }
</script>

<style scoped>

</style>
