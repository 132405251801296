import Auth from "./API/auth.service";
import Common from "./API/common.service";
import ResearchForm from "./API/researchForm.service"
import formRF from "./API/formRF.service"
import Exam from "./API/exam.service";
import Profile from "./API/profile.service";
import Budget from "./API/budget.service";

export default {
  Auth,
  Common,
  ResearchForm,
  formRF,
  Exam,
  Profile,
  Budget
}
