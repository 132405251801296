<template>
    <v-content>
        <v-layout>
            <v-flex shrink pt-2 v-if="label" style="font-weight: bold">
                <span class="mr-3">{{label}}</span>
            </v-flex>
            <v-flex class="font-weight-bold">
                <v-menu
                        v-model="menu_date"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        :disabled="disabled"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                                :id="id"
                                :hide-details="hideDetail"
                                v-model="model"
                                append-icon="date_range"
                                readonly
                                background-color="#FBF6F3"
                                item-color="#3E7662"
                                color="#3E7662"
                                filled
                                outlined
                                dense
                                v-on="on"
                                :disabled="disabled"
                        ></v-text-field>
                    </template>
                    <!--                    v-model="model"-->
                    <v-date-picker
                            @input="selectDateStart"
                            locale="th"
                            type="date"
                            color="#3E7662"
                            header-color="#3E7662"
                            :disabled="disabled"
                            :min="min"
                    ></v-date-picker>
                </v-menu>
            </v-flex>
        </v-layout>
    </v-content>
</template>

<script>
    export default {
        name: "DateInputForm",
        props: {
            rules:{
                type: String,
                default: ''
            },
            model: {
                required: false
            },
            label: {
                type: String,
            },
            id: {
                type: String,
            },
            hideDetail: {
                type: Boolean,
            },
            min:{
                type:String,
            },
            disabled: Boolean,
        },
        data: () => ({
            menu_date: '',
        }),
        methods: {
            selectDateStart(val) {
                this.menu_date = false;
                this.$emit("update-data", val);
            },
        }
    }
</script>

<style scoped>

</style>
