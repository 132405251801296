<template>
  <v-content style="background-color: #EFEAE7">
    <v-layout style="text-align: center;">
      <v-flex pt-8>
        <span style="font-weight: bold;font-size: 36px; color: #3E7662"
          >สร้างงานวิจัย</span
        >
      </v-flex>
    </v-layout>

    <v-layout>
      <v-flex style="background-color: #EFEAE7;padding: 2% 5% 2% 5%">
        <v-card>
          <v-layout pa-8 justify-center style="background-color: #3E7662;">
            <v-flex style="text-align: left;">
              <span style="font-weight: bold;font-size: 28px;color: white;"
                >แบบโครงร่างการวิจัย</span
              >
            </v-flex>
            <v-flex style="text-align: right;">
              <span style="font-size: 14px;color: white;"
                >Version……….Date…………………….</span
              ><br />
              <span style="font-size: 14px;color: white;">RF 08_2_2563</span>
            </v-flex>
          </v-layout>

          <v-layout>
            <v-flex px-12 py-6>
              <v-layout>
                <v-flex xs6 pb-6 style="text-align: left;font-weight: bold">
                  <span style="font-weight: bold;font-size: 16px;"
                    >11. เอกสารอื่นๆ
                  </span>
                  <span style="color: #CC3D45;font-size: 14px"
                    >*หมายเหตุ : อัพโหลดได้เฉพาะไฟล์นามสกุล PDF เท่านั้น</span
                  >
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs1></v-flex>
                <v-flex pt-6 row>
                  <v-radio-group row v-model="submissionType" disabled>
                    <v-radio
                      color="#3E7662"
                      label="Initial submission"
                      value="0"
                    ></v-radio>
                    <v-radio
                      color="#3E7662"
                      label="Re submission"
                      value="1"
                    ></v-radio>
                  </v-radio-group>
                </v-flex>
              </v-layout>

              <v-form ref="formFile" v-model="valid" lazy-validation>
                <div v-if="submissionType === '0'">
                  <!--แบบบันทึกข้อมูล (ถ้ามี)-->
                  <div class="mb-8">
                    <v-layout>
                      <v-flex mb-2 pb-2 pl-8 style="text-align: left;">
                        <span style="font-weight: bold;font-size: 16px;"
                          >แบบบันทึกข้อมูล (ถ้ามี)</span
                        >
                      </v-flex>
                    </v-layout>
                    <v-layout class="justify-center">
                      <v-flex shrink class="pt-3 font-weight-bold mr-3"
                        >ไฟล์</v-flex
                      >
                      <v-flex xs6>
                        <v-layout class="mb-2">
                          <v-flex xs10 class="text-start">
                            <v-file-input
                              accept=".pdf"
                              prepend-icon=""
                              id="dataRecordFrom"
                              :model="form.dataRecordFrom"
                              outlined
                              dense
                              background-color="#FBF6F3"
                              color="#3E7662"
                              item-color="#3E7662"
                              @change="
                                setDataUploadData('dataRecordFrom', $event)
                              "
                              hide-details
                            ></v-file-input>
                          </v-flex>
                          <v-flex shrink pl-4 style="text-align: left">
                            <v-btn
                              color="#3E7662"
                              dark
                              @click="uploadFile('dataRecordFrom')"
                            >
                              <v-icon class="pr-3">cloud_download</v-icon>
                              <span>Upload</span>
                            </v-btn>
                          </v-flex>
                        </v-layout>

                        <v-layout v-if="onHavedataRecordFrom">
                          <v-flex
                            class="text-start"
                            style="font-size: 12px;color: #CC3D45"
                          >
                            <span>ชื่อไฟล์ที่อัพโหลด : </span>
                            <span
                              class="link__pdf"
                              @click="openPDF('dataRecordFrom')"
                              >{{ onHavedataRecordFrom }}</span
                            >
                            <v-icon v-if="form.dataRecordFrom" @click="cancelFile('dataRecordFrom','onHavedataRecordFrom')" style="cursor: pointer">mdi-close</v-icon>
                          </v-flex>
                        </v-layout>
                        <v-layout v-if="verifyForm.noteSix">
                          <v-flex xs12 pa-2 style="border: #CC3D45 solid 1px">
                            <span>รายการแก้ไขเพิ่มเติม แบบบันทึกข้อมูล:</span><br>
                            <span style="color: #CC3D45">{{verifyForm.noteSix}}</span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </div>

                  <!--แบบสอบถามหรือสัมภาษณ์ (ถ้ามี)-->
                  <div class="mb-8">
                    <v-layout>
                      <v-flex mb-2 pb-2 pl-8 style="text-align: left;">
                        <span style="font-weight: bold;font-size: 16px;"
                          >แบบสอบถามหรือสัมภาษณ์ (ถ้ามี)</span
                        >
                      </v-flex>
                    </v-layout>
                    <v-layout class="justify-center">
                      <v-flex shrink class="pt-3 font-weight-bold mr-3"
                        >ไฟล์</v-flex
                      >
                      <v-flex xs6>
                        <v-layout class="mb-2">
                          <v-flex xs10 class="text-start">
                            <v-file-input
                              accept=".pdf"
                              prepend-icon=""
                              id="questionnaireInterview"
                              :model="form.questionnaireInterview"
                              outlined
                              dense
                              background-color="#FBF6F3"
                              color="#3E7662"
                              item-color="#3E7662"
                              @change="
                                setDataUploadData(
                                  'questionnaireInterview',
                                  $event
                                )
                              "
                              hide-details
                            ></v-file-input>
                          </v-flex>
                          <v-flex shrink pl-4 style="text-align: left">
                            <v-btn
                              color="#3E7662"
                              dark
                              @click="uploadFile('questionnaireInterview')"
                            >
                              <v-icon class="pr-3">cloud_download</v-icon>
                              <span>Upload</span>
                            </v-btn>
                          </v-flex>
                        </v-layout>

                        <v-layout v-if="onHaveQuestionnaireInterview">
                          <v-flex
                            class="text-start"
                            style="font-size: 12px;color: #CC3D45"
                          >
                            <span>ชื่อไฟล์ที่อัพโหลด : </span>
                            <span
                              class="link__pdf"
                              @click="openPDF('questionnaireInterview')"
                            >
                              {{ onHaveQuestionnaireInterview }}
                            </span>
                            <v-icon v-if="form.questionnaireInterview" @click="cancelFile('questionnaireInterview','onHaveQuestionnaireInterview')" style="cursor: pointer">mdi-close</v-icon>
                          </v-flex>
                        </v-layout>
                        <v-layout v-if="verifyForm.noteSeven">
                          <v-flex xs12 pa-2 style="border: #CC3D45 solid 1px">
                            <span>รายการแก้ไขเพิ่มเติม แบบสอบถามหรือสัมภาษณ์:</span><br>
                            <span style="color: #CC3D45">{{verifyForm.noteSeven}}</span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </div>

                  <!--สมุดบันทึก (diary) (ถ้ามี)-->
                  <div class="mb-8">
                    <v-layout>
                      <v-flex mb-2 pb-2 pl-8 style="text-align: left;">
                        <span style="font-weight: bold;font-size: 16px;"
                          >สมุดบันทึก (diary) (ถ้ามี)</span
                        >
                      </v-flex>
                    </v-layout>
                    <v-layout class="justify-center">
                      <v-flex shrink class="pt-3 font-weight-bold mr-3"
                        >ไฟล์</v-flex
                      >
                      <v-flex xs6>
                        <v-layout class="mb-2">
                          <v-flex xs10 class="text-start">
                            <v-file-input
                              accept=".pdf"
                              prepend-icon=""
                              id="diary"
                              :model="form.diary"
                              outlined
                              dense
                              background-color="#FBF6F3"
                              color="#3E7662"
                              item-color="#3E7662"
                              @change="setDataUploadData('diary', $event)"
                              hide-details
                            ></v-file-input>
                          </v-flex>
                          <v-flex shrink pl-4 style="text-align: left">
                            <v-btn
                              color="#3E7662"
                              dark
                              @click="uploadFile('diary')"
                            >
                              <v-icon class="pr-3">cloud_download</v-icon>
                              <span>Upload</span>
                            </v-btn>
                          </v-flex>
                        </v-layout>

                        <v-layout v-if="onHaveDiary">
                          <v-flex
                            class="text-start"
                            style="font-size: 12px;color: #CC3D45"
                          >
                            <span>ชื่อไฟล์ที่อัพโหลด : </span>
                            <span class="link__pdf" @click="openPDF('diary')">
                              {{ onHaveDiary }}
                            </span>
                            <v-icon v-if="form.diary" @click="cancelFile('diary','onHaveDiary')" style="cursor: pointer">mdi-close</v-icon>
                          </v-flex>
                        </v-layout>
                        <v-layout v-if="verifyForm.noteSeven">
                          <v-flex xs12 pa-2 style="border: #CC3D45 solid 1px">
                            <span>รายการแก้ไขเพิ่มเติม สมุดบันทึก (diary):</span><br>
                            <span style="color: #CC3D45">{{verifyForm.noteSeven}}</span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </div>

                  <!--เอกสารชี้แจงข้อมูลผู้ร่วมโครงการวิจัย /หนังสือแสดงเจตนา-->
                  <div class="mb-8">
                    <v-layout>
                      <v-flex mb-2 pb-2 pl-8 style="text-align: left;">
                        <span style="font-weight: bold;font-size: 16px;"
                          >เอกสารชี้แจงข้อมูลผู้ร่วมโครงการวิจัย
                          /หนังสือแสดงเจตนา (RF 09_1_2563)</span
                        >
                      </v-flex>
                    </v-layout>
                    <v-layout class="justify-center">
                      <v-flex shrink class="pt-3 font-weight-bold mr-3"
                        >ไฟล์</v-flex
                      >
                      <v-flex xs6>
                        <v-layout class="mb-2">
                          <v-flex xs10 class="text-start">
                            <v-file-input
                              accept=".pdf"
                              :disabled="!valid"
                              prepend-icon=""
                              id="documentsExplain"
                              :model="form.documentsExplain"
                              outlined
                              dense
                              background-color="#FBF6F3"
                              color="#3E7662"
                              item-color="#3E7662"
                              @change="
                                setDataUploadData('documentsExplain', $event)
                              "
                              hide-details
                            ></v-file-input>
                          </v-flex>
                          <v-flex shrink pl-4 style="text-align: left">
                            <v-btn
                              color="#3E7662"
                              dark
                              @click="uploadFile('documentsExplain')"
                            >
                              <v-icon class="pr-3">cloud_download</v-icon>
                              <span>Upload</span>
                            </v-btn>
                          </v-flex>
                        </v-layout>

                        <v-layout v-if="onHaveDocumentsExplain">
                          <v-flex
                            class="text-start"
                            style="font-size: 12px;color: #CC3D45"
                          >
                            <span>ชื่อไฟล์ที่อัพโหลด : </span>
                            <span
                              class="link__pdf"
                              @click="openPDF('documentsExplain')"
                            >
                              {{ onHaveDocumentsExplain }}
                            </span>
                            <v-icon v-if="form.documentsExplain" @click="cancelFile('documentsExplain','onHaveDocumentsExplain')" style="cursor: pointer">mdi-close</v-icon>
                          </v-flex>
                        </v-layout>
                        <v-layout v-if="verifyForm.noteEight">
                          <v-flex xs12 pa-2 style="border: #CC3D45 solid 1px">
                            <span>รายการแก้ไขเพิ่มเติม เอกสารชี้แจงข้อมูลผู้ร่วมโครงการวิจัย/หนังสือแสดงเจตนา (RF 09_1_2563):</span><br>
                            <span style="color: #CC3D45">{{verifyForm.noteEight}}</span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </div>

                  <!--เอกสารอื่นๆ ได้แก่ โฆษณา สิ่งตีพิมพ์ บทความ สื่อ เช่น วิดีโอ ซีดี เทป-->
                  <div class="mb-8">
                    <v-layout>
                      <v-flex mb-2 pb-2 pl-8 style="text-align: left;">
                        <span style="font-weight: bold;font-size: 16px;"
                          >เอกสารอื่นๆ ได้แก่ โฆษณา สิ่งตีพิมพ์ บทความ สื่อ เช่น
                          วิดีโอ ซีดี เทป</span
                        >
                      </v-flex>
                    </v-layout>
                    <v-layout class="justify-center">
                      <v-flex shrink class="pt-3 font-weight-bold mr-3"
                        >ไฟล์</v-flex
                      >
                      <v-flex xs6>
                        <v-layout class="mb-2">
                          <v-flex xs10 class="text-start">
                            <v-file-input
                              accept=".pdf"
                              prepend-icon=""
                              id="otherDocuments"
                              :model="form.otherDocuments"
                              outlined
                              dense
                              background-color="#FBF6F3"
                              color="#3E7662"
                              item-color="#3E7662"
                              @change="
                                setDataUploadData('otherDocuments', $event)
                              "
                              hide-details
                            ></v-file-input>
                          </v-flex>
                          <v-flex shrink pl-4 style="text-align: left">
                            <v-btn
                              color="#3E7662"
                              dark
                              @click="uploadFile('otherDocuments')"
                            >
                              <v-icon class="pr-3">cloud_download</v-icon>
                              <span>Upload</span>
                            </v-btn>
                          </v-flex>
                        </v-layout>

                        <v-layout v-if="onHaveOtherDocuments">
                          <v-flex
                            class="text-start"
                            style="font-size: 12px;color: #CC3D45"
                          >
                            <span>ชื่อไฟล์ที่อัพโหลด : </span>
                            <span
                              class="link__pdf"
                              @click="openPDF('otherDocuments')"
                            >
                              {{ onHaveOtherDocuments }}
                            </span>
                            <v-icon v-if="form.otherDocuments" @click="cancelFile('otherDocuments','onHaveOtherDocuments')" style="cursor: pointer">mdi-close</v-icon>
                          </v-flex>
                        </v-layout>
                        <v-layout v-if="verifyForm.noteNine">
                          <v-flex xs12 pa-2 style="border: #CC3D45 solid 1px">
                            <span>รายการแก้ไขเพิ่มเติม เอกสารอื่นๆ ได้แก่ โฆษณา สิ่งตีพิมพ์ บทความ สื่อ เช่น วิดีโอ ซีดี เทป:</span><br>
                            <span style="color: #CC3D45">{{verifyForm.noteNine}}</span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </div>

                  <!--สำเนาหนังสือรับรองว่า ได้ผ่านการสอบโครงร่างการวิจัยจากคณะ /สถาบันแล้ว-->
                  <div class="mb-8">
                    <v-layout>
                      <v-flex mb-2 pb-2 pl-8 style="text-align: left;">
                        <span style="font-weight: bold;font-size: 16px;"
                          >สำเนาหนังสือรับรองว่า
                          ได้ผ่านการสอบโครงร่างการวิจัยจากคณะ /สถาบันแล้ว</span
                        >
                      </v-flex>
                    </v-layout>
                    <v-layout class="justify-center">
                      <v-flex shrink class="pt-3 font-weight-bold mr-3"
                        >ไฟล์</v-flex
                      >
                      <v-flex xs6>
                        <v-layout class="mb-2">
                          <v-flex xs10 class="text-start">
                            <v-file-input
                              accept=".pdf"
                              prepend-icon=""
                              id="certificate"
                              :model="form.certificate"
                              outlined
                              dense
                              background-color="#FBF6F3"
                              color="#3E7662"
                              item-color="#3E7662"
                              @change="setDataUploadData('certificate', $event)"
                              hide-details
                            ></v-file-input>
                          </v-flex>
                          <v-flex shrink pl-4 style="text-align: left">
                            <v-btn
                              color="#3E7662"
                              dark
                              @click="uploadFile('certificate')"
                            >
                              <v-icon class="pr-3">cloud_download</v-icon>
                              <span>Upload</span>
                            </v-btn>
                          </v-flex>
                        </v-layout>

                        <v-layout v-if="onHaveCertificate">
                          <v-flex
                            class="text-start"
                            style="font-size: 12px;color: #CC3D45"
                          >
                            <span>ชื่อไฟล์ที่อัพโหลด : </span>
                            <span
                              class="link__pdf"
                              @click="openPDF('certificate')"
                            >
                              {{ onHaveCertificate }}
                            </span>
                            <v-icon v-if="form.certificate" @click="cancelFile('certificate','onHaveCertificate')" style="cursor: pointer">mdi-close</v-icon>
                          </v-flex>
                        </v-layout>
                        <v-layout v-if="verifyForm.noteEleven">
                          <v-flex xs12 pa-2 style="border: #CC3D45 solid 1px">
                            <span>รายการแก้ไขเพิ่มเติม สำเนาหนังสือรับรองว่า ได้ผ่านการสอบโครงร่างการวิจัยจากคณะ /สถาบันแล้ว:</span><br>
                            <span style="color: #CC3D45">{{verifyForm.noteEleven}}</span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </div>

                  <!--คู่มือผู้วิจัย (Investigator brochure)-->
                  <div class="mb-8">
                    <v-layout>
                      <v-flex mb-2 pb-2 pl-8 style="text-align: left;">
                        <span style="font-weight: bold;font-size: 16px;"
                          >คู่มือผู้วิจัย (Investigator brochure)</span
                        >
                      </v-flex>
                    </v-layout>
                    <v-layout class="justify-center">
                      <v-flex shrink class="pt-3 font-weight-bold mr-3"
                        >ไฟล์</v-flex
                      >
                      <v-flex xs6>
                        <v-layout class="mb-2">
                          <v-flex xs10 class="text-start">
                            <v-file-input
                              accept=".pdf"
                              prepend-icon=""
                              id="investigatorBrochure"
                              :model="form.investigatorBrochure"
                              outlined
                              dense
                              background-color="#FBF6F3"
                              color="#3E7662"
                              item-color="#3E7662"
                              @change="
                                setDataUploadData(
                                  'investigatorBrochure',
                                  $event
                                )
                              "
                              hide-details
                            ></v-file-input>
                          </v-flex>
                          <v-flex shrink pl-4 style="text-align: left">
                            <v-btn
                              color="#3E7662"
                              dark
                              @click="uploadFile('investigatorBrochure')"
                            >
                              <v-icon class="pr-3">cloud_download</v-icon>
                              <span>Upload</span>
                            </v-btn>
                          </v-flex>
                        </v-layout>

                        <v-layout v-if="onHaveInvestigatorBrochure">
                          <v-flex
                            class="text-start"
                            style="font-size: 12px;color: #CC3D45"
                          >
                            <span>ชื่อไฟล์ที่อัพโหลด : </span>
                            <span
                              class="link__pdf"
                              @click="openPDF('investigatorBrochure')"
                            >
                              {{ onHaveInvestigatorBrochure }}
                            </span>
                            <v-icon v-if="form.investigatorBrochure" @click="cancelFile('investigatorBrochure','onHaveInvestigatorBrochure')" style="cursor: pointer">mdi-close</v-icon>
                          </v-flex>
                        </v-layout>
                        <v-layout v-if="verifyForm.noteTwelve">
                          <v-flex xs12 pa-2 style="border: #CC3D45 solid 1px">
                            <span>รายการแก้ไขเพิ่มเติม คู่มือผู้วิจัย (Investigator brochure):</span><br>
                            <span style="color: #CC3D45">{{verifyForm.noteTwelve}}</span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </div>
                  <!--แบบประวัติผู้วิจัย/ผู้ร่วม/ที่ปรึกษา (RF 10_2563) ส่งแบบแยก -->
<!--                  <div class="mb-8">-->
<!--                    <v-layout>-->
<!--                      <v-flex mb-2 pb-2 pl-8 style="text-align: left;">-->
<!--                        <span style="font-weight: bold;font-size: 16px;"-->
<!--                          >แบบประวัติผู้วิจัย/ผู้ร่วม/ที่ปรึกษา (RF 10_2563) ส่งแบบแยก</span-->
<!--                        >-->
<!--                      </v-flex>-->
<!--                    </v-layout>-->
<!--                    <v-layout class="justify-center">-->
<!--                      <v-flex shrink class="pt-3 font-weight-bold mr-3"-->
<!--                        >ไฟล์</v-flex-->
<!--                      >-->
<!--                      <v-flex xs6>-->
<!--                        <v-layout class="mb-2">-->
<!--                          <v-flex xs10 class="text-start">-->
<!--                            <v-file-input-->
<!--                              accept=".pdf"-->
<!--                              prepend-icon=""-->
<!--                              id="researcherProfile"-->
<!--                              :model="form.researcherProfile"-->
<!--                              outlined-->
<!--                              dense-->
<!--                              background-color="#FBF6F3"-->
<!--                              color="#3E7662"-->
<!--                              item-color="#3E7662"-->
<!--                              @change="-->
<!--                                setDataUploadData(-->
<!--                                  'researcherProfile',-->
<!--                                  $event-->
<!--                                )-->
<!--                              "-->
<!--                              hide-details-->
<!--                            ></v-file-input>-->
<!--                          </v-flex>-->
<!--                          <v-flex shrink pl-4 style="text-align: left">-->
<!--                            <v-btn-->
<!--                              color="#3E7662"-->
<!--                              dark-->
<!--                              @click="uploadFile('researcherProfile')"-->
<!--                            >-->
<!--                              <v-icon class="pr-3">cloud_download</v-icon>-->
<!--                              <span>Upload</span>-->
<!--                            </v-btn>-->
<!--                          </v-flex>-->
<!--                        </v-layout>-->

<!--                        <v-layout v-if="onHaveResearcherProfile">-->
<!--                          <v-flex-->
<!--                            class="text-start"-->
<!--                            style="font-size: 12px;color: #CC3D45"-->
<!--                          >-->
<!--                            <span>ชื่อไฟล์ที่อัพโหลด : </span>-->
<!--                            <span-->
<!--                              class="link__pdf"-->
<!--                              @click="openPDF('researcherProfile')"-->
<!--                            >-->
<!--                              {{ onHaveResearcherProfile }}-->
<!--                            </span>-->
<!--                            <v-icon v-if="form.researcherProfile" @click="cancelFile('researcherProfile','onHaveResearcherProfile')" style="cursor: pointer">mdi-close</v-icon>-->
<!--                          </v-flex>-->
<!--                        </v-layout>-->
<!--                        <v-layout v-if="verifyForm.noteThree">-->
<!--                          <v-flex xs12 pa-2 style="border: #CC3D45 solid 1px">-->
<!--                            <span>รายการแก้ไขเพิ่มเติม แบบประวัติผู้วิจัย/ผู้ร่วม/ที่ปรึกษา (RF 10_2563):</span><br>-->
<!--                            <span style="color: #CC3D45">{{verifyForm.noteThree}}</span>-->
<!--                          </v-flex>-->
<!--                        </v-layout>-->
<!--                      </v-flex>-->
<!--                    </v-layout>-->
<!--                  </div>-->
                </div>

                <div v-if="submissionType === '1'">
                  <!--แบบบันทึกข้อมูล (ถ้ามี) Clean-->
                  <div class="mb-8">
                    <v-layout>
                      <v-flex mb-2 pb-2 pl-8 style="text-align: left;">
                        <span style="font-weight: bold;font-size: 16px;"
                          >แบบบันทึกข้อมูล (ถ้ามี) (Clean Version)</span
                        >
                      </v-flex>
                    </v-layout>
                    <v-layout class="justify-center">
                      <v-flex shrink class="pt-3 font-weight-bold mr-3"
                        >ไฟล์</v-flex
                      >
                      <v-flex xs6>
                        <v-layout class="mb-2">
                          <v-flex xs10 class="text-start">
                            <v-file-input
                              accept=".pdf"
                              prepend-icon=""
                              id="dataRecordFromClean"
                              :model="form.dataRecordFromClean"
                              outlined
                              dense
                              background-color="#FBF6F3"
                              color="#3E7662"
                              item-color="#3E7662"
                              @change="
                                setDataUploadData('dataRecordFromClean', $event)
                              "
                              hide-details
                            ></v-file-input>
                          </v-flex>
                          <v-flex shrink pl-4 style="text-align: left">
                            <v-btn
                              color="#3E7662"
                              dark
                              @click="uploadFile('dataRecordFromClean')"
                            >
                              <v-icon class="pr-3">cloud_download</v-icon>
                              <span>Upload</span>
                            </v-btn>
                          </v-flex>
                        </v-layout>

                        <v-layout v-if="onHavedataRecordFromClean">
                          <v-flex
                            class="text-start"
                            style="font-size: 12px;color: #CC3D45"
                          >
                            <span>ชื่อไฟล์ที่อัพโหลด : </span>
                            <span
                              class="link__pdf"
                              @click="openPDF('dataRecordFromClean')"
                              >{{ onHavedataRecordFromClean }}</span
                            >
                            <v-icon v-if="form.dataRecordFromClean" @click="cancelFile('dataRecordFromClean','onHavedataRecordFromClean')" style="cursor: pointer">mdi-close</v-icon>
                          </v-flex>
                        </v-layout>
                        <v-layout v-if="verifyForm.noteSix">
                          <v-flex xs12 pa-2 style="border: #CC3D45 solid 1px">
                            <span>รายการแก้ไขเพิ่มเติม แบบบันทึกข้อมูล:</span><br>
                            <span style="color: #CC3D45">{{verifyForm.noteSix}}</span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </div>
                  <!--แบบบันทึกข้อมูล (ถ้ามี) Highlight-->
                  <div class="mb-8">
                    <v-layout>
                      <v-flex mb-2 pb-2 pl-8 style="text-align: left;">
                        <span style="font-weight: bold;font-size: 16px;"
                          >แบบบันทึกข้อมูล (ถ้ามี) (Highlight Version)</span
                        >
                      </v-flex>
                    </v-layout>
                    <v-layout class="justify-center">
                      <v-flex shrink class="pt-3 font-weight-bold mr-3"
                        >ไฟล์</v-flex
                      >
                      <v-flex xs6>
                        <v-layout class="mb-2">
                          <v-flex xs10 class="text-start">
                            <v-file-input
                              accept=".pdf"
                              prepend-icon=""
                              id="dataRecordFromHighlight"
                              :model="form.dataRecordFromHighlight"
                              outlined
                              dense
                              background-color="#FBF6F3"
                              color="#3E7662"
                              item-color="#3E7662"
                              @change="
                                setDataUploadData(
                                  'dataRecordFromHighlight',
                                  $event
                                )
                              "
                              hide-details
                            ></v-file-input>
                          </v-flex>
                          <v-flex shrink pl-4 style="text-align: left">
                            <v-btn
                              color="#3E7662"
                              dark
                              @click="uploadFile('dataRecordFromHighlight')"
                            >
                              <v-icon class="pr-3">cloud_download</v-icon>
                              <span>Upload</span>
                            </v-btn>
                          </v-flex>
                        </v-layout>

                        <v-layout v-if="onHavedataRecordFromHighlight">
                          <v-flex
                            class="text-start"
                            style="font-size: 12px;color: #CC3D45"
                          >
                            <span>ชื่อไฟล์ที่อัพโหลด : </span>
                            <span
                              class="link__pdf"
                              @click="openPDF('dataRecordFromHighlight')"
                              >{{ onHavedataRecordFromHighlight }}</span
                            >
                            <v-icon v-if="form.dataRecordFromHighlight" @click="cancelFile('dataRecordFromHighlight','onHavedataRecordFromHighlight')" style="cursor: pointer">mdi-close</v-icon>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </div>

                  <!--แบบสอบถามหรือสัมภาษณ์ (ถ้ามี) Clean-->
                  <div class="mb-8">
                    <v-layout>
                      <v-flex mb-2 pb-2 pl-8 style="text-align: left;">
                        <span style="font-weight: bold;font-size: 16px;"
                          >แบบสอบถามหรือสัมภาษณ์ (ถ้ามี) (Clean Version)</span
                        >
                      </v-flex>
                    </v-layout>
                    <v-layout class="justify-center">
                      <v-flex shrink class="pt-3 font-weight-bold mr-3"
                        >ไฟล์</v-flex
                      >
                      <v-flex xs6>
                        <v-layout class="mb-2">
                          <v-flex xs10 class="text-start">
                            <v-file-input
                              accept=".pdf"
                              prepend-icon=""
                              id="questionnaireInterviewClean"
                              :model="form.questionnaireInterviewClean"
                              outlined
                              dense
                              background-color="#FBF6F3"
                              color="#3E7662"
                              item-color="#3E7662"
                              @change="
                                setDataUploadData(
                                  'questionnaireInterviewClean',
                                  $event
                                )
                              "
                              hide-details
                            ></v-file-input>
                          </v-flex>
                          <v-flex shrink pl-4 style="text-align: left">
                            <v-btn
                              color="#3E7662"
                              dark
                              @click="uploadFile('questionnaireInterviewClean')"
                            >
                              <v-icon class="pr-3">cloud_download</v-icon>
                              <span>Upload</span>
                            </v-btn>
                          </v-flex>
                        </v-layout>

                        <v-layout v-if="onHaveQuestionnaireInterviewClean">
                          <v-flex
                            class="text-start"
                            style="font-size: 12px;color: #CC3D45"
                          >
                            <span>ชื่อไฟล์ที่อัพโหลด : </span>
                            <span
                              class="link__pdf"
                              @click="openPDF('questionnaireInterviewClean')"
                            >
                              {{ onHaveQuestionnaireInterviewClean }}
                            </span>
                            <v-icon v-if="form.questionnaireInterviewClean" @click="cancelFile('questionnaireInterviewClean','onHaveQuestionnaireInterviewClean')" style="cursor: pointer">mdi-close</v-icon>
                          </v-flex>
                        </v-layout>
                        <v-layout v-if="verifyForm.noteSeven">
                          <v-flex xs12 pa-2 style="border: #CC3D45 solid 1px">
                            <span>รายการแก้ไขเพิ่มเติม แบบสอบถามหรือสัมภาษณ์:</span><br>
                            <span style="color: #CC3D45">{{verifyForm.noteSeven}}</span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </div>
                  <!--แบบสอบถามหรือสัมภาษณ์ (ถ้ามี) Highlight-->
                  <div class="mb-8">
                    <v-layout>
                      <v-flex mb-2 pb-2 pl-8 style="text-align: left;">
                        <span style="font-weight: bold;font-size: 16px;"
                          >แบบสอบถามหรือสัมภาษณ์ (ถ้ามี) (Highlight
                          Verison)</span
                        >
                      </v-flex>
                    </v-layout>
                    <v-layout class="justify-center">
                      <v-flex shrink class="pt-3 font-weight-bold mr-3"
                        >ไฟล์</v-flex
                      >
                      <v-flex xs6>
                        <v-layout class="mb-2">
                          <v-flex xs10 class="text-start">
                            <v-file-input
                              accept=".pdf"
                              prepend-icon=""
                              id="questionnaireInterviewHighlight"
                              :model="form.questionnaireInterviewHighlight"
                              outlined
                              dense
                              background-color="#FBF6F3"
                              color="#3E7662"
                              item-color="#3E7662"
                              @change="
                                setDataUploadData(
                                  'questionnaireInterviewHighlight',
                                  $event
                                )
                              "
                              hide-details
                            ></v-file-input>
                          </v-flex>
                          <v-flex shrink pl-4 style="text-align: left">
                            <v-btn
                              color="#3E7662"
                              dark
                              @click="
                                uploadFile('questionnaireInterviewHighlight')
                              "
                            >
                              <v-icon class="pr-3">cloud_download</v-icon>
                              <span>Upload</span>
                            </v-btn>
                          </v-flex>
                        </v-layout>

                        <v-layout v-if="onHaveQuestionnaireInterviewHighlight">
                          <v-flex
                            class="text-start"
                            style="font-size: 12px;color: #CC3D45"
                          >
                            <span>ชื่อไฟล์ที่อัพโหลด : </span>
                            <span
                              class="link__pdf"
                              @click="
                                openPDF('questionnaireInterviewHighlight')
                              "
                            >
                              {{ onHaveQuestionnaireInterviewHighlight }}
                            </span>
                            <v-icon v-if="form.questionnaireInterviewHighlight" @click="cancelFile('questionnaireInterviewHighlight','onHaveQuestionnaireInterviewHighlight')" style="cursor: pointer">mdi-close</v-icon>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </div>

                  <!--สมุดบันทึก (diary) (ถ้ามี) Clean-->
                  <div class="mb-8">
                    <v-layout>
                      <v-flex mb-2 pb-2 pl-8 style="text-align: left;">
                        <span style="font-weight: bold;font-size: 16px;"
                          >สมุดบันทึก (diary) (ถ้ามี) (Clean Version)</span
                        >
                      </v-flex>
                    </v-layout>
                    <v-layout class="justify-center">
                      <v-flex shrink class="pt-3 font-weight-bold mr-3"
                        >ไฟล์</v-flex
                      >
                      <v-flex xs6>
                        <v-layout class="mb-2">
                          <v-flex xs10 class="text-start">
                            <v-file-input
                              accept=".pdf"
                              prepend-icon=""
                              id="diaryClean"
                              :model="form.diaryClean"
                              outlined
                              dense
                              background-color="#FBF6F3"
                              color="#3E7662"
                              item-color="#3E7662"
                              @change="setDataUploadData('diaryClean', $event)"
                              hide-details
                            ></v-file-input>
                          </v-flex>
                          <v-flex shrink pl-4 style="text-align: left">
                            <v-btn
                              color="#3E7662"
                              dark
                              @click="uploadFile('diaryClean')"
                            >
                              <v-icon class="pr-3">cloud_download</v-icon>
                              <span>Upload</span>
                            </v-btn>
                          </v-flex>
                        </v-layout>

                        <v-layout v-if="onHaveDiaryClean">
                          <v-flex
                            class="text-start"
                            style="font-size: 12px;color: #CC3D45"
                          >
                            <span>ชื่อไฟล์ที่อัพโหลด : </span>
                            <span
                              class="link__pdf"
                              @click="openPDF('diaryClean')"
                            >
                              {{ onHaveDiaryClean }}
                            </span>
                            <v-icon v-if="form.diaryClean" @click="cancelFile('diaryClean','onHaveDiaryClean')" style="cursor: pointer">mdi-close</v-icon>
                          </v-flex>
                        </v-layout>
                        <v-layout v-if="verifyForm.noteSeven">
                          <v-flex xs12 pa-2 style="border: #CC3D45 solid 1px">
                            <span>รายการแก้ไขเพิ่มเติม สมุดบันทึก (diary):</span><br>
                            <span style="color: #CC3D45">{{verifyForm.noteSeven}}</span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </div>
                  <!--สมุดบันทึก (diary) (ถ้ามี) Highlight-->
                  <div class="mb-8">
                    <v-layout>
                      <v-flex mb-2 pb-2 pl-8 style="text-align: left;">
                        <span style="font-weight: bold;font-size: 16px;"
                          >สมุดบันทึก (diary) (ถ้ามี) (Highlight Version)</span
                        >
                      </v-flex>
                    </v-layout>
                    <v-layout class="justify-center">
                      <v-flex shrink class="pt-3 font-weight-bold mr-3"
                        >ไฟล์</v-flex
                      >
                      <v-flex xs6>
                        <v-layout class="mb-2">
                          <v-flex xs10 class="text-start">
                            <v-file-input
                              accept=".pdf"
                              prepend-icon=""
                              id="diaryHighlight"
                              :model="form.diaryHighlight"
                              outlined
                              dense
                              background-color="#FBF6F3"
                              color="#3E7662"
                              item-color="#3E7662"
                              @change="
                                setDataUploadData('diaryHighlight', $event)
                              "
                              hide-details
                            ></v-file-input>
                          </v-flex>
                          <v-flex shrink pl-4 style="text-align: left">
                            <v-btn
                              color="#3E7662"
                              dark
                              @click="uploadFile('diaryHighlight')"
                            >
                              <v-icon class="pr-3">cloud_download</v-icon>
                              <span>Upload</span>
                            </v-btn>
                          </v-flex>
                        </v-layout>

                        <v-layout v-if="onHaveDiaryHighlight">
                          <v-flex
                            class="text-start"
                            style="font-size: 12px;color: #CC3D45"
                          >
                            <span>ชื่อไฟล์ที่อัพโหลด : </span>
                            <span
                              class="link__pdf"
                              @click="openPDF('diaryHighlight')"
                            >
                              {{ onHaveDiaryHighlight }}
                            </span>
                            <v-icon v-if="form.diaryHighlight" @click="cancelFile('diaryHighlight','onHaveDiaryHighlight')" style="cursor: pointer">mdi-close</v-icon>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </div>

                  <!--เอกสารชี้แจงข้อมูลผู้ร่วมโครงการวิจัย /หนังสือแสดงเจตนา Clean Version-->
                  <div class="mb-8">
                    <v-layout>
                      <v-flex mb-2 pb-2 pl-8 style="text-align: left;">
                        <span style="font-weight: bold;font-size: 16px;"
                        >เอกสารชี้แจงข้อมูลผู้ร่วมโครงการวิจัย
                          /หนังสือแสดงเจตนา Clean Version (RF 09_1_2563)</span
                        >
                      </v-flex>
                    </v-layout>
                    <v-layout class="justify-center">
                      <v-flex shrink class="pt-3 font-weight-bold mr-3"
                      >ไฟล์</v-flex
                      >
                      <v-flex xs6>
                        <v-layout class="mb-2">
                          <v-flex xs10 class="text-start">
                            <v-file-input
                                accept=".pdf"
                                :disabled="!valid"
                                prepend-icon=""
                                id="documentsExplainClean"
                                :model="form.documentsExplainClean"
                                outlined
                                dense
                                background-color="#FBF6F3"
                                color="#3E7662"
                                item-color="#3E7662"
                                @change="
                                setDataUploadData('documentsExplainClean', $event)
                              "
                                hide-details
                            ></v-file-input>
                          </v-flex>
                          <v-flex shrink pl-4 style="text-align: left">
                            <v-btn
                                color="#3E7662"
                                dark
                                @click="uploadFile('documentsExplainClean')"
                            >
                              <v-icon class="pr-3">cloud_download</v-icon>
                              <span>Upload</span>
                            </v-btn>
                          </v-flex>
                        </v-layout>

                        <v-layout v-if="onHaveDocumentsExplainClean">
                          <v-flex
                              class="text-start"
                              style="font-size: 12px;color: #CC3D45"
                          >
                            <span>ชื่อไฟล์ที่อัพโหลด : </span>
                            <span
                                class="link__pdf"
                                @click="openPDF('documentsExplainClean')"
                            >
                              {{ onHaveDocumentsExplainClean }}
                            </span>
                            <v-icon v-if="form.documentsExplainClean" @click="cancelFile('documentsExplainClean','onHaveDocumentsExplainClean')" style="cursor: pointer">mdi-close</v-icon>
                          </v-flex>
                        </v-layout>
                        <v-layout v-if="verifyForm.noteEight">
                          <v-flex xs12 pa-2 style="border: #CC3D45 solid 1px">
                            <span>รายการแก้ไขเพิ่มเติม เอกสารชี้แจงข้อมูลผู้ร่วมโครงการวิจัย/หนังสือแสดงเจตนา (RF 09_1_2563):</span><br>
                            <span style="color: #CC3D45">{{verifyForm.noteEight}}</span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </div>
                  <!--เอกสารชี้แจงข้อมูลผู้ร่วมโครงการวิจัย /หนังสือแสดงเจตนา Highlight Version-->
                  <div class="mb-8">
                    <v-layout>
                      <v-flex mb-2 pb-2 pl-8 style="text-align: left;">
                        <span style="font-weight: bold;font-size: 16px;"
                          >เอกสารชี้แจงข้อมูลผู้ร่วมโครงการวิจัย
                          /หนังสือแสดงเจตนา Highlight Version (RF 09_1_2563)</span
                        >
                      </v-flex>
                    </v-layout>
                    <v-layout class="justify-center">
                      <v-flex shrink class="pt-3 font-weight-bold mr-3"
                        >ไฟล์</v-flex
                      >
                      <v-flex xs6>
                        <v-layout class="mb-2">
                          <v-flex xs10 class="text-start">
                            <v-file-input
                              accept=".pdf"
                              :disabled="!valid"
                              prepend-icon=""
                              id="documentsExplainHighlight"
                              :model="form.documentsExplainHighlight"
                              outlined
                              dense
                              background-color="#FBF6F3"
                              color="#3E7662"
                              item-color="#3E7662"
                              @change="
                                setDataUploadData('documentsExplainHighlight', $event)
                              "
                              hide-details
                            ></v-file-input>
                          </v-flex>
                          <v-flex shrink pl-4 style="text-align: left">
                            <v-btn
                              color="#3E7662"
                              dark
                              @click="uploadFile('documentsExplainHighlight')"
                            >
                              <v-icon class="pr-3">cloud_download</v-icon>
                              <span>Upload</span>
                            </v-btn>
                          </v-flex>
                        </v-layout>

                        <v-layout v-if="onHaveDocumentsExplainHighlight">
                          <v-flex
                            class="text-start"
                            style="font-size: 12px;color: #CC3D45"
                          >
                            <span>ชื่อไฟล์ที่อัพโหลด : </span>
                            <span
                              class="link__pdf"
                              @click="openPDF('documentsExplainHighlight')"
                            >
                              {{ onHaveDocumentsExplainHighlight }}
                            </span>
                            <v-icon v-if="form.documentsExplainHighlight" @click="cancelFile('documentsExplainHighlight','onHaveDocumentsExplainHighlight')" style="cursor: pointer">mdi-close</v-icon>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </div>

                  <!--เอกสารอื่นๆ ได้แก่ โฆษณา สิ่งตีพิมพ์ บทความ สื่อ เช่น วิดีโอ ซีดี เทป-->
                  <div class="mb-8">
                    <v-layout>
                      <v-flex mb-2 pb-2 pl-8 style="text-align: left;">
                        <span style="font-weight: bold;font-size: 16px;"
                          >เอกสารอื่นๆ ได้แก่ โฆษณา สิ่งตีพิมพ์ บทความ สื่อ เช่น
                          วิดีโอ ซีดี เทป</span
                        >
                      </v-flex>
                    </v-layout>
                    <v-layout class="justify-center">
                      <v-flex shrink class="pt-3 font-weight-bold mr-3"
                        >ไฟล์</v-flex
                      >
                      <v-flex xs6>
                        <v-layout class="mb-2">
                          <v-flex xs10 class="text-start">
                            <v-file-input
                              accept=".pdf"
                              prepend-icon=""
                              id="otherDocuments2"
                              :model="form.otherDocuments2"
                              outlined
                              dense
                              background-color="#FBF6F3"
                              color="#3E7662"
                              item-color="#3E7662"
                              @change="
                                setDataUploadData('otherDocuments2', $event)
                              "
                              hide-details
                            ></v-file-input>
                          </v-flex>
                          <v-flex shrink pl-4 style="text-align: left">
                            <v-btn
                              color="#3E7662"
                              dark
                              @click="uploadFile('otherDocuments2')"
                            >
                              <v-icon class="pr-3">cloud_download</v-icon>
                              <span>Upload</span>
                            </v-btn>
                          </v-flex>
                        </v-layout>

                        <v-layout v-if="onHaveOtherDocuments2">
                          <v-flex
                            class="text-start"
                            style="font-size: 12px;color: #CC3D45"
                          >
                            <span>ชื่อไฟล์ที่อัพโหลด : </span>
                            <span
                              class="link__pdf"
                              @click="openPDF('otherDocuments2')"
                            >
                              {{ onHaveOtherDocuments2 }}
                            </span>
                            <v-icon v-if="form.otherDocuments2" @click="cancelFile('otherDocuments2','onHaveOtherDocuments2')" style="cursor: pointer">mdi-close</v-icon>
                          </v-flex>
                        </v-layout>
                        <v-layout v-if="verifyForm.noteNine">
                          <v-flex xs12 pa-2 style="border: #CC3D45 solid 1px">
                            <span>รายการแก้ไขเพิ่มเติม เอกสารอื่นๆ ได้แก่ โฆษณา สิ่งตีพิมพ์ บทความ สื่อ เช่น วิดีโอ ซีดี เทป:</span><br>
                            <span style="color: #CC3D45">{{verifyForm.noteNine}}</span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </div>
                  <!--สำเนาหนังสือรับรองว่า ได้ผ่านการสอบโครงร่างการวิจัยจากคณะ /สถาบันแล้ว-->
                  <div class="mb-8">
                    <v-layout>
                      <v-flex mb-2 pb-2 pl-8 style="text-align: left;">
                        <span style="font-weight: bold;font-size: 16px;"
                          >สำเนาหนังสือรับรองว่า
                          ได้ผ่านการสอบโครงร่างการวิจัยจากคณะ /สถาบันแล้ว</span
                        >
                      </v-flex>
                    </v-layout>
                    <v-layout class="justify-center">
                      <v-flex shrink class="pt-3 font-weight-bold mr-3"
                        >ไฟล์</v-flex
                      >
                      <v-flex xs6>
                        <v-layout class="mb-2">
                          <v-flex xs10 class="text-start">
                            <v-file-input
                              accept=".pdf"
                              prepend-icon=""
                              id="certificate2"
                              :model="form.certificate2"
                              outlined
                              dense
                              background-color="#FBF6F3"
                              color="#3E7662"
                              item-color="#3E7662"
                              @change="
                                setDataUploadData('certificate2', $event)
                              "
                              hide-details
                            ></v-file-input>
                          </v-flex>
                          <v-flex shrink pl-4 style="text-align: left">
                            <v-btn
                              color="#3E7662"
                              dark
                              @click="uploadFile('certificate2')"
                            >
                              <v-icon class="pr-3">cloud_download</v-icon>
                              <span>Upload</span>
                            </v-btn>
                          </v-flex>
                        </v-layout>

                        <v-layout v-if="onHaveCertificate2">
                          <v-flex
                            class="text-start"
                            style="font-size: 12px;color: #CC3D45"
                          >
                            <span>ชื่อไฟล์ที่อัพโหลด : </span>
                            <span
                              class="link__pdf"
                              @click="openPDF('certificate2')"
                            >
                              {{ onHaveCertificate2 }}
                            </span>
                            <v-icon v-if="form.certificate2" @click="cancelFile('certificate2','onHaveCertificate2')" style="cursor: pointer">mdi-close</v-icon>
                          </v-flex>
                        </v-layout>
                        <v-layout v-if="verifyForm.noteEleven">
                          <v-flex xs12 pa-2 style="border: #CC3D45 solid 1px">
                            <span>รายการแก้ไขเพิ่มเติม สำเนาหนังสือรับรองว่า ได้ผ่านการสอบโครงร่างการวิจัยจากคณะ /สถาบันแล้ว:</span><br>
                            <span style="color: #CC3D45">{{verifyForm.noteEleven}}</span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </div>
                  <!--คู่มือผู้วิจัย (Investigator brochure)-->
                  <div class="mb-8">
                    <v-layout>
                      <v-flex mb-2 pb-2 pl-8 style="text-align: left;">
                        <span style="font-weight: bold;font-size: 16px;"
                          >คู่มือผู้วิจัย (Investigator brochure)</span
                        >
                      </v-flex>
                    </v-layout>
                    <v-layout class="justify-center">
                      <v-flex shrink class="pt-3 font-weight-bold mr-3"
                        >ไฟล์</v-flex
                      >
                      <v-flex xs6>
                        <v-layout class="mb-2">
                          <v-flex xs10 class="text-start">
                            <v-file-input
                              accept=".pdf"
                              prepend-icon=""
                              id="investigatorBrochure2"
                              :model="form.investigatorBrochure2"
                              outlined
                              dense
                              background-color="#FBF6F3"
                              color="#3E7662"
                              item-color="#3E7662"
                              @change="
                                setDataUploadData(
                                  'investigatorBrochure2',
                                  $event
                                )
                              "
                              hide-details
                            ></v-file-input>
                          </v-flex>
                          <v-flex shrink pl-4 style="text-align: left">
                            <v-btn
                              color="#3E7662"
                              dark
                              @click="uploadFile('investigatorBrochure2')"
                            >
                              <v-icon class="pr-3">cloud_download</v-icon>
                              <span>Upload</span>
                            </v-btn>
                          </v-flex>
                        </v-layout>

                        <v-layout v-if="onHaveInvestigatorBrochure2">
                          <v-flex
                            class="text-start"
                            style="font-size: 12px;color: #CC3D45"
                          >
                            <span>ชื่อไฟล์ที่อัพโหลด : </span>
                            <span
                              class="link__pdf"
                              @click="openPDF('investigatorBrochure2')"
                            >
                              {{ onHaveInvestigatorBrochure2 }}
                            </span>
                            <v-icon v-if="form.investigatorBrochure2" @click="cancelFile('investigatorBrochure2','onHaveInvestigatorBrochure2')" style="cursor: pointer">mdi-close</v-icon>
                          </v-flex>
                        </v-layout>
                        <v-layout v-if="verifyForm.noteTwelve">
                          <v-flex xs12 pa-2 style="border: #CC3D45 solid 1px">
                            <span>รายการแก้ไขเพิ่มเติม คู่มือผู้วิจัย (Investigator brochure):</span><br>
                            <span style="color: #CC3D45">{{verifyForm.noteTwelve}}</span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </div>
<!--                  <div class="mb-8">-->
<!--                    <v-layout>-->
<!--                      <v-flex mb-2 pb-2 pl-8 style="text-align: left;">-->
<!--                        <span style="font-weight: bold;font-size: 16px;"-->
<!--                        >แบบประวัติผู้วิจัย/ผู้ร่วม/ที่ปรึกษา (RF 10_2563) ส่งแบบแยก</span-->
<!--                        >-->
<!--                      </v-flex>-->
<!--                    </v-layout>-->
<!--                    <v-layout class="justify-center">-->
<!--                      <v-flex shrink class="pt-3 font-weight-bold mr-3"-->
<!--                      >ไฟล์</v-flex-->
<!--                      >-->
<!--                      <v-flex xs6>-->
<!--                        <v-layout class="mb-2">-->
<!--                          <v-flex xs10 class="text-start">-->
<!--                            <v-file-input-->
<!--                                accept=".pdf"-->
<!--                                prepend-icon=""-->
<!--                                id="researcherProfile2"-->
<!--                                :model="form.researcherProfile2"-->
<!--                                outlined-->
<!--                                dense-->
<!--                                background-color="#FBF6F3"-->
<!--                                color="#3E7662"-->
<!--                                item-color="#3E7662"-->
<!--                                @change="-->
<!--                                setDataUploadData(-->
<!--                                  'researcherProfile2',-->
<!--                                  $event-->
<!--                                )-->
<!--                              "-->
<!--                                hide-details-->
<!--                            ></v-file-input>-->
<!--                          </v-flex>-->
<!--                          <v-flex shrink pl-4 style="text-align: left">-->
<!--                            <v-btn-->
<!--                                color="#3E7662"-->
<!--                                dark-->
<!--                                @click="uploadFile('researcherProfile2')"-->
<!--                            >-->
<!--                              <v-icon class="pr-3">cloud_download</v-icon>-->
<!--                              <span>Upload</span>-->
<!--                            </v-btn>-->
<!--                          </v-flex>-->
<!--                        </v-layout>-->

<!--                        <v-layout v-if="onHaveResearcherProfile2">-->
<!--                          <v-flex-->
<!--                              class="text-start"-->
<!--                              style="font-size: 12px;color: #CC3D45"-->
<!--                          >-->
<!--                            <span>ชื่อไฟล์ที่อัพโหลด : </span>-->
<!--                            <span-->
<!--                                class="link__pdf"-->
<!--                                @click="openPDF('researcherProfile2')"-->
<!--                            >-->
<!--                              {{ onHaveResearcherProfile2 }}-->
<!--                            </span>-->
<!--                            <v-icon v-if="form.researcherProfile2" @click="cancelFile('researcherProfile2','onHaveResearcherProfile2')" style="cursor: pointer">mdi-close</v-icon>-->
<!--                          </v-flex>-->
<!--                        </v-layout>-->
<!--                      </v-flex>-->
<!--                    </v-layout>-->
<!--                  </div>-->
                </div>

                <v-layout mt-10>
                  <v-flex xs6 style="text-align: left;font-weight: bold">
                    <span
                      style="font-weight: bold;font-size: 14px;color: #3e7662"
                      >เอกสารที่เกี่ยวข้อง
                      ซึ่งคิดว่าอาจจะเป็นประโยชน์ในการพิจารณาโครงการวิจัย</span
                    >
                  </v-flex>
                </v-layout>

                <!-- Dynamic Fields -->
                <v-layout class="justify-center">
                  <v-flex xs11>
                    <v-layout>
                      <v-flex xs5></v-flex>
                      <v-flex xs6 style="text-align: right;">
                        <v-btn
                          min-width="5px"
                          small
                          dark
                          style="background-color: #3E7662;padding: 5px"
                          @click="add"
                        >
                          <v-icon small>add</v-icon>
                        </v-btn>
                      </v-flex>
                      <v-flex xs1 ml-2></v-flex>
                    </v-layout>

                    <v-layout
                      v-for="(item, i) in form.relatedDocuments"
                      :key="i"
                      mb-6
                      mt-6
                      class="justify-center"
                    >
                      <v-flex xs5>
                        <v-layout>
                          <v-flex shrink mr-3 class="pt-2">
                            <span class="font-weight-bold">ชื่อเอกสาร TH</span>
                          </v-flex>
                          <v-flex>
                            <v-text-field
                              prepend-icon=""
                              v-model="item.name"
                              outlined
                              dense
                              background-color="#FBF6F3"
                              color="#3E7662"
                              item-color="#3E7662"
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                        <v-layout>
                          <v-flex shrink mr-3 class="pt-2">
                            <span class="font-weight-bold">ชื่อเอกสาร EN</span>
                          </v-flex>
                          <v-flex>
                            <v-text-field
                              prepend-icon=""
                              v-model="item.name_en"
                              outlined
                              dense
                              background-color="#FBF6F3"
                              color="#3E7662"
                              item-color="#3E7662"
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                      </v-flex>

                      <v-flex xs6 pl-4 class="align-center">
                        <v-layout class="justify-center">
                          <v-flex shrink class="pt-3 font-weight-bold mr-3"
                            >ไฟล์</v-flex
                          >
                          <v-flex>
                            <v-layout class="mb-2">
                              <v-flex xs10 class="text-start">
                                <v-file-input
                                  accept=".pdf"
                                  prepend-icon=""
                                  :id="'file' + i"
                                  :model="item.file"
                                  outlined
                                  dense
                                  background-color="#FBF6F3"
                                  color="#3E7662"
                                  item-color="#3E7662"
                                  @change="setDataUploadDynamicFile($event, i)"
                                  hide-details
                                ></v-file-input>
                              </v-flex>
                              <v-flex
                                shrink
                                pl-4
                                style="text-align: left"
                                class="d-flex align-center"
                              >
                                <v-btn
                                  color="#3E7662"
                                  dark
                                  @click="uploadDynamicFile('file' + i)"
                                >
                                  <v-icon class="pr-3">cloud_download</v-icon>
                                  <span>Upload</span>
                                </v-btn>
                              </v-flex>
                            </v-layout>

                            <v-layout v-if="onHaveDynamicFile[i]">
                              <v-flex
                                class="text-start"
                                style="font-size: 12px;color: #CC3D45"
                              >
                                <span>ชื่อไฟล์ที่อัพโหลด : </span>
                                <span
                                  class="link__pdf"
                                  @click="
                                    openPDFDynamic(
                                      onHaveDynamicFile[i].filePath
                                    )
                                  "
                                >
                                  {{ onHaveDynamicFile[i].file }}
                                </span>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-flex>

                      <v-flex xs1 pt-2 ml-2>
                        <v-btn
                          min-width="5px"
                          small
                          dark
                          style="background-color: #CC3D45;padding: 5px"
                          @click="remove(i)"
                        >
                          <v-icon small>remove</v-icon>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout>
      <v-flex mb-12 style="padding: 0 5% 2% 5%; text-align: right;">
        <v-layout>
          <v-flex>
            <v-layout>
              <v-flex shrink class="text-start">
                <v-btn
                  style="color: #3E7662"
                  class="mr-4"
                  depressed
                  color="#ffffff"
                  @click="$router.push('/form-research-1')"
                >
                  <span class="font-weight-bold">ย้อนกลับ</span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex>
            <v-layout>
              <v-spacer></v-spacer>
              <v-flex shrink class="text-end">
                <v-btn
                  outlined
                  depressed
                  style="background-color: white"
                  color="#3E7662"
                  @click="saveResearch('saveResearch')"
                >
                  <v-icon class="mr-3">save_alt</v-icon>
                  <span style="color: #3e7662;font-weight: bold;"
                    >บันทึกโครงร่างงานวิจัย</span
                  >
                </v-btn>
              </v-flex>
              <v-flex shrink class="text-end ml-4">
                <v-btn
                  depressed
                  :dark="valid"
                  color="#3E7662"
                  @click="saveResearch('sendResearch')"
                  :disabled="!valid"
                >
                  <v-icon class="mr-3">unarchive</v-icon>
                  <span>ส่งโครงร่างงานวิจัย</span>
                </v-btn>

                <!--                                <v-btn-->
                <!--                                        :disabled="!valid"-->
                <!--                                        color="success"-->
                <!--                                        class="mr-4"-->
                <!--                                        @click="validate"-->
                <!--                                >-->
                <!--                                    Validate-->
                <!--                                </v-btn>-->
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <loading
      color="#527281"
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
    ></loading>
  </v-content>
</template>

<script>
import Swal from "sweetalert2";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { mapState } from "vuex";
import axios from "axios";
import { domain } from "../../services/Constants";

export default {
  name: "FormResearch2",
  components: {
    Loading,
  },
  computed: {
    ...mapState({
      researchID: (state) => state.FormResearch.researchID,
      lastRefID: (state) => state.FormResearch.lastRefID,
      remainingDate: (state) => state.Common.remainingDate,
    }),
  },
  data: () => ({
    isLoading: false,
    fullPage: true,
    form: {
      dataRecordFrom: null,
      questionnaireInterview: null,
      diary: null,
      documentsExplain: null,
      otherDocuments: null,
      certificate: null,
      investigatorBrochure: null,
      researcherProfile: null,
      submitReportForConsider: null,
      dataRecordFromClean: null,
      questionnaireInterviewClean: null,
      diaryClean: null,
      dataRecordFromHighlight: null,
      questionnaireInterviewHighlight: null,
      diaryHighlight: null,
      documentsExplainClean: null,
      documentsExplainHighlight: null,
      otherDocuments2: null,
      certificate2: null,
      investigatorBrochure2: null,
      researcherProfile2: null,
      submitReportForConsiderClean: null,
      submitReportForConsiderHighlight: null,
      relatedDocuments: [
        {
          name: "",
          name_en: "",
          file: null,
        },
      ],
    },
    relatedDocuments_temp: [],
    onHaveDiaryClean: "",
    onHavedataRecordFromHighlight: "",
    onHaveQuestionnaireInterviewHighlight: "",
    onHaveDiaryHighlight: "",
    onHaveDocumentsExplainClean: "",
    onHaveDocumentsExplainHighlight: "",
    onHaveOtherDocuments2: "",
    onHaveCertificate2: "",
    onHaveInvestigatorBrochure2: "",
    onHaveresearcherProfile2: "",
    onHavesubmitReportForConsiderClean: "",
    onHavesubmitReportForConsiderHighlight: "",
    dataRecordFromCleanLink: "",
    questionnaireInterviewCleanLink: "",
    diaryCleanLink: "",
    dataRecordFromHighlightLink: "",
    questionnaireInterviewHighlightLink: "",
    diaryHighlightLink: "",
    documentsExplainCleanLink: "",
    documentsExplainHighlightLink: "",
    otherDocuments2Link: "",
    certificate2Link: "",
    investigatorBrochure2Link: "",
    researcherProfile2Link: "",
    submitReportForConsiderCleanLink: "",
    submitReportForConsiderHighlightLink: "",
    onHavedataRecordFrom: "",
    onHaveQuestionnaireInterview: "",
    onHaveDiary: "",
    onHaveDocumentsExplain: "",
    onHaveOtherDocuments: "",
    onHaveCertificate: "",
    onHaveInvestigatorBrochure: "",
    onHaveResearcherProfile: "",
    onHaveSubmitReportForConsider: "",
    onHaveDynamicFile: [],
    form_api: {},

    dataRecordFromLink: "",
    questionnaireInterviewLink: "",
    diaryLink: "",
    documentsExplainLink: "",
    otherDocumentsLink: "",
    certificateLink: "",
    investigatorBrochureLink: "",
    researcherProfileLink: "",
    submitReportForConsiderLink: "",

    isManual: '0',
    name: "",
    researchVersion: "",
    researchStatus: "",
    submissionType: "0",
    valid: true,
    fieldRules: [(v) => !!v || "Field is required"],
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
    ],
    verifyForm: []
  }),
  async created() {
    if (this.lastRefID !== 0) {
      await this.getFormResearch2();
      await this.checkSubmission();
      await this.getVerifyForm();
    }
    if (this.remainingDate === 0) {
      this.$router.push("/profile");
    }
  },
  methods: {
    getFormResearch2() {
      let data = {
        researchID: this.researchID,
        researchPage: 2,
      };
      this.$store
        .dispatch("FormResearch/getFormResearch1", data)
        .then((res) => {
          this.form.dataRecordFrom =
            res.dataRecordFrom == null ? "" : res.dataRecordFrom;
          this.isManual = res.isManual;
          this.form.questionnaireInterview =
            res.questionnaireInterview == null
              ? ""
              : res.questionnaireInterview;
          this.form.diary = res.diary == null ? "" : res.diary;
          this.form.documentsExplain =
            res.documentsExplain == null ? "" : res.documentsExplain;
          this.form.otherDocuments =
            res.otherDocuments == null ? "" : res.otherDocuments;
          this.form.certificate =
            res.certificate == null ? "" : res.certificate;
          this.form.investigatorBrochure =
            res.investigatorBrochure == null ? "" : res.investigatorBrochure;
          this.form.researcherProfile =
            res.researcherProfile == null ? "" : res.researcherProfile;
          this.form.submitReportForConsider =
            res.submitReportForConsider == null ? "" : res.submitReportForConsider;
          this.form.dataRecordFromClean =
            res.dataRecordFromClean == null ? "" : res.dataRecordFromClean;
          this.form.questionnaireInterviewClean =
            res.questionnaireInterviewClean == null
              ? ""
              : res.questionnaireInterviewClean;
          this.form.diaryClean = res.diaryClean == null ? "" : res.diaryClean;
          this.form.dataRecordFromHighlight =
            res.dataRecordFromHighlight == null
              ? ""
              : res.dataRecordFromHighlight;
          this.form.questionnaireInterviewHighlight =
            res.questionnaireInterviewHighlight == null
              ? ""
              : res.questionnaireInterviewHighlight;
          this.form.diaryHighlight =
            res.diaryHighlight == null ? "" : res.diaryHighlight;
          this.form.documentsExplainClean =
            res.documentsExplainClean == null ? "" : res.documentsExplainClean;
          this.form.documentsExplainHighlight =
            res.documentsExplainHighlight == null ? "" : res.documentsExplainHighlight;
          this.form.otherDocuments2 =
            res.otherDocuments2 == null ? "" : res.otherDocuments2;
          this.form.certificate2 =
            res.certificate2 == null ? "" : res.certificate2;
          this.form.investigatorBrochure2 =
            res.investigatorBrochure2 == null ? "" : res.investigatorBrochure2;
          this.form.researcherProfile2 =
            res.researcherProfile2 == null ? "" : res.researcherProfile2;
          this.form.submitReportForConsiderClean =
            res.submitReportForConsiderClean == null ? "" : res.submitReportForConsiderClean;
          this.form.submitReportForConsiderHighlight =
            res.submitReportForConsiderHighlight == null ? "" : res.submitReportForConsiderHighlight;
          this.onHavedataRecordFrom =
            res.dataRecordFrom == null ? "" : res.dataRecordFrom;
          this.onHaveQuestionnaireInterview =
            res.questionnaireInterview == null
              ? ""
              : res.questionnaireInterview;
          this.onHaveDiary = res.diary == null ? "" : res.diary;
          this.onHaveDocumentsExplain =
            res.documentsExplain == null ? "" : res.documentsExplain;
          this.onHaveOtherDocuments =
            res.otherDocuments == null ? "" : res.otherDocuments;
          this.onHaveCertificate =
            res.certificate == null ? "" : res.certificate;
          this.onHaveInvestigatorBrochure =
            res.investigatorBrochure == null ? "" : res.investigatorBrochure;
          this.onHaveResearcherProfile =
            res.researcherProfile == null ? "" : res.researcherProfile;
          this.onHaveSubmitReportForConsider =
            res.submitReportForConsider == null ? "" : res.submitReportForConsider;
          this.onHavedataRecordFromClean =
            res.dataRecordFromClean == null ? "" : res.dataRecordFromClean;
          this.onHaveQuestionnaireInterviewClean =
            res.questionnaireInterviewClean == null
              ? ""
              : res.questionnaireInterviewClean;
          this.onHaveDiaryClean = res.diaryClean == null ? "" : res.diaryClean;
          this.onHavedataRecordFromHighlight =
            res.dataRecordFromHighlight == null
              ? ""
              : res.dataRecordFromHighlight;
          this.onHaveQuestionnaireInterviewHighlight =
            res.questionnaireInterviewHighlight == null
              ? ""
              : res.questionnaireInterviewHighlight;
          this.onHaveDiaryHighlight =
            res.diaryHighlight == null ? "" : res.diaryHighlight;
          this.onHaveDocumentsExplainClean =
            res.documentsExplainClean == null ? "" : res.documentsExplainClean;
          this.onHaveDocumentsExplainHighlight =
            res.documentsExplainHighlight == null ? "" : res.documentsExplainHighlight;
          this.onHaveOtherDocuments2 =
            res.otherDocuments2 == null ? "" : res.otherDocuments2;
          this.onHaveCertificate2 =
            res.certificate2 == null ? "" : res.certificate2;
          this.onHaveInvestigatorBrochure2 =
            res.investigatorBrochure2 == null ? "" : res.investigatorBrochure2;
          this.onHaveResearcherProfile2 =
            res.researcherProfile2 == null ? "" : res.researcherProfile2;
          this.onHavesubmitReportForConsiderClean =
            res.submitReportForConsiderClean == null ? "" : res.submitReportForConsiderClean;
          this.onHavesubmitReportForConsiderHighlight =
            res.submitReportForConsiderHighlight == null ? "" : res.submitReportForConsiderHighlight;
          this.dataRecordFromLink =
            res.dataRecordFromPath == null ? "" : res.dataRecordFromPath;
          this.questionnaireInterviewLink =
            res.questionnaireInterviewPath == null
              ? ""
              : res.questionnaireInterviewPath;
          this.diaryLink =
            res.diaryPath == null
              ? ""
              : res.diaryPath == null
              ? ""
              : res.diaryPath;
          this.documentsExplainLink =
            res.documentsExplainPath == null ? "" : res.documentsExplainPath;
          this.otherDocumentsLink =
            res.otherDocumentsPath == null ? "" : res.otherDocumentsPath;
          this.certificateLink =
            res.certificatePath == null ? "" : res.certificatePath;
          this.investigatorBrochureLink =
            res.investigatorBrochurePath == null
              ? ""
              : res.investigatorBrochurePath;
          this.researcherProfileLink =
            res.researcherProfilePath == null
              ? ""
              : res.researcherProfilePath;
          this.researcherProfileLink =
            res.submitReportForConsiderPath == null
              ? ""
              : res.submitReportForConsiderPath;
          this.submitReportForConsiderLink =
            res.dataRecordFromCleanPath == null
              ? ""
              : res.dataRecordFromCleanPath;
          this.questionnaireInterviewCleanLink =
            res.questionnaireInterviewCleanPath == null
              ? ""
              : res.questionnaireInterviewCleanPath;
          this.diaryCleanLink =
            res.diaryCleanPath == null ? "" : res.diaryCleanPath;
          this.dataRecordFromHighlightLink =
            res.dataRecordFromHighlightPath == null
              ? ""
              : res.dataRecordFromHighlightPath;
          this.questionnaireInterviewHighlightLink =
            res.questionnaireInterviewHighlightPath == null
              ? ""
              : res.questionnaireInterviewHighlightPath;
          this.diaryHighlightLink =
            res.diaryHighlightPath == null ? "" : res.diaryHighlightPath;
          this.documentsExplainCleanLink =
            res.documentsExplainCleanPath == null ? "" : res.documentsExplainCleanPath;
          this.documentsExplainHighlightLink =
            res.documentsExplainHighlightPath == null ? "" : res.documentsExplainHighlightPath;
          this.otherDocuments2Link =
            res.otherDocuments2Path == null ? "" : res.otherDocuments2Path;
          this.certificate2Link =
            res.certificate2Path == null ? "" : res.certificate2Path;
          this.investigatorBrochure2Link =
            res.investigatorBrochure2Path == null
              ? ""
              : res.investigatorBrochure2Path;
          this.researcherProfile2Link =
            res.researcherProfile2Path == null
              ? ""
              : res.researcherProfile2Path;
          this.submitReportForConsiderCleanLink =
            res.submitReportForConsiderCleanPath == null
              ? ""
              : res.submitReportForConsiderCleanPath;
          this.submitReportForConsiderHighlightLink =
            res.submitReportForConsiderHighlightPath == null
              ? ""
              : res.submitReportForConsiderHighlightPath;
          this.researchVersion = res.version;
          this.researchStatus = res.status;
          if (res.relatedDocuments) {
            res.relatedDocuments.forEach((data) => {
              this.onHaveDynamicFile.push({
                name: data.name == null ? "" : data.name,
                name_en: data.name_en == null ? "" : data.name_en,
                file: data.file == null ? "" : data.file,
                filePath: data.filePath == null ? "" : data.filePath,
              });
            });
            this.form.relatedDocuments = [];
            res.relatedDocuments.forEach((data) => {
              this.form.relatedDocuments.push({
                name: data.name == null ? "" : data.name,
                name_en: data.name_en == null ? "" : data.name_en,
                file: data.file == null ? "" : data.file,
                filePath: data.filePath == null ? "" : data.filePath,
              });
            });
          }
          this.checkSubmission();
        });
    },
    checkSubmission() {
      if(this.isManual == 1){
        this.submissionType = "1"
      }else if (
        this.researchVersion > 1 ||
        this.researchStatus === "ปรับปรุงแก้ไขเพื่อรับรอง" ||
        this.researchStatus === "ปรับปรุงแก้ไขเพื่อพิจารณาใหม่"
      ) {
        this.submissionType = "1";
      } else if (
        this.$route.query.id === "create" ||
        this.researchVersion === 1
      ) {
        this.submissionType = "0";
      } else {
        this.submissionType = "";
      }
    },
      cancelFile(name,pdfName){
          this.form[name] = '';
          this[pdfName] = '';
      },
    saveResearch(val) {
      let validate = true;
      if(this.form.relatedDocuments.length > 0){
        this.form.relatedDocuments.forEach((document) => {
          if(document.file && (!document.name || !document.name_en)) {
            validate = false
            Swal.fire({
              title: "เกิดข้อผิดพลาด",
              text: 'กรุณากรอกชื่อของเอกสารที่เกี่ยวข้อง ให้ครบถ้วน',
              icon: "warning",
              confirmButtonColor: "#3E7662",
            }).then(() => {
            });
          }
        })
      }
      if(validate) {
        if (val === "saveResearch") {
          Swal.fire({
            title: "คุณต้องการบันทึกโครงร่างงานวิจัยหรือไม่?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3E7662",
            cancelButtonColor: "rgb(189, 189, 189)",
            confirmButtonText: "ใช่ ฉันต้องการ",
            cancelButtonText: "ยกเลิก",
          }).then((result) => {
            if (result.value) {
              this.isLoading = true;
              this.form["researchID"] = this.researchID;
              this.form["checkResearch"] = val;
              this.$store
                  .dispatch("FormResearch/sendResearch", this.form)
                  .then((res) => {
                    Swal.fire({
                      title: "บันทึกโครงร่างงานวิจัยเรียบร้อยแล้ว",
                      icon: "success",
                      position: "center",
                      showConfirmButton: false,
                      background: "#fffff",
                      confirmButtonColor: "#3E7662",
                      timer: 1500,
                    });
                    // this.saveResearch2();
                    this.$router.push({
                      name: "Profile",
                      params: {edit: false, onFocus: true},
                    });
                    this.isLoading = false;
                  })
                  .catch((e) => {
                    this.isLoading = false;
                    if (e.response.data.errors) {
                      for (var k in e.response.data.errors) {
                        Swal.fire({
                          title: "เกิดข้อผิดพลาด",
                          text: e.response.data.errors[k][0],
                          icon: "warning",
                          confirmButtonColor: "#3E7662",
                        }).then(() => {
                          this.$vuetify.goTo(`#${k}`);
                        });
                      }
                    }
                    // Swal.fire({
                    //     title: 'เกิดข้อผิดพลาด',
                    //     text: 'บันทึกโครงร่างงานวิจัยไม่สำเร็จ',
                    //     icon: 'error',
                    //     confirmButtonColor: '#3E7662',
                    // }).then(() => {
                    //     this.isLoading = false;
                    // })
                  });
            }
          });
        } else {
          if (this.$refs.formFile.validate() === true) {
            Swal.fire({
              title: "คุณต้องการส่งโครงร่างงานวิจัยหรือไม่?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3E7662",
              cancelButtonColor: "rgb(189, 189, 189)",
              confirmButtonText: "ใช่ ฉันต้องการ",
              cancelButtonText: "ยกเลิก",
            })
                .then((result) => {
                  if (result.value) {
                    this.isLoading = true;
                    this.form["researchID"] = this.researchID;
                    this.form["checkResearch"] = val;
                    this.$store
                        .dispatch("FormResearch/sendResearch", this.form)
                        .then((res) => {
                          Swal.fire({
                            title: "ส่งโครงร่างงานวิจัยเรียบร้อยแล้ว",
                            icon: "success",
                            position: "center",
                            showConfirmButton: false,
                            background: "#fffff",
                            timer: 1500,
                            confirmButtonColor: "#3E7662",
                          });
                          if (this.relatedDocuments_temp.length > 0) {
                            axios
                                .post(
                                    domain + "/api/file-research-delete",
                                    this.relatedDocuments_temp,
                                    {
                                      headers: {
                                        "Content-Type": "multipart/form-data",
                                      },
                                    }
                                )
                                .then((response) => {
                                  // log response
                                })
                                .catch(function (error) {
                                });
                          }
                          this.isLoading = false;
                          this.$router.push({
                            name: "Profile",
                            params: {edit: false, onFocus: true},
                          });
                        })
                        .catch((e) => {
                          this.isLoading = false;
                          if (e.response.data.errors) {
                            for (var k in e.response.data.errors) {
                              Swal.fire({
                                title: "เกิดข้อผิดพลาด",
                                text: e.response.data.errors[k][0],
                                icon: "warning",
                                confirmButtonColor: "#3E7662",
                              }).then(() => {
                                this.$vuetify.goTo(`#${k}`);
                              });
                            }
                          }
                          // Swal.fire({
                          //     title: 'เกิดข้อผิดพลาด',
                          //     text: 'ส่งโครงร่างงานวิจัยไม่สำเร็จ',
                          //     icon: 'error',
                          //     confirmButtonColor: '#3E7662',
                          // }).then(() => {
                          //     this.isLoading = false;
                          // })
                        });
                  }
                })
                .catch((e) => {
                  this.isLoading = false;
                });
          }
        }
      }
    },
    uploadFile(name) {
      switch (name) {
        case "dataRecordFrom":
          document.getElementById("dataRecordFrom").click();
          break;
        case "questionnaireInterview":
          document.getElementById("questionnaireInterview").click();
          break;
        case "diary":
          document.getElementById("diary").click();
          break;
        case "documentsExplain":
          document.getElementById("documentsExplain").click();
          break;
        case "otherDocuments":
          document.getElementById("otherDocuments").click();
          break;
        case "certificate":
          document.getElementById("certificate").click();
          break;
        case "investigatorBrochure":
          document.getElementById("investigatorBrochure").click();
          break;
        case "researcherProfile":
          document.getElementById("researcherProfile").click();
          break;
        case "submitReportForConsider":
          document.getElementById("submitReportForConsider").click();
          break;
        case "dataRecordFromClean":
          document.getElementById("dataRecordFromClean").click();
          break;
        case "questionnaireInterviewClean":
          document.getElementById("questionnaireInterviewClean").click();
          break;
        case "diaryClean":
          document.getElementById("diaryClean").click();
          break;
        case "dataRecordFromHighlight":
          document.getElementById("dataRecordFromHighlight").click();
          break;
        case "questionnaireInterviewHighlight":
          document.getElementById("questionnaireInterviewHighlight").click();
          break;
        case "diaryHighlight":
          document.getElementById("diaryHighlight").click();
          break;
        case "documentsExplainClean":
          document.getElementById("documentsExplainClean").click();
          break;
        case "documentsExplainHighlight":
          document.getElementById("documentsExplainHighlight").click();
          break;
        case "otherDocuments2":
          document.getElementById("otherDocuments2").click();
          break;
        case "certificate2":
          document.getElementById("certificate2").click();
          break;
        case "investigatorBrochure2":
          document.getElementById("investigatorBrochure2").click();
          break;
        case "researcherProfile2":
          document.getElementById("researcherProfile2").click();
          break;
        case "submitReportForConsiderClean":
          document.getElementById("submitReportForConsiderClean").click();
          break;
        case "submitReportForConsiderHighlight":
          document.getElementById("submitReportForConsiderHighlight").click();
          break;
      }
    },
    uploadDynamicFile(val) {
      document.getElementById(val).click();
    },
    setDataUploadDynamicFile(val, index) {
      var formData = new FormData();
      formData.append("researchInsertFile", val);
      axios
        .post(domain + "/api/upload-file-pre", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
            if(response.data.fileName !== undefined)
                this.form.relatedDocuments[index].file = response.data.fileName;
            else
                this.form.relatedDocuments[index].file = '';
        })
        .catch(function(error) {});
    },
    setDataUploadData(name, e) {
      var formData = new FormData();
      switch (name) {
        case "dataRecordFrom":
          formData.append("dataRecordFrom", e);
          break;
        case "questionnaireInterview":
          formData.append("questionnaireInterview", e);
          break;
        case "diary":
          formData.append("diary", e);
          break;
        case "documentsExplain":
          formData.append("documentsExplain", e);
          break;
        case "otherDocuments":
          formData.append("otherDocuments", e);
          break;
        case "certificate":
          formData.append("certificate", e);
          break;
        case "investigatorBrochure":
          formData.append("investigatorBrochure", e);
          break;
        case "researcherProfile":
          formData.append("researcherProfile", e);
          break;
        case "submitReportForConsider":
          formData.append("submitReportForConsider", e);
          break;
        case "dataRecordFromClean":
          formData.append("dataRecordFromClean", e);
          break;
        case "questionnaireInterviewClean":
          formData.append("questionnaireInterviewClean", e);
          break;
        case "diaryClean":
          formData.append("diaryClean", e);
          break;
        case "dataRecordFromHighlight":
          formData.append("dataRecordFromHighlight", e);
          break;
        case "questionnaireInterviewHighlight":
          formData.append("questionnaireInterviewHighlight", e);
          break;
        case "diaryHighlight":
          formData.append("diaryHighlight", e);
          break;
        case "documentsExplainClean":
          formData.append("documentsExplainClean", e);
          break;
        case "documentsExplainHighlight":
          formData.append("documentsExplainHighlight", e);
          break;
        case "otherDocuments2":
          formData.append("otherDocuments2", e);
          break;
        case "certificate2":
          formData.append("certificate2", e);
          break;
        case "investigatorBrochure2":
          formData.append("investigatorBrochure2", e);
          break;
        case "researcherProfile2":
          formData.append("researcherProfile2", e);
          break;
        case "submitReportForConsiderClean":
          formData.append("submitReportForConsiderClean", e);
          break;
        case "submitReportForConsiderHighlight":
          formData.append("submitReportForConsiderHighlight", e);
          break;
      }
      axios
        .post(domain + "/api/upload-file-pre", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
            if(response.data.fileName !== undefined)
                this.form[name] = response.data.fileName;
            else
                this.form[name] = '';
        })
        .catch(function(error) {});
    },
    openPDF(val) {
      switch (val) {
        case "dataRecordFrom":
          window.open(this.dataRecordFromLink);
          break;
        case "questionnaireInterview":
          window.open(this.questionnaireInterviewLink);
          break;
        case "diary":
          window.open(this.diaryLink);
          break;
        case "documentsExplain":
          window.open(this.documentsExplainLink);
          break;
        case "otherDocuments":
          window.open(this.otherDocumentsLink);
          break;
        case "certificate":
          window.open(this.certificateLink);
          break;
        case "investigatorBrochure":
          window.open(this.investigatorBrochureLink);
          break;
        case "researcherProfile":
          window.open(this.researcherProfileLink);
          break;
        case "submitReportForConsider":
          window.open(this.submitReportForConsiderLink);
          break;
        case "dataRecordFromClean":
          window.open(this.dataRecordFromCleanLink);
          break;
        case "questionnaireInterviewClean":
          window.open(this.questionnaireInterviewCleanLink);
          break;
        case "diaryClean":
          window.open(this.diaryCleanLink);
          break;
        case "dataRecordFromHighlight":
          window.open(this.dataRecordFromHighlightLink);
          break;
        case "questionnaireInterviewHighlight":
          window.open(this.questionnaireInterviewHighlightLink);
          break;
        case "diaryHighlight":
          window.open(this.diaryHighlightLink);
          break;
        case "documentsExplainClean":
          window.open(this.documentsExplainCleanLink);
          break;
        case "documentsExplainHighlight":
          window.open(this.documentsExplainHighlightLink);
          break;
        case "otherDocuments2":
          window.open(this.otherDocuments2Link);
          break;
        case "certificate2":
          window.open(this.certificate2Link);
          break;
        case "investigatorBrochure2":
          window.open(this.investigatorBrochure2Link);
          break;
        case "researcherProfile2":
          window.open(this.researcherProfile2Link);
          break;
        case "submitReportForConsiderClean":
          window.open(this.submitReportForConsiderCleanLink);
          break;
        case "submitReportForConsiderHighlight":
          window.open(this.submitReportForConsiderHighlightLink);
          break;
      }
    },
    openPDFDynamic(link) {
      window.open(link);
    },
    add() {
      this.form.relatedDocuments.push({ name: "", file: "" });
    },
    remove(index) {
      // var item = this.form.relatedDocuments.splice(index, 1);
      if (this.form.relatedDocuments[index].file) {
        this.relatedDocuments_temp.push({
          name: this.form.relatedDocuments[index].name,
          file: this.form.relatedDocuments[index].file,
        });
      }
      this.form.relatedDocuments.splice(index, 1);
      this.onHaveDynamicFile.splice(index, 1);
    },
    getVerifyForm(){
      this.verifyForm = [];
      if (this.researchID !== 0) {
        this.isLoading = true;
        this.$store
            .dispatch("FormResearch/getResearchVerify", this.researchID)
            .then((res) => {
              this.verifyForm = res
              this.isLoading = false;
            })
            .catch((e) => {
              this.isLoading = false;
            });
      }
    }
  },
};
</script>

<style scoped>
.link__pdf:hover {
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
}
</style>
