<template>
    <v-content>
        <v-layout class="font-weight-bold">
            <v-flex shrink pt-2 v-if="label" style="text-align: right;font-weight: bold">
                <span class="mr-3">{{label}}</span>
            </v-flex>
            <v-flex class="text-input">
                <v-autocomplete
                        :id="id"
                        :placeholder="placeholder"
                        res="date"
                        :disabled="disabled"
                        :items="items"
                        :dark="dark"
                        :background-color="!disabled ? '#3E7662' : '#E0E0E0'"
                        :item-text="itemText"
                        :item-value="itemValue"
                        outlined
                        dense
                        clearable
                        :hide-details="hideDetails"
                        :value="model"
                        @input="onChange"
                ></v-autocomplete>
            </v-flex>
        </v-layout>
    </v-content>
</template>

<script>
    export default {
        name: "TextfieldForm",
        props: {
            rules:{
                type: String,
                default: ''
            },
            model: {
                required: false
            },
            dark: {
                type: Boolean,
                default: true,
            },
            id: String,
            label: String,
            itemText: String,
            placeholder: String,
            itemValue: String,
            items: Array,
            disabled: Boolean,
            hideDetails: Boolean,
            customFilter: Function,
            clearable: Boolean,
        },
        methods: {
            onChange(val) {
                this.$emit("update-data", val);
            },

        }
    }
</script>

<style scoped>

</style>
