<template>
    <v-content>
        <v-layout>
            <v-flex shrink pt-2 v-if="label" :style="model===true ? 'color: #3E7662' : 'color: #000000'">
                <span class="mr-3">{{label}}</span>
            </v-flex>
            <v-flex>
                <v-checkbox
                        :value="model"
                        :hide-details="hideDetail"
                        :color="color"
                        :label="label"
                        @change="onChange"
                ></v-checkbox>
            </v-flex>
        </v-layout>
    </v-content>
</template>

<script>
    export default {
        name: "CheckboxForm",
        props: {
            model: {
                required: false
            },
            color: {
                type: String,
                default: '#3E7662'
            },
            readonly: {
                type: Boolean
            },
            disabled: {
                type: Boolean
            },
            hideDetail: {
                type: Boolean,
                default: false,
            },
            label: {
                type: String,
            }
        },
        methods: {
            onChange(val) {
                this.$emit("update-data", val);
            },
        }
    }
</script>

<style lang="scss">
    .input-group--disabled.checkbox .input-group__input {
        color: #000 !important;
    }

    .input-group--disabled.input-group--select label {
        color: #000 !important;
    }
</style>
