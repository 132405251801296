<template>
    <v-content>
<!--        <multiselect-->
<!--                :value="model"-->
<!--                :options="options"-->
<!--                :placeholder="placeholder"-->
<!--                :label="label"-->
<!--                :track-by="trackBy"-->
<!--                @input="onChange"-->
<!--        >-->
<!--        </multiselect>-->
        <v-select
                :menu-props="{ bottom: true, offsetY: true }"
                :value="model"
                background-color="#3E7662"
                solo
                flat
                dense
                :placeholder="placeholder"
                :label="label"
                :items="items"
                :item-text="itemText"
                :item-value="itemValue"
                :value-comparator="findMatchingProvince"
                @input="onChange"
        ></v-select>
    </v-content>
</template>

<script>
    // import vSelect from 'vue-select'
    // import 'vue-select/dist/vue-select.css';
    // import Multiselect from 'vue-multiselect';

    export default {
        name: "MultiselectForm",
        components: {
            // Multiselect
            // vSelect
        },
        props: {
            placeholder: String,
            label: String,
            items: Array,
            itemText: String,
            itemValue: String,
            model: {
                required: false
            },
        },
        methods: {
            onChange(val) {
                this.$emit("update-data", val);
            },
            findMatchingProvince(a,b) {
                return a==b;
            },
        }
    }
</script>

<style scoped>

</style>
