<template>
    <v-content style="background-color: #EFEAE7">
        <v-layout>
            <v-flex pt-8>
                <span style="font-weight: bold;font-size: 36px; color: #3E7662">สร้างงานวิจัย</span>
            </v-flex>
        </v-layout>

        <FormCreateResearchPage1 v-if="this.page_form_research === 1"></FormCreateResearchPage1>
        <FormCreateResearchPage2 v-if="this.page_form_research === 2"></FormCreateResearchPage2>
        <FormCreateResearchPage3 v-if="this.page_form_research === 3"></FormCreateResearchPage3>
        <FormCreateResearchPage4 v-if="this.page_form_research === 4"></FormCreateResearchPage4>
    </v-content>
</template>

<script>
    import FormCreateResearchPage1 from "../components/FormCreateResearchPage1";
    import FormCreateResearchPage2 from "../components/FormCreateResearchPage2";
    import FormCreateResearchPage3 from "../components/FormCreateResearchPage3";
    import FormCreateResearchPage4 from "../components/FormCreateResearchPage4";
    import { mapState } from "vuex";

    export default {
        name: "FormCreateResearch",
        components:{
            FormCreateResearchPage1,
            FormCreateResearchPage2,
            FormCreateResearchPage3,
            FormCreateResearchPage4,
        },
        computed: {
            ...mapState({
                page_form_research : state => {
                    return state.FormResearch.formResearch;
                },
            })
        },
        data: () => ({

        }),

        methods: {

        },
    }
</script>

<style scoped>

</style>
