<template>
  <v-content>
    <v-layout style="background-color: #FAF5F2;">
      <v-flex>
        <v-layout mb-3 mt-6>
          <v-flex class="text-center" style="color: #3E7662;font-weight: bold;font-size: 28px">
            <span>แบบฟอร์ม</span>
          </v-flex>
        </v-layout>

        <v-layout style="margin-left: 100px;margin-right: 100px;">
          <v-flex>
            <v-layout mt-3 style="background-color: #3E7662;">
              <v-flex my-8 ml-8 style="text-align: left;font-size: 28px;color: white">
                <div style="font-weight: bold">
                  แบบรายงานเหตุการณ์ไม่พึงประสงค์จากยา/เหตุการณ์ไม่คาดคิดจากยา
                  (RF 04_1_2563)
                </div>
                <div style="font-size: 18px;">
                  Adverse Drug Reaction Report/Unexpected Event Report (เฉพาะ
                  Serious หรือ Unexpected)
                </div>
              </v-flex>
              <v-flex ml-8 pt-8 pr-8 style="text-align: right;font-weight: bold;font-size: 13px;color: white">
                <span>RF 04_1_2563</span>
              </v-flex>
            </v-layout>

            <!-- blog 1 -->
            <v-card flat class="py-12" style="padding-right: 80px;padding-left: 80px">
              <v-layout>
                <v-flex>
                  <!-- row 1 -->
                  <v-layout pb-3>
                    <v-flex style="text-align: left;font-weight: bold;">
                      <span>1. รหัสครงการ</span>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex>
                      <textfield-form :model="form.research_pass"
                        @update-data="updateData('research_pass', $event)"></textfield-form>
                    </v-flex>
                  </v-layout>
                  <!-- row 2 -->
                  <v-layout pb-3>
                    <v-flex style="text-align: left;font-weight: bold;">
                      <span>2. ชื่อโครงการวิจัย</span>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex>
                      <textfield-form :model="form.researchName"
                        @update-data="updateData('researchName', $event)"></textfield-form>
                    </v-flex>
                  </v-layout>
                  <!-- row 3 -->
                  <v-layout pb-3>
                    <v-flex style="text-align: left;font-weight: bold;">
                      <span>3. ชื่อผู้วิจัยหลัก</span>
                    </v-flex>
                  </v-layout>
                  <v-layout px-4>
                    <v-flex style="color: #3E7662">
                      <textfield-form label="ชื่อ" :model="form.fname"
                        @update-data="updateData('fname', $event)"></textfield-form>
                    </v-flex>
                    <v-flex ml-3 style="color: #3E7662">
                      <textfield-form label="นามสกุล" :model="form.lname"
                        @update-data="updateData('lname', $event)"></textfield-form>
                    </v-flex>
                  </v-layout>
                  <!-- row 4 -->
                  <v-layout pb-3>
                    <v-flex style="text-align: left;font-weight: bold;">
                      <span>4. สังกัด / เบอร์โทรศัพท์ติดต่อ</span>
                    </v-flex>
                  </v-layout>
                  <v-layout pb-3>
                    <v-flex>
                      <textfield-form :model="form.party" @update-data="updateData('party', $event)"></textfield-form>
                    </v-flex>
                  </v-layout>
                  <!-- row 5 -->
                  <v-layout pb-3 justify-space-between>
                    <v-flex mr-6 shrink d-flex align-center style="text-align: left;font-weight: bold;">
                      <span>5. อาสาสมัคร</span>
                    </v-flex>
                    <v-flex>
                      <v-radio-group v-model="form.volunteer_checkbox" @change="clearData">
                        <v-layout my-3 align-center>
                          <v-flex>
                            <v-radio color="#3E7662" label="ในสถาบัน" value="1"></v-radio>
                          </v-flex>
                        </v-layout>
                        <v-layout my-3 align-center>
                          <v-flex shrink>
                            <v-radio color="#3E7662" label="ประเทศไทย" value="2"></v-radio>
                          </v-flex>
                          <v-flex pl-4>
                            <textfield-form :background-color="!form.volunteer_checkbox ? 'gray' : '#FBF6F3'
                              " :disabled="form.volunteer_checkbox !== '2'" hide-detail :model="form.volunteer"
                              @update-data="updateData('volunteer', $event)"></textfield-form>
                          </v-flex>
                        </v-layout>
                        <v-layout my-3 align-center>
                          <v-flex shrink>
                            <v-radio color="#3E7662" label="ต่างประเทศ" value="3"></v-radio>
                          </v-flex>
                          <v-flex pl-4>
                            <textfield-form :background-color="!form.volunteer_checkbox ? 'gray' : '#FBF6F3'
                              " :disabled="form.volunteer_checkbox !== '3'" hide-detail :model="form.volunteerForeign"
                              @update-data="
                                updateData('volunteerForeign', $event)
                                "></textfield-form>
                          </v-flex>
                        </v-layout>
                      </v-radio-group>
                    </v-flex>
                  </v-layout>
                  <!-- row 6 -->
                  <v-layout pt-6 justify-space-between>
                    <v-flex mr-10 shrink d-flex align-center style="text-align: left;font-weight: bold;">
                      <span>6. ชื่อย่อ/รหัสอาสาสมัคร Patient Initial</span>
                    </v-flex>
                    <v-flex d-flex align-center>
                      <textfield-form hide-detail :model="form.patient_initial"
                        @update-data="updateData('patient_initial', $event)"></textfield-form>
                    </v-flex>
                  </v-layout>
                  <!-- row 7 -->
                  <v-layout pt-10>
                    <v-flex xs2 mr-10 d-flex align-center style="text-align: left;font-weight: bold;">
                      <span>7. วันเกิด</span>
                    </v-flex>
                    <v-flex xs2 d-flex align-center>
                      <date-input-form hide-detail :model="dateChange1"
                        @update-data="updateDate('birthDate', $event)"></date-input-form>
                    </v-flex>
                    <v-flex justify-end xs2 d-flex align-center>
                      <span style="text-align: left;font-weight: bold;">8. อายุ</span>
                    </v-flex>
                    <v-flex xs2 mx-4 d-flex align-center>
                      <textfield-form hide-detail :model="form.year"
                        @update-data="updateData('year', $event)"></textfield-form>
                    </v-flex>
                    <v-flex xs2 d-flex align-center>
                      <span style="text-align: left;font-weight: bold;">ปี</span>
                    </v-flex>
                  </v-layout>
                  <!-- row 8 -->
                  <v-layout mt-4>
                    <v-flex xs2 mr-10 d-flex align-center style="text-align: left;font-weight: bold;">
                      <span>9. เพศ</span>
                    </v-flex>
                    <v-flex d-flex align-center style="text-align: left;">
                      <v-radio-group v-model="form.sex" row>
                        <v-radio color="#3E7662" style="padding-right: 40px" label="ชาย" value="0"></v-radio>
                        <v-radio color="#3E7662" label="หญิง" value="1"></v-radio>
                      </v-radio-group>
                    </v-flex>
                  </v-layout>
                  <!-- row 9 -->
                  <v-layout mt-4>
                    <v-flex xs2 mr-10 d-flex align-center style="text-align: left;font-weight: bold;">
                      <span>10.1 วันที่เกิดอาการ</span>
                    </v-flex>
                    <v-flex xs2 d-flex align-center>
                      <date-input-form hide-detail :model="dateChange4"
                        @update-data="updateDate('dateSymptom', $event)"></date-input-form>
                    </v-flex>
                  </v-layout>
                  <v-layout mt-4>
                    <v-flex xs2 mr-10 d-flex align-center style="text-align: left;font-weight: bold;">
                      <span>10.2 วันที่ผู้วิจัยทราบ</span>
                    </v-flex>
                    <v-flex xs2 d-flex align-center>
                      <date-input-form hide-detail :model="dateChange5"
                        @update-data="updateDate('dateKnow', $event)"></date-input-form>
                    </v-flex>
                  </v-layout>
                  <!-- row 9 -->
                  <v-layout mb-3 mt-10>
                    <v-flex style="text-align: left;font-weight: bold;">
                      <span>11. รายละเอียดเหตุการณ์ไม่พึงประสงค์
                        (รวมทั้งการตรวจทางห้องปฏิบัติการ)</span>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex>
                      <textarea-form hide-detail :model="form.detail_events"
                        @update-data="updateData('detail_events', $event)" :rows="8" />
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>

            <!-- blog 2 -->
            <v-layout mt-12 style="background-color: #3E7662;">
              <v-flex pl-6 my-5 ml-8 style="font-size: 18px;color: white">
                <span>ข้อมูลยา (Suspect drugs information)</span>
              </v-flex>
            </v-layout>
            <v-layout py-12 style="background-color: white;padding-left: 60px;padding-right: 70px;">
              <v-flex>
                <v-layout mb-3>
                  <v-flex style="text-align: left;font-weight: bold;">
                    <span>12.ยาที่ใช้ (Suspect drugs) ทั้ง Generic name และ Trade
                      name</span>
                  </v-flex>
                </v-layout>
                <v-layout mb-4>
                  <v-flex style="text-align: left;">
                    <textarea-form :model="form.suspect_drug" @update-data="updateData('suspect_drug', $event)" />
                  </v-flex>
                </v-layout>
                <v-layout mb-3>
                  <v-flex style="text-align: left;font-weight: bold;">
                    <span>13.ขนาดยา (Daily doses)</span>
                  </v-flex>
                  <v-flex style="text-align: left;font-weight: bold;">
                    <span>14.วิธีให้ยา (Route)</span>
                  </v-flex>
                </v-layout>
                <v-layout mb-4>
                  <v-flex style="text-align: left;">
                    <textarea-form :model="form.daily_doses" @update-data="updateData('daily_doses', $event)" />
                  </v-flex>
                  <v-flex pl-10 style="text-align: left;">
                    <textarea-form :model="form.route" @update-data="updateData('route', $event)" />
                  </v-flex>
                </v-layout>
                <v-layout mb-3>
                  <v-flex style="text-align: left;font-weight: bold;">
                    <span>15.ข้อบ่งชี้ของการให้ยา (Indications)</span>
                  </v-flex>
                </v-layout>
                <v-layout mb-4>
                  <v-flex style="text-align: left;">
                    <textarea-form :model="form.indications" @update-data="updateData('indications', $event)" />
                  </v-flex>
                </v-layout>
                <v-layout mb-3>
                  <v-flex style="text-align: left;font-weight: bold;">
                    <span>16.วันที่เริ่มให้ยา และระยะเวลาการให้ยา (Therapy date and
                      duration)</span>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex style="text-align: left;">
                    <textarea-form :model="form.TherapyDateAndDuration" @update-data="
                      updateData('TherapyDateAndDuration', $event)
                      " />
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>

            <!-- Blog 3 -->
            <v-layout style="background-color: #3E7662;">
              <v-flex pl-6 my-5 ml-8 style="font-size: 18px;color: white">
                <span>ยาที่ให้ร่วม (Concomitant drugs and history)</span>
              </v-flex>
            </v-layout>
            <v-layout py-12 style="background-color: white;padding-left: 60px;padding-right: 70px;">
              <v-flex>
                <v-layout mb-3>
                  <v-flex style="text-align: left;font-weight: bold;">
                    <span>17.ยาที่ให้ร่วม และวันที่ให้ยา
                      (ไม่รวมยาที่ใช้เพื่อรักษาอาการไม่พึงประสงค์จากยา)</span>
                  </v-flex>
                </v-layout>
                <v-layout mb-4>
                  <v-flex style="text-align: left;">
                    <textarea-form :model="form.date_medication" @update-data="updateData('date_medication', $event)" />
                  </v-flex>
                </v-layout>
                <v-layout mb-3>
                  <v-flex style="text-align: left;font-weight: bold;">
                    <span>18.ประวัติอื่นที่อาจเกี่ยวข้อง เช่น การแพ้ยา
                      การตรวจทางห้องปฏิบัติการ การตั้งครรภ์</span>
                  </v-flex>
                </v-layout>
                <v-layout mb-4>
                  <v-flex style="text-align: left;">
                    <textarea-form :model="form.other_history" @update-data="updateData('other_history', $event)" />
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>

            <!-- Blog 4 -->
            <v-layout style="background-color: #3E7662;">
              <v-flex pl-6 my-5 ml-8 style="font-size: 18px;color: white">
                <span>ข้อมูลบริษัทผลิตยา (Manufacturer Information)</span>
              </v-flex>
            </v-layout>
            <v-layout py-12 style="background-color: white;padding-left: 60px;padding-right: 70px;">
              <v-flex>
                <v-layout mb-3>
                  <v-flex style="text-align: left;font-weight: bold;">
                    <span>19.ชื่อ ที่อยู่ บริษัทยา</span>
                  </v-flex>
                </v-layout>
                <v-layout mb-4>
                  <v-flex style="text-align: left;">
                    <textarea-form :model="form.pharmaceutical_company_address" @update-data="
                      updateData('pharmaceutical_company_address', $event)
                      " />
                  </v-flex>
                </v-layout>

                <v-layout mb-3>
                  <v-flex style="text-align: left;font-weight: bold;">
                    <span>20.รหัสยา</span>
                  </v-flex>
                </v-layout>
                <v-layout mb-4>
                  <v-flex xs6 style="text-align: left;">
                    <textfield-form :model="form.pharmaceuticalCode"
                      @update-data="updateData('pharmaceuticalCode', $event)" />
                  </v-flex>
                </v-layout>

                <v-layout mb-3>
                  <v-flex style="text-align: left;font-weight: bold;">
                    <span>21. ชนิดของการรายงาน</span>
                  </v-flex>
                </v-layout>
                <v-layout ml-4>
                  <v-flex>
                    <v-radio-group v-model="form.reportType" row>
                      <v-radio color="#3E7662" style="padding-right: 100px" label="ครั้งแรก Initial report"
                        value="0"></v-radio>
                      <v-radio color="#3E7662" label="เพื่อติดตาม Follow-up report" value="1"></v-radio>
                    </v-radio-group>
                  </v-flex>
                </v-layout>

                <v-layout mt-6 mb-3>
                  <v-flex style="text-align: left;font-weight: bold;">
                    <span>22. เหตุการณ์ที่เกิดขึ้น</span>
                  </v-flex>
                </v-layout>
                <v-layout ml-4>
                  <v-flex>
                    <v-radio-group v-model="form.incident">
                      <v-radio color="#3E7662" label="คาดว่าอาจจะเกิด" value="1"></v-radio>
                      <v-radio color="#3E7662"
                        label="ไม่คาดว่าจะเกิด Unexpected (ไม่ได้ระบุในโครงร่างการวิจัยหรือ Investigator brochure)"
                        value="2"></v-radio>
                      <v-radio color="#3E7662" label="Nature" value="3"></v-radio>
                      <v-radio color="#3E7662" label="Seriousness" value="4"></v-radio>
                      <v-radio color="#3E7662" label="Frequency" value="5"></v-radio>
                    </v-radio-group>
                  </v-flex>
                </v-layout>

                <v-layout mt-6 mb-3>
                  <v-flex style="text-align: left;font-weight: bold;">
                    <span>23. ความสัมพันธ์กับยาที่ใช้ในการวิจัย (Relation to the
                      study)</span>
                  </v-flex>
                </v-layout>
                <v-layout ml-4>
                  <v-flex>
                    <v-radio-group v-model="form.relationStudy">
                      <v-radio color="#3E7662" label="ไม่เกี่ยวข้อง (Not related)" value="1"></v-radio>
                      <v-radio color="#3E7662" label="อาจเกี่ยวข้อง (Possibly related)" value="2"></v-radio>
                      <v-radio color="#3E7662" label="น่าจะเกี่ยวข้อง (Probably related)" value="3"></v-radio>
                      <v-radio color="#3E7662" label="เกี่ยวข้องแน่นอน (Definitely related)" value="4"></v-radio>
                      <v-radio color="#3E7662" label="ไม่รู้ (Unknown)" value="5"></v-radio>
                    </v-radio-group>
                  </v-flex>
                </v-layout>

                <v-layout mt-6 mb-3>
                  <v-flex style="text-align: left;font-weight: bold;">
                    <span>24. ลงนามผู้วิจัย</span>
                  </v-flex>
                </v-layout>
                <v-layout ml-4>
                  <v-flex>
                    <TextfieldForm style="color: #3E7662" label="ชื่อ" :model="form.signedResearcherFname"
                      @update-data="updateData('signedResearcherFname', $event)" />
                  </v-flex>
                  <v-flex ml-12>
                    <TextfieldForm style="color: #3E7662" label="นามสกุล" :model="form.signedResearcherLname"
                      @update-data="updateData('signedResearcherLname', $event)" />
                  </v-flex>
                </v-layout>

                <v-layout mt-8>
                  <v-flex shrink mr-10 d-flex align-center style="text-align: left;font-weight: bold;">
                    <span>25. วันที่รายงาน</span>
                  </v-flex>
                  <v-flex xs3 d-flex align-center>
                    <date-input-form hide-detail :model="dateChange2"
                      @update-data="updateDate('reportDate', $event)"></date-input-form>
                  </v-flex>
                </v-layout>

                <v-layout mt-8 mb-3>
                  <v-flex style="text-align: left;font-weight: bold;">
                    <span>ก. ตรวจสอบความร้ายแรง (Seriousness)</span>
                  </v-flex>
                </v-layout>
                <v-layout ml-4>
                  <v-flex>
                    <v-radio-group v-model="form.severity">
                      <v-radio color="#3E7662" label="1. ตาย (Death)" value="1"></v-radio>
                      <v-radio color="#3E7662" label="2. รุนแรงและอาจทำให้เสียชีวิต (Life threatening)"
                        value="2"></v-radio>
                      <v-radio color="#3E7662"
                        label="3. ต้องรักษาในโรงพยาบาล (Hospitalization / prolonged hospitalization)" value="3"></v-radio>
                      <v-radio color="#3E7662"
                        label="4. พิการหรือทุพลภาพ (Persistent or significant disability / incapacity)"
                        value="4"></v-radio>
                      <v-radio color="#3E7662" label="5. ทารกพิการแต่กำเนิด (Congenital anomaly / birth defect)"
                        value="5"></v-radio>
                      <v-radio color="#3E7662" label="6. ไม่ร้ายแรง" value="6"></v-radio>
                    </v-radio-group>
                  </v-flex>
                </v-layout>

                <v-layout mt-8 mb-3>
                  <v-flex style="text-align: left;font-weight: bold;">
                    <span>ข. อาการหายไปหลังหยุดยาหรือไม่</span>
                  </v-flex>
                </v-layout>
                <v-layout ml-4>
                  <v-flex>
                    <v-radio-group v-model="form.symptomDisappear" row>
                      <v-radio color="#3E7662" label="หายไป" value="1"></v-radio>
                      <v-radio color="#3E7662" label="ไม่หาย" value="2"></v-radio>
                      <v-radio color="#3E7662" label="ตอบไม่ได้" value="3"></v-radio>
                    </v-radio-group>
                  </v-flex>
                </v-layout>

                <v-layout mt-8 mb-3>
                  <v-flex style="text-align: left;font-weight: bold;">
                    <span>ค. อาการเกิดขึ้นใหม่ เมื่อกลับให้ยาอีกหรือไม่</span>
                  </v-flex>
                </v-layout>
                <v-layout ml-4>
                  <v-flex>
                    <v-radio-group v-model="form.hasRecurrentAfterStop" row>
                      <v-radio color="#3E7662" label="เกิดซ้ำ" value="1"></v-radio>
                      <v-radio color="#3E7662" label="ไม่เกิดอีก" value="2"></v-radio>
                      <v-radio color="#3E7662" label="ตอบไม่ได้" value="3"></v-radio>
                    </v-radio-group>
                  </v-flex>
                </v-layout>

                <v-layout mt-8 mb-3>
                  <v-flex style="text-align: left;font-weight: bold;">
                    <span>ง. การดำเนินเหตุการณ์ (Progression)</span>
                  </v-flex>
                </v-layout>
                <v-layout ml-4>
                  <v-flex>
                    <v-radio-group v-model="form.progression" row>
                      <v-radio color="#3E7662" label="1. แก้ไขแล้ว (Resolved)" value="1"></v-radio>
                      <v-radio color="#3E7662" label="2. ยังคงมีปัญหา (Ongoing)" value="2"></v-radio>
                      <v-radio color="#3E7662" label="3. ไม่เกี่ยวข้อง (N/a)" value="3"></v-radio>
                    </v-radio-group>
                  </v-flex>
                </v-layout>

                <v-layout mt-8 mb-3>
                  <v-flex style="text-align: left;font-weight: bold;">
                    <span>จ. การเปลี่ยนแปลงโครงร่างการวิจัย</span>
                  </v-flex>
                </v-layout>
                <v-layout ml-4>
                  <v-flex>
                    <v-radio-group v-model="form.changesResearchProposal">
                      <v-radio color="#3E7662" label="1. ไม่เปลี่ยน" value="1"></v-radio>
                      <v-radio color="#3E7662"
                        label="2. เปลี่ยน (ส่งรายงานส่วนแก้ไขเพิ่มเติมโครงร่าง การวิจัย RF 12_2563 ให้คณะอนุกรรมการฯ)"
                        value="2"></v-radio>
                    </v-radio-group>
                  </v-flex>
                </v-layout>

                <v-layout mt-8 mb-3>
                  <v-flex style="text-align: left;font-weight: bold;">
                    <span>ฉ. การเปลี่ยนแปลงเอกสารชี้แจงข้อมูลอาสาสมัคร</span>
                  </v-flex>
                </v-layout>
                <v-layout ml-4>
                  <v-flex>
                    <v-radio-group v-model="form.changeClarificationDocument">
                      <v-radio color="#3E7662" label="1. ไม่เปลี่ยน" value="1"></v-radio>
                      <v-radio color="#3E7662"
                        label="2. เปลี่ยน (ส่งรายงานส่วนแก้ไขเพิ่มเติมโครงร่าง การวิจัย RF 12_2563 ให้คณะอนุกรรมการฯ)"
                        value="2"></v-radio>
                    </v-radio-group>
                  </v-flex>
                </v-layout>

                <v-layout mt-12 mb-8>
                  <v-flex style="color: #CC3D45;font-size: 14px;text-align: left;">
                    <span>
                      *ถ้าเป็นเหตุการณ์ไม่พึงประสงค์ชนิดร้ายแรง(ก 1-5)
                      ผู้วิจัยควรส่งรายงานแก่คณะอนุกรรมการพิจารณาโครงการวิจัย
                      พบ. ภายใน 7 วัน หลังทราบเหตุการณ์
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>

            <!-- Blog 5 -->
            <v-layout mt-12 style="background-color: #3E7662;">
              <v-flex pl-6 my-5 ml-8 style="font-size: 18px;color: white">
                <span>Naranjo’s algorithm</span>
              </v-flex>
            </v-layout>
            <v-layout py-12 style="background-color: white;padding-left: 60px;padding-right: 70px;">
              <v-flex>
                <v-layout mb-3>
                  <v-flex xs6 style="text-align: left;font-weight: bold;">
                    <span>ชื่อยา</span>
                  </v-flex>
                  <v-flex xs6 style="text-align: left;font-weight: bold;">
                    <span>เลขที่แบบรายงาน</span>
                  </v-flex>
                </v-layout>
                <v-layout mb-4>
                  <v-flex pr-12 xs6 style="text-align: left;">
                    <textfield-form :model="form.drugName" @update-data="updateData('drugName', $event)" />
                  </v-flex>
                  <v-flex xs6 style="text-align: left;">
                    <textfield-form :model="form.reportNumber" @update-data="updateData('reportNumber', $event)" />
                  </v-flex>
                </v-layout>

                <v-layout mb-3>
                  <v-flex style="text-align: left;font-weight: bold;">
                    <span>อาการไม่พึงประสงค์</span>
                  </v-flex>
                </v-layout>
                <v-layout mb-4>
                  <v-flex style="text-align: left;">
                    <textarea-form :rows="7" :model="form.symptom" @update-data="updateData('symptom', $event)" />
                  </v-flex>
                </v-layout>

                <v-card>
                  <!-- header -->
                  <v-layout py-3 style="background-color: #3E7662;color: white;">
                    <v-flex xs7 pr-12 pl-12 style="text-align: left;font-size: 18px">คำถาม</v-flex>
                    <v-flex xs1 pr-8>ใช่</v-flex>
                    <v-flex xs1 pr-8>ไม่ใช่</v-flex>
                    <v-flex xs1 pr-4>ไม่ทราบ</v-flex>
                    <v-flex xs2 pr-4 class="text-center">คะแนน</v-flex>
                  </v-layout>
                  <!-- row detail -->
                  <v-layout pl-6 py-3>
                    <v-flex xs7 pt-2 pr-12 style="text-align: left">1.
                      เคยมีสรุปหรือรายงานการเกิดปฏิกิริยานี้มาแล้วหรือไม่</v-flex>
                    <v-flex xs1 pt-2 pr-8 style="font-weight: bold">+1</v-flex>
                    <v-flex xs1 pt-2 pr-8 style="font-weight: bold">0</v-flex>
                    <v-flex xs1 pt-2 pr-8 style="font-weight: bold">0</v-flex>
                    <v-flex xs2 pr-3 style="font-weight: bold">
                      <textfield-form type="number" :min="-2" :max="2" hide-detail :model="form.point1"
                        @update-data="updateData('point1', $event)" />
                      <!--                                            <v-layout>-->
                      <!--                                                <v-flex-->
                      <!--                                                        class="px-2"-->
                      <!--                                                        style="-->
                      <!--                                                            background-color: rgb(251, 246, 243);-->
                      <!--                                                        border: 1px solid gray;-->
                      <!--                                                        border-radius: 5px;-->
                      <!--                                                        height: 40px;display: flex; justify-content: center;align-items: center"-->
                      <!--                                                >-->
                      <!--                                                    <v-layout class="d-flex justify-center align-center">-->
                      <!--                                                        <v-flex class="text-center">-->
                      <!--                                                                <v-icon class="btn-hover" style="cursor: pointer" small>remove</v-icon>-->
                      <!--                                                        </v-flex>-->
                      <!--                                                        <v-flex xs6 class="text-center">-->
                      <!--                                                            <div>+1</div>-->
                      <!--                                                        </v-flex>-->
                      <!--                                                        <v-flex class="text-center">-->
                      <!--                                                                <v-icon class="btn-hover" style="cursor: pointer" small>add</v-icon>-->
                      <!--                                                        </v-flex>-->
                      <!--                                                    </v-layout>-->
                      <!--                                                </v-flex>-->

                      <!--                                            </v-layout>-->
                    </v-flex>
                  </v-layout>
                  <v-layout pl-6 py-3 style="background-color: #FBF6F3;">
                    <v-flex xs7 pt-2 pr-12 style="text-align: left;">2.
                      อาการไม่พึงประสงค์นี้เกิดขึ้นภายหลังจากได้รับยาที่คิดว่าเป็นสาเหตุหรือไม่</v-flex>
                    <v-flex xs1 pt-2 pr-8 style="font-weight: bold">+2</v-flex>
                    <v-flex xs1 pt-2 pr-8 style="font-weight: bold">-1</v-flex>
                    <v-flex xs1 pt-2 pr-8 style="font-weight: bold">0</v-flex>
                    <v-flex xs2 pr-3 style="font-weight: bold">
                      <textfield-form type="number" :min="-2" :max="2" hide-detail :model="form.point2"
                        @update-data="updateData('point2', $event)" />
                    </v-flex>
                  </v-layout>
                  <v-layout pl-6 py-3>
                    <v-flex xs7 pt-2 pr-12 style="text-align: left;">3. อาการไม่พึงประสงค์นี้ดีขึ้นเมื่อหยุดยาดังกล่าว
                      หรือเมื่อให้ยาต้านที่จำเพาะเจาะจง (specific antagonist)
                      หรือไม่</v-flex>
                    <v-flex xs1 pt-2 pr-8 style="font-weight: bold">+1</v-flex>
                    <v-flex xs1 pt-2 pr-8 style="font-weight: bold">0</v-flex>
                    <v-flex xs1 pt-2 pr-8 style="font-weight: bold">0</v-flex>
                    <v-flex x21 pr-3 style="font-weight: bold">
                      <textfield-form type="number" :min="-2" :max="2" hide-detail :model="form.point3"
                        @update-data="updateData('point3', $event)" />
                    </v-flex>
                  </v-layout>
                  <v-layout pl-6 py-3 style="background-color: #FBF6F3;">
                    <v-flex xs7 pt-2 pr-12 style="text-align: left;">4.
                      อาการไม่พึงประสงค์ดังกล่าวเกิดขึ้นเมื่อเริ่มให้ยาใหม่หรือไม่</v-flex>
                    <v-flex xs1 pt-2 pr-8 style="font-weight: bold">+2</v-flex>
                    <v-flex xs1 pt-2 pr-8 style="font-weight: bold">-1</v-flex>
                    <v-flex xs1 pt-2 pr-8 style="font-weight: bold">0</v-flex>
                    <v-flex xs2 pr-3 style="font-weight: bold">
                      <textfield-form type="number" :min="-2" :max="2" hide-detail :model="form.point4"
                        @update-data="updateData('point4', $event)" />
                    </v-flex>
                  </v-layout>
                  <v-layout pl-6 py-3>
                    <v-flex xs7 pt-2 pr-12 style="text-align: left;">5. ปฏิกิริยาที่เกิดขึ้นสามารถเกิดจากสาเหตุอื่น
                      (นอกเหนือจากยา) ของอาสาสมัครได้หรือไม่</v-flex>
                    <v-flex xs1 pt-2 pr-8 style="font-weight: bold">-1</v-flex>
                    <v-flex xs1 pt-2 pr-8 style="font-weight: bold">+2</v-flex>
                    <v-flex xs1 pt-2 pr-8 style="font-weight: bold">0</v-flex>
                    <v-flex x21 pr-3 style="font-weight: bold">
                      <textfield-form type="number" :min="-2" :max="2" hide-detail :model="form.point5"
                        @update-data="updateData('point5', $event)" />
                    </v-flex>
                  </v-layout>
                  <v-layout pl-6 py-3 style="background-color: #FBF6F3;">
                    <v-flex xs7 pt-2 pr-12 style="text-align: left;">6. ปฏิกิริยาดังกล่าวเกิดขึ้นอีก เมื่อให้ยาหลอก
                      หรือไม่</v-flex>
                    <v-flex xs1 pt-2 pr-8 style="font-weight: bold">-1</v-flex>
                    <v-flex xs1 pt-2 pr-8 style="font-weight: bold">+1</v-flex>
                    <v-flex xs1 pt-2 pr-8 style="font-weight: bold">0</v-flex>
                    <v-flex x21 pr-3 style="font-weight: bold">
                      <textfield-form type="number" :min="-2" :max="2" hide-detail :model="form.point6"
                        @update-data="updateData('point6', $event)" />
                    </v-flex>
                  </v-layout>
                  <v-layout pl-6 py-3>
                    <v-flex xs7 pt-2 pr-12 style="text-align: left;">7. ตรวจวัดปริมาณยาได้ในเลือด(หรือของเหลวอื่น)
                      ในปริมาณความเข้มข้นที่เป็นพิษ หรือไม่</v-flex>
                    <v-flex xs1 pt-2 pr-8 style="font-weight: bold">+1</v-flex>
                    <v-flex xs1 pt-2 pr-8 style="font-weight: bold">0</v-flex>
                    <v-flex xs1 pt-2 pr-8 style="font-weight: bold">0</v-flex>
                    <v-flex xs2 pr-3 style="font-weight: bold">
                      <textfield-form type="number" :min="-2" :max="2" hide-detail :model="form.point7"
                        @update-data="updateData('point7', $event)" />
                    </v-flex>
                  </v-layout>
                  <v-layout pl-6 py-3 style="background-color: #FBF6F3;">
                    <v-flex xs7 pt-2 pr-12 style="text-align: left;">8. ปฏิกิริยารุนแรงที่เกิดขึ้นเมื่อเพิ่มขนาดยา
                      หรือลดความรุนแรงลงเมื่อลดขนาดยา หรือไม่</v-flex>
                    <v-flex xs1 pt-2 pr-8 style="font-weight: bold">+1</v-flex>
                    <v-flex xs1 pt-2 pr-8 style="font-weight: bold">0</v-flex>
                    <v-flex xs1 pt-2 pr-8 style="font-weight: bold">0</v-flex>
                    <v-flex xs2 pr-3 style="font-weight: bold">
                      <textfield-form type="number" :min="-2" :max="2" hide-detail :model="form.point8"
                        @update-data="updateData('point8', $event)" />
                    </v-flex>
                  </v-layout>
                  <v-layout pl-6 py-3>
                    <v-flex xs7 pt-2 pr-12 style="text-align: left;">9.
                      อาสาสมัครเคยมีประวัติเกิดปฏิกิริยาเหมือนหรือคล้ายคลึงกันนี้มาก่อน
                      เมื่อได้รับยาชนิดนี้ หรือ ยาในกลุ่มเดียวกันในครั้งก่อนๆ
                      หรือไม่</v-flex>
                    <v-flex xs1 pt-2 pr-8 style="font-weight: bold">+1</v-flex>
                    <v-flex xs1 pt-2 pr-8 style="font-weight: bold">0</v-flex>
                    <v-flex xs1 pt-2 pr-8 style="font-weight: bold">0</v-flex>
                    <v-flex xs2 pr-3 style="font-weight: bold">
                      <textfield-form type="number" :min="-2" :max="2" hide-detail :model="form.point9"
                        @update-data="updateData('point9', $event)" />
                    </v-flex>
                  </v-layout>
                  <v-layout pl-6 py-3 style="background-color: #FBF6F3;">
                    <v-flex xs7 pt-2 pr-12 style="text-align: left;">10. อาการไม่พึงประสงค์นี้
                      ได้รับการยืนยันโดยหลักฐานที่เป็นรูปธรรม (objective
                      evidence) หรือไม่</v-flex>
                    <v-flex xs1 pt-2 pr-8 style="font-weight: bold">+1</v-flex>
                    <v-flex xs1 pt-2 pr-8 style="font-weight: bold">0</v-flex>
                    <v-flex xs1 pt-2 pr-8 style="font-weight: bold;">0</v-flex>
                    <v-flex xs2 pr-3 style="font-weight: bold">
                      <textfield-form type="number" :min="-2" :max="2" hide-detail :model="form.point10"
                        @update-data="updateData('point10', $event)" />
                    </v-flex>
                  </v-layout>
                  <!-- footer -->
                  <v-layout py-3 style="background-color: #3E7662;color: white;">
                    <v-flex xs8 ml-6 class="align-center d-flex font-weight-bold justify-center">รวม</v-flex>
                    <!--                                        <v-flex xs1 pr-3 style="font-weight: bold">-->
                    <!--                                            <textfield-form hide-detail :model="form.sumYes" @update-data="updateData('point10', $event)"/>-->
                    <!--                                        </v-flex>-->
                    <!--                                        <v-flex xs1 pr-3 style="font-weight: bold">-->
                    <!--                                            <textfield-form hide-detail :model="form.sumNo" @update-data="updateData('point10', $event)"/>-->
                    <!--                                        </v-flex>-->
                    <!--                                        <v-flex xs1 pr-3 style="font-weight: bold">-->
                    <!--                                            <textfield-form hide-detail :model="form.sumUnknown" @update-data="updateData('point10', $event)"/>-->
                    <!--                                        </v-flex>-->
                    <v-spacer></v-spacer>
                    <v-flex xs1 pr-3 style="font-weight: bold" class="text-end text-field-center">
                      <textfield-form readonly hide-detail :model="form.sumPoint"
                        @update-data="updateData('point10', $event)" />
                    </v-flex>
                  </v-layout>
                </v-card>

                <v-layout pl-12 mt-8>
                  <v-flex style="text-align: left;font-weight: bold">
                    <span>ระดับคะแนน</span>
                  </v-flex>
                </v-layout>
                <v-layout pl-12 pt-2>
                  <v-flex xs3 style="text-align: left;font-size: 14px">
                    <span>คะแนนมากกว่าหรือเท่ากับ 9</span>
                  </v-flex>
                  <v-flex xs3 style="text-align: left;font-size: 14px">
                    <span>Definite (certain)</span>
                  </v-flex>
                  <v-flex xs3 style="text-align: left;font-size: 14px">
                    <span>ใช่แน่</span>
                  </v-flex>
                </v-layout>
                <v-layout pl-12 pt-2>
                  <v-flex xs3 style="text-align: left;font-size: 14px">
                    <span>คะแนนเท่ากับ 5 – 3</span>
                  </v-flex>
                  <v-flex xs3 style="text-align: left;font-size: 14px">
                    <span>Probable</span>
                  </v-flex>
                  <v-flex xs3 style="text-align: left;font-size: 14px">
                    <span>ใช่</span>
                  </v-flex>
                </v-layout>
                <v-layout pl-12 pt-2>
                  <v-flex xs3 style="text-align: left;font-size: 14px">
                    <span>คะแนนเท่ากับ1 – 4</span>
                  </v-flex>
                  <v-flex xs3 style="text-align: left;font-size: 14px">
                    <span>Possible</span>
                  </v-flex>
                  <v-flex xs3 style="text-align: left;font-size: 14px">
                    <span>อาจจะใช่</span>
                  </v-flex>
                </v-layout>
                <v-layout pl-12 pt-2>
                  <v-flex xs3 style="text-align: left;font-size: 14px">
                    <span>คะแนนน้อยกว่าหรือเท่ากับ 0</span>
                  </v-flex>
                  <v-flex xs3 style="text-align: left;font-size: 14px">
                    <span>Doubtful (unlikely)</span>
                  </v-flex>
                  <v-flex shrink style="text-align: left;font-size: 14px">
                    <span>น่าสงสัย (ไม่น่าใช่)</span>
                  </v-flex>
                </v-layout>

                <v-layout class="mt-12 mb-8">
                  <v-flex ml-4><span style="color: red">*ชื่อไฟล์ต้องเป็นภาษาอังกฤษเท่านั้น (a-z,0-9) ห้ามมีตัวอักษรอื่น
                    </span></v-flex>
                </v-layout>

                <v-layout class="mt-8 mb-5">
                  <v-flex>
                    <v-layout class="mb-8">
                      <v-flex>
                        <v-layout>
                          <v-flex shrink pt-2 pr-4 style="text-align: left;">
                            <span style="font-weight: bold;font-size: 16px;">หนังสือของหน่วยงาน
                            </span>
                          </v-flex>
                          <v-flex>
                            <v-file-input accept=".pdf" prepend-icon="" ref="agencyBooks" id="agencyBook"
                              :model="form.agencyBook" outlined dense clearable background-color="#FBF6F3" color="#3E7662"
                              item-color="#3E7662" @change="onUpload('agencyBooks', $event)" hide-details></v-file-input>
                            {{ form.agencyBook }}
                            <div v-if="agencyBook" class="mt-1 text-start" style="font-size: 12px;color: #CC3D45;">
                              <span>ไฟล์ที่อัพโหลด: <a :href="agencyBookPath" target="_blank"
                                  style="font-size: 12px;color: #CC3D45;">{{ agencyBook }}</a></span>
                            </div>
                          </v-flex>
                          <v-flex shrink pl-4 style="text-align: left">
                            <v-btn color="#3E7662" dark @click="onClickBtn('agencyBooks')">
                              <v-icon class="pr-3">cloud_download</v-icon>
                              <span>Upload</span>
                            </v-btn>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                    <v-layout>
                      <v-flex xs6>
                        <v-layout>
                          <v-flex class="d-flex align-center mr-4 font-weight-bold" shrink>หน่วยงานที่ออกหนังสือ</v-flex>
                          <v-flex>
                            <textfield-form hide-detail :model="form.instituteBook"
                              @update-data="updateData('instituteBook', $event)"></textfield-form>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                    <v-layout mt-6>
                      <v-flex xs6>
                        <v-layout>
                          <v-flex class="d-flex align-center mr-4 font-weight-bold" shrink>เลขหนังสือ</v-flex>
                          <v-flex>
                            <textfield-form hide-detail :model="form.bookNumber"
                              @update-data="updateData('bookNumber', $event)"></textfield-form>
                          </v-flex>
                        </v-layout>
                      </v-flex>

                      <v-flex xs6>
                        <v-layout>
                          <v-flex class="d-flex align-center mx-4 font-weight-bold" shrink>วันที่หนังสือ</v-flex>
                          <v-flex>
                            <DateInputForm id="orderDate" hide-detail :model="bookDate"
                              @update-data="updateDate('bookDate', $event)"></DateInputForm>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v-layout style="margin-top: 30px">
                  <v-flex ml-8 my-5 style="text-align: left;">
                    <span style="font-weight: bold">Note</span>
                    <span style="color: red"> *รายการที่ต้องแก้ไข</span>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex style="padding-left: 32px; padding-right: 32px">
                    <textarea-form readonly :model="form.noteDetail" :rows="5"></textarea-form>
                  </v-flex>
                </v-layout>
                <v-layout px-12 mt-8>
                  <!-- row 1-->
                  <v-flex xs6 style="font-weight: bold;font-size: 18px" d-flex justify-start>
                    <span>เอกสารแนบเพิ่มเติม</span>
                  </v-flex>
                </v-layout>
                <v-layout px-12 my-4>
                  <v-flex shrink mr-4>
                    <v-btn dark style="width: 80px;background-color: #3E7662" @click="addDynamicFile('fileOther')">
                      <v-icon>add</v-icon>
                      <span class="font-weight-bold">เพิ่ม</span>
                    </v-btn>
                  </v-flex>
                  <v-flex>
                    <span style="color: #CC3D45;">*ให้ผู้วิจัยระบุชื่่อ file / เวอร์ชั่น / วันที่
                      ให้ตรงกับ บันทึกข้อความ และห้ามมีอักขระพิเศษ
                      (!@#$%^&*)</span>
                  </v-flex>
                </v-layout>
                <div v-for="(input, k) in form.fileOther" :key="'d' + k">
                  <v-layout>
                    <v-flex>
                      <v-layout>
                        <v-flex shrink mr-4 pt-2 style="text-align: right;font-weight: bold;">
                          <span>ชื่อไฟล์</span>
                        </v-flex>
                        <v-flex xs5>
                          <textfield-form hide-detail :model="input.title" @update-data="
                            updateDynamicFileData('fileOther', 'title', k, $event)"></textfield-form>
                        </v-flex>
                        <v-flex shrink pt-2 px-4 style="text-align: left;">
                          <span style="font-weight: bold;font-size: 16px;">ไฟล์</span>
                        </v-flex>
                        <v-flex>
                          <v-file-input accept=".pdf" prepend-icon="" :id="'fileProtocolModify' + k" :model="input.file"
                            outlined dense background-color="#FBF6F3" color="#3E7662" item-color="#3E7662" @change="
                              updateDynamicFileData('fileOther', 'file', k, $event)"></v-file-input>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex ml-4>
                      <v-btn dark style="background-color: #CC3D45; width: 80px"
                        @click="removeDynamicFile('fileOther', k)" :disabled="form.fileOther.length < 1">
                        <v-icon>remove</v-icon>
                        <span class="font-weight-bold">ลบ</span>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                  <v-flex v-if="input.filePath"><a :href="input.filePath" target="_blank"
                      style="color: #3e7662;text-align: center" class="link-hover">
                      <p>ไฟล์ที่อัพโหลด: {{ input.file }}</p>
                    </a>
                  </v-flex>
                </div>

                <v-layout pt-10>
                  <v-spacer></v-spacer>
                  <v-flex xs6 style="font-weight: bold" class="text-field-center">
                    <textfield-form-append label="ลงชื่อผู้ประเมิน" icon="edit" :model="form.signature"
                      @update-data="updateData('signature', $event)" />
                  </v-flex>
                </v-layout>

                <v-layout mt-2>
                  <v-spacer></v-spacer>
                  <v-flex pt-2 mr-4 shrink style="font-weight: bold;text-align: right;">
                    <span>วันที่</span>
                  </v-flex>
                  <v-flex xs4 class="justify-end pr-12">
                    <date-input-form :model="dateChange3" @update-data="updateDate('date', $event)" />
                  </v-flex>
                </v-layout>

                <v-layout px-12 mb-8 pt-10>
                  <v-flex xs11 style="text-align: left;font-size: 14px; color: #3E7662">
                    <span>
                      อ้างอิง และ ปรับปรุงจากวารสารวิชาการสาธารณสุข 2559 ปีที่
                      25 ฉบับที่ 4หน้า 673-682 และ Naranjo CA, Busto U, Sellers
                      EM, et al. (1981). “A method for estimating the
                      probability of adverse drug reactions”. Clin. Pharmacol.
                      Ther. 30(2):239-45.
                    </span>
                  </v-flex>
                </v-layout>

                <v-layout pt-12 justify-space-between="">
                  <v-flex d-flex justify-start>
                    <span>สำนักงานคณะอนุกรรมการพิจารณาโครงการวิจัย พบ.</span>
                  </v-flex>
                  <v-flex d-flex justify-end>
                    <span>หน้า 1 จาก 1 หน้า</span>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>

            <!-- Button Save -->
            <v-layout mt-6>
              <v-flex mb-12 style="text-align: right;">
                <v-layout>
                  <v-flex>
                    <v-btn style="color: #3E7662" class="mx-4" @click="saveDraft" depressed
                      color="#ffffff">บันทึกร่าง</v-btn>
                    <!--<v-btn style="color: #3E7662" class="mx-4" depressed  color="#ffffff" @click="$router.go(-1)">ย้อนกลับ</v-btn>-->
                    <v-btn depressed dark color="#3E7662" @click="save">
                      <v-icon class="mr-3">add</v-icon>
                      <span>บันทึกข้อมูล</span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <loading color="#527281" :active.sync="isLoading" :can-cancel="false" :is-full-page="fullPage"></loading>
      </v-flex>
    </v-layout>
  </v-content>
</template>

<script>
import TextfieldForm from "../../components/input/TextfieldForm";
import DateInputForm from "../../components/input/DateInputForm";
import TextareaForm from "../../components/input/TextareaForm";
import Swal from "sweetalert2";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import TextfieldFormAppend from "../../components/input/TextfieldFormAppend";

export default {
  name: "FormRF06",
  components: {
    TextfieldFormAppend,
    TextfieldForm,
    DateInputForm,
    TextareaForm,
    Loading,
  },
  data: () => ({
    isLoading: false,
    fullPage: true,
    form: {
      noteDetail: "",
      fileOther: [],
      research_pass: "",
      researchName: "",
      fname: "",
      lname: "",
      party: "",
      volunteer_checkbox: false,
      volunteer: "",
      volunteerForeign: "",
      patient_initial: "",
      birthDate: "",
      year: "",
      sex: "",
      dateSymptom: "",
      dateKnow: "",
      detail_events: "",
      suspect_drug: "",
      daily_doses: "",
      route: "",
      indications: "",
      TherapyDateAndDuration: "",
      date_medication: "",
      other_history: "",
      pharmaceutical_company_address: "",
      pharmaceuticalCode: "",
      reportType: "",
      incident: "",
      relationStudy: "",
      signedResearcherFname: "",
      signedResearcherLname: "",
      reportDate: "",
      severity: "",
      symptomDisappear: "",
      hasRecurrentAfterStop: "",
      progression: "",
      changesResearchProposal: "",
      changeClarificationDocument: "",
      drugName: "",
      reportNumber: "",
      symptom: "",
      point1: 0,
      point2: 0,
      point3: 0,
      point4: 0,
      point5: 0,
      point6: 0,
      point7: 0,
      point8: 0,
      point9: 0,
      point10: 0,
      sumYes: "",
      sumNo: "",
      sumUnknown: "",
      sumPoint: 0,
      sasarLineListing: "",
      dsmbLetter: "",
      agencyBook: "",
      instituteBook: "",
      bookNumber: "",
      bookDate: "",
      signature: "",
      date: "",
    },
    sasarLineListing: "",
    dsmbLetter: "",
    agencyBook: "",
    bookDate: "",
    researchAllHeaders: [
      { text: "Version", value: "version", align: "center" },
      { text: "หน้า / บรรทัด", value: "page", align: "center" },
      { text: "ข้อมูลเดิม / ข้อเสนอแนะ", value: "data", align: "center" },
    ],
    itemResearchAll: [
      {
        version: "01",
        page:
          "การสำรวจข้อมูลพื้นฐานประชากรและความชุกของปัจจัยเสี่ยงทางด้านสุขภาพ",
        data: "เสร็จสิ้น",
      },
      {
        version: "02",
        page:
          "การศึกษาความชุกและปัจจัยเสี่ยงของโรคติดเชื้อปรสิตในลำไส้ในเด็กนักเรียน",
        data: "รอดำเนินการ",
      },
    ],
    dynamicDateChange: {
      fileOther: []
    },
    dateChange1: "",
    dateChange2: "",
    dateChange3: "",
    dateChange4: "",
    dateChange5: "",
  }),
  created() {
    // Check Permission
    // if (!this.$route.query.id && this.$route.query.status !== "อนุมัติ") {
    //   this.$router.go(-1);
    // } else {
    // Set data User
    this.form.research_pass = this.$route.query.researchCode;
    this.form.researchName = this.$route.query.nameResearch;
    this.form.party =
      this.$route.query.affiliation + " / " + this.$route.query.mobilePhone;
    this.updateDate("reportDate", new Date().toISOString().slice(0, 10));
    this.form.signature = this.$route.query.thName;
    this.updateDate("date", new Date().toISOString().slice(0, 10));
    if (this.$route.query.thName) {
      let text = this.$route.query.thName.split(" ");
      this.form.fname = text[0];
      this.form.signedResearcherFname = text[0];
      this.form.signedResearcherLname = text[1];
      this.form.lname = text[1];
    }
    if (this.$route.query.rfID) {
      console.log('is it2?')
      this.getRF04_1(this.$route.query.rfID);
    }
    // }
  },
  methods: {
    addDynamicFile(name) {
      this.form[name].push({ title: "", file: null });
    },
    updateDynamicFileData(name, type, index, value) {
      if (type === "file") {
        this.isLoading = true;
        this.$store
          .dispatch("Common/uploadPdf", { form: "rf-04-1other-file", file: value })
          .then((res) => {
            this.form[name][index].file = res.fileName;
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
          });
      } else {
        this.form[name][index][type] = value;
      }
    },
    removeDynamicFile(name, index) {
      this.form[name].splice(index, 1);
      this.$forceUpdate();
    },
    // updateDataBook(val) {
    //   console.log("book",val);
    //   this.form.instituteBook = val;
    // },
    updateData(name, val) {
      console.log("name", val);
      this.form[name] = val;
      this.form.sumPoint =
        parseInt(this.form.point1) +
        parseInt(this.form.point2) +
        parseInt(this.form.point3) +
        parseInt(this.form.point4) +
        parseInt(this.form.point5) +
        parseInt(this.form.point6) +
        parseInt(this.form.point7) +
        parseInt(this.form.point8) +
        parseInt(this.form.point9) +
        parseInt(this.form.point10);
    },
    clearData() {
      if (this.form.volunteer_checkbox === "1") {
        this.form.volunteer = "";
        this.form.volunteerForeign = "";
      } else if (this.form.volunteer_checkbox !== "3") {
        this.form.volunteerForeign = "";
      } else if (this.form.volunteer_checkbox !== "2") {
        this.form.volunteer = "";
      }
    },
    updateDate(name, val) {
      if (name === "birthDate") {
        this.form.birthDate = val;
        this.dateChange1 = this.changeDate(val);
        let today = new Date();
        let birthDay = new Date(val);
        this.form.year = today.getFullYear() - birthDay.getFullYear();
        if (today.getMonth() <= birthDay.getMonth()) {
          if (today.getDay() <= birthDay.getDay()) {
            this.form.year--;
          }
        }
      }
      if (name === "date") {
        this.form.date = val;
        this.dateChange3 = this.changeDate(val);
      }
      if (name === "reportDate") {
        this.form.reportDate = val;
        this.dateChange2 = this.changeDate(val);
      }
      if (name === "dateSymptom") {
        this.form.dateSymptom = val;
        this.dateChange4 = this.changeDate(val);
      }
      if (name === "dateKnow") {
        this.form.dateKnow = val;
        this.dateChange5 = this.changeDate(val);
      }
      if (name === "bookDate") {
        this.form.bookDate = val;
        this.bookDate = this.changeDate(val);
      }
    },
    changeDate(val) {
      if (val != null) {
        //change date start
        let sub_year = val.substring(0, 4);
        let sub_month = val.substring(5, 7);
        let sub_day = val.substring(8, 10);
        sub_year = parseInt(sub_year) + 543;
        let changeDate = sub_day + "/" + sub_month + "/" + sub_year;
        return changeDate;
      } else {
        return null;
      }
    },
    getRF04_1(id) {
      this.isLoading = true;
      this.$store
        .dispatch("FormRF/getRF04_1", id)
        .then((res) => {
          if (res.status !== "อนุมัติ") {
            this.form.instituteBook = res.instituteBook;
            this.form.fileOther = res.fileOther ?? [];
            this.form.noteDetail = res.noteDetail;
            this.form.research_pass = res.research_pass;
            this.form.researchName = res.researchName;
            this.form.fname = res.fname;
            this.form.lname = res.lname;
            this.form.party = res.party;
            this.form.volunteer_checkbox = res.volunteer_checkbox
              ? res.volunteer_checkbox.toString()
              : "";
            this.form.volunteer = res.volunteer;
            this.form.volunteerForeign = res.volunteerForeign;
            this.form.patient_initial = res.patient_initial;
            this.form.birthDate = res.birthDate;
            this.dateChange1 = res.birthDate ? this.changeDate(res.birthDate) : "";
            this.form.year = res.year;
            this.form.sex = res.sex;
            this.form.dateSymptom = res.dateSymptom;
            this.dateChange4 = res.dateSymptom
              ? this.changeDate(res.dateSymptom)
              : "";
            this.form.dateKnow = res.dateKnow;
            this.dateChange5 = res.dateKnow ? this.changeDate(res.dateKnow) : "";
            this.form.detail_events = res.detail_events;
            this.form.suspect_drug = res.suspect_drug;
            this.form.daily_doses = res.daily_doses;
            this.form.route = res.route;
            this.form.indications = res.indications;
            this.form.TherapyDateAndDuration = res.TherapyDateAndDuration;
            this.form.date_medication = res.date_medication;
            this.form.other_history = res.other_history;
            this.form.pharmaceutical_company_address =
              res.pharmaceutical_company_address;
            this.form.pharmaceuticalCode = res.pharmaceuticalCode;
            this.form.reportType = res.reportType;
            this.form.incident = res.incident;
            this.form.relationStudy = res.relationStudy;
            this.form.signedResearcherFname = res.signedResearcherFname;
            this.form.signedResearcherLname = res.signedResearcherLname;
            this.form.reportDate = res.reportDate;
            this.dateChange2 = res.reportDate
              ? this.changeDate(res.reportDate)
              : "";
            this.form.severity = res.severity;
            this.form.symptomDisappear = res.symptomDisappear;
            this.form.hasRecurrentAfterStop = res.hasRecurrentAfterStop
              ? res.hasRecurrentAfterStop.toString()
              : "";
            this.form.progression = res.progression;
            this.form.changesResearchProposal = res.changesResearchProposal;
            this.form.changeClarificationDocument =
              res.changeClarificationDocument;
            this.form.drugName = res.drugName;
            this.form.reportNumber = res.reportNumber;
            this.form.symptom = res.symptom;
            this.form.point1 = res.point1;
            this.form.point2 = res.point2;
            this.form.point3 = res.point3;
            this.form.point4 = res.point4;
            this.form.point5 = res.point5;
            this.form.point6 = res.point6;
            this.form.point7 = res.point7;
            this.form.point8 = res.point8;
            this.form.point9 = res.point9;
            this.form.point10 = res.point10;
            this.form.sumYes = res.sumYes;
            this.form.sumNo = res.sumNo;
            this.form.sumUnknown = res.sumUnknown;
            this.form.sumPoint = res.sumPoint;

            this.agencyBookPath = res.agencyBookPath;
            this.agencyBook = res.agencyBook;
            this.sasarLineListingPath = res.sasarLineListingPath;
            this.dsmbLetterPath = res.dsmbLetterPath;
            this.sasarLineListing = res.sasarLineListing;
            this.dsmbLetter = res.dsmbLetter;
            this.form.bookNumber = res.bookNumber;
            this.form.bookDate = !res.bookDate
              ? ""
              : res.bookDate
            this.bookDate = this.changeDate(res.bookDate)
            this.form.signature = res.signature;
            this.form.date = res.date;
            this.dateChange3 = res.date ? this.changeDate(res.date) : "";
            console.log('this.date', this.date)
          }
          this.isLoading = false;
        })
        .catch((e) => {
          this.isLoading = false;
        });
    },
    saveDraft() {
      Swal.fire({
        title: "คุณต้องการบันทึกแบบร่างหรือไม่?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3E7662",
        cancelButtonColor: "rgb(189, 189, 189)",
        confirmButtonText: "ใช่ บันทึกแบบร่าง",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.value) {
          this.form["researchID"] = this.$route.query.researchID;
          if (this.$route.query.rfID) {
            this.form["methods"] = "edit";
            this.form["rfID"] = this.$route.query.rfID;
          } else {
            this.form["status"] = "บันทึกแบบร่าง";
            this.form["methods"] = "save";
          }
          this.isLoading = true;
          this.$store
            .dispatch("FormRF/saveRF04_1", this.form)
            .then((res) => {
              this.isLoading = false;
              Swal.fire({
                position: "center",
                icon: "success",
                title: "บันทึกแบบร่างสำเร็จ",
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                this.$router.push("/profile");
              });
            })
            .catch((e) => {
              this.isLoading = false;
              if (e.response.data.errors) {
                for (var k in e.response.data.errors) {
                  Swal.fire({
                    title: "เกิดข้อผิดพลาด",
                    text: e.response.data.errors[k][0],
                    icon: "warning",
                    confirmButtonColor: "#3E7662",
                  }).then(() => {
                    this.$vuetify.goTo(`#${k}`);
                  });
                }
              }
              // Swal.fire({
              //     title: 'Save failed!',
              //     text: 'บันทึกไม่สำเร็จ',
              //     icon: 'warning',
              // }).then(() => {
              //     this.isLoading = false;
              // })
            });
        }
        this.isLoading = false;
      });
    },
    save() {
      Swal.fire({
        title: "คุณต้องการบันทึกหรือไม่?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3E7662",
        cancelButtonColor: "rgb(189, 189, 189)",
        confirmButtonText: "ใช่ บันทึก",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.value) {
          this.form["researchID"] = this.$route.query.researchID;
          if (this.$route.query.rfID) {
            this.form["methods"] = "edit";
            this.form["rfID"] = this.$route.query.rfID;
          } else {
            this.form["methods"] = "save";
          }
          this.isLoading = true;
          this.$store
            .dispatch("FormRF/saveRF04_1", this.form)
            .then((res) => {
              this.isLoading = false;
              Swal.fire({
                position: "center",
                icon: "success",
                title: "บันทึกสำเร็จ",
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                this.$router.push("/profile");
              });
            })
            .catch((e) => {
              this.isLoading = false;
              if (e.response.data.errors) {
                for (var k in e.response.data.errors) {
                  Swal.fire({
                    title: "เกิดข้อผิดพลาด",
                    text: e.response.data.errors[k][0],
                    icon: "warning",
                    confirmButtonColor: "#3E7662",
                  }).then(() => {
                    this.$vuetify.goTo(`#${k}`);
                  });
                }
              }
              // Swal.fire({
              //     title: 'Save failed!',
              //     text: 'บันทึกไม่สำเร็จ',
              //     icon: 'warning',
              // }).then(() => {
              //     this.isLoading = false;
              // })
            });
        }
        this.isLoading = false;
      });
    },
    otherChangeCheckbox() {
      if (!this.form.other_change_checkbox) {
        this.form.other_change = "";
      }
    },
    changeTitleCheckbox() {
      if (!this.form.change_title_checkbox) {
        this.form.change_title = "";
      }
    },
    onUpload(type, e) {
      this.$store
        .dispatch("Common/uploadPdf", { form: "rf04_1", file: e })
        .then((res) => {
          switch (type) {
            case "sasarLineListing":
              this.form.sasarLineListing = res.fileName;
              break;
            case "dsmbLetter":
              this.form.dsmbLetter = res.fileName;
              break;
            case "agencyBooks":
              this.form.agencyBook = res.fileName;
              break;
          }
        })
        .catch((error) => { });
    },

    onClickBtn(type) {
      switch (type) {
        case "sasarLineListing":
          document.getElementById(type).click();
          break;
        case "dsmbLetter":
          document.getElementById(type).click();
          break;
        case "agencyBook":
          document.getElementById(type).click();
          break;
        default:
          return false;
      }
    },
    onClickAddBtn() { },
  },
};
</script>

<style lang="scss">
label.v-label.theme--light {
  color: #544a44;
}

.btn-hover:hover {
  padding: 5px;
  background-color: rgba(84, 74, 68, 0.22);
}
</style>
