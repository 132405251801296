<template>
    <v-content>
        <v-layout class="body">
            <v-flex>
                <v-layout mt -12 py-5 style="font-weight: bold;font-size: 30px; text-align: center; color: #3E7662">
                    <v-flex>
                        <span>ข่าวสารทั้งหมด</span>
                    </v-flex>
                </v-layout>

                <!-- Content News -->
                <v-layout pb-12 justify-center >
                    <!-- Even -->
                    <v-flex xs6>
                        <v-layout mb-6 v-for="(item,i) in news_even" :key="i"  >
                            <v-flex xs12 px-12 style="text-align: left;" >
                                <v-layout style="max-width: 520px;margin: auto">
                                    <v-flex>
                                        <v-layout>
                                            <v-flex mb-2 style="text-align: center">
                                                <!--<img-->
                                                        <!--:src="item.mainPicture" alt=""-->
                                                        <!--style="max-width: 520px;"-->
                                                        <!--width="100%"-->
                                                        <!--height="auto"-->
                                                <!--&gt;-->
                                                <a @click="readNews(item.id)">

                                                <v-img
                                                        :src="item.mainPicture" alt=""
                                                        style="max-width: 520px;max-height: 300px;margin:auto"
                                                        width="100%"
                                                        height="auto"
                                                        aspect-ratio="1.73"
                                                />
                                                </a>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout>
                                            <v-flex style="font-size: 18px;font-weight: bold;color: #3E7662">
                                                <a @click="readNews(item.id)" class="one-line" style="color: #3E7662">{{item.topic}}</a>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout>
                                            <v-flex pt-3 style="font-size: 14px;color: #000000">
                                                <span class="two-line">
                                                    {{item.shortDescription}}
                                                </span>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout>
                                            <v-flex pt-6 style="font-size: 12px;color: #544A44">
                                                <span class="one-line">{{item.eventPlace}}</span>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                                <v-layout>
                                    <v-flex>
                                        <v-divider class="mt-4" style="background-color: #3E7662;border-width: 1px;max-width: 520px;margin: auto"></v-divider>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                    <!-- Odd -->
                    <v-flex xs6>
                        <v-layout mb-6 v-for="(item,i) in news_odd" :key="i" >
                            <!-- Odd -->
                            <v-flex xs12 px-12 style="text-align: left;">
                                <v-layout style="max-width: 520px;margin: auto">
                                    <v-flex>
                                        <v-layout>
                                            <v-flex mb-2 style="text-align: center">
                                                <!--<img-->
                                                        <!--:src="item.mainPicture" alt=""-->
                                                        <!--style="max-width: 520px;"-->
                                                        <!--width="100%"-->
                                                        <!--height="auto"-->
                                                <!--&gt;-->

                                                <a @click="readNews(item.id)">

                                                <v-img
                                                        :src="item.mainPicture" alt=""
                                                        style="max-width: 520px;max-height: 300px;margin:auto"
                                                        width="100%"
                                                        height="auto"
                                                        aspect-ratio="1.73"
                                                />
                                                </a>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout>
                                            <v-flex style="font-size: 18px;font-weight: bold;color: #3E7662">
                                                <a @click="readNews(item.id)" class="one-line" style="color: #3E7662">{{item.topic}}</a>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout>
                                            <v-flex pt-3 style="font-size: 14px;color: #000000">
                                <span class="two-line">
                                    {{item.shortDescription}}
                                </span>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout>
                                            <v-flex pt-6 style="font-size: 12px;color: #544A44">
                                                <span class="one-line">{{item.eventPlace}}</span>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                                <v-layout>
                                    <v-flex>
                                        <v-divider class="mt-4" style="background-color: #3E7662;border-width: 1px;max-width: 520px;margin: auto"></v-divider>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>

                <!-- Paginate -->
                <v-layout mt-8 >
                    <v-flex>
                        <v-pagination
                                color="#3E7662"
                                :length="paginate.last_page"
                                :total-visible="9"
                                @input="getNews"
                                v-model="paginate.current_page"
                        ></v-pagination>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>

        <loading
                color="#527281"
                :active.sync="isLoading"
                :can-cancel="false"
                :is-full-page="fullPage"
        ></loading>

    </v-content>
</template>

<script>
    import Loading from 'vue-loading-overlay';
    import Pic from '../assets/default-image.jpg'

    export default {
        name: 'AllNews',
        components: {
            Loading
        },
        data: () => ({
            news_odd: [],
            news_even: [],
            isLoading: false,
            fullPage: true,
            paginate: {
                current_page: 1,
                from: 1,
                last_page: 1,
                per_page: 1,
                total: 1,
                to: 1
            },
        }),
        created() {
            this.getNews(1);
        },
        methods: {
            getNews(page){
                this.loading = true;
                this.news_even = [];
                this.news_odd = [];
                this.$store.dispatch('Common/getAllNews',page).then(res => {
                    const items = res.data;
                    for (const [key, value] of Object.entries(items)) {
                        if (key %2 === 0) {
                            this.news_even.push({
                                id: value.id,
                                topic: this.subString(value.topic,'topic'),
                                description: value.description,
                                shortDescription: this.subString(value.shortDescription,'des'),
                                mainPicture: value.mainPicture ? value.mainPicture : Pic,
                                eventPlace: value.eventPlace,
                                eventDate: value.eventDate,
                            })
                        } else {
                            this.news_odd.push({
                                id: value.id,
                                topic: this.subString(value.topic,'topic'),
                                description: value.description,
                                shortDescription: this.subString(value.shortDescription,'des'),
                                mainPicture: value.mainPicture ? value.mainPicture : Pic,
                                eventPlace: value.eventPlace,
                                eventDate: value.eventDate,
                            })
                        }
                    }
                });
                this.loading = false;
            },
            readNews(id){
                this.$router.push({name:'ReadNews', query:{news_id: id}})
            },
            subString(val,name) {
                if (name === 'topic') {
                    if (val.length <= 70) {
                        return val;
                    } else {
                        return val.substring(0,70)+". . . . ";
                    }
                } else {
                    if (val.length <= 150) {
                        return val;
                    } else {
                        return val.substring(0,150)+". . . . ";
                    }
                }
            }
        }
    }
</script>