<template>
  <v-content>
    <v-layout style="background-color: #faf5f2">
      <v-flex>
        <v-layout mb-3 mt-6>
          <v-flex
            style="color: #3e7662; font-weight: bold; font-size: 28px"
            class="text-center"
          >
            <span>แบบฟอร์ม</span>
          </v-flex>
        </v-layout>
        <v-layout style="margin-left: 100px; margin-right: 100px">
          <v-flex>
            <v-layout mt-3 style="background-color: #3e7662">
              <v-flex
                my-8
                ml-8
                style="text-align: left; font-size: 28px; color: white"
              >
                <div style="font-weight: bold">
                  แบบรายงานเหตุการณ์ไม่พึงประสงค์นอกสถาบัน SUSAR / DMC / DSMB
                </div>
                <div style="font-size: 18px">
                  Off-campus adverse event reporting form
                </div>
              </v-flex>
              <v-flex
                ml-8
                pt-8
                pr-8
                style="
                  text-align: right;
                  font-weight: bold;
                  font-size: 13px;
                  color: white;
                "
              >
                <span>RFXX_2023_00</span>
              </v-flex>
            </v-layout>
            <v-card
              class="py-12"
              style="padding-right: 80px; padding-left: 80px"
            >
              <v-layout>
                <v-flex>
                  <v-layout>
                    <v-flex>
                      <textfield-form
                        :model="form.researchCode"
                        @update-data="updateData('researchCode', $event)"
                        label="รหัสโครงร่างการวิจัย"
                        readonly
                      >
                      </textfield-form>
                    </v-flex>
                    <v-flex ml-3 style="visibility: hidden" disabled>
                      <textfield-form label="นามสกุล" disabled></textfield-form>
                    </v-flex>
                  </v-layout>
                  <v-layout ml-13>
                    <v-flex>
                      <textfield-form
                        :model="form.firstName"
                        @update-data="updateData('firstResearcher', $event)"
                        label="ชื่อผู้วิจัยหลัก"
                        readonly
                      ></textfield-form>
                    </v-flex>
                    <v-flex ml-3>
                      <textfield-form
                        :model="form.lastName"
                        @update-data="updateData('lastResearcher', $event)"
                        label="นามสกุล"
                        readonly
                      ></textfield-form>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex>
                      <textfield-form
                        :model="form.researchName"
                        @update-data="updateData('nameResearch', $event)"
                        label="ชื่อโครงการวิจัย(ไทย)"
                        readonly
                      >
                      </textfield-form>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex>
                      <v-layout>
                        <v-flex class="d-flex font-weight-bold" shrink>
                          วันที่ผู้วิจัยรายงาน
                        </v-flex>
                        <v-flex ml-8 class="text-end text-field-center">
                          <DateInputForm
                            :model="startReport"
                            @update-data="updateDate('startReport', $event, k)"
                            style="width: 20%"
                          ></DateInputForm>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout style="margin-left: 100px; margin-right: 100px">
          <v-flex>
            <v-layout mt-12 style="background-color: #3e7662">
              <v-flex
                my-8
                ml-8
                style="text-align: center; font-size: 20px; color: white"
              >
                <div style="font-weight: bold">รายละเอียดรายงาน</div>
              </v-flex>
            </v-layout>
            <v-card
              class="py-12"
              style="padding-right: 80px; padding-left: 80px"
            >
              <v-flex>
                <v-layout>
                  <v-flex pb-10 shrink>
                    <v-checkbox
                      v-model="form.hasUnexpectedEvents"
                      v-bind:true-value="1"
                      v-bind:false-value="0"
                      hide-details
                      color="#3E7662"
                      label="เหตุการณ์ไม่พึ่งประสงค์นอกสถาบัน"
                    ></v-checkbox>
                  </v-flex>
                </v-layout>
                <div v-if="form.hasUnexpectedEvents === 1">
                  <div
                    v-for="(input, k) in form.newRowUnexpectedEvents"
                    :key="'c' + k"
                  >
                    <v-card
                      class="py-12 mt-4"
                      style="padding-right: 80px; padding-left: 80px"
                    >
                      <v-layout justify-end>
                        <v-flex ml-6 shrink d-flex justify-end>
                          <v-btn
                            v-if="k === 0"
                            @click="add('UnexpectedEvents')"
                            dark
                            style="width: 80px; background-color: #3e7662"
                          >
                            <v-icon>add</v-icon>
                            <span>เพิ่ม</span>
                          </v-btn>
                        </v-flex>
                        <v-flex shrink d-flex justify-end>
                          <v-btn
                            v-if="k != 0"
                            dark
                            @click="remove('UnexpectedEvents', k)"
                            style="width: 80px; background-color: red"
                          >
                            <v-icon>close</v-icon>
                            <span>ลบ</span>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                      <v-layout pb-6>
                        <v-flex
                          xs2
                          mr-4
                          d-flex
                          align-center
                          justify-end
                          style="text-align: right; font-weight: bold"
                        >
                          <span>ระยะเวลา</span>
                        </v-flex>
                        <v-flex
                          xs3
                          style="margin-right: -50px"
                          class="text-end text-field-center"
                        >
                          <DateInputForm
                            :model="input.startDateEvents"
                            @update-data="
                              updateDate('startDateEvents', $event, k)
                            "
                            style="width: 70%"
                            hide-detail
                          ></DateInputForm>
                        </v-flex>
                        <v-flex
                          xs1
                          d-flex
                          align-center
                          style="font-weight: bold"
                        >
                          <span>ถึง</span>
                        </v-flex>
                        <v-flex
                          xs3
                          style="margin-left: -40px"
                          class="text-end text-field-center"
                        >
                          <DateInputForm
                            :model="input.endDateEvents"
                            @update-data="
                              updateDate('endDateEvents', $event, k)
                            "
                            style="width: 70%"
                            hide-detail
                          ></DateInputForm>
                        </v-flex>
                      </v-layout>
                      <v-layout pt-8 pb-6>
                        <v-flex
                          xs2
                          mr-4
                          d-flex
                          align-center
                          justify-end
                          style="text-align: right; font-weight: bold"
                        >
                          <span>จำนวนรายการไม่พึงประสงค์</span>
                        </v-flex>
                        <v-flex d-flex align-center xs3>
                          <textfield-form
                            :model="input.amountUnexpectedEvents"
                            @update-data="
                              updateDataMultiRow(
                                'amountUnexpectedEvents',
                                $event,
                                k
                              )
                            "
                            hide-detail
                          ></textfield-form>
                        </v-flex>
                        <v-flex
                          xs1
                          ml-4
                          d-flex
                          align-center
                          style="text-align: right; font-weight: bold"
                        >
                          <span>ราย</span>
                        </v-flex>
                      </v-layout>
                      <v-layout pt-8 pb-6>
                        <v-flex
                          xs2
                          mr-4
                          d-flex
                          align-center
                          justify-end
                          style="text-align: right; font-weight: bold"
                        >
                          <span>จำนวนอาสาสมัคร</span>
                        </v-flex>
                        <v-flex d-flex align-center xs4>
                          <textfield-form
                            :model="input.amountMembers"
                            @update-data="
                              updateDataMultiRow('amountMembers', $event, k)
                            "
                            hide-detail
                          ></textfield-form>
                        </v-flex>
                        <v-flex
                          xs1
                          ml-4
                          d-flex
                          align-center
                          style="text-align: right; font-weight: bold"
                        >
                          <span>ราย</span>
                        </v-flex>
                      </v-layout>
                      <v-layout pt-8 pb-6>
                        <v-flex
                          xs2
                          mr-4
                          d-flex
                          align-center
                          justify-end
                          style="text-align: right; font-weight: bold"
                        >
                          <span>Note</span>
                        </v-flex>
                      </v-layout>
                      <v-layout>
                        <v-flex style="padding-left: 32px; padding-right: 32px">
                          <textarea-form
                            :model="input.note"
                            @update-data="updateDataMultiRow('note', $event, k)"
                            :rows="10"
                          />
                        </v-flex>
                      </v-layout>

                      <div
                        v-for="(file, i) in input.newRowUnexpectedEventsFile"
                        :key="'c' + i"
                      >
                        <!-- <p>{{k + "," + i}}</p> -->
                        <v-layout
                          px-12
                          :style="[
                            i >= 1
                              ? { 'margin-top': '20px' }
                              : { 'margin-top': '0px' },
                          ]"
                        >
                          <v-flex
                            shrink
                            pt-2
                            pr-4
                            style="text-align: left"
                            class="justify-end d-flex"
                          >
                            <span style="font-weight: bold; font-size: 16px"
                              >ไฟล์</span
                            >
                          </v-flex>
                          <v-flex xs3 class="d-flex">
                            <v-file-input
                              accept=".pdf"
                              prepend-icon=""
                              outlined
                              dense
                              :id="'UnexpectedEventsFile_' + i"
                              placeholder="Choose file"
                              color="#3E7662"
                              item-color="#3E7662"
                              hide-details
                              :model="file.file"
                              @change="
                                onUpload('UnexpectedEventsFile', $event, k, i)
                              "
                            ></v-file-input>
                          </v-flex>
                          <v-flex shrink pl-4 style="text-align: left">
                            <v-btn
                              @click="chooseFiles('UnexpectedEventsFile_' + i)"
                              color="#3E7662"
                              dark
                            >
                              <v-icon class="pr-3">cloud_download</v-icon>
                              <span>Upload</span>
                            </v-btn>
                          </v-flex>
                          <v-flex v-if="file.filePath" ml-5 pt-1>
                            <a
                              :href="file.filePath"
                              target="_blank"
                              style="color: #3e7662;text-align: center"
                              class="link-hover"
                            >
                              <p>ไฟล์ที่อัพโหลด: {{ file.file }}</p>
                            </a>
                          </v-flex>
                          <v-layout justify-end>
                            <v-flex ml-6 shrink d-flex justify-end>
                              <v-btn
                                dark
                                v-if="i === 0"
                                @click="add('UnexpectedEventsFile', k)"
                                style="width: 80px; background-color: #3e7662"
                              >
                                <v-icon>add</v-icon>
                                <span>เพิ่ม</span>
                              </v-btn>
                            </v-flex>
                            <v-flex shrink d-flex justify-end>
                              <v-btn
                                v-if="i != 0"
                                dark
                                @click="remove('UnexpectedEventsFile', k, i)"
                                style="width: 80px; background-color: red"
                              >
                                <v-icon>close</v-icon>
                                <span>ลบ</span>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </v-layout>
                      </div>
                    </v-card>
                  </div>
                </div>
                <v-layout style="margin-top: 30px">
                  <v-flex pb-10 shrink>
                    <v-checkbox
                      hide-details
                      v-model="form.hasDDLetter"
                      v-bind:true-value="1"
                      v-bind:false-value="0"
                      color="#3E7662"
                      label="DMC หรือ DSMB letter"
                    ></v-checkbox>
                  </v-flex>
                </v-layout>
                <div v-if="form.hasDDLetter === 1">
                  <v-card
                    class="py-12"
                    style="padding-right: 80px; padding-left: 80px"
                  >
                    <div
                      v-for="(input, k) in form.newRowDDLetter"
                      :key="'c' + k"
                    >
                      <v-layout
                        px-12
                        :style="[
                          k >= 1
                            ? { 'margin-top': '20px' }
                            : { 'margin-top': '0px' },
                        ]"
                      >
                        <v-flex
                          shrink
                          pt-2
                          pr-4
                          style="text-align: left"
                          class="justify-end d-flex"
                        >
                          <span style="font-weight: bold; font-size: 16px"
                            >ไฟล์</span
                          >
                        </v-flex>

                        <v-flex xs3 class="d-flex">
                          <v-file-input
                            accept=".pdf"
                            prepend-icon=""
                            outlined
                            dense
                            :id="'DDLetterFile_' + k"
                            placeholder="Choose file"
                            color="#3E7662"
                            item-color="#3E7662"
                            hide-details
                            :model="input.file"
                            @change="onUpload('DDletterFile', $event, k)"
                          ></v-file-input>
                        </v-flex>
                        <v-flex shrink pl-4 style="text-align: left">
                          <v-btn
                            @click="chooseFiles('DDLetterFile_' + k)"
                            color="#3E7662"
                            dark
                          >
                            <v-icon class="pr-3">cloud_download</v-icon>
                            <span>Upload</span>
                          </v-btn>
                        </v-flex>
                        <v-flex v-if="input.filePath" ml-5 pt-1>
                          <a
                            :href="input.filePath"
                            target="_blank"
                            style="color: #3e7662;text-align: center"
                            class="link-hover"
                          >
                            <p>ไฟล์ที่อัพโหลด: {{ input.file }}</p>
                          </a>
                        </v-flex>
                        <v-layout justify-end>
                          <v-flex ml-6 shrink d-flex justify-end>
                            <v-btn
                              dark
                              v-if="k === 0"
                              @click="add('DDLetter')"
                              style="width: 80px; background-color: #3e7662"
                            >
                              <v-icon>add</v-icon>
                              <span>เพิ่ม</span>
                            </v-btn>
                          </v-flex>
                          <v-flex shrink d-flex justify-end>
                            <v-btn
                              v-if="k != 0"
                              dark
                              @click="remove('DDLetter', k)"
                              style="width: 80px; background-color: red"
                            >
                              <v-icon>close</v-icon>
                              <span>ลบ</span>
                            </v-btn>
                          </v-flex>
                        </v-layout>
                      </v-layout>
                    </div>
                  </v-card>
                </div>

                <v-layout style="margin-top: 30px">
                  <v-flex pb-10 shrink>
                    <v-checkbox
                      hide-details
                      v-model="form.hasSafetyReport"
                      v-bind:true-value="1"
                      v-bind:false-value="0"
                      color="#3E7662"
                      label="Annual Safety report ปี"
                    ></v-checkbox>
                  </v-flex>
                </v-layout>
                <div v-if="form.hasSafetyReport === 1">
                  <v-card
                    class="py-12"
                    style="padding-right: 80px; padding-left: 80px"
                  >
                    <div
                      v-for="(input, k) in form.newRowSafetyReport"
                      :key="'c' + k"
                    >
                      <v-layout
                        px-12
                        :style="[
                          k >= 1
                            ? { 'margin-top': '20px' }
                            : { 'margin-top': '0px' },
                        ]"
                      >
                        <v-flex
                          shrink
                          pt-2
                          pr-4
                          style="text-align: left"
                          class="justify-end d-flex"
                        >
                          <span style="font-weight: bold; font-size: 16px"
                            >ไฟล์</span
                          >
                        </v-flex>

                        <v-flex xs3 class="d-flex">
                          <v-file-input
                            accept=".pdf"
                            prepend-icon=""
                            outlined
                            dense
                            :id="'SafetyReportFile_' + k"
                            placeholder="Choose file"
                            color="#3E7662"
                            item-color="#3E7662"
                            hide-details
                            :model="input.file"
                            @change="onUpload('SafetyReportFile', $event, k)"
                          ></v-file-input>
                        </v-flex>
                        <v-flex shrink pl-4 style="text-align: left">
                          <v-btn
                            @click="chooseFiles('SafetyReportFile_' + k)"
                            color="#3E7662"
                            dark
                          >
                            <v-icon class="pr-3">cloud_download</v-icon>
                            <span>Upload</span>
                          </v-btn>
                        </v-flex>
                        <v-flex v-if="input.filePath" ml-5 pt-1>
                          <a
                            :href="input.filePath"
                            target="_blank"
                            style="color: #3e7662;text-align: center"
                            class="link-hover"
                          >
                            <p>ไฟล์ที่อัพโหลด: {{ input.file }}</p>
                          </a>
                        </v-flex>
                        <v-layout justify-end>
                          <v-flex ml-6 shrink d-flex justify-end>
                            <v-btn
                              dark
                              v-if="k === 0"
                              @click="add('SafetyReport')"
                              style="width: 80px; background-color: #3e7662"
                            >
                              <v-icon>add</v-icon>
                              <span>เพิ่ม</span>
                            </v-btn>
                          </v-flex>
                          <v-flex shrink d-flex justify-end>
                            <v-btn
                              v-if="k != 0"
                              dark
                              @click="remove('SafetyReport', k)"
                              style="width: 80px; background-color: red"
                            >
                              <v-icon>close</v-icon>
                              <span>ลบ</span>
                            </v-btn>
                          </v-flex>
                        </v-layout>
                      </v-layout>
                    </div>
                  </v-card>
                </div>

                <v-layout style="margin-top: 30px">
                  <v-flex pb-10 shrink>
                    <v-checkbox
                      v-model="form.hasMoreReport"
                      v-bind:true-value="1"
                      v-bind:false-value="0"
                      hide-details
                      color="#3E7662"
                      label="เอกสารอื่น ๆ (โปรดระบุ)"
                    ></v-checkbox>
                  </v-flex>
                </v-layout>

                <div v-if="form.hasMoreReport === 1">
                  <div
                    v-for="(input, k) in form.newRowMoreReport"
                    :key="'c' + k"
                  >
                    <v-card
                      class="py-12 mt-4"
                      style="padding-right: 80px; padding-left: 80px"
                    >
                      <v-layout justify-end>
                        <v-flex ml-6 shrink d-flex justify-end>
                          <v-btn
                            dark
                            v-if="k == 0"
                            @click="add('MoreReport')"
                            style="width: 80px; background-color: #3e7662"
                          >
                            <v-icon>add</v-icon>
                            <span>เพิ่ม</span>
                          </v-btn>
                        </v-flex>
                        <v-flex shrink d-flex justify-end>
                          <v-btn
                            v-if="k != 0"
                            dark
                            @click="remove('MoreReport', k)"
                            style="width: 80px; background-color: red"
                          >
                            <v-icon>close</v-icon>
                            <span>ลบ</span>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                      <v-layout ml-13>
                        <v-flex xs5>
                          <textfield-form
                            :model="input.nameDoc"
                            @update-data="
                              updateDataMultiRow('nameDoc', $event, k)
                            "
                            label="ชื่อเอกสาร"
                          ></textfield-form>
                        </v-flex>
                        <v-flex
                          shrink
                          pt-2
                          pr-4
                          style="text-align: left"
                          class="justify-end d-flex"
                        >
                          <span
                            style="
                              font-weight: bold;
                              font-size: 16px;
                              margin-left: 18px;
                            "
                            >ไฟล์</span
                          >
                        </v-flex>
                        <v-flex xs3 class="d-flex">
                          <v-file-input
                            accept=".pdf"
                            prepend-icon=""
                            outlined
                            dense
                            id="reportFile"
                            placeholder="Choose file"
                            color="#3E7662"
                            item-color="#3E7662"
                            hide-details
                            @change="onUpload('fileMoreReport', $event, k)"
                          ></v-file-input>
                        </v-flex>
                        <v-flex shrink pl-4 style="text-align: left">
                          <v-btn
                            @click="chooseFiles('reportFile')"
                            color="#3E7662"
                            dark
                          >
                            <v-icon class="pr-3">cloud_download</v-icon>
                            <span>Upload</span>
                          </v-btn>
                        </v-flex>
                        <v-flex v-if="input.filePath2" ml-5 pt-1>
                          <a
                            :href="input.filePath2"
                            target="_blank"
                            style="color: #3e7662;text-align: center"
                            class="link-hover"
                          >
                            <p>ไฟล์ที่อัพโหลด: {{ input.fileMoreReport }}</p>
                          </a>
                        </v-flex>
                      </v-layout>
                      <v-layout ml-13>
                        <v-flex xs5>
                          <textfield-form
                            :model="input.instituteBook"
                            @update-data="
                              updateDataMultiRow('instituteBook', $event, k)
                            "
                            label="หน่วยงานที่ออกหนังสือ"
                          ></textfield-form>
                        </v-flex>
                        <v-flex
                          shrink
                          pt-2
                          pr-4
                          style="text-align: left"
                          class="justify-end d-flex"
                        >
                          <span
                            style="
                              font-weight: bold;
                              font-size: 16px;
                              margin-left: 18px;
                            "
                            >หนังสือหน่วยงาน</span
                          >
                        </v-flex>

                        <v-flex xs3 class="d-flex">
                          <v-file-input
                            accept=".pdf"
                            prepend-icon=""
                            outlined
                            dense
                            :id="'MoreReportFile_' + k"
                            placeholder="Choose file"
                            color="#3E7662"
                            item-color="#3E7662"
                            hide-details
                            @change="onUpload('fileDoc', $event, k)"
                          ></v-file-input>
                        </v-flex>
                        <v-flex shrink pl-4 style="text-align: left">
                          <v-btn
                            @click="chooseFiles('MoreReportFile_' + k)"
                            color="#3E7662"
                            dark
                          >
                            <v-icon class="pr-3">cloud_download</v-icon>
                            <span>Upload</span>
                          </v-btn>
                        </v-flex>
                        <v-flex v-if="input.filePath" ml-5 pt-1>
                          <a
                            :href="input.filePath"
                            target="_blank"
                            style="color: #3e7662;text-align: center"
                            class="link-hover"
                          >
                            <p>ไฟล์ที่อัพโหลด: {{ input.file }}</p>
                          </a>
                        </v-flex>
                      </v-layout>
                      <v-layout ml-13 mt-7>
                        <v-flex>
                          <textfield-form
                            :model="input.bookNumber"
                            @update-data="
                              updateDataMultiRow('bookNumber', $event, k)
                            "
                            label="เลขที่หนังสือ"
                          ></textfield-form>
                        </v-flex>
                        <v-layout px-12>
                          <v-flex
                            shrink
                            pt-2
                            pr-4
                            style="text-align: left"
                            class="justify-end d-flex"
                          >
                            <span
                              style="
                                font-weight: bold;
                                font-size: 16px;
                                margin-left: -30px;
                                margin-right: -30px;
                              "
                              >วันที่หนังสือ</span
                            >
                          </v-flex>
                          <v-flex ml-8 class="text-end text-field-center">
                            <DateInputForm
                              hide-detail
                              :model="input.bookDate"
                              @update-data="updateDate('bookDate', $event, k)"
                              style="width: 37%"
                            ></DateInputForm>
                          </v-flex>
                        </v-layout>
                      </v-layout>
                    </v-card>
                  </div>
                </div>

                <v-layout style="margin-top: 50px">
                  <v-flex xs6>
                    <v-layout>
                      <v-flex
                        xs3
                        mt-4
                        class="justify-start font-weight-bold d-flex align-center"
                      >
                        <span style="margin-left: 95px">ผู้วิจัย</span>
                      </v-flex>
                      <v-flex xs6 class="text-start text-field-center">
                        <v-text-field
                          hide-details
                          color="#3E7662"
                          append-icon="edit"
                          v-model="form.signature"
                        />
                      </v-flex>
                    </v-layout>
                    <v-layout>
                      <v-flex xs3></v-flex>
                      <v-flex xs6 class="text-field-center">
                        <v-layout>
                          <v-flex mr-2 mt-5 shrink class="d-flex align-center">
                            <span>(</span>
                          </v-flex>
                          <v-flex>
                            <v-text-field
                              v-model="form.signature"
                              hide-details
                              color="#3E7662"
                            />
                          </v-flex>
                          <v-flex ml-2 mt-5 shrink class="d-flex align-center">
                            <span>)</span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-spacer></v-spacer>
                    </v-layout>
                  </v-flex>
                  <!--                                        <v-spacer></v-spacer>-->
                  <v-flex xs4 class="text-end">
                    <v-layout>
                      <v-spacer></v-spacer>
                      <v-flex
                        xs3
                        mr-2
                        class="text-end font-weight-bold d-flex align-center"
                      >
                        <span>วันที่</span>
                      </v-flex>
                      <v-flex
                        style="margin-right: 150px"
                        xs7
                        class="text-end text-field-center"
                      >
                        <DateInputForm
                          hide-detail
                          :model="date"
                          @update-data="updateDate('date', $event)"
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v-layout style="margin-top: 30px">
                  <v-flex ml-8 my-5 style="text-align: left;">
                    <span style="font-weight: bold">Note</span>
                    <span style="color: red"> *รายการที่ต้องแก้ไข</span>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex style="padding-left: 32px; padding-right: 32px">
                    <textarea-form
                      readonly
                      :model="form.noteDetail"
                      :rows="5"
                    ></textarea-form>
                  </v-flex>
                </v-layout>
                <v-layout pt-16 justify-space-between="">
                  <v-flex pt-10 d-flex justify-start>
                    <span>สำนักงานคณะอนุกรรมการพิจารณาโครงการวิจัย พบ.</span>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-card>
            <v-layout pt-10></v-layout>
            <v-layout mt-6>
              <v-flex mb-12 style="text-align: right">
                <v-layout>
                  <v-flex>
                    <v-btn
                      style="color: #3e7662"
                      depressed
                      @click="saveDraft()"
                      color="#ffffff"
                      >บันทึกร่าง</v-btn
                    >
                    <v-btn
                      depressed
                      @click="save()"
                      dark
                      class="mx-4"
                      color="#3E7662"
                    >
                      <span>บันทึกข้อมูล</span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <loading
        color="#527281"
        :active.sync="isLoading"
        :can-cancel="false"
      >
      </loading>
    </v-layout>
  </v-content>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import TextareaForm from "../../components/input/TextareaForm";
import TextfieldForm from "../../components/input/TextfieldForm";
import DateInputForm from "../../components/input/DateInputForm";
import Swal from "sweetalert2";
import { mapState } from "vuex";
export default {
  name: "FormSusar",
  components: {
    TextareaForm,
    TextfieldForm,
    DateInputForm,
    Loading,
  },
  computed: {
    ...mapState({
      user: (state) => state.Auth.user,
    }),
  },
  data: () => ({
    form: {
      noteDetail: "",
      hasUnexpectedEvents: 0,
      hasDDLetter: 0,
      hasSafetyReport: 0,
      hasMoreReport: 0,
      researchCode: "",
      firstName: "",
      lastName: "",
      researchName: "",
      signature: "",
      newRowUnexpectedEvents: [
        {
          startDateEvents: "",
          endDateEvents: "",
          amountUnexpectedEvents: "",
          amountMembers: "",
          note: "",
          newRowUnexpectedEventsFile: [
            {
              file: "",
            },
          ],
        },
      ],
      newRowDDLetter: [
        {
          file: "",
        },
      ],
      newRowSafetyReport: [
        {
          file: "",
        },
      ],
      newRowMoreReport: [
        {
          nameDoc: "",
          fileMoreReport: "",
          file: "",
          bookNumber: "",
          bookDate: "",
          instituteBook: "",
        },
      ],
      startReport: "",
      date: "",
    },
    startReport: "",
    date: "",
  }),
  created() {
    // if (!this.$route.query.researchID && this.$route.query.status !== 'อนุมัติ') {
    //   this.$router.go(-1);
    // } else {
    // Set data Use
    this.form.hasUnexpectedEvents = this.$route.query.hasUnexpectedEvents;
    this.form.hasDDLetter = this.$route.query.hasDDLetter;
    this.form.hasSafetyReport = this.$route.query.hasSafetyReport;
    this.form.hasMoreReport = this.$route.query.hasMoreReport;
    this.form.startReport = this.$route.query.startReport;
    this.startReport = this.changeDate(this.$route.query.startReport);
    this.form.startReport = this.$route.query.startReport;
    this.form.researchCode = this.$route.query.researchCode;
    this.form.researchName = this.$route.query.nameResearch;
    this.form.researchName2 = this.$route.query.nameResearch;
    this.form.researcherSignature = this.$route.query.thName;
    this.form.signature = this.$route.query.thName;
    this.form.researcher = this.$route.query.thName;
    this.form.phoneNumber = this.$route.query.mobilePhone;
    this.form.affiliation = this.$route.query.affiliation;
    this.updateDate("reportDate", new Date().toISOString().slice(0, 10));
    this.updateDate("date", new Date().toISOString().slice(0, 10));
    if (this.$route.query.thName) {
      let text = this.$route.query.thName.split(" ");
      this.form.firstName = text[0];
      this.form.lastName = text[1];
    } else {
      this.form.firstName = this.$route.query.firstName;
      this.form.lastName = this.$route.query.lastName;
    }
    if (this.$route.query.rfID) {
      this.getSusar(this.$route.query.rfID);
    }
    // }
  },
  methods: {
    updateData(name, val) {
      this.form[name] = val;
    },
    getSusar(id) {
      this.isLoading = true;
      this.$store
        .dispatch("FormRF/getRFSusar", id)
        .then((res) => {
          this.isLoading = false;
          if (res !== "don't have research amendment yet") {
            if (res.status !== "อนุมัติ") {
              console.log("res eiei", res);
              this.form.signature = res.signature;
              this.form.hasUnexpectedEvents = res.hasUnexpectedEvents;
              this.form.hasDDLetter = res.hasDDLetter;
              this.form.hasSafetyReport = res.hasSafetyReport;
              this.form.hasMoreReport = res.hasMoreReport;
              this.form.noteDetail = res.noteDetail;
              this.form.newRowDDLetter = res.newRowDDLetter ?? [];
              this.form.newRowSafetyReport = res.newRowSafetyReport ?? [];
              this.form.newRowMoreReport = res.newRowMoreReport ?? [];
              this.form.newRowUnexpectedEvents =
                res.newRowUnexpectedEvents ?? [];
              this.form.researchCode = res.researchCode;
              this.form.firstName = res.firstName;
              this.form.lastName = res.lastName;
              this.form.researchName = res.researchName;
              this.form.hasScreening = res.hasScreening.toString();
              this.form.screening = res.screening;
              this.form.hasAdjustMethod = res.hasAdjustMethod.toString();
              this.form.adjustMethod = res.adjustMethod;
              this.form.hasAddOperate = res.hasAddOperate.toString();
              this.form.addOperate = res.addOperate;
              this.form.hasExpandTime = res.hasExpandTime.toString();
              this.form.expandTime = res.expandTime;
              this.form.hasAdjustOtherMethod = res.hasAdjustOtherMethod.toString();
              this.form.adjustOtherMethod = res.adjustOtherMethod;
              this.form.fileInformationSheet = res.fileInformationSheet ?? [];
              this.fileInformationSheet = res.fileInformationSheet ?? [];
              this.fileInformationSheetPath = res.fileInformationSheetPath;
              this.dateChange1 = !res.informationSheetDate
                ? ""
                : this.changeDate(res.informationSheetDate);
              this.form.fileProtocolModify = res.fileProtocolModify ?? [];
              this.fileProtocolModify = res.fileProtocolModify ?? [];
              this.fileProtocolModifyPath = res.fileProtocolModifyPath;
              this.dateChange2 = !res.protocolModifyDate
                ? ""
                : this.changeDate(res.protocolModifyDate);
              this.form.inclusion = res.inclusion == 1;
              this.form.procedureChange = res.procedureChange == 1;
              this.form.editorial = res.editorial == 1;
              this.form.hasOtherChange = res.hasOtherChange == 1;
              this.form.otherChange = res.otherChange;
              this.form.fileInvestigator = res.fileInvestigator ?? [];
              this.fileInvestigator = res.fileInvestigator ?? [];
              this.fileInvestigatorPath = res.fileInvestigatorPath;
              this.dateChange3 = !res.investigatorDate
                ? ""
                : this.changeDate(res.investigatorDate);
              this.form.hasNewInformation = res.hasNewInformation == 1;
              this.form.hasAdvertisement = res.hasAdvertisement == 1;
              this.form.hasChangeInTitle = res.hasChangeInTitle == 1;
              this.form.changeInTitle = res.changeInTitle;
              this.form.hasChangeInInvestigator =
                res.hasChangeInInvestigator == 1;
              this.form.changeInInvestigatorAdd = res.changeInInvestigatorAdd;
              this.form.changeInInvestigatorDelete =
                res.changeInInvestigatorDelete;
              this.form.hasChangeInSponsor = res.hasChangeInSponsor == 1;
              this.form.changeInSponsorAdd = res.changeInSponsorAdd;
              this.form.changeInSponsorDelete = res.changeInSponsorDelete;
              this.form.signature = res.signature;
              this.form.date = res.date;
              this.dateChange4 = !res.date ? "" : this.changeDate(res.date);
              this.form.researchName2 = res.researchName2;
              this.form.researcher = res.researcher;
              this.form.phoneNumber = res.phoneNumber;
              this.form.affiliation = res.affiliation;
              this.form.agencyBook = res.agencyBook;
              this.agencyBook = res.agencyBook;
              this.agencyBookPath = res.agencyBookPath;
              this.form.bookNumber = res.bookNumber;
              this.form.bookDate = res.bookDate;
              this.form.fileOther = res.fileOther ?? [];
              this.bookDate = this.changeDate(res.bookDate);
              this.form.researcherSignature = res.researcherSignature;
              this.form.reportDate = res.reportDate;
              this.dateChange5 = !res.reportDate
                ? ""
                : this.changeDate(res.reportDate);
              if (res.research_amendment_edit) {
                let i = 0;
                res.research_amendment_edit.forEach((data) => {
                  this.itemTableEdit.push({
                    index: i++,
                    version: data.version,
                    pageAndLine: data.pageAndLine,
                    suggestion: data.suggestion,
                    file: data.file,
                  });
                });
              }
            }
          }
          this.isLoading = false;
        })
        .catch((e) => {
          this.isLoading = false;
        });
    },
    saveDraft() {
      Swal.fire({
        title: "คุณต้องการบันทึกแบบร่างหรือไม่?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3E7662",
        cancelButtonColor: "rgb(189, 189, 189)",
        confirmButtonText: "ใช่ บันทึกแบบร่าง",
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          this.form["researchID"] = this.$route.query.researchID;
          if (this.$route.query.rfID) {
            this.form["methods"] = "edit";
            this.form["rfID"] = this.$route.query.rfID;
          } else {
            this.form["methods"] = "save";
            this.form["status"] = "บันทึกแบบร่าง";
          }
          this.$store
            .dispatch("FormRF/saveRFSusar", this.form)
            .then((res) => {
              Swal.fire({
                position: "center",
                icon: "success",
                title: "บันทึกแบบร่างสำเร็จ",
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                  this.$router.push('/profile');
                });
              this.isLoading = false;
              // this.viewData();
            })
            .catch((e) => {
              this.isLoading = false;
              if (e.response.data.errors) {
                for (var k in e.response.data.errors) {
                  Swal.fire({
                    title: "เกิดข้อผิดพลาด",
                    text: e.response.data.errors[k][0],
                    icon: "warning",
                    confirmButtonColor: "#3E7662",
                  }).then(() => {
                    this.$vuetify.goTo(`#${k}`);
                  });
                }
              }
            });
        }
      });
    },
    save() {
      Swal.fire({
        title: "คุณต้องการบันทึกหรือไม่?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3E7662",
        cancelButtonColor: "rgb(189, 189, 189)",
        confirmButtonText: "ใช่ บันทึก",
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          this.form["researchID"] = this.$route.query.researchID;
          if (this.$route.query.rfID) {
            this.form["methods"] = "edit";
            this.form["rfID"] = this.$route.query.rfID;
          } else {
            this.form["methods"] = "save";
          }
          this.$store
            .dispatch("FormRF/saveRFSusar", this.form)
            .then((res) => {
              Swal.fire({
                position: "center",
                icon: "success",
                title: "บันทึกสำเร็จ",
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                  this.$router.push('/profile');
                });
              this.isLoading = false;
              // this.viewData();
            })
            .catch((e) => {
              this.isLoading = false;
              if (e.response.data.errors) {
                for (var k in e.response.data.errors) {
                  Swal.fire({
                    title: "เกิดข้อผิดพลาด",
                    text: e.response.data.errors[k][0],
                    icon: "warning",
                    confirmButtonColor: "#3E7662",
                  }).then(() => {
                    this.$vuetify.goTo(`#${k}`);
                  });
                }
              }
            });
        }
      });
    },
    updateDataMultiRow(name, val, index) {
      if (name === "amountUnexpectedEvents") {
        this.form.newRowUnexpectedEvents[index].amountUnexpectedEvents = val;
      }
      if (name === "amountMembers") {
        this.form.newRowUnexpectedEvents[index].amountMembers = val;
      }
      if (name === "note") {
        this.form.newRowUnexpectedEvents[index].note = val;
      }
      if (name === "file") {
        this.form.newRowUnexpectedEvents[
          index
        ].newRowUnexpectedEventsFile.file = val;
      }
      if (name === "nameDoc") {
        this.form.newRowMoreReport[index].nameDoc = val;
      }
      if (name === "bookNumber") {
        this.form.newRowMoreReport[index].bookNumber = val;
      }
      if (name === "instituteBook") {
        this.form.newRowMoreReport[index].instituteBook = val;
      }
      //  if(name === "DDletterFile"){
      //     this.form.newRowDDLetter[index].file = val;
      //  }
    },
    updateDate(name, val, index) {
      //   console.log(val);
      if (name === "startReport") {
        this.form.startReport = val;
        this.startReport = this.changeDate(val);
      }
      if (name === "startDateEvents") {
        this.form.newRowUnexpectedEvents[index].startDateEvents = val;
        this.form.newRowUnexpectedEvents[
          index
        ].startDateEvents = this.changeDate(val);
      }
      if (name === "endDateEvents") {
        this.form.newRowUnexpectedEvents[index].endDateEvents = val;
        this.form.newRowUnexpectedEvents[index].endDateEvents = this.changeDate(
          val
        );
      }
      if (name === "bookDate") {
        console.log(val);
        this.form.newRowMoreReport[index].bookDate = val;
        this.form.newRowMoreReport[index].bookDate = this.changeDate(val);
      }
      if (name === "date") {
        this.form.date = val;
        this.date = this.changeDate(val);
      }
    },
    changeDate(val) {
      if (val != null) {
        //change date start
        let sub_year = val.substring(0, 4);
        let sub_month = val.substring(5, 7);
        let sub_day = val.substring(8, 10);
        sub_year = parseInt(sub_year) + 543;
        let changeDate = sub_day + "/" + sub_month + "/" + sub_year;
        //   console.log("c", changeDate);
        return changeDate;
      } else {
        return null;
      }
    },
    chooseFiles: function(files) {
      document.getElementById(files).click();
    },
    onUpload(filename, e, index, index2) {
      if (filename === "DDletterFile") {
        // this.form.newRowDDLetter[index].file = e.name;
        this.$store
          .dispatch("Common/uploadPdf", { form: "rfsusar-ddletter", file: e })
          .then((res) => {
            console.log("filename: ", res.fileName);
            this.form.newRowDDLetter[index].file = res.fileName;
          })
          .catch((error) => {});
      }
      if (filename === "SafetyReportFile") {
        // this.form.newRowSafetyReport[index].file = e.name;
        this.$store
          .dispatch("Common/uploadPdf", {
            form: "rfsusar-safetyreport",
            file: e,
          })
          .then((res) => {
            console.log("filename: ", res.fileName);
            this.form.newRowSafetyReport[index].file = res.fileName;
          })
          .catch((error) => {});
      }
      if (filename === "UnexpectedEventsFile") {
        // this.form.newRowUnexpectedEvents[index].newRowUnexpectedEventsFile[index2].file = e.name;
        this.$store
          .dispatch("Common/uploadPdf", {
            form: "rfsusar-unexpectedevents",
            file: e,
          })
          .then((res) => {
            console.log("filename: ", res.fileName);
            this.form.newRowUnexpectedEvents[index].newRowUnexpectedEventsFile[index2].file = res.fileName;
          })
          .catch((error) => {});
      }
      if (filename === "fileMoreReport") {
        // this.form.newRowMoreReport[index].fileMoreReport = e.name;
        this.$store
          .dispatch("Common/uploadPdf", { form: "rfsusar-morereport", file: e })
          .then((res) => {
            console.log("filename: ", res.fileName);
            this.form.newRowMoreReport[index].fileMoreReport = res.fileName;
          })
          .catch((error) => {});
      }
      if (filename === "fileDoc") {
        // this.form.newRowMoreReport[index].file = e.name;
        this.$store
          .dispatch("Common/uploadPdf", {
            form: "rfsusar-morereport2",
            file: e,
          })
          .then((res) => {
            console.log("filename: ", res.fileName);
            this.form.newRowMoreReport[index].file = res.fileName;
          })
          .catch((error) => {});
      }
      // this.newRow[name] = e.name;
      // this.$store.dispatch("Common/uploadPdf", { form: "rfsusar", file: e })
      //   .then((res) => {
      //     console.log(res);
      //     this.newRow[name] = res.fileName;
      //   })
      //   .catch((error) => { });
    },
    add(name, index) {
      if (name == "UnexpectedEvents") {
        this.form.newRowUnexpectedEvents.push({
          startDateEvents: "",
          endDateEvents: "",
          amountUnexpectedEvents: "",
          amountMembers: "",
          note: "",
          newRowUnexpectedEventsFile: [
            {
              file: "",
            },
          ],
        });
      }
      if (name == "UnexpectedEventsFile") {
        console.log(index);
        this.form.newRowUnexpectedEvents[
          index
        ].newRowUnexpectedEventsFile.push({ file: "" });
      }
      if (name == "DDLetter") {
        this.form.newRowDDLetter.push({ file: "" });
      }
      if (name == "SafetyReport") {
        this.form.newRowSafetyReport.push({ file: "" });
      }
      if (name == "MoreReport") {
        this.form.newRowMoreReport.push({
          nameDoc: "",
          fileMoreReport: "",
          file: "",
          bookNumber: "",
          bookDate: "",
          instituteBook: "",
        });
      }
    },
    remove(name, index, index2) {
      if (name == "UnexpectedEvents") {
        this.form.newRowUnexpectedEvents = this.form.newRowUnexpectedEvents.filter(
          (item, i) => i !== index
        );
      }
      if (name == "UnexpectedEventsFile") {
        this.form.newRowUnexpectedEvents[
          index
        ].newRowUnexpectedEventsFile = this.form.newRowUnexpectedEvents[
          index
        ].newRowUnexpectedEventsFile.filter((item, i) => i !== index2);
      }
      if (name == "DDLetter") {
        this.form.newRowDDLetter = this.form.newRowDDLetter.filter(
          (item, i) => i !== index
        );
      }
      if (name == "SafetyReport") {
        this.form.newRowSafetyReport = this.form.newRowSafetyReport.filter(
          (item, i) => i !== index
        );
      }
      if (name == "MoreReport") {
        this.form.newRowMoreReport = this.form.newRowMoreReport.filter(
          (item, i) => i !== index
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
