<template>
  <v-content>
    <v-layout>
      <v-flex style="background-color: #EFEAE7;padding: 2% 5% 10% 5%">
        <!-- FORM VIEW USER -->
        <v-card v-if="!isEdit">
          <v-layout style="position: absolute; right: 80px;top: 30px">
            <v-flex>
              <div class="mb-1"
                style="background-color: #FAF5F2; width: 103px;height: 123px;box-shadow: 0 2px 6px rgba(0,0,0,0.5)">
                <v-img :src="imageUrl" style="width: 100%;height: 100%;object-fit: cover"></v-img>
              </div>
              <v-file-input id="file" label="File input" style="display: none;" @change="onFilePicked"></v-file-input>
            </v-flex>
          </v-layout>

          <v-layout px-12 py-8 justify-center style="background-color: #3E7662;">
            <v-flex style="text-align: left;">
              <span style="font-weight: bold;font-size: 28px;color: white;">ประวัติผู้วิจัย</span>
            </v-flex>
          </v-layout>
          <v-layout py-8>
            <v-flex ml-12 style="text-align: left;">
              <v-btn @click="onEdit('edit')" dark color="#3E7662">
                <span>แก้ไขโปรไฟล์</span>
                <v-icon>edit</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>

          <!-- VIEW USER -->
          <v-layout style="background-color: #FBF6F3">
            <v-flex py-4 ml-12 style="text-align: left;">
              <v-layout>
                <v-flex>
                  <span style="font-weight: bold">1. ชื่อ-นามสกุล</span>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex pl-5 pt-4>
                  <span style="font-weight: bold">(ภาษาไทย)</span>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex class="profile-style">
                  <span>{{ user.preNameTh }} {{ user.thName }}</span>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex pl-5 pt-4>
                  <span style="font-weight: bold">(ภาษาอังกฤษ)</span>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex class="profile-style">
                  <span>{{ user.preNameEn }}
                    {{ user.enName }}</span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout style="background-color: #ffffff">
            <v-flex py-4 ml-12 style="text-align: left;">
              <v-layout>
                <v-flex>
                  <span style="font-weight: bold">2. ประวัติการศึกษา</span>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex class="profile-style">
                  <span>{{ user.profileEducation }}</span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout style="background-color: #FBF6F3">
            <v-flex py-4 ml-12 style="text-align: left;">
              <v-layout>
                <v-flex>
                  <span style="font-weight: bold">3. ประวัติการทำงาน</span>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex class="profile-style">
                  <span>{{ user.workEx }}</span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout style="background-color: #ffffff">
            <v-flex py-4 ml-12 style="text-align: left;">
              <v-layout>
                <v-flex>
                  <span style="font-weight: bold">4. สาขาที่เชี่ยวชาญ</span>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex class="profile-style">
                  <span>{{ user.profileField }}</span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout style="background-color: #FBF6F3">
            <v-flex py-4 ml-12 style="text-align: left;">
              <v-layout>
                <v-flex>
                  <span style="font-weight: bold">5. ตำแหน่งทางวิชาการ</span>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex class="profile-style">
                  <span>{{ user.profilePosition }}</span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout style="background-color: #FBF6F3">
            <v-flex py-4 ml-12 style="text-align: left;">
              <v-layout>
                <v-flex>
                  <span style="font-weight: bold">6. กอง</span>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex class="profile-style">
                  <span>{{ user.profileTeam }}</span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout style="background-color: #ffffff">
            <v-flex py-4 ml-12 style="text-align: left;">
              <v-layout>
                <v-flex>
                  <span style="font-weight: bold">7. สังกัด</span>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex class="profile-style">
                  <span>{{ user.profileAffiliation }}</span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout style="background-color: #FBF6F3">
            <v-flex py-4 ml-12 style="text-align: left;">
              <v-layout>
                <v-flex>
                  <span style="font-weight: bold">8. สถานที่ติดต่อ</span>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex class="profile-style">
                  <span v-if="user.provinces && user.provinces.name === 'กรุงเทพมหานคร'">
                    บ้านเลขที่ {{ user.houseNumber }} ถนน
                    {{ user.road }}
                    {{ user.sub_districts ? 'เเขวง' + user.sub_districts.name : '' }}
                    {{ user.districts ? 'เขต' + user.districts.name : '' }}
                    {{ user.provinces ? user.provinces.name : '' }}
                    {{ user.postalCode }}
                  </span>
                  <span v-else>
                    บ้านเลขที่ {{ user.houseNumber }} ถนน {{ user.road }} {{
                      user.sub_districts ? 'ต.' + user.sub_districts.name : ''
                    }}
                    {{ user.districts ? user.districts.name : '' }}
                    {{ user.provinces ? user.provinces.name : '' }}
                    {{ user.postalCode }}
                  </span>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex class="profile-style">
                  <span>โทรศัพท์ที่บ้าน {{ user.telPhone }}</span>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex class="profile-style">
                  <span>โทรศัพท์ที่มือถือ {{ user.mobilePhone }}</span>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex class="profile-style">
                  <span>E-mail Address {{ user.email }}</span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout style="background-color: #ffffff">
            <v-flex py-4 ml-12 style="text-align: left;">
              <v-layout>
                <v-flex>
                  <span style="font-weight: bold">9. ประวัติการอบรม Good Clinical Practice (GCP)
                    หรือการอบรมด้านจริยธรรมการวิจัยในมนุษย์</span>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex class="profile-style">
                  <span>{{ user.practiceEx }}</span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>

        <v-layout v-if="!isEdit" pt-8>
          <v-flex style="background-color: #EFEAE7;">
            <v-card flat>
              <v-layout justify-center style="background-color: #3E7662;">
                <v-flex class="expansion-panels">
                  <v-expansion-panels>
                    <v-expansion-panel class="mx-1 pt-4" v-for="(item, i) in 1" :key="i"
                      style="background:#3E7662;color: white;">
                      <v-expansion-panel-header class="mb-4" style="font-size: 28px;font-weight: bold;">
                        ผลงานวิจัย
                      </v-expansion-panel-header>
                      <v-expansion-panel-content style="background-color: #5FA88C;color: #ffffff">
                        <v-layout>
                          <v-flex pt-6 px-4>
                            <v-layout>
                              <v-flex pb-2 style="text-align: left;font-size: 18px;">
                                <span>9.
                                  ผลงานวิจัยที่ตีพิมพ์ในวารสารระดับชาติและนานาชาติ</span>
                              </v-flex>
                            </v-layout>
                            <v-layout>
                              <v-flex class="text-input">
                                <textarea-form readonly :model="form.interResearch" @update-data="
                                  updateData('interResearch', $event)
                                  "></textarea-form>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                        <v-layout>
                          <v-flex pt-6 px-4>
                            <v-layout>
                              <v-flex pb-2 style="text-align: left;font-size: 18px;">
                                <span>10. ผลงานอื่น ๆ เช่น นวัตกรรม สิทธิบัตร
                                  ฯลฯ</span>
                              </v-flex>
                            </v-layout>
                            <v-layout>
                              <v-flex class="text-input">
                                <textarea-form readonly :model="form.otherWork"
                                  @update-data="updateData('otherWork', $event)"></textarea-form>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                        <v-layout>
                          <v-flex pt-6 px-4>
                            <v-layout>
                              <v-flex pb-2 style="text-align: left;font-size: 18px;">
                                <span>11. รางวัลผลงานวิจัยที่เคยได้รับ</span>
                              </v-flex>
                            </v-layout>
                            <v-layout>
                              <v-flex class="text-input">
                                <textarea-form readonly :model="form.award"
                                  @update-data="updateData('award', $event)"></textarea-form>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                        <v-layout>
                          <v-flex pt-6 px-4>
                            <v-layout>
                              <v-flex pb-2 style="text-align: left;font-size: 18px;">
                                <span>12. งานวิจัยที่รับผิดชอบในปัจจุบัน</span>
                              </v-flex>
                            </v-layout>
                            <v-layout>
                              <v-flex class="text-input">
                                <textarea-form readonly :model="form.currentResearch" @update-data="
                                  updateData('currentResearch', $event)
                                  "></textarea-form>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>

        <!-- FORM EDIT USER -->
        <v-card v-if="isEdit">
          <v-layout style="position: absolute; right: 80px;top: 90px">
            <v-flex>
              <div class="mb-1"
                style="background-color: #FAF5F2; width: 103px;height: 123px;box-shadow: 0 2px 6px rgba(0,0,0,0.5)">
                <img :src="imageUrl" style="width: 100%;height: 100%;object-fit: cover" />
              </div>
              <v-file-input accept=".png,.jpg" id="file" label="File input" style="display: none;"
                @change="onFilePicked"></v-file-input>
              <v-btn style="width: 103px;font-size: 12px" color="#3E7662" dark @click="uploadFile">
                <v-icon class="pr-3">cloud_download</v-icon>
                <span>Upload</span>
              </v-btn>
            </v-flex>
          </v-layout>
          <v-layout mb-12 px-12 py-8 justify-space-between style="background-color: #3E7662;">
            <v-flex style="text-align: left;">
              <span style="font-weight: bold;font-size: 28px;color: white;">แก้ไขแบบประวัติผู้วิจัย</span>
            </v-flex>
            <!--                        <v-flex style="text-align: right;">-->
            <!--                            <span style="font-size: 14px;color: white;">Version……….Date…………………….</span><br>-->
            <!--                            <span style="font-size: 14px;color: white;">RF 10_2560</span>-->
            <!--                        </v-flex>-->
          </v-layout>
          <v-layout>
            <v-flex ml-12 shrink d-flex justify-start align-center style="text-align: left">
              <v-btn color="#3E7662" @click="onEdit('back')">
                <v-icon color="#ffffff">reply</v-icon>
                <span style="color: #ffffff;font-weight: bold;">ย้อนกลับ</span>
              </v-btn>
            </v-flex>
          </v-layout>

          <!-- Form EDIT USER -->

          <v-layout justify-center>
            <v-flex xs12 sm10 md8 lg10 pt-8 pb-12>

              <!-- ชื่อ-นามสกุล -->
              <v-layout class="mt-8">
                <v-flex mr-8 pt-1 xs3 class="font-weight-bold d-flex align-center justify-end">
                  <p class="text-right">คำนำหน้าชื่อ<span style="color: #CC3D45">*</span>
                    <br><span style="color: #CC3D45">กรุณาตรวจสอบคำนำหน้าชื่อให้ถูกต้อง
                      เนื่องจากข้อมูลจะถูกระบุไว้ในเอกสารที่ได้รับการรับรอง</span>
                  </p>
                </v-flex>
                <v-flex xs6 class="field-select d-flex align-center">
                  <v-layout>
                    <v-flex xs6 pr-2>
                      <TextfieldForm id="PreNameTh" placeholder="ยศ ภาษาไทย" hide-detail :model="form.preNameTh"
                        @update-data="updateData('preNameTh', $event)">
                      </TextfieldForm>
                      <!--                      <text-autocomplete-->
                      <!--                        id="nameTitle"-->
                      <!--                        placeholder="คำนำหน้าชื่อ"-->
                      <!--                        :items="titleName"-->
                      <!--                        hide-details-->
                      <!--                        item-text="title_name_th"-->
                      <!--                        itemValue="title_id"-->
                      <!--                        :model="form.nameTitle"-->
                      <!--                        @update-data="updateData('nameTitle', $event)"-->
                      <!--                      />-->
                    </v-flex>
                    <v-flex xs6 pl-2>
                      <TextfieldForm id="preNameEn" placeholder="ยศ ภาษาอังกฤษ" hide-detail :model="form.preNameEn"
                        @update-data="updateData('preNameEn', $event)">
                      </TextfieldForm>
                      <!--                      <text-autocomplete-->
                      <!--                        id="nameTitle"-->
                      <!--                        placeholder="ยศ"-->
                      <!--                        :items="rankName"-->
                      <!--                        hide-details-->
                      <!--                        item-text="name_th"-->
                      <!--                        itemValue="id"-->
                      <!--                        :model="form.rank"-->
                      <!--                        @update-data="updateData('rank', $event)"-->
                      <!--                      />-->
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex pl-8 class="font-weight-bold d-flex align-center justify-start">
                  <span>(ยศ)</span>
                </v-flex>
              </v-layout>

              <v-layout class="mt-8">
                <v-flex mr-8 pt-2 xs3 class="font-weight-bold d-flex align-center justify-end"
                  style="text-align: right;">
                  <span>1. ชื่อ-นามสกุล<span style="color: #CC3D45">*</span></span>
                </v-flex>
                <v-flex xs3 pr-2 class="text-input">
                  <TextfieldForm id="thFirstName" placeholder="ชื่อจริงภาษาไทย" hide-detail :model="form.thFirstName"
                    @update-data="updateData('thFirstName', $event)"></TextfieldForm>
                </v-flex>
                <v-flex xs3 pl-2 class="text-input">
                  <TextfieldForm id="thLastName" placeholder="นามสกุลภาษาไทย" hide-detail :model="form.thLastName"
                    @update-data="updateData('thLastName', $event)"></TextfieldForm>
                </v-flex>
                <v-flex pl-8 class="font-weight-bold d-flex align-center justify-start">
                  <span>(ภาษาไทย)</span>
                </v-flex>
              </v-layout>

              <v-layout class="mt-8">
                <v-flex mr-8 pt-2 xs3 style="text-align: right;"> </v-flex>
                <v-flex xs3 pr-2 class="text-input">
                  <TextfieldForm id="enFirstName" placeholder="ชื่อจริงอังกฤษ" hide-detail :model="form.enFirstName"
                    @update-data="updateData('enFirstName', $event)"></TextfieldForm>
                </v-flex>
                <v-flex xs3 pl-2 class="text-input">
                  <TextfieldForm id="enLastName" placeholder="นามสกุลอังกฤษ" hide-detail :model="form.enLastName"
                    @update-data="updateData('enLastName', $event)"></TextfieldForm>
                </v-flex>
                <v-flex pl-8 class="font-weight-bold d-flex align-center justify-start">
                  <span>(ภาษาอังกฤษ)</span>
                </v-flex>
              </v-layout>

              <!-- ประวัติการศึกษา -->
              <v-layout class="mt-8">
                <v-flex xs3 mr-8 class="font-weight-bold d-flex align-center justify-end">
                  <span>2. วุฒิการศึกษา<span style="color: #CC3D45">*</span></span>
                </v-flex>
                <v-flex xs6 class="text-input">
                  <text-autocomplete id="education" placeholder="เลือกวุฒิการศึกษา" :items="educationList" hide-details
                    item-text="education_name" itemValue="education_name" :model="form.education"
                    @update-data="updateData('education', $event)" />
                </v-flex>
                <v-flex xs2>
                  <v-flex class="font-weight-bold d-flex align-center ">
                    <span style="padding-left: 8px;padding-right: 8px">ระบุ</span>
                    <TextfieldForm :background-color="isOtherEducation ? 'rgba(183,183,183,0.79)' : '#FBF6F3'
                      " :disabled="isOtherEducation" id="otherEducation" hide-detail :model="form.otherEducation"
                      @update-data="updateData('otherEducation', $event)"></TextfieldForm>
                  </v-flex>
                </v-flex>
                <v-spacer></v-spacer>
              </v-layout>

              <!-- ประวัติการศึกษา -->
              <v-layout class="mt-8">
                <v-flex xs3 mr-8 class="font-weight-bold d-flex justify-end">
                  <span>3. ประวัติการศึกษา(โดยย่อ)</span>
                </v-flex>
                <v-flex xs6 class="text-input">
                  <textarea-form id="workEx" hide-detail :model="form.educationEx"
                    @update-data="updateData('educationEx', $event)"></textarea-form>
                </v-flex>
                <v-spacer></v-spacer>
              </v-layout>

              <!-- ประวัติการทำงาน -->
              <v-layout class="mt-8">
                <v-flex xs3 mr-8 class="font-weight-bold d-flex justify-end">
                  <span>4. ประวัติการทำงาน</span>
                </v-flex>
                <v-flex xs6 class="text-input">
                  <textarea-form id="workEx" hide-detail :model="form.workEx"
                    @update-data="updateData('workEx', $event)"></textarea-form>
                </v-flex>
                <v-spacer></v-spacer>
              </v-layout>

              <!-- สาขาที่เชี่ยวชาญ -->
              <v-layout class="mt-8">
                <v-flex mr-8 xs3 class="font-weight-bold d-flex align-center justify-end">
                  <span>5. สาขาที่เชี่ยวชาญ<span style="color: #CC3D45">*</span></span>
                </v-flex>
                <v-flex xs6 class="text-input">
                  <text-autocomplete id="field" placeholder="สาขา" :items="fieldList" hide-details
                    item-text="field_name" itemValue="field_name" :model="form.field"
                    @update-data="updateData('field', $event)" />
                </v-flex>
                <v-flex xs2>
                  <v-flex class="font-weight-bold d-flex align-center ">
                    <span style="padding-left: 8px;padding-right: 8px">ระบุ</span>
                    <TextfieldForm :background-color="isOtherField ? 'rgba(183,183,183,0.79)' : '#FBF6F3'
                      " :disabled="isOtherField" id="houseNumber" hide-detail :model="form.otherField"
                      @update-data="updateData('otherField', $event)"></TextfieldForm>
                  </v-flex>
                </v-flex>
                <v-spacer></v-spacer>
              </v-layout>

              <!-- ตำแหน่งทางวิชาการ -->
              <v-layout class="mt-8">
                <v-flex mr-8 xs3 class="font-weight-bold d-flex align-center justify-end">
                  <span>6. ตำแหน่งทางวิชาการ<span style="color: #CC3D45">*</span></span>
                </v-flex>
                <v-flex xs6 class="text-input">
                  <text-autocomplete id="position" placeholder="ตำแหน่ง" :items="positionList" hide-details
                    item-text="position_name" itemValue="position_name" :model="form.position"
                    @update-data="updateData('position', $event)" />
                </v-flex>
                <v-flex xs2>
                  <v-flex class="font-weight-bold d-flex align-center ">
                    <span style="padding-left: 8px;padding-right: 8px">ระบุ</span>
                    <TextfieldForm :background-color="isOtherPosition ? 'rgba(183,183,183,0.79)' : '#FBF6F3'
                      " :disabled="isOtherPosition" id="otherPosition" hide-detail :model="form.otherPosition"
                      @update-data="updateData('otherPosition', $event)"></TextfieldForm>
                  </v-flex>
                </v-flex>
                <v-spacer></v-spacer>
              </v-layout>

              <!-- สังกัด -->
              <v-layout class="mt-8">
                <v-flex xs3 mr-8 class="font-weight-bold d-flex align-center justify-end">
                  <span>7. สังกัด<span style="color: #CC3D45">*</span></span>
                </v-flex>
                <v-flex xs6 class="text-input d-flex align-center">
                  <text-autocomplete id="affiliationID" placeholder="ตำแหน่ง" :items="affiliationList" hide-details
                    item-text="affiliation_name" itemValue="affiliation_id" :model="form.affiliationID"
                    @update-data="updateData('affiliationID', $event)" />
                </v-flex>
                <v-flex shrink class="font-weight-bold d-flex align-center ">
                  <span style="padding-left: 8px;padding-right: 8px">ระบุ</span>
                </v-flex>
                <v-flex xs2>
                  <v-layout>
                    <v-flex class="font-weight-bold d-flex align-center ">
                      <TextfieldForm :background-color="isOtherAffiliation
                        ? 'rgba(183,183,183,0.79)'
                        : '#FBF6F3'
                        " :disabled="isOtherAffiliation" id="otherAffiliation" placeholder="ภาษาไทย" hide-detail
                        :model="form.otherAffiliation" @update-data="updateData('otherAffiliation', $event)">
                      </TextfieldForm>
                    </v-flex>
                  </v-layout>
                  <v-layout class="mt-2">
                    <v-flex class="font-weight-bold d-flex align-center">
                      <TextfieldForm :background-color="isOtherAffiliation
                        ? 'rgba(183,183,183,0.79)'
                        : '#FBF6F3'
                        " :disabled="isOtherAffiliation" id="otherAffiliationEN" hide-detail placeholder="ภาษาอังกฤษ"
                        :model="form.otherAffiliationEN" @update-data="updateData('otherAffiliationEN', $event)">
                      </TextfieldForm>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-spacer></v-spacer>
              </v-layout>

              <!-- กอง -->
              <v-layout class="mt-8">
                <v-flex xs3 mr-8 class="font-weight-bold d-flex align-center justify-end">
                  <span>8. กอง<span style="color: #CC3D45">*</span></span>
                </v-flex>
                <v-flex xs6 class="text-input d-flex align-center">
                  <text-autocomplete id="team" placeholder="กอง" :items="teamList" hide-details item-text="team_name"
                    itemValue="team_name" :model="form.team" @update-data="updateData('team', $event)" />
                </v-flex>
                <v-flex shrink class="font-weight-bold d-flex align-center ">
                  <span style="padding-left: 8px;padding-right: 8px">ระบุ</span>
                </v-flex>
                <v-flex xs2>
                  <v-layout>
                    <v-flex class="font-weight-bold d-flex align-center ">
                      <TextfieldForm :background-color="isOtherTeam
                        ? 'rgba(183,183,183,0.79)'
                        : '#FBF6F3'
                        " :disabled="isOtherTeam" placeholder="ภาษาไทย" id="otherTeam" hide-detail
                        :model="form.otherTeam" @update-data="updateData('otherTeam', $event)"></TextfieldForm>
                    </v-flex>
                  </v-layout>
                  <v-layout class="mt-2">
                    <v-flex class="font-weight-bold d-flex align-center ">
                      <TextfieldForm :background-color="isOtherTeam
                        ? 'rgba(183,183,183,0.79)'
                        : '#FBF6F3'
                        " :disabled="isOtherTeam" placeholder="ภาษาอังกฤษ" id="otherTeamEN" hide-detail
                        :model="form.otherTeamEN" @update-data="updateData('otherTeamEN', $event)"></TextfieldForm>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-spacer></v-spacer>
              </v-layout>

              <!-- สถานที่ติดต่อ -->
              <v-layout class="mt-8">
                <v-flex xs3 mr-8 class="font-weight-bold d-flex align-center justify-end">
                  <span>9. สถานที่ติดต่อ</span>
                </v-flex>
                <v-flex xs6>
                  <v-layout>
                    <v-flex xs3 pr-2 class="font-weight-bold d-flex align-center justify-end">
                      <span>บ้านเลขที่</span>
                    </v-flex>
                    <v-flex xs3>
                      <TextfieldForm id="houseNumber" hide-detail :model="form.houseNumber"
                        @update-data="updateData('houseNumber', $event)"></TextfieldForm>
                    </v-flex>
                    <v-flex xs1 ml-4 mr-2 class="font-weight-bold d-flex align-center justify-end">
                      <span>ถนน</span>
                    </v-flex>
                    <v-flex xs5>
                      <TextfieldForm id="road" hide-detail :model="form.road" @update-data="updateData('road', $event)">
                      </TextfieldForm>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-spacer></v-spacer>
              </v-layout>

              <!-- Select Province, District, SubDistrict -->
              <v-layout class="mt-8">
                <v-flex xs3></v-flex>
                <v-flex xs6 class="ml-8">
                  <v-layout>
                    <v-flex xs4 pr-2 class="font-weight-bold d-flex align-center justify-end">
                      <span>จังหวัด</span>
                    </v-flex>
                    <v-flex xs8 class="field-select">
                      <text-autocomplete id="province" placeholder="เลือก จังหวัด" :items="provinces" hide-details
                        item-text="province_name" itemValue="province_id" :model="form.provinceID"
                        @update-data="updateData('provinceID', $event)" />
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-spacer></v-spacer>
              </v-layout>

              <v-layout class="mt-8">
                <v-flex xs3></v-flex>
                <v-flex xs6 class="ml-8">
                  <v-layout>
                    <v-flex xs4 pr-2 class="font-weight-bold d-flex align-center justify-end">
                      <span>อำเภอ/เขต</span>
                    </v-flex>
                    <v-flex xs8 class="field-select">
                      <text-autocomplete id="district" placeholder="เลือก อำเภอ/เขต" :items="districts" hide-details
                        item-text="district_name" itemValue="district_id" :model="form.districtID"
                        @update-data="updateData('districtID', $event)" />
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-spacer></v-spacer>
              </v-layout>

              <v-layout class="mt-8">
                <v-flex xs3></v-flex>
                <v-flex xs6 class="ml-8">
                  <v-layout>
                    <v-flex xs4 pr-2 class="font-weight-bold d-flex align-center justify-end">
                      <span>ตำบล/แขวง</span>
                    </v-flex>
                    <v-flex xs8 class="field-select">
                      <text-autocomplete id="subDistrict" placeholder="เลือก ตำบล/แขวง" :items="subDistricts"
                        hide-details item-text="subDistrict_name" itemValue="subDistrict_id" :model="form.subDistrictID"
                        @update-data="updateData('subDistrictID', $event)" />
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-spacer></v-spacer>
              </v-layout>

              <v-layout class="mt-8">
                <v-flex xs3></v-flex>
                <v-flex xs6 class="ml-8">
                  <v-layout>
                    <v-flex xs4 pr-2 class="font-weight-bold d-flex align-center justify-end">
                      <span>รหัสไปรษณีย์</span>
                    </v-flex>
                    <v-flex class="field-select">
                      <TextfieldForm id="postalCode" hide-detail :model="form.postalCode"
                        @update-data="updateData('postalCode', $event)"></TextfieldForm>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-spacer></v-spacer>
              </v-layout>

              <v-layout class="mt-8">
                <v-flex xs3></v-flex>
                <v-flex xs6 class="ml-8">
                  <v-layout>
                    <v-flex xs4 pr-2 class="font-weight-bold d-flex align-center justify-end">
                      <span>โทรศัพท์ที่ทำงาน</span>
                    </v-flex>
                    <v-flex class="field-select">
                      <TextfieldForm type="number" id="telPhone" hide-detail :model="form.telPhone"
                        @update-data="updateData('telPhone', $event)"></TextfieldForm>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-spacer></v-spacer>
              </v-layout>

              <v-layout class="mt-8">
                <v-flex xs3></v-flex>
                <v-flex xs6 class="ml-8">
                  <v-layout>
                    <v-flex xs4 pr-2 class="font-weight-bold d-flex align-center justify-end">
                      <span>โทรศัพท์มือถือ<span style="color: #CC3D45">*</span></span>
                    </v-flex>
                    <v-flex class="field-select">
                      <TextfieldForm type="number" id="mobilePhone" hide-detail :model="form.mobilePhone"
                        @update-data="updateData('mobilePhone', $event)"></TextfieldForm>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-spacer></v-spacer>
              </v-layout>

              <v-layout class="mt-8">
                <v-flex xs3></v-flex>
                <v-flex xs6 class="ml-8">
                  <v-layout>
                    <v-flex xs4 pr-2 class="font-weight-bold d-flex align-center justify-end">
                      <span>E-mail Address</span>
                    </v-flex>
                    <v-flex class="field-select">
                      <TextfieldForm id="email" hide-detail :model="form.email"
                        @update-data="updateData('email', $event)"></TextfieldForm>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-spacer></v-spacer>
              </v-layout>

              <!-- ประวัติการอบรม Good Clinical Practice (GCP) หรือการอบรมด้านจริยธรรมการวิจัยในมนุษย์ -->
              <v-layout class="mt-8">
                <v-flex py-6 class="font-weight-bold d-flex align-center justify-start">
                  <span>10. ประวัติการอบรม Good Clinical Practice (GCP)
                    หรือการอบรมด้านจริยธรรมการวิจัยในมนุษย์</span>
                </v-flex>
              </v-layout>
              <v-layout class="justify-center">
                <v-flex xs6 class="text-input">
                  <textarea-form id="practiceEx" :model="form.practiceEx"
                    @update-data="updateData('practiceEx', $event)"></textarea-form>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>

        <v-layout v-if="isEdit" pt-8>
          <v-flex style="background-color: #EFEAE7;">
            <v-card flat>
              <v-layout justify-center style="background-color: #3E7662;">
                <v-flex class="expansion-panels">
                  <v-expansion-panels>
                    <v-expansion-panel class="mx-1 pt-4" v-for="(item, i) in 1" :key="i"
                      style="background:#3E7662;color: white;">
                      <v-expansion-panel-header class="mb-4" style="font-size: 28px;font-weight: bold;">
                        ผลงานวิจัย
                      </v-expansion-panel-header>
                      <v-expansion-panel-content style="background-color: #5FA88C;color: #ffffff">
                        <v-layout>
                          <v-flex pt-6 px-4>
                            <v-layout>
                              <v-flex pb-2 style="text-align: left;font-size: 18px;">
                                <span>9.
                                  ผลงานวิจัยที่ตีพิมพ์ในวารสารระดับชาติและนานาชาติ</span>
                              </v-flex>
                            </v-layout>
                            <v-layout>
                              <v-flex class="text-input">
                                <textarea-form label="(โดยย่อ)" :model="form.interResearch" @update-data="
                                  updateData('interResearch', $event)
                                  "></textarea-form>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                        <v-layout>
                          <v-flex pt-6 px-4>
                            <v-layout>
                              <v-flex pb-2 style="text-align: left;font-size: 18px;">
                                <span>10. ผลงานอื่น ๆ เช่น นวัตกรรม สิทธิบัตร
                                  ฯลฯ</span>
                              </v-flex>
                            </v-layout>
                            <v-layout>
                              <v-flex class="text-input">
                                <textarea-form label="(โดยย่อ)" :model="form.otherWork"
                                  @update-data="updateData('otherWork', $event)"></textarea-form>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                        <v-layout>
                          <v-flex pt-6 px-4>
                            <v-layout>
                              <v-flex pb-2 style="text-align: left;font-size: 18px;">
                                <span>11. รางวัลผลงานวิจัยที่เคยได้รับ</span>
                              </v-flex>
                            </v-layout>
                            <v-layout>
                              <v-flex class="text-input">
                                <textarea-form label="(โดยย่อ)" :model="form.award"
                                  @update-data="updateData('award', $event)"></textarea-form>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                        <v-layout>
                          <v-flex pt-6 px-4>
                            <v-layout>
                              <v-flex pb-2 style="text-align: left;font-size: 18px;">
                                <span>12. งานวิจัยที่รับผิดชอบในปัจจุบัน</span>
                              </v-flex>
                            </v-layout>
                            <v-layout>
                              <v-flex class="text-input">
                                <textarea-form label="(โดยย่อ)" :model="form.currentResearch" @update-data="
                                  updateData('currentResearch', $event)
                                  "></textarea-form>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>

        <!-- CHANGE PASSWORD -->
        <v-layout v-if="!isEdit" py-8>
          <v-flex style="background-color: #EFEAE7;">
            <v-card flat>
              <v-layout justify-center style="background-color: #3E7662;">
                <v-flex class="expansion-panels">
                  <v-expansion-panels>
                    <v-expansion-panel class="mx-1" v-for="(item, i) in 1" :key="i"
                      style="background:#3E7662;color: white;">
                      <v-expansion-panel-header class="" style="font-size: 28px;font-weight: bold;">
                        <v-layout>
                          <v-flex>
                            <v-btn>
                              <span style="color: #3E7662;font-weight: bold;font-size: 16px">เปลี่ยนรหัสผ่าน</span>
                              <v-icon color="#3E7662">edit</v-icon>
                            </v-btn>
                          </v-flex>
                        </v-layout>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content style="background-color: #ffffff;color: #000000">
                        <v-layout>
                          <v-flex pt-6>
                            <v-layout justify-center>
                              <v-flex shrink pt-2 px-4>
                                <span>รหัสผ่านใหม่</span>
                              </v-flex>
                              <v-flex xs3>
                                <textfield-form type="password" :model="changePassword.password" @update-data="
                                  updatePasswordData('password', $event)
                                  "></textfield-form>
                              </v-flex>
                              <v-flex shrink pt-2 px-4>
                                <span>ยืนยันรหัสผ่านใหม่</span>
                              </v-flex>
                              <v-flex xs3>
                                <textfield-form type="password" :model="changePassword.password_confirmation"
                                  @update-data="
                                    updatePasswordData(
                                      'password_confirmation',
                                      $event
                                    )
                                    "></textfield-form>
                              </v-flex>
                              <v-flex shrink px-4>
                                <v-btn color="#3E7662" dark @click="changePasswordNew()">
                                  <span>เปลี่ยนรหัสผ่าน</span>
                                </v-btn>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>

        <!-- Table ผู้เข้าร่วมวิจัย -->
        <form-table v-if="!isEdit" name-table="ผู้เข้าร่วมวิจัย/ที่ปรึกษา" :headers="headers"
          :items="ParticipantList"></form-table>

        <!-- Online training -->
        <v-layout pt-8>
          <v-flex>
            <v-card>
              <v-layout>
                <v-flex class="expansion-panels">
                  <v-expansion-panels v-model="isOnlineExpand">
                    <v-expansion-panel class="mx-1 pt-4" v-for="(item, i) in 1" :key="i"
                      style="background:#3E7662;color: white;">
                      <v-expansion-panel-header class="mb-4" style="font-size: 28px;font-weight: bold;">
                        <span>Online training</span>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content style="background-color: #ffffff; color: black">
                        <v-layout v-if="user.remark === 'T'" my-4>
                          <v-flex xs4 d-flex align-center>
                            <span style="color:#CC3D45;font-weight: bold;">* Training request กรุณาทำการทดสอบอีกครั้ง
                              ก่อนดำเนินการใดใด</span>
                          </v-flex>
                        </v-layout>
                        <v-layout ml-5 mt-6>
                          <v-flex style="text-align: left;">
                            <span style="text-align: left;font-weight: bold;font-size: 18px;">Certificate GCP
                              training</span>
                            <span v-if="user.certificateStatus !== 1 ||
                              remaining_date <= 30 ||
                              user.remark === 'T'
                            " class="ml-4" style="font-weight: bold;text-align: left;font-size: 12px;color: #CC3D45">
                              *ได้เฉพาะไฟล์ PDF</span>
                          </v-flex>
                        </v-layout>
                        <v-layout mb-10 mx-5 v-if="user.certificateStatus !== 1 ||
                          remaining_date <= 30 ||
                          user.remark === 'T'
                        ">
                          <v-flex xs5>
                            <v-layout>
                              <v-flex>
                                <v-file-input accept=".pdf" hide-details prepend-icon="" id="certificate_gcp_training"
                                  :model="formCertificate.certificate_gcp_training
                                    " outlined dense background-color="#FBF6F3" color="#3E7662" item-color="#3E7662"
                                  @change="uploadCertificate"></v-file-input>
                              </v-flex>
                              <v-flex shrink pl-4 style="text-align: left">
                                <v-btn id="uploadBtn" color="#3E7662" dark @click="uploadFileCertificate()">
                                  <v-icon class="pr-3">cloud_download</v-icon>
                                  <span>Upload</span>
                                </v-btn>
                              </v-flex>
                            </v-layout>

                            <v-layout>
                              <v-flex style="text-align: left" xs9>
                                <span style="color: #CC3D45;font-size: 12px;text-align: left;font-weight: bold;">
                                  Certificate GCP and Humen subject protection
                                  cours
                                </span>
                              </v-flex>
                            </v-layout>
                            <v-layout>
                              <v-flex style="text-align: left" xs9>
                                <span style="color: #CC3D45;font-size: 12px;text-align: left;font-weight: bold;">
                                  *กรณี ที่ยังไม่ได้ผ่านการอบรมสามารถเข้าอบรม
                                  Online training ด้านล่าง
                                </span>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs5>
                            <v-layout justify-end>
                              <v-flex px-2 shrink d-flex align-center>
                                <span>วันที่ออก Certificate</span>
                              </v-flex>
                              <v-flex xs6 d-flex justify-end align-center>
                                <date-input-form :model="dateChange1" @update-data="
                                  updateCertificateFile(
                                    'certificate_date',
                                    $event
                                  )
                                  " hide-detail />
                              </v-flex>
                            </v-layout>

                            <!--                                                        <v-layout>-->
                            <!--                                                            <v-flex style="text-align: right">-->
                            <!--                                                                <span style="color: #CC3D45;font-size: 12px;text-align: left;">-->
                            <!--                                                                   *จำนวนวันที่จะสิ้นสุดการรับรอง {{remaining_date}} วัน-->
                            <!--                                                                </span>-->
                            <!--                                                            </v-flex>-->
                            <!--                                                        </v-layout>-->
                          </v-flex>
                          <v-flex xs2>
                            <v-layout justify-end>
                              <v-btn color="#3E7662" :dark="!isOldCer" :disabled="isOldCer" @click="saveCertificate">
                                <span>บันทึก</span>
                                <v-icon>save</v-icon>
                              </v-btn>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                        <v-layout justify-center v-if="user.certificateStatus === 0">
                          <v-flex xs5 class="text-center">
                            <v-card class="py-6" style="background-color: #CC3D45; color: white">
                              <span><b>Certificate ที่รอการอนุมัติ : </b>
                                {{ certificate_wait }}</span>
                            </v-card>
                          </v-flex>
                        </v-layout>

                        <!-- Test Exam -->
                        <v-layout ml-5 mt-12 v-if="user.certificateStatus !== 1 ||
                          remaining_date <= 30 ||
                          user.remark === 'T'
                        ">
                          <v-flex style="text-align: left;font-weight: bold;font-size: 18px;">
                            <span>กรณี ที่ยังไม่ได้ผ่านการอบรม สามารถเข้าอบรม
                              Online training</span>
                          </v-flex>
                        </v-layout>
                        <v-layout ml-5 mt-2 mb-12 v-if="user.certificateStatus !== 1 ||
                          remaining_date <= 30 ||
                          user.remark === 'T'
                        ">
                          <v-btn color="#3E7662" @click="$router.push('/test-exam')">
                            <span style="color: white;">ทำแบบทดสอบ</span>
                            <v-icon class="pr-2" color="#fff">edit</v-icon>
                          </v-btn>
                        </v-layout>
                        <v-layout mx-5 justify-space-between v-if="user.certificateStatus === 1">
                          <v-flex xs4 d-flex align-center>
                            <span><b>ชื่อไฟล์ :
                                <span style="font-size: 20px">{{
                                  user.certificate
                                  }}</span></b></span>
                          </v-flex>
                          <v-flex xs4 d-flex align-center>
                            <span><b>วันที่ออก Certificate :
                                <span style="font-size: 20px">{{
                                  changeDate(user.certificate_create_at)
                                  }}</span></b></span>
                          </v-flex>
                          <v-flex xs4 d-flex align-center>
                            <span>
                              <b>*จำนวนวันที่จะสิ้นสุดการรับรอง
                                <span style="font-size: 20px" :style="remaining_date <= 30
                                  ? 'color: #CC3D45'
                                  : 'color: #000000'
                                  ">
                                  {{ remaining_date }}
                                </span>
                                วัน
                              </b>
                            </span>
                          </v-flex>
                          <v-flex class="text-end" xs4 d-flex align-center
                            v-if="remaining_date <= 30 && remaining_date >= 1">
                            <span style="color:#CC3D45;font-weight: bold;">* ใบ Certificate ใกล้หมดอายุ</span>
                          </v-flex>
                          <v-flex class="text-end" xs4 d-flex align-center v-if="remaining_date < 1">
                            <span style="color:#CC3D45;font-weight: bold;">* ใบ Certificate หมดอายุ กรุณาตรวจสอบ</span>
                          </v-flex>
                        </v-layout>

                        <!-- Show Certificate -->
                        <v-layout mt-4 v-if="user.certificateStatus === 1">
                          <v-flex>
                            <iframe :src="certificate_path" width="100%" height="842"></iframe>
                          </v-flex>
                        </v-layout>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>

        <!-- งานวิจัยที่รอดำเนินการ -->
        <v-layout v-if="!isEdit" py-8 id="ongoingResearch" ref="ongoingResearch">
          <v-flex>
            <v-layout justify-space-between pa-8 style="background-color: #3E7662;">
              <v-flex d-flex align-center justify-start>
                <!--                                @click.stop="dialog = true"-->
                <span style="font-size: 28px;font-weight: bold;color: white;">งานวิจัยที่อยู่ระหว่างการดำเนินงาน</span>
              </v-flex>
              <v-flex d-flex justify-center>
                <text-autocomplete id="researchList" placeholder="รายชื่องานวิจัยที่กำลังดำเนินการ"
                  :items="onGoingResearchList" hide-details item-text="name" itemValue="page" :model="page"
                  @update-data="getResearch($event)" />
              </v-flex>
              <v-flex d-flex align-center justify-end>
                <v-btn color="#fff" @click="createResearch">
                  <v-icon class="pr-2" color="#3E7662">add</v-icon>
                  <span style="color: #3E7662;font-weight: bold">สร้างงานวิจัย</span>
                </v-btn>
              </v-flex>
            </v-layout>

            <v-card>
              <v-layout pt-6 px-8 justify-space-between="">
                <v-flex xs8>
                  <v-layout>
                    <v-flex style="text-align: left;">
                      <span>1. ชื่อโครงการ</span>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex v-if="nameResearch !== ''" style="text-align: left;font-weight: bold;">
                      <span>{{ nameResearch }}</span>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex d-flex justify-end align-center>
                  <v-btn :dark="!disableCheck" color="#3E7662" :disabled="disableCheck" @click="editResearch()">
                    <span>อัพเดท/แก้ไขงานวิจัย</span>
                    <v-icon>edit</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>

              <v-layout pt-6 px-8 justify-space-between="">
                <v-flex d-flex justify-end align-center>
                  <v-btn elevation="2" outlined width="193px" color="#F30D0D" @click="cancelReserch()">
                    <span>ขอยกเลิกโครงการ</span>
                  </v-btn>
                </v-flex>
              </v-layout>

              <v-layout pb-12 pt-6 pl-8>
                <v-flex xs6>
                  <v-layout justify-space-between mb-6>
                    <v-flex d-flex justify-end align-center xs4 style="text-align: right;">
                      <span>สถานะงานวิจัย</span>
                    </v-flex>
                    <v-flex mx-4 d-flex align-center xs8 style="text-align: left; font-weight: bold">
                      <textfield-form :background-color="researchReadonly ? '#EEEEEE' : '#FBF6F3'
                        " :model="researchForm.statusResearch" :readonly="researchReadonly"
                        hide-detail></textfield-form>
                    </v-flex>
                  </v-layout>
                  <v-layout justify-space-between mb-6>
                    <v-flex d-flex justify-end align-center xs4 style="text-align: right;">
                      <span>สถานะการแก้ไข</span>
                    </v-flex>
                    <v-flex mx-4 d-flex align-center xs8 style="text-align: left; font-weight: bold">
                      <textfield-form :background-color="researchReadonly ? '#EEEEEE' : '#FBF6F3'
                        " :model="researchForm.editStatus" :readonly="researchReadonly" hide-detail></textfield-form>
                    </v-flex>
                  </v-layout>
                  <v-layout justify-space-between mb-6>
                    <v-flex d-flex justify-end align-center xs4 style="text-align: right;">
                      <span>เลขที่งานวิจัย</span>
                    </v-flex>
                    <v-flex mx-4 d-flex align-center xs4 style="text-align: left; font-weight: bold">
                      <textfield-form :background-color="researchReadonly ? '#EEEEEE' : '#FBF6F3'
                        " :model="researchForm.researchNumber" :readonly="researchReadonly"
                        hide-detail></textfield-form>
                    </v-flex>
                    <v-flex xs4></v-flex>
                  </v-layout>
                  <v-layout justify-space-between mb-6>
                    <v-flex d-flex justify-end align-center xs4 style="text-align: right;">
                      <span>วันที่อนุมัติโครงร่าง</span>
                    </v-flex>
                    <v-flex mx-4 d-flex align-center xs4 style="text-align: left; font-weight: bold">
                      <textfield-form :background-color="researchReadonly ? '#EEEEEE' : '#FBF6F3'
                        " :model="researchForm.approvalDate" :readonly="researchReadonly" hide-detail></textfield-form>
                    </v-flex>
                    <v-flex xs4></v-flex>
                  </v-layout>
                  <v-layout justify-space-between mb-6>
                    <v-flex d-flex justify-end align-center xs4 style="text-align: right;">
                      <span>วันที่ต่ออายุโครงร่าง</span>
                    </v-flex>
                    <v-flex mx-4 d-flex align-center xs4 style="text-align: left; font-weight: bold">
                      <textfield-form :background-color="researchReadonly ? '#EEEEEE' : '#FBF6F3'
                        " :model="researchForm.approvalExpireDate" :readonly="researchReadonly"
                        hide-detail></textfield-form>
                    </v-flex>
                    <v-flex xs4></v-flex>
                  </v-layout>
                  <v-layout justify-space-between mb-6 v-if="considerType != 3">
                    <v-flex xs4 d-flex align-center style="text-align: right;color: #CC3D45">
                      <span>*จำนวนวันที่จะสิ้นสุด การส่งรายงานความก้าวหน้า</span>
                    </v-flex>
                    <v-flex mx-4 d-flex align-center xs4 style=" font-weight: bold">
                      <textfield-form :background-color="researchReadonly ? '#EEEEEE' : '#FBF6F3'
                        " :model="researchForm.remainDate" hide-detail :readonly="researchReadonly"></textfield-form>
                    </v-flex>
                    <v-flex style="color: #CC3D45" xs4 d-flex align-center>
                      <span>วัน</span>
                    </v-flex>
                  </v-layout>
                </v-flex>

                <v-flex xs6 mr-8 v-if="user.certificateStatus !== 1 ||
                  this.researchForm.statusResearch !== 'อนุมัติ' ||
                  user.remark === 'T'
                ">
                  <div style="text-align: right" v-if="user.remark === 'T'" class="my-4">
                    <span style="color:#CC3D45;font-weight: bold;">* Training request กรุณาทำการทดสอบอีกครั้ง
                      ก่อนดำเนินการใดใด</span>
                  </div>
                  <div class="mb-6" style="text-align: right;color: darkgrey">
                    <span>
                      <!--                                            รายงานแก้ไขเพิ่มเติมโครงร่างการวิจัย (Amendment Protocol)-->
                      แบบรายงานการแก้ไขเพิ่มเติมโครงร่างการวิจัย (RF 12_2563)
                    </span>
                  </div>
                  <div class="mb-6" style="text-align: right;color: darkgrey">
                    <span>
                      <!--                                            แบบรายงานเหตุการณ์ไม่พึงประสงค์จากยา (SAE Report)-->
                      แบบรายงานเหตุการณ์ไม่พึงประสงค์จากยา/เหตุการณ์ไม่คาดคิดจากยา
                      (RF 04_1_2563)
                    </span>
                  </div>
                  <div class="mb-6" style="text-align: right;color: darkgrey">
                    <span>
                      <!--                                           แบบรายงานเหตุการณ์ไม่พึง ประสงค์นอกสถาบัน /DSMB/DSUR/DMC-->
                      แบบรายงานเหตุการณ์ไม่พึง ประสงค์นอกสถาบัน /DSMB/DSUR/DMC
                    </span>
                  </div>
                  <div class="mb-6" style="text-align: right;color: darkgrey">
                    <span>
                      <!--                                            แบบรายงานเหตุการณ์ไม่พึงประสงค์ (นอกเหนือจากยา) (SAE Report)-->
                      แบบรายงานเหตุการณ์ไม่พึงประสงค์ (SAE) (RF 04_2_2563)
                    </span>
                  </div>
                  <div class="mb-6" style="text-align: right;color: darkgrey">
                    <span>
                      <!--                                            รายงานการเบี่ยงเบนโครงร่างวิจัย (Deviation Report)-->
                      แบบรายงานการไม่ปฏิบัติตามข้อกำหนด (RF 05_2563)
                    </span>
                  </div>
                  <div class="mb-6" style="text-align: right;color: darkgrey">
                    <span>
                      <!--                                            รายงานความก้าวหน้าและขอต่ออายุโครงการวิจัย (Progress Report)-->
                      แบบรายงานความก้าวหน้าของการวิจัย/ขอต่ออายุโครงการ (RF
                      03_2563)
                    </span>
                  </div>
                  <div class="mb-6" style="text-align: right;color: darkgrey">
                    <span>
                      แบบรายงานแจ้งปิด/สรุปผลการวิจัย (RF 06_2563)
                      <!--                                            รายงานสรุปผลการวิจัย (Final Report)-->
                    </span>
                  </div>
                  <!--                                    <div class="mb-6" style="text-align: right;color: darkgrey">-->
                  <!--                                        <span>รายงานแจ้งเพื่อทราบอื่นๆ (Correspondent Report)</span>-->
                  <!--                                    </div>-->
                  <div class="mb-6" style="text-align: right;color: darkgrey">
                    <span>
                      <!--                                          แจ้งปิดโครงการวิจัยก่อนกำหนด (Terminate)-->
                      แบบรายงานการยุติโครงการวิจัยก่อนกําหนด (RF 07_2563)
                    </span>
                  </div>
                  <div class="mb-6" style="text-align: right;color: darkgrey">
                    <span>
                      <!--                                          แจ้งปิดโครงการวิจัยก่อนกำหนด (Terminate)-->
                      เอกสารและจดหมายแจ้งเกี่ยวกับงานวิจัย (Correspondence Report)
                    </span>
                  </div>

                </v-flex>

                <v-flex xs6 mr-8 style="text-align: right;" v-if="user.certificateStatus === 1 &&
                  this.researchForm.statusResearch === 'อนุมัติ' &&
                  user.remark !== 'T'
                ">
                  <div class="mb-6">
                    <span class="research-link" @click="onClickRF('FormRF12')" v-if="checkRF12 === true">
                      <!--                                            รายงานแก้ไขเพิ่มเติมโครงร่างการวิจัย (Amendment Protocol)-->
                      แบบรายงานการแก้ไขเพิ่มเติมโครงร่างการวิจัย (RF 12_2563)
                    </span>
                    <span v-else style="text-align: right;color: darkgrey">
                      <!--                                          รายงานแก้ไขเพิ่มเติมโครงร่างการวิจัย (Amendment Protocol)-->
                      แบบรายงานการแก้ไขเพิ่มเติมโครงร่างการวิจัย (RF 12_2563)
                    </span>
                  </div>
                  <div class="mb-6">
                    <span class="research-link" @click="onClickRF('FormRF04_1')" v-if="checkRF04_1 === true">
                      <!--                                            แบบรายงานเหตุการณ์ไม่พึงประสงค์จากยา (SAE Report)-->
                      แบบรายงานเหตุการณ์ไม่พึงประสงค์จากยา/เหตุการณ์ไม่คาดคิดจากยา
                      (RF 04_1_2563)
                    </span>
                    <span v-else style="text-align: right;color: darkgrey">
                      <!--                                          แบบรายงานเหตุการณ์ไม่พึงประสงค์จากยา (SAE Report)-->
                      แบบรายงานเหตุการณ์ไม่พึงประสงค์จากยา/เหตุการณ์ไม่คาดคิดจากยา
                      (RF 04_1_2563)
                    </span>
                  </div>
                  <div class="mb-6">
                    <span class="research-link" @click="onClickRF('FormSusar')">
                      <!--                                          แบบรายงานเหตุการณ์ไม่พึง ประสงค์นอกสถาบัน /DSMB/DSUR/DMC-->
                      แบบรายงานเหตุการณ์ไม่พึง ประสงค์นอกสถาบัน /DSMB/DSUR/DMC
                    </span>
                  </div>
                  <div class="mb-6">
                    <span class="research-link" @click="onClickRF('FormRF04_2')" v-if="checkRF04_2 === true">
                      <!--                                            แบบรายงานเหตุการณ์ไม่พึงประสงค์ (นอกเหนือจากยา) (SAE Report)-->
                      แบบรายงานเหตุการณ์ไม่พึงประสงค์ (SAE) (RF 04_2_2563)
                    </span>
                    <span v-else style="text-align: right;color: darkgrey">
                      <!--                                          แบบรายงานเหตุการณ์ไม่พึงประสงค์ (นอกเหนือจากยา) (SAE Report)-->
                      แบบรายงานเหตุการณ์ไม่พึงประสงค์ (SAE) (RF 04_2_2563)
                    </span>
                  </div>
                  <div class="mb-6">
                    <span class="research-link" @click="onClickRF('FormRF05')" v-if="checkRF05 === true">
                      <!--                                            รายงานการเบี่ยงเบนโครงร่างวิจัย (Deviation Report)-->
                      แบบรายงานการไม่ปฏิบัติตามข้อกำหนด (RF 05_2563)
                    </span>
                    <span v-else style="text-align: right;color: darkgrey">
                      <!--                                          รายงานการเบี่ยงเบนโครงร่างวิจัย (Deviation Report)-->
                      แบบรายงานการไม่ปฏิบัติตามข้อกำหนด (RF 05_2563)
                    </span>
                  </div>
                  <div class="mb-6">
                    <span class="research-link" @click="onClickRF('FormRF03')" v-if="checkRF03 === true">
                      <!--                                            รายงานความก้าวหน้าและขอต่ออายุโครงการวิจัย (Progress Report)-->
                      แบบรายงานความก้าวหน้าของการวิจัย/ขอต่ออายุโครงการ (RF
                      03_2563)
                    </span>
                    <span v-else style="text-align: right;color: darkgrey">
                      <!--                                          รายงานความก้าวหน้าและขอต่ออายุโครงการวิจัย (Progress Report)-->
                      แบบรายงานความก้าวหน้าของการวิจัย/ขอต่ออายุโครงการ (RF
                      03_2563)
                    </span>
                  </div>
                  <div class="mb-6">
                    <span class="research-link" @click="onClickRF('FormRF06')" v-if="checkRF06 === true">
                      <!--                                            รายงานสรุปผลการวิจัย (Final Report)-->
                      แบบรายงานแจ้งปิด/สรุปผลการวิจัย (RF 06_2563)
                    </span>
                    <span v-else style="text-align: right;color: darkgrey">
                      <!--                                          รายงานสรุปผลการวิจัย (Final Report)-->
                      แบบรายงานแจ้งปิด/สรุปผลการวิจัย (RF 06_2563)
                    </span>
                  </div>
                  <div class="mb-6">
                    <span class="research-link" @click="onClickRF('FormRF07')" v-if="checkRF07 === true">
                      <!--                                            แจ้งปิดโครงการวิจัยก่อนกำหนด (Terminate)-->
                      แบบรายงานการยุติโครงการวิจัยก่อนกําหนด (RF 07_2563)
                    </span>
                    <span v-else style="text-align: right;color: darkgrey">
                      <!--                                          แจ้งปิดโครงการวิจัยก่อนกำหนด (Terminate)-->
                      แบบรายงานการยุติโครงการวิจัยก่อนกําหนด (RF 07_2563)
                    </span>
                  </div>
                  <div class="mb-6">
                    <span class="research-link" @click="onClickRF('Correspondence')" v-if="checkCorres === true">
                      <!--                                            แจ้งปิดโครงการวิจัยก่อนกำหนด (Terminate)-->
                      เอกสารและจดหมายแจ้งเกี่ยวกับงานวิจัย (Correspondence Report)
                    </span>
                    <span v-else style="text-align: right;color: darkgrey">
                      <!--                                          แจ้งปิดโครงการวิจัยก่อนกำหนด (Terminate)-->
                      เอกสารและจดหมายแจ้งเกี่ยวกับงานวิจัย (Correspondence Report)
                    </span>
                  </div>
                </v-flex>
              </v-layout>

              <v-layout pb-10 pr-8>
                <v-flex class="text-end">
                  <v-btn color="#3E7662" dark @click="
                    $router.push({
                      name: 'HistoryResearch',
                      query: { id: researchForm.refID },
                    })
                    ">ประวัติการส่ง/แก้ไขงานวิจัย</v-btn>
                </v-flex>
              </v-layout>
            </v-card>

            <v-layout mt-8>
              <v-flex v-if="nameResearch !== ''">
                <v-pagination color="#3E7662" :length="paginateResearch.last_page" :total-visible="9"
                  @input="getResearch" v-model="paginateResearch.current_page"></v-pagination>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <!-- Table งานวิจัยทั้งหมด -->
        <form-table v-if="!isEdit" name-table="งานวิจัยทั้งหมด" :headers="researchAllHeaders" :items="itemResearchAll"
          :items-per-page="itemsPerPage"></form-table>

        <!--                <v-layout mt-8>-->
        <!--                    <v-flex>-->
        <!--                        <v-pagination-->
        <!--                                :length="paginateResearchAll.last_page"-->
        <!--                                :total-visible="9"-->
        <!--                                @input="getAllResearchPaginate"-->
        <!--                                v-model="paginateResearchAll.current_page"-->
        <!--                        ></v-pagination>-->
        <!--                    </v-flex>-->
        <!--                </v-layout>-->
      </v-flex>
    </v-layout>

    <v-layout mt-8 v-if="isEdit">
      <v-flex mb-12 style="padding: 0 5% 2% 5%; text-align: right;">
        <v-layout>
          <v-flex>
            <v-btn style="color: #3E7662" class="mx-4" depressed color="#ffffff"
              @click="onEdit('back')">ย้อนกลับ</v-btn>
            <v-btn depressed dark color="#3E7662" @click="update">ยืนยันการแก้ไขข้อมูล</v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <!-- MODAL DIALOG -->
    <v-dialog v-model="dialog" max-width="830">
      <v-card>
        <v-layout justify-end>
          <v-flex d-flex justify-end align-center>
            <v-icon @click="dialog = false" color="#3E7662">clear</v-icon>
          </v-flex>
        </v-layout>
        <v-layout pa-12>
          <v-flex>
            <v-layout>
              <v-flex>
                <span>ชื่อโครงการ</span>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex>
                <span style="font-size: 18px;font-weight: bold;">การสำรวจข้อมูลพื้นฐานประชากรและความชุกของปัจจัยเสี่ยง
                  ทางด้านสุขภาพในชุมชนชนบท</span>
              </v-flex>
            </v-layout>
            <v-layout mt-8 justify-center>
              <v-flex xs6>
                <v-layout pa-12 style="background-color: #FAF5F2">
                  <v-flex>
                    <div>
                      <span style="color: #CC3D45">*จำนวนวันที่จะสิ้นสุดการรับรอง</span>
                    </div>
                    <div style="color: #CC3D45">
                      <span>เหลืออีก</span>
                      <span class="mx-6" style="font-size: 36px">60</span>
                      <span>วัน</span>
                    </div>
                  </v-flex>
                </v-layout>
                <v-layout mt-8 justify-space-between="">
                  <v-flex d-flex justify-start align-center>
                    <v-btn color="#3E7662" dark>
                      <span>ต่ออายุโครงการ</span>
                    </v-btn>
                  </v-flex>
                  <v-flex d-flex justify-end align-center>
                    <v-btn color="#3E7662" dark>
                      <span>ภายหลัง</span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>


    <template>
      <v-row justify="center">
        <v-dialog v-model="corresDialog" persistent width="1200px" max-width="80%">
          <v-card>
            <!-- Header -->
            <v-card-title class="mb-4">
              <span class="font-weight-medium font-weight-bold">แนบเอกสาร Correspondence Report</span>
            </v-card-title>
            <v-card-text>
              <v-layout ma-6 class=" d-flex justify-space-around">
                <v-layout class="shrink flex pt-3 font-weight-bold  text-right">ชื่อเอกสาร
                </v-layout>
                <v-layout class="flex xs4">
                  <TextfieldForm id="corresName" hide-detail :model="corresForm.name"
                    @update-data="corresForm.name = $event"></TextfieldForm>
                </v-layout>
                <v-layout class="flex shrink pt-3 font-weight-bold text-right">ไฟล์
                </v-layout>
                <v-layout class="flex xs4 text-start">
                  <v-file-input accept=".pdf" prepend-icon="" id="corresFile" :model="corresForm.file" outlined dense
                    background-color="#FBF6F3" color="#3E7662" item-color="#3E7662"
                    @change="uploadCorresFile($event, i)" hide-details></v-file-input>
                </v-layout>
                <v-layout shrink pl-4 style="text-align: left" class="d-flex align-center">
                  <v-btn color="#3E7662" dark @click="uploadCorresFileBtn()">
                    <v-icon class="pr-3">cloud_download</v-icon>
                    <span>Upload</span>
                  </v-btn>
                </v-layout>
              </v-layout>
            </v-card-text>

            <v-card-title class="mb-4">
              <span class="font-weight-medium font-weight-bold">แนบเอกสารอื่นๆที่เกี่ยวข้อง</span>
            </v-card-title>
            <v-card-text>
              <v-layout px-12 my-4>
                <v-flex shrink mr-4>
                  <v-btn dark style="width: 80px;background-color: #3E7662" @click="addDynamicFile('otherFile')">
                    <v-icon>add</v-icon>
                    <span class="font-weight-bold">เพิ่ม</span>
                  </v-btn>
                </v-flex>
              </v-layout>
              <div v-for="(input, k) in corresForm.otherFile" :key="k">
                <v-layout mt-6 class="align-center">
                  <v-flex shrink mr-4 pt-2 style="text-align: right;font-weight: bold;">
                    <span>ชื่อไฟล์</span>
                  </v-flex>
                  <v-flex xs4>
                    <textfield-form hide-detail :model="input.title"
                      @update-data="updateDynamicFileData('otherFile', 'title', k, $event)"></textfield-form>
                  </v-flex>
                  <v-flex shrink pt-2 px-4 style="text-align: left;">
                    <span style="font-weight: bold;font-size: 16px;">ไฟล์</span>
                  </v-flex>
                  <v-flex class="mt-6" xs5>
                    <a v-if="input.filePath" :href="input.filePath" target="_blank"
                      style="color: #3e7662;text-align: center" class="link-hover">
                      <p>{{ input.file }}</p>
                    </a>
                    <p v-if="!input.filePath" class="text-center" style="color:red;"> *กรุณาอัพโหลดไฟล์</p>
                    <v-file-input style="display: none" accept=".pdf" prepend-icon="" :id="'otherFile' + k"
                      :model="input.file" outlined dense background-color="#FBF6F3" color="#3E7662" item-color="#3E7662"
                      @change="updateDynamicFileData('otherFile', 'file', k, $event)"></v-file-input>
                  </v-flex>
                  <v-flex shrink pl-4 style="text-align: left" class="">
                    <v-layout>
                      <v-btn color="#3E7662" dark @click="uploadFileBtn('otherFile' + k)">
                        <v-icon class="pr-3">cloud_download</v-icon>
                        <span>Upload</span>
                      </v-btn>
                    </v-layout>
                    <v-layout mt-4>
                      <v-btn dark style="background-color: #CC3D45; width: 80px"
                        @click="removeDynamicFile('otherFile', k)">
                        <v-icon>remove</v-icon>
                        <span class="font-weight-bold">ลบ</span>
                      </v-btn>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn style="font-weight: bold" color="#3E7662" text @click="corresDialog = false">
                Close
              </v-btn>
              <v-btn style="font-weight: bold" color="#3E7662" text @click="sendCorresFile">Send
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <template>
      <v-row justify="center">
        <v-dialog v-model="cancelModel" persistent max-width="600px">
          <v-card>
            <!-- Header -->
            <v-card-title class="mb-4" style="background-color:#3E7662">
              <span style="color:#FFFFFF;font-weight: bold;">ขอยกเลิกโครงการ</span>
            </v-card-title>
            <v-card-text>
              <!-- row 1 -->
              <v-layout>
                <v-flex xs3 mr-1 d-flex>
                  <span style="font-weight: bold;">ชื่อโครงการ</span>
                </v-flex>
              </v-layout>
              <v-layout px-6>
                <v-flex xs6 mr-1 d-flex>
                  <span style="font-weight: bold;">{{ nameResearch }}</span>
                </v-flex>
              </v-layout>
              <!-- row 2 -->
              <v-layout mt-6 px-6>
                <v-flex xs4 mr-1 d-flex>
                  <span style="font-weight: bold;">เอกสารขอยกเลิกโครงการ</span>
                </v-flex>
              </v-layout>
              <v-layout mt-1 px-6>
                <v-flex xs1 mr-3 pt-5 d-flex>
                  <span style="font-weight: bold;">ไฟล์</span>
                </v-flex>
                <v-flex xs9 pt-4 class="field-select">
                  <v-file-input accept=".pdf" prepend-icon="" id="cancelFile" :model="form.cancelForm.file" outlined
                    dense background-color="#FBF6F3" color="#3E7662" item-color="#3E7662" hide-details
                    placeholder="Choose file" @change="uploadCannelFile('cancelResearchFile', $event)"></v-file-input>
                </v-flex>
                <v-flex ml-3 pt-4>
                  <v-btn color="#3E7662" dark @click="uploadcancelFileBtn()">
                    <v-icon class="pr-3">cloud_download</v-icon>
                    <span>Upload</span>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn style="font-weight: bold" color="#3E7662" text @click="cancelModel = false">ยกเลิก
              </v-btn>
              <v-btn style="font-weight: bold" color="#3E7662" text @click="setCancelupload(form)">บันทึก</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <loading color="#527281" :active.sync="isLoading" :can-cancel="false" :is-full-page="fullPage"></loading>
  </v-content>
</template>

<script>
import Swal from "sweetalert2";
import { mapState, mapActions, mapMutations } from "vuex";
import TextfieldForm from "../components/input/TextfieldForm";
import TextareaForm from "../components/input/TextareaForm";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import FormTable from "../components/table/FormTable";
import DateInputForm from "../components/input/DateInputForm";
import TextAutocomplete from "../components/input/TextAutocomplete";
import axios from "axios";
import { domain } from "../services/Constants";

export default {
  name: "Profile",
  components: {
    TextfieldForm,
    TextareaForm,
    Loading,
    FormTable,
    DateInputForm,
    TextAutocomplete,
  },
  computed: {
    ...mapState({
      user: (state) => state.Auth.user,
      remainingDate: (state) => state.Common.remainingDate,
      isOnlineExpand: (state) => state.Auth.isOnlineExpand,
    }),
  },
  data: () => ({
    itemsPerPage: null,
    dialog: false,
    researchReadonly: true,
    isLoading: false,
    fullPage: true,
    role: [],
    titleName: [],
    rankName: [],
    isEdit: false,
    certificate_wait: "",
    form: {
      idNumber: "",
      role: "",
      rank: null,
      nameTitle: "",
      preNameTh: "",
      preNameEn: "",
      thName: "",
      thFirstName: "",
      thLastName: "",
      enName: "",
      enFirstName: "",
      enLastName: "",
      education: "",
      otherEducation: "",
      workEx: "",
      field: "",
      otherField: "",
      position: "",
      otherPosition: "",
      team: "",
      teamEN: "",
      otherTeam: "",
      otherTeamEN: "",
      affiliationID: "",
      otherAffiliation: "",
      otherAffiliationEN: "",
      houseNumber: "",
      road: "",
      practiceEx: "",
      interResearch: "",
      otherWork: "",
      award: "",
      currentResearch: "",
      subDistrictID: "",
      districtID: "",
      provinceID: "",
      postalCode: "",
      telPhone: "",
      mobilePhone: "",
      email: "",
      password: "",
      password_confirmation: "",
      pictureFile: "",
      cancelForm: {
        file: '',
        name: '',
        researchID: ''
      },
      certificate_gcp_training: null,
      certificate_date: new Date(),
    },
    changePassword: {
      password: "",
      password_confirmation: "",
    },
    formCertificate: {
      certificate_gcp_training: "",
      certificate_date: new Date(),
    },
    researchForm: {
      id: "",
      statusResearch: "",
      editStatus: "-",
      researchNumber: "",
      approvalDate: "",
      approvalExpireDate: "",
      remainDate: "",
      firstApprovalDate: "",
      numberCertificateEnd: "",
      refID: "",
    },
    onGoingResearchList: [],
    provinces: [],
    districts: [],
    subDistricts: [],
    imageName: "",
    imageUrl: "",
    imageFile: "",
    isOtherField: true,
    isOtherPosition: true,
    isOtherTeam: true,
    isOtherAffiliation: true,
    isOtherEducation: true,
    isOldCer: true,

    // data table
    headers: [
      { text: "ลำดับ", value: "number", align: "center", width: "10%" },
      { text: "ชื่อผู้วิจัย", value: "name", align: "center", width: "20%" },
      {
        text: "ชื่อโครงการงานวิจัย",
        value: "research",
        align: "center",
        width: "30%",
      },
      { text: "Role", value: "role", align: "center", width: "10%" },
      { text: "สถานะ", value: "status", align: "center", width: "10%" },
    ],
    ParticipantList: [],
    researchAllHeaders: [
      { text: "ลำดับ", value: "number", align: "center", width: "10%" },
      {
        text: "ชื่อโครงการงานวิจัย",
        value: "researchName",
        align: "center",
        width: "50%",
      },
      { text: "สถานะ", value: "status", align: "center", width: "20%" },
      { text: "version", value: "version", align: "center", width: "10%" },
    ],
    itemResearchAll: [],
    nameResearch: "",
    paginateResearch: {
      current_page: 1,
      from: 1,
      last_page: 1,
      per_page: 1,
      total: 1,
      to: 1,
    },
    certificate_path: "",
    remaining_date: "",
    dateChange1: "",
    dataResearch: [],
    disableCheck: false,
    educationList: [
      { education_id: 0, education_name: "น้อยกว่าปริญญาตรี" },
      { education_id: 1, education_name: "ปริญญาตรี" },
      { education_id: 2, education_name: "ปริญญาโท" },
      { education_id: 3, education_name: "ปริญญาเอก" },
      { education_id: 4, education_name: "อื่นๆ" },
    ],
    fieldList: [
      { field_id: 1, field_name: "สูตินรีเวชกรรม" },
      { field_id: 2, field_name: "ศัลยกรรม" },
      { field_id: 3, field_name: "อายุรกรรม" },
      { field_id: 4, field_name: "กุมารเวชกรรม" },
      { field_id: 5, field_name: "มะเร็งวิทยา" },
      { field_id: 6, field_name: "เภสัชกรรม" },
      { field_id: 7, field_name: "ออร์โธปิดิกส์" },
      { field_id: 8, field_name: "ระบาดวิทยา" },
      { field_id: 9, field_name: "พยาธิวิทยา" },
      { field_id: 10, field_name: "พยาบาล" },
      { field_id: 11, field_name: "อื่นๆ" },
    ],
    positionList: [
      { position_id: 1, position_name: "ผศ." },
      { position_id: 2, position_name: "รศ." },
      { position_id: 3, position_name: "ศ." },
      { position_id: 4, position_name: "ไม่มี" },
      { position_id: 5, position_name: "อื่นๆ" },
    ],
    teamList: [
      {
        id: 1,
        team_name: 'กองตรวจโรคผู้ป่วยนอก',
        team_name_en: 'Outpatient Department'
      },
      {
        id: 2,
        team_name: 'กองอุบัติเหตุและเวชกรรมฉุกเฉิน',
        team_name_en: 'Trauma and Emergency Medicine Department'
      },
      {
        id: 3,
        team_name: 'กองอายุรกรรม',
        team_name_en: 'Medicine Department'
      },
      {
        id: 4,
        team_name: 'กองจิตเวชและประสาทวิทยา',
        team_name_en: 'Psychiatry and Neurology Department'
      },
      {
        id: 5,
        team_name: 'กองการพยาบาล',
        team_name_en: 'Nursing Department'
      },
      {
        id: 6,
        team_name: 'กองรังสีกรรม',
        team_name_en: 'Radiology Department'
      },
      {
        id: 7,
        team_name: 'กองวิสัญญีและห้องผ่าตัด',
        team_name_en: 'Anesthesiology and operating room Department'
      },
      {
        id: 8,
        team_name: 'กองออร์โธปิดิกส์',
        team_name_en: 'Orthopedic Department'
      },
      {
        id: 9,
        team_name: 'กองเภสัชกรรม',
        team_name_en: 'Pharmaceutical Department'
      },
      {
        id: 10,
        team_name: 'กองสูตินรีเวชกรรม',
        team_name_en: 'Obstetrics And Gynecology Department'
      },
      {
        id: 11,
        team_name: 'กองกุมารเวชกรรม',
        team_name_en: 'Pediatric Department'
      },
      {
        id: 12,
        team_name: 'กองจักษุกรรม',
        team_name_en: 'Ophthalmology Department'
      },
      {
        id: 13,
        team_name: 'กองโสต ศอ นาสิกกรรม',
        team_name_en: 'Otolaryngology Department'
      },
      {
        id: 14,
        team_name: 'กองเวชศาสตร์ฟื้นฟู',
        team_name_en: 'Rehabilitation medicine Department'
      },
      {
        id: 15,
        team_name: 'กองทันตกรรม',
        team_name_en: 'Dental Department'
      },
      {
        id: 16,
        team_name: 'กองศัลยกรรม',
        team_name_en: 'Surgical Department'
      },
      {
        id: 17,
        team_name: 'กองพยาธิวิทยา',
        team_name_en: 'Pathology Department'
      },
      {
        team_name: 'ไม่มี'
      },
      {
        team_name: 'อื่นๆ'
      },
    ],
    affiliationList: [],
    userInfo: [],
    budgetResource: "",
    checkRF12: null,
    checkRF04_1: null,
    checkRF04_2: null,
    checkRF05: null,
    checkRF03: null,
    checkRF06: null,
    checkRF07: null,
    checkCorres: null,
    corresForm: {
      file: '',
      name: '',
      researchID: '',
      otherFile: [],
    },

    corresDialog: false,
    page: 0,
    activeResearchCount: 0,
    considerType: "",
    cancelModel: false,
    cancelFile: "",
  }),
  async created() {
    this.isLoading = true;
    await Promise.all([
      this.getUserProfile(),
      this.getProvinces(),
      this.getRole(),
      this.getTitle(),
      this.getRank(),

      this.getAffiliationList(),
      this.getResearch(1),

      this.getAllResearchPaginate(),
      this.getCertificate(),
      this.getParticipantList(),
    ]);
    this.isLoading = false;

    this.isEdit = this.$route.params.edit;
    this.imageUrl = this.user.pictureFilePath;
    this.form.pictureFile = this.user.pictureFile;

    setTimeout(() => {
      this.scrollMeTo(this.$route.params.onFocus);
    }, 1000);
  },
  methods: {
    ...mapMutations("Auth", ["Expand_STATE"]),
    // UPDATE DATA TO COMPONENT

    scrollMeTo(on_focus) {
      if (on_focus) {
        this.$vuetify.goTo("#ongoingResearch");
      }
    },
    updateData(name, val) {
      this.form[name] = val;
      switch (name) {
        case "provinceID":
          this.getDistricts(val);
          break;
        case "districtID":
          this.getSubDistricts(val);
          break;
        case "field":
          if (this.form["field"] === "อื่นๆ") {
            this.isOtherField = false;
          } else {
            this.isOtherField = true;
            this.form["otherField"] = "";
          }
          this.form["otherField"] = "";
          break;
        case "position":
          if (this.form["position"] === "อื่นๆ") {
            this.isOtherPosition = false;
          } else {
            this.isOtherPosition = true;
            this.form["otherPosition"] = "";
          }
          this.form["otherPosition"] = "";
          break;
        case "team":
          this.teamEN = this.teamList.find((data) => data.team_name === val);
          this.form["team_en"] = this.teamEN.team_name_en;
          if (this.form["team"] === "อื่นๆ") {
            this.isOtherTeam = false;
          } else {
            this.isOtherTeam = true;
            this.form["otherTeam"] = "";
            this.form["otherTeamEN"] = "";
          }
          this.form["otherTeam"] = "";
          this.form["otherTeamEN"] = "";
          break;
        case "affiliationID":
          if (this.form.affiliationID === 15) {
            this.isOtherAffiliation = false;
          } else {
            this.isOtherAffiliation = true;
            this.form["otherAffiliation"] = "";
            this.form["otherAffiliationEN"] = "";
          }
          this.form["otherAffiliation"] = "";
          this.form["otherAffiliationEN"] = "";
          break;
        case "education":
          if (this.form["education"] === "อื่นๆ") {
            this.isOtherEducation = false;
          } else {
            this.isOtherEducation = true;
            this.form["otherEducation"] = "";
          }
          this.form["otherEducation"] = "";
          break;
        default:
      }
    },
    updatePasswordData(name, val) {
      this.changePassword[name] = val;
    },
    updateCertificateFile(name, val) {
      if (name === "certificate_date") {
        this.formCertificate[name] = val;
        this.dateChange1 = this.changeDate(val);
        let nowDate = new Date();
        nowDate.setFullYear(nowDate.getFullYear() - 2);
        if (new Date(val) > nowDate) this.isOldCer = false;
        else this.isOldCer = true;
      }
    },
    changeDate(val) {
      //change date start
      let sub_year = val.substring(0, 4);
      let sub_month = val.substring(5, 7);
      let sub_day = val.substring(8, 10);
      sub_year = parseInt(sub_year) + 543;
      let changeDate = sub_day + "/" + sub_month + "/" + sub_year;
      return changeDate;
    },
    // GET USER BY ID
    getUserProfile() {
      // this.isLoading = true;
      this.$store
        .dispatch("Auth/getUser")
        .then((res) => {
          this.getDistricts(this.user.provinceID);
          this.getSubDistricts(this.user.districtID);
          return res;
        })
        .then((res) => {
          this.form.idNumber = this.checkValueNull(res.idNumber);
          this.form.role = res.roleID;
          this.form.rank = res.rankID;
          this.form.nameTitle = res.nameTitleID;
          this.form.preNameTh = res.preNameTh;
          this.form.preNameEn = res.preNameEn;
          this.form.thName = res.thName;
          this.form.enName = res.enName;
          this.form.thFirstName = res.thFirstName;
          this.form.enFirstName = res.enFirstName;
          this.form.thLastName = res.thLastName;
          this.form.enLastName = res.enLastName;
          this.form.education = res.education;
          this.form.otherEducation =
            res.otherEducation && res.otherEducation !== "null"
              ? res.otherEducation
              : "";
          this.user.profileEducation =
            res.education === "อื่นๆ"
              ? res.education + " (" + res.otherEducation + ")"
              : res.education;
          this.form.educationEx = res.educationEx;
          this.form.workEx = res.workEx;
          this.form.field = res.field;
          this.form.otherField = res.otherField ? res.otherField : "";
          this.user.profileField =
            res.field === "อื่นๆ"
              ? res.field + " (" + res.otherField + ")"
              : res.field;
          this.form.affiliationID = res.affiliation ? res.affiliation.id : '';
          this.isOtherField = res.field !== "อื่นๆ";
          this.isOtherPosition = res.position !== "อื่นๆ";
          this.isOtherTeam = res.team !== "อื่นๆ";
          this.isOtherAffiliation = this.form.affiliationID !== 15;
          this.form.practiceEx = res.practiceEx;
          this.form.interResearch = this.checkValueNull(res.interResearch);
          this.form.otherWork = this.checkValueNull(res.otherWork);
          this.form.award = this.checkValueNull(res.award);
          this.form.currentResearch = this.checkValueNull(res.currentResearch);
          this.form.position = res.position;
          this.form.otherPosition =
            res.otherPosition && res.otherPosition !== "null"
              ? res.otherPosition
              : "";
          this.form.team = res.team;
          this.form.otherTeam =
            res.otherTeam && res.otherTeam !== "null"
              ? res.otherTeam
              : "";
          this.form.otherTeamEN =
            res.otherTeamEN && res.otherTeamEN !== "null"
              ? res.otherTeamEN
              : "";
          this.user.profilePosition =
            res.position === "อื่นๆ"
              ? res.position + " (" + res.otherPosition + ")"
              : res.position;
          this.user.profileTeam =
            res.team === "อื่นๆ"
              ? res.team + " (" + res.otherTeam + ")"
              : res.team;
          this.form.otherAffiliation =
            res.otherAffiliation && res.otherAffiliation !== "null"
              ? res.otherAffiliation
              : "";
          this.form.otherAffiliationEN =
            res.otherAffiliationEN && res.otherAffiliationEN !== "null"
              ? res.otherAffiliationEN
              : "";
          this.user.profileAffiliation =
            this.form.affiliationID === 15
              ? res.affiliation.affiliation + " (" + res.otherAffiliation + ")"
              : res.affiliation ? res.affiliation.affiliation : '';
          this.form.houseNumber = res.houseNumber;
          this.form.road = res.road;
          this.form.postalCode = res.postalCode;
          this.form.telPhone = res.telPhone;
          this.form.mobilePhone = res.mobilePhone;
          this.form.email = res.email;
          this.form.provinceID = res.provinceID;
          this.form.districtID = res.districtID;
          this.form.subDistrictID = res.subDistrictID;
          this.certificate_wait = res.certificate;
          this.user.remark = res.remark;
        });
    },
    checkValueNull(val) {
      if (
        val === "null" ||
        val === "NULL" ||
        val === "undefined" ||
        val === null
      ) {
        return (val = "");
      } else {
        return val;
      }
    },
    onEdit(val) {
      if (val === "edit") {
        this.isEdit = true;
      } else {
        this.isEdit = false;
        this.imageUrl = this.user.pictureFilePath
        window.scrollTo(0, 0)
        // this.imageUrl = this.user.pictureFile;
      }
    },
    checkTitleName(title) {
      if (title === "นาย") {
        return "Mr.";
      } else if (title === "นาง") {
        return "Miss";
      } else {
        return "";
      }
    },
    // UPLOAD IMAGE FILE
    uploadFile() {
      document.getElementById("file").click();
    },
    onFilePicked(e) {
      this.$store
        .dispatch("Common/uploadImage", { file: e, name: "image-profile" })
        .then((res) => {
          this.imageUrl = res.filePath;
          this.form.pictureFile = res.fileName;
        })
        .catch((e) => {
          document.getElementById("file").value = "";
          Swal.fire({
            title: "เกิดข้อผิดพลาด",
            text: "นามสกุลรูปภาพไม่ถูกต้อง (แนะนำ .jpg, .png)",
            icon: "warning",
            confirmButtonColor: "#3E7662",
          });
        });
    },
    // UPLOAD CORRES FILE
    uploadCorresFileBtn() {
      document.getElementById("corresFile").click();
    },
    // Upload Cancel File
    uploadcancelFileBtn() {
      document.getElementById("cancelFile").click();
    },
    uploadCorresFile(e) {
      this.$store.dispatch('Common/uploadPdf', { form: 'corres-file', file: e }).then(res => {
        this.corresForm.file = res.filePath;
      }).catch(error => {
      });
    },
    // UPDATE USER
    update() {
      Swal.fire({
        title: "คุณต้องการบันทึกการแก้ไข?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ใช่ ตกลง",
        confirmButtonColor: "#3E7662",
        cancelButtonText: "ยกเลิก",
        cancelButtonColor: "#CC3D45",
        // reverseButtons: true
      })
        .then((result) => {
          if (result.value) {
            this.form["id_user"] = this.user.id;
            this.form["idNumber"] =
              this.form["idNumber"] === "null" ? "" : this.form["idNumber"];
            this.form["practiceEx"] =
              this.form["practiceEx"] === "null" ? "" : this.form["practiceEx"];
            this.isLoading = true;
            this.$store
              .dispatch("Auth/update", this.form)
              .then((res) => {
                Swal.fire({
                  title: "Save success!",
                  text: "ระบบได้ทำการแก้ไขข้อมูลเรียบร้อยแล้ว",
                  icon: "success",
                  confirmButtonColor: "#3E7662",
                });
                this.getUserProfile();
                this.isLoading = false;
                this.onEdit('back')
              })
              .catch((e) => {
                // alert(e.response);
                this.isLoading = false;
                if (e.response.data.errors) {
                  for (var k in e.response.data.errors) {
                    Swal.fire({
                      title: "เกิดข้อผิดพลาด",
                      text: e.response.data.errors[k][0],
                      icon: "warning",
                      confirmButtonColor: "#3E7662",
                    }).then(() => {
                      this.$vuetify.goTo(`#${k}`);
                    });
                  }
                }
              });
          } else {
            this.isLoading = false;
          }
        })
        .catch((e) => {
          this.isLoading = false;
        });
    },
    sendCorresFile() {
      Swal.fire({
        title: "คุณต้องการส่งเอกสารหรือไม่?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ใช่ ตกลง",
        confirmButtonColor: "#3E7662",
        cancelButtonText: "ยกเลิก",
        cancelButtonColor: "#CC3D45",
        // reverseButtons: true
      })
        .then((result) => {
          if (result.value) {
            this.isLoading = true;
            this.$store
              .dispatch("FormRF/sendCorres", this.corresForm)
              .then((res) => {
                Swal.fire({
                  title: "Send success!",
                  text: "ระบบได้ทำการส่งเอกสาร Correspondence เรียบร้อยแล้ว",
                  icon: "success",
                  confirmButtonColor: "#3E7662",
                });
                location.reload();
                this.isLoading = false;
              })
              .catch((e) => {
                // alert(e.response);
                this.isLoading = false;
                if (e.response.data.errors) {
                  for (var k in e.response.data.errors) {
                    Swal.fire({
                      title: "เกิดข้อผิดพลาด",
                      text: e.response.data.errors[k][0],
                      icon: "warning",
                      confirmButtonColor: "#3E7662",
                    }).then(() => {
                      this.$vuetify.goTo(`#${k}`);
                    });
                  }
                }
              });
          } else {
            this.isLoading = false;
          }
        })
        .catch((e) => {
          this.isLoading = false;
        });
    },
    // CHANGE PASSWORD
    changePasswordNew() {
      if (
        this.changePassword.password &&
        this.changePassword.password_confirmation
      ) {
        Swal.fire({
          title: "ต้องการเปลี่ยนรหัสผ่านหรือไม่?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, change it!",
          confirmButtonColor: "#3E7662",
          cancelButtonText: "No, cancel!",
          cancelButtonColor: "#d33",
        }).then((result) => {
          this.isLoading = true;
          if (result.value) {
            this.changePassword["id"] = this.user.id;
            this.$store
              .dispatch("Auth/changePassword", this.changePassword)
              .then((res) => {
                Swal.fire(
                  "Change success!",
                  "เปลี่ยนรหัสผ่านเรียบร้อยแล้ว",
                  "success"
                );
                this.isLoading = false;
              })
              .catch((err) => {
                Swal.fire("ERROR!", "รหัสผ่านไม่ตรงกัน", "warning");
                this.isLoading = false;
              });
          }
        });
      } else {
        Swal.fire("ERROR!", "กรุณากรอกข้อมูลให้ครบ", "warning");
      }
    },
    //Get Province
    getProvinces() {
      this.districts = [];
      this.subDistricts = [];
      // this.isLoading = true;
      this.$store.dispatch("Common/getProvince").then((res) => {
        res.forEach((data) => {
          this.provinces.push({
            province_code: data.code,
            province_name: data.name,
            province_id: data.province_id,
          });
        });
        // this.isLoading = false;
      });
    },
    //Get District
    getDistricts(id) {
      if (id) {
        // this.isLoading = true;
        this.districts = [];
        this.subDistricts = [];
        this.$store.dispatch("Common/getDistricts", id).then((data) => {
          data.forEach((dataDistrict) => {
            this.districts.push({
              district_id: dataDistrict.district_id,
              province_id: dataDistrict.province_id,
              district_name: dataDistrict.name,
            });
          });
          // this.isLoading = false;
        });
      } else {
        // this.isLoading = false;
        this.districts = [];
        this.subDistricts = [];
        this.form.district = "";
        this.form.subDistrict = "";
      }
    },
    //Get Sub-District
    getSubDistricts(id) {
      if (id) {
        this.subDistricts = [];
        this.isLoading = true;
        this.$store.dispatch("Common/getSubDistricts", id).then((res) => {
          res.forEach((dataDistrict) => {
            this.subDistricts.push({
              subDistrict_id: dataDistrict.subdistrict_id,
              province_id: dataDistrict.province_id,
              subDistrict_name: dataDistrict.name,
            });
          });
          this.isLoading = false;
        });
      } else {
        this.form.subDistrict = "";
        this.subDistricts = [];
      }
    },
    // Get role
    getRole() {
      // this.isLoading = true;
      this.$store.dispatch("Common/getRole").then((res) => {
        res.forEach((data) => {
          this.role.push({
            ...data,
            role_id: data.id,
            role_name: data.role,
          });
        });
        // this.isLoading = false;
      });
    },
    // Get ParticipantList
    getParticipantList() {
      // this.isLoading = true;
      this.$store.dispatch("Common/getParticipantList").then((res) => {
        let i = 1;
        res.forEach((data) => {
          this.ParticipantList.push({
            number: i,
            name: data.titleName + data.researcherName,
            research: data.researchName,
            role: data.role,
            status: data.status,
          });
          i++;
        });
        // this.isLoading = false;
      });
    },
    // Get Name Title
    async getTitle() {
      const res = await this.$store.dispatch("Common/getTitleName");
      res.forEach((data) => {
        this.titleName.push({
          title_name_th: data.thTitle,
          title_id: data.id,
        });
      });
    },
    async getRank() {
      const res = await this.$store.dispatch("Common/getRank");
      this.rankName = res;
    },
    // Get Research
    getResearch(page) {
      this.isLoading = true;
      this.$store
        .dispatch("Profile/getResearch", page)
        .then((res) => {
          this.isLoading = false;
          if (res.paginate.data[0] !== undefined) {
            this.considerType = res.paginate.data[0].considerType;
            this.userInfo = res.paginate.data[0].user;
            this.budgetResource = res.paginate.data[0].budgetResource;
            this.dataResearch = res.paginate.data[0];
            this.nameResearch = res.paginate.data[0].thResearchName;
            this.researchForm.id = res.paginate.data[0].id;
            this.researchForm.statusResearch = res.paginate.data[0].status;
            this.researchForm.editStatus = res.paginate.data[0].editStatus;
            this.researchForm.researchNumber =
              res.paginate.data[0].researchCode;
            this.researchForm.approvalDate = res.paginate.data[0].approvalDate;
            this.researchForm.approvalExpireDate = res.paginate.data[0]
              .approvalExpireDate;
            this.researchForm.remainDate = res.paginate.data[0].remainDate;
            this.researchForm.firstApprovalDate =
              res.paginate.data[0].firstApprovalDate;
            this.researchForm.refID = res.paginate.data[0].refID;
            this.paginateResearch.current_page = res.paginate.current_page;
            this.paginateResearch.from = res.paginate.from;
            this.paginateResearch.last_page = res.paginate.last_page;
            this.paginateResearch.per_page = res.paginate.per_page;
            this.paginateResearch.total = res.paginate.total;
            this.paginateResearch.to = res.paginate.to;
            this.checkDisable();
          } else {
            this.disableCheck = true;
          }
          if (res.forSelect[0] !== undefined) {
            res.forSelect.forEach((item, index) => {
              this.onGoingResearchList.push({
                name: item.thResearchName,
                page: index + 1,
              });
            });
          }
          this.page = page;
        })
        // .then((res) => {
        //   if (!res.data) {
        //     this.$store
        //       .dispatch("Profile/getExpireDate", res.data[0].id)
        //       .then((res) => {
        //         // this.researchForm.numberCertificateEnd = res;
        //         this.isLoading = false;
        //       });
        //   }
        // })
        .then(() => {
          this.getHistoryResearch();
        })
        .catch((e) => {
          this.isLoading = false;
        });
    },
    getAllResearchPaginate() {
      this.itemResearchAll = [];
      // this.isLoading = true;
      this.$store
        .dispatch("Profile/getAllResearchPaginate")
        .then((res) => {
          this.itemsPerPage = res.length;
          let i = 1;
          res.forEach((data) => {
            this.itemResearchAll.push({
              number: i,
              researchName: data.thResearchName,
              status: data.status,
              version: data.version,
            });
            if (
              data.status === "รอดำเนินการ" ||
              data.status === "อยู่ระหว่างการพิจารณา" ||
              data.status === "ปรับปรุงแก้ไขเพื่อพิจารณาใหม่" ||
              data.status === "ปรับปรุงแก้ไขเพื่อรับรอง" ||
              data.status === "ผ่านการพิจารณา" ||
              data.status === "อนุมัติ"
            )
              this.activeResearchCount++;
            i++;
          });
          // this.isLoading = false;
        })
        .catch((e) => {
          this.isLoading = false;
        });
    },
    // UPLOAD FILE CERTIFICATE
    uploadCertificate(e) {
      this.formCertificate.certificate_gcp_training = e;
    },
    uploadFileCertificate() {
      document.getElementById("certificate_gcp_training").click();
    },
    saveCertificate() {
      Swal.fire({
        title: "ต้องการเปลี่ยน Certificate หรือไม่?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ใช่",
        confirmButtonColor: "#3E7662",
        cancelButtonText: "ยกเลิก",
        cancelButtonColor: "#d33",
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          this.$store
            .dispatch("Common/uploadCertificate", this.formCertificate)
            .then((res) => {
              Swal.fire({
                title: "Upload Success !",
                icon: "success",
              });
              this.isLoading = false;
              this.getUserProfile();
              this.getCertificate();
            })
            .catch((e) => {
              Swal.fire({
                title: "Upload Failed !",
                icon: "warning",
              });
              this.isLoading = false;
            });
        }
      });
    },
    getCertificate() {
      this.$store.dispatch("Common/getCertificate").then((res) => {
        this.certificate_path = res.certificate_path;
        this.remaining_date = res.remaining_date;
        // this.remaining_date = 0;
      });
    },
    // Check create research
    createResearch() {
      if (this.user.remark === "B") {
        Swal.fire({
          title: "Blacklist",
          text: "ท่านถูกระงับการสร้างงานวิจัย กรุณาติดต่อเจ้าหน้าที่",
          icon: "warning",
          confirmButtonColor: "#3E7662",
        });
      } else if (this.user.remark === "T") {
        Swal.fire({
          title: "Training request",
          text: "กรุณาอบรม GCP ซ้ำอีกรอบ ก่อนดำเนินการใดๆ ในระบบ",
          icon: "warning",
          confirmButtonColor: "#3E7662",
        });
      } else if (this.user.remark === "C") {
        if (this.activeResearchCount > 2)
          Swal.fire({
            title: "Careful Monitoring",
            text:
              "ท่านถูกจำกัดสิทธิ์ ไม่สามารถมีงานวิจัยที่อยู่ระหว่างดำเนินการได้เกิน 2 เรื่อง",
            icon: "warning",
            confirmButtonColor: "#3E7662",
          });
        else this.$router.push("/manual-research");
      } else if (this.user.certificateStatus === 0) {
        Swal.fire({
          title: "รอการตรวจสอบ",
          text: "กรุณาติดต่อเจ้าหน้าที่",
          icon: "warning",
          confirmButtonColor: "#3E7662",
        });
      } else if (
        this.user.certificateStatus === 1 &&
        this.remainingDate !== 0
      ) {
        this.$router.push("/manual-research");
      } else if (
        this.user.certificateStatus === 1 &&
        this.remainingDate === 0
      ) {
        Swal.fire({
          title: "ใบ Certificate หมดอายุ!",
          text: "ระบบไม่สามารถสร้างโครงร่างงานวิจัยได้",
          icon: "warning",
          confirmButtonColor: "#3E7662",
        });
      } else {
        Swal.fire({
          title: " ท่านยังไม่มีใบ Certificate!",
          text: "ระบบไม่สามารถสร้างโครงร่างงานวิจัยได้",
          icon: "warning",
          confirmButtonColor: "#3E7662",
        }).then(() => {
          this.Expand_STATE();
          setTimeout(() => {
            document.getElementById("uploadBtn").focus();
          }, 400);
        });
      }
    },
    // Get Edit Research
    editResearch() {
      if (this.user.certificateStatus === 1 && this.remainingDate === 0) {
        Swal.fire({
          title: "ใบ Certificate หมดอายุ!",
          text: "ไม่สามารถแก้ไขโครงร่างงานวิจัยได้",
          icon: "warning",
          confirmButtonColor: "#3E7662",
        }).then(() => {
          this.Expand_STATE();
          setTimeout(() => {
            document.getElementById("uploadBtn").focus();
          }, 400);
        });
        return false
      }
      this.$store
        .dispatch("FormResearch/setResearchID", this.researchForm.id)
        .then((res) => {
          this.$router.push({
            name: "FormResearch1",
            query: { id: this.researchForm.id },
          });
        });
    },
    // cancel Reserch
    cancelReserch() {
      this.cancelModel = true;
    },
    setCancelupload(form) {
      let data = {
        id: this.researchForm.id,
        pdf: form.cancelForm.cancelResearchFile
      }
      Swal.fire({
        title: "คุณต้องการยกเลิกโครงการหรือไม่?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ใช่ ตกลง",
        confirmButtonColor: "#3E7662",
        cancelButtonText: "ยกเลิก",
        cancelButtonColor: "#CC3D45",
      }).then((res) => {
        if (res.value) {
          this.$store.dispatch("Profile/cancelResearch", data).then((res) => {
            Swal.fire({
              title: res.text,
              icon: res.status,
            });
          });
          this.cancelModel = false;
        }
      });
    },
    uploadCannelFile(fileName, e) {
      var formData = new FormData();
      formData.append(fileName, e);
      axios.post(domain + "/api/upload-file-pre", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        if (response.data.fileName !== undefined) {
          this.form.cancelForm[fileName] = response.data.fileName;
        }
        else {
          this.form.cancelForm[fileName] = '';
        }
      }).catch(function (error) { });
    },
    // Check disabled
    checkDisable() {
      if (this.user.certificateStatus === 1) {
        if (this.researchForm.statusResearch === "อนุมัติ") {
          return (this.disableCheck = true);
        } else {
          if (!this.dataResearch.initialNumber) {
            return (this.disableCheck = false);
          } else if (
            this.dataResearch.initialNumber &&
            this.researchForm.statusResearch === "อยู่ระหว่างการพิจารณา"
          ) {
            return (this.disableCheck = true);
          } else if (
            this.dataResearch.initialNumber &&
            (this.researchForm.statusResearch ===
              "ปรับปรุงแก้ไขเพื่อพิจารณาใหม่" ||
              this.researchForm.statusResearch === "ปรับปรุงแก้ไขเพื่อรับรอง")
          ) {
            return (this.disableCheck = false);
          } else {
            return (this.disableCheck = true);
          }
        }
      } else {
        return (this.disableCheck = true);
      }
    },
    getHistoryResearch() {
      this.isLoading = true;
      let data = {
        id: this.researchForm.refID,
      };
      this.$store.dispatch("Common/getHistoryResearch", data).then((res) => {
        this.isLoading = false;
        this.checkRF12 = this.checkRF("RF12", res.researchAmendment);
        this.checkRF04_1 = this.checkRF("RF04_1", res.adverseDrugEvent);
        this.checkRF04_2 = this.checkRF("RF04_2", res.adverseEvent);
        this.checkRF05 = this.checkRF("RF05", res.deviationReport);
        this.checkRF03 = this.checkRF("RF03", res.progressReport);
        this.checkRF06 = this.checkRF("RF06", res.researchSummarize);
        this.checkRF07 = this.checkRF("RF07", res.researchTermination);
        this.checkCorres = this.checkRF("Corres", res.researchTermination);
      });
    },
    checkRF(name, val) {
      if (!val) {
        return true;
      }
      if (name === "RF12") {
        if (val.length > 0) {
          // if (val[0].status === 'อยู่ระหว่างการพิจารณา' || val[0].status === 'ผ่านการพิจารณา') {
          //     return false;
          // } else {
          return true;
          // }
        } else {
          return true;
        }
      }
      if (name === "RF04_1") {
        if (val.length > 0) {
          // if (val[0].status === 'อยู่ระหว่างการพิจารณา' || val[0].status === 'ผ่านการพิจารณา') {
          //     return false;
          // } else {
          return true;
          // }
        } else {
          return true;
        }
      }
      if (name === "RF04_2") {
        if (val.length > 0) {
          // if (val[0].status === 'อยู่ระหว่างการพิจารณา' || val[0].status === 'ผ่านการพิจารณา') {
          //     return false;
          // } else {
          return true;
          // }
        } else {
          return true;
        }
      }
      if (name === "RF05") {
        if (val.length > 0) {
          // if (val[0].status === 'อยู่ระหว่างการพิจารณา' || val[0].status === 'ผ่านการพิจารณา') {
          //     return false;
          // } else {
          return true;
          // }
        } else {
          return true;
        }
      }
      if (name === "RF03") {
        if (val.length > 0) {
          if (
            val[0].status === "อยู่ระหว่างการพิจารณา" ||
            val[0].status === "ผ่านการพิจารณา"
          ) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      }
      if (name === "RF06") {
        if (val.length > 0) {
          if (
            val[0].status === "อยู่ระหว่างการพิจารณา" ||
            val[0].status === "ผ่านการพิจารณา"
          ) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      }
      if (name === "RF07") {
        if (val.length > 0) {
          if (
            val[0].status === "อยู่ระหว่างการพิจารณา" ||
            val[0].status === "ผ่านการพิจารณา"
          ) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      }
      if (name === "Corres") {
        if (val.length > 0) {
          return true;
        } else {
          return true;
        }
      }
    },
    // Get AffiliationList
    getAffiliationList() {
      this.affiliationList = [];
      // this.isLoading = true;
      this.$store.dispatch("Common/getAffiliationList").then((res) => {

        res.forEach((data) => {
          this.affiliationList.push({
            affiliation_id: data.id,
            affiliation_name: data.affiliation,
          });
        });
        // this.isLoading = false;
      });
    },
    onClickRF(name_rf) {
      console.log(name_rf);
      switch (name_rf) {
        case "FormRF12":
          var FormRF12 = this.$router.resolve({
            name: "FormRF12",
            query: {
              researchID: this.researchForm.id,
              status: this.researchForm.statusResearch,
              researchCode: this.researchForm.researchNumber,
              thName: this.user.thName,
              mobilePhone: this.user.mobilePhone,
              affiliation: this.user.affiliation.affiliation === 'อื่นๆ' ? this.user.otherAffiliation : this.user.affiliation.affiliation,
              nameResearch: this.nameResearch,
            },
          });
          window.open(FormRF12.href, "_self");
          break;
        case "FormSusar":
          var FormSusar = this.$router.resolve({
            name: "FormSusar",
            query: {
              researchID: this.researchForm.id,
              status: this.researchForm.statusResearch,
              researchCode: this.researchForm.researchNumber,
              thName: this.user.thName,
              mobilePhone: this.user.mobilePhone,
              affiliation: this.user.affiliation.affiliation === 'อื่นๆ' ? this.user.otherAffiliation : this.user.affiliation.affiliation,
              nameResearch: this.nameResearch,
            },
          });
          window.open(FormSusar.href, "_self");
          break;
        case "FormRF04_1":
          var FormRF04_1 = this.$router.resolve({
            name: "FormRF04_1",
            query: {
              researchID: this.researchForm.id,
              status: this.researchForm.statusResearch,
              researchCode: this.researchForm.researchNumber,
              thName: this.user.thName,
              mobilePhone: this.user.mobilePhone,
              affiliation: this.user.affiliation.affiliation === 'อื่นๆ' ? this.user.otherAffiliation : this.user.affiliation.affiliation,
              nameResearch: this.nameResearch,
            },
          });
          window.open(FormRF04_1.href, "_self");
          break;
        case "FormRF04_2":
          var FormRF04_2 = this.$router.resolve({
            name: "FormRF04_2",
            query: {
              researchID: this.researchForm.id,
              status: this.researchForm.statusResearch,
              researchCode: this.researchForm.researchNumber,
              thName: this.user.thName,
              mobilePhone: this.user.mobilePhone,
              affiliation: this.user.affiliation.affiliation === 'อื่นๆ' ? this.user.otherAffiliation : this.user.affiliation.affiliation,
              nameResearch: this.nameResearch,
            },
          });
          window.open(FormRF04_2.href, "_self");
          break;
        case "FormRF05":
          var FormRF05 = this.$router.resolve({
            name: "FormRF05",
            query: {
              researchID: this.researchForm.id,
              status: this.researchForm.statusResearch,
              researchCode: this.researchForm.researchNumber,
              thName: this.user.thName,
              nameResearch: this.nameResearch,
            },
          });
          window.open(FormRF05.href, "_self");
          break;
        case "FormRF03":
          var FormRF03 = this.$router.resolve({
            name: "FormRF03",
            query: {
              researchID: this.researchForm.id,
              status: this.researchForm.statusResearch,
              researchCode: this.researchForm.researchNumber,
              firstApprovalDate: this.researchForm.firstApprovalDate,
              approvalDate: this.researchForm.approvalDate,
              approvalExpireDate: this.researchForm.approvalExpireDate,
              remainDate: this.researchForm.remainDate,
              thName: this.user.thName,
              nameResearch: this.nameResearch,
            },
          });
          window.open(FormRF03.href, "_self");
          // } else {
          //   Swal.fire({
          //     title: "โครงการวิจัยของท่านยังไม่ถึงกำหนดส่งรายงานความก้าวหน้า",
          //     text:
          //       "*กรุณาส่งรายงานความก้าวหน้าภายใน 30 วันก่อนวันหมดอายุโครงการวิจัย",
          //     icon: "warning",
          //     confirmButtonColor: "#3E7662",
          //   });
          // }
          break;
        case "FormRF06":
          var FormRF06 = this.$router.resolve({
            name: "FormRF06",
            query: {
              researchID: this.researchForm.id,
              status: this.researchForm.statusResearch,
              researchCode: this.researchForm.researchNumber,
              thName: this.user.thName,
              budgetResource: this.budgetResource.budgetResource,
              nameResearch: this.nameResearch,
              mobilePhone: this.user.mobilePhone,
              email: this.user.email,
              startDate: this.researchForm.startDate,
              endDate: this.researchForm.endDate,
              firstApprovalDate: this.researchForm.firstApprovalDate,
              approvalDate: this.researchForm.approvalDate,
            },
          });
          window.open(FormRF06.href, "_self");
          break;
        case "FormRF07":
          var FormRF07 = this.$router.resolve({
            name: "FormRF07",
            query: {
              researchID: this.researchForm.id,
              status: this.researchForm.statusResearch,
              researchCode: this.researchForm.researchNumber,
              thName: this.user.thName,
              budgetResource: this.budgetResource.budgetResource,
              nameResearch: this.nameResearch,
              mobilePhone: this.user.mobilePhone,
              email: this.user.email,
              startDate: this.researchForm.startDate,
              endDate: this.researchForm.endDate,
              firstApprovalDate: this.researchForm.firstApprovalDate,
              approvalDate: this.researchForm.approvalDate,
            },
          });
          window.open(FormRF07.href, "_self");
          break;
        case "Correspondence":
          this.corresDialog = true;
          this.corresForm.researchID = this.researchForm.id;
          break;
      }
    },
    addDynamicFile(name) {
      this.corresForm[name].push({ title: '', file: null, filePath: '' });
    },
    removeDynamicFile(name, index) {
      this.corresForm[name].splice(index, 1);
      this.$forceUpdate();
    },
    uploadFileBtn(name) {
      document.getElementById(name).click();
    },
    updateDynamicFileData(name, type, index, value) {
      if (type === 'file') {
        this.$store.dispatch('Common/uploadPdf', { form: 'corres-file', file: value }).then(res => {
          this.corresForm[name][index].file = res.fileName;
          this.corresForm[name][index].filePath = res.filePath;
        }).catch(error => {
        });
      } else {
        this.corresForm[name][index][type] = value;
      }
    },

  },
};
</script>

<style lang="scss" scoped>
.profile-style {
  color: #3e7662;
  padding-left: 20px;
}

.research-link {
  cursor: pointer;
}

.research-link:hover {
  font-weight: bold;
  color: #3e7662;
  border-bottom: solid 1px #3e7662;
}
</style>
