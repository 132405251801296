import Service from '../Service'
import AuthService from '../AuthService'

const Exam = {
    chapter() {
        return Service.get("/api/get-chapter").then(res => res)
    },
    question(id) {
        return Service.get(`/api/get-question/${id}`).then(res => res)
    },
    choice(id){
        return Service.get(`/api/get-choice/${id}`).then(res=>res)
    },
    answer(data){
        return AuthService.post(`/api/answer`, data).then(res=>res)
    },
    pointcalculate(){
        return AuthService.get(`/api/calculatePointByChapter`).then(res=>res)
    },
    certificate(data){
        return AuthService.post(`/api/certificate`,data).then(res=>res)
    },
    getToday(){
        return AuthService.get("/api/get-today").then(res => res)
    },
    getChapterName(chapterName){
        return AuthService.get(`/api/get-chapter-name/${chapterName}`).then(res=>res)
    },
    getCertificate(){
        return AuthService.get("/api/get-certificate").then(res=>res)
    }
}

export default Exam;
