<template>
  <v-content class="pa-12" style="background-color: #FAF5F2;">
    <v-layout>
      <v-flex>
        <v-layout style="background-color: #3E7662;">
          <v-flex my-8 ml-8 style="text-align: left;font-weight: bold;font-size: 28px;color: white">
            <div>รายงานการยุติโครงการวิจัยก่อนกำหนด</div>
            <div style="font-size: 18px;">
              Premature Study Termination Report
            </div>
          </v-flex>
          <v-flex ml-8 pt-8 pr-8 style="text-align: right;font-weight: bold;font-size: 13px;color: white">
            <span>RF 07_2563</span>
          </v-flex>
        </v-layout>

        <!-- blog 1 -->
        <v-card flat class="px-12">
          <v-layout py-12>
            <v-flex>
              <v-layout justify-center>
                <v-flex xs2 mr-4 pt-2 style="text-align: right;font-weight: bold;">
                  <span>ชื่อโครงการวิจัย</span>
                </v-flex>
                <v-flex xs6>
                  <textfield-form :model="form.researchName" style="font-weight: bold"
                    @update-data="updateData('researchName', $event)"></textfield-form>
                </v-flex>
              </v-layout>
              <v-layout justify-center>
                <v-flex xs2 mr-4 pt-2 style="text-align: right;font-weight: bold;">
                  <span>รหัสโครงการวิจัย</span>
                </v-flex>
                <v-flex xs6>
                  <textfield-form :model="form.researchCode" style="font-weight: bold"
                    @update-data="updateData('researchCode', $event)"></textfield-form>
                </v-flex>
              </v-layout>
              <v-layout justify-center>
                <v-flex xs2 mr-4 pt-2 style="text-align: right;font-weight: bold;">
                  <span>ผู้สนับสนุนทุนวิจัย</span>
                </v-flex>
                <v-flex xs6>
                  <textfield-form :model="form.researchInvestor" style="font-weight: bold"
                    @update-data="updateData('researchInvestor', $event)"></textfield-form>
                </v-flex>
              </v-layout>
              <v-layout justify-center>
                <v-flex xs2 mr-4 pt-2 style="text-align: right;font-weight: bold;">
                  <span>ผู้วิจัยหลัก</span>
                </v-flex>
                <v-flex xs6>
                  <textfield-form :model="form.mainResearcher" style="font-weight: bold"
                    @update-data="updateData('mainResearcher', $event)"></textfield-form>
                </v-flex>
              </v-layout>
              <v-layout justify-center>
                <v-flex xs2 mr-4 pt-2 style="text-align: right;font-weight: bold;">
                  <span>โทรศัพท์</span>
                </v-flex>
                <v-flex xs6>
                  <textfield-form :model="form.phoneNumber" style="font-weight: bold"
                    @update-data="updateData('phoneNumber', $event)"></textfield-form>
                </v-flex>
              </v-layout>
              <v-layout justify-center>
                <v-flex xs2 mr-4 pt-2 style="text-align: right;font-weight: bold;">
                  <span>อีเมล์</span>
                </v-flex>
                <v-flex xs6>
                  <textfield-form :model="form.email" style="font-weight: bold"
                    @update-data="updateData('email', $event)"></textfield-form>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>

        <!-- blog 2 -->
        <v-layout pt-12 pb-8 my-12 style="background-color: white;padding-left: 100px;padding-right: 100px;">
          <v-flex>
            <!-- row 1 -->
            <v-layout>
              <v-flex xs3 pt-2 mr-3 style="text-align: right;font-weight: bold;">
                <span>วันที่รับรองโครงร่างการวิจัยครั้งแรก</span>
              </v-flex>
              <v-flex xs3>
                <DateInputForm :model="dateChange.firstDate" style="font-weight: bold"
                  @update-data="updateDate('firstDate', $event)"></DateInputForm>
              </v-flex>
              <v-flex xs3 pt-2 mr-3 style="text-align: right;font-weight: bold;">
                <span>วันที่รับรองโครงร่างการวิจัยครั้งล่าสุด</span>
              </v-flex>
              <v-flex xs3>
                <DateInputForm :model="dateChange.lastDate" style="font-weight: bold"
                  @update-data="updateDate('lastDate', $event)"></DateInputForm>
              </v-flex>
            </v-layout>

            <!-- row 2 -->
            <v-layout>
              <v-flex xs3 pt-2 mr-3 style="text-align: right;font-weight: bold;">
                <span>วันที่เริ่มดำเนินการวิจัย</span>
              </v-flex>
              <v-flex xs2>
                <DateInputForm :model="dateChange.startDate" style="font-weight: bold"
                  @update-data="updateDate('startDate', $event)"></DateInputForm>
              </v-flex>
              <v-flex xs3>
                <v-checkbox v-model="form.hasStartDate" v-bind:true-value="true" v-bind:false-value="false"
                  color="#3E7662" style="margin-left: 10px; margin-top: 4px;"
                  label="ยังไม่ได้เริ่มเก็บข้อมูล"></v-checkbox>
              </v-flex>
              <v-flex xs3 pt-2 mr-3 style="text-align: right;font-weight: bold;">
                <span>วันที่สิ้นสุดการวิจัย</span>
              </v-flex>
              <v-flex xs3>
                <DateInputForm :model="dateChange.endDate" style="font-weight: bold"
                  @update-data="updateDate('endDate', $event)"></DateInputForm>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <!-- blog 3 -->
        <!-- blog 3 -->
        <v-layout pt-12 pb-8 mb-12 style="background-color: white;padding-left: 100px;padding-right: 100px;">
          <v-flex>
            <!-- Group 1 -->
            <v-layout pt-4>
              <v-flex d-flex justify-start>
                <span style="font-weight: bold;font-size: 18px;">Summary of Protocol Participants</span>
              </v-flex>
            </v-layout>
            <v-layout py-6 pl-12>
              <v-flex>
                <v-layout pt-1 mb-4>
                  <v-flex d-flex justify-start>
                    <v-layout>
                      <v-flex class="d-flex align-center xs6">
                        <span style="color: #3E7662;">Accrual ceiling set by IEC/IRB</span>
                      </v-flex>
                      <v-flex class="xs3">
                        <textfield-form type="number" hide-detail id="email" :model="form.accrualCeiling"
                          style="font-weight: bold" @update-data="updateData('accrualCeiling', $event)"></textfield-form>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v-layout pt-1 mb-4>
                  <v-flex d-flex justify-start>
                    <v-layout>
                      <v-flex class="d-flex align-center xs6">
                        <span style="color: #3E7662;">Total accrued participants</span>
                      </v-flex>
                      <v-flex class="xs3">
                        <textfield-form type="number" hide-detail id="email" :model="form.totalParticipants"
                          style="font-weight: bold"
                          @update-data="updateData('totalParticipants', $event)"></textfield-form>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v-layout pt-1 mb-4>
                  <v-flex d-flex justify-start>
                    <v-layout>
                      <v-flex class="d-flex align-center xs6">
                        <span style="color: #3E7662;">Total participants fail screening</span>
                      </v-flex>
                      <v-flex class="xs3">
                        <textfield-form type="number" hide-detail id="email" :model="form.totalScreening"
                          style="font-weight: bold" @update-data="updateData('totalScreening', $event)"></textfield-form>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v-layout pt-1 mb-4>
                  <v-flex d-flex justify-start>
                    <v-layout>
                      <v-flex class="d-flex align-center xs6">
                        <span style="color: #3E7662;">Total participants withdrawn</span>
                      </v-flex>
                      <v-flex class="xs3">
                        <textfield-form type="number" hide-detail id="email" :model="form.totalWithdrawn"
                          style="font-weight: bold" @update-data="updateData('totalWithdrawn', $event)"></textfield-form>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v-layout pt-1 mb-4>
                  <v-flex d-flex justify-start>
                    <v-layout>
                      <v-flex class="d-flex align-center xs6">
                        <span style="color: #3E7662;">Total participants dead</span>
                      </v-flex>
                      <v-flex class="xs3">
                        <textfield-form type="number" hide-detail id="email" :model="form.totalDead"
                          style="font-weight: bold" @update-data="updateData('totalDead', $event)"></textfield-form>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v-layout pt-1 mb-4>
                  <v-flex d-flex justify-start>
                    <v-layout>
                      <v-flex class="d-flex align-center xs6">
                        <span style="color: #3E7662;">Total participants complete the research</span>
                      </v-flex>
                      <v-flex class="xs3">
                        <textfield-form type="number" hide-detail id="email" :model="form.totalCompleteResearch"
                          style="font-weight: bold" @update-data="
                            updateData('totalCompleteResearch', $event)
                            "></textfield-form>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <!-- Group 2 -->
            <v-layout pt-8>
              <v-flex d-flex justify-start>
                <span style="font-weight: bold;font-size: 18px;">Summary of Adverse Events</span>
              </v-flex>
            </v-layout>
            <v-layout pt-6 pb-12 pl-12>
              <v-flex>
                <v-layout pt-1 mb-4>
                  <v-flex d-flex justify-start>
                    <v-layout>
                      <v-flex class="d-flex align-center xs6">
                        <span style="color: #3E7662;">Total participants experience adverse events</span>
                      </v-flex>
                      <v-flex class="xs3">
                        <textfield-form type="number" hide-detail id="email" :model="form.totalExpAdversEvents"
                          style="font-weight: bold" @update-data="
                            updateData('totalExpAdversEvents', $event)
                            "></textfield-form>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v-layout pt-1 mb-4>
                  <v-flex d-flex justify-start>
                    <v-layout>
                      <v-flex class="d-flex align-center xs6">
                        <span style="color: #3E7662;">Total participants experience SUSARs</span>
                      </v-flex>
                      <v-flex class="xs3">
                        <textfield-form type="number" hide-detail id="email" :model="form.totalExpSUSARs"
                          style="font-weight: bold" @update-data="updateData('totalExpSUSARs', $event)"></textfield-form>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v-layout pt-1>
                  <v-flex d-flex justify-start>
                    <v-layout>
                      <v-flex class="d-flex align-center xs6">
                        <span style="color: #3E7662;">Total global participants experience SUSARs, if
                          applicable.</span>
                      </v-flex>
                      <v-flex class="xs3">
                        <textfield-form type="number" hide-detail id="email" :model="form.totalGlobal"
                          style="font-weight: bold" @update-data="updateData('totalGlobal', $event)"></textfield-form>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <!-- Group 3 -->
            <v-layout py-6>
              <v-flex d-flex justify-start>
                <span style="font-weight: bold;font-size: 18px;">
                  Has there been any protocol deviation/violation or compliance
                  issues occurred during the study?
                </span>
              </v-flex>
            </v-layout>
            <v-layout pl-12>
              <v-flex style="color: #3E7662">
                <v-radio-group v-model="form.hasIssue" row id="hasIssue">
                  <v-radio color="#3E7662" style="padding-right: 100px" label="No" value="0"></v-radio>
                  <v-radio color="#3E7662" label="YES (please attach a report)" value="1"></v-radio>
                </v-radio-group>
              </v-flex>
            </v-layout>
            <v-layout v-if="form.hasIssue === '1'">
              <v-flex shrink pt-2 pr-4 style="text-align: left;">
                <span style="font-weight: bold;font-size: 16px;">ไฟล์</span>
              </v-flex>
              <v-flex lg4 md7 sm6>
                <v-file-input accept=".pdf" prepend-icon="" id="anyProtocolFile" ref="anyProtocolFile"
                  :model="form.anyProtocolFile" outlined dense background-color="#FBF6F3" color="#3E7662"
                  item-color="#3E7662" @change="onUpload('anyProtocolFile', $event)" hide-details></v-file-input>
              </v-flex>
              <v-flex shrink pl-4 style="text-align: left">
                <v-btn color="#3E7662" dark @click="onClickBtn('anyProtocolFile')">
                  <v-icon class="pr-3">cloud_download</v-icon>
                  <span>Upload</span>
                </v-btn>
              </v-flex>
              <v-flex xs3 ml-4><span style="color: red">*ชื่อไฟล์ต้องเป็นภาษาอังกฤษเท่านั้น (a-z,0-9) ห้ามมีตัวอักษรอื่น
                </span></v-flex>
            </v-layout>

            <div v-if="anyProtocolFile" class="mt-1 text-start" style="font-size: 12px;color: #CC3D45;padding-left: 40px">
              ไฟล์ที่อัพโหลด:
              <a :href="anyProtocolFilePath" target="_blank" class="link-hover">
                <span>{{ anyProtocolFile }}</span>
              </a>
            </div>
            <!-- Group 4 -->
            <v-layout py-6>
              <v-flex d-flex justify-start>
                <span style="font-weight: bold;font-size: 18px;">
                  Has there been any complaint or warning letter against the
                  research or investigator during the study?
                </span>
              </v-flex>
            </v-layout>
            <v-layout pl-12>
              <v-flex style="color: #3E7662">
                <v-radio-group v-model="form.hasWarning" row id="hasWarning">
                  <v-radio color="#3E7662" style="padding-right: 100px" label="No" value="0"></v-radio>
                  <v-radio color="#3E7662" label="YES (please attach a report)" value="1"></v-radio>
                </v-radio-group>
              </v-flex>
            </v-layout>
            <v-layout v-if="form.hasWarning === '1'">
              <v-flex shrink pt-2 pr-4 style="text-align: left;">
                <span style="font-weight: bold;font-size: 16px;">ไฟล์</span>
              </v-flex>
              <v-flex lg4 md7 sm6>
                <v-file-input accept=".pdf" prepend-icon="" id="anyComplaintFile" ref="anyComplaintFile"
                  :model="form.anyComplaintFile" outlined dense background-color="#FBF6F3" color="#3E7662"
                  item-color="#3E7662" @change="onUpload('anyComplaintFile', $event)" hide-details></v-file-input>
              </v-flex>
              <v-flex shrink pl-4 style="text-align: left">
                <v-btn color="#3E7662" dark @click="onClickBtn('anyComplaintFile')">
                  <v-icon class="pr-3">cloud_download</v-icon>
                  <span>Upload</span>
                </v-btn>
              </v-flex>
              <v-flex xs3 ml-4><span style="color: red">*ชื่อไฟล์ต้องเป็นภาษาอังกฤษเท่านั้น (a-z,0-9) ห้ามมีตัวอักษรอื่น
                </span></v-flex>
            </v-layout>
            <div v-if="anyComplaintFile" class="mt-1 text-start"
              style="font-size: 12px;color: #CC3D45;padding-left: 40px">
              ไฟล์ที่อัพโหลด:
              <a :href="anyComplaintFilePath" target="_blank" class="link-hover">
                <span>{{ anyComplaintFile }}</span>
              </a>
            </div>
          </v-flex>
        </v-layout>

        <!-- blog 4 -->
        <v-layout pt-12 pb-8 mb-12 style="background-color: white;padding-left: 100px;padding-right: 100px;">
          <v-flex>
            <!-- row 1 -->
            <v-layout mb-4>
              <v-flex d-flex justify-start>
                <span style="font-weight: bold;font-size: 18px;">
                  Reasons for the premature study termination
                </span>
              </v-flex>
            </v-layout>
            <v-layout pt-6 mb-12>
              <v-flex d-flex justify-start>
                <textarea-form :model="form.reason" @update-data="updateData('reason', $event)"></textarea-form>
              </v-flex>
            </v-layout>

            <!-- row 2 -->
            <v-layout mb-4>
              <v-flex d-flex justify-start>
                <span style="font-weight: bold;font-size: 18px;">
                  Are there any follow-up and management for participants after
                  study termination
                </span>
              </v-flex>
            </v-layout>
            <v-layout pl-12>
              <v-flex style="color: #3E7662">
                <v-radio-group v-model="form.hasFollowUp" row>
                  <v-radio @click.native="clear('hasFollowUp')" color="#3E7662" style="padding-right: 100px" label="No"
                    value="0"></v-radio>
                  <v-radio color="#3E7662" label="YES (Please describe)" value="1"></v-radio>
                </v-radio-group>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex d-flex justify-start>
                <textarea-form :background-color="form.hasFollowUp !== '1' ? '#E0E0E0' : '#FBF6F3'
                  " :disabled="form.hasFollowUp !== '1'" :model="form.followUp"
                  @update-data="updateData('followUp', $event)"></textarea-form>
              </v-flex>
            </v-layout>

            <!-- row 3 -->
            <v-layout mb-4>
              <v-flex d-flex justify-start>
                <span style="font-weight: bold;font-size: 18px;">
                  Is there any plan to inform participants
                </span>
              </v-flex>
            </v-layout>
            <v-layout pl-12>
              <v-flex style="color: #3E7662">
                <v-radio-group v-model="form.hasPlan" row>
                  <v-radio @click.native="clear('hasPlan')" color="#3E7662" style="padding-right: 100px" label="No"
                    value="0"></v-radio>
                  <v-radio color="#3E7662" label="YES (Please describe)" value="1"></v-radio>
                </v-radio-group>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex d-flex justify-start>
                <textarea-form :background-color="form.hasPlan !== '1' ? '#E0E0E0' : '#FBF6F3'
                  " :disabled="form.hasPlan !== '1'" :model="form.plan"
                  @update-data="updateData('plan', $event)"></textarea-form>
              </v-flex>
            </v-layout>

            <!-- row 4 -->
            <v-layout mt-12>
              <v-flex xs4 style="text-align: left;">
                <span style="font-weight: bold;font-size: 18px;">
                  Please attach a summary of result
                </span>
              </v-flex>
              <v-flex xs6>
                <v-layout>
                  <v-flex shrink pt-2 pr-4 style="text-align: left;">
                    <span style="font-weight: bold;font-size: 16px;">ไฟล์</span>
                  </v-flex>
                  <v-flex>
                    <v-file-input accept=".pdf" prepend-icon="" id="resultFile" :model="form.resultFile" outlined dense
                      background-color="#FBF6F3" color="#3E7662" item-color="#3E7662"
                      @change="onUpload('resultFile', $event)" hide-details></v-file-input>
                    <div v-if="showResultFile" class="mt-1 text-start"
                      style="font-size: 12px;color: #CC3D45;padding-left: 40px">
                      ไฟล์ที่อัพโหลด:
                      <a :href="pathResult" target="_blank" class="link-hover">
                        <span>{{ showResultFile }}</span>
                      </a>
                    </div>
                  </v-flex>
                  <v-flex shrink pl-4 style="text-align: left">
                    <v-btn color="#3E7662" dark @click="onClickBtn('resultFile')">
                      <v-icon class="pr-3">cloud_download</v-icon>
                      <span>Upload</span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs3 ml-4><span style="color: red">*ชื่อไฟล์ต้องเป็นภาษาอังกฤษเท่านั้น (a-z,0-9) ห้ามมีตัวอักษรอื่น
                </span></v-flex>
            </v-layout>

            <v-layout style="margin-top: 5rem;margin-bottom: 5rem">
              <v-flex>
                <v-layout class="mb-8">
                  <v-flex>
                    <v-layout>
                      <v-flex shrink pt-2 pr-4 style="text-align: left;">
                        <span style="font-weight: bold;font-size: 16px;">หนังสือของหน่วยงาน
                        </span>
                      </v-flex>
                      <v-flex>
                        <v-file-input accept=".pdf" prepend-icon="" ref="agencyBooks" id="agencyBook"
                          :model="form.agencyBook" outlined dense clearable background-color="#FBF6F3" color="#3E7662"
                          item-color="#3E7662" @change="onUpload('agencyBook', $event)" hide-details></v-file-input>
                        <div v-if="agencyBook" class="mt-1 text-start"
                          style="font-size: 12px;color: #CC3D45;padding-left: 40px">
                          ไฟล์ที่อัพโหลด:
                          <a :href="agencyBookPath" target="_blank" class="link-hover">
                            <span>{{ agencyBook }}</span>
                          </a>
                        </div>
                      </v-flex>
                      <v-flex shrink pl-4 style="text-align: left">
                        <v-btn color="#3E7662" dark @click="onClickBtn('agencyBook')">
                          <v-icon class="pr-3">cloud_download</v-icon>
                          <span>Upload</span>
                        </v-btn>
                      </v-flex>
                      <v-flex xs3 ml-4><span style="color: red">*ชื่อไฟล์ต้องเป็นภาษาอังกฤษเท่านั้น (a-z,0-9)
                          ห้ามมีตัวอักษรอื่น </span></v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs6>
                    <v-layout>
                      <v-flex class="d-flex align-center mr-4 font-weight-bold" shrink>หน่วยงานที่ออกหนังสือ</v-flex>
                      <v-flex>
                        <textfield-form hide-detail :model="form.instituteBook"
                          @update-data="updateData('instituteBook', $event)"></textfield-form>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v-layout mt-6>
                  <v-flex xs6>
                    <v-layout>
                      <v-flex class="d-flex align-center mr-4 font-weight-bold" shrink>เลขหนังสือ</v-flex>
                      <v-flex>
                        <textfield-form hide-detail :model="form.bookNumber"
                          @update-data="updateData('bookNumber', $event)"></textfield-form>
                      </v-flex>
                    </v-layout>
                  </v-flex>

                  <v-flex xs6>
                    <v-layout>
                      <v-flex class="d-flex align-center mx-4 font-weight-bold" shrink>วันที่หนังสือ</v-flex>
                      <v-flex>
                        <DateInputForm id="orderDate" hide-detail :model="bookDate"
                          @update-data="updateDate('bookDate', $event)"></DateInputForm>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout style="margin-top: 30px">
              <v-flex ml-8 my-5 style="text-align: left;">
                <span style="font-weight: bold">Note</span>
                <span style="color: red"> *รายการที่ต้องแก้ไข</span>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex style="padding-left: 32px; padding-right: 32px">
                <textarea-form readonly :model="form.noteDetail" :rows="5"></textarea-form>
              </v-flex>
            </v-layout>

            <v-layout px-12 mt-8>
              <!-- row 1-->
              <v-flex xs6 style="font-weight: bold;font-size: 18px" d-flex justify-start>
                <span>เอกสารแนบเพิ่มเติม</span>
              </v-flex>
            </v-layout>
            <v-layout px-12 my-4>
              <v-flex shrink mr-4>
                <v-btn dark style="width: 80px;background-color: #3E7662" @click="addDynamicFile('fileOther')">
                  <v-icon>add</v-icon>
                  <span class="font-weight-bold">เพิ่ม</span>
                </v-btn>
              </v-flex>
              <v-flex>
                <span style="color: #CC3D45;">*ให้ผู้วิจัยระบุชื่่อ file / เวอร์ชั่น / วันที่
                  ให้ตรงกับ บันทึกข้อความ และห้ามมีอักขระพิเศษ
                  (!@#$%^&*)</span>
              </v-flex>
            </v-layout>
            <div v-for="(input, k) in form.fileOther" :key="'d' + k">
              <v-layout>
                <v-flex>
                  <v-layout>
                    <v-flex shrink mr-4 pt-2 style="text-align: right;font-weight: bold;">
                      <span>ชื่อไฟล์</span>
                    </v-flex>
                    <v-flex xs5>
                      <textfield-form hide-detail :model="input.title" @update-data="
                        updateDynamicFileData('fileOther', 'title', k, $event)"></textfield-form>
                    </v-flex>
                    <v-flex shrink pt-2 px-4 style="text-align: left;">
                      <span style="font-weight: bold;font-size: 16px;">ไฟล์</span>
                    </v-flex>
                    <v-flex>
                      <v-file-input accept=".pdf" prepend-icon="" :id="'fileProtocolModify' + k" :model="input.file"
                        outlined dense background-color="#FBF6F3" color="#3E7662" item-color="#3E7662" @change="
                          updateDynamicFileData('fileOther', 'file', k, $event)"></v-file-input>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex ml-4>
                  <v-btn dark style="background-color: #CC3D45; width: 80px" @click="removeDynamicFile('fileOther', k)"
                    :disabled="form.fileOther.length < 1">
                    <v-icon>remove</v-icon>
                    <span class="font-weight-bold">ลบ</span>
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-flex v-if="input.filePath"><a :href="input.filePath" target="_blank"
                  style="color: #3e7662;text-align: center" class="link-hover">
                  <p>ไฟล์ที่อัพโหลด: {{ input.file }}</p>
                </a>
              </v-flex>
            </div>

            <!-- row 4 -->
            <v-layout mt-12>
              <v-flex xs6 pl-3 mr-6 class="font-weight-bold">
                <v-text-field dense placeholder="signature" append-icon="edit" color="#3E7662"
                  v-model="form.signature"></v-text-field>
              </v-flex>
              <v-flex>
                <DateInputForm style="font-weight: bold" label="Date" :model="dateChange.reportDate"
                  @update-data="updateDate('reportDate', $event)"></DateInputForm>
              </v-flex>
            </v-layout>

            <v-layout mt-12>
              <v-flex d-flex justify-start>
                <span>สำนักงานคณะอนุกรรมการพิจารณาโครงการวิจัย พบ.</span>
              </v-flex>
              <v-flex d-flex justify-end>
                <span>หน้า 1 จาก 1 หน้า</span>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout>
          <v-flex mb-12 style="text-align: right;">
            <v-layout>
              <v-flex>
                <v-btn style="color: #3E7662" class="mx-4" depressed @click="saveDraft" color="#ffffff">บันทึกร่าง</v-btn>
                <!--<v-btn style="color: #3E7662" class="mx-4" depressed  color="#ffffff" @click="$router.go(-1)">ย้อนกลับ</v-btn>-->
                <v-btn depressed dark color="#3E7662" @click="save">
                  <v-icon class="mr-3">add</v-icon>
                  <span>บันทึกข้อมูล</span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <loading color="#527281" :active.sync="isLoading" :can-cancel="false" :is-full-page="fullPage"></loading>
  </v-content>
</template>

<script>
import TextfieldForm from "../../components/input/TextfieldForm";
import DateInputForm from "../../components/input/DateInputForm";
import TextareaForm from "../../components/input/TextareaForm";
import Swal from "sweetalert2";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { mapState } from "vuex";

export default {
  name: "FormRF06",
  components: {
    TextfieldForm,
    DateInputForm,
    TextareaForm,
    Loading,
  },
  data: () => ({
    isLoading: false,
    fullPage: true,
    form: {
      noteDetail: "",
      fileOther: [],
      hasStartDate: false,
      researchName: "",
      researchCode: "",
      researchInvestor: "",
      mainResearcher: "",
      phoneNumber: "",
      email: "",
      firstDate: "",
      lastDate: "",
      startDate: "",
      endDate: "",
      hasIssue: "",
      hasWarning: "",
      reason: "",
      hasFollowUp: "",
      followUp: "",
      hasPlan: "",
      plan: "",
      resultFile: "",
      agencyBook: "",
      bookNumber: "",
      bookDate: "",
      signature: "",
      reportDate: "",
      accrualCeiling: "",
      totalParticipants: "",
      totalScreening: "",
      totalWithdrawn: "",
      totalDead: "",
      totalCompleteResearch: "",
      totalExpAdversEvents: "",
      totalExpSUSARs: "",
      totalGlobal: "",
      anyProtocolFile: "",
      anyComplaintFile: "",
      instituteBook: "",
    },
    dynamicDateChange: {
      fileOther: []
    },
    dateChange: {
      firstDate: "",
      lastDate: "",
      startDate: "",
      endDate: "",
      reportDate: "",
    },
    userID: "",
    showResultFile: "",
    agencyBook: "",
    bookDate: "",
    anyProtocolFile: "",
    anyComplaintFile: "",
    anyProtocolFilePath: "",
    anyComplaintFilePath: "",
    agencyBookPath: "",
    resultFilePath: "",
  }),
  computed: {
    ...mapState({
      user: (state) => state.Auth.user,
    }),
  },
  created() {
    // Check Permission
    // if (!this.$route.query.researchID && this.$route.query.status !== 'อนุมัติ') {
    //     this.$router.go(-1);
    // }else {
    this.userID = this.$route.query.id;
    // Set data User
    // this.updateDate("startDate", this.$route.query.startDate);
    // this.updateDate("endDate", this.$route.query.endDate);
    this.updateDate("reportDate", new Date().toISOString().slice(0, 10));

    if (!this.$route.query.rfID) {
      this.form.researchCode = this.$route.query.researchCode;
      this.form.researchName = this.$route.query.nameResearch;
      this.form.researchInvestor = this.$route.query.budgetResource;
      this.form.mainResearcher = this.$route.query.thName;
      this.form.signature = this.$route.query.thName;
      this.form.phoneNumber = this.$route.query.mobilePhone;
      this.form.email = this.$route.query.email;
      this.updateDate("firstDate", this.$route.query.firstApprovalDate);
      this.updateDate("lastDate", this.$route.query.approvalDate);
      this.updateDate("reportDate", new Date().toISOString().slice(0, 10));
    }
    // this.getRF07(this.$route.query.researchID);
    if (this.$route.query.rfID) {
      this.getRF07(this.$route.query.rfID);
    }

    // }
  },
  methods: {
    addDynamicFile(name) {
      this.form[name].push({ title: "", file: null });
    },
    updateDynamicFileData(name, type, index, value) {
      if (type === "file") {
        this.isLoading = true;
        this.$store
          .dispatch("Common/uploadPdf", { form: "rf-07other-file", file: value })
          .then((res) => {
            this.form[name][index].file = res.fileName;
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
          });
      } else {
        this.form[name][index][type] = value;
      }
    },
    removeDynamicFile(name, index) {
      this.form[name].splice(index, 1);
      this.$forceUpdate();
    },
    // upload file
    uploadFile() {
      document.getElementById("resultFile").click();
    },
    changeFile(e) {
      this.form.resultFile = e;
    },
    updateData(name, val) {
      this.form[name] = val;
    },
    viewData() {
      this.$router.push({
        name: "ViewDataPostApprove",
        query: { id: this.userID },
      });
    },
    updateDate(name, val) {
      if (name === "firstDate") {
        this.form.firstDate = val;
        this.dateChange.firstDate = this.changeDate(val);
      }
      if (name === "lastDate") {
        this.form.lastDate = val;
        this.dateChange.lastDate = this.changeDate(val);
      }
      if (name === "startDate") {
        this.form.startDate = val;
        this.dateChange.startDate = this.changeDate(val);
      }
      if (name === "endDate") {
        this.form.endDate = val;
        this.dateChange.endDate = this.changeDate(val);
      }
      if (name === "reportDate") {
        this.form.reportDate = val;
        this.dateChange.reportDate = this.changeDate(val);
      }
      if (name === "bookDate") {
        this.form.bookDate = val;
        this.bookDate = this.changeDate(val);
      }
    },
    // clearCheckbox(name){
    //   if(name === "hasStartDate"){
    //     if(this.form.hasStartDate === true){
    //       this.dateChange.startDate = "";
    //     }
    //   }
    // },
    clear(name) {
      if (name === "hasFollowUp") {
        this.form.followUp = "";
      }
      if (name === "hasPlan") {
        this.form.plan = "";
      }
    },
    changeDate(val) {
      if(val != null){
        //change date start
      let sub_year = val.substring(0, 4);
      let sub_month = val.substring(5, 7);
      let sub_day = val.substring(8, 10);
      sub_year = parseInt(sub_year) + 543;
      let changeDate = sub_day + "/" + sub_month + "/" + sub_year;
      return changeDate;
      }else{
        return null;
      }
    },
    getRF07(id) {
      this.isLoading = true;
      this.$store
        .dispatch("FormRF/getRF07", id)
        .then((res) => {

          if (res !== "don't have research termination yet") {
            if (res.status !== "อนุมัติ") {
              this.form.hasStartDate = res.hasStartDate == 1;
              this.form.instituteBook = res.instituteBook;
              this.form.fileOther = res.fileOther ?? [];
              this.form.noteDetail = res.noteDetail;
              this.form.researchCode = res.researchCode;
              this.form.researchName = res.researchName;
              this.form.researchInvestor = res.researchInvestor;
              this.form.mainResearcher = res.mainResearcher;
              this.form.signature = res.signature;
              this.form.phoneNumber = res.phoneNumber;
              this.form.email = res.email;
              this.form.firstDate = res.firstDate;
              this.dateChange.firstDate = this.changeDate(res.firstDate);
              this.form.lastDate = res.lastDate;
              this.dateChange.lastDate = this.changeDate(res.lastDate);
              this.form.startDate = res.startDate;
              this.dateChange.startDate = this.changeDate(res.startDate);
              this.form.endDate = res.endDate;
              this.dateChange.endDate = this.changeDate(res.endDate);
              this.form.hasIssue = res.hasIssue.toString();
              this.form.hasWarning = res.hasWarning.toString();

              this.form.reason = res.reason;
              this.form.hasFollowUp = res.hasFollowUp.toString();
              this.form.followUp = res.followUp;
              this.form.hasPlan = res.hasPlan.toString();
              this.form.plan = res.plan;
              this.showResultFile = res.resultFile;
              this.pathResult = res.pathResult;
              this.form.resultFile = res.resultFile;

              this.form.accrualCeiling = res.accrualCeiling;
              this.form.totalParticipants = res.totalParticipants;
              this.form.totalScreening = res.totalScreening;
              this.form.totalWithdrawn = res.totalWithdrawn;
              this.form.totalDead = res.totalDead;
              this.form.totalCompleteResearch = res.totalCompleteResearch;
              this.form.totalExpAdversEvents = res.totalExpAdversEvents;
              this.form.totalExpSUSARs = res.totalExpSUSARs;
              this.form.totalGlobal = res.totalGlobal;
              this.form.anyProtocolFile = res.anyProtocolFile;
              this.form.anyComplaintFile = res.anyComplaintFile;
              this.anyComplaintFile = res.anyComplaintFile;
              this.anyComplaintFilePath = res.anyComplaintFilePath;
              this.form.anyProtocolFile = res.anyProtocolFile;
              this.anyProtocolFile = res.anyProtocolFile;
              this.anyProtocolFilePath = res.anyProtocolFilePath;

              this.form.agencyBook = res.agencyBook;
              this.agencyBook = res.agencyBook;
              this.agencyBookPath = res.agencyBookPath;
              this.bookDate = this.changeDate(res.bookDate);
              this.form.bookDate = res.bookDate;
              this.form.bookNumber = res.bookNumber;
              this.form.signature = res.signature.toString();
              this.form.reportDate = res.reportDate;
              this.dateChange.reportDate = this.changeDate(res.reportDate);
            }
          }
          this.isLoading = false;
        })
        .catch((e) => {
          this.isLoading = false;
        });
    },
    save() {
      Swal.fire({
        title: "คุณต้องการบันทึกหรือไม่?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3E7662",
        cancelButtonColor: "rgb(189, 189, 189)",
        confirmButtonText: "บันทึก",
        cancelButtonText: "ยกเลิก",
      })
        .then((result) => {
          if (result.value) {
            this.isLoading = true;
            this.form["researchID"] = this.$route.query.researchID;
            if (this.$route.query.rfID) {
              this.form["methods"] = "edit";
              this.form["rfID"] = this.$route.query.rfID;
            } else {
              this.form["methods"] = "save";
            }
            this.$store
              .dispatch("FormRF/saveRF07", this.form)
              .then((res) => {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "บันทึกสำเร็จ",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.isLoading = false;
                this.$router.push("/profile");
              })
              .catch((e) => {
                this.isLoading = false;
                if (e.response.data.errors) {
                  for (var k in e.response.data.errors) {
                    Swal.fire({
                      title: "เกิดข้อผิดพลาด",
                      text: e.response.data.errors[k][0],
                      icon: "warning",
                      confirmButtonColor: "#3E7662",
                    }).then(() => {
                      this.$vuetify.goTo(`#${k}`);
                    });
                  }
                }
                // Swal.fire({
                //     title: 'Save failed!',
                //     text: 'บันทึกไม่สำเร็จ',
                //     icon: 'warning',
                // })
                // this.isLoading = false;
              });
          }
        })
        .catch((e) => {
          this.isLoading = false;
        });
    },
    saveDraft() {
      Swal.fire({
        title: "คุณต้องการบันทึกแบบร่างหรือไม่?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3E7662",
        cancelButtonColor: "rgb(189, 189, 189)",
        confirmButtonText: "บันทึก",
        cancelButtonText: "ยกเลิก",
      })
        .then((result) => {
          if (result.value) {
            this.isLoading = true;
            this.form["researchID"] = this.$route.query.researchID;
            if (this.$route.query.rfID) {
              this.form["methods"] = "edit";
              this.form["rfID"] = this.$route.query.rfID;
            } else {
              this.form["methods"] = "save";
              this.form["status"] = "บันทึกแบบร่าง";
            }
            this.$store
              .dispatch("FormRF/saveRF07", this.form)
              .then((res) => {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "บันทึกแบบร่างสำเร็จ",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.isLoading = false;
                this.$router.push("/profile");
              })
              .catch((e) => {
                this.isLoading = false;
                if (e.response.data.errors) {
                  for (var k in e.response.data.errors) {
                    Swal.fire({
                      title: "เกิดข้อผิดพลาด",
                      text: e.response.data.errors[k][0],
                      icon: "warning",
                      confirmButtonColor: "#3E7662",
                    }).then(() => {
                      this.$vuetify.goTo(`#${k}`);
                    });
                  }
                }
                // Swal.fire({
                //     title: 'Save failed!',
                //     text: 'บันทึกไม่สำเร็จ',
                //     icon: 'warning',
                // })
                // this.isLoading = false;
              });
          }
        })
        .catch((e) => {
          this.isLoading = false;
        });
    },
    onUpload(name, e) {
      this.$store
        .dispatch("Common/uploadPdf", { form: "rf07", file: e })
        .then((res) => {
          this.form[name] = res.fileName;
        })
        .catch((error) => { });
    },
    onClickBtn(id) {
      document.getElementById(id).click();
    },
  },
};
</script>

<style scoped>
.link-hover:hover {
  cursor: pointer;
  text-decoration: underline;
}

.link-hover {
  color: #cc3d45;
  text-decoration: none;
}
</style>
