<template>
    <v-content>
        <v-layout class="font-weight-bold">
            <v-flex shrink pt-2 v-if="label" :style="css">
                <span class="mr-3">{{label}}</span>
            </v-flex>
            <v-flex class="font-weight-bold">
                <v-text-field
                        :disabled="disabled"
                        dense
                        :placeholder="placeholder"
                        :append-icon="icon"
                        :color="color"
                        :value="model"
                        :readonly="readonly"
                        @input="onChange"
                        :background-color="backgroundColor"
                        :hide-details="hideDetail"
                        :type="type"
                        single-line
                ></v-text-field>
            </v-flex>
        </v-layout>
    </v-content>
</template>

<script>
    export default {
        name: "TextfieldFormAppend",
        props: {
            type: {
              type: String,
            },
            model: {
                required: false
            },
            placeholder: String,
            backgroundColor:{
                type: String,
            },
            color: {
                type: String,
                default: '#3E7662'
            },
            readonly: {
                type: Boolean
            },
            disabled: {
                type: Boolean
            },
            hideDetail: {
                type: Boolean,
                default: false,
            },
            label: {
                type: String,
            },
            icon: {
                type: String,
            },
            css: {
                type: String,
                // default: 'text-align: right;color: #3E7662'
            }
        },
        methods: {
            onChange(val) {
                this.$emit("update-data", val);
            },
        }
    }
</script>

<style scoped>

</style>
