import Service from "../../services";

const FormResearch = {
    namespaced: true,
    state: {
        formResearch: 1,
        researchID: 0,
        lastRefID: 0,
    },
    mutations: {
        SET_PAGE: (state, page) => {
            state.formResearch = page;
        },
        SET_RESEARCH: (state, res) => {
            state.researchID = res.id;
            state.lastRefID = res.lastRefID;
        },
        SET_RESEARCH_ID: (state, id) => {
            state.researchID = id;
        },
        CLEAR_RESEARCH_ID: (state) => {
            state.researchID = 0;
        }
    },
    actions: {
        changePage({ commit }, page) {
            commit("SET_PAGE", page);
        },
        saveResearch1({ commit }, data) {
            return Service.ResearchForm.saveResearchPage1(data).then(res => {
                commit("SET_RESEARCH", res);
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        saveResearch2({ commit }, data) {
            return Service.ResearchForm.saveResearchPage2(data, data.researchID).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        saveResearch3({ commit }, data) {
            return Service.ResearchForm.saveResearchPage3(data, data.researchID).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        saveResearch4({ commit }, data) {
            return Service.ResearchForm.saveResearchPage4(data, data.researchID).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        sendResearch({ commit }, data) {
            return Service.ResearchForm.sendResearch(data, data.researchID).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        getFormResearch1({ commit }, id) {
            return Service.ResearchForm.getFormResearch1(id).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        getFormResearch2({ commit }, id) {
            return Service.ResearchForm.getFormResearch2(id).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        getFormResearch3({ commit }, id) {
            return Service.ResearchForm.getFormResearch3(id).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        getFormResearch4({ commit }, id) {
            return Service.ResearchForm.getFormResearch4(id).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        getResearchVerify({ commit }, id) {
            return Service.ResearchForm.getResearchVerify(id).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        setResearchID({ commit }, id) {
            commit("SET_RESEARCH_ID", id);
        },
        clearResearchID({ commit }) {
            commit("CLEAR_RESEARCH_ID");
        },
        getCertificate({ commit }) {
            return Service.ResearchForm.getCertificate().then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err);
            })
        }
    }
}

export default FormResearch;
