<template>
    <v-content>
        <v-layout ma-6>
            <v-flex xs12 px-12>
                <v-layout>
                    <v-flex xs12 px-12 py-12 style="text-align: center;">
                        <span style="color: #3E7662;font-size: 36px;font-weight: bold;">
                            ขั้นตอนการเสนอโครงร่างการวิจัย
                        </span>
                    </v-flex>
                </v-layout>
                <v-layout>
                    <v-flex xs12 pb-8 px-12 style="text-align: center;">
                        <img alt="Vue logo" src="../assets/StepResearch.png" style="width: 80%;">
                    </v-flex>
                </v-layout>
                <v-layout>
                    <v-flex xs12 pb-8 px-12 style="text-align: right;">
                        <v-btn color="#3E7662" @click="createResearch">
                            <v-icon class="pr-3" style="color: white">add</v-icon>
                            <span style="color:white">สร้างงานวิจัย</span>
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
    </v-content>
</template>

<script>
    import Swal from 'sweetalert2';
    import { mapState , mapMutations} from "vuex";

    export default {
        name: "FlowCreate",
        computed: {
            ...mapState({
                user: state => state.Auth.user,
            })
        },
        methods: {
            createResearch() {

                if(this.user.remark === 'B') {
                    Swal.fire({
                        title: 'Blacklist',
                        text: 'ท่านถูกระงับการสร้างงานวิจัย กรุณาติดต่อเจ้าหน้าที่',
                        icon: 'warning',
                        confirmButtonColor: '#3E7662',
                    })
                }else if(this.user.remark === 'T') {
                    Swal.fire({
                        title: 'Training request',
                        text: 'กรุณาอบรม GCP ซ้ำอีกรอบ ก่อนดำเนินการใดๆ ในระบบ',
                        icon: 'warning',
                        confirmButtonColor: '#3E7662',
                    })
                }else if(this.user.remark === 'C'){
                    if(this.activeResearchCount > 2)
                        Swal.fire({
                            title: 'Careful Monitoring',
                            text: 'ท่านถูกจำกัดสิทธิ์ ไม่สามารถมีงานวิจัยที่อยู่ระหว่างดำเนินการได้เกิน 2 เรื่อง',
                            icon: 'warning',
                            confirmButtonColor: '#3E7662',
                        });
                    else
                        this.$router.push('/manual-research');
                } else if (this.user.certificateStatus === 0) {
                    Swal.fire({
                        title: 'รอการตรวจสอบ',
                        text: 'กรุณาติดต่อเจ้าหน้าที่',
                        icon: 'warning',
                        confirmButtonColor: '#3E7662',
                    })
                } else if (this.user.certificateStatus === 1 && this.remainingDate !== 0) {
                    this.$router.push('/manual-research');
                } else if (this.user.certificateStatus === 1 && this.remainingDate === 0) {
                    Swal.fire({
                        title: 'ใบ Certificate หมดอายุ!',
                        text: 'ระบบไม่สามารถสร้างโครงร่างงานวิจัยได้',
                        icon: 'warning',
                        confirmButtonColor: '#3E7662',
                    })
                } else {
                    Swal.fire({
                        title: 'ท่านยังไม่มีใบ Certificate !',
                        text: 'ระบบไม่สามารถสร้างโครงร่างงานวิจัยได้',
                        icon: 'warning',
                        confirmButtonColor: '#3E7662',
                    }).then(() => {
                        this.Expand_STATE();
                        setTimeout(() => {
                            document.getElementById('uploadBtn').focus();
                        }, 400)
                    })
                }

            },
        }
    }
</script>

<style scoped>

</style>
