<template>
    <v-content>
        <v-layout>
            <v-flex px-12 pt-12 pb-4 style="text-align: left;">
                <span style="font-size: 24px;color: #3E7662;font-weight: bold;">
                    ผลการพิจารณาโครงร่างการวิจัยทั้งหมด
                </span>
            </v-flex>
        </v-layout>

        <v-layout>
            <v-flex px-12 pb-12>
                <v-card color="#FFFFFF">
                    <v-layout>
                        <v-flex pa-12>

                            <div v-for="(item,i) in resultAll" :key="i">
                                <v-layout justify-space-between="">
                                    <v-flex xs5 d-flex justify-start align-center>
                                        <span style="font-weight: bold">{{item.meetingName}}</span>
                                    </v-flex>
                                    <v-flex xs5 shrink d-flex align-center ml-12>
                                        <span style="font-weight: bold">{{item.meetingDate}}</span>
                                    </v-flex>
                                    <v-flex xs2 shrink d-flex justify-end align-right>
                                        <v-btn color="#3E7662" dark @click="openResult(item.pathResult)">
                                            <v-icon class="pr-3">visibility</v-icon>
                                            <span>ผลการพิจารณา</span>
                                        </v-btn>
                                    </v-flex>
                                </v-layout>
                                <v-layout>
                                    <v-flex py-3>
                                        <v-divider style="background-color: white"></v-divider>
                                    </v-flex>
                                </v-layout>
                            </div>

                        </v-flex>
                    </v-layout>
                </v-card>
            </v-flex>
        </v-layout>

        <loading
                color="#527281"
                :active.sync="isLoading"
                :can-cancel="false"
                :is-full-page="fullPage"
        ></loading>

    </v-content>
</template>

<script>
    import Loading from 'vue-loading-overlay';

    export default {
        name: "DownloadForm",
        components: {
            Loading
        },
        data: () => ({
            isLoading: false,
            fullPage: true,
            resultAll: [],
        }),
        created() {
            this.getAllMeeting();
        },
        methods: {
            getAllMeeting(){
                this.loading = true;
                this.$store.dispatch('Common/getAllMeeting').then(res => {
                    this.resultAll = res;
                });
                this.loading = false;
            },
            openResult(val) {
                window.open(val)
            }
        }
    }
</script>

<style scoped>

</style>
