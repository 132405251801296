import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import FormResearch from "./modules/formResearch.store";
import Auth from "./modules/auth.store"
import Common from "./modules/Common.store";
import FormRF from "./modules/formRF.store"
import Exam from "./modules/exam.store";
import Profile from "./modules/profile.store";
import Budget from "./modules/budget.store";


Vue.use(Vuex);

const modules = {
    FormResearch: FormResearch,
    Auth: Auth,
    Common: Common,
    FormRF: FormRF,
    Exam: Exam,
    Profile: Profile,
    Budget: Budget
};

export default new Vuex.Store({
    modules,
    plugins: [createPersistedState()]
})
