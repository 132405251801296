import Service from "../../services";

const Common = {
  namespaced: true,
  state: {
    province: "",
    district: "",
    subDistrict: "",
    remainingDate: null,
  },
  mutations: {
    SET_PROVINCE: (state, data) => {
      state.province = data;
    },
    SET_REMAINING_DATE: (state, data) => {
      state.remainingDate = data;
    },
  },
  actions: {
    getProvince({ commit }) {
      return Service.Common.getProvince().then((res) => {
        commit("SET_PROVINCE", res);
        return res;
      });
    },
    getDistricts({ commit }, id) {
      return Service.Common.getDistricts(id).then((res) => {
        return res;
      });
    },
    getSubDistricts({ commit }, id) {
      return Service.Common.getSubDistricts(id).then((res) => {
        return res;
      });
    },
    getRole({ commit }) {
      return Service.Common.getRole().then((res) => {
        return res;
      });
    },
    getTitleName({ commit }) {
      return Service.Common.getTitleName().then((res) => {
        return res;
      });
    },
    getRank({ commit }) {
      return Service.Common.getRank().then((res) => {
        return res;
      });
    },
    getResearchType({ commit }) {
      return Service.Common.getResearchType().then((res) => {
        return res;
      });
    },
    getUserList({ commit }) {
      return Service.Common.showResearcherName().then((res) => {
        return res;
      });
    },
    getBudgetList({ commit }, data) {
      return Service.Common.getBudgetList(data).then((res) => {
        return res;
      });
    },
    getParticipantList({ commit }) {
      return Service.Common.getParticipantList().then((res) => {
        return res;
      });
    },
    getCertificate({ commit }) {
      return Service.Common.getCertificate().then((res) => {
        commit("SET_REMAINING_DATE", res.remaining_date);
        return res;
      });
    },
    uploadCertificate({ commit }, data) {
      return Service.Common.uploadCertificate(data).then((res) => {
        return res;
      });
    },
    getNews({ commit }) {
      return Service.Common.getNews().then((res) => {
        return res;
      });
    },
    getNewsByID({ commit }, id) {
      return Service.Common.getNewsByID(id).then((res) => {
        return res;
      });
    },
    getAllNews({ commit }, page) {
      return Service.Common.getAllNews(page).then((res) => {
        return res;
      });
    },
    getLastMonthMeeting({ commit }) {
      return Service.Common.getLastMonthMeeting().then((res) => {
        return res;
      });
    },
    getThisMonthMeeting({ commit }) {
      return Service.Common.getThisMonthMeeting().then((res) => {
        return res;
      });
    },
    getAllMeeting({ commit }) {
      return Service.Common.getAllMeeting().then((res) => {
        return res;
      });
    },
    getHistoryResearch({ commit }, data) {
      return Service.Common.getHistoryResearch(data).then((res) => {
        return res;
      });
    },
    getAffiliationList({ commit }) {
      return Service.Common.getAffiliationList().then((res) => {
        return res;
      });
    },
    verifyEmail({ commit }, token) {
      return Service.Common.verifyEmail(token).then((res) => {
        return res;
      });
    },
    uploadPdf({ commit }, data) {
      return Service.Common.uploadPdf(data)
        .then((data) => {
          return data;
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    uploadImage({ commit }, data) {
      return Service.Common.uploadImage(data)
        .then((data) => {
          return data;
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
  },
};

export default Common;
