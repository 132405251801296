<template>
  <v-footer>
    <v-layout>
      <v-flex style="background-color: #3E7662;color: #fff;">
        <v-layout>
          <v-flex xs12 py-8 style="padding-left: 100px;padding-right: 100px;text-align: left">
            <v-layout>
              <v-flex style="font-size: 16px">
                ที่ตั้งสำนักงาน
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex py-3>
                <v-divider style="background-color: white"></v-divider>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs8 mr-6 style="font-size: 17px">
                <b>สำนักงานคณะอนุกรรมการพิจารณาโครงการวิจัย กรมแพทย์ทหารบก</b><br>
                อาคารพระมงกุฎเกล้าเวชวิทยา ชั้น 5 วิทยาลัยแพทยศาสตร์พระมงกุฎเกล้า 317/5 ถนนราชวิถี แขวงทุ่งพญาไท เขตราชเทวี กรุงเทพฯ 10400<br>
                โทร. 02-763-4297 , 02-763-4270 , โทรศัพท์ 086-7761807 , Email : irbrta.ec@gmail.com , irbrta@yahoo.com
              </v-flex>

            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout>
          <v-flex py-4 style="background-color: #544A44;color: white;text-align: center">
            Copyright © 2018. All Rights Reserved.
          </v-flex>
        </v-layout>
        <!--Line Help-->
      </v-flex>
    </v-layout>
  </v-footer>
</template>

<script>
    export default {
      name: "Footer",

      methods: {
        onclickFacebook() {
          window.open('https://www.facebook.com/irb.rta')
        }
      }
    }

</script>

<style scoped>
  .v-footer {
    padding: 0px;
  }
</style>
