<template>
  <v-content style="background-color: #EFEAE7">
    <loading color="#527281" :active.sync="isLoading" :can-cancel="false" :is-full-page="fullPage"></loading>

    <v-layout style="text-align: center">
      <v-flex pt-12>
        <span style="font-weight: bold;font-size: 36px; color: #3E7662;">ประวัติการส่ง/แก้ไขงานวิจัย</span>
      </v-flex>
    </v-layout>

    <v-layout style="text-align: left" class="px-12 mt-2 mb-12">
      <v-flex>
        <v-btn color="#3E7662" dark class="font-weight-bold" @click="$router.go(-1)">
          ย้อนกลับ
        </v-btn>
      </v-flex>
    </v-layout>

    <v-layout class="px-12">
      <v-flex>
        <!-- Table ผู้เข้าร่วมวิจัย -->
        <form-table name-table="ประวัติการส่ง/แก้ไขงานวิจัย" :headers="headers" :items="itemList"></form-table>
      </v-flex>
    </v-layout>

    <!-- Table RF12 -->
    <v-layout class="px-12" style="margin-top: 5rem">
      <v-flex>
        <form-table name-table="ประวัติการส่ง/แก้ไข แบบรายงานการแก้ไขเพิ่มเติมโครงร่างการวิจัย (RF 12_2563)"
          :headers="headerRF12" :items="itemListRF12" onEdit></form-table>
      </v-flex>
    </v-layout>

    <!-- Table RF04_1 -->
    <v-layout class="px-12" style="margin-top: 5rem">
      <v-flex>
        <form-table name-table="ประวัติการส่ง/แก้ไข แบบรายงานเหตุการณ์ไม่พึงประสงค์จากยา/เหตุการณ์ไม่คาดคิดจากยา
                      (RF 04_1_2563)" :headers="headerRF04_1" :items="itemListRF04_1"
          onEdit></form-table>
      </v-flex>
    </v-layout>

    <!-- Table RF04_2 -->
    <v-layout class="px-12" style="margin-top: 5rem">
      <v-flex>
        <form-table name-table="ประวัติการส่ง/แก้ไข แบบรายงานเหตุการณ์ไม่พึงประสงค์ (SAE) (RF 04_2_2563)"
          :headers="headerRF04_2" :items="itemListRF04_2" onEdit></form-table>
      </v-flex>
    </v-layout>

    <!-- Table RF05 -->
    <v-layout class="px-12" style="margin-top: 5rem">
      <v-flex>
        <form-table name-table="ประวัติการส่ง/แก้ไข แบบรายงานการไม่ปฏิบัติตามข้อกำหนด (RF 05_2563)" :headers="headerRF05"
          :items="itemListRF05" onEdit></form-table>
      </v-flex>
    </v-layout>

    <!-- Table RF03 -->
    <v-layout class="px-12" style="margin-top: 5rem">
      <v-flex>
        <form-table name-table="ประวัติการส่ง/แก้ไข แบบรายงานความก้าวหน้าของการวิจัย/ขอต่ออายุโครงการ (RF03_2563)"
          :headers="headerRF03" :items="itemListRF03" onEdit></form-table>
      </v-flex>
    </v-layout>

    <!-- Table RF06 -->
    <v-layout class="px-12" style="margin-top: 5rem">
      <v-flex>
        <form-table name-table="ประวัติการส่ง/แก้ไข แบบรายงานแจ้งปิด/สรุปผลการวิจัย (RF 06_2563)" :headers="headerRF06"
          :items="itemListRF06" onEdit></form-table>
      </v-flex>
    </v-layout>

    <!-- Table RF07 -->
    <v-layout class="px-12" style="margin-top: 5rem;margin-bottom: 5rem">
      <v-flex>
        <form-table name-table="ประวัติการส่ง/แก้ไข แบบรายงานการยุติโครงการวิจัยก่อนกําหนด (RF 07_2563)"
          :headers="headerRF07" :items="itemListRF07" onEdit></form-table>
      </v-flex>
    </v-layout>

    <!-- Table Correspondence -->
    <v-layout class="px-12" style="margin-top: 5rem;margin-bottom: 5rem">
      <v-flex>
        <form-table name-table="ประวัติการส่ง/แก้ไข เอกสารและจดหมายแจ้งเกี่ยวกับงานวิจัย (Correspondence Report)"
          :headers="headerCorres" :items="itemListCorres" onEdit :viewCorresFile="viewCorresFile"></form-table>
      </v-flex>
    </v-layout>

    <!-- Table Susar -->
    <v-layout class="px-12" style="margin-top: 5rem;margin-bottom: 5rem">
      <v-flex>
        <form-table name-table="ประวัติการส่ง/แก้ไข แบบรายงานเหตุการณ์ไม่พึ่งประสงค์นอกสถาบัน SUSAR / DMC / DSMB"
          :headers="headerSusar" :items="itemListSusar" onEdit></form-table>
      </v-flex>
    </v-layout>

    <!-- Modal View To Reviewer Files -->
    <template>
      <v-row justify="center">
        <v-dialog v-model="viewFileDialog" persistent width="800px" max-width="80%">
          <v-card>
            <!-- Header -->
            <v-card-title class="mb-4 justify-center" style="background-color: #3e7662;color: white;">
              <span class="font-weight-medium font-weight-bold">ดูเอกสารแนบ</span>
            </v-card-title>
            <v-card-text class="justify-center" v-if="corresFile">
              <v-layout>
                <h2 style="margin: auto">
                  เอกสาร Correspondence
                </h2>
              </v-layout>
              <v-layout my-6>
                <v-flex>
                  <a :href="corresFile.file" style="color: #3e7662;" target="_blank">
                    <p style="font-size: 16px;text-align: center" class="my-2">
                      {{ corresFile.title ? corresFile.title : "ไม่มีชื่อ" }}
                    </p>
                  </a>
                </v-flex>
              </v-layout>
              <div v-if="corresOtherFile.length > 1" class="my-6">
                <v-layout>
                  <h2 style="margin: auto" class="mb-6">
                    เอกสาร อื่นๆ
                  </h2>
                </v-layout>
                <v-layout v-for="(file, k) in corresOtherFile" :key="k">
                  <v-flex ml-2 justify-center>
                    <a :href="file.filePath" style="color: #3e7662;" target="_blank">
                      <p style="font-size: 16px;text-align: center" class="my-2">
                        {{ file.title ? file.title : "ไม่มีชื่อ" }}
                      </p>
                    </a>
                  </v-flex>
                </v-layout>
              </div>
              <div v-else>
                <v-layout>
                  <v-flex my-12>
                    <p style="font-size: 18px;font-weight: bold; color: #3e7662;text-align: center">
                      ไม่พบเอกสารแนบ
                    </p>
                  </v-flex>
                </v-layout>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn style="font-weight: bold" color="#3E7662" text @click="viewFileDialog = false">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </v-content>
</template>

<script>
import { mapState } from "vuex";
import FormTable from "../components/table/FormTable";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "ManualResearch",
  components: {
    FormTable,
    Loading,
  },
  created() {
    this.getHistoryResearch(1);
  },
  data: () => ({
    isLoading: false,
    fullPage: true,
    headers: [
      { text: "ลำดับ", value: "number", align: "center" },
      { text: "ชื่อโครงการงานวิจัย", value: "nameResearch", align: "center" },
      { text: "Version", value: "version", align: "center" },
      { text: "สถานะ", value: "status", align: "center" },
      { text: "เลขรับ", value: "no", align: "center" },
      { text: "วันที่รับ", value: "created_at", align: "center" },
    ],
    headerRF12: [
      { text: "ลำดับ", value: "number", align: "center" },
      { text: "ชื่อโครงการงานวิจัย", value: "nameResearch", align: "center" },
      { text: "เรื่องที่", value: "groupID", align: "center" },
      { text: "Version", value: "version", align: "center" },
      { text: "สถานะ", value: "status", align: "center" },
      { text: "เลขรับ", value: "no", align: "center" },
      { text: "วันที่รับ", value: "created_at", align: "center" },
      { text: "แก้ไข", value: "edit", align: "center" },
    ],
    headerRF04_1: [
      { text: "ลำดับ", value: "number", align: "center" },
      { text: "ชื่อโครงการงานวิจัย", value: "nameResearch", align: "center" },
      { text: "เรื่องที่", value: "groupID", align: "center" },
      { text: "Version", value: "version", align: "center" },
      { text: "สถานะ", value: "status", align: "center" },
      { text: "เลขรับ", value: "no", align: "center" },
      { text: "วันที่รับ", value: "created_at", align: "center" },
      { text: "แก้ไข", value: "edit", align: "center" },
    ],
    headerRF04_2: [
      { text: "ลำดับ", value: "number", align: "center" },
      { text: "ชื่อโครงการงานวิจัย", value: "nameResearch", align: "center" },
      { text: "เรื่องที่", value: "groupID", align: "center" },
      { text: "Version", value: "version", align: "center" },
      { text: "สถานะ", value: "status", align: "center" },
      { text: "เลขรับ", value: "no", align: "center" },
      { text: "วันที่รับ", value: "created_at", align: "center" },
      { text: "แก้ไข", value: "edit", align: "center" },
    ],
    headerRF05: [
      { text: "ลำดับ", value: "number", align: "center" },
      { text: "ชื่อโครงการงานวิจัย", value: "nameResearch", align: "center" },
      { text: "เรื่องที่", value: "groupID", align: "center" },
      { text: "Version", value: "version", align: "center" },
      { text: "สถานะ", value: "status", align: "center" },
      { text: "เลขรับ", value: "no", align: "center" },
      { text: "วันที่รับ", value: "created_at", align: "center" },
      { text: "แก้ไข", value: "edit", align: "center" },
    ],
    headerRF03: [
      { text: "ลำดับ", value: "number", align: "center" },
      { text: "ชื่อโครงการงานวิจัย", value: "nameResearch", align: "center" },
      { text: "เรื่องที่", value: "groupID", align: "center" },
      { text: "Version", value: "version", align: "center" },
      { text: "สถานะ", value: "status", align: "center" },
      { text: "เลขรับ", value: "no", align: "center" },
      { text: "วันที่รับ", value: "created_at", align: "center" },
      { text: "แก้ไข", value: "edit", align: "center" },
    ],
    headerRF06: [
      { text: "ลำดับ", value: "number", align: "center" },
      { text: "ชื่อโครงการงานวิจัย", value: "nameResearch", align: "center" },
      { text: "เรื่องที่", value: "groupID", align: "center" },
      { text: "Version", value: "version", align: "center" },
      { text: "สถานะ", value: "status", align: "center" },
      { text: "เลขรับ", value: "no", align: "center" },
      { text: "วันที่รับ", value: "created_at", align: "center" },
      { text: "แก้ไข", value: "edit", align: "center" },
    ],
    headerRF07: [
      { text: "ลำดับ", value: "number", align: "center" },
      { text: "ชื่อโครงการงานวิจัย", value: "nameResearch", align: "center" },
      { text: "เรื่องที่", value: "groupID", align: "center" },
      { text: "Version", value: "version", align: "center" },
      { text: "สถานะ", value: "status", align: "center" },
      { text: "เลขรับ", value: "no", align: "center" },
      { text: "วันที่รับ", value: "created_at", align: "center" },
      { text: "แก้ไข", value: "edit", align: "center" },
    ],
    headerCorres: [
      { text: "ลำดับ", value: "number", align: "center" },
      { text: "ชื่อเอกสาร", value: "corresName", align: "center" },
      { text: "เรื่องที่", value: "groupID", align: "center" },
      { text: "สถานะ", value: "status", align: "center" },
      { text: "เลขรับ", value: "no", align: "center" },
      { text: "Date", value: "created_at", align: "center" },
      { text: "ดูไฟล์", value: "view", align: "center" },
    ],
    headerSusar: [
      { text: "ลำดับ", value: "number", align: "center" },
      { text: "ชื่อโครงการงานวิจัย", value: "nameResearch", align: "center" },
      { text: "เรื่องที่", value: "groupID", align: "center" },
      { text: "Version", value: "version", align: "center" },
      { text: "สถานะ", value: "status", align: "center" },
      { text: "เลขรับ", value: "no", align: "center" },
      { text: "วันที่รับ", value: "created_at", align: "center" },
      { text: "แก้ไข", value: "edit", align: "center" },
    ],
    itemList: [],
    itemListRF12: [],
    itemListRF04_1: [],
    itemListRF04_2: [],
    itemListRF05: [],
    itemListRF03: [],
    itemListRF06: [],
    itemListRF07: [],
    itemListCorres: [],
    itemListSusar: [],
    viewFileDialog: false,
    corresOtherFile: [],
    corresFile: { file: '', title: '' },
  }),
  methods: {
      changeDate(val) {
          //change date start
          let sub_year = val.substring(0, 4);
          let sub_month = val.substring(5, 7);
          let sub_day = val.substring(8, 10);
          sub_year = parseInt(sub_year) + 543;
          let changeDate = sub_day + "/" + sub_month + "/" + sub_year;
          return changeDate;
      },
      getHistoryResearch(page) {
          this.isLoading = true;
          let data = {
              page: page,
              id: this.$route.query.id,
          };
          this.itemList = [];
          this.itemListRF12 = [];
          this.itemListRF04_1 = [];
          this.itemListRF04_2 = [];
          this.itemListRF05 = [];
          this.itemListRF03 = [];
          this.itemListRF06 = [];
          this.itemListRF07 = [];
          this.itemListCorres = [];
          this.itemListSusar = [],
          this.itemList = [];
          this.$store
              .dispatch("Common/getHistoryResearch", data)
              .then((res) => {
                  this.isLoading = false;
                  let i = 1;
                  // Map history research
                  res.research.forEach((data) => {
                      this.itemList.push({
                          number: i,
                          nameResearch: data.thResearchName,
                          version: data.version,
                          status: data.status,
                          no: data.initialNumber ? data.initialNumber : "-",
                          created_at: this.changeDate(data.created_at),
                      });
                      i++;
                  });
                  return res;
              })
              .then((res) => {
                  // Map history RF12
                  let i = 1;
                  res.researchAmendment.forEach((data) => {
                      this.itemListRF12.push({
                          item: res,
                          number: i,
                          id: data.id,
                          nameResearch: data.researchName,
                          groupID: data.group_id + 1,
                          version: data.version,
                          status: data.status,
                          no: data.initialNumber ? data.initialNumber : "-",
                          isOld: data.isOld,
                          edit: "RF12",
                          created_at: this.changeDate(data.created_at),
                      });
                      i++;
                  });
                  return res;
              })
              .then((res) => {
                  // Map history RF04_1
                  let i = 1;
                  res.adverseDrugEvent.forEach((data) => {
                      this.itemListRF04_1.push({
                          item: res,
                          number: i,
                          id: data.id,
                          nameResearch: data.researchName,
                          groupID: data.group_id + 1,
                          version: data.version,
                          status: data.status,
                          no: data.initialNumber ? data.initialNumber : "-",
                          isOld: data.isOld,
                          edit: "RF04_1",
                          created_at: this.changeDate(data.created_at),
                      });
                      i++;
                  });
                  return res;
              })
              .then((res) => {
                  // Map history RF04_2
                  let i = 1;
                  res.adverseEvent.forEach((data) => {
                      this.itemListRF04_2.push({
                          item: res,
                          number: i,
                          id: data.id,
                          nameResearch: data.researchName,
                          groupID: data.group_id + 1,
                          version: data.version,
                          status: data.status,
                          no: data.initialNumber ? data.initialNumber : "-",
                          isOld: data.isOld,
                          edit: "RF04_2",
                          created_at: this.changeDate(data.created_at),
                      });
                      i++;
                  });
                  return res;
              })
              .then((res) => {
                  // Map history RF05
                  let i = 1;
                  res.deviationReport.forEach((data) => {
                      this.itemListRF05.push({
                          item: res,
                          number: i,
                          id: data.id,
                          nameResearch: data.researchName,
                          groupID: data.group_id + 1,
                          version: data.version,
                          status: data.status,
                          no: data.initialNumber ? data.initialNumber : "-",
                          isOld: data.isOld,
                          edit: "RF05",
                          created_at: this.changeDate(data.created_at),
                      });
                      i++;
                  });
                  return res;
              })
              .then((res) => {
                  // Map history RF03
                  let i = 1;
                  res.progressReport.forEach((data) => {
                      this.itemListRF03.push({
                          item: res,
                          number: i,
                          id: data.id,
                          nameResearch: data.researchName,
                          groupID: data.group_id + 1,
                          version: data.version,
                          status: data.status,
                          no: data.initialNumber ? data.initialNumber : "-",
                          isOld: data.isOld,
                          edit: "RF03",
                          created_at: this.changeDate(data.created_at),
                      });
                      i++;
                  });
                  return res;
              })
              .then((res) => {
                  // Map history RF06
                  let i = 1;
                  res.researchSummarize.forEach((data) => {
                      this.itemListRF06.push({
                          item: res,
                          number: i,
                          id: data.id,
                          nameResearch: data.researchName,
                          groupID: data.group_id + 1,
                          version: data.version,
                          status: data.status,
                          no: data.initialNumber ? data.initialNumber : "-",
                          isOld: data.isOld,
                          edit: "RF06",
                          created_at: this.changeDate(data.created_at),
                      });
                      i++;
                  });
                  return res;
              })
              .then((res) => {
                  // Map history RF07
                  let i = 1;
                  res.researchTermination.forEach((data) => {
                      this.itemListRF07.push({
                          item: res,
                          number: i,
                          id: data.id,
                          nameResearch: data.researchName,
                          groupID: data.group_id + 1,
                          version: data.version,
                          status: data.status,
                          no: data.initialNumber ? data.initialNumber : "-",
                          isOld: data.isOld,
                          edit: "RF07",
                          created_at: this.changeDate(data.created_at),
                      });
                      i++;
                  });
                  return res;
              })
              .catch((e) => {
                  this.isLoading = false;
              })
              .then((res) => {
                  // Map history RF07
                  let i = 1;
                  res.correspondence.forEach((data) => {
                      this.itemListCorres.push({
                          item: data,
                          number: i,
                          id: data.id,
                          researchName: data.researchName,
                          corresName: data.name,
                          groupID: data.group_id + 1,
                          status: data.status,
                          no: data.initialNumber ? data.initialNumber : "-",
                          isOld: data.isOld,
                          filePath: data.file,
                          edit: "Corres",
                          created_at: this.changeDate(data.created_at),
                      });
                      i++;
                  });
                  return res;
              })
              .catch((e) => {
                  this.isLoading = false;
              })
              .then((res) => {
                  // Map history Susar
                  let i = 1;
                  res.susar.forEach((data) => {
                    console.log("data Susar: ", data);
                      this.itemListSusar.push({
                          item: data,
                          number: i,
                          id: data.id,
                          nameResearch: data.researchName,
                          groupID: data.group_id + 1,
                          version: data.version ? data.version: "-",
                          status: data.status,
                          no: data.initialNumber ? data.initialNumber : "-",
                          isOld: data.isOld,
                          edit: "Susar",
                          created_at: this.changeDate(data.created_at),
                         
                      });
                      i++;
                  });
                  return res;
              })
              .catch((e) => {
                  this.isLoading = false;
              });
      },
      viewCorresFile(item) {
          this.viewFileDialog = true;
          this.corresOtherFile = item.otherFile;
          this.corresFile.file = item.file;
          this.corresFile.title = item.name;
      },
  }
};
</script>
