<template>
    <v-content>
        <v-layout pa-12 style="background-color: #FAF5F2" class="align-center">

            <v-flex xs12 pl-12 style="text-align: left;">
                <v-layout mt-4>
                    <v-flex>
                        <span style="color: #3E7662;font-size: 36px;font-weight: bolder">
                            วิสัยทัศน์ (Vision)
                        </span>
                    </v-flex>
                </v-layout>
                <v-layout mb-6>
                    <v-flex style="font-size: 18px;font-weight: bold">
                        <span> National Leading IRB with excellent quality operating systems and smart e-office</span>
                    </v-flex>
                </v-layout>
                <div>
                    <span style="color: #3E7662;font-size: 36px;font-weight: bolder">
                        พันธกิจ (Mission)
                    </span>
                </div>
                <v-layout>
                    <v-flex style="font-size: 18px;font-weight: bold">
                        <span>Uphold the high-quality international standards for the ethical review to protect right safety and well-being of human research subjects and community</span>
                    </v-flex>
                </v-layout>
            </v-flex>

            <v-flex xs12  pl-12 style="text-align: left;padding-top: 110px">
                <v-layout>
                    <v-flex style="text-align: center;color: #3E7662;font-weight: bold;cursor: pointer">
                        <img src="../assets/about1.png" alt="" style="max-width: 100%" width="483px" height="323px">
                    </v-flex>
                </v-layout>
                <v-layout>
                    <v-flex py-3 style="text-align: center;color: #3E7662;font-weight: bold">
                        <v-layout justify-center>
                            <v-flex xs3 py-6>
                                <v-divider style="background-color: #3E7662;border-width: 2px"></v-divider>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-flex>

        </v-layout>

        <loading
                color="#527281"
                :active.sync="isLoading"
                :can-cancel="false"
                :is-full-page="fullPage"
        ></loading>

    </v-content>
</template>

<script>
    import Loading from 'vue-loading-overlay';

    export default {
        name: 'Homepage',
        components: {
            Loading
        },
        data: () => ({
            isLoading: false,
            fullPage: true,
            news1:{},
            news2:{},
            lastMonthMeeting: [],
            thisMonthMeeting: [],
        }),
        created() {
            this.getNews();
            this.getLastMonthMeeting();
            this.getThisMonthMeeting();
        },
        methods: {
            downloadForm(name) {
                switch (name) {
                    case 'rf01' : window.open('http://www.irbrta.pmk.ac.th/images/RF_other/RF%2001_2560.doc');
                        break;
                    case 'rf08' : window.open('http://www.irbrta.pmk.ac.th/images/RF_other/RF%2008_2560.doc');
                        break;
                    case 'rf09_1' : window.open('http://www.irbrta.pmk.ac.th/images/RF_other/RF%2009_1_2560_new.doc');
                        break;
                    case 'rf10' : window.open('http://www.irbrta.pmk.ac.th/images/RF_other/RF%2010_2560.doc');
                        break;
                    case 'ro01_1.1' : window.open('http://www.irbrta.pmk.ac.th/images/RO_other/RO%2001_1.1_2560.doc');
                        break;
                    case 'ro01_2' : window.open('http://www.irbrta.pmk.ac.th/images/RO_other/RO%2001_2_2560.doc');
                        break;
                    case 'บันทึกข้อความจากกองหรือหน่วย' : window.open('#');
                        break;
                    case 'orther' : window.open('http://www.irbrta.pmk.ac.th/index.php/from-download/entire-form');
                        break;
                }
            },
            getNews(){
                this.loading = true;
                this.$store.dispatch('Common/getNews').then(res => {
                    this.news1 = res[0];
                    this.news2 = res[1];
                })
                this.loading = false;
            },
            getLastMonthMeeting(){
                this.loading = true;
                this.$store.dispatch('Common/getLastMonthMeeting').then(res => {
                    this.lastMonthMeeting = res;
                });
                this.loading = false;
            },
            getThisMonthMeeting(){
                this.loading = true;
                this.$store.dispatch('Common/getThisMonthMeeting').then(res => {
                    this.thisMonthMeeting = res;
                });
                this.loading = false;
            },
            readNews(id){
                this.$router.push({name:'ReadNews', query:{news_id: id}})
            },
            openResult(val) {
                window.open(val)
            }
        }
    }
</script>

<style lang="scss">
    .contect-hompage{
        background-color: #ffffff;
        padding-left: 150px;
        padding-right: 150px;
        padding-top: 100px;
        padding-bottom: 100px
    }
    .link-result {
        cursor: pointer;
    }
    .link-result:hover {
        color: #42b983;
    }
</style>
