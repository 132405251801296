import Service from "../../services"

const Auth = {
    namespaced: true,
    state: {
        registerToken: null,
        checkLogin: 0,
        accessToken: null,
        user: [],
        isOnlineExpand: null
    },
    mutations: {
        SET_TOKEN: (state, data) => {
            state.accessToken = data.access_token;
            state.user = data.user;
            state.checkLogin = 1;
        },
        SET_TOKEN_REGISTER: (state, token) => {
            state.registerToken = token;
        },
        SET_USER: (state, data) => {
            state.user = data;
        },
        RESET_STATE: (state) => {
            state.user = [];
            state.accessToken = null;
            state.checkLogin = 0;
        },
        Expand_STATE: (state) => {
            state.isOnlineExpand = 0;
        },
    },
    actions: {
        login({commit}, data) {
            return Service.Auth.login(data).then(data => {
                    commit("SET_TOKEN", data);
                    return data;
                }).catch(err => {
                    return Promise.reject(err)
                })
        },
        forceLogin({commit}, data) {
            return Service.Auth.forceLogin(data).then(data => {
                    commit("SET_TOKEN", data);
                    return data;
                }).catch(err => {
                    return Promise.reject(err)
                })
        },
        resetPassword({commit}, data) {
            return Service.Auth.resetPassword(data).then(data => {
                    return data;
                })
        },
        register({ commit },data) {
           return Service.Auth.register(data).then(res => {
               commit('SET_TOKEN_REGISTER', res.access_token)
           }).catch(err => {
               return Promise.reject(err)
           })
        },
        logout({commit}) {
            commit("RESET_STATE");
        },
        getUser({ commit }) {
            return Service.Auth.getUser().then(res => {
                commit("SET_USER", res);
                return res;
            })
        },
        changePassword({ commit }, data) {
            return Service.Auth.changePassword(data.id, data).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        changePasswordByEmail({ commit }, data) {
            return Service.Auth.changePasswordByEmail(data).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        update({ commit }, data) {
            return Service.Auth.update(data).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        }
    }
}

export default Auth;
