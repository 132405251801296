<template>
    <v-content>
        <v-layout pa-12 class="body" >
            <v-flex>
                <v-layout pb-12
                          style="color: #3E7662">
                    <v-flex
                            style="font-weight: bold;font-size: 36px;line-height: 54px;">
                        <span>ทำแบบทดสอบ</span>
                    </v-flex>
                </v-layout>

                <v-layout justify-space-between  mx-12 pa-12 style="background-color: #3E7662;font-size: 24px;line-height: 36px;font-weight: bold;">
                    <v-flex d-flex justify-start ml-3>
                        <span>บทที่ {{this.$route.query.chapter_id}} ชื่อ {{chapterName}}</span>
                    </v-flex>
                    <v-flex d-flex justify-end mr-3>
                        <span>จำนวน 4 ข้อ</span>
                    </v-flex>
                </v-layout>
                <!--question-->
                <v-form>
                <v-content v-for="(item,i) in question" :key="i" style="background-color: white;margin-left: 48px;margin-right: 48px;color: #544A44;margin-bottom: 48px">
                    <v-layout mx-12 mt-8 mb-1 style="background: #FAF5F2;">
                        <v-flex  mx-5 style="text-align: left">
                            <span style="font-size: 24px;line-height: 36px;font-weight: bold;">
                                ข้อที่ {{item.question_number}}<br/>
                            </span>
                            <span style="font-size: 18px;line-height: 27px;">
                                คำถาม {{item.question}}
                            </span>
                        </v-flex>
                    </v-layout>
                    <!--choice-->
                        <v-layout mx-12 mb-12 style="background: #FAFAFA;">
                            <v-flex style="font-size: 18px;line-height: 27px;text-align: left;margin-left: 96px;font-weight: bold;">
                                <v-radio-group v-model="radioGroup[i]">
                                    <v-radio
                                            v-for="(choiceitem,index) in item.choice"
                                            :key="index"
                                            :label="`${choiceitem.choice}`"
                                            :value="choiceitem"
                                    ></v-radio>
                                </v-radio-group>
                            </v-flex>
                        </v-layout>
                </v-content>
                <v-layout mr-12>
                    <v-flex justify-end d-flex >
                        <v-btn x-large color="white" style="margin-right: 24px" @click="back()">
                            <span style="color: #3E7662;font-weight: bold">ย้อนกลับ</span>
                        </v-btn>
                        <v-btn x-large type-submit color="#3E7662" dark style="font-weight: bold" @click="sendAnswer()">
                            <span>บันทึก</span>
                        </v-btn>
                    </v-flex>
                </v-layout>
                    </v-form>

            </v-flex>
        </v-layout>
    </v-content>
</template>

<script>
    export default {
        name: "chapter-exam",
        components: {
        },
        data:() => ({
            isLoading: false,
            fullPage: true,
            question:[],
            radioGroup:[],
            chapterName:""
        }),
        created() {
            const chapter_id = this.$route.query.chapter_id;
            this.getExamById(chapter_id);
            this.getQuestion(chapter_id);
            this.getChapterName(chapter_id);
        },
        methods: {
            getExamById(id) {
            },
            getQuestion(id) {
                this.isLoading = true;
                this.$store.dispatch('Exam/getQuestion',id).then(res => {
                    res.QuestionList.forEach(item => {
                        this.question.push({
                            chapterID: item.chapterID,
                            question_id: item.id,
                            question_number: item.questionNumber,
                            question: item.question,
                            choice: item.test_choice,
                        })
                    })
                    this.isLoading = false;
                })
            },
            sendAnswer(){
                this.isLoading = true;
                let data = {
                  dataChoice: this.radioGroup,
                  chapterID: this.$route.query.chapter_id
                }
                this.$store.dispatch('Exam/sendAnswer',data).then(res =>{
                    this.isLoading = false;
                    this.$router.replace('/test-exam')
                })
            },
            getChapterName(id){
                this.isLoading = true;
                this.$store.dispatch('Exam/getChapterName',id).then(res => {
                    this.chapterName = res;
                    this.isLoading = false;
                })
            },
            back(){
                this.$router.replace('/test-exam')
            }
        }
    }
</script>

<style scoped>
    .body{
        background: #FAF5F2;
        color:white;
        font-size: 36px;
        font-weight: normal;
        text-align: center;
        line-height: 54px;
    }
</style>
