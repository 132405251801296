<template>
    <v-content>
        <v-layout pa-12 style="background-color: #FAF5F2">
            <v-flex xs12 pt-12 pl-12 style="text-align: left;">
                <v-layout>
                    <span style="color: #3E7662;font-size: 36px;font-weight: bolder">
                        ประวัติความเป็นมา
                    </span>
                </v-layout>
                <v-layout mt-4>
                    <v-flex style="font-size: 18px;font-weight: bold">
                        <span>การวิจัยในยุคใหม่ของแวดวงวิชาการทางการแพทย์และการสาธารณสุขนั้น
มีความจำเป็นอย่างยิ่งที่ต้องได้รับการรับรองทางด้านจริยธรรมการวิจัยในคน
โดยเฉพาะกรมแพทย์ทหารบก ซึ่งเป็นสถาบันหลักทางการแพทย์ของกองทัพบก
มีสถาบันและหน่วยต่างๆ ที่ต้องบริหารให้นโยบาย และกำกับดูแลกิจการต่างๆ
โดยเฉพาะการบริการทางการแพทย์และการพัฒนาบุคลากรให้มีศักยภาพความเข้มแข็ง
ทางวิชาการและการวิจัยให้สู่ความเป็นเลิศ   โดยมีสถาบันและหน่วยต่างๆที่สำคัญ
ในด้านวิชาการและการวิจัย</span>
                    </v-flex>
                </v-layout>
                <v-layout mt-6>
                    <v-flex style="font-size: 18px;font-weight: bold">
                        <span>
ได้แก่ โรงพยาบาลพระมงกุฎเกล้า วิทยาลัยแพทยศาสตร์พระมงกุฎเกล้า
วิทยาลัยพยาบาลกองทัพบก สถาบันวิจัยวิทยศาสตร์การแพทย์ทหารกรมแพทย์ทหารบก
สถาบันพยาธิวิทยา โรงเรียนเสนารักษ์กรมแพทย์ทหารบก
และโรงพยาบาลในสังกัดส่วนภูมิภาค 36 แห่ง</span>
                    </v-flex>
                </v-layout>
                <v-layout mt-6>
                    <v-flex style="font-size: 18px;color: #3E7662">
                        <span>สำหรับ <span style="font-weight: bolder">”การวิจัย”</span> หากย้อนรอยขึ้นไปก็พบหลักฐานในการแต่งตั้งคณะกรรมการ
เกี่ยวกับการพิจารณาโครงการวิจัยในคน และคณะกรรมการพิจารณาจริยธรรม
การวิจัยในคนเพื่อทำหน้าที่ในการพิจารณาระเบียบวิธีวิจัย จริยธรรมการวิจัย
ความเหมาะสมและความ เป็นไปได้ในการทำวิจัย และสนับสนุนทุนการวิจัย</span>
                    </v-flex>
                </v-layout>
            </v-flex>

            <v-flex xs12  pl-12 style="text-align: left;padding-top: 110px">
                <v-layout>
                    <v-flex style="text-align: center;color: #3E7662;font-weight: bold;cursor: pointer">
                        <img src="../assets/about1.png" alt="" style="max-width: 100%" width="483px" height="323px">
                    </v-flex>
                </v-layout>
                <v-layout>
                    <v-flex py-3 style="text-align: center;color: #3E7662;font-weight: bold">
                        <v-layout justify-center>
                            <v-flex xs3 py-6>
                                <v-divider style="background-color: #3E7662;border-width: 2px"></v-divider>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>
                <!--                <v-layout>-->
                <!--                    <v-flex style="text-align: center">-->
                <!--                        <v-btn tile color="#5FA88C" depressed min-width="10" class="px-2">-->
                <!--                            <v-icon color="#3E7662">chevron_left</v-icon>-->
                <!--                        </v-btn>-->
                <!--                        <v-btn tile color="#3E7662" depressed min-width="10" class="px-2">-->
                <!--                            <v-icon color="#5FA88C">chevron_right</v-icon>-->
                <!--                        </v-btn>-->
                <!--                    </v-flex>-->
                <!--                </v-layout>-->
            </v-flex>


        </v-layout>
        <div style="padding-top:50px;background-color:#FAF5F2 ">
        <v-layout mt-n10 style="position: absolute;padding-left: 100px">
            <v-flex xs7>
                <v-layout class="">
                    <v-flex>
                        <img src="../assets/about2.png" alt=""
                             style=""
                             width="564px"
                             height="423px">
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex xs5>
            </v-flex>
        </v-layout>
        <v-layout py-12 style="background-color: #544A44;height: 500px;padding-right: 100px">
            <v-flex xs7></v-flex>
            <v-flex xs5>
                <span style="color: white">
                และต่อมาในปี พ.ศ.2539 เพื่อให้ภารกิจด้านการวิจัยมีความเจริญก้าวหน้า
                เหมาะสมและสอดคล้องกับนโยบายการวิจัยของกองทัพบก
                การส่งเสริมสนับสนุนการวิจัย มีการพัฒนาอย่างต่อเนื่องมีประสิทธิภาพ
                และสามารถใช้ทรัพยากรการวิจัยให้เกิดประโยชน์สูงสุด
                พลโทสุจินต์  อุบลวัตร  เจ้ากรมแพทย์ทหารบกในขณะนั้นได้แต่งตั้ง
                คณะกรรมการวิจัยกรมแพทย์ทหารบก คณะอนุกรรมการนโยบายการวิจัย
                คณะอนุกรรมการนโยบายการวิจัย คณะอนุกรรมการพิจารณาโครงการวิจัย
                และคณะอนุกรรมการส่งเสริมและประสานงานวิจัย
                เมื่อวันที่ 31 กรกฎาคม 2539 ดังนั้นชื่อของคณะอนุกรรมการพิจารณา
                โครงการวิจัย จึงเป็นชื่อที่ใช้ตั้งแต่นั้นมาจนถึงปัจจุบัน
                </span>
            </v-flex>
        </v-layout>
        </div>
        <v-layout px-12 pt-12>
            <v-flex>
                <v-layout justify-center="">
                    <v-flex xs8 md10 sm12 px-12 style="text-align: left;color: #3E7662;font-size: 18px;font-weight: bold">
                        <p>คณะอนุกรรมการพิจารณาโครงการวิจัย กรมแพทย์ทหารบก  จึงได้มีการพัฒนาปรับปรุงหลักเกณฑ์การดำเนินการพิจารณาโครงการวิจัยให้ได้มาตรฐาน
                            ตามหลักสากล เหมาะสมกับสถาบัน และตระหนักถึงจริยธรรมการวิจัยที่เกี่ยวข้องกับมนุษย์ โดยนำหลักเกณฑ์ของ
                            World Medical  Association : DELCARATION OF HELSINKI ,  GUDELINE FOR GOOD CLINICAL PRACTICE : ICH  Harmonised Tripartite Guideline,
                            Council for International Organizations of Medical Sciences (CIOMS) , CODE  of FEDERAL REGULATIONS : Title 45 Public Welfare;
                            Part 46 Protection of Human Subjects, The Belmont Report   และแนวทางจริยธรรมการทำวิจัยในคนแห่งชาติ
                            มาประยุกต์ใช้ในการพิจารณาโครงการวิจัย โดยต้องมีความเคารพในบุคคล (respect for person)  มีคุณประโยชน์และไม่ก่ออันตราย (Beneficence)
                            และความยุติธรรม (Justice)
                        </p>
                    </v-flex>
                </v-layout>
                <v-layout justify-center>
                    <v-flex xs3 pt-3 py-12 pt-12>
                        <!--<v-divider style="background-color: #3E7662;border-width: 2px"></v-divider>-->
                    </v-flex>
                </v-layout>

                <v-layout pb-12 justify-center>
                    <v-flex xs8 md10 sm12>
                        <v-layout>
                            <v-flex xs6 px-12 style="text-align: left">
                                <v-layout>
                                    <v-flex>
                                        <v-layout >
                                            <v-flex mb-2 style="text-align: center">
                                                <img
                                                        src="../assets/about3.png" alt=""
                                                        width="509px"
                                                        height="339px"
                                                >
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                                <v-layout mt-3 justify-center>
                                    <v-flex pt-3 xs8>
                                        <v-divider style="background-color: #3E7662;border-width: 1px"></v-divider>
                                    </v-flex>
                                </v-layout>
                            </v-flex>

                            <v-flex xs6 px-12 style="text-align: left">
                                <v-layout >
                                    <v-flex>
                                        <v-layout >
                                            <v-flex mb-2 style="text-align: center">
                                                <img
                                                        src="../assets/about4.png" alt=""
                                                        width="509px"
                                                        height="339px"
                                                >
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                                <v-layout mt-3 justify-center>
                                    <v-flex pt-3 xs8>
                                        <v-divider style="background-color: #3E7662;border-width: 1px"></v-divider>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>

        <v-layout style="margin-left: 100px;margin-right: 100px">
            <v-flex>
                <span style="font-size: 18px;font-weight: bold">
                    พ.ศ.2545 กรมแพทย์ทหารบกได้อนุมัติให้จัดตั้งสำนักงานพิจารณาโครงการวิจัย กรมแพทย์ทหารบก  ซึ่งตั้งอยู่ ณ ชั้น 5 อาคารพระมงกุฎเกล้าเวชวิทยา วิทยาลัยแพทยศาสตร์พระมงกุฎเกล้า และอนุมัติให้คณะอนุกรรมการใช้ ”การดำเนินการมาตรฐาน  พ.ศ.2545”
เพื่อใช้เป็นหลักเกณฑ์สำคัญและแนวทางปฏิบัติของคณะอนุกรรมการที่เป็นมาตรฐานในการแต่งตั้งกำหนดโครงสร้างและหน้าที่ เงื่อนไขต่างๆ ตลอดจนแนวทางในการยื่นโครงร่างการวิจัยเพื่อพิจารณาโดยเฉพาะการวิจัยและการทดลองในมนุษย์เป็นฉบับแรก และปัจจุบันคือฉบับที่ 5 (2551)
                </span>
            </v-flex>
        </v-layout>
        <v-layout mt-6 style="margin-left: 100px;margin-right: 100px">
            <v-flex>
                <span style="font-size: 18px;font-weight: bold">
พ.ศ.2548 กรมแพทย์ทหารบกได้อนุมัติแต่งตั้งคณะอนุกรรมการพิจารณาโครงการวิจัยเสริม (Alternate member)
                </span>
            </v-flex>
        </v-layout>
        <v-layout style="margin-left: 100px;margin-right: 100px;margin-bottom: 48px">
            <v-flex>
                <span style="font-size: 18px;font-weight: bold">
พ.ศ.2551 กรมแพทย์ทหารบกได้อนุมัติแต่งตั้งที่ปรึกษาอิสระสำหรับการพิจารณาโครงร่างการวิจัย ซึ่งเป็นข้าราชการในสังกัดกรมแพทย์ทหารบก
                </span>
            </v-flex>
        </v-layout>

        <v-layout px-12 style="background-color: #FAF5F2">
            <v-flex xs12 pa-12 style="text-align: left">
                <v-layout style="text-align: center">
                    <v-flex style="font-size: 40px;font-weight: bolder">
                        คณะอนุกรรมการ และสำนักงานได้การรับรองคุณภาพและรางวัล
                    </v-flex>
                </v-layout>

                <v-layout justify-center>
                    <v-flex xs8 md10 sm12 mt-12>
                        <v-layout>
                            <v-flex xs12 style="margin-left: 120px;margin-right: 120px" class="d-flex align-center">
                                พ.ศ.2548  ได้การรับรองคุณภาพจากThe Strategic Initiative for Developing Capacity in Ethical Review & Forum for Ethical Review
                                Committees in Asia and the Western Pacific
                            </v-flex>
                        </v-layout>
                        <v-layout>
                            <v-flex style="margin-left: 100px;margin-right: 100px" py-3>
                                <v-divider style="background-color: white;border-width: 1px;border-color: white"></v-divider>
                            </v-flex>
                        </v-layout>
                        <v-layout>
                            <v-flex xs12 style="margin-left: 120px;margin-right: 120px" class="d-flex align-center">
                                พ.ศ.2552  ได้การรับรองคุณภาพต่อเนื่องครั้งที่ 1  จากThe Strategic Initiative for Developing Capacity in Ethical Review & Forum for
                                Ethical Review Committees in Asia and the Western Pacific
                            </v-flex>
                        </v-layout>
                        <v-layout>
                            <v-flex style="margin-left: 100px;margin-right: 100px" py-3>
                                <v-divider style="background-color: white;border-width: 1px;border-color: white"></v-divider>
                            </v-flex>
                        </v-layout>
                        <v-layout>
                            <v-flex xs12 style="margin-left: 120px;margin-right: 120px" class="d-flex align-center">
                                พ.ศ.2553  ได้รับรางวัล  PReMA’s TOP IRB 2010
                            </v-flex>
                        </v-layout>
                        <v-layout>
                            <v-flex style="margin-left: 100px;margin-right: 100px" py-3>
                                <v-divider style="background-color: white;border-width: 1px;border-color: white"></v-divider>
                            </v-flex>
                        </v-layout>
                        <v-layout>
                            <v-flex xs12 style="margin-left: 120px;margin-right: 120px" class="d-flex align-center">
                                พ.ศ.2555  ได้การรับรองคุณภาพต่อเนื่องครั้งที่ 2  จากThe Strategic Initiative for Developing Capacity in Ethical Review & Forum for
                                Ethical Review Committees in Asia and the Western Pacific
                            </v-flex>
                        </v-layout>
                        <v-layout>
                            <v-flex style="margin-left: 100px;margin-right: 100px" py-3>
                                <v-divider style="background-color: white;border-width: 1px;border-color: white"></v-divider>
                            </v-flex>
                        </v-layout>
                        <v-layout>
                            <v-flex xs12 style="margin-left: 120px;margin-right: 120px" class="d-flex align-center">
                                พ.ศ.2559  ได้การรับรองคุณภาพต่อเนื่องครั้งที่ 3  จากThe Strategic Initiative for Developing Capacity in Ethical Review & Forum for
                                Ethical Review Committees in Asia and the Western Pacific
                            </v-flex>
                        </v-layout>
                        <v-layout>
                            <v-flex py-3 style="margin-left: 100px;margin-right: 100px">
                                <v-divider style="background-color: white;border-width: 1px;border-color: white"></v-divider>
                            </v-flex>
                        </v-layout>
                        <v-layout>
                            <v-flex xs12 style="margin-left: 120px;margin-right: 120px" class="d-flex align-center">
                                พ.ศ.2560 ได้รับรางวัล Thai TECT’S outstanding EC/IRB
                            </v-flex>
                        </v-layout>
                        <v-layout>
                            <v-flex style="margin-left: 100px;margin-right: 100px" py-3>
                                <v-divider style="background-color: white;border-width: 1px;border-color: white"></v-divider>
                            </v-flex>
                        </v-layout>
                        <v-layout>
                            <v-flex xs12 style="margin-left: 120px;margin-right: 120px" class="d-flex align-center">
                                พ.ศ.2562 ได้รับรางวัล TECT's EC/IRB Award
                            </v-flex>
                        </v-layout>
                        <v-layout>
                            <v-flex py-3 style="margin-left: 100px;margin-right: 100px">
                                <v-divider style="background-color: white;border-width: 1px;border-color: white"></v-divider>
                            </v-flex>
                        </v-layout>

                        <v-layout>
                            <v-flex py-3 mt-12 style="margin-left: 100px;margin-right: 100px"  >
                                <v-divider style="background-color: #3E7662;border-width: 2px"></v-divider>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>

        <loading
                color="#527281"
                :active.sync="isLoading"
                :can-cancel="false"
                :is-full-page="fullPage"
        ></loading>

    </v-content>
</template>

<script>
    import Loading from 'vue-loading-overlay';

    export default {
        name: 'Homepage',
        components: {
            Loading
        },
        data: () => ({
            isLoading: false,
            fullPage: true,
            news1:{},
            news2:{},
            lastMonthMeeting: [],
            thisMonthMeeting: [],
        }),
        created() {
            this.getNews();
            this.getLastMonthMeeting();
            this.getThisMonthMeeting();
        },
        methods: {
            downloadForm(name) {
                switch (name) {
                    case 'rf01' : window.open('http://www.irbrta.pmk.ac.th/images/RF_other/RF%2001_2560.doc');
                        break;
                    case 'rf08' : window.open('http://www.irbrta.pmk.ac.th/images/RF_other/RF%2008_2560.doc');
                        break;
                    case 'rf09_1' : window.open('http://www.irbrta.pmk.ac.th/images/RF_other/RF%2009_1_2560_new.doc');
                        break;
                    case 'rf10' : window.open('http://www.irbrta.pmk.ac.th/images/RF_other/RF%2010_2560.doc');
                        break;
                    case 'ro01_1.1' : window.open('http://www.irbrta.pmk.ac.th/images/RO_other/RO%2001_1.1_2560.doc');
                        break;
                    case 'ro01_2' : window.open('http://www.irbrta.pmk.ac.th/images/RO_other/RO%2001_2_2560.doc');
                        break;
                    case 'บันทึกข้อความจากกองหรือหน่วย' : window.open('#');
                        break;
                    case 'orther' : window.open('http://www.irbrta.pmk.ac.th/index.php/from-download/entire-form');
                        break;
                }
            },
            getNews(){
                this.loading = true;
                this.$store.dispatch('Common/getNews').then(res => {
                    this.news1 = res[0];
                    this.news2 = res[1];
                })
                this.loading = false;
            },
            getLastMonthMeeting(){
                this.loading = true;
                this.$store.dispatch('Common/getLastMonthMeeting').then(res => {
                    this.lastMonthMeeting = res;
                });
                this.loading = false;
            },
            getThisMonthMeeting(){
                this.loading = true;
                this.$store.dispatch('Common/getThisMonthMeeting').then(res => {
                    this.thisMonthMeeting = res;
                });
                this.loading = false;
            },
            readNews(id){
                this.$router.push({name:'ReadNews', query:{news_id: id}})
            },
            openResult(val) {
                window.open(val)
            }
        }
    }
</script>

<style lang="scss">
    .contect-hompage{
        background-color: #ffffff;
        padding-left: 150px;
        padding-right: 150px;
        padding-top: 100px;
        padding-bottom: 100px
    }
    .link-result {
        cursor: pointer;
    }
    .link-result:hover {
        color: #42b983;
    }
</style>
