<template>
  <v-content id="container">
    <!-- Header -->
      <Header id="header"></Header>
    <!-- End-Header -->

    <!-- Menu Header -->
      <menu-header></menu-header>
    <!-- End-Menu Header -->

    <!-- Detail -->
      <template id="body">
        <v-layout justify-center style="background-color: #EFEAE7;">
          <v-flex xs11>
            <v-card style="box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.3);">
              <router-view/>
            </v-card>
          </v-flex>
        </v-layout>
      </template>
    <!-- End-Detail -->
    <!-- Footer -->
      <Footer id="footer"></Footer>
    <!-- End-Footer -->

  </v-content>
</template>

<script>
import Header from '../components/Header'
import Footer from '../components/Footer'
import MenuHeader from "../components/MenuHeader";

export default {
  name: 'Mainlayout',
  components: {
    Header,
    Footer,
    MenuHeader
  }
}
</script>

<style>

.bottom-right-icon {
  position: absolute;
  bottom: 100px;
  right: 20px;
  text-align: center;
}
</style>
