<template>
    <v-content>
        <v-layout>
            <v-flex style="background-color: #EFEAE7;padding: 2% 5% 2% 5%">
                <v-card>
                    <v-layout pa-8 justify-center style="background-color: #3E7662;">
                        <v-flex style="text-align: left;">
                            <span style="font-weight: bold;font-size: 28px;color: white;">แบบประวัติผู้วิจัย</span>
                        </v-flex>
                        <v-flex style="text-align: right;">
                            <span style="font-size: 14px;color: white;">Version……….Date…………………….</span><br>
                            <span style="font-size: 14px;color: white;">RF 10_2560</span>
                        </v-flex>
                    </v-layout>

                    <v-layout justify-center>
                        <v-flex px-12 py-6>
                            <!-- Choice 20 -->
                            <v-layout>
                                <v-flex xs6 pb-6 style="text-align: left;">
                                    <span style="font-weight: bold;font-size: 16px;">20. เอกสารอ้างอิง (Vancouver style)</span>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex xs6 pb-2 pl-8 style="text-align: left;">
                                    <span style="font-weight: bold;font-size: 16px;color: #3E7662">ควรเขียนตามมาตรฐานการเขียนเอกสารอ้างอิง แบบ Vancouver</span>
                                </v-flex>
                            </v-layout>
                            <TextareaForm
                                    :model="form.reference"
                                    @update-data="updateData('reference', $event)"
                            ></TextareaForm>

                            <!-- Choice 21 -->
                            <v-layout>
                                <v-flex xs6 pb-6 style="text-align: left;">
                                    <span style="font-weight: bold;font-size: 16px;">21. ภาคผนวก</span>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex xs6 mb-6 pb-2 pl-8 style="text-align: left;">
                                    <span style="font-weight: bold;font-size: 16px;color: #3E7662">เอกสารที่เกี่ยวข้อง ซึ่งคิดว่าอาจจะเป็นประโยชน์ในการพิจารณาโครงการวิจัย</span>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-spacer></v-spacer>
                                <v-flex xs4>
                                    <v-file-input
                                            prepend-icon=""
                                            id="related_documents"
                                            :model="form.related_documents"
                                            outlined
                                            dense
                                            background-color="#FBF6F3"
                                            color="#3E7662"
                                            item-color="#3E7662"
                                            @change="uploadRelatedDocuments"
                                    ></v-file-input>
                                </v-flex>
                                <v-flex shrink pl-4 style="text-align: left">
                                    <v-btn color="#3E7662" dark @click="uploadFile('related_documents')">
                                        <v-icon class="pr-3">cloud_download</v-icon>
                                        <span>Upload</span>
                                    </v-btn>
                                </v-flex>
                                <v-spacer></v-spacer>
                            </v-layout>
                            <hr>
                            <v-layout>
                                <v-flex xs6 py-6 pl-8 style="text-align: left;">
                                    <span style="font-weight: bold;font-size: 16px;color: #3E7662">แบบฟอร์มเก็บข้อมูล</span>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-spacer></v-spacer>
                                <v-flex xs4>
                                    <v-file-input
                                            prepend-icon=""
                                            id="data_collection_form"
                                            :model="form.data_collection_form"
                                            outlined
                                            dense
                                            background-color="#FBF6F3"
                                            color="#3E7662"
                                            item-color="#3E7662"
                                            @change="uploadDataCollectionForm"
                                    ></v-file-input>
                                </v-flex>
                                <v-flex shrink pl-4 style="text-align: left">
                                    <v-btn color="#3E7662" dark @click="uploadFile('data_collection_form')">
                                        <v-icon class="pr-3">cloud_download</v-icon>
                                        <span>Upload</span>
                                    </v-btn>
                                </v-flex>
                                <v-spacer></v-spacer>
                            </v-layout>
                            <hr>

                            <v-layout>
                                <v-flex xs6 py-6 pl-8 style="text-align: left;">
                                    <span style="font-weight: bold;font-size: 16px;color: #3E7662">ประวัติผู้วิจัยและผู้ร่วมวิจัย</span>
                                </v-flex>
                            </v-layout>
                            <TextareaForm
                                    :model="form.history"
                                    @update-data="updateData('history', $event)"
                            ></TextareaForm>
                            <hr>

                            <v-layout>
                                <v-flex xs6 py-6 pl-8 style="text-align: left;">
                                    <span style="font-weight: bold;font-size: 16px;color: #3E7662">อื่นๆ ได้แก่ สมุดบันทึก ไดอารี่ โฆษณา แผ่นพับ บัตรประจำตัวอาสาสมัคร เป็นต้น</span>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-spacer></v-spacer>
                                <v-flex xs4>
                                    <v-file-input
                                            prepend-icon=""
                                            id="other"
                                            :model="form.other"
                                            outlined
                                            dense
                                            background-color="#FBF6F3"
                                            color="#3E7662"
                                            item-color="#3E7662"
                                            @change="uploadOther"
                                    ></v-file-input>
                                </v-flex>
                                <v-flex shrink pl-4 style="text-align: left">
                                    <v-btn color="#3E7662" dark @click="uploadFile('other')">
                                        <v-icon class="pr-3">cloud_download</v-icon>
                                        <span>Upload</span>
                                    </v-btn>
                                </v-flex>
                                <v-spacer></v-spacer>
                            </v-layout>
                            <hr>

                            <v-layout>
                                <v-flex xs6 pt-10 style="text-align: left; padding-bottom: 100px;">
                                    <span style="font-weight: bold;font-size: 16px;">22. ใส่ ฉบับที่ (Version) และลงวันที่ (Date)ที่หัวกระดาษทุกแผ่น ใส่ หมายเลขหน้า ที่ท้ายกระดาษ ทุกแผ่น</span>
                                </v-flex>
                            </v-layout>


                            <v-layout pt-12>
                                <v-flex style="text-align: left;">
                                    <span>สำนักงานคณะอนุกรรมการพิจารณาโครงการวิจัย พบ.</span>
                                </v-flex>
                                <v-flex style="text-align: right;">
                                    <span>หน้า 5 จาก 5 หน้า</span>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                </v-card>
            </v-flex>
        </v-layout>

        <v-layout>
            <v-flex mb-12 style="padding: 0 5% 2% 5%; text-align: right;">
                <v-layout>
                    <v-flex>
                        <v-btn style="color: #3E7662" class="mx-4" depressed  color="#ffffff" @click="backPage">ย้อนกลับ</v-btn>
                        <v-btn depressed  dark color="#3E7662" @click="saveResearch">
                            <v-icon class="mr-3">add</v-icon>
                            <span>ยืนยันการบันทึกงานวิจัย</span>
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
        <loading
                color="#527281"
                :active.sync="isLoading"
                :can-cancel="false"
                :is-full-page="fullPage"
        ></loading>
    </v-content>
</template>

<script>
    import TextareaForm from "./input/TextareaForm";
    import Swal from 'sweetalert2';
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import {mapState} from "vuex"

    export default {
        name: "FormCreateResearchPage1",
        components: {
            TextareaForm,
            Loading,
        },
        data: () => ({
            isLoading: false,
            fullPage: true,
            form_api: {},
            form: {
                reference: '',
                related_documents: '',
                data_collection_form: '',
                history: '',
                other: '',
            } ,
        }),
        computed: {
            ...mapState({
                research_id: state => state.FormResearch.research_id,
            })
        },

        methods: {
            updateData(name,val) {
                this.form[name] = val;
            },
            uploadRelatedDocuments(e) {
                this.form.related_documents = e;
            },
            uploadDataCollectionForm(e) {
                this.form.data_collection_form = e;
            },
            uploadOther(e) {
                this.form.other = e;
            },
            uploadFile(name) {
                if (name === 'related_documents') {
                    document.getElementById("related_documents").click();
                } else if (name === 'data_collection_form'){
                    document.getElementById("data_collection_form").click();
                } else {
                    document.getElementById("other").click();
                }
            },
            saveResearch() {
                this.isLoading = true;
                // this.form_api['research_info'] =  [
                //     {subTopicID: 1, answer:this.form.reference},
                //     {subTopicID: 2, answer:this.form.related_documents},
                //     {subTopicID: 3, answer:this.form.data_collection_form},
                //     {subTopicID: 4, answer:this.form.history},
                //     {subTopicID: 5, answer:this.form.other},
                // ]
                this.form['research_id'] = this.research_id;
                this.$store.dispatch("FormResearch/saveResearch4", this.form).then(res => {
                    Swal.fire({
                        title: 'Success',
                        text: 'บันทึกเรียบร้อย',
                        icon: 'success',
                        position: 'center',
                        showConfirmButton: false,
                        confirmButtonColor: '#3E7662',
                        background: '#fffff',
                        timer: 1500
                    })
                    this.isLoading = false;
                }).catch(e => {
                    Swal.fire({
                        title: 'Save failed!',
                        text: 'บันทึกไม่สำเร็จ',
                        icon: 'warning',
                        confirmButtonColor: '#3E7662',
                    }).then(() => {
                        this.isLoading = false;
                    })
                })
            },
            backPage() {
                this.$store.dispatch("FormResearch/changePage", 3)
            },
        },
    }
</script>

<style scoped>

</style>
