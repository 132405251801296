import Service from "../../services"

const Exam = {
    namespaced: true,
    state: {
        chapter: null,
        question: null,
        choice: null,
        answer: null,
        exam: [],
    },
    mutations: {
        FETCH_CHAPTER: (state, data) => {
            state.exam = data;
        },
        SET_TOKEN_REGISTER: (state, token) => {
            state.registerToken = token;
        },
        SET_USER: (state, data) => {
            state.user = data;
        },
        RESET_STATE: (state) => {
            state.user = null;
            state.accessToken = null;
            state.checkLogin = 0;
        },
    },
    actions: {
        getChapter({commit}) {
            return Service.Exam.chapter().then(res => {
                let items = res;
                let data = [];
                items.ChapterList.forEach(item => {
                    data.push({
                        chapter_id: item.id,
                        chapter: item.chapterName,
                        link: item.chapterLink,
                        questionNumber: '4 ข้อ',
                    })
                })
                commit("FETCH_CHAPTER", data);
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        getQuestion({commit},id){
            return Service.Exam.question(id).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        getChoice({commit},id){
            return Service.Exam.choice(id).then(res=>{
                return res;
            }).catch(err=>{
                return Promise.reject(err)
            })
        },
        sendAnswer({commit},data){
            return Service.Exam.answer(data).then(res=>{
                return res;
            }).catch(err=>{
                return Promise.reject(err)
            })
        },
        getPoint({commit}){
            return Service.Exam.pointcalculate().then(res=>{
                return res;
            }).catch(err=>{
                return Promise.reject(err)
            })
        },
        makeCertificate({commit},data){
            return Service.Exam.certificate(data).then(res=>{
                return res;
            }).catch(err=>{
                return Promise.reject(err)
            })
        },
        getToday({commit}){
            return Service.Exam.getToday().then(res=>{
                return res;
            }).catch(err=>{
                return Promise.reject(err)
            })
        },
        getChapterName({commit},id){
            return Service.Exam.getChapterName(id).then(res=>{
                return res;
            }).catch(err=>{
                return Promise.reject(err)
            })
        },
        getCertificate({commit}){
            return Service.Exam.getCertificate().then(res=>{
                return res;
            }).catch(err=> {
                return Promise.reject(err)
            })
        },
    }
}

export default Exam;
