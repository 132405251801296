<template>
    <v-content>
        <v-layout>
            <v-flex style="background-color: #EFEAE7;padding: 2% 5% 2% 5%">
                <v-card>
                    <v-layout pa-8 justify-center style="background-color: #3E7662;">
                        <v-flex style="text-align: left;" >
                            <span style="font-weight: bold;font-size: 28px;color: white;">แบบโครงร่างการวิจัย</span>
                        </v-flex>
                        <v-flex style="text-align: right;">
                            <span style="font-size: 14px;color: white;">Version……….Date…………………….</span><br>
                            <span style="font-size: 14px;color: white;">RF 10_2560</span>
                        </v-flex>
                    </v-layout>

                    <v-layout>
                        <v-flex px-12 py-6>
                            <v-layout>
                                <v-flex xs6 pb-6 style="text-align: left;">
                                    <span style="font-weight: bold;font-size: 16px;">12. วิธีดำเนินการวิจัย</span>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex xs6 pb-2 pl-8 style="text-align: left;">
                                    <span style="font-weight: bold;font-size: 16px;color: #3E7662">1. ประชากรเป้าหมาย</span>
                                </v-flex>
                            </v-layout>
                            <TextareaForm
                                    :model="form.target_population"
                                    @update-data="updateData('target_population', $event)"
                            ></TextareaForm>

                            <v-layout>
                                <v-flex xs6 pb-2 pl-8 style="text-align: left;">
                                    <span style="font-weight: bold;font-size: 16px;color: #3E7662">2. กรรมวิธีการรักษาหรือการดำเนินการวิจัย</span>
                                </v-flex>
                            </v-layout>
                            <TextareaForm
                                    :model="form.conducting_research"
                                    @update-data="updateData('conducting_research', $event)"
                            ></TextareaForm>

                            <v-layout>
                                <v-flex xs6 pb-2 pl-8 style="text-align: left;">
                                    <span style="font-weight: bold;font-size: 16px;color: #3E7662">3. การวัดผลของการวิจัย</span>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex pl-12 pb-2 style="text-align: left;">
                                    <span style="font-weight: bold;font-size: 16px;">เครื่องมือที่ใช้วัด ความน่าเชื่อถือของเครื่องมือ</span>
                                </v-flex>
                            </v-layout>
                            <TextareaForm
                                    :model="form.tool_reliability"
                                    @update-data="updateData('tool_reliability', $event)"
                            ></TextareaForm>

                            <v-layout>
                                <v-flex pl-12 pb-2 style="text-align: left;">
                                    <span style="font-weight: bold;font-size: 16px;">ง. คำจำกัดความ</span>
                                </v-flex>
                            </v-layout>
                            <TextareaForm
                                    :model="form.definitions"
                                    @update-data="updateData('definitions', $event)"
                            ></TextareaForm>

                            <v-layout>
                                <v-flex pb-2 style="text-align: left;">
                                    <span style="font-weight: bold;font-size: 16px;">13. การวัดผลการวิจัย</span>
                                </v-flex>
                            </v-layout>
                            <TextareaForm
                                    :model="form.evaluation_research_results"
                                    @update-data="updateData('evaluation_research_results', $event)"
                            ></TextareaForm>

                            <v-layout>
                                <v-flex pb-2 style="text-align: left;">
                                    <span style="font-weight: bold;font-size: 16px;">14. การเก็บรวบรวมข้อมูล</span>
                                </v-flex>
                            </v-layout>
                            <TextareaForm
                                    :model="form.data_collection"
                                    @update-data="updateData('data_collection', $event)"
                            ></TextareaForm>

                            <v-layout>
                                <v-flex pb-2 style="text-align: left;">
                                    <span style="font-weight: bold;font-size: 16px;">15. การวิเคราะห์ทางสถิติ</span>
                                </v-flex>
                            </v-layout>
                            <TextareaForm
                                    :model="form.statistical_analysis"
                                    @update-data="updateData('statistical_analysis', $event)"
                            ></TextareaForm>

                            <v-layout>
                                <v-flex pb-2 style="text-align: left;">
                                    <span style="font-weight: bold;font-size: 16px;">16. ปัญหาด้านจริยธรรม</span>
                                </v-flex>
                            </v-layout>
                            <TextareaForm
                                    :model="form.ethical_issues"
                                    @update-data="updateData('ethical_issues', $event)"
                            ></TextareaForm>

                            <v-layout>
                                <v-flex pb-2 style="text-align: left;">
                                    <span style="font-weight: bold;font-size: 16px;">17. เพิ่มเติมข้อพิจารณาในเรื่องชุมชน ในกรณีที่ทำการวิจัยในชุมชน (ถ้ามี)</span>
                                </v-flex>
                            </v-layout>
                            <TextareaForm
                                    :model="form.more"
                                    @update-data="updateData('more', $event)"
                            ></TextareaForm>


                            <v-layout pt-12>
                                <v-flex style="text-align: left;">
                                    <span>สำนักงานคณะอนุกรรมการพิจารณาโครงการวิจัย พบ.</span>
                                </v-flex>
                                <v-flex style="text-align: right;">
                                    <span>หน้า 3 จาก 5 หน้า</span>
                                </v-flex>
                            </v-layout>


                        </v-flex>
                    </v-layout>

                </v-card>
            </v-flex>
        </v-layout>

        <v-layout>
            <v-flex mb-12 style="padding: 0 5% 2% 5%; text-align: right;">
                <v-layout>
                    <v-flex>
                        <v-btn style="color: #3E7662" class="mx-4" depressed  color="#ffffff" @click="backPage">ย้อนกลับ</v-btn>
                        <v-btn depressed  dark color="#3E7662" @click="nextPage">
                            <v-icon class="mr-3">add</v-icon>
                            <span>บันทึกและถัดไป</span>
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
        <loading
                color="#527281"
                :active.sync="isLoading"
                :can-cancel="false"
                :is-full-page="fullPage"
        ></loading>
    </v-content>
</template>

<script>
    import TextareaForm from "./input/TextareaForm";
    import Swal from 'sweetalert2';
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import  { mapState } from "vuex"

    export default {
        name: "FormCreateResearchPage1",
        components: {
            TextareaForm,
            Loading,
        },
        computed: {
            ...mapState({
                researchID: state => state.FormResearch.researchID,
            })
        },
        data: () => ({
            isLoading: false,
            fullPage: true,
            form: {
                target_population: '',
                conducting_research: '',
                tool_reliability: '',
                definitions: '',
                evaluation_research_results: '',
                data_collection: '',
                statistical_analysis: '',
                ethical_issues: '',
                more: '',
            } ,
            form_api: {},
        }),
        methods: {
            updateData(name, val) {
                this.form[name] = val;
            },
            nextPage() {
                this.isLoading = true;
                this.form_api['research_info'] =  [
                    {subTopicID: 9,  answer:this.form.target_population},
                    {subTopicID: 10, answer:this.form.conducting_research},
                    {subTopicID: 11, answer:this.form.tool_reliability},
                    {subTopicID: 12, answer:this.form.definitions},
                    {subTopicID: 13, answer:this.form.evaluation_research_results},
                    {subTopicID: 14, answer:this.form.data_collection},
                    {subTopicID: 15, answer:this.form.statistical_analysis},
                    {subTopicID: 16, answer:this.form.ethical_issues},
                    {subTopicID: 17, answer:this.form.more},
                ];
                this.form_api['researchID'] = this.researchID;
                this.$store.dispatch("FormResearch/saveResearch2", this.form_api).then(res => {
                    this.$store.dispatch("FormResearch/changePage", 3)
                    this.isLoading = false;
                }).catch(e => {
                    Swal.fire({
                        title: 'Save failed!',
                        text: 'บันทึกไม่สำเร็จ',
                        icon: 'warning',
                    }).then(() => {
                        this.isLoading = false;
                    })
                })
            },
            backPage() {
                this.$store.dispatch("FormResearch/changePage", 1)
            },
            setData() {
                this.form.target_population = 'ประชากรเป้าหมาย';
                this.form.conducting_research = 'กรรมวิธีการรักษาหรือการดำเนินการวิจัย';
                this.form.tool_reliability = 'เครื่องมือที่ใช้วัด ความน่าเชื่อถือของเครื่องมือ';
                this.form.definitions = 'คำจำกัดความ';
                this.form.evaluation_research_results = 'การวัดผลการวิจัย';
                this.form.data_collection = 'การเก็บรวบรวมข้อมูล';
                this.form.statistical_analysis = 'การวิเคราะห์ทางสถิติ';
                this.form.ethical_issues = 'ปัญหาด้านจริยธรรม';
                this.form.more = 'เพิ่มเติมข้อพิจารณาในเรื่องชุมชน ในกรณีที่ทำการวิจัยในชุมชน (ถ้ามี)'
            }
        },
    }
</script>

<style scoped>

</style>
