<template>
    <v-content>
        <v-layout xs12 px-12 pt-8 pb-4 justify-space-between>
            <v-flex d-flex ml-12 justify-start align-center shrink style="text-align: left;cursor: pointer;" @click="$router.push('/').catch(err => {})">
                <v-layout>
                    <v-flex>
                        <span style="color: #3E7662;font-size:16px;">สำนักงานคณะอนุกรรมการพิจารณาโครงการวิจัย กรมแพทย์ทหารบก</span><br>
                        <span style="color: #3E7662;font-size:22px;line-height:30px"><b>Institutional Review Board</b></span><br>
                        <span style="color: #3E7662;font-size:16px;">Royal Thai Army Medical Department</span>
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex d-flex justify-end align-end mr-12>
                <div @click="$router.push('/')" style="color: #3E7662;font-weight: bold;cursor: pointer;font-size: 22px">
                    <v-icon color="#3E7662">arrow_back</v-icon>
                    <span style="text-decoration: underline">หน้าแรก</span>
                </div>
            </v-flex>
        </v-layout>

        <v-layout justify-center>
            <v-flex style="background-color: #EFEAE7;padding: 2% 10% 2% 10%">
                <v-card class="pa-12">
                    <v-layout justify-center>
                        <v-flex xs12-pa-4 sm10 md8 lg5 style="text-align: center">
                            <v-layout>
                                <v-flex>
                                    <img src="../assets/logo.png" alt="logo login" style="width: 100px;">
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex pt-12 pb-8>
                                    <span style="font-weight: bold;font-size: 24px;color: #3E7662">ระบบยืนยันอีเมล</span>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex class="text-input font-weight-bold">
                                    {{this.responseData}}
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex  mt-12 pr-1 style="text-align: center;">
                                    <v-btn color="#3E7662" dark @click="$router.push('/login')">
                                        <span class="px-4">เข้าสู่ระบบ</span>
                                    </v-btn>
                                </v-flex>
                            </v-layout>
<!--                            <v-layout justify-center>-->
<!--                                <v-flex>-->
<!--                                    <span @click="forgetName" style="font-weight: bold; color: #3E7662;cursor: pointer;">-->
<!--                                        ลืมชื่อสมาชิก?-->
<!--                                    </span>-->
<!--                                </v-flex>-->
<!--                            </v-layout>-->
                        </v-flex>
                    </v-layout>
                </v-card>
            </v-flex>
        </v-layout>
        <loading
                color="#527281"
                :active.sync="isLoading"
                :can-cancel="false"
                :is-full-page="fullPage"
        ></loading>
        <Footer></Footer>
    </v-content>
</template>

<script>
    import Footer from "../components/Footer";
    import Swal from 'sweetalert2';
    import { mapState } from "vuex";
    // import MultiselectForm from "../components/input/MultiselectForm";
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';

    export default {
        name: "Login",
        components: {
            Footer,
            // MultiselectForm,
            Loading,
        },
        data: () => ({
            isLoading: false,
            fullPage: true,
            items: [{name:'ผู้วิจัย/ผู้ร่วมวิจัย'}],
            checkbox: false,
            onError: false,
            loginForm: {
                role: '',
                email: '',
                password: '',
            },
            responseData: 'test',

            //Validate
            rules: {
                required: value => !!value || 'Required.',
                counter: value => value.length <= 20 || 'Max 20 characters',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                },
            },
        }),
        created(){
            const token = this.$route.query.token;
            this.verifyEmail(token)
        },
        methods: {
            updateData(name,val) {
                this.loginForm[name] = val;
            },
            verifyEmail(token) {
                this.$store.dispatch("Common/verifyEmail", token).then(res => {
                    this.responseData = res;
                })
            }
        }
    }
</script>

<style lang="scss">
    /*.icon-login {*/
    /*    i.v-icon.notranslate.material-icons.theme--light {*/
    /*        color: #3E7662;*/
    /*    }*/
    /*}*/
</style>
