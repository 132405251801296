<template>
  <v-content>
      <v-layout justify-space-between px-12 py-4 style="background-color: white">
          <v-flex d-flex justify-start align-center ml-12>
              <v-layout xs12>
                  <v-flex shrink mr-3 style="text-align: center;">
                      <img alt="Vue logo" src="../assets/logo.png" style="width: 80px;cursor: pointer;" @click="$router.push('/').catch(err => {})">
                  </v-flex>
                  <v-flex shrink style="text-align: left;cursor: pointer;" @click="$router.push('/').catch(err => {})">
                      <span style="color: #3E7662;font-size:16px;">สำนักงานคณะอนุกรรมการพิจารณาโครงการวิจัย กรมแพทย์ทหารบก</span><br>
                      <span style="color: #3E7662;font-size:22px;">Institutional Review Board</span><br>
                      <span style="color: #3E7662;font-size:16px;">Royal Thai Army Medical Department</span>
                  </v-flex>
              </v-layout>
          </v-flex>
          <v-flex shrink d-flex justify-end align-center mr-12>
              <!-- Login success -->
              <v-layout v-if="checkLogin === 1">
                  <v-flex pr-3 d-flex justify-end align-center style="max-width: 60px;height: 50px;">
                      <img :src="user.pictureFilePath" alt="" style="border-radius: 50px;height: 100%;width:100%;object-fit: cover;">
                  </v-flex>
                  <v-flex pr-3 d-flex justify-end align-center>
                      <span style="color: #3E7662;font-weight: bold;font-size: 14px">{{user ? user.thName : 'TEST'}}</span>
                      <!--<v-menu offset-y bottom right id="menuFormHome">-->
                          <!--<template v-slot:activator="{ on }">-->
                              <!--<v-btn icon v-on="on">-->
                                  <!--<v-icon style="color: #3E7662">arrow_drop_down</v-icon>-->
                              <!--</v-btn>-->
                          <!--</template>-->
                          <!--<v-list id="menulistHome">-->
                              <!--<v-layout py-2 px-4 style="background-color: #E5E5E5; text-align: left">-->
                                  <!--<v-flex>-->
                                      <!--<span>ข้อมูลส่วนตัว</span>-->
                                  <!--</v-flex>-->
                              <!--</v-layout>-->
                              <!--<v-list-item-title-->
                                      <!--class="menu-list-home"-->
                                      <!--@click="$router.push('#').catch(err => {})"-->
                              <!--&gt;-->
                                  <!--<div style="border-bottom: 1px solid #E5E5E5; text-align: left;cursor: pointer;">-->
                                      <!--<v-flex class="my-1 mx-4">-->
                                          <!--<v-icon class="mr-4">library_books</v-icon>-->
                                          <!--<span style="font-size: 12px;">TEST1</span>-->
                                      <!--</v-flex>-->
                                  <!--</div>-->
                              <!--</v-list-item-title>-->
                              <!--<v-list-item-title-->
                                      <!--class="menu-list-home"-->
                                      <!--@click="$router.push('#').catch(err => {})"-->
                              <!--&gt;-->
                                  <!--<div style="border-bottom: 1px solid #E5E5E5; text-align: left;cursor: pointer;">-->
                                      <!--<v-flex class="my-1 mx-4">-->
                                          <!--<v-icon class="mr-4">edit</v-icon>-->
                                          <!--<span style="font-size: 12px;">TEST2</span>-->
                                      <!--</v-flex>-->
                                  <!--</div>-->
                              <!--</v-list-item-title>-->
                              <!--<v-list-item-title-->
                                      <!--class="menu-list-home"-->
                                      <!--@click="$router.push('#').catch(err => {})"-->
                              <!--&gt;-->
                                  <!--<div style="border-bottom: 1px solid #E5E5E5; text-align: left;cursor: pointer;">-->
                                      <!--<v-flex class="my-1 mx-4">-->
                                          <!--<v-icon class="mr-4">lock</v-icon>-->
                                          <!--<span style="font-size: 12px;">TEST3</span>-->
                                      <!--</v-flex>-->
                                  <!--</div>-->
                              <!--</v-list-item-title>-->
                              <!--<v-list-item-title-->
                                      <!--class="menu-list-home"-->
                                      <!--@click="$router.push('#').catch(err => {})"-->
                              <!--&gt;-->
                                  <!--<div style="border-bottom: 1px solid #E5E5E5; text-align: left;cursor: pointer;">-->
                                      <!--<v-flex class="my-1 mx-4">-->
                                          <!--<v-icon class="mr-4">help</v-icon>-->
                                          <!--<span style="font-size: 12px;">TEST4</span>-->
                                      <!--</v-flex>-->
                                  <!--</div>-->
                              <!--</v-list-item-title>-->
                              <!--<v-list-item-title-->
                                      <!--class="menu-list-home"-->
                                      <!--@click="logout()"-->
                              <!--&gt;-->
                                  <!--<div style="border-bottom: 1px solid #E5E5E5; text-align: left;cursor: pointer;">-->
                                      <!--<v-flex class="my-1 mx-4">-->
                                          <!--<v-icon class="mr-4">exit_to_app</v-icon>-->
                                          <!--<span style="font-size: 12px;">ออกจากระบบ</span>-->
                                      <!--</v-flex>-->
                                  <!--</div>-->
                              <!--</v-list-item-title>-->
                          <!--</v-list>-->
                      <!--</v-menu>-->
                  </v-flex>
                  <v-flex d-flex justify-end align-center>
                      <v-btn color="#3E7662" dark @click="logout()">
                          ออกจากระบบ
                      </v-btn>
                  </v-flex>
              </v-layout>

              <!-- Not Login -->
              <v-layout v-if="checkLogin === 0">
                  <v-flex mx-8 class="shrink" d-flex justify-end align-center>
                      <v-btn color="#ffffff" depressed @click="$router.push('/register')">
                          <span style="color: #496256;font-weight: bold">ลงทะเบียน</span>
                      </v-btn>
                  </v-flex>
                  <v-flex d-flex justify-end align-center>
                      <v-btn color="#3E7662" dark @click="$router.push('/login')">
                          เข้าสู่ระบบ
                      </v-btn>
                  </v-flex>
              </v-layout>
              <loading
                      color="#527281"
                      :active.sync="isLoading"
                      :can-cancel="false"
                      :is-full-page="fullPage"
              ></loading>
          </v-flex>
      </v-layout>

  </v-content>
</template>

<script>
    import Swal from 'sweetalert2';
    import { mapState, mapActions } from "vuex";
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';

    export default {
        name: "Header",
        components: {
            Loading
        },
        async created() {
            await this.getUser();
            this.imageUrl = this.user.pictureFile;
        },

        data: () => ({
            isLoading: false,
            fullPage: true,
            imageUrl: '',
        }),
        computed: {
            ...mapState({
                checkLogin: state => state.Auth.checkLogin,
                user: state => state.Auth.user
            })
        },
        methods: {
            ...mapActions('Auth',['getUser']),
            logout() {
                this.isLoading = true;
                Swal.fire({
                    title: 'คุณต้องการออกจากระบบหรือไม่?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'ใช่ ตกลง',
                    confirmButtonColor: '#3E7662',
                    cancelButtonText: 'ยกเลิก',
                    cancelButtonColor: '#CC3D45',
                }).then((result) => {
                    if (result.value) {
                        this.$store.dispatch('Auth/logout').then(() => {
                            this.$router.replace('/login');
                            this.isLoading = false;
                        })
                    }
                })
                this.isLoading = false;
            }
        },
    }
</script>

<style lang="scss">
    .theme--light.v-list#menulistHome {
        padding-top: 0;
        padding-bottom: 0;
    }

    .v-menu__content.theme--light.menuable__content__active#menuFormHome {
        left: unset !important;
    }

    .v-list-item#listItemHome {
        padding: 0px !important;
    }

    .menu-list-home:hover {
        background: rgba(224, 216, 209, 0.15);
    }
</style>
