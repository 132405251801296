import Service from "../../services";

const Profile = {
    namespaced: true,
    state: {
        formResearch: 1,
        activeResearchCount: 0
    },
    mutations: {
        SET_PAGE: (state, page) => {
            state.formResearch = page;
        },
        // RESEARCH_COUNT:(state,res)=>{
        //     var i =0;
        //     res.forEach(data => {
        //         if (data.status === 'รอดำเนินการ' || data.status === 'อยู่ระหว่างการพิจารณา' || data.status === 'ปรับปรุงแก้ไขเพื่อพิจารณาใหม่' || data.status === 'ปรับปรุงแก้ไขเพื่อรับรอง' || data.status === 'ผ่านการพิจารณา' || data.status === 'อนุมัติ')
        //             i++
        //     })
        //     state.activeResearchCount = i;
        // },
    },
    actions: {
        getResearch({ commit }, page) {
            return Service.Profile.getResearch(page).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        getAllResearchPaginate({ commit },) {
            return Service.Profile.getAllResearchPaginate().then(res => {
                // commit("RESEARCH_COUNT", res);
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        getExpireDate({ commit }, id) {
            return Service.Profile.getExpireDate(id).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        cancelResearch({ commit }, id) {
            return Service.Profile.cancelResearch(id).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
    }
}

export default Profile;
