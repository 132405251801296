import Service from "../../services"

const Budget = {
    namespaced: true,
    state: {

    },
    mutations: {

    },
    actions: {
        getBudgetResourceList({commit},page) {
            return Service.Budget.getBudgetResourceList(page).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        save({commit}, data) {
            return Service.Budget.save(data).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        delete({commit}, id) {
            return Service.Budget.delete(id).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        update({commit},data) {
            return Service.Budget.update(data, data.id).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        getBudget({commit}, id) {
            return Service.Budget.getBudget(id).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        createBudget({commit}, data) {
            return Service.Budget.createBudget(data).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        updateBudget({commit}, data) {
            return Service.Budget.updateBudget(data,data.id).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        deleteBudget({commit}, data) {
            return Service.Budget.deleteBudget(data).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
    }
}

export default Budget;
