import Service from "../../services";

const FormRF = {
    namespaced: true,
    state: {
        formResearch: 1,
        researchID: '',
    },
    mutations: {
        SET_PAGE:(state,page)=>{
            state.formResearch = page;
        },
        SET_RESEARCH_ID:(state,res)=>{
            state.researchID = res.researchID;
        },
    },
    actions: {
        saveRFSusar({commit},data) {
            return Service.formRF.saveRFSusar(data).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        saveRF04_1({commit},data) {
            return Service.formRF.saveRF04_1(data).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        saveRF04_2({commit},data) {
            return Service.formRF.saveRF04_2(data).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        saveRF06({commit},data) {
            return Service.formRF.saveRF06(data).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        saveRF12({commit},data) {
            return Service.formRF.saveRF12(data).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        saveRF03({commit},data) {
            return Service.formRF.saveRF03(data).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        saveRF05({commit},data) {
            return Service.formRF.saveRF05(data).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        saveRF07({commit},data) {
            return Service.formRF.saveRF07(data).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        sendCorres({commit},data) {
            return Service.formRF.sendCorres(data).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        getRF07({commit},id) {
            return Service.formRF.getRF07(id).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        getRF12({commit},id) {
            return Service.formRF.getRF12(id).then(res => {
                commit("SET_RESEARCH_ID", res);
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        getRFSusar({commit},id) {
            return Service.formRF.getRFSusar(id).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        getRF04_1({commit},id) {
            return Service.formRF.getRF04_1(id).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        getRF04_2({commit},id) {
            return Service.formRF.getRF04_2(id).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        getRF05({commit},id) {
            return Service.formRF.getRF05(id).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        getRF03({commit},id) {
            return Service.formRF.getRF03(id).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        getRF06({commit},id) {
            return Service.formRF.getRF06(id).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        getHistoryRF12({commit},id) {
            return Service.formRF.getHistoryRF12(id).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        getHistoryRF04_1({commit},id) {
            return Service.formRF.getHistoryRF04_1(id).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        getHistoryRF04_2({commit},id) {
            return Service.formRF.getHistoryRF04_2(id).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        getHistoryRF05({commit},id) {
            return Service.formRF.getHistoryRF05(id).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        getHistoryRF03({commit},id) {
            return Service.formRF.getHistoryRF03(id).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        getHistoryRF06({commit},id) {
            return Service.formRF.getHistoryRF06(id).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
        getHistoryRF07({commit},id) {
            return Service.formRF.getHistoryRF07(id).then(res => {
                return res;
            }).catch(err => {
                return Promise.reject(err)
            })
        },
    }
}

export default FormRF;
