<template>
    <v-content>
        <v-layout>
            <v-flex shrink pt-2 v-if="label" style="color: #3E7662;font-weight: bold">
                <span class="mr-3">{{label}}</span>
            </v-flex>
            <v-flex class="font-weight-bold">
                <v-menu
                        v-model="menu_date"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        :disabled="disabled"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                                :hide-details="hideDetail"
                                v-model="model"
                                readonly
                                item-color="#3E7662"
                                color="#3E7662"
                                dense
                                v-on="on"
                                :disabled="disabled"
                        ></v-text-field>
                    </template>
                    <!--                    v-model="model"-->
                    <v-date-picker
                            @input="selectDateStart"
                            locale="th"
                            type="date"
                            color="#3E7662"
                            header-color="#3E7662"
                            :disabled="disabled"
                    ></v-date-picker>
                </v-menu>
            </v-flex>
        </v-layout>
    </v-content>
</template>

<script>
    export default {
        name: "DateInputFormAppend",
        props: {
            model: {
                required: false
            },
            label: {
                type: String,
            },
            icon: {
                type: String,
            },
            disabled: {
                type: Boolean
            },
            hideDetail: Boolean,
        },
        data: () => ({
            menu_date: '',
        }),
        methods: {
            onChange(val) {
                this.$emit("update-data", val);
            },
            selectDateStart(val) {
                this.menu_date = false;
                this.$emit("update-data", val);
            },
        }
    }
</script>

<style scoped>

</style>
