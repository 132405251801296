<template>
    <v-content>
        <v-layout style="background-color: #FAF5F2;">
            <v-flex>
                <v-layout mb-3 mt-6>
                    <v-flex class="text-center" style="color: #3E7662;font-weight: bold;font-size: 28px">
                        <span>แบบฟอร์ม</span>
                    </v-flex>
                </v-layout>

                <v-layout style="margin-left: 100px;margin-right: 100px;">
                    <v-flex>
                        <v-layout mt-3 style="background-color: #3E7662;">
                            <v-flex my-8 ml-8 style="text-align: left;font-size: 28px;color: white">
                                <div style="font-weight: bold">แบบรายงานการแก้ไขเพิ่มเติมโครงร่างการวิจัย</div>
                                <div style="font-size: 18px;">Amendment Form</div>
                            </v-flex>
                            <v-flex ml-8 pt-8 pr-8 style="text-align: right;font-weight: bold;font-size: 13px;color: white">
                                <span>RF 12_2563</span>
                            </v-flex>
                        </v-layout>

                        <!-- blog 1 -->
                        <v-card flat class="mb-12 px-12">
                            <v-layout py-12 pr-12 mr-12>
                                <v-flex>
                                    <!-- row 1 -->
                                    <v-layout pt-8>
                                        <v-flex xs2 mr-4 pt-2 style="text-align: right;font-weight: bold;">
                                            <span>รหัสโครงร่างการวิจัย</span>
                                        </v-flex>
                                        <v-flex xs4>
                                            <textfield-form :model="form.researchCode"
                                                @update-data="updateData('researchCode', $event)"></textfield-form>
                                        </v-flex>
                                    </v-layout>
                                    <!-- row 2 -->
                                    <v-layout>
                                        <v-flex xs2 mr-4 pt-2 style="text-align: right;font-weight: bold;">
                                            <span>ชื่อผู้วิจัยหลัก</span>
                                        </v-flex>
                                        <v-flex xs4>
                                            <textfield-form :model="form.firstName"
                                                @update-data="updateData('firstName', $event)"></textfield-form>
                                        </v-flex>
                                        <v-flex xs2 pr-4 pt-2 style="text-align: right;font-weight: bold;">
                                            <span>นามสกุล</span>
                                        </v-flex>
                                        <v-flex xs4>
                                            <textfield-form :model="form.lastName"
                                                @update-data="updateData('lastName', $event)"></textfield-form>
                                        </v-flex>
                                    </v-layout>
                                    <!-- row 3 -->
                                    <v-layout pb-6>
                                        <v-flex xs2 mr-4 pt-2 style="text-align: right;font-weight: bold;">
                                            <span>ชื่อโครงการวิจัย</span>
                                        </v-flex>
                                        <v-flex xs10>
                                            <textfield-form :model="form.researchName"
                                                @update-data="updateData('researchName', $event)"></textfield-form>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                            </v-layout>
                        </v-card>

                        <!-- blog 2 -->
                        <v-layout mt-3 style="background-color: #3E7662;">
                            <v-flex my-5 ml-8 style="text-align: left;font-size: 28px;color: white">
                                <div style="font-weight: bold">SUMMARY OF PROTOCOL AMENDMENT FOR RISK/BENEFIT
                                    ASSESSMENT
                                </div>
                                <div style="font-size: 18px;">
                                    สรุปการปรับการดำเนินการในโครงร่างการวิจัยสำหรับการประเมินประโยชน์และความเสี่ยง
                                </div>
                            </v-flex>
                        </v-layout>
                        <v-layout pt-12 pb-8 mb-12 style="background-color: white;padding-left: 60px;padding-right: 70px;">
                            <v-flex>
                                <!-- Group 1 -->
                                <v-layout>
                                    <v-flex style="text-align: left;font-weight: bold;">
                                        <span>1. การปรับเกณฑ์การคัดเข้า/คัดออก</span>
                                    </v-flex>
                                </v-layout>
                                <v-layout>
                                    <v-flex pl-3 style="text-align: left;">
                                        <v-radio-group v-model="form.hasScreening" row>
                                            <v-radio color="#3E7662" style="padding-right: 100px" label="ใช่"
                                                value="0"></v-radio>
                                            <v-radio @click.native="clearRedio('hasScreening')" color="#3E7662"
                                                label="ไม่ใช่" value="1"></v-radio>
                                        </v-radio-group>
                                    </v-flex>
                                </v-layout>
                                <v-layout mb-6>
                                    <v-flex pl-3 style="text-align: left;">
                                        <textarea-form :disabled="form.hasScreening === '1'"
                                            :background-color="form.hasScreening === '1' ? '#F9F9F9' : '#FBF6F3'"
                                            :model="form.screening" placeholder="(อธิบายโดยสรุปและระบุเหตุผล)"
                                            @update-data="updateData('screening', $event)"></textarea-form>
                                    </v-flex>
                                </v-layout>
                                <!-- Group 2 -->
                                <v-layout>
                                    <v-flex style="text-align: left;font-weight: bold;">
                                        <span>2. การปรับวิธีการหรือขนาดยา</span>
                                    </v-flex>
                                </v-layout>
                                <v-layout>
                                    <v-flex pl-3 style="text-align: left;">
                                        <v-radio-group row v-model="form.hasAdjustMethod">
                                            <v-radio color="#3E7662" style="padding-right: 100px" label="ใช่"
                                                value="0"></v-radio>
                                            <v-radio @click.native="clearRedio('hasAdjustMethod')" color="#3E7662"
                                                label="ไม่ใช่" value="1"></v-radio>
                                        </v-radio-group>
                                    </v-flex>
                                </v-layout>
                                <v-layout mb-6>
                                    <v-flex pl-3 style="text-align: left;">
                                        <textarea-form :disabled="form.hasAdjustMethod === '1'"
                                            :background-color="form.hasAdjustMethod === '1' ? '#F9F9F9' : '#FBF6F3'"
                                            :model="form.adjustMethod" placeholder="(อธิบายโดยสรุปและระบุเหตุผล)"
                                            @update-data="updateData('adjustMethod', $event)"></textarea-form>
                                    </v-flex>
                                </v-layout>
                                <!-- Group 3 -->
                                <v-layout>
                                    <v-flex style="text-align: left;font-weight: bold;">
                                        <span>3. เพิ่มเติมหัตถการในการวิจัย</span>
                                    </v-flex>
                                </v-layout>
                                <v-layout>
                                    <v-flex pl-3 style="text-align: left;">
                                        <v-radio-group row v-model="form.hasAddOperate">
                                            <v-radio color="#3E7662" style="padding-right: 100px" label="ใช่"
                                                value="0"></v-radio>
                                            <v-radio @click.native="clearRedio('redio')" color="#3E7662" label="ไม่ใช่"
                                                value="1"></v-radio>
                                        </v-radio-group>
                                    </v-flex>
                                </v-layout>
                                <v-layout mb-6>
                                    <v-flex pl-3 style="text-align: left;">
                                        <textarea-form :disabled="form.hasAddOperate === '1'"
                                            :background-color="form.hasAddOperate === '1' ? '#F9F9F9' : '#FBF6F3'"
                                            :model="form.addOperate" placeholder="(อธิบายโดยสรุปและระบุเหตุผล)"
                                            @update-data="updateData('addOperate', $event)"></textarea-form>
                                    </v-flex>
                                </v-layout>
                                <!-- Group 4 -->
                                <v-layout>
                                    <v-flex style="text-align: left;font-weight: bold;">
                                        <span>4. ขยายระยะเวลาการให้ยาวิจัย</span>
                                    </v-flex>
                                </v-layout>
                                <v-layout>
                                    <v-flex pl-3 style="text-align: left;">
                                        <v-radio-group row v-model="form.hasExpandTime">
                                            <v-radio color="#3E7662" style="padding-right: 100px" label="ใช่"
                                                value="0"></v-radio>
                                            <v-radio @click.native="clearRedio('hasExpandTime')" color="#3E7662"
                                                label="ไม่ใช่" value="1"></v-radio>
                                        </v-radio-group>
                                    </v-flex>
                                </v-layout>
                                <v-layout mb-6>
                                    <v-flex pl-3 style="text-align: left;">
                                        <textarea-form :disabled="form.hasExpandTime === '1'"
                                            :background-color="form.hasExpandTime === '1' ? '#F9F9F9' : '#FBF6F3'"
                                            :model="form.expandTime" placeholder="(อธิบายโดยสรุปและระบุเหตุผล)"
                                            @update-data="updateData('expandTime', $event)"></textarea-form>
                                    </v-flex>
                                </v-layout>
                                <!-- Group 5 -->
                                <v-layout>
                                    <v-flex style="text-align: left;font-weight: bold;">
                                        <span>5. การปรับการดำเนินการวิจัยอื่นๆที่มีผลเพิ่มตามความเสี่ยงต่ออาสาสมัคร</span>
                                    </v-flex>
                                </v-layout>
                                <v-layout>
                                    <v-flex pl-3 style="text-align: left;">
                                        <v-radio-group row v-model="form.hasAdjustOtherMethod">
                                            <v-radio color="#3E7662" style="padding-right: 100px" label="ใช่"
                                                value="0"></v-radio>
                                            <v-radio @click.native="clearRedio('hasAdjustOtherMethod')" color="#3E7662"
                                                label="ไม่ใช่" value="1"></v-radio>
                                        </v-radio-group>
                                    </v-flex>
                                </v-layout>
                                <v-layout mb-6>
                                    <v-flex pl-3 style="text-align: left;">
                                        <textarea-form :disabled="form.hasAdjustOtherMethod === '1'"
                                            :background-color="form.hasAdjustOtherMethod === '1' ? '#F9F9F9' : '#FBF6F3'"
                                            :model="form.adjustOtherMethod" placeholder="(อธิบายโดยสรุปและระบุเหตุผล)"
                                            @update-data="updateData('adjustOtherMethod', $event)"></textarea-form>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                        </v-layout>

                        <!-- Blog 3 -->
                        <v-layout pt-12 pb-8 mb-12 style="background-color: white;padding-left: 60px;padding-right: 70px;">
                            <v-flex>
                                <v-layout mb-12>
                                    <v-flex style="font-weight: bold" d-flex justify-start>
                                        <span>CHECK ALL PROTOCOL AMENDMENTS THAT APPLY :</span>
                                    </v-flex>
                                </v-layout>
                                <!-- Group 1 -->
                                <v-layout px-12>
                                    <v-flex xs6 style="font-weight: bold;font-size: 18px" d-flex justify-start>
                                        <span>Information sheet / Consent form</span>
                                    </v-flex>
                                </v-layout>

                                <v-layout px-12 my-4>
                                    <v-flex shrink mr-4>
                                        <v-btn dark style="width: 80px;background-color: #3E7662"
                                            @click="addDynamicFile('fileInformationSheet')">
                                            <v-icon>add</v-icon>
                                            <span class="font-weight-bold">เพิ่ม</span>
                                        </v-btn>
                                    </v-flex>
                                    <v-flex> <span style="color: #CC3D45;">*ให้ผู้วิจัยระบุชื่่อ file / เวอร์ชั่น / วันที่
                                            ให้ตรงกับ บันทึกข้อความ และห้ามมีอักขระพิเศษ (!@#$%^&*)</span> </v-flex>
                                </v-layout>
                                <div v-for="(input, k) in form.fileInformationSheet" :key="'a' + k">
                                    <v-layout mt-6>
                                        <v-flex>
                                            <v-layout>
                                                <v-flex shrink mr-4 pt-2 style="text-align: right;font-weight: bold;">
                                                    <span>ชื่อไฟล์</span>
                                                </v-flex>
                                                <v-flex xs5>
                                                    <textfield-form hide-detail :model="input.title"
                                                        @update-data="updateDynamicFileData('fileInformationSheet', 'title', k, $event)"></textfield-form>
                                                </v-flex>
                                                <v-flex shrink pt-2 px-4 style="text-align: left;">
                                                    <span style="font-weight: bold;font-size: 16px;">ไฟล์</span>
                                                </v-flex>
                                                <v-flex>
                                                    <v-file-input accept=".pdf" prepend-icon=""
                                                        :id="'fileInformationSheet' + k" :model="input.file" outlined dense
                                                        background-color="#FBF6F3" color="#3E7662" item-color="#3E7662"
                                                        @change="updateDynamicFileData('fileInformationSheet', 'file', k, $event)"></v-file-input>
                                                </v-flex>
                                            </v-layout>
                                            <v-layout class="mx-12 justify-start d-flex">
                                                <v-flex xs3>
                                                    <textfield-form-append :model="input.version"
                                                        css="color: #3E7662;font-weight: bold" label="Version"
                                                        @update-data="updateDynamicFileData('fileInformationSheet', 'version', k, $event)"></textfield-form-append>
                                                </v-flex>
                                                <v-flex xs3 ml-6>
                                                    <date-input-form-append
                                                        :model="dynamicDateChange.fileInformationSheet[k]"
                                                        style="color: #3E7662;" label="Date"
                                                        @update-data="updateDynamicFileData('fileInformationSheet', 'date', k, $event)"></date-input-form-append>
                                                </v-flex>
                                                <v-flex v-if="input.filePath">
                                                    <a :href="input.filePath" target="_blank"
                                                        style="color: #3e7662;text-align: center" class="link-hover">
                                                        <p>ไฟล์ที่อัพโหลด: {{ input.file }}</p>
                                                    </a>
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex shrink pl-4 style="text-align: left" class="">
                                            <v-layout>
                                                <v-btn color="#3E7662" dark
                                                    @click="uploadFileBtn('fileInformationSheet' + k)">
                                                    <v-icon class="pr-3">cloud_download</v-icon>
                                                    <span>Upload</span>
                                                </v-btn>
                                            </v-layout>
                                            <v-layout mt-4>
                                                <v-btn dark style="background-color: #CC3D45; width: 80px"
                                                    @click="removeDynamicFile('fileInformationSheet', k)"
                                                    :disabled="form.fileInformationSheet.length < 1">
                                                    <v-icon>remove</v-icon>
                                                    <span class="font-weight-bold">ลบ</span>
                                                </v-btn>
                                            </v-layout>
                                        </v-flex>
                                    </v-layout>
                                </div>


                                <!--<v-layout px-12>-->
                                <!--<v-flex xs4>-->
                                <!--<v-layout>-->
                                <!--<v-flex shrink pt-2 pr-4 style="text-align: left;">-->
                                <!--<span style="font-weight: bold;font-size: 16px;">ไฟล์</span>-->
                                <!--</v-flex>-->
                                <!--<v-flex>-->
                                <!--<v-file-input-->
                                <!--accept=".pdf"-->
                                <!--prepend-icon=""-->
                                <!--id="fileInformationSheet"-->
                                <!--:model="form.fileInformationSheet"-->
                                <!--outlined-->
                                <!--dense-->
                                <!--background-color="#FBF6F3"-->
                                <!--color="#3E7662"-->
                                <!--item-color="#3E7662"-->
                                <!--@change="uploadFile('fileInformationSheet', $event)"-->
                                <!--&gt;</v-file-input>-->
                                <!--<div v-if="fileInformationSheet" class="mt-1 text-start" style="font-size: 12px;color: #CC3D45;">-->
                                <!--ไฟล์ที่อัพโหลด:-->
                                <!--<a :href="fileInformationSheetPath" target="_blank" class="link-hover">-->
                                <!--<span>{{fileInformationSheet}}</span>-->
                                <!--</a>-->
                                <!--</div>-->
                                <!--</v-flex>-->
                                <!--<v-flex shrink pl-4 style="text-align: left">-->
                                <!--<v-btn-->
                                <!--color="#3E7662"-->
                                <!--dark-->
                                <!--@click="uploadFileBtn('fileInformationSheet')"-->
                                <!--&gt;-->
                                <!--<v-icon class="pr-3">cloud_download</v-icon>-->
                                <!--<span>Upload</span>-->
                                <!--</v-btn>-->
                                <!--</v-flex>-->
                                <!--</v-layout>-->
                                <!--</v-flex>-->
                                <!--</v-layout>-->


                                <v-layout px-12 py-12>
                                    <v-flex style="text-align: left;color: #544A44;">
                                        <span>(Provide copy of original consent form, a description of the revisions and
                                            highlighted revised consent form)</span>
                                    </v-flex>
                                </v-layout>

                                <!-- Group 2 -->
                                <v-layout px-12 mt-8>
                                    <v-flex xs6 style="font-weight: bold;font-size: 18px" d-flex justify-start>
                                        <span>Protocol Modification or Amendment</span>
                                    </v-flex>
                                </v-layout>

                                <v-layout px-12 my-4>
                                    <v-flex shrink mr-4>
                                        <v-btn dark style="width: 80px;background-color: #3E7662"
                                            @click="addDynamicFile('fileProtocolModify')">
                                            <v-icon>add</v-icon>
                                            <span class="font-weight-bold">เพิ่ม</span>
                                        </v-btn>
                                    </v-flex>

                                    <v-flex> <span style="color: #CC3D45;">*ให้ผู้วิจัยระบุชื่่อ file / เวอร์ชั่น / วันที่
                                            ให้ตรงกับ บันทึกข้อความ และห้ามมีอักขระพิเศษ (!@#$%^&*)</span> </v-flex>
                                </v-layout>
                                <div v-for="(input, k) in form.fileProtocolModify" :key="'b' + k">
                                    <v-layout mt-6>
                                        <v-flex>
                                            <v-layout>
                                                <v-flex shrink mr-4 pt-2 style="text-align: right;font-weight: bold;">
                                                    <span>ชื่อไฟล์</span>
                                                </v-flex>
                                                <v-flex xs5>
                                                    <textfield-form hide-detail :model="input.title"
                                                        @update-data="updateDynamicFileData('fileProtocolModify', 'title', k, $event)"></textfield-form>
                                                </v-flex>
                                                <v-flex shrink pt-2 px-4 style="text-align: left;">
                                                    <span style="font-weight: bold;font-size: 16px;">ไฟล์</span>
                                                </v-flex>
                                                <v-flex>
                                                    <v-file-input accept=".pdf" prepend-icon=""
                                                        :id="'fileProtocolModify' + k" :model="input.file" outlined dense
                                                        background-color="#FBF6F3" color="#3E7662" item-color="#3E7662"
                                                        @change="updateDynamicFileData('fileProtocolModify', 'file', k, $event)"></v-file-input>
                                                </v-flex>
                                            </v-layout>
                                            <v-layout class="mx-12 justify-start d-flex">
                                                <v-flex xs3>
                                                    <textfield-form-append :model="input.version"
                                                        css="color: #3E7662;font-weight: bold" label="Version"
                                                        @update-data="updateDynamicFileData('fileProtocolModify', 'version', k, $event)"></textfield-form-append>
                                                </v-flex>
                                                <v-flex xs3 ml-6>
                                                    <date-input-form-append :model="dynamicDateChange.fileProtocolModify[k]"
                                                        style="color: #3E7662;" label="Date"
                                                        @update-data="updateDynamicFileData('fileProtocolModify', 'date', k, $event)"></date-input-form-append>
                                                </v-flex>
                                                <v-flex v-if="input.filePath">
                                                    <a :href="input.filePath" target="_blank"
                                                        style="color: #3e7662;text-align: center" class="link-hover">
                                                        <p>ไฟล์ที่อัพโหลด: {{ input.file }}</p>
                                                    </a>
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex shrink pl-4 style="text-align: left" class="">
                                            <v-layout>
                                                <v-btn color="#3E7662" dark
                                                    @click="uploadFileBtn('fileProtocolModify' + k)">
                                                    <v-icon class="pr-3">cloud_download</v-icon>
                                                    <span>Upload</span>
                                                </v-btn>
                                            </v-layout>
                                            <v-layout mt-4>
                                                <v-btn dark style="background-color: #CC3D45; width: 80px"
                                                    @click="removeDynamicFile('fileProtocolModify', k)"
                                                    :disabled="form.fileProtocolModify.length < 1">
                                                    <v-icon>remove</v-icon>
                                                    <span class="font-weight-bold">ลบ</span>
                                                </v-btn>
                                            </v-layout>
                                        </v-flex>
                                    </v-layout>
                                </div>
                                <v-layout px-12>
                                    <v-flex style="text-align: left;color: #544A44;">
                                        <span>
                                            (Attach a description of the modification, amendment, and addendum,
                                            all supporting documents, and a highlighted (tracked changed) revised consent
                                            form, if required.)
                                        </span>
                                    </v-flex>
                                </v-layout>
                                <!-- Group 3 -->
                                <v-layout mt-12 style="padding-left: 150px">
                                    <v-flex>
                                        <!-- row 1 -->
                                        <v-layout>
                                            <v-flex :class="!form.inclusion ? '' : 'color-label-checkbox'">
                                                <v-checkbox v-model="form.inclusion" hide-details color="#3E7662"
                                                    label="Inclusion / Exclusion Criteria Change"></v-checkbox>
                                            </v-flex>
                                        </v-layout>
                                        <!-- row 2 -->
                                        <v-layout>
                                            <v-flex :class="!form.procedureChange ? '' : 'color-label-checkbox'">
                                                <v-checkbox v-model="form.procedureChange" hide-details color="#3E7662"
                                                    label="Procedure Changes"></v-checkbox>
                                            </v-flex>
                                        </v-layout>
                                        <!-- row 3 -->
                                        <v-layout>
                                            <v-flex :class="!form.editorial ? '' : 'color-label-checkbox'">
                                                <v-checkbox v-model="form.editorial" hide-details color="#3E7662"
                                                    label="Editorial / Administrative Changes"></v-checkbox>
                                            </v-flex>
                                        </v-layout>
                                        <!-- row 3 -->
                                        <v-layout>
                                            <v-flex shrink :class="!form.hasOtherChange ? '' : 'color-label-checkbox'">
                                                <v-checkbox hide-details color="#3E7662" v-model="form.hasOtherChange"
                                                    label="Other changes, please specify"
                                                    @change="otherChangeCheckbox()"></v-checkbox>
                                            </v-flex>
                                            <v-flex ml-4 pt-1>
                                                <textfield-form-append :disabled="!form.hasOtherChange"
                                                    :model="form.otherChange"
                                                    @update-data="updateData('otherChange', $event)"></textfield-form-append>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                                <!-- Group 4-->
                                <v-layout px-12 mt-8>
                                    <!-- row 1-->
                                    <v-flex xs6 style="font-weight: bold;font-size: 18px" d-flex justify-start>
                                        <span>Investigator Brochure</span>
                                    </v-flex>
                                </v-layout>
                                <v-layout px-12 my-4>
                                    <v-flex shrink mr-4>
                                        <v-btn dark style="width: 80px;background-color: #3E7662"
                                            @click="addDynamicFile('fileInvestigator')">
                                            <v-icon>add</v-icon>
                                            <span class="font-weight-bold">เพิ่ม</span>
                                        </v-btn>
                                    </v-flex>

                                    <v-flex> <span style="color: #CC3D45;">*ให้ผู้วิจัยระบุชื่่อ file / เวอร์ชั่น / วันที่
                                            ให้ตรงกับ บันทึกข้อความ และห้ามมีอักขระพิเศษ (!@#$%^&*)</span> </v-flex>
                                </v-layout>
                                <div v-for="(input, k) in form.fileInvestigator" :key="'c' + k">
                                    <v-layout mt-6>
                                        <v-flex>
                                            <v-layout>
                                                <v-flex shrink mr-4 pt-2 style="text-align: right;font-weight: bold;">
                                                    <span>ชื่อไฟล์</span>
                                                </v-flex>
                                                <v-flex xs5>
                                                    <textfield-form hide-detail :model="input.title"
                                                        @update-data="updateDynamicFileData('fileInvestigator', 'title', k, $event)"></textfield-form>
                                                </v-flex>
                                                <v-flex shrink pt-2 px-4 style="text-align: left;">
                                                    <span style="font-weight: bold;font-size: 16px;">ไฟล์</span>
                                                </v-flex>
                                                <v-flex>
                                                    <v-file-input accept=".pdf" prepend-icon="" :id="'fileInvestigator' + k"
                                                        :model="input.file" outlined dense background-color="#FBF6F3"
                                                        color="#3E7662" item-color="#3E7662"
                                                        @change="updateDynamicFileData('fileInvestigator', 'file', k, $event)"></v-file-input>
                                                </v-flex>
                                            </v-layout>
                                            <v-layout class="mx-12 justify-start d-flex">
                                                <v-flex xs3>
                                                    <textfield-form-append :model="input.version"
                                                        css="color: #3E7662;font-weight: bold" label="Version"
                                                        @update-data="updateDynamicFileData('fileInvestigator', 'version', k, $event)"></textfield-form-append>
                                                </v-flex>
                                                <v-flex xs3 ml-6>
                                                    <date-input-form-append :model="dynamicDateChange.fileInvestigator[k]"
                                                        style="color: #3E7662;" label="Date"
                                                        @update-data="updateDynamicFileData('fileInvestigator', 'date', k, $event)"></date-input-form-append>
                                                </v-flex>
                                                <v-flex v-if="input.filePath">
                                                    <a :href="input.filePath" target="_blank"
                                                        style="color: #3e7662;text-align: center" class="link-hover">
                                                        <p>ไฟล์ที่อัพโหลด: {{ input.file }}</p>
                                                    </a>
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex shrink pl-4 style="text-align: left" class="">
                                            <v-layout>
                                                <v-btn color="#3E7662" dark @click="uploadFileBtn('fileInvestigator' + k)">
                                                    <v-icon class="pr-3">cloud_download</v-icon>
                                                    <span>Upload</span>
                                                </v-btn>
                                            </v-layout>
                                            <v-layout mt-4>
                                                <v-btn dark style="background-color: #CC3D45; width: 80px"
                                                    @click="removeDynamicFile('fileInvestigator', k)"
                                                    :disabled="form.fileInvestigator.length < 1">
                                                    <v-icon>remove</v-icon>
                                                    <span class="font-weight-bold">ลบ</span>
                                                </v-btn>
                                            </v-layout>
                                        </v-flex>
                                    </v-layout>
                                </div>
                                <!-- row 2-->
                                <v-layout px-12>
                                    <v-flex xs6 :class="!form.hasNewInformation ? '' : 'color-label-checkbox'"
                                        style="font-weight: bold;font-size: 18px" d-flex justify-start>
                                        <v-checkbox v-model="form.hasNewInformation" hide-details color="#3E7662"
                                            label="New Information Provided to Subjects"></v-checkbox>
                                    </v-flex>
                                </v-layout>
                                <!-- row 3-->
                                <v-layout px-12 mb-6>
                                    <v-flex xs6 :class="!form.hasAdvertisement ? '' : 'color-label-checkbox'"
                                        style="font-weight: bold;font-size: 18px" d-flex justify-start>
                                        <v-checkbox v-model="form.hasAdvertisement" hide-details color="#3E7662"
                                            label="Advertisement (s) / Recruitment Letter (s)"></v-checkbox>
                                    </v-flex>
                                </v-layout>
                                <!-- row 4-->

                                <v-layout px-12 mt-8>
                                    <!-- row 1-->
                                    <v-flex xs6 style="font-weight: bold;font-size: 18px" d-flex justify-start>
                                        <span>เอกสารแนบเพิ่มเติม</span>
                                    </v-flex>
                                </v-layout>
                                <v-layout px-12 my-4>
                                    <v-flex shrink mr-4>
                                        <v-btn dark style="width: 80px;background-color: #3E7662"
                                            @click="addDynamicFile('fileOther')">
                                            <v-icon>add</v-icon>
                                            <span class="font-weight-bold">เพิ่ม</span>
                                        </v-btn>
                                    </v-flex>
                                    <v-flex> <span style="color: #CC3D45;">*ให้ผู้วิจัยระบุชื่่อ file / เวอร์ชั่น / วันที่
                                            ให้ตรงกับ บันทึกข้อความ และห้ามมีอักขระพิเศษ (!@#$%^&*)</span> </v-flex>
                                </v-layout>
                                <div v-for="(input, k) in form.fileOther" :key="'d' + k">
                                    <v-layout>
                                        <v-flex>
                                            <v-layout>
                                                <v-flex shrink mr-4 pt-2 style="text-align: right;font-weight: bold;">
                                                    <span>ชื่อไฟล์</span>
                                                </v-flex>
                                                <v-flex xs5>
                                                    <textfield-form hide-detail :model="input.title"
                                                        @update-data="updateDynamicFileData('fileOther', 'title', k, $event)"></textfield-form>
                                                </v-flex>
                                                <v-flex shrink pt-2 px-4 style="text-align: left;">
                                                    <span style="font-weight: bold;font-size: 16px;">ไฟล์</span>
                                                </v-flex>
                                                <v-flex>
                                                    <v-file-input accept=".pdf" prepend-icon=""
                                                        :id="'fileProtocolModify' + k" :model="input.file" outlined dense
                                                        background-color="#FBF6F3" color="#3E7662" item-color="#3E7662"
                                                        @change="updateDynamicFileData('fileOther', 'file', k, $event)"></v-file-input>
                                                </v-flex>
                                            </v-layout>
                                            <v-layout class="mx-12 justify-start d-flex">
                                                <v-flex xs3>
                                                    <textfield-form-append :model="input.version"
                                                        css="color: #3E7662;font-weight: bold" label="Version"
                                                        @update-data="updateDynamicFileData('fileOther', 'version', k, $event)"></textfield-form-append>
                                                </v-flex>
                                                <v-flex xs3 ml-6>
                                                    <date-input-form-append :model="dynamicDateChange.fileOther[k]"
                                                        style="color: #3E7662;" label="Date"
                                                        @update-data="updateDynamicFileData('fileOther', 'date', k, $event)"></date-input-form-append>
                                                </v-flex>
                                                <v-flex v-if="input.filePath">
                                                    <a :href="input.filePath" target="_blank"
                                                        style="color: #3e7662;text-align: center" class="link-hover">
                                                        <p>ไฟล์ที่อัพโหลด: {{ input.file }}</p>
                                                    </a>
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex ml-4>
                                            <v-btn dark style="background-color: #CC3D45; width: 80px"
                                                @click="removeDynamicFile('fileOther', k)"
                                                :disabled="form.fileOther.length < 1">
                                                <v-icon>remove</v-icon>
                                                <span class="font-weight-bold">ลบ</span>
                                            </v-btn>
                                        </v-flex>
                                    </v-layout>
                                </div>

                                <v-layout px-12 mb-6>
                                    <v-flex shrink :class="!form.hasChangeInTitle ? '' : 'color-label-checkbox'"
                                        style="font-weight: bold;font-size: 18px" d-flex justify-start>
                                        <v-checkbox v-model="form.hasChangeInTitle" hide-details color="#3E7662"
                                            label="Change in Title :" @change="changeTitleCheckbox"></v-checkbox>
                                    </v-flex>
                                    <v-flex pt-5 ml-3 shrink style="font-weight: bold;font-size: 16px;color: #3E7662">
                                        <span>(Attach revised consent form with new title)</span>
                                    </v-flex>
                                </v-layout>
                                <!-- Title form area -->
                                <v-layout mt-8 mb-4 style="padding-left: 80px;">
                                    <v-flex d-flex justify-start>
                                        <span style="font-size: 24px;font-weight: bold"
                                            :style="[!form.hasChangeInTitle ? { 'color': 'gray' } : { 'color': '#000' }]">
                                            New Title :
                                        </span>
                                    </v-flex>
                                </v-layout>
                                <v-layout px-12>
                                    <v-flex>
                                        <textarea-form :model="form.changeInTitle" :disabled="!form.hasChangeInTitle"
                                            :rows="10" :background-color="!form.hasChangeInTitle ? '#F9F9F9' : '#FBF6F3'"
                                            @update-data="updateData('changeInTitle', $event)"></textarea-form>
                                    </v-flex>
                                </v-layout>
                                <!-- Group 5 -->
                                <v-layout px-12>
                                    <v-flex :class="!form.hasChangeInInvestigator ? '' : 'color-label-checkbox'" d-flex
                                        justify-start style="font-size: 18px;font-weight: bold">
                                        <v-checkbox v-model="form.hasChangeInInvestigator" hide-details color="#3E7662"
                                            label="Change in Investigators :"
                                            @change="clearCheckbox('hasChangeInInvestigator')"></v-checkbox>
                                    </v-flex>
                                </v-layout>
                                <v-layout px-12 mt-2>
                                    <v-flex style="text-align: left;color: #544A44;">
                                        <span>
                                            (Attach revised consent form., Include CV, ethical training records,
                                            and letter from Sponsor acknowledging change, if applicable)
                                        </span>
                                    </v-flex>
                                </v-layout>
                                <!-- text field append form  -->
                                <v-layout px-12 mt-3>
                                    <v-flex mr-6>
                                        <textfield-form-append css="font-weight: bold"
                                            :disabled="!form.hasChangeInInvestigator" :model="form.changeInInvestigatorAdd"
                                            label="Add Name : "
                                            @update-data="updateData('changeInInvestigatorAdd', $event)"></textfield-form-append>
                                    </v-flex>
                                    <v-flex>
                                        <textfield-form-append css="font-weight: bold"
                                            :disabled="!form.hasChangeInInvestigator"
                                            :model="form.changeInInvestigatorDelete" label="Delete Name : "
                                            @update-data="updateData('changeInInvestigatorDelete', $event)"></textfield-form-append>
                                    </v-flex>
                                </v-layout>
                                <!-- Group 6 -->
                                <v-layout px-12 mt-6>
                                    <v-flex :class="!form.hasChangeInSponsor ? '' : 'color-label-checkbox'" d-flex
                                        justify-start style="font-size: 18px;font-weight: bold">
                                        <v-checkbox v-model="form.hasChangeInSponsor" hide-details color="#3E7662"
                                            label="Change in Sponsor(s) :"
                                            @change="clearCheckbox('hasChangeInSponsor')"></v-checkbox>
                                    </v-flex>
                                </v-layout>
                                <!-- text field append form  -->
                                <v-layout px-12 mt-3>
                                    <v-flex mr-6>
                                        <textfield-form-append :disabled="!form.hasChangeInSponsor" css="font-weight: bold"
                                            :model="form.changeInSponsorAdd" label="Add Sponsor Name : "
                                            @update-data="updateData('changeInSponsorAdd', $event)"></textfield-form-append>
                                    </v-flex>
                                    <v-flex>
                                        <textfield-form-append :disabled="!form.hasChangeInSponsor" css="font-weight: bold"
                                            :model="form.changeInSponsorDelete" label="Delete Sponsor Name : "
                                            @update-data="updateData('changeInSponsorDelete', $event)"></textfield-form-append>
                                    </v-flex>
                                </v-layout>
                                <!-- Group 7 -->
                                <v-layout mt-12 px-12>
                                    <v-flex>
                                        <v-layout>
                                            <v-flex xs6 pl-3 mr-6>
                                                <textfield-form-append :model="form.signature" hide-detail icon="edit"
                                                    @update-data="updateData('signature', $event)"></textfield-form-append>
                                            </v-flex>
                                            <v-flex xs3>
                                                <date-input-form :model="dateChange4" label="Date"
                                                    @update-data="updateDate('date', $event)"></date-input-form>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout px-12 style="margin-top: -20px;font-weight: bold;">
                                            <v-flex xs6>
                                                <span>Signature of Principal Investigator</span>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                                <v-layout mt-12>
                                    <v-flex style="font-weight: bold" d-flex justify-start>
                                        <span>สำนักงานคณะอนุกรรมการพิจารณาโครงการวิจัย พบ.</span>
                                    </v-flex>
                                    <v-flex style="font-weight: bold" d-flex justify-end>
                                        <span>หน้า 1 จาก 1 หน้า</span>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                        </v-layout>

                        <!-- Blog 4 -->
                        <v-layout mt-3 style="background-color: #3E7662;">
                            <v-flex my-8 ml-8 style="text-align: left;font-weight: bold;font-size: 28px;color: white">
                                <div>แบบรายงานการแก้ไขเพิ่มเติมโครงร่างการวิจัย</div>
                                <div style="font-size: 18px;">Amendment Form</div>
                            </v-flex>
                        </v-layout>
                        <v-card class="mb-12 px-12">
                            <v-layout py-12>
                                <v-flex>
                                    <!-- row 1 -->
                                    <v-layout justify-center>
                                        <v-flex xs2 mr-4 pt-2 style="text-align: right;font-weight: bold;">
                                            <span>ชื่อโครงการวิจัย</span>
                                        </v-flex>
                                        <v-flex xs6>
                                            <textfield-form :model="form.researchName2"
                                                @update-data="updateData('researchName2', $event)"></textfield-form>
                                        </v-flex>
                                    </v-layout>
                                    <!-- row 2 -->
                                    <v-layout justify-center>
                                        <v-flex xs2 mr-4 pt-2 style="text-align: right;font-weight: bold;">
                                            <span>ผู้วิจัย</span>
                                        </v-flex>
                                        <v-flex xs6>
                                            <textfield-form :model="form.researcher"
                                                @update-data="updateData('researcher', $event)"></textfield-form>
                                        </v-flex>
                                    </v-layout>
                                    <!-- row 3 -->
                                    <v-layout justify-center>
                                        <v-flex xs2 mr-4 pt-2 style="text-align: right;font-weight: bold;">
                                            <span>เบอร์โทรศัพท์</span>
                                        </v-flex>
                                        <v-flex xs6>
                                            <textfield-form :model="form.phoneNumber"
                                                @update-data="updateData('phoneNumber', $event)"></textfield-form>
                                        </v-flex>
                                    </v-layout>
                                    <!-- row 4 -->
                                    <v-layout justify-center>
                                        <v-flex xs2 mr-4 pt-2 style="text-align: right;font-weight: bold;">
                                            <span>สังกัด</span>
                                        </v-flex>
                                        <v-flex xs6>
                                            <textfield-form :model="form.affiliation"
                                                @update-data="updateData('affiliation', $event)"></textfield-form>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                            </v-layout>
                        </v-card>

                        <!-- Blog 6 -->
                        <v-card>

                        </v-card>
                        <v-layout pb-12>
                            <v-flex>
                                <!-- Table 2 -->
                                <v-layout py-4 style="background-color: #3E7662;">
                                    <v-flex class="text-center font-weight-bold">
                                        <span style="color: white;font-size: 24px">แบบฟอร์มการแก้ไข RF12</span>
                                    </v-flex>
                                </v-layout>
                                <v-layout>
                                    <v-flex>
                                        <v-divider style="background-color: #544A44;border-width: 2px"></v-divider>
                                    </v-flex>
                                </v-layout>
                                <v-layout px-4 py-4 style="background-color: #fff;">
                                    <v-flex>
                                        <v-layout>

                                            <v-flex shrink mr-2 d-flex justify-start align-center>
                                                <span>Version :</span>
                                            </v-flex>
                                            <v-flex xs1 mr-4 d-flex justify-start align-center>
                                                <TextfieldForm :model="information.version"
                                                    @update-data="tableEditUpdateData('version', $event)" hide-detail />
                                            </v-flex>
                                            <v-flex mr-2 shrink d-flex justify-start align-center>
                                                <span>หน้า/บรรทัด :</span>
                                            </v-flex>
                                            <v-flex xs1 mr-4 d-flex justify-start align-center>
                                                <TextfieldForm :model="information.pageAndLine"
                                                    @update-data="tableEditUpdateData('pageAndLine', $event)" hide-detail />
                                            </v-flex>
                                            <v-flex mr-2 shrink d-flex justify-start align-center>
                                                <span>ข้อมูล/ข้อเสนอแนะ :</span>
                                            </v-flex>
                                            <v-flex d-flex justify-start align-center>
                                                <TextfieldForm :model="information.suggestion"
                                                    @update-data="tableEditUpdateData('suggestion', $event)" hide-detail />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout class="mt-6 ">
                                            <v-flex mr-2 shrink d-flex justify-start align-center>
                                                <span>ไฟล์แนบ :</span>
                                            </v-flex>
                                            <v-flex>
                                                <v-file-input accept=".pdf" prepend-icon="" ref="informationFile"
                                                    id="informationFile" :model="information.file" outlined dense clearable
                                                    background-color="#FBF6F3" color="#3E7662" item-color="#3E7662"
                                                    @change="updateFileData('information', 1, $event)"
                                                    hide-details></v-file-input>
                                                <!--<div v-if="information.file" class="mt-1 text-start" style="font-size: 12px;color: #CC3D45;">-->
                                                <!--ไฟล์ที่อัพโหลด:-->
                                                <!--<a :href="information.filePath" target="_blank" class="link-hover">-->
                                                <!--<span>{{information.file}}</span>-->
                                                <!--</a>-->
                                                <!--</div>-->
                                            </v-flex>
                                            <v-flex shrink pl-4 style="text-align: left">
                                                <v-btn color="#3E7662" dark @click="uploadFileBtn('informationFile')">
                                                    <v-icon class="pr-3">cloud_download</v-icon>
                                                    <span>Upload</span>
                                                </v-btn>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                    <v-flex class="text-center align-center d-flex justify-center">
                                        <v-layout>
                                            <v-flex>
                                                <v-btn color="#3E7662" dark @click="addTableEdit" class="mx-12">
                                                    <span>เพิ่มข้อมูล</span>
                                                </v-btn>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout>
                                            <v-flex>
                                                <span style="color: #E39397">*เพิ่มได้มากกว่า 1 รายการ</span>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                                <v-layout>
                                    <v-flex>
                                        <v-divider style="background-color: #544A44;border-width: 2px"></v-divider>
                                    </v-flex>
                                </v-layout>

                                <!-- Table -->
                                <v-layout>
                                    <v-flex class="table">
                                        <v-data-table :headers="researchAllHeaders" :items="itemTableEdit"
                                            hide-default-footer>
                                            <template v-slot:item.file="{ item }">
                                                <a v-if="item.file" :href="item.file" target="_blank"
                                                    style="text-decoration: none">
                                                    <v-icon color="#3E7662" style="cursor: pointer;">visibility</v-icon>
                                                </a>
                                                <v-icon v-if="!item.file" color="grey">visibility</v-icon>
                                            </template>
                                            <template v-slot:item.delete="{ item }">
                                                <v-icon color="#FF0000" @click="deleteRowTableEdit(item)"
                                                    style="cursor: pointer;">delete
                                                </v-icon>
                                            </template>
                                        </v-data-table>
                                    </v-flex>
                                </v-layout>

                                <v-layout>
                                    <v-flex>
                                        <v-divider style="background-color: #544A44;border-width: 2px"></v-divider>
                                    </v-flex>
                                </v-layout>

                            </v-flex>
                        </v-layout>

                        <!-- Blog 7 -->
                        <v-layout pt-12 pb-8 mb-8 style="background-color: white;padding-left: 60px;padding-right: 70px;">
                            <v-flex>


                                <v-layout class="mb-12">
                                    <v-flex>
                                        <v-layout class="mb-8">
                                            <v-flex>
                                                <v-layout>
                                                    <v-flex shrink pt-2 pr-4 style="text-align: left;">
                                                        <span style="font-weight: bold;font-size: 16px;">หนังสือของหน่วยงาน
                                                        </span>
                                                    </v-flex>
                                                    <v-flex>
                                                        <v-file-input accept=".pdf" prepend-icon="" ref="agencyBooks"
                                                            id="agencyBook" :model="form.agencyBook" outlined dense
                                                            clearable background-color="#FBF6F3" color="#3E7662"
                                                            item-color="#3E7662" @change="onUpload"
                                                            hide-details></v-file-input>
                                                        <div v-if="agencyBook" class="mt-1 text-start"
                                                            style="font-size: 12px;color: #CC3D45;">
                                                            ไฟล์ที่อัพโหลด:
                                                            <a :href="agencyBookPath" target="_blank" class="link-hover">
                                                                <span>{{ agencyBook }}</span>
                                                            </a>
                                                        </div>
                                                    </v-flex>
                                                    <v-flex shrink pl-4 style="text-align: left">
                                                        <v-btn color="#3E7662" dark @click="onClickBtn">
                                                            <v-icon class="pr-3">cloud_download</v-icon>
                                                            <span>Upload</span>
                                                        </v-btn>
                                                    </v-flex>
                                                    <v-flex xs3 ml-4><span
                                                            style="color: red">*ชื่อไฟล์ต้องเป็นภาษาอังกฤษเท่านั้น (a-z,0-9)
                                                            ห้ามมีตัวอักษรอื่น </span></v-flex>
                                                </v-layout>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout>
                                            <v-flex xs6>
                                                <v-layout>
                                                    <v-flex class="d-flex align-center mr-4 font-weight-bold" shrink>
                                                        หน่วยงานที่ออกหนังสือ
                                                    </v-flex>
                                                    <v-flex>
                                                        <textfield-form hide-detail :model="form.instituteBook"
                                                            @update-data="updateData('instituteBook', $event)"></textfield-form>
                                                    </v-flex>
                                                </v-layout>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout mt-6>
                                            <v-flex xs6>
                                                <v-layout>
                                                    <v-flex class="d-flex align-center mr-4 font-weight-bold" shrink>
                                                        เลขหนังสือ
                                                    </v-flex>
                                                    <v-flex>
                                                        <textfield-form hide-detail :model="form.bookNumber"
                                                            @update-data="updateData('bookNumber', $event)"></textfield-form>
                                                    </v-flex>
                                                </v-layout>
                                            </v-flex>

                                            <v-flex xs6>
                                                <v-layout>
                                                    <v-flex class="d-flex align-center mx-4 font-weight-bold" shrink>
                                                        วันที่หนังสือ
                                                    </v-flex>
                                                    <v-flex>
                                                        <DateInputForm id="orderDate" hide-detail :model="bookDate"
                                                            @update-data="updateDate('bookDate', $event)"></DateInputForm>
                                                    </v-flex>
                                                </v-layout>
                                            </v-flex>
                                        </v-layout>

                                    </v-flex>
                                </v-layout>
                                <v-layout style="margin-top: 30px">
                                    <v-flex ml-8 my-5 style="text-align: left;">
                                        <span style="font-weight: bold">Note</span>
                                        <span style="color: red"> *รายการที่ต้องแก้ไข</span>
                                    </v-flex>
                                </v-layout>
                                <v-layout>
                                    <v-flex style="padding-left: 32px; padding-right: 32px">
                                        <textarea-form readonly :model="form.noteDetail" :rows="5"></textarea-form>
                                    </v-flex>
                                </v-layout>
                                <v-layout justify-space-between>
                                    <v-flex xs5 pl-3 mr-6>
                                        <textfield-form-append :model="form.researcherSignature" css="font-weight: bold;"
                                            label="ผู้วิจัย" hide-detail
                                            @update-data="updateData('researcherSignature', $event)"></textfield-form-append>
                                    </v-flex>
                                    <v-flex xs5>
                                        <date-input-form :model="dateChange5" label="วันที่รายงาน"
                                            @update-data="updateDate('reportDate', $event)"></date-input-form>
                                    </v-flex>
                                </v-layout>
                                <v-layout mt-8>
                                    <v-flex style="font-weight: bold" d-flex justify-start>
                                        <span>สำนักงานคณะอนุกรรมการพิจารณาโครงการวิจัย พบ.</span>
                                    </v-flex>
                                    <v-flex style="font-weight: bold" d-flex justify-end>
                                        <span>หน้า 1 จาก 1 หน้า</span>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                        </v-layout>

                        <!-- Button Save -->
                        <v-layout>
                            <v-flex mb-12 style="text-align: right;">
                                <v-layout>
                                    <v-flex>
                                        <v-btn style="color: #3E7662" class="mx-4" depressed @click="saveDraft"
                                            color="#ffffff">บันทึกร่าง</v-btn>
                                        <!--<v-btn style="color: #3E7662" class="mx-4" depressed  color="#ffffff" @click="$router.go(-1)">ย้อนกลับ</v-btn>-->
                                        <v-btn depressed dark color="#3E7662" @click="save">
                                            <v-icon class="mr-3">add</v-icon>
                                            <span>บันทึกข้อมูล</span>
                                        </v-btn>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>
                <loading color="#527281" :active.sync="isLoading" :can-cancel="false"
                    :is-full-pageAndLine="fullpageAndLine"></loading>
            </v-flex>
        </v-layout>
    </v-content>
</template>

<script>
import TextfieldForm from "../../components/input/TextfieldForm";
import DateInputForm from "../../components/input/DateInputForm";
import TextareaForm from "../../components/input/TextareaForm";
import Swal from 'sweetalert2';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import TextfieldFormAppend from "../../components/input/TextfieldFormAppend";
import DateInputFormAppend from "../../components/input/DateInputFormAppend";
import { mapState } from "vuex";


export default {
    name: "FormRF06",
    components: {
        TextfieldForm,
        DateInputForm,
        TextareaForm,
        Loading,
        TextfieldFormAppend,
        DateInputFormAppend,
        // editAdd,
    },
    computed: {
        ...mapState({
            researchID: state => state.formRF.researchID
        })
    },
    data: () => ({
        isLoading: false,
        fullpageAndLine: true,
        selected: [],
        dialog: false,
        form: {
            noteDetail: '',
            researchCode: '',
            firstName: '',
            lastName: '',
            researchName: '',
            hasScreening: '1',
            screening: '',
            hasAdjustMethod: '1',
            adjustMethod: '',
            hasAddOperate: '1',
            addOperate: '',
            hasExpandTime: '1',
            expandTime: '',
            hasAdjustOtherMethod: '1',
            adjustOtherMethod: '',
            fileInformationSheet: [],
            fileProtocolModify: [],
            inclusion: false,
            procedureChange: false,
            editorial: false,
            hasOtherChange: false,
            otherChange: '',
            fileInvestigator: [],
            hasNewInformation: false,
            hasAdvertisement: false,
            hasChangeInTitle: false,
            fileOther: [],
            changeInTitle: '',
            hasChangeInInvestigator: false,
            changeInInvestigatorAdd: '',
            changeInInvestigatorDelete: '',
            hasChangeInSponsor: false,
            changeInSponsorAdd: '',
            changeInSponsorDelete: '',
            signature: '',
            date: '',
            researchName2: '',
            researcher: '',
            phoneNumber: '',
            affiliation: '',
            agencyBook: '',
            instituteBook: '',
            bookNumber: '',
            bookDate: '',
            researcherSignature: '',
            reportDate: '',
        },
        researchAllHeaders: [
            { text: 'Version', value: 'version', align: "center" },
            { text: 'หน้า / บรรทัด', value: 'pageAndLine', align: "center" },
            { text: 'ข้อมูลเดิม / ข้อเสนอแนะ', value: 'suggestion', align: "center" },
            { text: 'ดูไฟล์', value: 'file', align: "center" },
            { text: 'ลบ', value: 'delete', align: "center" },
        ],
        itemResearchAll: [],
        itemTableEdit: [],
        edit: {
            version: '',
            pageAndLine: '',
            suggestion: '',
            file: '',
        },
        information: {
            version: '',
            pageAndLine: '',
            suggestion: '',
            file: '',
        },
        dateChange1: '',
        dateChange2: '',
        dateChange3: '',
        dateChange4: '',
        dateChange5: '',
        agencyBook: '',
        agencyBookPath: '',
        fileInformationSheet: '',
        fileProtocolModify: '',
        fileInvestigator: '',
        fileInformationSheetPath: '',
        fileProtocolModifyPath: '',
        fileInvestigatorPath: '',
        bookDate: '',
        dynamicDateChange: {
            fileInformationSheet: [],
            fileProtocolModify: [],
            fileInvestigator: [],
            fileOther: []
        },
    }),
    created() {
        // Check Permission
        // if (!this.$route.query.researchID && this.$route.query.status !== 'อนุมัติ') {
        //     this.$router.go(-1);
        // } else {
        // Set data User
        this.form.researchCode = this.$route.query.researchCode;
        this.form.researchName = this.$route.query.nameResearch;
        this.form.researchName2 = this.$route.query.nameResearch;
        this.form.researcherSignature = this.$route.query.thName;
        this.form.signature = this.$route.query.thName;
        this.form.researcher = this.$route.query.thName;
        this.form.phoneNumber = this.$route.query.mobilePhone;
        this.form.affiliation = this.$route.query.affiliation;
        this.updateDate('reportDate', new Date().toISOString().slice(0, 10));
        this.updateDate('date', new Date().toISOString().slice(0, 10));
        if (this.$route.query.thName) {
            let text = this.$route.query.thName.split(" ");
            this.form.firstName = text[0];
            this.form.lastName = text[1];
        }
        if (this.$route.query.rfID) {
            this.getRF12(this.$route.query.rfID);
        }
        // }
    },
    methods: {
        updateData(name, val) {
            console.log("test", val);
            this.form[name] = val;
        },
        onUpload(e) {
            this.$store.dispatch('Common/uploadPdf', { form: 'rf12', file: e }).then(res => {
                this.form.agencyBook = res.fileName;
            }).catch(error => {
            });
        },
        uploadFile(name, e) {
            this.$store.dispatch('Common/uploadPdf', { form: 'rf12-file', file: e }).then(res => {
                if (name === 'fileInformationSheet')
                    this.form.fileInformationSheet = res.fileName;
                else if (name === 'fileInvestigator')
                    this.form.fileInvestigator = res.fileName;
                else if (name === 'fileProtocolModify')
                    this.form.fileProtocolModify = res.fileName;
            }).catch(error => {
            });
        },
        uploadFileBtn(name) {
            document.getElementById(name).click();
        },
        onClickBtn() {
            document.getElementById('agencyBook').click();
        },
        updateFileData(type, index, value) {
            if (type === 'title') {
                this.form.fileOther[index].title = value;
            } else if (type === 'information') {
                this.$store.dispatch('Common/uploadPdf', { form: 'rf12-file', file: value }).then(res => {
                    this.information.file = res.filePath;
                }).catch(error => {
                });
            } else {
                this.$store.dispatch('Common/uploadPdf', { form: 'rf12-file', file: value }).then(res => {
                    this.form.fileOther[index].file = res.fileName;
                }).catch(error => {
                });
            }
        },
        updateDynamicFileData(name, type, index, value) {
            if (type === 'file') {
                this.isLoading = true;
                this.$store.dispatch('Common/uploadPdf', { form: 'rf12-file', file: value }).then(res => {
                    this.form[name][index].file = res.fileName;
                    this.isLoading = false;
                }).catch(error => {
                    this.isLoading = false;
                });
            } else if (type === 'date') {
                this.form[name][index][type] = value;
                this.dynamicDateChange[name].splice(index, 1, this.changeDate(value));
            } else {
                this.form[name][index][type] = value;
            }
        },
        updateDate(name, val) {
            if (name === "informationSheetDate") {
                this.form.informationSheetDate = val;
                this.dateChange1 = this.changeDate(val);
            }
            if (name === "protocolModifyDate") {
                this.form.protocolModifyDate = val;
                this.dateChange2 = this.changeDate(val);
            }
            if (name === "investigatorDate") {
                this.form.investigatorDate = val;
                this.dateChange3 = this.changeDate(val);
            }
            if (name === "date") {
                this.form.date = val;
                this.dateChange4 = this.changeDate(val);
            }
            if (name === "reportDate") {
                this.form.reportDate = val;
                this.dateChange5 = this.changeDate(val);
            }
            if (name === "bookDate") {
                this.form.bookDate = val;
                this.bookDate = this.changeDate(val);
            }
        },
        changeDate(val) {
            let sub_year = val.substring(0, 4);
            let sub_month = val.substring(5, 7);
            let sub_day = val.substring(8, 10);
            sub_year = parseInt(sub_year) + 543;
            let changeDate = sub_day + '/' + sub_month + '/' + sub_year;
            return changeDate;
        },
        tableUpdateData(name, val) {
            this.edit[name] = val;
        },
        tableEditUpdateData(name, val) {
            this.information[name] = val;
        },
        getRF12(id) {
            this.isLoading = true;
            this.$store.dispatch("FormRF/getRF12", id).then(res => {
                if (res !== "don't have research amendment yet") {
                    if (res.status !== 'อนุมัติ') {
                        console.log("res", res);
                        this.form.instituteBook = res.instituteBook;
                        this.form.noteDetail = res.noteDetail;
                        this.form.researchCode = res.researchCode;
                        this.form.firstName = res.firstName;
                        this.form.lastName = res.lastName;
                        this.form.researchName = res.researchName;
                        this.form.hasScreening = res.hasScreening.toString();
                        this.form.screening = res.screening;
                        this.form.hasAdjustMethod = res.hasAdjustMethod.toString();
                        this.form.adjustMethod = res.adjustMethod;
                        this.form.hasAddOperate = res.hasAddOperate.toString();
                        this.form.addOperate = res.addOperate;
                        this.form.hasExpandTime = res.hasExpandTime.toString();
                        this.form.expandTime = res.expandTime;
                        this.form.hasAdjustOtherMethod = res.hasAdjustOtherMethod.toString();
                        this.form.adjustOtherMethod = res.adjustOtherMethod;
                        this.form.fileInformationSheet = res.fileInformationSheet ?? [];
                        this.fileInformationSheet = res.fileInformationSheet ?? [];
                        this.fileInformationSheetPath = res.fileInformationSheetPath;
                        this.dateChange1 = !res.informationSheetDate ? '' : this.changeDate(res.informationSheetDate);
                        this.form.fileProtocolModify = res.fileProtocolModify ?? [];
                        this.fileProtocolModify = res.fileProtocolModify ?? [];
                        this.fileProtocolModifyPath = res.fileProtocolModifyPath;
                        this.dateChange2 = !res.protocolModifyDate ? '' : this.changeDate(res.protocolModifyDate);
                        this.form.inclusion = res.inclusion == 1;
                        this.form.procedureChange = res.procedureChange == 1;
                        this.form.editorial = res.editorial == 1;
                        this.form.hasOtherChange = res.hasOtherChange == 1;
                        this.form.otherChange = res.otherChange;
                        this.form.fileInvestigator = res.fileInvestigator ?? [];
                        this.fileInvestigator = res.fileInvestigator ?? [];
                        this.fileInvestigatorPath = res.fileInvestigatorPath;
                        this.dateChange3 = !res.investigatorDate ? '' : this.changeDate(res.investigatorDate);
                        this.form.hasNewInformation = res.hasNewInformation == 1;
                        this.form.hasAdvertisement = res.hasAdvertisement == 1;
                        this.form.hasChangeInTitle = res.hasChangeInTitle == 1;
                        this.form.changeInTitle = res.changeInTitle;
                        this.form.hasChangeInInvestigator = res.hasChangeInInvestigator == 1;
                        this.form.changeInInvestigatorAdd = res.changeInInvestigatorAdd;
                        this.form.changeInInvestigatorDelete = res.changeInInvestigatorDelete;
                        this.form.hasChangeInSponsor = res.hasChangeInSponsor == 1;
                        this.form.changeInSponsorAdd = res.changeInSponsorAdd;
                        this.form.changeInSponsorDelete = res.changeInSponsorDelete;
                        this.form.signature = res.signature;
                        this.form.date = res.date;
                        this.dateChange4 = !res.date ? '' : this.changeDate(res.date);
                        this.form.researchName2 = res.researchName2;
                        this.form.researcher = res.researcher;
                        this.form.phoneNumber = res.phoneNumber;
                        this.form.affiliation = res.affiliation;
                        this.form.agencyBook = res.agencyBook;
                        this.agencyBook = res.agencyBook;
                        this.agencyBookPath = res.agencyBookPath;
                        this.form.bookNumber = res.bookNumber;
                        this.form.bookDate = res.bookDate;
                        this.form.fileOther = res.fileOther ?? [];
                        this.bookDate = this.changeDate(res.bookDate);
                        this.form.researcherSignature = res.researcherSignature;
                        this.form.reportDate = res.reportDate;
                        this.getDynamicChangeDate('fileInformationSheet', res.fileInformationSheet);
                        this.getDynamicChangeDate('fileProtocolModify', res.fileProtocolModify);
                        this.getDynamicChangeDate('fileInvestigator', res.fileInvestigator);
                        this.getDynamicChangeDate('fileOther', res.fileOther);
                        this.dateChange5 = !res.reportDate ? '' : this.changeDate(res.reportDate);
                        if (res.research_amendment_edit) {
                            let i = 0;
                            res.research_amendment_edit.forEach(data => {
                                this.itemTableEdit.push({
                                    index: i++,
                                    version: data.version,
                                    pageAndLine: data.pageAndLine,
                                    suggestion: data.suggestion,
                                    file: data.file
                                });
                            })
                        }
                    }
                }
                this.isLoading = false;
            }).catch(e => {
                this.isLoading = false;
            })
        },
        save() {
            Swal.fire({
                title: 'คุณต้องการบันทึกหรือไม่?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3E7662',
                cancelButtonColor: 'rgb(189, 189, 189)',
                confirmButtonText: 'บันทึก',
                cancelButtonText: 'ยกเลิก'
            }).then((result) => {
                if (result.value) {
                    this.form['researchID'] = this.$route.query.researchID;
                    if (this.$route.query.rfID) {
                        this.form['methods'] = 'edit';
                        this.form['rfID'] = this.$route.query.rfID;
                    } else {
                        this.form['methods'] = 'save';
                    }
                    this.form['edit'] = this.itemResearchAll;
                    this.form['information'] = this.itemTableEdit;
                    this.isLoading = true;
                    console.log(this.form);
                    this.$store.dispatch("FormRF/saveRF12", this.form).then(res => {
                        this.isLoading = false;
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: 'บันทึกสำเร็จ',
                            showConfirmButton: false,
                            timer: 2000
                        }).then(() => {
                            this.$router.push('/profile');
                        })
                    }).catch(e => {
                        this.isLoading = false;
                        if (e.response.data.errors) {
                            for (var k in e.response.data.errors) {
                                Swal.fire({
                                    title: "เกิดข้อผิดพลาด",
                                    text: e.response.data.errors[k][0],
                                    icon: "warning",
                                    confirmButtonColor: "#3E7662",
                                }).then(() => {
                                    this.$vuetify.goTo(`#${k}`);
                                });
                            }
                        }
                        // Swal.fire({
                        //     title: 'Save failed!',
                        //     text: 'บันทึกไม่สำเร็จ',
                        //     icon: 'warning',
                        // }).then(() => {
                        //     this.isLoading = false;
                        // })
                    })
                }
                this.isLoading = false;
            });
        },
        saveDraft() {
            Swal.fire({
                title: 'คุณต้องการบันทึกแบบร่างหรือไม่?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3E7662',
                cancelButtonColor: 'rgb(189, 189, 189)',
                confirmButtonText: 'บันทึก',
                cancelButtonText: 'ยกเลิก'
            }).then((result) => {
                if (result.value) {
                    this.form['researchID'] = this.$route.query.researchID;
                    if (this.$route.query.rfID) {
                        this.form["methods"] = "edit";
                        this.form["rfID"] = this.$route.query.rfID;
                    } else {
                        this.form["methods"] = "save";
                        this.form["status"] = "บันทึกแบบร่าง";
                    }
                    this.form['edit'] = this.itemResearchAll;
                    this.form['information'] = this.itemTableEdit;
                    this.isLoading = true;
                    this.$store.dispatch("FormRF/saveRF12", this.form).then(res => {
                        this.isLoading = false;
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: 'บันทึกแบบร่างสำเร็จ',
                            showConfirmButton: false,
                            timer: 2000
                        }).then(() => {
                            this.$router.push('/profile');
                        })
                    }).catch(e => {
                        this.isLoading = false;
                        if (e.response.data.errors) {
                            for (var k in e.response.data.errors) {
                                Swal.fire({
                                    title: "เกิดข้อผิดพลาด",
                                    text: e.response.data.errors[k][0],
                                    icon: "warning",
                                    confirmButtonColor: "#3E7662",
                                }).then(() => {
                                    this.$vuetify.goTo(`#${k}`);
                                });
                            }
                        }
                        // Swal.fire({
                        //     title: 'Save failed!',
                        //     text: 'บันทึกไม่สำเร็จ',
                        //     icon: 'warning',
                        // }).then(() => {
                        //     this.isLoading = false;
                        // })
                    })
                }
                this.isLoading = false;
            });
        },
        otherChangeCheckbox() {
            if (!this.form.hasOtherChange) {
                this.form.otherChange = '';
            }
        },
        changeTitleCheckbox() {
            if (!this.form.hasChangeInTitle) {
                this.form.changeInTitle = '';
            }
        },
        clearCheckbox(name) {
            if (name === 'hasChangeInInvestigator') {
                if (!this.form.hasChangeInInvestigator) {
                    this.form.changeInInvestigatorAdd = '';
                    this.form.changeInInvestigatorDelete = '';
                }
            }
            if (name === 'hasChangeInSponsor') {
                if (!this.form.hasChangeInSponsor) {
                    this.form.changeInSponsorAdd = '';
                    this.form.changeInSponsorDelete = '';
                }
            }

        },
        clearRedio(name) {
            if (name === 'hasAddOperate') {
                this.form.addOperate = '';
                this.form.hasAddOperate = '1';
            }
            if (name === 'hasExpandTime') {
                this.form.expandTime = '';
                this.form.hasExpandTime = '1';
            }
            if (name === 'hasAdjustOtherMethod') {
                this.form.adjustOtherMethod = '';
                this.form.hasAdjustOtherMethod = '1';
            }
            if (name === 'hasAdjustMethod') {
                this.form.adjustMethod = '';
                this.form.hasAdjustMethod = '1';
            }
            if (name === 'hasScreening') {
                this.form.screening = '';
                this.form.hasScreening = '1';
            }

        },
        addFile(name) {
            this.form[name].push({ title: '', file: null });
        },
        getDynamicChangeDate(name, data) {
            if (data) {
                data.forEach((item, i) => {
                    if (item.date)
                        this.dynamicDateChange[name][i] = this.changeDate(item.date);
                });
            }
        },
        addDynamicFile(name) {
            this.form[name].push({ title: '', file: null, version: '', date: '' });
            this.dynamicDateChange[name].push();
        },
        removeDynamicFile(name, index) {
            this.form[name].splice(index, 1);
            this.dynamicDateChange[name].splice(index, 1);
        },
        removeFile(index) {
            this.form.fileOther.splice(index, 1);
        },

        add() {
            if (this.edit.version && this.edit.pageAndLine && this.edit.suggestion) {
                this.itemResearchAll.push({
                    index: new Date().valueOf(),
                    version: this.edit.version,
                    pageAndLine: this.edit.pageAndLine,
                    suggestion: this.edit.suggestion,
                    file: this.edit.file,
                })
            } else {
                Swal.fire({
                    title: 'กรุณากรอกข้อมูลให้ครบ',
                    icon: 'warning',
                    confirmButtonColor: '#3E7662'
                })
            }

        },
        addTableEdit() {
            if (this.information.version || this.information.pageAndLine || this.information.suggestion || this.information.file) {
                this.itemTableEdit.push({
                    index: new Date().valueOf(),
                    version: this.information.version ? this.information.version : '-',
                    pageAndLine: this.information.pageAndLine ? this.information.pageAndLine : '-',
                    suggestion: this.information.suggestion ? this.information.suggestion : '-',
                    file: this.information.file ? this.information.file : '',
                })
                this.information.version = '';
                this.information.pageAndLine = '';
                this.information.suggestion = '';
                this.information.file = '';
                this.$refs.informationFile.value = null;
            } else {
                Swal.fire({
                    title: 'กรุณากรอกข้อมูลให้ครบ',
                    icon: 'warning',
                    confirmButtonColor: '#3E7662'
                })
            }

        },
        deleteRow(val) {
            this.itemResearchAll = this.itemResearchAll.filter(item => item.index !== val.index)
        },
        deleteRowTableEdit(val) {
            this.itemTableEdit = this.itemTableEdit.filter(item => item.index !== val.index)
        },
    },
}
</script>

<style lang="scss" >
label.v-label.theme--light {
    color: #544A44;
}

.color-label-checkbox {
    label.v-label.theme--light {
        color: #3E7662;
    }
}

.link-hover:hover {
    cursor: pointer;
    text-decoration: underline;
}

.link-hover {
    color: #3e7662;
    text-decoration: none;
}
</style>
