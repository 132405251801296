<template>
    <v-content>
        <v-layout class="font-weight-bold">
            <v-flex shrink pt-2 v-if="label" style="text-align: right;font-weight: bold">
                <span class="mr-3">{{label}}</span>
            </v-flex>
            <v-flex class="text-input font-weight-bold">
                <v-text-field
                        :id="id"
                        :placeholder="placeholder"
                        :disabled="disabled"
                        :value="model"
                        :readonly="readonly"
                        @input="onChange"
                        :background-color="backgroundColor"
                        :type="type"
                        item-color="#3E7662"
                        color="#3E7662"
                        outlined
                        :hide-details="hideDetail"
                        dense
                        :min='min'
                        :max='max'
                ></v-text-field>
            </v-flex>
        </v-layout>
    </v-content>
</template>

<script>
    export default {
        name: "TextfieldForm",
        props: {
            rules:{
                type: String,
                default: ''
            },
            id: String,
            min: Number,
            max: Number,
            type: {
              type: String,
            },
            model: {
                required: false
            },
            placeholder: String,
            backgroundColor:{
                type: String,
                default: '#FBF6F3'
            },
            color: String,
            readonly: {
                type: Boolean
            },
            hideDetail: {
                type: Boolean,
                default: false,
            },
            label: {
                type: String,
            },
            disabled: {
                type: Boolean
            },
        },
        methods: {
            onChange(val) {
                this.$emit("update-data", val);
            },
        }
    }
</script>

<style scoped>

</style>
