<template>
    <v-content>
        <v-layout>
            <v-flex style="background-color: #EFEAE7;padding: 2% 5% 2% 5%">
                <v-card>
                    <v-layout pa-8 justify-center style="background-color: #3E7662;">
                        <v-flex style="text-align: left;" >
                            <span style="font-weight: bold;font-size: 28px;color: white;">แบบประวัติผู้วิจัย</span>
                        </v-flex>
                        <v-flex style="text-align: right;">
                            <span style="font-size: 14px;color: white;">Version……….Date…………………….</span><br>
                            <span style="font-size: 14px;color: white;">RF 10_2560</span>
                        </v-flex>
                    </v-layout>

                    <v-layout>
                        <v-flex px-12 py-6>
                            <v-layout>
                                <v-flex xs6 pb-6 style="text-align: left;">
                                    <span style="font-weight: bold;font-size: 16px;">18. ระยะเวลาที่จะทำการวิจัยและการบริหารจัดการ</span>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex xs6 pb-2 pl-8 style="text-align: left;">
                                    <span style="font-weight: bold;font-size: 16px;color: #3E7662">1. การจัดเวลาในการวิจัย</span>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex xs6 pb-2 style="text-align: left;">
                                    <span style="font-weight: bold;font-size: 16px;">การเตรียมข้อมูลเบื้องต้น</span>
                                </v-flex>
                            </v-layout>
                            <textarea-form
                                    :model="formResearch3.initial_information_preparation"
                                    @update-data="updateFormResearchData('initial_information_preparation', $event)"
                            ></textarea-form>

                            <v-layout>
                                <v-flex xs6 pb-2 style="text-align: left;">
                                    <span style="font-weight: bold;font-size: 16px;">เวลาดำเนินการวิจัย</span>
                                </v-flex>
                            </v-layout>
                            <TextareaForm
                                    :model="formResearch3.research_time"
                                    @update-data="updateFormResearchData('research_time', $event)"
                            ></TextareaForm>

                            <v-layout>
                                <v-flex xs6 pb-2 style="text-align: left;">
                                    <span style="font-weight: bold;font-size: 16px;">เวลาการรวบรวมและวิเคราะห์ข้อมูล</span>
                                </v-flex>
                            </v-layout>
                            <TextareaForm
                                    :model="formResearch3.data_collection_and_analysis_time"
                                    @update-data="updateFormResearchData('data_collection_and_analysis_time', $event)"
                            ></TextareaForm>

                            <v-layout>
                                <v-flex xs6 pb-2 style="text-align: left;">
                                    <span style="font-weight: bold;font-size: 16px;">การนำเสนอผลงานการวิจัยและเขียนรายงาน</span>
                                </v-flex>
                            </v-layout>
                            <TextareaForm
                                    :model="formResearch3.research_presentations_and_report_writing"
                                    @update-data="updateFormResearchData('research_presentations_and_report_writing', $event)"
                            ></TextareaForm>

                            <v-layout>
                                <v-flex xs6 pb-2 pt-8 pl-8 style="text-align: left;">
                                    <span style="font-weight: bold;font-size: 16px;color: #3E7662">2. บุคลากรที่จะใช้ในการวิจัย</span>
                                </v-flex>
                            </v-layout>
                            <TextareaForm
                                    :model="formResearch3.personnel_research"
                                    @update-data="updateFormResearchData('personnel_research', $event)"
                            ></TextareaForm>

                            <!-- Chioce 19 -->
                            <v-layout>
                                <v-flex xs6 py-12 style="text-align: left;">
                                    <span style="font-weight: bold;font-size: 16px;">19. แหล่งทุนและงบประมาณในการวิจัย</span>
                                </v-flex>
                            </v-layout>

                            <v-layout mx-8 justify-space-between>
                                <v-flex shrink pt-2 mr-3 style="color: #3E7662;font-weight: bold">
                                    <span>กรุณาเลือกเเหล่งทุนวิจัย</span>
                                </v-flex>
                                <v-flex xs4 class="field-select">
<!--                                    <MultiselectForm-->
<!--                                            placeholder="กรุณาเลือก"-->
<!--                                            :options="test"-->
<!--                                            label="name"-->
<!--                                            track-by="name"-->
<!--                                            :model="formResearch3.research_fund"-->
<!--                                            @update-data="updateFormResearchData('research_fund', $event)"-->
<!--                                    ></MultiselectForm>-->
                                    <v-select
                                            clearable
                                            label="ผู้วิจัย / ที่ปรึกษา"
                                            :menu-props="{ bottom: true, offsetY: true }"
                                            v-model="formResearch3.budget"
                                            background-color="#3E7662"
                                            solo
                                            flat
                                            dense
                                            :items="budget_list"
                                            item-text="budget_name"
                                            item-value="budget_id"
                                            :value-comparator="findMatchingProvince"
                                    ></v-select>
                                </v-flex>

                                <v-spacer></v-spacer>

                                <v-flex ml-8 mr-3 shrink pt-2 style="color: #3E7662;font-weight: bold">
                                    <span>ยอดรวม</span>
                                </v-flex>
                                <v-flex xs4 d-flex justify-end>
                                    <textfield-form
                                            :model="formResearch3.amount"
                                            @update-data="updateFormResearchData('amount', $event)"
                                    ></textfield-form>
                                </v-flex>
                                <v-flex ml-3 shrink pt-2 style="color: #3E7662;font-weight: bold">
                                    <span>บาท</span>
                                </v-flex>
                            </v-layout>

<!--                            <v-layout>-->
<!--                                <v-flex xs6 pb-4 pl-8 style="text-align: left;">-->
<!--                                    <span style="font-weight: bold;font-size: 16px;color: #3E7662">1. ค่าใช้จ่ายด้านอุปกรณ์การวิจัย</span>-->
<!--                                </v-flex>-->
<!--                            </v-layout>-->
<!--                            <TextfieldForm-->
<!--                                    :model="formResearch3.research_equipment_expenses"-->
<!--                                    @update-data="updateFormResearchData('research_equipment_expenses', $event)"-->
<!--                            ></TextfieldForm>-->

<!--                            <v-layout>-->
<!--                                <v-flex xs6 pb-4 pl-8 style="text-align: left;">-->
<!--                                    <span style="font-weight: bold;font-size: 16px;color: #3E7662">2. ค่าใช้จ่ายด้านบุคลากร</span>-->
<!--                                </v-flex>-->
<!--                            </v-layout>-->
<!--                            <TextfieldForm-->
<!--                                    :model="formResearch3.personnel_expenses"-->
<!--                                    @update-data="updateFormResearchData('personnel_expenses', $event)"-->
<!--                            ></TextfieldForm>-->

<!--                            <v-layout>-->
<!--                                <v-flex xs6 pb-4 pl-8 style="text-align: left;">-->
<!--                                    <span style="font-weight: bold;font-size: 16px;color: #3E7662">3. ค่าเดินทาง</span>-->
<!--                                </v-flex>-->
<!--                            </v-layout>-->
<!--                            <TextfieldForm-->
<!--                                    :model="formResearch3.travel_expenses"-->
<!--                                    @update-data="updateFormResearchData('travel_expenses', $event)"-->
<!--                            ></TextfieldForm>-->

<!--                            <v-layout>-->
<!--                                <v-flex xs6 pb-4 pl-8 style="text-align: left;">-->
<!--                                    <span style="font-weight: bold;font-size: 16px;color: #3E7662">4. ค่าวิเคราะห์ข้อมูล</span>-->
<!--                                </v-flex>-->
<!--                            </v-layout>-->
<!--                            <TextfieldForm-->
<!--                                    :model="formResearch3.data_analysis_fee"-->
<!--                                    @update-data="updateFormResearchData('data_analysis_fee', $event)"-->
<!--                            ></TextfieldForm>-->

<!--                            <v-layout>-->
<!--                                <v-flex xs6 pb-4 pl-8 style="text-align: left;">-->
<!--                                    <span style="font-weight: bold;font-size: 16px;color: #3E7662">5. ค่าครุภัณฑ์</span>-->
<!--                                </v-flex>-->
<!--                            </v-layout>-->
<!--                            <TextfieldForm-->
<!--                                    :model="formResearch3.durable_goods"-->
<!--                                    @update-data="updateFormResearchData('durable_goods', $event)"-->
<!--                            ></TextfieldForm>-->

<!--                            <v-layout>-->
<!--                                <v-flex xs6 pb-4 pl-8 style="text-align: left;">-->
<!--                                    <span style="font-weight: bold;font-size: 16px;color: #3E7662">6. ค่าตีพิมพ์ผลการวิจัย</span>-->
<!--                                </v-flex>-->
<!--                            </v-layout>-->
<!--                            <TextfieldForm-->
<!--                                    :model="formResearch3.publication_fees_research_results"-->
<!--                                    @update-data="updateFormResearchData('publication_fees_research_results', $event)"-->
<!--                            ></TextfieldForm>-->

<!--                            <v-layout>-->
<!--                                <v-flex xs6 pb-4 pl-8 style="text-align: left;">-->
<!--                                    <span style="font-weight: bold;font-size: 16px;color: #3E7662">7. อื่นๆ</span>-->
<!--                                </v-flex>-->
<!--                            </v-layout>-->
<!--                            <TextfieldForm-->
<!--                                    :model="formResearch3.other"-->
<!--                                    @update-data="updateFormResearchData('other', $event)"-->
<!--                            ></TextfieldForm>-->

<!--                            <v-layout>-->
<!--                                <v-flex xs6 pb-4 pl-8 style="text-align: left;">-->
<!--                                    <span style="font-weight: bold;font-size: 16px;color: #3E7662">8. รวม</span>-->
<!--                                </v-flex>-->
<!--                            </v-layout>-->
<!--                            <TextfieldForm-->
<!--                                    :model="formResearch3.sum"-->
<!--                                    @update-data="updateFormResearchData('sum', $event)"-->
<!--                            ></TextfieldForm>-->

                            <v-layout>
                                <v-flex pl-8 style="text-align: left;padding-top: 50px;padding-bottom: 100px">
                                    <span style="font-weight: bold;font-size: 16px;color:#CC3D45;text-decoration:underline">
                                        ควรระบุด้วยว่าใครจะเป็นผู้บริหารงบประมาณ สำนักงานบริหารงบประมาณอยู่ที่ไหน
                                    </span>
                                </v-flex>
                            </v-layout>


                            <v-layout pt-12>
                                <v-flex style="text-align: left;">
                                    <span>สำนักงานคณะอนุกรรมการพิจารณาโครงการวิจัย พบ.</span>
                                </v-flex>
                                <v-flex style="text-align: right;">
                                    <span>หน้า 4 จาก 5 หน้า</span>
                                </v-flex>
                            </v-layout>


                        </v-flex>
                    </v-layout>

                </v-card>
            </v-flex>
        </v-layout>

        <v-layout>
            <v-flex mb-12 style="padding: 0 5% 2% 5%; text-align: right;">
                <v-layout>
                    <v-flex>
                        <v-btn style="color: #3E7662" class="mx-4" depressed  color="#ffffff" @click="backPage">ย้อนกลับ</v-btn>
                        <v-btn depressed  dark color="#3E7662" @click="nextPage">
                            <v-icon class="mr-3">add</v-icon>
                            <span>บันทึกและถัดไป</span>
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
        <loading
                color="#527281"
                :active.sync="isLoading"
                :can-cancel="false"
                :is-full-page="fullPage"
        ></loading>
    </v-content>
</template>

<script>
    import TextareaForm from "./input/TextareaForm";
    import TextfieldForm from "./input/TextfieldForm";
    import Swal from 'sweetalert2';
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import {mapState} from "vuex"

    export default {
        name: "FormCreateResearchPage1",
        components: {
            TextareaForm,
            TextfieldForm,
            Loading,
            // MultiselectForm,
        },
        computed: {
            ...mapState({
                research_id: state => state.FormResearch.research_id,
            })
        },
        created() {
            this.getBudgetList();
        },
        data: () => ({
            isLoading: false,
            fullPage: true,
            formResearch3: {
                initial_information_preparation: '',
                research_time: '',
                data_collection_and_analysis_time: '',
                research_presentations_and_report_writing: '',
                personnel_research: '',
                // research_equipment_expenses: '',
                // personnel_expenses: '',
                // travel_expenses: '',
                // data_analysis_fee: '',
                // durable_goods: '',
                // publication_fees_research_results: '',
                // other: '',
                // sum: '',
                budgetResourceID:'',
                amount:'',
                // research_fund: '',
                // amount: '',
            },
            form_api: {},
            budget_list: [],
        }),
        methods: {
            updateFormResearchData(name,val) {
                this.formResearch3[name] = val;
            },
            findMatchingProvince(a,b) {
                return a==b;
            },
            nextPage() {
                this.isLoading = true;
                this.form_api['research_info'] =  [
                    {subTopicID: 18,  answer:this.formResearch3.initial_information_preparation},
                    {subTopicID: 19, answer:this.formResearch3.research_time},
                    {subTopicID: 20, answer:this.formResearch3.data_collection_and_analysis_time},
                    {subTopicID: 21, answer:this.formResearch3.research_presentations_and_report_writing},
                    {subTopicID: 22, answer:this.formResearch3.personnel_research},
                    {subTopicID: 23, answer:this.formResearch3.budgetResourceID},
                    {subTopicID: 24, answer:this.formResearch3.amount},
                    // {subTopicID: 23, answer:this.formResearch3.research_equipment_expenses},
                    // {subTopicID: 24, answer:this.formResearch3.personnel_expenses},
                    // {subTopicID: 25, answer:this.formResearch3.travel_expenses},
                    // {subTopicID: 26, answer:this.formResearch3.data_analysis_fee},
                    // {subTopicID: 27, answer:this.formResearch3.durable_goods},
                    // {subTopicID: 28, answer:this.formResearch3.publication_fees_research_results},
                    // {subTopicID: 29, answer:this.formResearch3.other},
                    // {subTopicID: 30, answer:this.formResearch3.sum},
                ];
                this.form_api['research_id'] = this.research_id;
                this.$store.dispatch("FormResearch/saveResearch3", this.form_api).then(res => {
                    this.$store.dispatch("FormResearch/changePage", 4);
                    this.isLoading = false;
                }).catch(e => {
                    Swal.fire({
                        title: 'Save failed!',
                        text: 'บันทึกไม่สำเร็จ',
                        icon: 'warning',
                    }).then(() => {
                        this.isLoading = false;
                    })
                })
            },
            backPage() {
                this.$store.dispatch("FormResearch/changePage", 2)
            },
            // Get Budget List
            getBudgetList() {
                this.isLoading = true;
                this.$store.dispatch('Common/getBudgetList').then(res => {
                    res.all_researcher_data.forEach(data => {
                        this.budget_list.push({
                            budget_id: data.id,
                            budget_name: data.thName,
                        });
                    })
                    this.isLoading = false;
                }).catch(e => {
                    this.isLoading = false;
                })
            },
            setData() {
                    this.formResearch3.initial_information_preparation = 'การเตรียมข้อมูลเบื้องต้น';
                    this.formResearch3.research_time = 'เวลาดำเนินการวิจัย';
                    this.formResearch3.data_collection_and_analysis_time = 'เวลาการรวบรวมและวิเคราะห์ข้อมูล';
                    this.formResearch3.research_presentations_and_report_writing = 'การนำเสนอผลงานการวิจัยและเขียนรายงาน';
                    this.formResearch3.personnel_research = 'บุคลากรที่จะใช้ในการวิจัย';
                    this.formResearch3.research_equipment_expenses = 'ค่าใช้จ่ายด้านอุปกรณ์การวิจัย';
                    this.formResearch3.personnel_expenses = 'ค่าใช้จ่ายด้านบุคลากร';
                    this.formResearch3.travel_expenses = 'ค่าเดินทาง';
                    this.formResearch3.data_analysis_fee = 'ค่าวิเคราะห์ข้อมูล';
                    this.formResearch3.durable_goods = 'ค่าครุภัณฑ์';
                    this.formResearch3.publication_fees_research_results = 'ค่าตีพิมพ์ผลการวิจัย';
                    this.formResearch3.other = 'อื่นๆ';
                    this.formResearch3.sum = 'รวม';
            }
        },
    }
</script>

<style scoped>

</style>
