<template>
    <v-content>
        <v-layout>
            <v-flex style="background-color: #EFEAE7;padding: 2% 5% 2% 5%">
                <v-card>
                    <v-layout pa-8 justify-center style="background-color: #3E7662;">
                        <v-flex style="text-align: left;">
                            <span style="font-weight: bold;font-size: 28px;color: white;">แบบโครงร่างการวิจัย</span>
                        </v-flex>
                        <v-flex style="text-align: right;">
                            <span style="font-size: 14px;color: white;">Version……….Date…………………….</span><br>
                            <span style="font-size: 14px;color: white;">RF 10_2560</span>
                        </v-flex>
                    </v-layout>

                    <v-layout>
                        <v-flex px-12 py-6>
                            <!-- choice 1 -->
                            <v-layout>
                                <v-flex style="text-align: left;">
                                    <span style="font-weight: bold;font-size: 16px;">1. ชื่อโครงการ</span>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex pt-2 style="text-align: left;">
                                    <span class="pl-3"
                                        style="font-weight: bold;font-size: 16px;color: #CC3D45;">*ภาษาไทย</span>
                                </v-flex>
                            </v-layout>
                            <TextfieldForm :model="form.researchs.thResearchName"
                                @update-data="updateDataResearchs('thResearchName', $event)"></TextfieldForm>

                            <v-layout>
                                <v-flex style="text-align: left;">
                                    <span class="pl-3"
                                        style="font-weight: bold;font-size: 16px;color: #CC3D45;">*ภาษาอังกฤษ</span>
                                </v-flex>
                            </v-layout>
                            <TextfieldForm :model="form.researchs.enResearchName"
                                @update-data="updateDataResearchs('enResearchName', $event)"></TextfieldForm>

                            <!-- choice 2 -->
                            <v-layout>
                                <v-flex shrink pt-2 pr-4 style="text-align: left;">
                                    <span style="font-weight: bold;font-size: 16px;">2. สาขาที่ทำการวิจัย</span>
                                </v-flex>
                                <TextfieldForm :model="form.researchs.field"
                                    @update-data="updateDataResearchs('field', $event)"></TextfieldForm>
                            </v-layout>

                            <!-- choice 3 -->
                            <v-layout mb-4 mt-6>
                                <v-flex shrink pt-2 pr-4 style="text-align: left;">
                                    <span style="font-weight: bold;font-size: 16px;">3. ผู้วิจัยร่วมและที่ปรึกษา</span>
                                </v-flex>
                            </v-layout>
                            <v-layout mb-12>
                                <v-flex>
                                    <div v-for="(input, k) in researchers" :key="k">
                                        <v-layout justify-space-between>

                                            <v-flex class="field-select">
                                                <v-select label="ผู้วิจัย / ที่ปรึกษา"
                                                    :menu-props="{ bottom: true, offsetY: true }" v-model="input.position"
                                                    background-color="#3E7662" solo flat dense :items="researcher_type"
                                                    item-text="type" item-value="type"
                                                    :value-comparator="findMatchingProvince"></v-select>
                                            </v-flex>

                                            <v-flex shrink pt-2 mr-2 ml-6 style="text-align: right">
                                                <span>ชื่อ-นามสกุล</span>
                                            </v-flex>
                                            <v-flex class="field-select">
                                                <v-select label="เลือก ชื่อผู้วิจัย / ที่ปรึกษา"
                                                    :menu-props="{ bottom: true, offsetY: true }" background-color="#3E7662"
                                                    solo flat dense v-model="input.userID" :items="user_list"
                                                    item-text="user_name" item-value="user_id"
                                                    :value-comparator="findMatchingProvince"></v-select>
                                            </v-flex>

                                            <v-flex ml-6 shrink d-flex justify-end>
                                                <v-btn style="background-color: white; color: #3E7662; width: 80px"
                                                    @click="remove(k)" v-if="k != researchers.length - 1">
                                                    <v-icon>remove</v-icon>
                                                    <span>ลบ</span>
                                                </v-btn>
                                                <v-btn dark style="width: 80px;background-color: #3E7662" @click="add(k)"
                                                    v-if="k == researchers.length - 1">
                                                    <v-icon>add</v-icon>
                                                    <span>เพิ่ม</span>
                                                </v-btn>
                                            </v-flex>

                                        </v-layout>
                                    </div>
                                </v-flex>
                            </v-layout>

                            <!-- choice 4 -->
                            <v-layout>
                                <v-flex shrink style="text-align: left;">
                                    <date-input-form :model="form.researchs.startDate" style="font-weight: bold"
                                        label="4. ระยะเวลาที่ทำการวิจัย เริ่ม"
                                        @update-data="updateDataResearchs('startDate', $event)"></date-input-form>
                                </v-flex>
                                <v-flex shrink pl-12 style="text-align: right;">
                                    <date-input-form :model="form.researchs.endDate" style="font-weight: bold"
                                        label="สิ้นสุด"
                                        @update-data="updateDataResearchs('endDate', $event)"></date-input-form>
                                </v-flex>
                            </v-layout>

                            <!-- choice 5 -->
                            <v-layout>
                                <v-flex xs6 pr-12 pb-6>
                                    <v-layout>
                                        <v-flex shrink pt-2 pr-4 style="text-align: left;">
                                            <span style="font-weight: bold;font-size: 16px;">5. สถานที่ทำการวิจัย</span>
                                        </v-flex>
                                        <v-flex class="text-input">
                                            <TextfieldForm :model="form.researchs.researchPlace"
                                                @update-data="updateDataResearchs('researchPlace', $event)"></TextfieldForm>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex xs6 style="text-align: left;">
                                    <span
                                        style="font-weight: bold;font-size: 19px;color: #CC3D45;">*หนังสือหน่วยงานต้นสังกัด</span>
                                </v-flex>
                                <v-flex xs6>
                                    <v-layout>
                                        <v-flex shrink pt-2 pr-4 style="text-align: left;">
                                            <span style="font-weight: bold;font-size: 16px;">ไฟล์</span>
                                        </v-flex>
                                        <v-flex>
                                            <v-file-input prepend-icon="" id="affiliationBookFile"
                                                :model="form.researchs.affiliationBookFile" outlined dense
                                                background-color="#FBF6F3" color="#3E7662" item-color="#3E7662"
                                                @change="uploadBookDepartment"></v-file-input>
                                        </v-flex>
                                        <v-flex shrink pl-4 style="text-align: left">
                                            <v-btn color="#3E7662" dark @click="uploadFile('affiliationBookFile')">
                                                <v-icon class="pr-3">cloud_download</v-icon>
                                                <span>Upload</span>
                                            </v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex xs6 style="text-align: left;">
                                    <span style="font-weight: bold;font-size: 19px;color: #CC3D45;">*ข้อ 6 - 22
                                        กรอกข้อมูลลงระบบ หรือ อัพโหลดเอกสาร</span>
                                </v-flex>
                                <v-flex xs6>
                                    <v-layout>
                                        <v-flex shrink pt-2 pr-4 style="text-align: left;">
                                            <span style="font-weight: bold;font-size: 16px;">ไฟล์</span>
                                        </v-flex>
                                        <v-flex>
                                            <v-file-input prepend-icon="" id="researchInfoFile"
                                                :model="form.researchs.researchInfoFile" outlined dense
                                                background-color="#FBF6F3" color="#3E7662" item-color="#3E7662"
                                                @change="uploadUpdateDocument"></v-file-input>
                                        </v-flex>
                                        <v-flex shrink pl-4 style="text-align: left">
                                            <v-btn color="#3E7662" dark @click="uploadFile('researchInfoFile')">
                                                <v-icon class="pr-3">cloud_download</v-icon>
                                                <span>Upload</span>
                                            </v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                            </v-layout>

                            <v-layout>
                                <v-flex xs2 pt-2 mr-4 style="text-align: right">
                                    <span>ประเภทผู้วิจัย</span>
                                </v-flex>
                                <v-flex xs3 class="field-select">
                                    <multiselect-form label="เลือกประเภทผู้วิจัย" :items="research_type"
                                        item-text="research_type_name" item-value="research_type_id"
                                        :model="form.researchs.researcherTypeID"
                                        @update-data="updateDataResearchs('researcherTypeID', $event)"></multiselect-form>
                                </v-flex>
                                <v-flex xs4 ml-12>
                                    <textfield-form label="จำนวนอาสาสมัคร" :model="form.researchs.amountOfVolunteer"
                                        @update-data="updateDataResearchs('amountOfVolunteer', $event)"></textfield-form>
                                </v-flex>
                                <v-flex shrink ml-3 pt-2>
                                    <span>คน</span>
                                </v-flex>
                            </v-layout>

                            <!-- choice 6 -->
                            <v-layout>
                                <v-flex xs6 pb-2 style="text-align: left;">
                                    <span style="font-weight: bold;font-size: 16px;">6.
                                        ความสำคัญของปัญหาที่จะทำการวิจัย</span>
                                </v-flex>
                            </v-layout>
                            <TextareaForm
                                placeholder="สรุปขนาดและลักษณะของปัญหาที่ทำให้เกิดความสนใจทำการวิจัยเรื่องนั้น ข้อมูลพื้นฐานกับสถานการณ์ หรือสภาพแวดล้อมที่เกี่ยวข้อง กับการวิจัย เพื่อให้ผู้พิจารณาสามารถเข้าใจสภาวะทำให้เกิดปัญหานั้นได้โดยมีตัวเลขและหลักฐานอ้างอิงที่มีความน่าเชื่อถือ"
                                :model="research_info.importance_problem"
                                @update-data="updateDataResearchInfo('importance_problem', $event)"></TextareaForm>

                            <!-- choice 7 -->
                            <v-layout>
                                <v-flex xs6 pb-2 style="text-align: left;">
                                    <span style="font-weight: bold;font-size: 16px;">7. วัตถุประสงค์ของการวิจัย</span>
                                </v-flex>
                            </v-layout>
                            <TextareaForm
                                placeholder="แจ้งวัตถุประสงค์ของการวิจัยเพื่อให้พิจารณาเข้าใจว่าคำถามของการวิจัยคืออะไรและการวิจัยจะตอบคำถามได้อย่างไร ถ้ามีวัตถุประสงค์ ของการวิจัยหลายข้อ ควรระบุว่าข้อใดเป็นวัตถุประสงค์หลัก (1 ข้อ) และข้อใดเป็นวัตถุประสงค์รอง"
                                :model="research_info.objective" @update-data="updateDataResearchInfo('objective', $event)">
                            </TextareaForm>

                            <!-- choice 8 -->
                            <v-layout>
                                <v-flex xs6 pb-2 style="text-align: left;">
                                    <span style="font-weight: bold;font-size: 16px;">8. ประโยชน์ที่จะได้จากการวิจัย</span>
                                </v-flex>
                            </v-layout>
                            <TextareaForm
                                placeholder="แจ้งวัตถุประสงค์ของการวิจัยเพื่อให้พิจารณาเข้าใจว่าคำถามของการวิจัยคืออะไรและการวิจัยจะตอบคำถามได้อย่างไร ถ้ามีวัตถุประสงค์ ของการวิจัยหลายข้อ ควรระบุว่าข้อใดเป็นวัตถุประสงค์หลัก (1 ข้อ) และข้อใดเป็นวัตถุประสงค์รอง"
                                :model="research_info.benefit" @update-data="updateDataResearchInfo('benefit', $event)">
                            </TextareaForm>

                            <!-- choice 9 -->
                            <v-layout>
                                <v-flex xs6 pb-2 style="text-align: left;">
                                    <span style="font-weight: bold;font-size: 16px;">9. การรวบรวมรายงานที่สำคัญๆ
                                        ที่เกี่ยวข้องกับการวิจัยที่จะกระทำครั้งนี้อย่างครบถ้วน</span>
                                </v-flex>
                            </v-layout>
                            <TextareaForm
                                placeholder="การรวบรวมรายงานที่เกี่ยวข้องกับการวิจัยให้ครบถ้วน และนำมาสรุปให้ได้ใจความเพื่อดูว่างานวิจัยที่จะทำมีผู้ทำมามากน้อยเพียงใดงาน วิจัยที่กระทำจะเพิ่มความรู้เดิมได้อย่างไร โดยจะต้องสรุปจากรายงานของการวิจัยในเรื่องนั้นที่ผู้รายงานไว้ก่อนแล้วพร้อมเอกสารอ้างอิง"
                                :model="research_info.important_work"
                                @update-data="updateDataResearchInfo('important_work', $event)"></TextareaForm>

                            <!-- choice 10 -->
                            <v-layout>
                                <v-flex xs6 pb-2 style="text-align: left;">
                                    <span style="font-weight: bold;font-size: 16px;">10. แบบแผนการวิจัย</span>
                                </v-flex>
                            </v-layout>
                            <TextareaForm
                                placeholder="แบบแผนการวิจัย ควรเลือกให้เหมาะสมกับคำถามการวิจัย มีความเชื่อถือได้และอยู่ในขีดความสามารถที่ผู้วิจัยจะกระทำได้ เช่น การวิจัยแบบพรรณนา (Descriptive) หรือ Analytic observationalหรือ Randomized Controlled trial"
                                :model="research_info.research_plan"
                                @update-data="updateDataResearchInfo('research_plan', $event)"></TextareaForm>

                            <!-- choice 11 -->
                            <v-layout>
                                <v-flex xs6 pb-6 style="text-align: left;">
                                    <span style="font-weight: bold;font-size: 16px;">11.
                                        ลักษณะตัวอย่างหรือประชากรที่ทำการศึกษา</span>
                                </v-flex>
                            </v-layout>

                            <v-layout>
                                <v-flex xs6 pb-2 pl-12 style="text-align: left;">
                                    <span style="font-weight: bold;font-size: 16px;color: #3E7662">1. ประชากรเป้าหมาย</span>
                                </v-flex>
                            </v-layout>
                            <TextareaForm
                                placeholder="รายละเอียดเกี่ยวกับประชากรเป้าหมายที่จะทำการศึกษาว่าเป็นใคร เช่น เด็กนักเรียนในกรุงเทพฯ ซึ่งควรเป็นประชากรกลุ่มเดียวกับที่นำผลการวิจัยไปใช้"
                                :model="research_info.target_population"
                                @update-data="updateDataResearchInfo('target_population', $event)"></TextareaForm>

                            <v-layout>
                                <v-flex xs6 pb-2 pl-12 style="text-align: left;">
                                    <span style="font-weight: bold;font-size: 16px;color: #3E7662">2.
                                        การเลือกตัวอย่าง</span>
                                </v-flex>
                            </v-layout>
                            <TextareaForm placeholder="กำหนดวิธีและหลักเกณฑ์ในการเลือกตัวอย่างจากประชากรเป้าหมายให้ชัดเจน"
                                :model="research_info.choose_sample"
                                @update-data="updateDataResearchInfo('choose_sample', $event)"></TextareaForm>

                            <v-layout>
                                <v-flex xs6 pb-2 pl-12 style="text-align: left;">
                                    <span style="font-weight: bold;font-size: 16px;color: #3E7662">3. ขนาดตัวอย่าง</span>
                                </v-flex>
                            </v-layout>
                            <TextareaForm
                                placeholder="ข้อมูลเบื้องต้นจากการศึกษาของผู้อื่น เกี่ยวกับอุบัติการณ์ (Incidence) หรือความชุก (Prevalence) หรือความแตกต่างระหว่างกลุ่ม(ใส่หมายเลขอ้างอิงด้วย) สามารถนำมา คำนวณขนาดตัวอย่างที่เหมาะสมในการวิจัยได้ การปรึกษากับนักสถิติก่อนที่จะทำการวิจัยจะสามารถแก้ปัญหาเกี่ยวกับขนาดตัวอย่างที่มากหรือน้อยเกินไปล่วงหน้า เพื่อให้งาน วิจัยสามารถกระทำได้และมีคุณภาพดี"
                                :model="research_info.sample_size"
                                @update-data="updateDataResearchInfo('sample_size', $event)"></TextareaForm>

                            <v-layout pt-12>
                                <v-flex style="text-align: left;">
                                    <span>สำนักงานคณะอนุกรรมการพิจารณาโครงการวิจัย พบ.</span>
                                </v-flex>
                                <v-flex style="text-align: right;">
                                    <span>หน้า 2 จาก 5 หน้า</span>
                                </v-flex>
                            </v-layout>

                        </v-flex>
                    </v-layout>
                </v-card>
            </v-flex>
        </v-layout>

        <v-layout>
            <v-flex mb-12 style="padding: 0 5% 2% 5%; text-align: right;">
                <v-layout>
                    <v-flex>
                        <v-btn style="color: #3E7662" class="mx-4" depressed color="#ffffff"
                            @click="$router.push('/manual-research')">ย้อนกลับ</v-btn>
                        <v-btn depressed dark color="#3E7662" @click="changePage">
                            <v-icon class="mr-3">add</v-icon>
                            <span>บันทึกและถัดไป</span>
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
        <loading color="#527281" :active.sync="isLoading" :can-cancel="false" :is-full-page="fullPage"></loading>
    </v-content>
</template>

<script>
import TextareaForm from "./input/TextareaForm";
import TextfieldForm from "./input/TextfieldForm";
// import FileInputForm from "./input/FileInputForm";
import MultiselectForm from "./input/MultiselectForm";
import DateInputForm from "./input/DateInputForm";
import Swal from 'sweetalert2';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { mapState } from "vuex"

export default {
    name: "FormCreateResearchPage1",
    components: {
        TextareaForm,
        TextfieldForm,
        // FileInputForm,
        MultiselectForm,
        DateInputForm,
        Loading,
    },
    computed: {
        ...mapState({
            researchID: state => state.FormResearch.researchID,
        })
    },
    data: () => ({
        isLoading: false,
        fullPage: true,
        menu_date_start: false,
        menu_date_end: false,
        modal: false,
        form: {
            researchs: {
                thResearchName: '',
                enResearchName: '',
                field: '',
                startDate: new Date().toISOString().substr(0, 10),
                endDate: new Date().toISOString().substr(0, 10),
                researchPlace: '',
                affiliationBookFile: '',
                researchInfoFile: '',
                researcherTypeID: '',
                amountOfVolunteer: '',
            },
        },
        research_info: {
            importance_problem: '',
            objective: '',
            benefit: '',
            important_work: '',
            research_plan: '',
            target_population: '',
            choose_sample: '',
            sample_size: '',
        },
        researchers: [
            {
                position: '',
                userID: ''
            }
        ],
        researchersDelete: [
        ],
        role: [],
        research_type: [],
        user_list: [],
        researcher_type: [
            {
                id: 1,
                type: 'ผู้วิจัย',
            },
            {
                id: 2,
                type: 'ที่ปรึกษา',
            }
        ],
    }),
    created() {
        this.getRole();
        this.getResearchType();
        this.getUserList();
        this.getFormResearch1();
    },
    methods: {
        updateDataResearchs(name, val) {
            this.form.researchs[name] = val;
        },
        updateDataResearchInfo(name, val) {
            this.research_info[name] = val;
        },
        findMatchingProvince(a, b) {
            return a == b;
        },
        selectDateStart() {
            this.menu_date_start = false;
            //change date start
            let sub_year_start = this.date_start.substring(0, 4);
            let sub_date_start = this.date_start.substring(4, 10);
            sub_year_start = parseInt(sub_year_start) + 543;
            let date_start = sub_year_start + sub_date_start;
        },
        selectDateEnd() {
            this.menu_date_end = false;
            //change date end
            let sub_year_end = this.date_end.substring(0, 4);
            let sub_date_end = this.date_end.substring(4, 10);
            sub_year_end = parseInt(sub_year_end) + 543;
            let date_end = sub_year_end + sub_date_end;
        },
        changePage() {
            this.isLoading = true;
            this.form['researchers'] = this.researchers;
            this.form['researchersDelete'] = this.researchersDelete;
            this.form['researcher_info'] = [
                { subTopicID: 1, answer: this.research_info.importance_problem },
                { subTopicID: 2, answer: this.research_info.objective },
                { subTopicID: 3, answer: this.research_info.benefit },
                { subTopicID: 4, answer: this.research_info.important_work },
                { subTopicID: 5, answer: this.research_info.research_plan },
                { subTopicID: 6, answer: this.research_info.target_population },
                { subTopicID: 7, answer: this.research_info.choose_sample },
                { subTopicID: 8, answer: this.research_info.sample_size },
            ]
            this.$store.dispatch("FormResearch/saveResearch1", this.form).then(res => {
                this.$store.dispatch("FormResearch/changePage", 2)
                this.isLoading = false;
            }).catch(e => {
                Swal.fire({
                    title: 'Save failed!',
                    text: 'บันทึกไม่สำเร็จ',
                    icon: 'warning',
                }).then(() => {
                    this.isLoading = false;
                })
            })
        },
        getFormResearch1() {
            if (this.researchID === '') {
                this.$store.dispatch("FormResearch/saveResearch1",)
            } else {
            }
        },
        add(index) {
            this.researchers.push({ position: '', userID: '' });
        },
        remove(index) {
            this.researchersDelete.push(this.researchers.splice(index, 1));
        },
        // Get role
        getRole() {
            this.isLoading = true;
            this.$store.dispatch('Common/getRole').then(res => {
                res.forEach(data => {
                    this.role.push({
                        role_id: data.id,
                        role_name: data.role,
                    });
                })
                this.isLoading = false;
            })
        },
        // Get User List
        getUserList() {
            this.isLoading = true;
            this.$store.dispatch('Common/getUserList').then(res => {
                res.all_researcher_data.forEach(data => {
                    this.user_list.push({
                        user_id: data.id,
                        user_name: data.thName,
                    });
                })
                this.isLoading = false;
            })
        },
        // Get Research Type
        getResearchType() {
            this.isLoading = true;
            this.$store.dispatch('Common/getResearchType').then(res => {
                res.all_researcher_type.forEach(data => {
                    this.research_type.push({
                        research_type_id: data.id,
                        research_type_name: data.typeName,
                        research_type_code: data.typeCode,
                    });
                })
                this.isLoading = false;
            })
        },
        // upload file
        uploadFile(name) {
            if (name === 'affiliationBookFile') {
                document.getElementById("affiliationBookFile").click();
            } else {
                document.getElementById("researchInfoFile").click();
            }
        },
        uploadBookDepartment(e) {
            this.form.researchs.affiliationBookFile = e;
        },
        uploadUpdateDocument(e) {
            this.form.researchs.researchInfoFile = e;
        },
        setData() {
            this.form.researchs.thResearchName = 'ทดสอบ';
            this.form.researchs.enResearchName = 'Test';
            this.form.researchs.field = 'สาขา test';
            this.form.researchs.startDate = new Date().toISOString().substr(0, 10);
            this.form.researchs.endDate = new Date().toISOString().substr(0, 10);
            this.form.researchs.researchPlace = 'ทดสอบ researchPlace';
            this.form.researchs.researcherTypeID = 1;
            this.form.researchs.amountOfVolunteer = '10';
            this.research_info.importance_problem = 'ทดสอบ ความสำคัญของปัญหาที่จะทำการวิจัย';
            this.research_info.objective = 'ทดสอบ วัตถุประสงค์ของการวิจัย';
            this.research_info.benefit = 'ทดสอบ ประโยชน์ที่จะได้จากการวิจัย';
            this.research_info.important_work = 'ทดสอบ การรวบรวมรายงานที่สำคัญ';
            this.research_info.research_plan = 'ทดสอบ การรวบรวมรายงานที่สำคัญ';
            this.research_info.target_population = 'ทดสอบ การรวบรวมรายงานที่สำคัญ';
            this.research_info.choose_sample = 'ทดสอบ การเลือกตัวอย่าง';
            this.research_info.sample_size = 'ทดสอบ ขนาดตัวอย่าง';
        }
    }
}
</script>

<style lang="scss"></style>
