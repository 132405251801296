import Service from "../Service";
import AuthService from "../AuthService";

const Common = {
  getProvince() {
    return Service.get("/api/address/province");
  },
  getDistricts(id) {
    return Service.get(`/api/address/district/${id}`);
  },
  getSubDistricts(id) {
    return Service.get(`/api/address/sub-district/${id}`);
  },
  getRole() {
    return Service.get("/api/get-role");
  },
  getTitleName() {
    return Service.get("/api/get-title-name");
  },
  getRank() {
    return Service.get("/api/get-rank-name");
  },
  getResearchType() {
    return AuthService.get("/api/show-researcher-type");
  },
  showResearcherName() {
    return AuthService.get("/api/show-researcher-name");
  },
  getBudgetList(id) {
    return AuthService.get(`/api/show-budget-resource/${id}`);
  },
  getParticipantList() {
    return AuthService.get("/api/show-participant");
  },
  getCertificate() {
    return AuthService.get("/api/get-certificate");
  },
  getNews() {
    return Service.get("/api/get-home-news");
  },
  getNewsByID(id) {
    return Service.get(`/api/get-news-by-id/${id}`);
  },
  getAllNews(page) {
    return Service.get("/api/get-all-news?page=" + page);
  },
  uploadCertificate(data) {
    let formData = new FormData();
    formData.append("certificate", data.certificate_gcp_training);
    formData.append("certificate_create_at", data.certificate_date);
    return AuthService.post("/api/upload-certificate", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  getLastMonthMeeting() {
    return Service.get("/api/get-last-month-meeting");
  },
  getThisMonthMeeting() {
    return Service.get("/api/get-this-month-meeting");
  },
  getAllMeeting() {
    return Service.get("/api/get-all-past-meeting");
  },
  getHistoryResearch(data) {
    return AuthService.get("/api/get-history?refID=" + data.id);
    // return Service.get('/api/get-history-research?page='+data.page+'&id='+data.id);
  },
  getAffiliationList() {
    return Service.get("/api/show-affiliation-for-select");
  },
  verifyEmail(token) {
    return Service.get(`/api/email/verify/${token}`);
  },
  uploadPdf(data) {
    let formData = new FormData();
    switch (data.form) {
      case "rf03":
        formData.append("rf03affiliationFile", data.file);
        break;
      case "rf04_1":
        formData.append("rf04_1affiliationFile", data.file);
        break;
      case "rf04_2":
        formData.append("rf04_2affiliationFile", data.file);
        break;
      case "rf05":
        formData.append("rf05affiliationFile", data.file);
        break;
      case "rf06":
        formData.append("rf06affiliationFile", data.file);
        break;
      case "rf07":
        formData.append("rf07affiliationFile", data.file);
        break;
      case "rf12":
        formData.append("rf12affiliationFile", data.file);
        break;
      case "rf12-file":
        formData.append("rf12File", data.file);
        break;
      case "corres-file":
        formData.append("corresFile", data.file);
        break;
      case "rf-04-2other-file":
        formData.append("rf04_2otherFile",data.file);
        break;
      case "rf-03other-file":
        formData.append("rf03otherFile",data.file);
        break;
      case "rf-04-1other-file":
        formData.append("rf04_1otherFile",data.file);
        break;
      case "rf-06other-file":
        formData.append("rf06otherFile",data.file);
        break;
      case "rf-07other-file":
        formData.append("rf07otherFile",data.file);
        break;  
      case "rf-05other-file":
        formData.append("rf05otherFile",data.file);
        break; 
      case "rfsusar-ddletter":
        formData.append("rfsusarddletter",data.file);
        break; 
      case "rfsusar-safetyreport":
        formData.append("rfsusarsafetyreport",data.file);
        break;  
      case "rfsusar-unexpectedevents":
        formData.append("rfsusarunexpectedevents",data.file);
        break;  
      case "rfsusar-morereport":
        formData.append("rfsusarmorereport",data.file);
        break;    
      case "rfsusar-morereport2":
        formData.append("rfsusarmorereport2",data.file);
        break; 
    }
    return AuthService.post("/api/upload-file-post", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((res) => {
      return res;
    });
  },
  uploadImage(data) {
    let formData = new FormData();
    formData.append("profileImage", data.file);
    return Service.post("/api/upload-image", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((res) => {
      return res;
    });
  },
};

export default Common;
