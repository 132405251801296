<template>
  <v-content style="background-color: #EFEAE7">
    <v-layout class="text-center">
      <v-flex pt-8>
        <span style="font-weight: bold;font-size: 36px;color: #3E7662">ลงทะเบียน</span>
      </v-flex>
    </v-layout>

    <v-layout>
      <v-flex style="background-color: #EFEAE7;padding: 2% 5% 2% 5%">
        <v-card flat class="pb-8">
          <!-- Upload Image -->
          <v-layout style="position: absolute; right: 80px;top: 90px">
            <v-flex id="pictureFile">
              <div class="mb-1"
                style="background-color: #FAF5F2; width: 103px;height: 123px;box-shadow: 0 2px 6px rgba(0,0,0,0.5)">
                <v-img :src="imageUrl" style="width: 100%;height: 100%;object-fit: cover"></v-img>
              </div>
              <v-file-input accept=".jpg, .png" id="file" label="File input" style="display: none;"
                @change="onFilePicked"></v-file-input>
              <v-btn style="width: 103px;font-size: 12px" color="#3E7662" dark @click="uploadFile">
                <v-icon class="pr-3">cloud_download</v-icon>
                <span>Upload</span>
              </v-btn>
            </v-flex>
          </v-layout>

          <!-- Header -->
          <v-layout mb-12 pa-8 justify-center style="background-color: #3E7662;">
            <v-flex style="text-align: left;">
              <span style="font-weight: bold;font-size: 28px;color: white;">แบบประวัติผู้วิจัย</span>
            </v-flex>
            <v-flex style="text-align: right;">
              <span style="font-size: 14px;color: white;">Version……….Date…………………….</span><br />
              <span style="font-size: 14px;color: white;">RF 10_2563</span>
            </v-flex>
          </v-layout>

          <!-- Form Register -->
          <v-layout justify-center>
            <v-flex xs12 sm10 md8 lg10 pt-8 pb-12>
              <!-- E-mail -->
              <v-layout>
                <v-flex mr-8 xs3 class="font-weight-bold d-flex align-center justify-end">
                  <span>อีเมลใช้ลงทะเบียน<span style="color: #CC3D45">*</span></span>
                </v-flex>
                <v-flex xs6 class="text-input">
                  <TextfieldForm id="email" :model="form.email" @update-data="updateData('email', $event)" hide-detail>
                  </TextfieldForm>
                </v-flex>
                <v-spacer></v-spacer>
              </v-layout>
              <v-layout>
                <v-flex mr-8 pt-2 xs3 style="text-align: right;"></v-flex>
                <v-flex class="text-input font-weight-bold" style="text-align: right;">
                  <span style="font-size: 12px;color: #CC3D45">หมายเหตุ : กรุณาใช้อีเมลของสำนักงาน หรือ G-mail
                    เท่านั้น*</span>
                </v-flex>
                <v-flex xs3 pl-8 pt-3 style="text-align: left"></v-flex>
              </v-layout>

              <!-- Password -->
              <v-layout mt-12>
                <v-flex mr-8 xs3 class="font-weight-bold d-flex align-center justify-end">
                  <span>รหัสผ่าน<span style="color: #CC3D45">*</span></span>
                </v-flex>
                <v-flex xs6 class="text-input">
                  <TextfieldForm id="password" hide-detail type="password" :model="form.password"
                    @update-data="updateData('password', $event)"></TextfieldForm>
                </v-flex>
                <v-spacer></v-spacer>
              </v-layout>
              <v-layout class="mt-8">
                <v-flex mr-8 xs3 class="font-weight-bold d-flex align-center justify-end">
                  <span>ยืนยันรหัสผ่าน<span style="color: #CC3D45">*</span></span>
                </v-flex>
                <v-flex xs6 class="text-input">
                  <TextfieldForm id="password_confirmation" hide-detail type="password"
                    :model="form.password_confirmation" @update-data="updateData('password_confirmation', $event)">
                  </TextfieldForm>
                </v-flex>
                <v-spacer></v-spacer>
              </v-layout>


              <!-- ชื่อ-นามสกุล -->
              <v-layout class="mt-8">
                <v-flex mr-8 pt-1 xs3 class="font-weight-bold d-flex align-center justify-end">
                  <p class="text-right">คำนำหน้าชื่อ<span style="color: #CC3D45">*</span>
                    <br><span style="color: #CC3D45">กรุณาตรวจสอบคำนำหน้าชื่อให้ถูกต้อง
                      เนื่องจากข้อมูลจะถูกระบุไว้ในเอกสารที่ได้รับการรับรอง</span>
                  </p>
                </v-flex>
                <v-flex xs6 class="field-select d-flex align-center">
                  <v-layout>
                    <v-flex xs6 pr-2>
                      <TextfieldForm id="PreNameTh" placeholder="ยศ ภาษาไทย" hide-detail :model="form.preNameTh"
                        @update-data="updateData('preNameTh', $event)">
                      </TextfieldForm>
                      <!--                      <text-autocomplete-->
                      <!--                        id="nameTitle"-->
                      <!--                        placeholder="คำนำหน้าชื่อ"-->
                      <!--                        :items="titleName"-->
                      <!--                        hide-details-->
                      <!--                        item-text="title_name_th"-->
                      <!--                        itemValue="title_id"-->
                      <!--                        :model="form.nameTitle"-->
                      <!--                        @update-data="updateData('nameTitle', $event)"-->
                      <!--                      />-->
                    </v-flex>
                    <v-flex xs6 pl-2>
                      <TextfieldForm id="preNameEn" placeholder="ยศ ภาษาอังกฤษ" hide-detail :model="form.preNameEn"
                        @update-data="updateData('preNameEn', $event)">
                      </TextfieldForm>
                      <!--                      <text-autocomplete-->
                      <!--                        id="nameTitle"-->
                      <!--                        placeholder="อังกฤษ"-->
                      <!--                        :items="rankName"-->
                      <!--                        hide-details-->
                      <!--                        item-text="name_th"-->
                      <!--                        itemValue="id"-->
                      <!--                        :model="form.rank"-->
                      <!--                        @update-data="updateData('rank', $event)"-->
                      <!--                      />-->
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex pl-8 class="font-weight-bold d-flex align-center justify-start">
                  <span>(ยศ)</span>
                </v-flex>
              </v-layout>

              <v-layout class="mt-8">
                <v-flex mr-8 pt-2 xs3 class="font-weight-bold d-flex align-center justify-end" style="text-align: right;">
                  <span>1. ชื่อ-นามสกุล<span style="color: #CC3D45">*</span></span>
                </v-flex>
                <v-flex xs3 pr-2 class="text-input">
                  <TextfieldForm id="thFirstName" placeholder="ชื่อจริงภาษาไทย" hide-detail :model="form.thFirstName"
                    @update-data="updateData('thFirstName', $event)"></TextfieldForm>
                </v-flex>
                <v-flex xs3 pl-2 class="text-input">
                  <TextfieldForm id="thLastName" placeholder="นามสกุลภาษาไทย" hide-detail :model="form.thLastName"
                    @update-data="updateData('thLastName', $event)"></TextfieldForm>
                </v-flex>
                <v-flex pl-8 class="font-weight-bold d-flex align-center justify-start">
                  <span>(ภาษาไทย)</span>
                </v-flex>
              </v-layout>

              <v-layout class="mt-8">
                <v-flex mr-8 pt-2 xs3 style="text-align: right;"> </v-flex>
                <v-flex xs3 pr-2 class="text-input">
                  <TextfieldForm id="enFirstName" placeholder="ชื่อจริงอังกฤษ" hide-detail :model="form.enFirstName"
                    @update-data="updateData('enFirstName', $event)"></TextfieldForm>
                </v-flex>
                <v-flex xs3 pl-2 class="text-input">
                  <TextfieldForm id="enLastName" placeholder="นามสกุลอังกฤษ" hide-detail :model="form.enLastName"
                    @update-data="updateData('enLastName', $event)"></TextfieldForm>
                </v-flex>
                <v-flex pl-8 class="font-weight-bold d-flex align-center justify-start">
                  <span>(ภาษาอังกฤษ)</span>
                </v-flex>
              </v-layout>

              <!-- ประวัติการศึกษา -->
              <v-layout class="mt-8">
                <v-flex xs3 mr-8 class="font-weight-bold d-flex align-center justify-end">
                  <span>2. วุฒิการศึกษา<span style="color: #CC3D45">*</span></span>
                </v-flex>
                <v-flex xs6 class="text-input">
                  <text-autocomplete id="education" placeholder="เลือกวุฒิการศึกษา" :items="educationList" hide-details
                    item-text="education_name" itemValue="education_name" :model="form.education"
                    @update-data="updateData('education', $event)" />
                </v-flex>
                <v-flex xs2>
                  <v-flex class="font-weight-bold d-flex align-center ">
                    <span style="padding-left: 8px;padding-right: 8px">ระบุ</span>
                    <TextfieldForm :background-color="isOtherEducation ? 'rgba(183,183,183,0.79)' : '#FBF6F3'
                      " :disabled="isOtherEducation" id="otherEducation" hide-detail :model="form.otherEducation"
                      @update-data="updateData('otherEducation', $event)"></TextfieldForm>
                  </v-flex>
                </v-flex>
                <v-spacer></v-spacer>
              </v-layout>

              <!-- ประวัติการทำงาน -->
              <v-layout class="mt-8">
                <v-flex xs3 mr-8 class="font-weight-bold d-flex justify-end">
                  <span>3. ประวัติการศึกษา(โดยย่อ)</span>
                </v-flex>
                <v-flex xs6 class="text-input">
                  <textarea-form id="workEx" hide-detail :model="form.educationEx"
                    @update-data="updateData('educationEx', $event)"></textarea-form>
                </v-flex>
                <v-spacer></v-spacer>
              </v-layout>

              <v-layout class="mt-8">
                <v-flex xs3 mr-8 class="font-weight-bold d-flex justify-end">
                  <span>4. ประวัติการทำงาน</span>
                </v-flex>
                <v-flex xs6 class="text-input">
                  <textarea-form id="workEx" hide-detail :model="form.workEx"
                    @update-data="updateData('workEx', $event)"></textarea-form>
                </v-flex>
                <v-spacer></v-spacer>
              </v-layout>

              <!-- สาขาที่เชี่ยวชาญ -->
              <v-layout class="mt-8">
                <v-flex mr-8 xs3 class="font-weight-bold d-flex align-center justify-end">
                  <span>5. สาขาที่เชี่ยวชาญ<span style="color: #CC3D45">*</span></span>
                </v-flex>
                <v-flex xs6 class="text-input">
                  <text-autocomplete id="field" placeholder="สาขา" :items="fieldList" hide-details item-text="field_name"
                    itemValue="field_name" :model="form.field" @update-data="updateData('field', $event)" />
                </v-flex>
                <v-flex xs2>
                  <v-flex class="font-weight-bold d-flex align-center ">
                    <span style="padding-left: 8px;padding-right: 8px">ระบุ</span>
                    <TextfieldForm :background-color="isOtherField ? 'rgba(183,183,183,0.79)' : '#FBF6F3'
                      " :disabled="isOtherField" id="houseNumber" hide-detail :model="form.otherField"
                      @update-data="updateData('otherField', $event)"></TextfieldForm>
                  </v-flex>
                </v-flex>
                <v-spacer></v-spacer>
              </v-layout>

              <!-- ตำแหน่งทางวิชาการ -->
              <v-layout class="mt-8">
                <v-flex mr-8 xs3 class="font-weight-bold d-flex align-center justify-end">
                  <span>6. ตำแหน่งทางวิชาการ<span style="color: #CC3D45">*</span></span>
                </v-flex>
                <v-flex xs6 class="text-input">
                  <text-autocomplete id="position" placeholder="ตำแหน่ง" :items="positionList" hide-details
                    item-text="position_name" itemValue="position_name" :model="form.position"
                    @update-data="updateData('position', $event)" />
                </v-flex>
                <v-flex xs2>
                  <v-flex class="font-weight-bold d-flex align-center ">
                    <span style="padding-left: 8px;padding-right: 8px">ระบุ</span>
                    <TextfieldForm :background-color="isOtherPosition ? 'rgba(183,183,183,0.79)' : '#FBF6F3'
                      " :disabled="isOtherPosition" id="otherPosition" hide-detail :model="form.otherPosition"
                      @update-data="updateData('otherPosition', $event)"></TextfieldForm>
                  </v-flex>
                </v-flex>
                <v-spacer></v-spacer>
              </v-layout>

              <!-- สังกัด -->
              <v-layout class="mt-8">
                <v-flex xs3 mr-8 class="font-weight-bold d-flex align-center justify-end">
                  <span>7. สังกัด<span style="color: #CC3D45">*</span></span>
                </v-flex>
                <v-flex xs6 class="text-input d-flex align-center ">
                  <text-autocomplete id="affiliationID" placeholder="สังกัด" :items="affiliationList" hide-details
                    item-text="affiliation_name" itemValue="affiliation_id" :model="form.affiliationID"
                    @update-data="updateData('affiliationID', $event)" />
                </v-flex>
                <v-flex shrink class="font-weight-bold d-flex align-center ">
                  <span style="padding-left: 8px;padding-right: 8px">ระบุ</span>
                </v-flex>
                <v-flex xs2>
                  <v-layout>
                    <v-flex class="font-weight-bold d-flex align-center ">
                      <TextfieldForm :background-color="isOtherAffiliation
                        ? 'rgba(183,183,183,0.79)'
                        : '#FBF6F3'
                        " :disabled="isOtherAffiliation" id="otherAffiliation" ref="otherAffiliation"
                        placeholder="ภาษาไทย" hide-detail :model="form.otherAffiliation" required
                        @update-data="updateData('otherAffiliation', $event)" autofocus></TextfieldForm>
                    </v-flex>
                  </v-layout>
                  <v-layout class="mt-2">
                    <v-flex class="font-weight-bold d-flex align-center">
                      <TextfieldForm :background-color="isOtherAffiliation
                        ? 'rgba(183,183,183,0.79)'
                        : '#FBF6F3'
                        " :disabled="isOtherAffiliation" id="otherAffiliationEN" hide-detail placeholder="ภาษาอังกฤษ"
                        required :model="form.otherAffiliationEN" @update-data="updateData('otherAffiliationEN', $event)">
                      </TextfieldForm>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-spacer></v-spacer>
              </v-layout>

              <!-- กอง -->
              <v-layout class="mt-8">
                <v-flex xs3 mr-8 class="font-weight-bold d-flex align-center justify-end">
                  <span>8. กอง<span style="color: #CC3D45">*</span></span>
                </v-flex>
                <v-flex xs6 class="text-input d-flex align-center ">
                  <text-autocomplete id="team" placeholder="กอง" :items="teamList" hide-details item-text="team_name"
                    itemValue="team_name" :model="form.team" @update-data="updateData('team', $event)" />
                </v-flex>
                <v-flex shrink class="font-weight-bold d-flex align-center ">
                  <span style="padding-left: 8px;padding-right: 8px">ระบุ</span>
                </v-flex>
                <v-flex xs2>
                  <v-layout>
                    <v-flex class="font-weight-bold d-flex align-center ">
                      <TextfieldForm :background-color="isOtherTeam
                        ? 'rgba(183,183,183,0.79)'
                        : '#FBF6F3'
                        " :disabled="isOtherTeam" placeholder="ภาษาไทย" id="otherTeam" hide-detail
                        :model="form.otherTeam" @update-data="updateData('otherTeam', $event)" autofocus></TextfieldForm>
                    </v-flex>
                  </v-layout>
                  <v-layout class="mt-2">
                    <v-flex class="font-weight-bold d-flex align-center ">
                      <TextfieldForm :background-color="isOtherTeam
                        ? 'rgba(183,183,183,0.79)'
                        : '#FBF6F3'
                        " :disabled="isOtherTeam" placeholder="ภาษาอังกฤษ" id="otherTeamEN" hide-detail
                        :model="form.otherTeamEN" @update-data="updateData('otherTeamEN', $event)"></TextfieldForm>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-spacer></v-spacer>
              </v-layout>

              <!-- สถานที่ติดต่อ -->
              <v-layout class="mt-8">
                <v-flex xs3 mr-8 class="font-weight-bold d-flex align-center justify-end">
                  <span>9. สถานที่ติดต่อ</span>
                </v-flex>
                <v-flex xs6>
                  <v-layout>
                    <v-flex xs3 pr-2 class="font-weight-bold d-flex align-center justify-end">
                      <span>บ้านเลขที่</span>
                    </v-flex>
                    <v-flex xs3>
                      <TextfieldForm id="houseNumber" hide-detail :model="form.houseNumber"
                        @update-data="updateData('houseNumber', $event)"></TextfieldForm>
                    </v-flex>
                    <v-flex xs1 ml-4 mr-2 class="font-weight-bold d-flex align-center justify-end">
                      <span>ถนน</span>
                    </v-flex>
                    <v-flex xs5>
                      <TextfieldForm id="road" hide-detail :model="form.road" @update-data="updateData('road', $event)">
                      </TextfieldForm>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-spacer></v-spacer>
              </v-layout>

              <!-- Select Province, District, SubDistrict -->
              <v-layout class="mt-8">
                <v-flex xs3></v-flex>
                <v-flex xs6 class="ml-8">
                  <v-layout>
                    <v-flex xs4 pr-2 class="font-weight-bold d-flex align-center justify-end">
                      <span>จังหวัด</span>
                    </v-flex>
                    <v-flex xs8 class="field-select">
                      <text-autocomplete id="province" placeholder="เลือก จังหวัด" :items="provinces" hide-details
                        item-text="province_name" itemValue="province_id" :model="form.province"
                        @update-data="updateData('province', $event)" />
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-spacer></v-spacer>
              </v-layout>

              <v-layout class="mt-8">
                <v-flex xs3></v-flex>
                <v-flex xs6 class="ml-8">
                  <v-layout>
                    <v-flex xs4 pr-2 class="font-weight-bold d-flex align-center justify-end">
                      <span>อำเภอ/เขต</span>
                    </v-flex>
                    <v-flex xs8 class="field-select">
                      <text-autocomplete id="district" placeholder="เลือก อำเภอ/เขต" :items="districts" hide-details
                        item-text="district_name" itemValue="district_id" :model="form.district"
                        @update-data="updateData('district', $event)" />
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-spacer></v-spacer>
              </v-layout>

              <v-layout class="mt-8">
                <v-flex xs3></v-flex>
                <v-flex xs6 class="ml-8">
                  <v-layout>
                    <v-flex xs4 pr-2 class="font-weight-bold d-flex align-center justify-end">
                      <span>ตำบล/แขวง</span>
                    </v-flex>
                    <v-flex xs8 class="field-select">
                      <text-autocomplete id="subDistrict" placeholder="เลือก ตำบล/แขวง" :items="subDistricts" hide-details
                        item-text="subDistrict_name" itemValue="subDistrict_id" :model="form.subDistrict"
                        @update-data="updateData('subDistrict', $event)" />
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-spacer></v-spacer>
              </v-layout>

              <v-layout class="mt-8">
                <v-flex xs3></v-flex>
                <v-flex xs6 class="ml-8">
                  <v-layout>
                    <v-flex xs4 pr-2 class="font-weight-bold d-flex align-center justify-end">
                      <span>รหัสไปรษณีย์</span>
                    </v-flex>
                    <v-flex class="field-select">
                      <TextfieldForm id="postalCode" hide-detail :model="form.postalCode"
                        @update-data="updateData('postalCode', $event)"></TextfieldForm>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-spacer></v-spacer>
              </v-layout>

              <v-layout class="mt-8">
                <v-flex xs3></v-flex>
                <v-flex xs6 class="ml-8">
                  <v-layout>
                    <v-flex xs4 pr-2 class="font-weight-bold d-flex align-center justify-end">
                      <span>โทรศัพท์ที่ทำงาน</span>
                    </v-flex>
                    <v-flex class="field-select">
                      <TextfieldForm type="number" id="telPhone" hide-detail :model="form.telPhone"
                        @update-data="updateData('telPhone', $event)"></TextfieldForm>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-spacer></v-spacer>
              </v-layout>

              <v-layout class="mt-8">
                <v-flex xs3></v-flex>
                <v-flex xs6 class="ml-8">
                  <v-layout>
                    <v-flex xs4 pr-2 class="font-weight-bold d-flex align-center justify-end">
                      <span>โทรศัพท์มือถือ<span style="color: #CC3D45">*</span></span>
                    </v-flex>
                    <v-flex class="field-select">
                      <TextfieldForm type="number" id="mobilePhone" hide-detail :model="form.mobilePhone"
                        @update-data="updateData('mobilePhone', $event)"></TextfieldForm>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-spacer></v-spacer>
              </v-layout>

              <v-layout class="mt-8">
                <v-flex xs3></v-flex>
                <v-flex xs6 class="ml-8">
                  <v-layout>
                    <v-flex xs4 pr-2 class="font-weight-bold d-flex align-center justify-end">
                      <span>E-mail Address</span>
                    </v-flex>
                    <v-flex class="field-select">
                      <TextfieldForm id="email" hide-detail :model="form.email"
                        @update-data="updateData('email', $event)"></TextfieldForm>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-spacer></v-spacer>
              </v-layout>

              <!-- ประวัติการอบรม Good Clinical Practice (GCP) หรือการอบรมด้านจริยธรรมการวิจัยในมนุษย์ -->
              <v-layout class="mt-8">
                <v-flex py-6 class="font-weight-bold d-flex align-center justify-start">
                  <span>10. ประวัติการอบรม Good Clinical Practice (GCP)
                    หรือการอบรมด้านจริยธรรมการวิจัยในมนุษย์</span>
                </v-flex>
              </v-layout>
              <v-layout class="justify-center">
                <v-flex xs6 class="text-input">
                  <textarea-form id="practiceEx" :model="form.practiceEx"
                    @update-data="updateData('practiceEx', $event)"></textarea-form>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout>
      <v-flex mb-12 style="padding: 0 5% 2% 5%; text-align: right;">
        <v-layout>
          <v-flex>
            <v-btn style="color: #3E7662" class="mx-4" depressed color="#ffffff"
              @click="$router.push('/login')">ย้อนกลับ</v-btn>
            <v-btn depressed dark color="#3E7662" @click="register">ยืนยันการลงทะเบียน</v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <loading color="#527281" :active.sync="isLoading" :can-cancel="false" :is-full-page="false"></loading>
  </v-content>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
import TextfieldForm from "../components/input/TextfieldForm";
import TextareaForm from "../components/input/TextareaForm";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import "vue-select/dist/vue-select.css";
import TextAutocomplete from "../components/input/TextAutocomplete";
import axios from "axios";
import { domain } from "../services/Constants";

export default {
  name: "Register",
  components: {
    TextfieldForm,
    TextareaForm,
    Loading,
    TextAutocomplete,
  },
  data: () => ({
    isLoading: false,
    fullPage: true,
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    modal: false,
    form: {
      role: "",
      nameTitle: "",
      rank: "",
      preNameTh: "",
      preNameEn: "",
      thFirstName: "",
      enFirstName: "",
      thLastName: "",
      enLastName: "",
      education: "",
      otherEducation: "",
      educationEx: "",
      workEx: "",
      field: "",
      otherField: "",
      position: "",
      otherPosition: "",
      affiliationID: "",
      otherAffiliation: "",
      otherAffiliationEN: "",
      team: "",
      teamEN: "",
      otherTeam: "",
      otherTeamEN: "",
      houseNumber: "",
      road: "",
      practiceEx: "",
      interResearch: "",
      otherWork: "",
      subDistrict: "",
      district: "",
      province: "",
      postalCode: "",
      telPhone: "",
      mobilePhone: "",
      email: "",
      password: "",
      password_confirmation: "",
      pictureFile: "",
      certificate_gcp_training: null,
      certificate_date: new Date(),
    },
    provinces: [],
    districts: [],
    subDistricts: [],
    role: [],
    titleName: [],
    rankName: [],
    imageName: "",
    imageUrl: "",
    imageFile: "",
    year: "",
    isOtherField: true,
    isOtherPosition: true,
    isOtherAffiliation: true,
    isOtherTeam: true,
    isOtherEducation: true,
    educationList: [
      { education_id: 0, education_name: "น้อยกว่าปริญญาตรี" },
      { education_id: 1, education_name: "ปริญญาตรี" },
      { education_id: 2, education_name: "ปริญญาโท" },
      { education_id: 3, education_name: "ปริญญาเอก" },
      { education_id: 4, education_name: "อื่นๆ" },
    ],
    fieldList: [
      { field_id: 1, field_name: "สูตินรีเวชกรรม" },
      { field_id: 2, field_name: "ศัลยกรรม" },
      { field_id: 3, field_name: "อายุรกรรม" },
      { field_id: 4, field_name: "กุมารเวชกรรม" },
      { field_id: 5, field_name: "มะเร็งวิทยา" },
      { field_id: 6, field_name: "เภสัชกรรม" },
      { field_id: 7, field_name: "ออร์โธปิดิกส์" },
      { field_id: 8, field_name: "ระบาดวิทยา" },
      { field_id: 9, field_name: "พยาธิวิทยา" },
      { field_id: 10, field_name: "พยาบาล" },
      { field_id: 11, field_name: "อื่นๆ" },
    ],
    positionList: [
      { position_id: 1, position_name: "ผศ." },
      { position_id: 2, position_name: "รศ." },
      { position_id: 3, position_name: "ศ." },
      { position_id: 4, position_name: "ไม่มี" },
      { position_id: 5, position_name: "อื่นๆ" },
    ],
    affiliationList: [],
    teamList: [
      {
        id: 1,
        team_name: 'กองตรวจโรคผู้ป่วยนอก',
        team_name_en: 'Outpatient Department'
      },
      {
        id: 2,
        team_name: 'กองอุบัติเหตุและเวชกรรมฉุกเฉิน',
        team_name_en: 'Trauma and Emergency Medicine Department'
      },
      {
        id: 3,
        team_name: 'กองอายุรกรรม',
        team_name_en: 'Medicine Department'
      },
      {
        id: 4,
        team_name: 'กองจิตเวชและประสาทวิทยา',
        team_name_en: 'Psychiatry and Neurology Department'
      },
      {
        id: 5,
        team_name: 'กองการพยาบาล',
        team_name_en: 'Nursing Department'
      },
      {
        id: 6,
        team_name: 'กองรังสีกรรม',
        team_name_en: 'Radiology Department'
      },
      {
        id: 7,
        team_name: 'กองวิสัญญีและห้องผ่าตัด',
        team_name_en: 'Anesthesiology and operating room Department'
      },
      {
        id: 8,
        team_name: 'กองออร์โธปิดิกส์',
        team_name_en: 'Orthopedic Department'
      },
      {
        id: 9,
        team_name: 'กองเภสัชกรรม',
        team_name_en: 'Pharmaceutical Department'
      },
      {
        id: 10,
        team_name: 'กองสูตินรีเวชกรรม',
        team_name_en: 'Obstetrics And Gynecology Department'
      },
      {
        id: 11,
        team_name: 'กองกุมารเวชกรรม',
        team_name_en: 'Pediatric Department'
      },
      {
        id: 12,
        team_name: 'กองจักษุกรรม',
        team_name_en: 'Ophthalmology Department'
      },
      {
        id: 13,
        team_name: 'กองโสต ศอ นาสิกกรรม',
        team_name_en: 'Otolaryngology Department'
      },
      {
        id: 14,
        team_name: 'กองเวชศาสตร์ฟื้นฟู',
        team_name_en: 'Rehabilitation medicine Department'
      },
      {
        id: 15,
        team_name: 'กองทันตกรรม',
        team_name_en: 'Dental Department'
      },
      {
        id: 16,
        team_name: 'กองศัลยกรรม',
        team_name_en: 'Surgical Department'
      },
      {
        id: 17,
        team_name: 'กองพยาธิวิทยา',
        team_name_en: 'Pathology Department'
      },
      {
        team_name: 'ไม่มี'
      },
      {
        team_name: 'อื่นๆ'
      },
    ],
  }),
  computed: {
    ...mapState({
      token: (state) => state.Auth.registerToken,
    }),
    years() {
      this.year = new Date().getFullYear();
      return Array.from(
        { length: this.year - 1900 },
        (value, index) => 1901 + 543 + index
      ).reverse();
    },
  },
  created() {
    this.getTitle();
    this.getRank();
    this.getProvinces();
    this.getRole();
    this.getAffiliationList();
  },
  methods: {
    async getTitle() {
      const res = await this.$store.dispatch("Common/getTitleName");
      res.forEach((data) => {
        this.titleName.push({
          title_name_th: data.thTitle,
          title_id: data.id,
        });
      });
    },
    async getRank() {
      const res = await this.$store.dispatch("Common/getRank");
      this.rankName = res;
    },
    async updateData(name, val) {
      this.form[name] = val;
      switch (name) {
        case "province":
          this.getDistricts(val);
          break;
        case "district":
          this.getSubDistricts(val);
          break;
        case "field":
          if (this.form["field"] === "อื่นๆ") {
            this.isOtherField = false;
          } else {
            this.isOtherField = true;
            this.form["otherField"] = "";
          }
          this.form["otherField"] = "";
          break;
        case "position":
          if (this.form["position"] === "อื่นๆ") {
            this.isOtherPosition = false;
          } else {
            this.isOtherPosition = true;
            this.form["otherPosition"] = "";
          }
          this.form["otherPosition"] = "";
          break;
        case "affiliationID":
          if (this.form["affiliationID"] === 15) {
            this.isOtherAffiliation = false;
          } else {
            this.isOtherAffiliation = true;
            this.form["otherAffiliation"] = "";
            this.form["otherAffiliationEN"] = "";
          }
          this.form["otherAffiliation"] = "";
          this.form["otherAffiliationEN"] = "";
          break;
        case "team":
          this.teamEN = this.teamList.find((data) => data.team_name === val);
          this.form["team_en"] = this.teamEN.team_name_en;
          if (this.form["team"] === "อื่นๆ") {
            this.isOtherTeam = false;
          } else {
            this.isOtherTeam = true;
            this.form["otherTeam"] = "";
            this.form["otherTeamEN"] = "";
          }
          this.form["otherTeam"] = "";
          this.form["otherTeamEN"] = "";
          break;
        case "education":
          if (this.form["education"] === "อื่นๆ") {
            this.isOtherEducation = false;
          } else {
            this.isOtherEducation = true;
            this.form["otherEducation"] = "";
          }
          this.form["otherEducation"] = "";
          break;
        default:
        // code block
      }
    },
    uploadFile() {
      document.getElementById("file").click();
    },
    onFilePicked(e) {
      this.$store
        .dispatch("Common/uploadImage", { file: e, name: "image-profile" })
        .then((res) => {
          this.imageUrl = res.filePath;
          this.form.pictureFile = res.fileName;
        })
        .catch((e) => {
          document.getElementById("file").value = "";
          Swal.fire({
            title: "เกิดข้อผิดพลาด",
            text: "นามสกุลรูปภาพไม่ถูกต้อง (แนะนำ .jpg, .png)",
            icon: "warning",
            confirmButtonColor: "#3E7662",
          });
        });
    },
    selectDate() {
      this.menu = false;

      let sub_year = this.date.substring(0, 4);
      let sub_date = this.date.substring(4, 10);
      sub_year = parseInt(sub_year) + 543;
      let date = sub_year + sub_date;
    },
    validateForm() {
      if (!this.form.thFirstName) {
        Swal.fire({
          title: "กรุณากรอกชื่อจริงภาษาไทย",
          icon: "warning",
          confirmButtonColor: "#3E7662",
        });
      } else if (!this.form.enFirstName) {
        Swal.fire({
          title: "กรุณากรอกชื่อจริงภาษาอังกฤษ",
          icon: "warning",
          confirmButtonColor: "#3E7662",
        });
      } else if (!this.form.thLastName) {
        Swal.fire({
          title: "กรุณากรอกนามสกุลภาษาไทย",
          icon: "warning",
          confirmButtonColor: "#3E7662",
        });
      } else if (!this.form.enLastName) {
        Swal.fire({
          title: "กรุณากรอกนามสกุลภาษาอังกฤษ",
          icon: "warning",
          confirmButtonColor: "#3E7662",
        });
      } else if (!this.form.education) {
        Swal.fire({
          title: "กรุณาเลือกวุฒิการศึกษา",
          icon: "warning",
          confirmButtonColor: "#3E7662",
        });
      } else if (!this.form.workEx) {
        Swal.fire({
          title: "กรุณากรอกประวัติการทำงาน",
          icon: "warning",
          confirmButtonColor: "#3E7662",
        });
      } else if (!this.form.field) {
        Swal.fire({
          title: "กรุณาเลือกสาขาที่เชี่ยวชาญ",
          icon: "warning",
          confirmButtonColor: "#3E7662",
        });
      } else if (!this.form.position) {
        Swal.fire({
          title: "กรุณาเลือกตำแหน่งทางวิชาการ",
          icon: "warning",
          confirmButtonColor: "#3E7662",
        });
      } else if (!this.form.affiliationID) {
        Swal.fire({
          title: "กรุณาเลือกสังกัด",
          icon: "warning",
          confirmButtonColor: "#3E7662",
        });
      } else if (!this.form.houseNumber) {
        Swal.fire({
          title: "กรุณากรอกบ้านเลขที่",
          icon: "warning",
          confirmButtonColor: "#3E7662",
        });
      } else if (!this.form.road) {
        Swal.fire({
          title: "กรุณากรอกถนน",
          icon: "warning",
          confirmButtonColor: "#3E7662",
        });
      } else if (!this.form.province) {
        Swal.fire({
          title: "กรุณาเลือกจังหวัด",
          icon: "warning",
          confirmButtonColor: "#3E7662",
        });
      } else if (!this.form.subDistrict) {
        Swal.fire({
          title: "กรุณาเลือกอำเภอ/เขต",
          icon: "warning",
          confirmButtonColor: "#3E7662",
        });
      } else if (!this.form.district) {
        Swal.fire({
          title: "กรุณาเลือกตำบล/แขวง",
          icon: "warning",
          confirmButtonColor: "#3E7662",
        });
      } else if (!this.form.postalCode) {
        Swal.fire({
          title: "กรุณากรอกรหัสไปรษณีย์",
          icon: "warning",
          confirmButtonColor: "#3E7662",
        });
      } else if (!this.form.telPhone) {
        Swal.fire({
          title: "กรุณากรอกโทรศัพท์ที่ทำงาน",
          icon: "warning",
          confirmButtonColor: "#3E7662",
        });
      } else if (!this.form.mobilePhone) {
        Swal.fire({
          title: "กรุณากรอกโทรศัพท์มือถือ",
          icon: "warning",
          confirmButtonColor: "#3E7662",
        });
        // } else if (!this.form.practiceEx) {
        //     Swal.fire({
        //         title: 'กรุณากรอกประวัติการอบรม',
        //         icon: 'warning',
        //         confirmButtonColor: '#3E7662',
        //     })
      } else if (!this.form.email) {
        Swal.fire({
          title: "กรุณากรอก E-mail Address",
          icon: "warning",
          confirmButtonColor: "#3E7662",
        });
      } else if (!this.form.password) {
        Swal.fire({
          title: "กรุณากรอก password",
          icon: "warning",
          confirmButtonColor: "#3E7662",
        });
      } else {
        this.register();
      }
    },
    register() {
      if (this.form["affiliationID"] === 15) {
        if (this.form.otherAffiliation == "" || this.form.otherAffiliation == null) {
          Swal.fire({
            title: "เกิดข้อผิดพลาด",
            text: "กรุณาระบุสังกัด",
            icon: "warning",
            confirmButtonColor: "#3E7662",
          })
          return;
        }
        if (this.form.otherAffiliationEN == "" || this.form.otherAffiliationEN == null) {
          Swal.fire({
            title: "เกิดข้อผิดพลาด",
            text: "กรุณาระบุสังกัด",
            icon: "warning",
            confirmButtonColor: "#3E7662",
          })
          return;
        }
      }
      if (this.form['team'] == "อื่นๆ") {
        if (this.form.otherTeam == "" || this.form.otherTeam == null) {
          Swal.fire({
            title: "เกิดข้อผิดพลาด",
            text: "กรุณาระบุกอง",
            icon: "warning",
            confirmButtonColor: "#3E7662",
          })
          return;
        }
        if (this.form.otherTeamEN == "" || this.form.otherTeamEN == null) {
          Swal.fire({
            title: "เกิดข้อผิดพลาด",
            text: "กรุณาระบุกอง",
            icon: "warning",
            confirmButtonColor: "#3E7662",
          })
          return;
        }
      }
      Swal.fire({
        title: "ยืนยันการสมัครสมาชิก?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3E7662",
        cancelButtonColor: "#d33",
        confirmButtonText: "ใช่ ต้องการ",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          if (!this.form.pictureFile) {
            this.form.pictureFile = "default614788644.png";
          }
          this.$store
            .dispatch("Auth/register", this.form)
            .then((res) => {
              this.isLoading = false;
              Swal.fire({
                title: "Success",
                text: "สมัครสมาชิกเรียบร้อยแล้ว",
                confirmButtonColor: "#3E7662",
              }).then(() => {
                this.$router.push("/login");
              });
            })
            .catch((e) => {
              this.isLoading = false;
              if (e.response.data.errors) {
                for (var k in e.response.data.errors) {
                  Swal.fire({
                    title: "เกิดข้อผิดพลาด",
                    text: e.response.data.errors[k][0],
                    icon: "warning",
                    confirmButtonColor: "#3E7662",
                  }).then(() => {
                    this.$vuetify.goTo(`#${k}`);
                  });
                }
              }
            });
        }
      });
    },
    //Get Province
    getProvinces() {
      this.provinces = [];
      this.districts = [];
      this.subDistricts = [];
      this.isLoading = true;
      this.$store.dispatch("Common/getProvince").then((res) => {
        res.forEach((data) => {
          this.provinces.push({
            province_code: data.code,
            province_name: data.name,
            province_id: data.province_id,
          });
        });
        this.form.district = "";
        this.form.subDistrict = "";

        this.isLoading = false;
      });
    },
    //Get District
    getDistricts(val) {
      if (val) {
        this.isLoading = true;
        this.districts = [];
        this.subDistricts = [];
        this.form.district = "";
        this.form.subDistrict = "";
        this.$store
          .dispatch("Common/getDistricts", val)
          .then((data) => {
            this.district = [];
            data.forEach((dataDistrict) => {
              this.districts.push({
                district_id: dataDistrict.district_id,
                province_id: dataDistrict.province_id,
                district_name: dataDistrict.name,
              });
            });
            this.form.district = "";
            this.form.subDistrict = "";
            this.isLoading = false;
          })
          .catch((e) => {
            this.isLoading = false;
          });
      } else {
        this.districts = [];
        this.subDistricts = [];
        this.form.district = "";
        this.form.subDistrict = "";
      }
    },
    //Get Sub-District
    getSubDistricts(val) {
      if (val) {
        this.subDistricts = [];
        this.isLoading = true;
        this.$store.dispatch("Common/getSubDistricts", val).then((res) => {
          res.forEach((dataDistrict) => {
            this.subDistricts.push({
              subDistrict_id: dataDistrict.subdistrict_id,
              province_id: dataDistrict.province_id,
              subDistrict_name: dataDistrict.name,
            });
          });
          this.form.subDistrict = "";
          this.isLoading = false;
        });
      } else {
        this.form.subDistrict = "";
        this.subDistricts = [];
      }
    },
    // set sub district
    setDataSelect(name, val) {
      if (name === "subDistricts") {
        this.form.subDistrict = val.subDistrict_id;
      }
      if (name === "role") {
        this.form.role = val.role_id;
      }
      if (name === "nameTitle") {
        this.form.nameTitle = val.title_id;
      }
    },
    //change Data Province
    changeDataProvince(val) { },
    // Get role
    getRole() {
      this.isLoading = true;
      this.$store.dispatch("Common/getRole").then((res) => {
        res.forEach((data) => {
          this.role.push({
            ...data,
            role_id: data.id,
            role_name: data.role,
          });
        });
        this.isLoading = false;
      });
    },
    // Get AffiliationList
    getAffiliationList() {
      this.affiliationList = [];
      this.isLoading = true;
      this.$store.dispatch("Common/getAffiliationList").then((res) => {
        res.forEach((data) => {
          this.affiliationList.push({
            affiliation_id: data.id,
            affiliation_name: data.affiliation,
          });
        });
        this.isLoading = false;
      });
    },
    // Upload Certificate
    uploadCertificate() {
      document.getElementById("fileCertificate").click();
    },
    onFilePickedCertificate(e) {
      const files = e;
      if (files !== undefined) {
        const fr = new FileReader();
        fr.readAsDataURL(files);
        fr.addEventListener("load", () => {
          this.imageUrl = fr.result;
          this.form.pictureFile = files; // this is an image file that can be sent to server...
          this.form.certificate_gcp_training = files;
        });
      } else {
        this.imageName = "";
        this.imageFile = "";
        this.imageUrl = "";
      }
    },
  },
};
</script>

<style lang="scss"></style>
