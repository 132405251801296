// import Service from '../Service'
import AuthService from '../AuthService'

const FormRF = {
    saveRF04_1(data) {
        return AuthService.post('/api/adverse-drug-event', data).then(res => res)
    },
    saveRF04_2(data) {
        return AuthService.post('/api/adverse-event', data).then(res => res)
    },
    saveRF06(data) {
        return AuthService.post('/api/research-summarize', data).then(res => res)
    },
    saveRFSusar(data) {
        return AuthService.post('/api/form-susar', data).then(res => res)
    },
    saveRF12(data) {
        // return AuthService.post('/api/research-amendment', data).then(res => res)
        let formData = new FormData();
        formData.append('researchCode',data.researchCode ? data.researchCode : '');
        formData.append('firstName',data.firstName ? data.firstName : '');
        formData.append('lastName',data.lastName ? data.lastName : '');
        formData.append('researchName',data.researchName ? data.researchName : '');
        formData.append('hasScreening',data.hasScreening);
        formData.append('screening',data.screening ? data.screening : '');
        formData.append('hasAdjustMethod',data.hasAdjustMethod);
        formData.append('adjustMethod',data.adjustMethod ? data.adjustMethod : '');
        formData.append('hasAddOperate',data.hasAddOperate);
        formData.append('addOperate',data.addOperate ? data.addOperate : '');
        formData.append('hasExpandTime',data.hasExpandTime);
        formData.append('expandTime',data.expandTime ? data.expandTime : '');
        formData.append('hasAdjustOtherMethod',data.hasAdjustOtherMethod);
        formData.append('adjustOtherMethod',data.adjustOtherMethod ? data.adjustOtherMethod : '');
        formData.append('fileInformationSheet', data.fileInformationSheet ? JSON.stringify(data.fileInformationSheet) : '');
        formData.append('fileProtocolModify', data.fileProtocolModify ? JSON.stringify(data.fileProtocolModify) : '');
        formData.append('inclusion',data.inclusion ? '1' : '0');
        formData.append('procedureChange',data.procedureChange ? '1' : '0');
        formData.append('editorial',data.editorial ? '1' : '0');
        formData.append('hasOtherChange',data.hasOtherChange ? '1' : '0');
        formData.append('otherChange',data.otherChange ? data.otherChange : '');
        formData.append('fileInvestigator', data.fileInvestigator ? JSON.stringify(data.fileInvestigator) : '');
        formData.append('hasNewInformation',data.hasNewInformation ? '1' : '0');
        formData.append('hasAdvertisement',data.hasAdvertisement ? '1' : '0');
        formData.append('hasChangeInTitle',data.hasChangeInTitle ? '1' : '0');
        formData.append('fileOther', data.fileOther ? JSON.stringify(data.fileOther) : '');
        formData.append('changeInTitle',data.changeInTitle ? data.changeInTitle : '');
        formData.append('hasChangeInInvestigator',data.hasChangeInInvestigator ? '1' : '0');
        formData.append('changeInInvestigatorAdd',data.changeInInvestigatorAdd ? data.changeInInvestigatorAdd : '');
        formData.append('changeInInvestigatorDelete',data.changeInInvestigatorDelete ? data.changeInInvestigatorDelete : '');
        formData.append('hasChangeInSponsor',data.hasChangeInSponsor ? '1' : '0');
        formData.append('changeInSponsorAdd',data.changeInSponsorAdd ? data.changeInSponsorAdd : '');
        formData.append('changeInSponsorDelete',data.changeInSponsorDelete ? data.changeInSponsorDelete : '');
        formData.append('signature',data.signature ? data.signature : '');
        formData.append('researcherSignature',data.researcherSignature ? data.researcherSignature : '');
        formData.append('date',data.date ? data.date : '');
        formData.append('reportDate',data.reportDate ? data.reportDate : '');
        formData.append('researchID',data.researchID ? data.researchID : '');
        formData.append('researchName2',data.researchName2 ? data.researchName2 : '');
        formData.append('researcher',data.researcher ? data.researcher : '');
        formData.append('phoneNumber',data.phoneNumber ? data.phoneNumber : '');
        formData.append('affiliation',data.affiliation ? data.affiliation : '');
        formData.append('agencyBook',data.agencyBook ? data.agencyBook : '');
        formData.append('bookNumber',data.bookNumber ? data.bookNumber : '');
        formData.append('bookDate',data.bookDate ? data.bookDate : '');
        formData.append('methods',data.methods);
        formData.append('instituteBook',data.instituteBook);
        formData.append('rfID',data.rfID);
        formData.append('information',data.information ? JSON.stringify(data.information) : '');
        return AuthService.post('/api/research-amendment', formData, {
            headers: {
                "Content-Type": 'multipart/form-data',
            }
        });
    },
    saveRF03(data) {
        return AuthService.post('/api/progress-report', data).then(res => res)
    },
    saveRF05(data) {
        return AuthService.post('/api/deviation-report', data).then(res => res)
    },
    saveRF07(data) {
        return AuthService.post('/api/research-termination', data).then(res => res)
    },
    sendCorres(data) {
        return AuthService.post('/api/correspondence', data).then(res => res)
    },
    getRFSusar(id) {
        return AuthService.get(`/api/research-susar/${id}`).then(res => res)
    },
    getRF12(id) {
        return AuthService.get(`/api/research-amendment/${id}`).then(res => res)
    },
    getRF04_1(id) {
        return AuthService.get(`/api/adverse-drug-event/${id}`).then(res => res)
    },
    getRF04_2(id) {
        return AuthService.get(`/api/adverse-event/${id}`).then(res => res)
    },
    getRF05(id) {
        return AuthService.get(`/api/deviation-report/${id}`).then(res => res)
    },
    getRF06(id) {
        return AuthService.get(`/api/research-summarize/${id}`).then(res => res)
    },
    getRF07(id) {
        return AuthService.get(`/api/research-termination/${id}`).then(res => res)
    },
    getRF03(id) {
        return AuthService.get(`/api/progress-report/${id}`).then(res => res)
    },
    getHistoryRF12(id) {
        // return AuthService.get(`/api/research-amendment/${id}`).then(res => res)
    },
    getHistoryRF04_1(id) {
        // return AuthService.get(`/api/research-amendment/${id}`).then(res => res)
    },
    getHistoryRF04_2(id) {
        // return AuthService.get(`/api/research-amendment/${id}`).then(res => res)
    },
    getHistoryRF05(id) {
        // return AuthService.get(`/api/research-amendment/${id}`).then(res => res)
    },
    getHistoryRF03(id) {
        // return AuthService.get(`/api/research-amendment/${id}`).then(res => res)
    },
    getHistoryRF06(id) {
        // return AuthService.get(`/api/research-amendment/${id}`).then(res => res)
    },
    getHistoryRF07(id) {
        // return AuthService.get(`/api/research-amendment/${id}`).then(res => res)
    },
}

export default FormRF;
