import Service from '../Service'
import AuthService from '../AuthService'

const ResearchForm = {
    saveResearchPage1(data) {
        let formData = new FormData();
        formData.append('thResearchName', data.researchs.thResearchName);
        formData.append('enResearchName', data.researchs.enResearchName);
        formData.append('protocolNumber', data.researchs.protocolNumber);
        formData.append('mainResearcherID', data.researchs.mainResearcherID);
        formData.append('researcherProfile', data.researchs.researcherProfile);
        formData.append('field', data.researchs.field);
        formData.append('startDate', data.researchs.startDate);
        formData.append('endDate', data.researchs.endDate);
        formData.append('researchPlace', data.researchs.researchPlace);
        formData.append('researchPlaceEn', data.researchs.researchPlaceEn);
        formData.append('educationType', data.researchs.educationType);
        formData.append('checkboxSampleGroup', JSON.stringify(data.researchs.checkboxSampleGroup));
        formData.append('budgetResourceID', data.researchs.budgetResourceID);
        formData.append('budgetAmount', data.researchs.budgetAmount);
        formData.append('checkboxInterestedGroup', JSON.stringify(data.researchs.checkboxInterestedGroup));
        formData.append('ownerAmount', data.researchs.ownerAmount);
        formData.append('compensationAmount', data.researchs.compensationAmount);
        formData.append('otherAmount', data.researchs.otherAmount);
        formData.append('guarantyFile', data.researchs.guarantyFile);
        formData.append('researchFile', data.researchs.researchFile);
        formData.append('ro01_1File', data.researchs.ro01_1File);
        formData.append('ro01_2File', data.researchs.ro01_2File);
        formData.append('crecFile', data.researchs.crecFile);
        formData.append('editPointFile', data.researchs.editPointFile);
        formData.append('researchFileClean', data.researchs.researchFileClean);
        formData.append('researchFileHighlight', data.researchs.researchFileHighlight);
        formData.append('ro01_1FileClean', data.researchs.ro01_1FileClean);
        formData.append('ro01_1FileHighlight', data.researchs.ro01_1FileHighlight);
        formData.append('ro01_2FileClean', data.researchs.ro01_2FileClean);
        formData.append('ro01_2FileHighlight', data.researchs.ro01_2FileHighlight);
        formData.append('considerResultFile', data.researchs.considerResultFile);
        formData.append('crecFile2', data.researchs.crecFile2);
        formData.append('submitReportForConsider', data.researchs.submitReportForConsider);
        formData.append('submitReportForConsiderClean', data.researchs.submitReportForConsiderClean);
        formData.append('submitReportForConsiderHighlight', data.researchs.submitReportForConsiderHighlight);
        formData.append('otherEducationType', data.researchs.otherEducationType);
        formData.append('otherSampleGroup', data.researchs.otherSampleGroup);
        formData.append('guarantyNumber', data.researchs.guarantyNumber);
        formData.append('researchID', data.researchID);
        formData.append('researcher', JSON.stringify(data.researchers));
        formData.append('researcherDelete', JSON.stringify(data.researchersDelete));
        formData.append('research_info', JSON.stringify(data.researcher_info));
        formData.append('isManual', data.researchs.isManual);
        formData.append('researchCode', data.researchs.researchCode == null ? '' : data.researchs.researchCode);
        formData.append('ymidtype', data.researchs.ymidtype);
        formData.append('ymidname', data.researchs.ymidname);
        formData.append('_method', 'POST');
        for (let pair of formData.entries()) {
        }
        return AuthService.post('/api/add-research', formData, {
            headers: {
                "Content-Type": 'multipart/form-data',
            }
        }).then(res => res)
    },

    saveResearchPage2(data, id) {
        return AuthService.post(`/api/add-research-page3-4/${id}`, data).then(res => res)
    },

    saveResearchPage3(data, id) {
        return AuthService.post(`/api/add-research-page3-4/${id}`, data).then(res => res)
    },

    saveResearchPage4(data, id) {
        let formData = new FormData();
        formData.append('reference', data.reference);
        formData.append('related_documents', data.related_documents);
        formData.append('data_collection_form', data.data_collection_form);
        formData.append('history', data.history);
        formData.append('other', data.other);
        formData.append('checkResearch', data.checkResearch);
        formData.append('_method', 'POST');
        return AuthService.post(`/api/add-research-page5/${id}`, formData, {
            headers: {
                "Content-Type": 'multipart/form-data',
            }
        }).then(res => res)
    },

    sendResearch(data, id) {
        let formData = new FormData();
        formData.append('checkResearch', data.checkResearch);
        formData.append('dataRecordFrom', data.dataRecordFrom);
        formData.append('questionnaireInterview', data.questionnaireInterview);
        formData.append('diary', data.diary);
        formData.append('documentsExplain', data.documentsExplain);
        formData.append('otherDocuments', data.otherDocuments);
        formData.append('certificate', data.certificate);
        formData.append('investigatorBrochure', data.investigatorBrochure);
        formData.append('researcherProfile', data.researcherProfile);
        // formData.append('submitReportForConsider',data.submitReportForConsider);
        formData.append('dataRecordFromClean', data.dataRecordFromClean);
        formData.append('questionnaireInterviewClean', data.questionnaireInterviewClean);
        formData.append('diaryClean', data.diaryClean);
        formData.append('dataRecordFromHighlight', data.dataRecordFromHighlight);
        formData.append('questionnaireInterviewHighlight', data.questionnaireInterviewHighlight);
        formData.append('diaryHighlight', data.diaryHighlight);
        formData.append('documentsExplainClean', data.documentsExplainClean);
        formData.append('documentsExplainHighlight', data.documentsExplainHighlight);
        formData.append('otherDocuments2', data.otherDocuments2);
        formData.append('certificate2', data.certificate2);
        formData.append('investigatorBrochure2', data.investigatorBrochure2);
        formData.append('researcherProfile2', data.researcherProfile2);
        // formData.append('submitReportForConsiderClean',data.submitReportForConsiderClean);
        // formData.append('submitReportForConsiderHighlight',data.submitReportForConsiderHighlight);
        formData.append('relatedDocuments', JSON.stringify(data.relatedDocuments));
        formData.append('_method', 'POST');
        return AuthService.post(`/api/create-research-page2/${id}`, formData, {
            headers: {
                "Content-Type": 'multipart/form-data',
            }
        }).then(res => res)
    },

    getFormResearch1(data) {
        console.log('qqweqeqeqe',data);
        return AuthService.get(`/api/show-research/${data.researchID}?researchPage=${data.researchPage}`).then(res => res)
    },

    getFormResearch2(id) {
        return AuthService.get(`/api/show-research/${id}`).then(res => res)
    },

    getFormResearch3(id) {
        return AuthService.get(`/api/show-research/${id}`).then(res => res)
    },

    getFormResearch4(id) {
        return AuthService.get(`/api/show-research/${id}`).then(res => res)
    },
    getResearchVerify(id) {
        return AuthService.get(`/api/get-research-verify/${id}`).then(res => res)
    },
    getCertificate() {
        return AuthService.get('/api/show-all-certificate-users');
    }
}

export default ResearchForm;
