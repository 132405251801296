<template>
    <v-content>

        <v-layout  justify-center py-6 px-4 style="background-color: #3E7662;">
            <v-flex xs10>
                <v-layout justify-space-around>
                    <v-flex>
                        <span @click="$router.push('/')" class="menu-header" :class="{ 'underline':$route.path === '/' }">หน้าแรก</span>
                    </v-flex>
                    <v-flex v-if="checkLogin === 1">
                        <span @click="createResearch" class="menu-header" :class="{ 'underline':$route.path === '/manual-research'}">สร้างงานวิจัยใหม่</span>
                    </v-flex>
                    <v-flex>
                        <span @click="$router.push('/download-form')" class="menu-header" :class="{ 'underline':$route.path === '/download-form'}">ดาวน์โหลดแบบฟอร์มและเอกสาร</span>
                    </v-flex>
                    <v-flex>
                        <span @click="$router.push('/sop-standard')" class="menu-header" :class="{ 'underline':$route.path === '/sop-standard'}">มาตรฐาน SOP</span>
                    </v-flex>
                    <v-flex v-if="checkLogin === 1">
                        <v-menu offset-y>
                            <template v-slot:activator="{ on }">
                                <span @click="$router.push({name: 'Profile', params: { edit: false, onFocus: false}})" v-on="on" class="menu-header" :class="{ 'underline': $route.path === '/profile'}">ข้อมูลนักวิจัย</span>
                            </template>
                            <!-- <v-list class="list pa-0" style="width: 200px">
                                <v-list-item class="list-item" @click="$router.push({name: 'Profile', params: { edit: false, onFocus: false}})">
                                    <v-list-item-title class="list-item-title">โปรไฟล์</v-list-item-title>
                                </v-list-item>
                            </v-list> -->
                        </v-menu>
                    </v-flex>
                    <v-flex>
                        <span @click="$router.push('/contact-us')" class="menu-header" :class="{ 'underline':$route.path == '/contact-us'}">ติดต่อเรา</span>
                    </v-flex>
                    <v-flex>
<!--                        <span @click="$router.push('/about-irbrta')" class="menu-header" :class="{ 'underline':$route.path == '/about-irbrta'}"></span>-->
                        <v-menu offset-y>
                            <template v-slot:activator="{ on }">
                                <span v-on="on" class="menu-header" :class="{ 'underline': $route.path === '/about-irbrta' || $route.path === '/vision' || $route.path === '/core-value'}">เกี่ยวกับ IRBRTA</span>
                            </template>
                            <v-list class="list pa-0" style="width: 200px">
                                <v-list-item class="list-item" @click="$router.push({name: 'AboutIRBRTA', params: { edit: false}})">
                                    <v-list-item-title class="list-item-title">ประวัติองค์กร</v-list-item-title>
                                </v-list-item>
                                <v-list-item class="list-item" @click="$router.push({name: 'Vision', params: { edit: false}})">
                                    <v-list-item-title class="list-item-title">วิสัยทัศน์ และพันธกิจ</v-list-item-title>
                                </v-list-item>
                                <v-list-item class="list-item" @click="$router.push({name: 'CoreValue', params: { edit: false}})">
                                    <v-list-item-title class="list-item-title">Core Value</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
    </v-content>
</template>

<script>
    import Swal from 'sweetalert2';
    import isOnlineExpand from '../views/Profile'
    import { mapState , mapMutations} from "vuex";

    export default {
        name: "MenuHeader",
        data: () => ({
            activeClass: 'active',
            check_login: true,
            openOnHover: false,
            activeResearchCount: 0,
        }),
        computed: {
            ...mapState({
                checkLogin: state => state.Auth.checkLogin,
                user: state => state.Auth.user,
                remainingDate: state => state.Common.remainingDate,
                isOnlineExpand: state => state.Auth.isOnlineExpand
                // activeResearchCount: state => state.Profile.activeResearchCount,
            })
        },
        created() {
          this.getAllResearchPaginate();
        },
        methods: {
            ...mapMutations('Auth',['Expand_STATE']),
            createResearch() {

                if(this.user.remark === 'B') {
                    Swal.fire({
                        title: 'Blacklist',
                        text: 'ท่านถูกระงับการสร้างงานวิจัย กรุณาติดต่อเจ้าหน้าที่',
                        icon: 'warning',
                        confirmButtonColor: '#3E7662',
                    })
                }else if(this.user.remark === 'T') {
                    Swal.fire({
                        title: 'Training request',
                        text: 'กรุณาอบรม GCP ซ้ำอีกรอบ ก่อนดำเนินการใดๆ ในระบบ',
                        icon: 'warning',
                        confirmButtonColor: '#3E7662',
                    })
                }else if(this.user.remark === 'C'){
                    if(this.activeResearchCount > 2)
                        Swal.fire({
                            title: 'Careful Monitoring',
                            text: 'ท่านถูกจำกัดสิทธิ์ ไม่สามารถมีงานวิจัยที่อยู่ระหว่างดำเนินการได้เกิน 2 เรื่อง',
                            icon: 'warning',
                            confirmButtonColor: '#3E7662',
                        });
                    else
                        this.$router.push('/manual-research');
                } else if (this.user.certificateStatus === 0) {
                    Swal.fire({
                        title: 'รอการตรวจสอบ',
                        text: 'กรุณาติดต่อเจ้าหน้าที่',
                        icon: 'warning',
                        confirmButtonColor: '#3E7662',
                    })
                } else if (this.user.certificateStatus === 1 && this.remainingDate !== 0) {
                    this.$router.push('/manual-research');
                } else if (this.user.certificateStatus === 1 && this.remainingDate === 0) {
                    Swal.fire({
                        title: 'ใบ Certificate หมดอายุ!',
                        text: 'ระบบไม่สามารถสร้างโครงร่างงานวิจัยได้',
                        icon: 'warning',
                        confirmButtonColor: '#3E7662',
                    })
                } else {
                    Swal.fire({
                        title: 'ท่านยังไม่มีใบ Certificate !',
                        text: 'ระบบไม่สามารถสร้างโครงร่างงานวิจัยได้',
                        icon: 'warning',
                        confirmButtonColor: '#3E7662',
                    }).then(() => {
                        this.Expand_STATE();
                        setTimeout(() => {
                            document.getElementById('uploadBtn').focus();
                        }, 400)
                    })
                }

            },
            getAllResearchPaginate() {
                this.itemResearchAll= [];
                this.isLoading = true;
                this.$store.dispatch('Profile/getAllResearchPaginate').then(res => {
                    res.forEach(data => {
                        if(data.status === 'รอดำเนินการ' || data.status === 'อยู่ระหว่างการพิจารณา' || data.status === 'ปรับปรุงแก้ไขเพื่อพิจารณาใหม่' || data.status === 'ปรับปรุงแก้ไขเพื่อรับรอง' || data.status === 'ผ่านการพิจารณา' || data.status === 'อนุมัติ')
                            this.activeResearchCount++;
                    })
                }).catch(e => {
                    this.isLoading = false;
                })

            },
        }
    }
</script>

<style lang="scss" scoped>
    .list-item {
        padding-top: 0;
        cursor: pointer;
        text-align: left;
    }
    .list-item:hover {
        background-color: #39896B;
        .list-item-title {
            color: white;
        }
    }
    .menu-header {
        color: white;
        cursor: pointer;
        font-size: 20px;
    }
    .menu-header:hover {
        color: #42b983;
    }
    .underline {
        border-bottom: solid 1px #FFF;
    }
</style>
