<template>
  <v-content>
    <v-layout>
      <v-flex px-12 pt-12 pb-4 style="text-align: left;">
        <span style="font-size: 24px;color: #3E7662;font-weight: bold;">
          คู่มือการใช้งานระบบ
        </span>
        <!--<span>เรื่อง หลักเกณฑ์ วิธีการ และเงื่อนไขการยอมรับคณะกรรมการพิจารณาจริยธรรมการวิจัยในคน ที่พิจารณาโครงการวิจัยทางคลินิกเกี่ยวกับยา</span>-->
      </v-flex>
    </v-layout>

    <v-layout>
      <v-flex px-12 pb-12>
        <v-card color="#FFFFFF">
          <v-layout>
            <v-flex pa-12 style="text-align: left">
              <v-layout>
                <v-flex xs10>
                  คู่มือการใช้งานระบบ (Manual Documentation)
                </v-flex>
                <v-flex xs2 style="text-align: right">
                  <v-btn color="#3E7662" dark @click="downloadForm('draft')">
                    <v-icon class="pr-3">cloud_download</v-icon>
                    <span>Download</span>
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex py-3 style="text-align: right">
                  <v-divider style="background-color: white"></v-divider>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout>
      <v-flex px-12 pt-12 pb-4 style="text-align: left;">
        <span style="font-size: 24px;color: #3E7662;font-weight: bold;">
          สำหรับแพทย์, พยาบาล, นักศึกษา,สำหรับบริษัทยา
        </span>
      </v-flex>
    </v-layout>

    <v-layout>
      <v-flex px-12 pb-12>
        <v-card color="#FFFFFF">
          <v-layout>
            <v-flex pa-12 style="text-align: left">
              <v-layout>
                <v-flex xs10>
                  RF 01_2563 แบบรายงานการส่งโครงร่างการวิจัยเพื่อพิจารณา
                </v-flex>
                <v-flex xs2 style="text-align: right">
                  <v-btn color="#3E7662" dark @click="downloadForm('rf01')">
                    <v-icon class="pr-3">cloud_download</v-icon>
                    <span>Download</span>
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex py-3>
                  <v-divider style="background-color: white"></v-divider>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs10 class="d-flex align-center">
                  RF 08_1_2563 คู่มือการเขียนโครงร่างการวิจัย
                </v-flex>
                <v-flex xs2 style="text-align: right">
                  <v-btn color="#3E7662" dark @click="downloadForm('rf08')">
                    <v-icon class="pr-3">cloud_download</v-icon>
                    <span>Download</span>
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex py-3>
                  <v-divider style="background-color: white"></v-divider>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs10 class="d-flex align-center">
                  RF 09_1_2563
                  คู่มือการเขียนเอกสารชี้แจงข้อมูลและหนังสือแสดงเจตนายินยอม
                </v-flex>
                <v-flex xs2 style="text-align: right">
                  <v-btn color="#3E7662" dark @click="downloadForm('rf09_1')">
                    <v-icon class="pr-3">cloud_download</v-icon>
                    <span>Download</span>
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex py-3>
                  <v-divider style="background-color: white"></v-divider>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs10 class="d-flex align-center">
                  RF 09_2_2563 เอกสารชี้แจงข้อมูลสำหรับการรายงานกรณีศึกษา
                </v-flex>
                <v-flex xs2 style="text-align: right">
                  <v-btn color="#3E7662" dark @click="downloadForm('rf09_2')">
                    <v-icon class="pr-3">cloud_download</v-icon>
                    <span>Download</span>
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex py-3>
                  <v-divider style="background-color: white"></v-divider>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs10 class="d-flex align-center">
                  RF 09_3_2563
                  เอกสารชี้แจงข้อมูลแก่อาสาสมัครในโครงการวิจัยเกี่ยวกับมนุษยพันธุ์ศาสตร์วัตถุชีวภาพ
                </v-flex>
                <v-flex xs2 style="text-align: right">
                  <v-btn color="#3E7662" dark @click="downloadForm('rf09_3')">
                    <v-icon class="pr-3">cloud_download</v-icon>
                    <span>Download</span>
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex py-3>
                  <v-divider style="background-color: white"></v-divider>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs10 class="d-flex align-center">
                  RF 10_2563 แบบประวัติผู้วิจัย
                </v-flex>
                <v-flex xs2 style="text-align: right">
                  <v-btn color="#3E7662" dark @click="downloadForm('rf10')">
                    <v-icon class="pr-3">cloud_download</v-icon>
                    <span>Download</span>
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex py-3>
                  <v-divider style="background-color: white"></v-divider>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs10 class="d-flex align-center">
                  ตัวอย่างบันทึกข้อความ letter
                </v-flex>
                <v-flex xs2 style="text-align: right">
                  <v-btn
                    color="#3E7662"
                    dark
                    @click="downloadForm('letter-example')"
                  >
                    <v-icon class="pr-3">cloud_download</v-icon>
                    <span>Download</span>
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex py-3 style="text-align: right">
                  <v-divider style="background-color: white"></v-divider>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout>
      <v-flex px-12 pt-12 pb-4 style="text-align: left;">
        <span style="font-size: 24px;color: #3E7662;font-weight: bold;">
          แบบฟอร์มดาวน์โหลดทั้งหมด
        </span>
        <!--<br><span>เอกสารประกอบการยื่นขอรับการพิจารณา (ใช้ Font TH Sarabun PSK 14)</span>-->
      </v-flex>
    </v-layout>

    <v-layout>
      <v-flex px-12 pb-12>
        <v-card color="#FFFFFF">
          <v-layout class="justify-center">
            <v-flex class="pa-12">
              <v-layout>
                <v-flex xs10 class="d-flex align-center">
                  RF 01_2563 แบบรายงานการส่งโครงร่างการวิจัยเพื่อพิจารณา
                </v-flex>
                <v-flex xs2 style="text-align: right">
                  <v-btn color="#3E7662" dark @click="downloadForm('rf01')">
                    <v-icon class="pr-3">cloud_download</v-icon>
                    <span>Download</span>
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex py-3>
                  <v-divider style="background-color: white"></v-divider>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs10 class="d-flex align-center">
                  RF 02_2563 แบบรายงานการแก้ไขโครงร่างการวิจัย
                </v-flex>
                <v-flex xs2 style="text-align: right">
                  <v-btn color="#3E7662" dark @click="downloadForm('rf02')">
                    <v-icon class="pr-3">cloud_download</v-icon>
                    <span>Download</span>
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex py-3>
                  <v-divider style="background-color: white"></v-divider>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs10 class="d-flex align-center">
                  RF 03_2563 แบบรายงานความก้าวหน้าของการวิจัย
                </v-flex>
                <v-flex xs2 style="text-align: right">
                  <v-btn color="#3E7662" dark @click="downloadForm('rf03_1')">
                    <v-icon class="pr-3">cloud_download</v-icon>
                    <span>Download</span>
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex py-3>
                  <v-divider style="background-color: white"></v-divider>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs10 class="d-flex align-center">
                  RF 04_1_2563
                  แบบรายงานเหตุการณ์ไม่พึงประสงค์จากยาเหตุการณ์ไม่คาดคิด
                </v-flex>
                <v-flex xs2 style="text-align: right">
                  <v-btn color="#3E7662" dark @click="downloadForm('rf04_1')">
                    <v-icon class="pr-3">cloud_download</v-icon>
                    <span>Download</span>
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex py-3>
                  <v-divider style="background-color: white"></v-divider>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs10 class="d-flex align-center">
                  RF 04_2_2563 แบบรายงานเหตุการณ์ไม่พึงประสงค์ (SAE)
                </v-flex>
                <v-flex xs2 style="text-align: right">
                  <v-btn color="#3E7662" dark @click="downloadForm('rf04_2')">
                    <v-icon class="pr-3">cloud_download</v-icon>
                    <span>Download</span>
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex py-3 style="text-align: right">
                  <v-divider style="background-color: white"></v-divider>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs10 class="d-flex align-center">
                  RF 05_2563 แบบรายงานการไม่ปฏิบัติตามข้อกำหนด
                </v-flex>
                <v-flex xs2 style="text-align: right">
                  <v-btn color="#3E7662" dark @click="downloadForm('rf05')">
                    <v-icon class="pr-3">cloud_download</v-icon>
                    <span>Download</span>
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex py-3>
                  <v-divider style="background-color: white"></v-divider>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs10 class="d-flex align-center">
                  RF 06_2563 แบบรายงานแจ้งปิดสรุปผลการวิจัย
                </v-flex>
                <v-flex xs2 style="text-align: right">
                  <v-btn color="#3E7662" dark @click="downloadForm('rf06')">
                    <v-icon class="pr-3">cloud_download</v-icon>
                    <span>Download</span>
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex py-3>
                  <v-divider style="background-color: white"></v-divider>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs10 class="d-flex align-center">
                  RF 07_2563 แบบรายงานการยุติโครงการวิจัยก่อนกำหนด
                </v-flex>
                <v-flex xs2 style="text-align: right">
                  <v-btn color="#3E7662" dark @click="downloadForm('rf07')">
                    <v-icon class="pr-3">cloud_download</v-icon>
                    <span>Download</span>
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex py-3>
                  <v-divider style="background-color: white"></v-divider>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs10 class="d-flex align-center">
                  RF 08_1_2563 คู่มือการเขียนโครงร่างการวิจัย
                </v-flex>
                <v-flex xs2 style="text-align: right">
                  <v-btn color="#3E7662" dark @click="downloadForm('rf08')">
                    <v-icon class="pr-3">cloud_download</v-icon>
                    <span>Download</span>
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex py-3>
                  <v-divider style="background-color: white"></v-divider>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs10 class="d-flex align-center">
                  RF 09_1_2563
                  คู่มือการเขียนเอกสารชี้แจงข้อมูลและหนังสือแสดงเจตนายินยอม
                </v-flex>
                <v-flex xs2 style="text-align: right">
                  <v-btn color="#3E7662" dark @click="downloadForm('rf09_1')">
                    <v-icon class="pr-3">cloud_download</v-icon>
                    <span>Download</span>
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex py-3>
                  <v-divider style="background-color: white"></v-divider>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs10 class="d-flex align-center">
                  RF 09_2_2563 เอกสารชี้แจงข้อมูลสำหรับการรายงานกรณีศึกษา
                </v-flex>
                <v-flex xs2 style="text-align: right">
                  <v-btn color="#3E7662" dark @click="downloadForm('rf09_2')">
                    <v-icon class="pr-3">cloud_download</v-icon>
                    <span>Download</span>
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex py-3>
                  <v-divider style="background-color: white"></v-divider>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs10 class="d-flex align-center">
                  RF 09_3_2563
                  เอกสารชี้แจงข้อมูลแก่อาสาสมัครในโครงการวิจัยเกี่ยวกับมนุษยพันธุ์ศาสตร์วัตถุชีวภาพ
                </v-flex>
                <v-flex xs2 style="text-align: right">
                  <v-btn color="#3E7662" dark @click="downloadForm('rf09_3')">
                    <v-icon class="pr-3">cloud_download</v-icon>
                    <span>Download</span>
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex py-3>
                  <v-divider style="background-color: white"></v-divider>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs10 class="d-flex align-center">
                  RF 10_2563 แบบประวัติผู้วิจัย ผู้ร่วม และที่ปรึกษา
                </v-flex>
                <v-flex xs2 style="text-align: right">
                  <v-btn color="#3E7662" dark @click="downloadForm('rf10')">
                    <v-icon class="pr-3">cloud_download</v-icon>
                    <span>Download</span>
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex py-3>
                  <v-divider style="background-color: white"></v-divider>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs10 class="d-flex align-center">
                  RF 11_2563 คำแนะนำการส่งโครงร่างการวิจัยเพื่อพิจารณา
                </v-flex>
                <v-flex xs2 style="text-align: right">
                  <v-btn color="#3E7662" dark @click="downloadForm('rf11')">
                    <v-icon class="pr-3">cloud_download</v-icon>
                    <span>Download</span>
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex py-3>
                  <v-divider style="background-color: white"></v-divider>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs10 class="d-flex align-center">
                  RF 12_2563 แบบรายงานการแก้ไขเพิ่มเติมโครงร่างการวิจัย
                </v-flex>
                <v-flex xs2 style="text-align: right">
                  <v-btn color="#3E7662" dark @click="downloadForm('rf12')">
                    <v-icon class="pr-3">cloud_download</v-icon>
                    <span>Download</span>
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex py-3>
                  <v-divider style="background-color: white"></v-divider>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs10 class="d-flex align-center">
                  RO 01_1_2563 หนังสือข้อตกลงรักษาความลับของข้อมูล
                </v-flex>
                <v-flex xs2 style="text-align: right">
                  <v-btn color="#3E7662" dark @click="downloadForm('ro01_1')">
                    <v-icon class="pr-3">cloud_download</v-icon>
                    <span>Download</span>
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex py-3>
                  <v-divider style="background-color: white"></v-divider>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs10 class="d-flex align-center">
                  RO 01_2_2563 หนังสือข้อตกลงในการดำเนินการวิจัย
                </v-flex>
                <v-flex xs2 style="text-align: right">
                  <v-btn color="#3E7662" dark @click="downloadForm('ro01_2')">
                    <v-icon class="pr-3">cloud_download</v-icon>
                    <span>Download</span>
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex py-3>
                  <v-divider style="background-color: white"></v-divider>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs10 class="d-flex align-center">
                  RO 13_2563 แบบรายงานขอค้นเอกสารโครงการวิจัย
                </v-flex>
                <v-flex xs2 style="text-align: right">
                  <v-btn color="#3E7662" dark @click="downloadForm('ro13')">
                    <v-icon class="pr-3">cloud_download</v-icon>
                    <span>Download</span>
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex py-3>
                  <v-divider style="background-color: white"></v-divider>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs10 class="d-flex align-center">
                  RO 16_2563 แบบบันทึกการร้องเรียน
                </v-flex>
                <v-flex xs2 style="text-align: right">
                  <v-btn color="#3E7662" dark @click="downloadForm('ro16')">
                    <v-icon class="pr-3">cloud_download</v-icon>
                    <span>Download</span>
                  </v-btn>
                </v-flex>
              </v-layout>

              <v-layout>
                <v-flex py-3 style="text-align: right">
                  <v-divider style="background-color: white"></v-divider>
                </v-flex>
              </v-layout>

              <!--<v-layout>-->
              <!--<v-flex py-3 style="text-align: right;color: #3E7662;font-weight: bold;cursor: pointer">-->
              <!--<span @click="downloadForm('orther')">ดาวน์โหลดแบบฟอร์มอื่นๆ...</span>-->
              <!--</v-flex>-->
              <!--</v-layout>-->
              <!--<v-layout>-->
              <!--<v-flex py-3>-->
              <!--<v-divider style="background-color: #3E7662; border-width: 1px"></v-divider>-->
              <!--</v-flex>-->
              <!--</v-layout>-->

              <!--<v-layout>-->
              <!--<v-flex pt-6 style="text-align: left;">-->
              <!--<p>-->
              <!-- - ให้แนบหลักฐานการอบรมจริยธรรมในมนุษย์ (GCP) มาด้วยทุกครั้ง ของผู้วิจัย ผู้ร่วม หรือที่ปรึกษา <br/>-->
              <!-- - แบบฟอร์มเก็บข้อมูล/แบบบันทึกข้อมูล/แบบสอบถาม/แบบสัมภาษณ์<br/>-->
              <!-- - ส่งอิเล็กทรอนิกส์ File ทาง E-mail หรือส่ง แผ่น CD ที่เป็น File Word และ PDF<br/>-->
              <!-- - ส่งเอกสาร จำนวน 4 ชุด (download แบบฟอร์มได้ที่เว็บไซต์นี้)<br/>-->
              <!--</p>-->
              <!--</v-flex>-->
              <!--</v-layout>-->
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </v-content>
</template>

<script>
import { domain } from "/src/services/Constants";
export default {
  name: "DownloadForm",
  methods: {
    downloadForm(name) {
      switch (name) {
        case "draft":
          window.open(
              domain+"/storage/แบบฟอร์มดาวน์โหลดทั้งหมด/[Research]Draft_manual.pdf"
          );
          break;
        case "rf01":
          window.open(
            domain+"/storage/แบบฟอร์มดาวน์โหลดทั้งหมด/RF 01_2563 แบบรายงานการส่งโครงร่างการวิจัยเพื่อพิจารณา.doc"
          );
          break;
        case "rf02":
          window.open(
            domain+"/storage/แบบฟอร์มดาวน์โหลดทั้งหมด/RF 02_2563 แบบรายงานการแก้ไขโครงร่างการวิจัย.doc"
          );
          break;
        case "rf03_1":
          window.open(
            domain+"/storage/แบบฟอร์มดาวน์โหลดทั้งหมด/RF 03_2563 แบบรายงานความก้าวหน้าของการวิจัย.doc"
          );
          break;
        case "rf04_1":
          window.open(
            domain+"/storage/แบบฟอร์มดาวน์โหลดทั้งหมด/RF 04_1_2563 แบบรายงานเหตุการณ์ไม่พึงประสงค์จากยาเหตุการณ์ไม่คาดคิด.doc"
          );
          break;
        case "rf04_2":
          window.open(
            domain+"/storage/แบบฟอร์มดาวน์โหลดทั้งหมด/RF 04_2_2563 แบบรายงานเหตุการณ์ไม่พึงประสงค์ (นอกเหนือจากยา).doc"
          );
          break;
        case "rf05":
          window.open(
            domain+"/storage/แบบฟอร์มดาวน์โหลดทั้งหมด/RF 05_2563 แบบรายงานการไม่ปฏิบัติตามข้อกำหนด.doc"
          );
          break;
        case "rf06":
          window.open(
            domain+"/storage/แบบฟอร์มดาวน์โหลดทั้งหมด/RF 06_2563 แบบรายงานแจ้งปิดสรุปผลการวิจัย.doc"
          );
          break;
        case "rf07":
          window.open(
            domain+"/storage/แบบฟอร์มดาวน์โหลดทั้งหมด/RF 07_2563 แบบรายงานการยุติโครงการวิจัยก่อนกำหนด.doc"
          );
          break;
        case "rf08":
          window.open(
            domain+"/storage/แบบฟอร์มดาวน์โหลดทั้งหมด/RF 08_1_2563 คู่มือการเขียนโครงร่างการวิจัย.doc"
          );
          break;
        case "rf09_1":
          window.open(
            domain+"/storage/แบบฟอร์มดาวน์โหลดทั้งหมด/RF 09_1_2563 คู่มือการเขียนเอกสารชี้แจงข้อมูลและหนังสือแสดงเจตนายินยอม.doc"
          );
          break;
        case "rf09_2":
          window.open(
            domain+"/storage/แบบฟอร์มดาวน์โหลดทั้งหมด/RF 09_2_2563 เอกสารชี้แจงข้อมูลสำหรับการรายงานกรณีศึกษา.doc"
          );
          break;
        case "rf09_3":
          window.open(
            domain+"/storage/แบบฟอร์มดาวน์โหลดทั้งหมด/RF 09_3_2563 เอกสารชี้แจงข้อมูลแก่อาสาสมัครในโครงการวิจัยเกี่ยวกับมนุษยพันธุ์ศาสตร์.doc"
          );
          break;
        case "rf10":
          window.open(
            domain+"/storage/แบบฟอร์มดาวน์โหลดทั้งหมด/RF 10_2563 แบบประวัติผู้วิจัย.doc"
          );
          break;
        case "rf11":
          window.open(
            domain+"/storage/แบบฟอร์มดาวน์โหลดทั้งหมด/RF 11_2563 คำแนะนำการส่งโครงร่างการวิจัยเพื่อพิจารณา.pdf"
          );
          break;
        case "rf12":
          window.open(
            domain+"/storage/แบบฟอร์มดาวน์โหลดทั้งหมด/RF 12_2563 แบบรายงานการแก้ไขเพิ่มเติมโครงร่างการวิจัย.doc"
          );
          break;
        case "ro01_1":
          window.open(
            domain+"/storage/แบบฟอร์มดาวน์โหลดทั้งหมด/RO 01_1_2563 หนังสือข้อตกลงรักษาความลับของข้อมูล.doc"
          );
          break;
        case "ro01_2":
          window.open(
            domain+"/storage/แบบฟอร์มดาวน์โหลดทั้งหมด/RO 01_2_2563 หนังสือข้อตกลงในการดำเนินการวิจัย.doc"
          );
          break;
        case "ro13":
          window.open(
            domain+"/storage/แบบฟอร์มดาวน์โหลดทั้งหมด/RO 13_2563 แบบรายงานขอค้นเอกสารโครงการวิจัย.doc"
          );
          break;
        case "ro16":
          window.open(
            domain+"/storage/แบบฟอร์มดาวน์โหลดทั้งหมด/RO 16_2563 แบบบันทึกการร้องเรียน.doc"
          );
          break;
        case "letter-example":
          window.open(
            domain+"/storage/สำหรับแพทย์, พยาบาล, นักศึกษา,สำหรับบริษัทยา/ตัวอย่างบันทึกข้อความ letter.doc"
          );
          break;
      }
    },
  },
};
</script>

<style scoped></style>
