<template>
  <v-content>
    <v-layout style="background-color: #FAF5F2;">
      <v-flex>
        <v-layout mb-3 mt-6>
          <v-flex class="text-center" style="color: #3E7662;font-weight: bold;font-size: 28px">
            <span>แบบฟอร์ม</span>
          </v-flex>
        </v-layout>

        <v-layout style="margin-left: 100px;margin-right: 100px;">
          <v-flex>
            <v-layout mt-3 style="background-color: #3E7662;">
              <v-flex my-8 ml-8 style="text-align: left;font-size: 28px;color: white">
                <div style="font-weight: bold">
                  แบบรายงานความก้าวหน้าของการวิจัย
                </div>
                <div style="font-size: 18px;">Progress Report Form</div>
              </v-flex>
              <v-flex ml-8 pt-8 pr-8 style="text-align: right;font-weight: bold;font-size: 13px;color: white">
                <span>RF 03_2563</span>
              </v-flex>
            </v-layout>

            <!-- blog 1 -->
            <v-card flat class="mb-12 px-12">
              <v-layout py-12 pr-12 mr-12>
                <v-flex>
                  <!-- row 1 -->
                  <v-layout pt-8 pb-6>
                    <v-flex xs2 mr-4 d-flex align-center justify-end style="text-align: right;font-weight: bold;">
                      <span>รหัสโครงร่างการวิจัย</span>
                    </v-flex>
                    <v-flex d-flex align-center xs4>
                      <textfield-form hide-detail :model="form.researchCode"
                        @update-data="updateData('researchCode', $event)"></textfield-form>
                    </v-flex>
                  </v-layout>
                  <!-- row 2 -->
                  <v-layout pb-6>
                    <v-flex xs2 mr-4 d-flex align-center justify-end style="text-align: right;font-weight: bold;">
                      <span>ชื่อผู้วิจัยหลัก</span>
                    </v-flex>
                    <v-flex d-flex align-center xs4>
                      <textfield-form hide-detail :model="form.firstName"
                        @update-data="updateData('firstName', $event)"></textfield-form>
                    </v-flex>
                    <v-flex xs2 pr-4 d-flex align-center justify-end style="text-align: right;font-weight: bold;">
                      <span>นามสกุล</span>
                    </v-flex>
                    <v-flex d-flex align-center xs4>
                      <textfield-form hide-detail :model="form.lastName"
                        @update-data="updateData('lastName', $event)"></textfield-form>
                    </v-flex>
                  </v-layout>
                  <!-- row 3 -->
                  <v-layout pb-6>
                    <v-flex d-flex align-center xs2 mr-4 style="text-align: right;font-weight: bold;">
                      <span>ชื่อโครงการวิจัย (ไทย)</span>
                    </v-flex>
                    <v-flex xs10>
                      <textfield-form hide-detail :model="form.researchName"
                        @update-data="updateData('researchName', $event)"></textfield-form>
                    </v-flex>
                  </v-layout>
                  <!-- row 4 -->
                  <v-layout pb-6>
                    <v-flex xs2 mr-4 d-flex align-center justify-end style="text-align: right;font-weight: bold;">
                      <span>วันที่รับรองครั้งสุดท้าย</span>
                    </v-flex>
                    <v-flex xs3>
                      <date-input-form hide-detail :model="dateChange1"
                        @update-data="updateDate('lastDay', $event)"></date-input-form>
                    </v-flex>
                  </v-layout>
                  <!-- row 5 -->
                  <v-layout pb-6>
                    <v-flex xs2 mr-4 d-flex align-center justify-end style="text-align: right;font-weight: bold;">
                      <span>วันสิ้นสุดอายุการรับรอง</span>
                    </v-flex>
                    <v-flex xs3>
                      <date-input-form hide-detail :model="dateChange3"
                        @update-data="updateDate('endDay', $event)"></date-input-form>
                    </v-flex>
                  </v-layout>
                  <!-- row 6 -->
                  <v-layout pb-6>
                    <v-flex xs2 mr-4 d-flex align-center justify-end style="text-align: right;font-weight: bold;">
                      <span>วันที่เริ่มเก็บข้อมูล</span>
                    </v-flex>
                    <v-flex xs3>
                      <date-input-form hide-detail :model="dateChange2"
                       @update-data="updateDate('startDay', $event)"></date-input-form>
                    </v-flex>
                      <v-checkbox color="#3E7662" v-model="form.hasStartDay" v-bind:true-value="true" v-bind:false-value="false" style="margin-left: 25px; margin-top: 2px;" label="ยังไม่ได้เริ่มเก็บข้อมูล"></v-checkbox>
                  </v-layout>
                  <!-- row 7 -->
                </v-flex>
              </v-layout>
            </v-card>

            <!-- blog 2 -->
            <v-layout pt-12 pb-4 mt-12 style="background-color: white;">
              <v-flex>
                <v-layout pb-6>
                  <v-flex xs2 mr-4 d-flex align-center justify-end style="text-align: right;font-weight: bold;">
                    <span>วันที่ส่งรายงานความก้าวหน้า</span>
                  </v-flex>
                  <v-flex d-flex align-center xs3>
                    <date-input-form hide-detail :model="dateChange4"
                      @update-data="updateDate('reportDate', $event)"></date-input-form>
                  </v-flex>
                </v-layout>
                <!-- row 1 -->
                <v-layout style="padding-left: 100px;">
                  <v-flex justify-start d-flex>
                    <span style="font-weight: bold;;font-size: 16px;">เวลาการส่งรายงาน</span>
                  </v-flex>
                </v-layout>
                <v-layout style="padding-left: 30vh;">
                  <v-flex justify-start d-flex>
                    <v-radio-group v-model="form.reportTime" hide-details>
                      <!--<v-radio color="#3E7662" style="padding-right: 100px" label="มากกว่า 30 วัน ก่อนหมดอายุการรับรอง" value="1"></v-radio>-->
                      <v-radio color="#3E7662" style="padding-right: 100px" label="ภายใน 30 วัน ก่อนหมดอายุการรับรอง"
                        value="1"></v-radio>
                      <v-radio color="#3E7662" style="padding-right: 100px" label="ภายหลังหมดอายุการรับรอง"
                        value="2"></v-radio>
                      <!--<v-radio color="#3E7662" style="padding-right: 100px" label="ภายหลังหมดอายุการรับรอง" value="3"></v-radio>-->
                    </v-radio-group>
                  </v-flex>
                </v-layout>
                <v-layout style="padding-left: 34vh;">
                  <v-flex justify-start d-flex>
                    <span style="color: #CC3D45;">
                      (วันที่ส่งรายงาน ต้องภายใน 30 วัน ก่อนวันหมดอายุการรับรอง)
                    </span>
                  </v-flex>
                </v-layout>

                <!-- row 2 -->
                <v-layout mt-12 style="padding-left: 100px;">
                  <v-flex justify-start d-flex>
                    <span style="font-weight: bold;;font-size: 16px;">การขอต่ออายุการรับรองโครงร่างการวิจัย</span>
                  </v-flex>
                </v-layout>
                <v-layout style="padding-left: 30vh;">
                  <v-flex justify-start d-flex>
                    <v-radio-group v-model="form.wantRenew" hide-details>
                      <v-radio color="#3E7662" style="padding-right: 100px" label="ขอต่ออายุการรับรอง"
                        value="1"></v-radio>
                      <v-radio color="#3E7662" style="padding-right: 100px" label="ไม่ขอ เนื่องจากการวิจัยสิ้นสุดแล้ว"
                        value="2"></v-radio>
                    </v-radio-group>
                  </v-flex>
                </v-layout>

                <!-- row 3 -->
                <!-- <v-layout mt-12 style="padding-left: 100px;">
                  <v-flex justify-start d-flex style="font-weight: bold">
                    <v-checkbox
                      color="#3E7662"
                      label="อื่นๆ ระบุ"
                      v-model="form.hasOther"
                    />
                  </v-flex>
                </v-layout> -->

                <!-- row 4 Blog Tatle -->
                <v-layout ma-12>
                  <v-flex>
                    <!-- Header -->
                    <v-layout py-4 style="background-color: #3E7662;color: white;">
                      <v-flex xs6 class="text-center">
                        <span>1. จำนวนอาสาสมัคร (ค + ง + จ + ฉ = ข)</span>
                      </v-flex>
                      <v-flex xs6 class="text-center">
                        <span>ข้อคิดเห็น</span>
                      </v-flex>
                    </v-layout>

                    <!-- Card 1 -->
                    <v-card outlined>
                      <v-layout>
                        <v-flex xs1 style="background-color: #EFEAE7;font-size: 24px;">
                          <span class="textAlignVer">Trial Progress</span>
                        </v-flex>
                        <v-flex style="flex: 1">
                          <!-- row 1 -->
                          <v-layout px-8 pt-6 pb-4>
                            <v-flex xs5>
                              <v-checkbox @change="clearCheckbox('hasPlan')" color="#3E7662" label="1. วางแผนไว้"
                                v-model="form.hasPlan" />
                            </v-flex>
                            <v-flex pl-6>
                              <textarea-form hide-detail :rows="2" :disabled="form.hasPlan === false" :background-color="form.hasPlan === false ? '#E4E4E4' : '#FBF6F3'
                                " :model="form.plan" @update-data="updateData('plan', $event)"></textarea-form>
                            </v-flex>
                          </v-layout>
                          <!-- row 2 -->
                          <v-layout px-8 pt-6 pb-4 style="background-color: #FBF6F3">
                            <v-flex xs5>
                              <v-checkbox @change="clearCheckbox('hasScreen')" color="#3E7662"
                                label="2. คัดเลือกเข้าในการวิจัย" v-model="form.hasScreen" />
                            </v-flex>
                            <v-flex pl-6>
                              <textarea-form hide-detail :rows="2" :disabled="form.hasScreen === false" :background-color="form.hasScreen === false
                                ? '#E4E4E4'
                                : '#FBF6F3'
                                " :model="form.screen" @update-data="updateData('screen', $event)"></textarea-form>
                            </v-flex>
                          </v-layout>
                          <!-- row 3 -->
                          <v-layout px-8 pt-6 pb-4>
                            <v-flex xs5>
                              <v-checkbox @change="clearCheckbox('hasResearching')" color="#3E7662"
                                label="3. อยู่ในระหว่างดำเนินการวิจัย (มีการทำหัตถการหรือการทดลอง)"
                                v-model="form.hasResearching" />
                            </v-flex>
                            <v-flex pl-6>
                              <textarea-form hide-detail :rows="2" :disabled="form.hasResearching === false"
                                :background-color="form.hasResearching === false
                                  ? '#E4E4E4'
                                  : '#FBF6F3'
                                  " :model="form.researching"
                                @update-data="updateData('researching', $event)"></textarea-form>
                            </v-flex>
                          </v-layout>
                          <!-- row 4 -->
                          <v-layout px-8 pt-6 pb-4 style="background-color: #FBF6F3">
                            <v-flex xs5>
                              <v-checkbox @change="clearCheckbox('hasFollowing')" color="#3E7662"
                                label="4. อยู่ในระหว่างการติดตามเท่านั้น" v-model="form.hasFollowing" />
                            </v-flex>
                            <v-flex pl-6>
                              <textarea-form hide-detail :rows="2" :disabled="form.hasFollowing === false"
                                :background-color="form.hasFollowing === false
                                  ? '#E4E4E4'
                                  : '#FBF6F3'
                                  " :model="form.following"
                                @update-data="updateData('following', $event)"></textarea-form>
                            </v-flex>
                          </v-layout>
                          <!-- row 5 -->
                          <v-layout px-8 pt-6 pb-4>
                            <v-flex xs5>
                              <v-checkbox @change="clearCheckbox('hasWithdraw')" color="#3E7662"
                                label="5. ถอนตัว หรือขาดการติดตาม (รวมที่เสียชีวิต)" v-model="form.hasWithdraw" />
                            </v-flex>
                            <v-flex pl-6>
                              <textarea-form hide-detail :rows="2" :disabled="form.hasWithdraw === false"
                                :background-color="form.hasWithdraw === false
                                  ? '#E4E4E4'
                                  : '#FBF6F3'
                                  " :model="form.withdraw"
                                @update-data="updateData('withdraw', $event)"></textarea-form>
                            </v-flex>
                          </v-layout>
                          <!-- row 6 -->
                          <v-layout px-8 pt-6 pb-4 style="background-color: #FBF6F3">
                            <v-flex xs5>
                              <v-checkbox @change="clearCheckbox('hasFinish')" color="#3E7662" label="6. สิ้นสุดการวิจัย"
                                v-model="form.hasFinish" />
                            </v-flex>
                            <v-flex pl-6>
                              <textarea-form hide-detail :rows="2" :disabled="form.hasFinish === false" :background-color="form.hasFinish === false
                                ? '#E4E4E4'
                                : '#FBF6F3'
                                " :model="form.finish" @update-data="updateData('finish', $event)"></textarea-form>
                            </v-flex>
                          </v-layout>
                          <!-- row 7 -->
                          <v-layout py-6 px-6 style="background-color: #39896B;">
                            <v-flex>
                              <v-layout>
                                <v-flex xs8 style="text-align: left;color: white;font-weight: bold">
                                  <span>2. มีอาสาสมัครถอนตัวจากการวิจัย
                                    ในช่วงการรับรองของคณะอนุกรรมการฯ ไม่?</span>
                                </v-flex>
                                <v-flex xs4 mt-n5 style="text-align: right;">
                                  <v-radio-group v-model="form.hasWithdrawVolunteer" hide-details row dark>
                                    <v-radio color="#fff" label="มี" value="1"></v-radio>
                                    <v-radio @click="
                                      clearRedio('hasWithdrawVolunteer', '2')
                                      " color="#fff" label="ไม่มี" value="2"></v-radio>
                                    <v-radio @click="
                                      clearRedio('hasWithdrawVolunteer', '3')
                                      " color="#fff" label="ไม่เกี่ยวข้อง" value="3"></v-radio>
                                  </v-radio-group>
                                </v-flex>
                              </v-layout>
                              <v-layout pl-4>
                                <v-flex xs8 style="text-align: left;color: white;">
                                  <span>(ถ้ามี สรุปจำนวนและระบุเหตุผล)</span>
                                </v-flex>
                              </v-layout>
                              <v-layout px-4 mt-4>
                                <v-flex>
                                  <TextareaForm hide-detail :rows="4" :disabled="form.hasWithdrawVolunteer !== '1'
                                    " :background-color="form.hasWithdrawVolunteer !== '1'
    ? '#E4E4E4'
    : '#FBF6F3'
    " :model="form.withdrawVolunteer" @update-data="
    updateData('withdrawVolunteer', $event)
    " />
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>

                    <!-- Card 2 -->
                    <v-card outlined class="mt-8">
                      <v-layout>
                        <v-flex xs1 style="background-color: #EFEAE7;font-size: 24px;">
                          <span class="textAlignVer">Protocol Related Documents</span>
                        </v-flex>
                        <v-flex style="flex: 1">
                          <!-- row 1 -->
                          <v-layout py-6 px-6 style="background-color: #39896B;">
                            <v-flex>
                              <v-layout>
                                <v-flex xs8 style="text-align: left;color: white;font-weight: bold;">
                                  <span>3. มีส่วนแก้ไขเพิ่มเติมโครงร่างการวิจัย
                                    ในช่วงการรับรองของคณะอนุกรรมการฯหรือไม่</span>
                                </v-flex>
                                <v-flex xs4 mt-n5 style="text-align: right;">
                                  <v-radio-group v-model="form.hasEdit" hide-details row dark>
                                    <v-radio color="#fff" label="มี" value="1"></v-radio>
                                    <v-radio @click="clearRedio('hasEdit', '2')" color="#fff" label="ไม่มี"
                                      value="2"></v-radio>
                                    <v-radio @click="clearRedio('hasEdit', '3')" color="#fff" label="ไม่เกี่ยวข้อง"
                                      value="3"></v-radio>
                                  </v-radio-group>
                                </v-flex>
                              </v-layout>
                              <v-layout pl-4>
                                <v-flex xs8 style="text-align: left;color: white;">
                                  <span>(ถ้ามี สรุปสั้นๆ)</span>
                                </v-flex>
                              </v-layout>
                              <v-layout px-4 mt-4>
                                <v-flex>
                                  <TextareaForm hide-detail :rows="4" :disabled="form.hasEdit !== '1'" :background-color="form.hasEdit !== '1'
                                    ? '#E4E4E4'
                                    : '#FBF6F3'
                                    " :model="form.edit" @update-data="updateData('edit', $event)" />
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                          <!-- row 2 -->
                          <v-layout py-6 px-6 style="background-color: #fff;">
                            <v-flex>
                              <v-layout>
                                <v-flex xs8 style="text-align: left;font-weight: bold;">
                                  <span>4. มีการเปลี่ยนแปลงคู่มือผู้วิจัย
                                    (investigator’s
                                    brochure)ในช่วงการรับรอง</span>
                                </v-flex>
                                <v-flex xs4 mt-n5 style="text-align: right;">
                                  <v-radio-group v-model="form.hasEditBrochure" hide-details row>
                                    <v-radio color="#39896B" label="มี" value="1"></v-radio>
                                    <v-radio color="#39896B" label="ไม่มี" value="2"></v-radio>
                                    <v-radio color="#39896B" label="ไม่เกี่ยวข้อง" value="3"></v-radio>
                                  </v-radio-group>
                                </v-flex>
                              </v-layout>
                              <v-layout pl-4>
                                <v-flex xs8 style="text-align: left;">
                                  <span>ของคณะอนุกรรมการฯหรือไม่?</span>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                          <!-- row 3 -->
                          <v-layout py-6 px-6 style="background-color: #39896B;">
                            <v-flex>
                              <v-layout>
                                <v-flex xs8 style="text-align: left;color: white;font-weight: bold;">
                                  <span>5. มีข้อมูลใหม่ที่เกี่ยวข้องกับการวิจัย
                                    ในช่วงการรับรองของคณะอนุกรรมการฯหรือไม่?</span>
                                </v-flex>
                                <v-flex xs4 mt-n5 style="text-align: right;">
                                  <v-radio-group v-model="form.hasNewInformation" hide-details row dark>
                                    <v-radio color="#fff" label="มี" value="1"></v-radio>
                                    <v-radio @click="
                                      clearRedio('hasNewInformation', '2')
                                      " color="#fff" label="ไม่มี" value="2"></v-radio>
                                    <v-radio @click="
                                      clearRedio('hasNewInformation', '3')
                                      " color="#fff" label="ไม่เกี่ยวข้อง" value="3"></v-radio>
                                  </v-radio-group>
                                </v-flex>
                              </v-layout>
                              <v-layout pl-4>
                                <v-flex xs8 style="text-align: left;color: white;">
                                  <span>(ถ้ามี สรุปสั้นๆ)</span>
                                </v-flex>
                              </v-layout>
                              <v-layout px-4 mt-4>
                                <v-flex>
                                  <TextareaForm hide-detail :rows="4" :disabled="form.hasNewInformation !== '1'"
                                    :background-color="form.hasNewInformation !== '1'
                                      ? '#E4E4E4'
                                      : '#FBF6F3'
                                      " :model="form.newInformation" @update-data="
    updateData('newInformation', $event)
    " />
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>

                    <!-- Card 3 -->
                    <v-card outlined class="mt-8">
                      <v-layout>
                        <v-flex xs1 style="background-color: #EFEAE7;font-size: 24px;">
                          <span class="textAlignVer">Risk&Benefit</span>
                        </v-flex>
                        <v-flex style="flex: 1">
                          <!-- row 1 -->
                          <v-layout py-6 px-6 style="background-color: #39896B;">
                            <v-flex>
                              <v-layout>
                                <v-flex xs8 style="text-align: left;color: white;">
                                  <span><b>6.
                                      มีปัญหาเกี่ยวกับงานวิจัยที่เพิ่มความเสี่ยงต่ออาสาสมัครในช่วงการรับรองของ
                                      คณะอนุกรรมการฯหรือไม่? </b>(ถ้ามี สรุปสั้นๆ)</span>
                                </v-flex>
                                <v-flex xs4 mt-n5 style="text-align: right;">
                                  <v-radio-group v-model="form.hasIssue" hide-details row dark>
                                    <v-radio color="#fff" label="มี" value="1"></v-radio>
                                    <v-radio @click="clearRedio('hasIssue', '2')" color="#fff" label="ไม่มี"
                                      value="2"></v-radio>
                                    <v-radio @click="clearRedio('hasIssue', '3')" color="#fff" label="ไม่เกี่ยวข้อง"
                                      value="3"></v-radio>
                                  </v-radio-group>
                                </v-flex>
                              </v-layout>
                              <v-layout px-4 mt-4>
                                <v-flex>
                                  <TextareaForm hide-detail :rows="4" :disabled="form.hasIssue !== '1'" :background-color="form.hasIssue !== '1'
                                    ? '#E4E4E4'
                                    : '#FBF6F3'
                                    " :model="form.issue" @update-data="updateData('issue', $event)" />
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                          <!-- row 2 -->
                          <v-layout py-6 px-6 style="background-color: #fff;">
                            <v-flex>
                              <v-layout>
                                <v-flex xs8 style="text-align: left;">
                                  <span><b>7.
                                      มีการเปลี่ยนแปลงอัตราส่วนของความเสี่ยงต่อประโยชน์ในช่วงการรับรองของ
                                      คณะอนุกรรมการฯหรือไม่
                                    </b>
                                    (ถ้ามี สรุปสั้นๆ)</span>
                                </v-flex>
                                <v-flex xs4 mt-n5 style="text-align: right;">
                                  <v-radio-group v-model="form.hasChangeRatio" hide-details row>
                                    <v-radio color="#39896B" label="มี" value="1"></v-radio>
                                    <v-radio @click="clearRedio('hasChangeRatio', '2')" color="#39896B" label="ไม่มี"
                                      value="2"></v-radio>
                                    <v-radio @click="clearRedio('hasChangeRatio', '3')" color="#39896B"
                                      label="ไม่เกี่ยวข้อง" value="3"></v-radio>
                                  </v-radio-group>
                                </v-flex>
                              </v-layout>
                              <v-layout px-4 mt-4>
                                <v-flex>
                                  <TextareaForm hide-detail :rows="4" :disabled="form.hasChangeRatio !== '1'"
                                    :background-color="form.hasChangeRatio !== '1'
                                      ? '#E4E4E4'
                                      : '#FBF6F3'
                                      " :model="form.changeRatio" @update-data="
    updateData('changeRatio', $event)
    " />
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>

                    <!-- Card 4 -->
                    <v-card outlined class="mt-8">
                      <v-layout>
                        <v-flex xs1 style="background-color: #EFEAE7;font-size: 24px;">
                          <span class="textAlignVer">Informed consent</span>
                        </v-flex>
                        <v-flex style="flex: 1">
                          <!-- row 1 -->
                          <v-layout py-6 px-6 style="background-color: #39896B;">
                            <v-flex>
                              <v-layout>
                                <v-flex xs8 style="text-align: left;color: white;">
                                  <span><b>8.
                                      มีการเปลี่ยนแปลงเอกสารชี้แจงข้อมูลแก่อาสาสมัคร
                                      ในช่วงการรับรองของ คณะอนุกรรมการฯหรือไม่? </b>(ถ้ามี สรุปสั้นๆ)</span>
                                </v-flex>
                                <v-flex xs4 mt-n5 style="text-align: right;">
                                  <v-radio-group v-model="form.hasChangeDocument" hide-details row dark>
                                    <v-radio color="#fff" label="มี" value="1"></v-radio>
                                    <v-radio @click="
                                      clearRedio('hasChangeDocument', '2')
                                      " color="#fff" label="ไม่มี" value="2"></v-radio>
                                    <v-radio @click="
                                      clearRedio('hasChangeDocument', '3')
                                      " color="#fff" label="ไม่เกี่ยวข้อง" value="3"></v-radio>
                                  </v-radio-group>
                                </v-flex>
                              </v-layout>
                              <v-layout px-4 mt-4>
                                <v-flex>
                                  <TextareaForm hide-detail :rows="4" :disabled="form.hasChangeDocument !== '1'"
                                    :background-color="form.hasChangeDocument !== '1'
                                      ? '#E4E4E4'
                                      : '#FBF6F3'
                                      " :model="form.changeDocument" @update-data="
    updateData('changeDocument', $event)
    " />
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                          <!-- row 2 -->
                          <v-layout py-6 px-6 style="background-color: #fff;">
                            <v-flex>
                              <v-layout>
                                <v-flex xs8 style="text-align: left;">
                                  <span><b>9.
                                      มีปัญหาในกระบวนการขอความยินยอมจากอาสาสมัครในช่วงการรับรองของ
                                      คณะอนุกรรมการฯหรือไม่?
                                    </b>
                                    (ถ้ามี สรุปสั้นๆ)</span>
                                </v-flex>
                                <v-flex xs4 mt-n5 style="text-align: right;">
                                  <v-radio-group v-model="form.hasConsentIssue" hide-details row>
                                    <v-radio color="#39896B" label="มี" value="1"></v-radio>
                                    <v-radio @click="
                                      clearRedio('hasConsentIssue', '2')
                                      " color="#39896B" label="ไม่มี" value="2"></v-radio>
                                    <v-radio @click="
                                      clearRedio('hasConsentIssue', '3')
                                      " color="#39896B" label="ไม่เกี่ยวข้อง" value="3"></v-radio>
                                  </v-radio-group>
                                </v-flex>
                              </v-layout>
                              <v-layout px-4 mt-4>
                                <v-flex>
                                  <TextareaForm hide-detail :rows="4" :disabled="form.hasConsentIssue !== '1'"
                                    :background-color="form.hasConsentIssue !== '1'
                                      ? '#E4E4E4'
                                      : '#FBF6F3'
                                      " :model="form.consentIssue" @update-data="
    updateData('consentIssue', $event)
    " />
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>

                    <!-- Card 5 -->
                    <v-card outlined class="mt-8">
                      <v-layout>
                        <v-flex xs1 style="background-color: #EFEAE7;font-size: 24px;">
                          <span class="textAlignVer">local issues</span>
                        </v-flex>
                        <v-flex style="flex: 1">
                          <!-- row 1 -->
                          <v-layout py-6 px-6 style="background-color: #39896B;">
                            <v-flex>
                              <v-layout>
                                <v-flex xs8 style="text-align: left;color: white;">
                                  <span><b>10.
                                      มีเรื่องร้องเรียนของอาสาสมัครเกี่ยวกับโครงการวิจัยจากอาสาในช่วงการรับรองของ
                                      คณะอนุกรรมการฯหรือไม่? </b>(ถ้ามี สรุปสั้นๆ)</span>
                                </v-flex>
                                <v-flex xs4 mt-n5 style="text-align: right;">
                                  <v-radio-group v-model="form.hasComplaint" hide-details row dark>
                                    <v-radio color="#fff" label="มี" value="1"></v-radio>
                                    <v-radio @click="clearRedio('hasComplaint', '2')" color="#fff" label="ไม่มี"
                                      value="2"></v-radio>
                                    <v-radio @click="clearRedio('hasComplaint', '3')" color="#fff" label="ไม่เกี่ยวข้อง"
                                      value="3"></v-radio>
                                  </v-radio-group>
                                </v-flex>
                              </v-layout>
                              <v-layout px-4 mt-4>
                                <v-flex>
                                  <TextareaForm hide-detail :rows="4" :disabled="form.hasComplaint !== '1'"
                                    :background-color="form.hasComplaint !== '1'
                                      ? '#E4E4E4'
                                      : '#FBF6F3'
                                      " :model="form.complaint" @update-data="
    updateData('complaint', $event)
    " />
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                          <!-- row 2 -->
                          <v-layout py-6 px-6 style="background-color: #fff;">
                            <v-flex>
                              <v-layout>
                                <v-flex xs8 pr-4 style="text-align: left;">
                                  <span><b>11. มีผลกระทบในด้านทัศนคติเชิงลบ
                                      ของชุมชนต่อโครงการวิจัย ในช่วงการรับรองของ
                                      คณะอนุกรรมการฯหรือไม่ ?
                                    </b>
                                    (ถ้ามี สรุปสั้นๆ)</span>
                                </v-flex>
                                <v-flex xs4 mt-n5 style="text-align: right;">
                                  <v-radio-group v-model="form.hasBadEffect" hide-details row>
                                    <v-radio color="#39896B" label="มี" value="1"></v-radio>
                                    <v-radio @click="clearRedio('hasBadEffect', '2')" color="#39896B" label="ไม่มี"
                                      value="2"></v-radio>
                                    <v-radio @click="clearRedio('hasBadEffect', '3')" color="#39896B" label="ไม่เกี่ยวข้อง"
                                      value="3"></v-radio>
                                  </v-radio-group>
                                </v-flex>
                              </v-layout>
                              <v-layout px-4 mt-4>
                                <v-flex>
                                  <TextareaForm hide-detail :rows="4" :disabled="form.hasBadEffect !== '1'"
                                    :background-color="form.hasBadEffect !== '1'
                                      ? '#E4E4E4'
                                      : '#FBF6F3'
                                      " :model="form.badEffect" @update-data="
    updateData('badEffect', $event)
    " />
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>

                    <!-- Card 6 -->
                    <v-card outlined class="mt-8">
                      <v-layout>
                        <v-flex xs1 style="background-color: #EFEAE7;font-size: 24px;">
                          <span class="textAlignVer">Investigators</span>
                        </v-flex>
                        <v-flex style="flex: 1">
                          <!-- row 1 -->
                          <v-layout py-6 px-6 style="background-color: #39896B;">
                            <v-flex>
                              <v-layout>
                                <v-flex xs8 style="text-align: left;color: white;">
                                  <span><b>12. มีการเปลี่ยนผู้วิจัยหลัก
                                      หรือผู้วิจัยร่วมในช่วงการรับรองของ
                                      คณะอนุกรรมการฯหรือไม่ ?
                                    </b></span>
                                </v-flex>
                                <v-flex xs4 mt-n5 style="text-align: right;">
                                  <v-radio-group v-model="form.hasChangeMainResearcher" hide-details row dark>
                                    <v-radio color="#fff" label="มี" value="1"></v-radio>
                                    <v-radio @click="
                                      clearRedio(
                                        'hasChangeMainResearcher',
                                        '2'
                                      )
                                      " color="#fff" label="ไม่มี" value="2"></v-radio>
                                    <v-radio @click="
                                      clearRedio(
                                        'hasChangeMainResearcher',
                                        '3'
                                      )
                                      " color="#fff" label="ไม่เกี่ยวข้อง" value="3"></v-radio>
                                  </v-radio-group>
                                </v-flex>
                              </v-layout>
                              <v-layout pl-4>
                                <v-flex xs8 style="text-align: left;color: white;">
                                  <span>(ถ้ามี สรุปสั้นๆ)</span>
                                </v-flex>
                              </v-layout>
                              <v-layout px-4 mt-4>
                                <v-flex>
                                  <TextareaForm hide-detail :rows="4" :disabled="form.hasChangeMainResearcher !== '1'
                                    " :background-color="form.hasChangeMainResearcher !== '1'
    ? '#E4E4E4'
    : '#FBF6F3'
    " :model="form.changeMainResearcher" @update-data="
    updateData('changeMainResearcher', $event)
    " />
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                          <!-- row 2 -->
                          <v-layout py-6 px-6 style="background-color: #fff;">
                            <v-flex>
                              <v-layout>
                                <v-flex xs8 pr-4 style="text-align: left;">
                                  <span><b>13.
                                      มีการเปลี่ยนแปลงฐานะหรือคุณสมบัติของผู้วิจัยในช่วงการรับรองของ
                                      คณะอนุกรรมการฯหรือไม่ ? </b>(ถ้ามี สรุปสั้นๆ)</span>
                                </v-flex>
                                <v-flex xs4 mt-n5 style="text-align: right;">
                                  <v-radio-group v-model="form.hasChangeResearcherProperty" hide-details row>
                                    <v-radio color="#39896B" label="มี" value="1"></v-radio>
                                    <v-radio @click="
                                      clearRedio(
                                        'hasChangeResearcherProperty',
                                        '2'
                                      )
                                      " color="#39896B" label="ไม่มี" value="2"></v-radio>
                                    <v-radio @click="
                                      clearRedio(
                                        'hasChangeResearcherProperty',
                                        '3'
                                      )
                                      " color="#39896B" label="ไม่เกี่ยวข้อง" value="3"></v-radio>
                                  </v-radio-group>
                                </v-flex>
                              </v-layout>
                              <v-layout px-4 mt-4>
                                <v-flex>
                                  <TextareaForm hide-detail :rows="4" :disabled="form.hasChangeResearcherProperty !== '1'
                                    " :background-color="form.hasChangeResearcherProperty !== '1'
    ? '#E4E4E4'
    : '#FBF6F3'
    " :model="form.changeResearcherProperty" @update-data="
    updateData(
      'changeResearcherProperty',
      $event
    )
    " />
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>

                    <!-- Card 7 -->
                    <v-card outlined class="mt-8">
                      <v-layout>
                        <v-flex xs1 style="background-color: #EFEAE7;font-size: 24px;">
                          <span class="textAlignVer">Other issues</span>
                        </v-flex>
                        <v-flex style="flex: 1">
                          <!-- row 1 -->
                          <v-layout py-6 px-6 style="background-color: #39896B;">
                            <v-flex>
                              <v-layout>
                                <v-flex xs8 style="text-align: left;color: white;">
                                  <span><b>14. มีข้อกังวลของผู้วิจัย
                                      เกี่ยวกับโครงการวิจัยในช่วงการรับรองของ
                                      คณะอนุกรรมการฯหรือไม่? </b>(ถ้ามี สรุปสั้นๆ)</span>
                                </v-flex>
                                <v-flex xs4 mt-n5 style="text-align: right;">
                                  <v-radio-group v-model="form.hasConcern" hide-details row dark>
                                    <v-radio color="#fff" label="มี" value="1"></v-radio>
                                    <v-radio @click="clearRedio('hasConcern', '2')" color="#fff" label="ไม่มี"
                                      value="2"></v-radio>
                                    <v-radio @click="clearRedio('hasConcern', '3')" color="#fff" label="ไม่เกี่ยวข้อง"
                                      value="3"></v-radio>
                                  </v-radio-group>
                                </v-flex>
                              </v-layout>
                              <v-layout px-4 mt-4>
                                <v-flex>
                                  <TextareaForm hide-detail :rows="4" :disabled="form.hasConcern !== '1'"
                                    :background-color="form.hasConcern !== '1'
                                      ? '#E4E4E4'
                                      : '#FBF6F3'
                                      " :model="form.concern" @update-data="updateData('concern', $event)" />
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                          <!-- row 2 -->
                          <v-layout py-6 px-6 style="background-color: #fff;">
                            <v-flex>
                              <v-layout>
                                <v-flex xs8 pr-4 style="text-align: left;">
                                  <span><b>15.
                                      มีข้อมูลจากคณะกรรมการกำกับดูแลโครงการวิจัย
                                      (data monitoring- committees, DMCs)
                                      ในช่วงการรับรองของคณะอนุกรรมการฯหรือไม่? </b>(ถ้ามี สรุปสั้นๆ)</span>
                                </v-flex>
                                <v-flex xs4 mt-n5 style="text-align: right;">
                                  <v-radio-group v-model="form.hasInformationFromCommittee" hide-details row>
                                    <v-radio color="#39896B" label="มี" value="1"></v-radio>
                                    <v-radio @click="
                                      clearRedio(
                                        'hasInformationFromCommittee',
                                        '2'
                                      )
                                      " color="#39896B" label="ไม่มี" value="2"></v-radio>
                                    <v-radio @click="
                                      clearRedio(
                                        'hasInformationFromCommittee',
                                        '3'
                                      )
                                      " color="#39896B" label="ไม่เกี่ยวข้อง" value="3"></v-radio>
                                  </v-radio-group>
                                </v-flex>
                              </v-layout>
                              <v-layout px-4 mt-4>
                                <v-flex>
                                  <TextareaForm hide-detail :rows="4" :disabled="form.hasInformationFromCommittee !== '1'
                                    " :background-color="form.hasInformationFromCommittee !== '1'
    ? '#E4E4E4'
    : '#FBF6F3'
    " :model="form.informationFromCommittee" @update-data="
    updateData(
      'informationFromCommittee',
      $event
    )
    " />
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>

                <!-- row 5 -->
                <v-layout my-6 px-12>
                  <v-flex style="font-weight: bold; text-align: left;">
                    <span>สรุปข้อมูลเพิ่มเติม</span>
                  </v-flex>
                </v-layout>
                <v-layout px-12>
                  <v-flex>
                    <TextareaForm :rows="10" :model="form.summarize" @update-data="updateData('summarize', $event)" />
                  </v-flex>
                </v-layout>

                <v-layout class="mb-12 px-12">
                  <v-flex>
                    <v-layout class="mb-8">
                      <v-flex>
                        <v-layout>
                          <v-flex shrink pt-2 pr-4 style="text-align: left;">
                            <span style="font-weight: bold;font-size: 16px;">หนังสือของหน่วยงาน
                            </span>
                          </v-flex>
                          <v-flex>
                            <v-file-input accept=".pdf" prepend-icon="" ref="agencyBooks" id="agencyBook"
                              :model="form.agencyBook" outlined dense clearable background-color="#FBF6F3" color="#3E7662"
                              item-color="#3E7662" @change="onUpload" hide-details></v-file-input>
                            <div v-if="agencyBook" class="mt-1 text-start" style="font-size: 12px;color: #CC3D45;">
                              <span>ไฟล์ที่อัพโหลด: {{ agencyBook }}</span>
                            </div>
                          </v-flex>
                          <v-flex shrink pl-4 style="text-align: left">
                            <v-btn color="#3E7662" dark @click="onClickBtn">
                              <v-icon class="pr-3">cloud_download</v-icon>
                              <span>Upload</span>
                            </v-btn>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs3 ml-4><span style="color: red">*ชื่อไฟล์ต้องเป็นภาษาอังกฤษเท่านั้น (a-z,0-9)
                          ห้ามมีตัวอักษรอื่น </span></v-flex>
                    </v-layout>
                    <v-layout>
                      <v-flex xs6>
                        <v-layout>
                          <v-flex class="d-flex align-center mr-4 font-weight-bold" shrink>หน่วยงานที่ออกหนังสือ</v-flex>
                          <v-flex>
                            <textfield-form hide-detail :model="form.instituteBook"
                              @update-data="updateData('instituteBook', $event)"></textfield-form>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                    <v-layout mt-6>
                      <v-flex xs6>
                        <v-layout>
                          <v-flex class="d-flex align-center mr-4 font-weight-bold" shrink>เลขหนังสือ</v-flex>
                          <v-flex>
                            <textfield-form hide-detail :model="form.bookNumber"
                              @update-data="updateData('bookNumber', $event)"></textfield-form>
                          </v-flex>
                        </v-layout>
                      </v-flex>

                      <v-flex xs6>
                        <v-layout>
                          <v-flex class="d-flex align-center mx-4 font-weight-bold" shrink>วันที่หนังสือ</v-flex>
                          <v-flex>
                            <DateInputForm id="orderDate" hide-detail :model="bookDate"
                              @update-data="updateDate('bookDate', $event)"></DateInputForm>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v-layout style="margin-top: 30px">
                  <v-flex ml-8 my-5 style="text-align: left;">
                    <span style="font-weight: bold">Note</span>
                    <span style="color: red">  *รายการที่ต้องแก้ไข</span>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex style="padding-left: 32px; padding-right: 32px">
                    <textarea-form readonly :model="form.noteDetail" :rows="5"></textarea-form>
                  </v-flex>
                </v-layout>
                <v-layout px-12 mt-8>
                      <!-- row 1-->
                      <v-flex
                        xs6
                        style="font-weight: bold;font-size: 18px"
                        d-flex
                        justify-start
                      >
                        <span>เอกสารแนบเพิ่มเติม</span>
                      </v-flex>
                    </v-layout>
                    <v-layout px-12 my-4>
                      <v-flex shrink mr-4>
                        <v-btn
                          dark
                          style="width: 80px;background-color: #3E7662"
                          @click="addDynamicFile('fileOther')"
                        >
                          <v-icon>add</v-icon>
                          <span class="font-weight-bold">เพิ่ม</span>
                        </v-btn>
                      </v-flex>
                      <v-flex>
                        <span style="color: #CC3D45;"
                          >*ให้ผู้วิจัยระบุชื่่อ file / เวอร์ชั่น / วันที่
                          ให้ตรงกับ บันทึกข้อความ และห้ามมีอักขระพิเศษ
                          (!@#$%^&*)</span
                        >
                      </v-flex>
                    </v-layout>
                    <div v-for="(input, k) in form.fileOther" :key="'d' + k">
                      <v-layout>
                        <v-flex>
                          <v-layout pl-12>
                            <v-flex
                              shrink
                              mr-4
                              pt-2
                              style="text-align: right;font-weight: bold;"
                            >
                              <span>ชื่อไฟล์</span>
                            </v-flex>
                            <v-flex xs5>
                              <textfield-form
                                hide-detail
                                :model="input.title"
                                @update-data="
                                  updateDynamicFileData('fileOther','title',k,$event)"
                              ></textfield-form>
                            </v-flex>
                            <v-flex shrink pt-2 px-4 style="text-align: left;">
                              <span style="font-weight: bold;font-size: 16px;"
                                >ไฟล์</span
                              >
                            </v-flex>
                            <v-flex>
                              <v-file-input
                                accept=".pdf"
                                prepend-icon=""
                                :id="'fileProtocolModify' + k"
                                :model="input.file"
                                outlined
                                dense
                                background-color="#FBF6F3"
                                color="#3E7662"
                                item-color="#3E7662"
                                @change="
                                  updateDynamicFileData('fileOther','file',k,$event)"
                              ></v-file-input>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex ml-4>
                          <v-btn
                            dark
                            style="background-color: #CC3D45; width: 80px"
                            @click="removeDynamicFile('fileOther', k)"
                            :disabled="form.fileOther.length < 1"
                          >
                            <v-icon>remove</v-icon>
                            <span class="font-weight-bold">ลบ</span>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                      <v-flex v-if="input.filePath"><a :href="input.filePath" target="_blank" style="color: #3e7662;text-align: center" class="link-hover">
                              <p>ไฟล์ที่อัพโหลด: {{ input.file }}</p>
                              </a>
                      </v-flex>
                    </div>

                <!-- row 6 -->
                <v-layout my-6 px-12>
                  <v-flex mr-12 xs5 style="text-align: left;" class="text-field-center">
                    <textfield-form-append css="font-weight: bold;" label="ผู้วิจัย" icon="edit" :model="form.signature"
                      @update-data="updateData('signature', $event)" hide-detail />
                  </v-flex>
                  <v-flex shrink pt-2 mr-4 style="font-weight: bold;">
                    <span>วันที่</span>
                  </v-flex>
                  <v-flex xs3>
                    <date-input-form hide-detail :model="dateChange5" @update-data="updateDate('date', $event)" />
                  </v-flex>
                </v-layout>
                <v-layout mt-6 px-12>
                  <v-flex xs5>
                    <v-layout justify-center>
                      <v-flex shrink style="font-weight: bold">
                        <span>(</span>
                      </v-flex>
                      <v-flex xs8 mx-2 mt-n3 class="text-field-center">
                        <textfield-form-append :model="form.signature" @update-data="updateData('signature', $event)" />
                      </v-flex>
                      <v-flex shrink style="font-weight: bold">
                        <span>)</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>

            <!-- Button Save -->
            <v-layout mt-8>
              <v-flex mb-12 style="text-align: right;">
                <v-layout>
                  <v-flex>
                    <v-btn style="color: #3E7662" class="mx-4" depressed @click="saveDraft" color="#ffffff">บันทึกร่าง</v-btn>
                    <!--<v-btn style="color: #3E7662" class="mx-4" depressed  color="#ffffff" @click="$router.go(-1)">ย้อนกลับ</v-btn>-->
                    <v-btn depressed dark color="#3E7662" @click="save">
                      <v-icon class="mr-3">add</v-icon>
                      <span>บันทึกข้อมูล</span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <loading color="#527281" :active.sync="isLoading" :can-cancel="false" :is-full-pageAndLine="fullpageAndLine">
        </loading>
      </v-flex>
    </v-layout>
  </v-content>
</template>

<script>
import TextfieldForm from "../../components/input/TextfieldForm";
import DateInputForm from "../../components/input/DateInputForm";
import TextareaForm from "../../components/input/TextareaForm";
import Swal from "sweetalert2";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import TextfieldFormAppend from "../../components/input/TextfieldFormAppend";
import { mapState } from "vuex";

export default {
  name: "FormRF06",
  components: {
    TextfieldForm,
    DateInputForm,
    TextareaForm,
    Loading,
    TextfieldFormAppend,
  },
  computed: {
    ...mapState({
      researchID: (state) => state.formRF.researchID,
    }),
  },
  data: () => ({
    isLoading: false,
    fullpageAndLine: true,
    selected: [],
    dialog: false,
    form: {
      noteDetail:"",
      fileOther:[],
      researchCode: "",
      firstName: "",
      lastName: "",
      researchName: "",
      lastDay: "",
      startDay: "",
      endDay: "",
      reportDate: "",
      reportTime: "",
      wantRenew: "",
      hasOther: false,
      hasPlan: false,
      plan: "",
      hasScreen: false,
      screen: "",
      hasStartDay: false,
      hasResearching: false,
      researching: "",
      hasFollowing: false,
      following: "",
      hasWithdraw: false,
      withdraw: "",
      hasFinish: false,
      finish: "",
      hasWithdrawVolunteer: "2",
      withdrawVolunteer: "",
      hasEdit: "2",
      edit: "",
      hasEditBrochure: "2",
      hasNewInformation: "2",
      newInformation: "",
      hasIssue: "2",
      issue: "",
      hasChangeRatio: "2",
      changeRatio: "",
      hasChangeDocument: "2",
      changeDocument: "",
      hasConsentIssue: "2",
      consentIssue: "",
      hasComplaint: "2",
      complaint: "",
      hasBadEffect: "2",
      badEffect: "",
      hasChangeMainResearcher: "2",
      changeMainResearcher: "",
      hasChangeResearcherProperty: "2",
      changeResearcherProperty: "",
      hasConcern: "2",
      concern: "",
      hasInformationFromCommittee: "2",
      informationFromCommittee: "",
      summarize: "",
      agencyBook: "",
      bookNumber: "",
      bookDate: "",
      signature: "",
      date: "",
      instituteBook: ""
    },
     dynamicDateChange: {
            fileOther: []
        },
    dateChange1: "",
    dateChange2: "",
    dateChange3: "",
    dateChange4: "",
    dateChange5: "",
    agencyBook: "",
    bookDate: "",
  }),
  created() {
    // Check Permission
    // if (!this.$route.query.researchID && this.$route.query.status !== 'อนุมัติ') {
    //     this.$router.go(-1);
    // } else {
    // Set data User
    // if (!this.$route.query.remainDate || !this.$route.query.remainDate <= 20) {
    //   this.$router.go(-1);
    // }
    this.form.researchCode = this.$route.query.researchCode;
    this.form.researchName = this.$route.query.nameResearch;
    this.form.signature = this.$route.query.thName;
    this.updateDate("date", new Date().toISOString().slice(0, 10));
    this.updateDate("lastDay", this.$route.query.approvalDate);
    this.updateDate("endDay", this.$route.query.approvalExpireDate);
    if (this.$route.query.thName) {
      let text = this.$route.query.thName.split(" ");
      this.form.firstName = text[0];
      this.form.lastName = text[1];
    }
     if (this.$route.query.rfID) {
      this.getRF03(this.$route.query.rfID);
      this.isOnCreated = false;
    }
    // }
  },
  methods: {
    addDynamicFile(name) {
      this.form[name].push({ title: "", file: null });
    },
    updateDynamicFileData(name, type, index, value) {
      if (type === "file") {
        this.isLoading = true;
        this.$store
          .dispatch("Common/uploadPdf", { form: "rf-03other-file", file: value })
          .then((res) => {
            this.form[name][index].file = res.fileName;
            this.isLoading = false;
          })
          .catch((error) => {});
      } else {
        this.form[name][index][type] = value;
        this.isLoading = false;
      }
    },
    removeDynamicFile(name, index) {
      this.form[name].splice(index, 1);
      this.$forceUpdate();
    },
    updateData(name, val) {
      this.form[name] = val;
    },
    updateDate(name, val) {
      if (name === "lastDay") {
        this.form.lastDay = val;
        this.dateChange1 = this.changeDate(val);
      }
      if (name === "startDay") {
        this.form.startDay = val;
        this.dateChange2 = this.changeDate(val);
      }
      if (name === "endDay") {
        this.form.endDay = val;
        this.dateChange3 = this.changeDate(val);
      }
      if (name === "reportDate") {
        this.form.reportDate = val;
        this.dateChange4 = this.changeDate(val);
      }
      if (name === "date") {
        this.form.date = val;
        this.dateChange5 = this.changeDate(val);
      }
      if (name === "bookDate") {
        this.form.bookDate = val;
        this.bookDate = this.changeDate(val);
      }
    },
    changeDate(val) {
      if(val != null){
        //change date start
      // console.log("test", val);
      let sub_year = val.substring(0, 4);
      let sub_month = val.substring(5, 7);
      let sub_day = val.substring(8, 10);
      sub_year = parseInt(sub_year) + 543;
      let changeDate = sub_day + "/" + sub_month + "/" + sub_year;
      return changeDate;
      }else{
        return null;
      }
    },
    tableUpdateData(name, val) {
      this.edit[name] = val;
    },
    tableEditUpdateData(name, val) {
      this.information[name] = val;
    },
    getRF03(id) {
      this.isLoading = true;
      this.$store
        .dispatch("FormRF/getRF03", id)
        .then((res) => {
          if (res !== "don't have progress report yet") {
            if (res.status !== "อนุมัติ") {
              this.form.instituteBook = res.instituteBook;
              this.form.fileOther = res.fileOther ?? [];
              this.form.noteDetail = res.noteDetail;
              this.form.researchCode = res.researchCode;
              this.form.researchName = res.researchName;
              this.form.signature = res.signature;
              this.form.firstName = res.firstName;
              this.form.lastName = res.lastName;
              this.updateDate("lastDay", res.lastDay);
              this.updateDate("startDay", res.startDay);
              this.updateDate("endDay", res.endDay);
              this.updateDate("reportDate", res.reportDate);
              this.form.reportTime = res.reportTime.toString();
              this.form.wantRenew = res.wantRenew.toString();
              this.form.hasOther = res.hasOther == 1;
              this.form.hasPlan = res.hasPlan == 1;
              this.form.plan = res.plan;
              this.form.hasScreen = res.hasScreen == 1;
              this.form.screen = res.screen;
              this.form.hasResearching = res.hasResearching == 1;
              this.form.researching = res.researching;
              this.form.hasFollowing = res.hasFollowing == 1;
              this.form.following = res.following;
              this.form.hasWithdraw = res.hasWithdraw == 1;
              this.form.withdraw = res.withdraw;
              this.form.hasFinish = res.hasFinish == 1;
              this.form.finish = res.finish;
              this.form.hasWithdrawVolunteer = res.hasWithdrawVolunteer.toString();
              this.form.withdrawVolunteer = res.withdrawVolunteer;
              this.form.hasEdit = res.hasEdit.toString();
              this.form.edit = res.edit;
              this.form.hasEditBrochure = res.hasEditBrochure.toString();
              this.form.hasNewInformation = res.hasNewInformation.toString();
              this.form.newInformation = res.newInformation;
              this.form.hasIssue = res.hasIssue.toString();
              this.form.issue = res.issue;
              this.form.hasChangeRatio = res.hasChangeRatio.toString();
              this.form.changeRatio = res.changeRatio;
              this.form.hasChangeDocument = res.hasChangeDocument.toString();
              this.form.changeDocument = res.changeDocument;
              this.form.hasConsentIssue = res.hasConsentIssue.toString();
              this.form.consentIssue = res.consentIssue;
              this.form.hasComplaint = res.hasComplaint.toString();
              this.form.complaint = res.complaint;
              this.form.hasBadEffect = res.hasBadEffect.toString();
              this.form.badEffect = res.badEffect;
              this.form.hasChangeMainResearcher = res.hasChangeMainResearcher.toString();
              this.form.changeMainResearcher = res.changeMainResearcher;
              this.form.hasChangeResearcherProperty = res.hasChangeResearcherProperty.toString();
              this.form.changeResearcherProperty = res.changeResearcherProperty;
              this.form.hasConcern = res.hasConcern.toString();
              this.form.concern = res.concern;
              this.form.hasInformationFromCommittee = res.hasInformationFromCommittee.toString();
              this.form.informationFromCommittee = res.informationFromCommittee;
              this.form.summarize = res.summarize;
              this.form.agencyBook = res.agencyBook;
              this.agencyBook = res.agencyBook;
              this.updateDate("bookDate", res.bookDate);
              this.form.bookNumber = res.bookNumber;
              this.form.summarize = res.summarize;
              this.form.signature = res.signature;
              this.updateDate("date", res.date);
            }
          }
          this.isLoading = false;
        })
        .catch((e) => {
          this.isLoading = false;
        });
    },
    saveDraft() {
      Swal.fire({
        title: "คุณต้องการบันทึกแบบร่างหรือไม่?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3E7662",
        cancelButtonColor: "rgb(189, 189, 189)",
        confirmButtonText: "บันทึก",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.value) {
          this.form["researchID"] = this.$route.query.researchID;
          if (this.$route.query.rfID) {
              this.form["methods"] = "edit";
              this.form["rfID"] = this.$route.query.rfID;
            } else {
              this.form["methods"] = "save";
              this.form["status"] = "บันทึกแบบร่าง";
            }
          this.isLoading = true;
          this.$store
            .dispatch("FormRF/saveRF03", this.form)
            .then((res) => {
              this.isLoading = false;
              Swal.fire({
                position: "center",
                icon: "success",
                title: "บันทึกแบบร่างสำเร็จ",
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                this.$router.push("/profile");
              });
            })
            .catch((e) => {
              this.isLoading = false;
              if (e.response.data.errors) {
                for (var k in e.response.data.errors) {
                  Swal.fire({
                    title: "เกิดข้อผิดพลาด",
                    text: e.response.data.errors[k][0],
                    icon: "warning",
                    confirmButtonColor: "#3E7662",
                  }).then(() => {
                    this.$vuetify.goTo(`#${k}`);
                  });
                }
              }
              //   Swal.fire({
              //     title: "Save failed!",
              //     text: "บันทึกไม่สำเร็จ",
              //     icon: "warning",
              //   }).then(() => {
              //     this.isLoading = false;
              //   });
            });
        }
        this.isLoading = false;
      });
    },
    save() {
      Swal.fire({
        title: "คุณต้องการบันทึกหรือไม่?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3E7662",
        cancelButtonColor: "rgb(189, 189, 189)",
        confirmButtonText: "บันทึก",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.value) {
          this.form["researchID"] = this.$route.query.researchID;
           if (this.$route.query.rfID) {
              this.form["methods"] = "edit";
              this.form["rfID"] = this.$route.query.rfID;
            } else {
              this.form["methods"] = "save";
            }
          this.isLoading = true;
          this.$store
            .dispatch("FormRF/saveRF03", this.form)
            .then((res) => {
              this.isLoading = false;
              Swal.fire({
                position: "center",
                icon: "success",
                title: "บันทึกสำเร็จ",
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                this.$router.push("/profile");
              });
            })
            .catch((e) => {
              this.isLoading = false;
              if (e.response.data.errors) {
                for (var k in e.response.data.errors) {
                  Swal.fire({
                    title: "เกิดข้อผิดพลาด",
                    text: e.response.data.errors[k][0],
                    icon: "warning",
                    confirmButtonColor: "#3E7662",
                  }).then(() => {
                    this.$vuetify.goTo(`#${k}`);
                  });
                }
              }
              //   Swal.fire({
              //     title: "Save failed!",
              //     text: "บันทึกไม่สำเร็จ",
              //     icon: "warning",
              //   }).then(() => {
              //     this.isLoading = false;
              //   });
            });
        }
        this.isLoading = false;
      });
    },
    clearCheckbox(name) {
      // if(name === "hasStartDay"){
      //   if(this.form.hasStartDay === true){
      //     this.dateChange2 = "";
      //   }
      // }
      if (name === "hasPlan") {
        if (this.form.hasPlan === false) {
          this.form.plan = "";
        }
      }
      if (name === "hasScreen") {
        if (this.form.hasScreen === false) {
          this.form.screen = "";
        }
      }
      if (name === "hasResearching") {
        if (this.form.hasResearching === false) {
          this.form.researching = "";
        }
      }
      if (name === "hasFollowing") {
        if (this.form.hasFollowing === false) {
          this.form.following = "";
        }
      }
      if (name === "hasWithdraw") {
        if (this.form.hasWithdraw === false) {
          this.form.withdraw = "";
        }
      }
      if (name === "hasFinish") {
        if (this.form.hasFinish === false) {
          this.form.finish = "";
        }
      }
    },
    clearRedio(name, val) {
      if (name === "hasWithdrawVolunteer") {
        this.form.withdrawVolunteer = "";
        this.form.hasWithdrawVolunteer = val;
      }
      if (name === "hasEdit") {
        this.form.edit = "";
        this.form.hasEdit = val;
      }
      if (name === "hasNewInformation") {
        this.form.newInformation = "";
        this.form.hasNewInformation = val;
      }
      if (name === "hasIssue") {
        this.form.issue = "";
        this.form.hasIssue = val;
      }
      if (name === "hasChangeRatio") {
        this.form.changeRatio = "";
        this.form.hasChangeRatio = val;
      }
      if (name === "hasChangeDocument") {
        this.form.changeDocument = "";
        this.form.hasChangeDocument = val;
      }
      if (name === "hasConsentIssue") {
        this.form.consentIssue = "";
        this.form.hasConsentIssue = val;
      }
      if (name === "hasComplaint") {
        this.form.complaint = "";
        this.form.hasComplaint = val;
      }
      if (name === "hasBadEffect") {
        this.form.badEffect = "";
        this.form.hasBadEffect = val;
      }
      if (name === "hasChangeMainResearcher") {
        this.form.changeMainResearcher = "";
        this.form.hasChangeMainResearcher = val;
      }
      if (name === "hasChangeResearcherProperty") {
        this.form.changeResearcherProperty = "";
        this.form.hasChangeResearcherProperty = val;
      }
      if (name === "hasConcern") {
        this.form.concern = "";
        this.form.hasConcern = val;
      }
      if (name === "hasInformationFromCommittee") {
        this.form.informationFromCommittee = "";
        this.form.hasInformationFromCommittee = val;
      }
    },
    onUpload(e) {
      this.$store
        .dispatch("Common/uploadPdf", { form: "rf03", file: e })
        .then((res) => {
          this.form.agencyBook = res.fileName;
        })
        .catch((error) => { });
    },
    onClickBtn() {
      document.getElementById("agencyBook").click();
    },
  },
};
</script>

<style lang="scss">
label.v-label.theme--light {
  color: #544a44;
}

.color-label-checkbox {
  label.v-label.theme--light {
    color: #3e7662;
  }
}

.textAlignVer {
  -moz-transform-origin: 0 50%;
  -moz-transform: rotate(-90deg) translate(-50%, 50%);
  -webkit-transform-origin: 0 50%;
  -webkit-transform: rotate(-90deg) translate(-50%, 50%);
  -o-transform-origin: 0 50%;
  -o-transform: rotate(-90deg) translate(-50%, 50%);
  -ms-transform-origin: 0 50%;
  -ms-transform: rotate(-90deg) translate(-50%, 50%);
  transform-origin: 0 50%;
  transform: rotate(-90deg) translate(-50%, 50%);
  position: absolute;
  top: 0;
  bottom: 0;
  height: 2em;
  /* line-height of .wrapper div:first-child span */
  margin: auto;
  font-weight: bold;
  font-size: 1em;
  line-height: 2em;
  /* Copy to other locations */
  left: 15px;
}
</style>
