<template>
    <v-content style="background-color: #EFEAE7">

        <v-layout style="text-align: center;">
            <v-flex pt-8>
                <span style="font-weight: bold;font-size: 36px; color: #3E7662">สร้างงานวิจัย</span>
            </v-flex>
        </v-layout>

        <v-layout>
            <v-flex style="background-color: #EFEAE7;padding: 2% 5% 2% 5%">
                <v-card>
                    <v-layout pa-8 justify-center style="background-color: #3E7662;">
                        <v-flex style="text-align: left;">
                            <span style="font-weight: bold;font-size: 28px;color: white;">แบบประวัติผู้วิจัย</span>
                        </v-flex>
                        <v-flex style="text-align: right;">
                            <span style="font-size: 14px;color: white;">Version……….Date…………………….</span><br>
                            <span style="font-size: 14px;color: white;">RF 10_2560</span>
                        </v-flex>
                    </v-layout>

                    <v-layout justify-center>
                        <v-flex px-12 py-6>
                            <!-- Choice 20 -->
                            <v-layout>
                                <v-flex xs6 pb-6 style="text-align: left;">
                                    <span style="font-weight: bold;font-size: 16px;">20. เอกสารอ้างอิง (Vancouver style)</span>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex xs6 pb-2 pl-8 style="text-align: left;">
                                    <span style="font-weight: bold;font-size: 16px;color: #3E7662">ควรเขียนตามมาตรฐานการเขียนเอกสารอ้างอิง แบบ Vancouver</span>
                                </v-flex>
                            </v-layout>
                            <TextareaForm
                                    :model="form.reference"
                                    @update-data="updateData('reference', $event)"
                            ></TextareaForm>

                            <!-- Choice 21 -->
                            <v-layout>
                                <v-flex xs6 pb-6 style="text-align: left;">
                                    <span style="font-weight: bold;font-size: 16px;">21. ภาคผนวก</span>
                                </v-flex>
                            </v-layout>
                            <v-layout>
                                <v-flex mb-6 pb-2 pl-8 style="text-align: left;">
                                    <span style="font-weight: bold;font-size: 16px;color: #3E7662">เอกสารที่เกี่ยวข้อง ซึ่งคิดว่าอาจจะเป็นประโยชน์ในการพิจารณาโครงการวิจัย</span>
                                </v-flex>
                            </v-layout>
                            <v-layout class="justify-center">
                                <v-flex xs6>
                                    <v-layout class="mb-2">
                                        <v-flex xs10 class="text-start">
                                            <v-file-input
                                                    prepend-icon=""
                                                    id="related_documents"
                                                    :model="form.related_documents"
                                                    outlined
                                                    dense
                                                    background-color="#FBF6F3"
                                                    color="#3E7662"
                                                    item-color="#3E7662"
                                                    @change="uploadRelatedDocuments"
                                                    hide-details
                                            ></v-file-input>
                                        </v-flex>
                                        <v-flex shrink pl-4 style="text-align: left">
                                            <v-btn color="#3E7662" dark @click="uploadFile('related_documents')">
                                                <v-icon class="pr-3">cloud_download</v-icon>
                                                <span>Upload</span>
                                            </v-btn>
                                        </v-flex>
                                    </v-layout>

                                    <v-layout v-if="namePDF1">
                                        <v-flex class="text-start" style="font-size: 12px;color: #CC3D45">
                                            <span>ชื่อไฟล์ที่อัพโหลด : {{namePDF1}}</span>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                            </v-layout>

                            <v-divider class="mt-8"></v-divider>

                            <v-layout>
                                <v-flex py-6 pl-8 style="text-align: left;">
                                    <span style="font-weight: bold;font-size: 16px;color: #3E7662">แบบฟอร์มเก็บข้อมูล</span>
                                </v-flex>
                            </v-layout>
                            <v-layout class="justify-center">
                                <v-flex xs6>
                                    <v-layout class="mb-2">
                                        <v-flex xs10 class="text-start">
                                            <v-file-input
                                                    hide-details
                                                    prepend-icon=""
                                                    id="data_collection_form"
                                                    :model="form.data_collection_form"
                                                    outlined
                                                    dense
                                                    background-color="#FBF6F3"
                                                    color="#3E7662"
                                                    item-color="#3E7662"
                                                    @change="uploadDataCollectionForm"
                                            ></v-file-input>
                                        </v-flex>
                                        <v-flex shrink pl-4 style="text-align: left">
                                            <v-btn color="#3E7662" dark @click="uploadFile('data_collection_form')">
                                                <v-icon class="pr-3">cloud_download</v-icon>
                                                <span>Upload</span>
                                            </v-btn>
                                        </v-flex>
                                    </v-layout>

                                    <v-layout v-if="namePDF2">
                                        <v-flex class="text-start" style="font-size: 12px;color: #CC3D45">
                                            <span>ชื่อไฟล์ที่อัพโหลด : {{namePDF2}}</span>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                            </v-layout>

                            <v-divider class="mt-8"></v-divider>

                            <v-layout>
                                <v-flex xs6 py-6 pl-8 style="text-align: left;">
                                    <span style="font-weight: bold;font-size: 16px;color: #3E7662">ประวัติผู้วิจัยและผู้ร่วมวิจัย</span>
                                </v-flex>
                            </v-layout>
                            <TextareaForm
                                    :model="form.history"
                                    @update-data="updateData('history', $event)"
                            ></TextareaForm>

                            <v-divider class="mt-2"></v-divider>

                            <v-layout>
                                <v-flex py-6 pl-8 style="text-align: left;">
                                    <span style="font-weight: bold;font-size: 16px;color: #3E7662">อื่นๆ ได้แก่ สมุดบันทึก ไดอารี่ โฆษณา แผ่นพับ บัตรประจำตัวอาสาสมัคร เป็นต้น</span>
                                </v-flex>
                            </v-layout>
                            <v-layout class="justify-center">
                                <v-flex xs6>
                                    <v-layout class="mb-2">
                                        <v-flex xs10>
                                            <v-file-input
                                                    hide-details
                                                    prepend-icon=""
                                                    id="other"
                                                    :model="form.other"
                                                    outlined
                                                    dense
                                                    background-color="#FBF6F3"
                                                    color="#3E7662"
                                                    item-color="#3E7662"
                                                    @change="uploadOther"
                                            ></v-file-input>
                                        </v-flex>
                                        <v-flex shrink pl-4 style="text-align: left">
                                            <v-btn color="#3E7662" dark @click="uploadFile('other')">
                                                <v-icon class="pr-3">cloud_download</v-icon>
                                                <span>Upload</span>
                                            </v-btn>
                                        </v-flex>
                                    </v-layout>

                                    <v-layout v-if="namePDF3">
                                        <v-flex class="text-start" style="font-size: 12px;color: #CC3D45">
                                            <span>ชื่อไฟล์ที่อัพโหลด : {{namePDF3}}</span>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                            </v-layout>

                            <v-divider class="mt-8"></v-divider>

                            <v-layout>
                                <v-flex xs6 pt-10 style="text-align: left; padding-bottom: 100px;">
                                    <span style="font-weight: bold;font-size: 16px;">22. ใส่ ฉบับที่ (Version) และลงวันที่ (Date)ที่หัวกระดาษทุกแผ่น ใส่ หมายเลขหน้า ที่ท้ายกระดาษ ทุกแผ่น</span>
                                </v-flex>
                            </v-layout>


                            <v-layout pt-12>
                                <v-flex style="text-align: left;">
                                    <span>สำนักงานคณะอนุกรรมการพิจารณาโครงการวิจัย พบ.</span>
                                </v-flex>
                                <v-flex style="text-align: right;">
                                    <span>หน้า 5 จาก 5 หน้า</span>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                </v-card>
            </v-flex>
        </v-layout>

        <v-layout>
            <v-flex mb-12 style="padding: 0 5% 2% 5%; text-align: right;">
                <v-layout>
                    <v-flex>
                        <v-layout>
                            <v-flex shrink class="text-start">
                                <v-btn style="color: #3E7662" class="mr-4" depressed  color="#ffffff" @click="$router.push('/form-research-3')">
                                    <span class="font-weight-bold">ย้อนกลับ</span>
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-flex>

                    <v-flex>
                        <v-layout>
                            <v-spacer></v-spacer>
                            <v-flex shrink class="text-end">
                                <v-btn outlined depressed style="background-color: white" color="#3E7662" @click="saveResearch('saveResearch')">
                                    <v-icon class="mr-3">save_alt</v-icon>
                                    <span style="color: #3e7662;font-weight: bold;">บันทึกโครงร่างงานวิจัย</span>
                                </v-btn>
                            </v-flex>
                            <v-flex shrink class="text-end ml-4">
                                <v-btn depressed  dark color="#3E7662" @click="saveResearch('sendResearch')">
                                    <v-icon class="mr-3">unarchive</v-icon>
                                    <span>ส่งโครงร่างงานวิจัย</span>
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
        <loading
                color="#527281"
                :active.sync="isLoading"
                :can-cancel="false"
                :is-full-page="fullPage"
        ></loading>

    </v-content>
</template>

<script>
    import TextareaForm from "../../components/input/TextareaForm";
    import Swal from 'sweetalert2';
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import {mapState} from "vuex"
    export default {
        name: "FormResearch4",
        components: {
            TextareaForm,
            Loading,
        },
        data: () => ({
            isLoading: false,
            fullPage: true,
            form_api: {},
            namePDF1:'',
            namePDF2:'',
            namePDF3:'',
            form: {
                reference: '',
                related_documents: '',
                data_collection_form: '',
                history: '',
                other: '',
            } ,
        }),
        computed: {
            ...mapState({
                researchID: state => state.FormResearch.researchID,
                lastRefID: state => state.FormResearch.lastRefID,
                remainingDate: state => state.Common.remainingDate
            })
        },
        created() {
            this.getFormResearch4();
            if (this.remainingDate === 0) {
                this.$router.push('/profile')
            }
        },
        methods: {
            updateData(name,val) {
                this.form[name] = val;
            },
            uploadRelatedDocuments(e) {
                this.form.related_documents = e;
            },
            uploadDataCollectionForm(e) {
                this.form.data_collection_form = e;
            },
            uploadOther(e) {
                this.form.other = e;
            },
            uploadFile(name) {
                if (name === 'related_documents') {
                    document.getElementById("related_documents").click();
                } else if (name === 'data_collection_form'){
                    document.getElementById("data_collection_form").click();
                } else {
                    document.getElementById("other").click();
                }
            },
            saveResearch(val) {
                if (val === 'saveResearch') {
                    this.isLoading = true;
                    this.form['researchID'] = this.researchID;
                    this.form['checkResearch'] = val;
                    this.$store.dispatch("FormResearch/saveResearch4", this.form).then(res => {
                        Swal.fire({
                            title: "บันทึกโครงร่างงานวิจัยเรียบร้อยแล้ว",
                            icon: 'success',
                            position: 'center',
                            showConfirmButton: false,
                            background: '#fffff',
                            confirmButtonColor: '#3E7662',
                            timer: 1500
                        });
                        this.getFormResearch4();
                        this.isLoading = false;
                    }).catch(e => {
                        Swal.fire({
                            title: 'เกิดข้อผิดพลาด',
                            text: 'บันทึกโครงร่างงานวิจัยไม่สำเร็จ',
                            icon: 'error',
                            confirmButtonColor: '#3E7662',
                        }).then(() => {
                            this.isLoading = false;
                        })
                    })
                } else {
                    Swal.fire({
                        title: 'คุณต้องการส่งโครงร่างงานวิจัยหรือไม่?',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3E7662',
                        cancelButtonColor: 'rgb(189, 189, 189)',
                        confirmButtonText: 'ใช่ ฉันต้องการ',
                        cancelButtonText: 'ยกเลิก',
                    }).then((result) => {
                        if (result.value) {
                            this.isLoading = true;
                            this.form['researchID'] = this.researchID;
                            this.form['checkResearch'] = val;
                            this.$store.dispatch("FormResearch/saveResearch4", this.form).then(res => {
                                Swal.fire({
                                    title: "ส่งโครงร่างงานวิจัยเรียบร้อยแล้ว",
                                    icon: 'success',
                                    position: 'center',
                                    showConfirmButton: false,
                                    background: '#fffff',
                                    timer: 1500,
                                    confirmButtonColor: '#3E7662',
                                });
                                this.isLoading = false;
                                this.$router.push('/profile');
                            }).catch(e => {
                                Swal.fire({
                                    title: 'เกิดข้อผิดพลาด',
                                    text: 'ส่งโครงร่างงานวิจัยไม่สำเร็จ',
                                    icon: 'error',
                                    confirmButtonColor: '#3E7662',
                                }).then(() => {
                                    this.isLoading = false;
                                })
                            })
                        }
                    }).catch(e => {
                        this.isLoading = false;
                    })
                }


            },
            getFormResearch4() {
                if (this.lastRefID !== 0) {
                    this.$store.dispatch('FormResearch/getFormResearch3', this.researchID).then(res => {
                        if (res.research_info.length > 25) {
                            this.form.reference = res.research_info[25].subTopicID === 26 ? res.research_info[25].answer : '';
                            this.form.related_documents = res.research_info[26].subTopicID === 27 ? res.research_info[26].answer : '';
                            this.form.data_collection_form = res.research_info[27].subTopicID === 28 ? res.research_info[27].answer : '';
                            this.form.history = res.research_info[28].subTopicID === 29 ? res.research_info[28].answer : '';
                            this.form.other = res.research_info[29].subTopicID === 30 ? res.research_info[29].answer : '';

                            this.namePDF1 = res.research_info[26].subTopicID === 27 ? res.research_info[26].answer : '';
                            this.namePDF2 = res.research_info[27].subTopicID === 28 ? res.research_info[27].answer : '';
                            this.namePDF3 = res.research_info[29].subTopicID === 30 ? res.research_info[29].answer : '';
                        }else {
                            this.$store.dispatch('FormResearch/getFormResearch3', this.lastRefID).then(res => {
                                if (res.research_info.length > 25) {
                                    this.form.reference = res.research_info[25].subTopicID === 26 ? res.research_info[25].answer : '';
                                    this.form.related_documents = res.research_info[26].subTopicID === 27 ? res.research_info[26].answer : '';
                                    this.form.data_collection_form = res.research_info[27].subTopicID === 28 ? res.research_info[27].answer : '';
                                    this.form.history = res.research_info[28].subTopicID === 29 ? res.research_info[28].answer : '';
                                    this.form.other = res.research_info[29].subTopicID === 30 ? res.research_info[29].answer : '';

                                    this.namePDF1 = res.research_info[26].subTopicID === 27 ? res.research_info[26].answer : '';
                                    this.namePDF2 = res.research_info[27].subTopicID === 28 ? res.research_info[27].answer : '';
                                    this.namePDF3 = res.research_info[29].subTopicID === 30 ? res.research_info[29].answer : '';
                                }
                            })
                        }
                    })
                }
            },
            backPage() {
                this.$store.dispatch("FormResearch/changePage", 3)
            },
        },
    }
</script>

<style scoped>

</style>
