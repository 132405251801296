<template>
  <v-content class="formrf06">
    <v-layout style="background-color: #FAF5F2;">
      <v-flex>
        <v-layout my-3 class="text-center">
          <v-flex style="color: #3E7662;font-weight: bold;font-size: 28px">
            <span>แบบฟอร์ม</span>
          </v-flex>
        </v-layout>

        <v-layout style="margin-left: 100px;margin-right: 100px;">
          <v-flex>
            <v-layout mt-3 style="background-color: #3E7662;">
              <v-flex my-8 ml-8 style="text-align: left;font-weight: bold;font-size: 28px;color: white">
                <div>แบบรายงานสรุปผลการวิจัย</div>
                <div style="font-size: 18px;">Final Report</div>
              </v-flex>
              <v-flex ml-8 pt-8 pr-8 style="text-align: right;font-weight: bold;font-size: 13px;color: white">
                <span>RF 06_2563</span>
              </v-flex>
            </v-layout>

            <v-card class="mb-12 px-12">
              <!-- blog 2 -->
              <v-layout py-12>
                <v-flex>
                  <v-layout justify-center>
                    <v-flex xs2 mr-4 pt-2 style="text-align: right;font-weight: bold;">
                      <span>ชื่อโครงการวิจัย</span>
                    </v-flex>
                    <v-flex xs6>
                      <textfield-form id="researchName" :model="form.researchName" style="font-weight: bold"
                        @update-data="updateData('researchName', $event)"></textfield-form>
                    </v-flex>
                  </v-layout>
                  <v-layout justify-center>
                    <v-flex xs2 mr-4 pt-2 style="text-align: right;font-weight: bold;">
                      <span>รหัสโครงการวิจัย</span>
                    </v-flex>
                    <v-flex xs6>
                      <textfield-form id="researchCode" :model="form.researchCode" style="font-weight: bold"
                        @update-data="updateData('researchCode', $event)"></textfield-form>
                    </v-flex>
                  </v-layout>
                  <v-layout justify-center>
                    <v-flex xs2 mr-4 pt-2 style="text-align: right;font-weight: bold;">
                      <span>ผู้สนับสนุนทุนวิจัย</span>
                    </v-flex>
                    <v-flex xs6>
                      <textfield-form id="researchInvestor" :model="form.researchInvestor" style="font-weight: bold"
                        @update-data="updateData('researchInvestor', $event)"></textfield-form>
                    </v-flex>
                  </v-layout>
                  <v-layout justify-center>
                    <v-flex xs2 mr-4 pt-2 style="text-align: right;font-weight: bold;">
                      <span>ผู้วิจัยหลัก</span>
                    </v-flex>
                    <v-flex xs6>
                      <textfield-form id="mainResearcher" :model="form.mainResearcher" style="font-weight: bold"
                        @update-data="updateData('mainResearcher', $event)"></textfield-form>
                    </v-flex>
                  </v-layout>
                  <v-layout justify-center>
                    <v-flex xs2 mr-4 pt-2 style="text-align: right;font-weight: bold;">
                      <span>โทรศัพท์</span>
                    </v-flex>
                    <v-flex xs6>
                      <textfield-form required id="phoneNumber" :model="form.phoneNumber" style="font-weight: bold"
                        @update-data="updateData('phoneNumber', $event)"></textfield-form>
                    </v-flex>
                  </v-layout>
                  <v-layout justify-center>
                    <v-flex xs2 mr-4 pt-2 style="text-align: right;font-weight: bold;">
                      <span>อีเมล</span>
                    </v-flex>
                    <v-flex xs6>
                      <textfield-form id="email" :model="form.email" style="font-weight: bold"
                        @update-data="updateData('email', $event)"></textfield-form>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>

            <!-- blog 2 -->
            <v-layout pt-12 pb-8 mb-12 style="background-color: white;padding-left: 100px;padding-right: 100px;">
              <v-flex>
                <!-- row 1 -->
                <v-layout>
                  <v-flex xs3 pt-2 mr-3 style="text-align: right;font-weight: bold;">
                    <span>วันที่รับรองโครงร่างการวิจัยครั้งแรก</span>
                  </v-flex>
                  <v-flex xs3>
                    <date-input-form id="firstDate" :model="dateChange1"
                      @update-data="updateDate('firstDate', $event)"></date-input-form>
                  </v-flex>
                  <v-flex xs3 pt-2 mr-3 style="text-align: right;font-weight: bold;">
                    <span>วันที่รับรองโครงร่างการวิจัยครั้งล่าสุด</span>
                  </v-flex>
                  <v-flex xs3>
                    <date-input-form id="lastDate" :model="dateChange2"
                      @update-data="updateDate('lastDate', $event)"></date-input-form>
                  </v-flex>
                </v-layout>

                <!-- row 2 -->
                <v-layout>
                  <v-flex xs3 pt-2 mr-3 style="text-align: right;font-weight: bold;">
                    <span>วันที่เริ่มดำเนินการวิจัย</span>
                  </v-flex>
                  <v-flex xs3>
                    <date-input-form id="startDate" :model="dateChange3"
                      @update-data="updateDate('startDate', $event)"></date-input-form>
                  </v-flex>
                  <v-flex xs3 pt-2 mr-3 style="text-align: right;font-weight: bold;">
                    <span>วันที่สิ้นสุดการวิจัย</span>
                  </v-flex>
                  <v-flex xs3>
                    <date-input-form id="endDate" :model="dateChange4"
                      @update-data="updateDate('endDate', $event)"></date-input-form>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>

            <!-- blog 3 -->
            <v-layout pt-12 pb-8 mb-12 style="background-color: white;padding-left: 100px;padding-right: 100px;">
              <v-flex>
                <!-- Group 1 -->
                <v-layout pt-4>
                  <v-flex d-flex justify-start>
                    <span style="font-weight: bold;font-size: 18px;">Summary of Protocol Participants</span>
                  </v-flex>
                </v-layout>
                <v-layout py-6 pl-12>
                  <v-flex>
                    <v-layout pt-1 mb-4>
                      <v-flex d-flex justify-start>
                        <v-layout>
                          <v-flex class="d-flex align-center xs6">
                            <span style="color: #3E7662;">Accrual ceiling set by IEC/IRB</span>
                          </v-flex>
                          <v-flex class="xs3">
                            <textfield-form type="number" hide-detail id="email" :model="form.accrualCeiling"
                              style="font-weight: bold" @update-data="
                                updateData('accrualCeiling', $event)
                                "></textfield-form>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                    <v-layout pt-1 mb-4>
                      <v-flex d-flex justify-start>
                        <v-layout>
                          <v-flex class="d-flex align-center xs6">
                            <span style="color: #3E7662;">Total accrued participants</span>
                          </v-flex>
                          <v-flex class="xs3">
                            <textfield-form type="number" hide-detail id="email" :model="form.totalParticipants"
                              style="font-weight: bold" @update-data="
                                updateData('totalParticipants', $event)
                                "></textfield-form>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                    <v-layout pt-1 mb-4>
                      <v-flex d-flex justify-start>
                        <v-layout>
                          <v-flex class="d-flex align-center xs6">
                            <span style="color: #3E7662;">Total participants fail screening</span>
                          </v-flex>
                          <v-flex class="xs3">
                            <textfield-form type="number" hide-detail id="email" :model="form.totalScreening"
                              style="font-weight: bold" @update-data="
                                updateData('totalScreening', $event)
                                "></textfield-form>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                    <v-layout pt-1 mb-4>
                      <v-flex d-flex justify-start>
                        <v-layout>
                          <v-flex class="d-flex align-center xs6">
                            <span style="color: #3E7662;">Total participants withdrawn</span>
                          </v-flex>
                          <v-flex class="xs3">
                            <textfield-form type="number" hide-detail id="email" :model="form.totalWithdrawn"
                              style="font-weight: bold" @update-data="
                                updateData('totalWithdrawn', $event)
                                "></textfield-form>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                    <v-layout pt-1 mb-4>
                      <v-flex d-flex justify-start>
                        <v-layout>
                          <v-flex class="d-flex align-center xs6">
                            <span style="color: #3E7662;">Total participants dead</span>
                          </v-flex>
                          <v-flex class="xs3">
                            <textfield-form type="number" hide-detail id="email" :model="form.totalDead"
                              style="font-weight: bold" @update-data="updateData('totalDead', $event)"></textfield-form>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                    <v-layout pt-1 mb-4>
                      <v-flex d-flex justify-start>
                        <v-layout>
                          <v-flex class="d-flex align-center xs6">
                            <span style="color: #3E7662;">Total participants complete the research</span>
                          </v-flex>
                          <v-flex class="xs3">
                            <textfield-form type="number" hide-detail id="email" :model="form.totalCompleteResearch"
                              style="font-weight: bold" @update-data="
                                updateData('totalCompleteResearch', $event)
                                "></textfield-form>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <!-- Group 2 -->
                <v-layout pt-8>
                  <v-flex d-flex justify-start>
                    <span style="font-weight: bold;font-size: 18px;">Summary of Protocol Participants</span>
                  </v-flex>
                </v-layout>
                <v-layout pt-6 pb-12 pl-12>
                  <v-flex>
                    <v-layout pt-1 mb-4>
                      <v-flex d-flex justify-start>
                        <v-layout>
                          <v-flex class="d-flex align-center xs6">
                            <span style="color: #3E7662;">Total participants experience adverse
                              events</span>
                          </v-flex>
                          <v-flex class="xs3">
                            <textfield-form type="number" hide-detail id="email" :model="form.totalExpAdversEvents"
                              style="font-weight: bold" @update-data="
                                updateData('totalExpAdversEvents', $event)
                                "></textfield-form>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                    <v-layout pt-1 mb-4>
                      <v-flex d-flex justify-start>
                        <v-layout>
                          <v-flex class="d-flex align-center xs6">
                            <span style="color: #3E7662;">Total participants experience SUSARs</span>
                          </v-flex>
                          <v-flex class="xs3">
                            <textfield-form type="number" hide-detail id="email" :model="form.totalExpSUSARs"
                              style="font-weight: bold" @update-data="
                                updateData('totalExpSUSARs', $event)
                                "></textfield-form>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>

                    <v-layout pt-1>
                      <v-flex d-flex justify-start>
                        <v-layout>
                          <v-flex class="d-flex text-start xs6">
                            <span style="color: #3E7662;">Total global participants experience SUSARs, if
                              applicable</span>
                          </v-flex>
                          <v-flex class="xs3">
                            <textfield-form type="number" hide-detail id="totalGlobal" :model="form.totalGlobal"
                              style="font-weight: bold" @update-data="updateData('totalGlobal', $event)"></textfield-form>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <!-- Group 3 -->
                <v-layout py-6>
                  <v-flex d-flex justify-start>
                    <span style="font-weight: bold;font-size: 18px;">
                      Has there been any protocol deviation/violation or
                      compliance issues occurred during the study?
                    </span>
                  </v-flex>
                </v-layout>
                <v-layout pl-12>
                  <v-flex style="color: #3E7662">
                    <v-radio-group v-model="form.hasIssue" row id="hasIssue">
                      <v-radio color="#3E7662" style="padding-right: 100px" label="No" value="0"></v-radio>
                      <v-radio color="#3E7662" label="YES (please attach a report)" value="1"></v-radio>
                    </v-radio-group>
                  </v-flex>
                </v-layout>
                <v-layout v-if="form.hasIssue === '1'">
                  <v-flex shrink pt-2 pr-4 style="text-align: left;">
                    <span style="font-weight: bold;font-size: 16px;">ไฟล์</span>
                  </v-flex>
                  <v-flex lg4 md7 sm6>
                    <v-file-input accept=".pdf" prepend-icon="" id="anyProtocolFile" ref="anyProtocolFile"
                      :model="form.anyProtocolFile" outlined dense background-color="#FBF6F3" color="#3E7662"
                      item-color="#3E7662" @change="onUpload('anyProtocolFile', $event)" hide-details></v-file-input>
                  </v-flex>
                  <v-flex shrink pl-4 style="text-align: left">
                    <v-btn color="#3E7662" dark @click="onClickUpload('anyProtocolFile')">
                      <v-icon class="pr-3">cloud_download</v-icon>
                      <span>Upload</span>
                    </v-btn>
                  </v-flex>
                  <v-flex xs3 ml-4><span style="color: red">*ชื่อไฟล์ต้องเป็นภาษาอังกฤษเท่านั้น (a-z,0-9)
                      ห้ามมีตัวอักษรอื่น </span></v-flex>
                </v-layout>
                <div v-if="anyProtocolFile" class="mt-1 text-start"
                  style="font-size: 12px;color: #CC3D45;padding-left: 40px">
                  ไฟล์ที่อัพโหลด:
                  <a :href="anyProtocolFilePath" target="_blank" class="link-hover">
                    <span>{{ anyProtocolFile }}</span>
                  </a>
                </div>
                <!-- Group 4 -->
                <v-layout py-6>
                  <v-flex d-flex justify-start>
                    <span style="font-weight: bold;font-size: 18px;">
                      Has there been any complaint or warning letter against the
                      research or investigator during the study?
                    </span>
                  </v-flex>
                </v-layout>
                <v-layout pl-12>
                  <v-flex style="color: #3E7662">
                    <v-radio-group v-model="form.hasWarning" row id="hasWarning">
                      <v-radio color="#3E7662" style="padding-right: 100px" label="No" value="0"></v-radio>
                      <v-radio color="#3E7662" label="YES (please attach a report)" value="1"></v-radio>
                    </v-radio-group>
                  </v-flex>
                </v-layout>
                <v-layout v-if="form.hasWarning === '1'">
                  <v-flex shrink pt-2 pr-4 style="text-align: left;">
                    <span style="font-weight: bold;font-size: 16px;">ไฟล์</span>
                  </v-flex>
                  <v-flex lg4 md7 sm6>
                    <v-file-input accept=".pdf" prepend-icon="" id="anyComplaintFile" ref="anyComplaintFile"
                      :model="form.anyComplaintFile" outlined dense background-color="#FBF6F3" color="#3E7662"
                      item-color="#3E7662" @change="onUpload('anyComplaintFile', $event)" hide-details></v-file-input>
                  </v-flex>
                  <v-flex shrink pl-4 style="text-align: left">
                    <v-btn color="#3E7662" dark @click="onClickUpload('anyComplaintFile')">
                      <v-icon class="pr-3">cloud_download</v-icon>
                      <span>Upload</span>
                    </v-btn>
                  </v-flex>
                  <v-flex xs3 ml-4><span style="color: red">*ชื่อไฟล์ต้องเป็นภาษาอังกฤษเท่านั้น (a-z,0-9)
                      ห้ามมีตัวอักษรอื่น </span></v-flex>
                </v-layout>
                <div v-if="anyComplaintFile" class="mt-1 text-start"
                  style="font-size: 12px;color: #CC3D45;padding-left: 40px">
                  ไฟล์ที่อัพโหลด:
                  <a :href="anyComplaintFilePath" target="_blank" class="link-hover">
                    <span>{{ anyComplaintFile }}</span>
                  </a>
                </div>
                <!-- Group 5 -->
                <v-layout py-6>
                  <v-flex d-flex justify-start>
                    <span style="font-weight: bold;font-size: 18px;">
                      Are there any benefit or negative impact to participants
                      after the study completion?
                    </span>
                  </v-flex>
                </v-layout>
                <v-layout pl-12 pb-12>
                  <v-flex style="color: #3E7662">
                    <v-radio-group v-model="form.hasImpact" row id="hasImpact">
                      <v-radio color="#3E7662" style="padding-right: 100px" label="No" value="0"></v-radio>
                      <v-radio color="#3E7662" label="YES" value="1"></v-radio>
                    </v-radio-group>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>

            <!-- blog 4 -->
            <v-layout pt-12 pb-8 mb-12 style="background-color: white;padding-left: 100px;padding-right: 100px;">
              <v-flex>
                <!-- row 1 -->
                <v-layout pb-4>
                  <v-flex d-flex justify-start>
                    <span style="font-weight: bold;font-size: 18px;">
                      Summary of Result (or attached abstract)
                    </span>
                  </v-flex>
                </v-layout>
                <v-layout pt-6>
                  <v-flex d-flex justify-start>
                    <textarea-form id="summaryOfResult" :rows="15" :model="form.summaryOfResult"
                      @update-data="updateData('summaryOfResult', $event)"></textarea-form>
                  </v-flex>
                </v-layout>
                <!-- row 4 -->
                <v-layout mt-12>
                  <v-flex xs6 style="text-align: left;">
                    <span style="font-weight: bold;font-size: 18px;">
                      Please attach a summary of result
                    </span>
                  </v-flex>
                </v-layout>
                <v-layout class="mt-4">
                  <v-flex shrink pt-2 pr-4 style="text-align: left;">
                    <span style="font-weight: bold;font-size: 16px;">ไฟล์</span>
                  </v-flex>
                  <v-flex lg4 md7 sm6>
                    <v-file-input accept=".pdf" prepend-icon="" id="resultFile" ref="resultFile" :model="form.resultFile"
                      outlined dense background-color="#FBF6F3" color="#3E7662" item-color="#3E7662"
                      @change="onUpload('resultFile', $event)" hide-details></v-file-input>
                  </v-flex>
                  <v-flex shrink pl-4 style="text-align: left">
                    <v-btn color="#3E7662" dark @click="onClickUpload('resultFile')">
                      <v-icon class="pr-3">cloud_download</v-icon>
                      <span>Upload</span>
                    </v-btn>
                  </v-flex>
                  <v-flex xs3 ml-4><span style="color: red">*ชื่อไฟล์ต้องเป็นภาษาอังกฤษเท่านั้น (a-z,0-9)
                      ห้ามมีตัวอักษรอื่น </span></v-flex>
                </v-layout>

                <div v-if="resultFile" class="mt-1 text-start" style="font-size: 12px;color: #CC3D45;padding-left: 40px">
                  ไฟล์ที่อัพโหลด:
                  <a :href="resultFilePath" target="_blank" class="link-hover">
                    <span>{{ resultFile }}</span>
                  </a>
                </div>

                <v-layout class="my-12">
                  <v-flex>
                    <v-layout class="mb-8">
                      <v-flex>
                        <v-layout>
                          <v-flex shrink pt-2 pr-4 style="text-align: left;">
                            <span style="font-weight: bold;font-size: 16px;">หนังสือของหน่วยงาน
                            </span>
                          </v-flex>
                          <v-flex>
                            <v-file-input accept=".pdf" prepend-icon="" ref="agencyBooks" id="agencyBook"
                              :model="form.agencyBook" outlined dense clearable background-color="#FBF6F3" color="#3E7662"
                              item-color="#3E7662" @change="onUpload('agencyBook', $event)" hide-details></v-file-input>
                            <div v-if="agencyBook" class="mt-1 text-start" style="font-size: 12px;color: #CC3D45;">
                              ไฟล์ที่อัพโหลด:
                              <a :href="agencyBookPath" target="_blank" class="link-hover">
                                <span>{{ agencyBook }}</span>
                              </a>
                            </div>
                          </v-flex>
                          <v-flex shrink pl-4 style="text-align: left">
                            <v-btn color="#3E7662" dark @click="onClickUpload('agencyBook')">
                              <v-icon class="pr-3">cloud_download</v-icon>
                              <span>Upload</span>
                            </v-btn>
                          </v-flex>
                          <v-flex xs3 ml-4><span style="color: red">*ชื่อไฟล์ต้องเป็นภาษาอังกฤษเท่านั้น (a-z,0-9)
                              ห้ามมีตัวอักษรอื่น </span></v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                    <v-layout>
                      <v-flex xs6>
                        <v-layout>
                          <v-flex class="d-flex align-center mr-4 font-weight-bold" shrink>หน่วยงานที่ออกหนังสือ</v-flex>
                          <v-flex>
                            <textfield-form hide-detail :model="form.instituteBook"
                              @update-data="updateData('instituteBook', $event)"></textfield-form>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                    <v-layout mt-6>
                      <v-flex xs6>
                        <v-layout>
                          <v-flex class="d-flex align-center mr-4 font-weight-bold" shrink>เลขหนังสือ</v-flex>
                          <v-flex>
                            <textfield-form hide-detail :model="form.bookNumber"
                              @update-data="updateData('bookNumber', $event)"></textfield-form>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs6>
                        <v-layout>
                          <v-flex class="d-flex align-center mx-4 font-weight-bold" shrink>วันที่หนังสือ</v-flex>
                          <v-flex>
                            <DateInputForm id="orderDate" hide-detail :model="bookDate"
                              @update-data="updateDate('bookDate', $event)"></DateInputForm>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v-layout style="margin-top: 30px">
                  <v-flex ml-8 my-5 style="text-align: left;">
                    <span style="font-weight: bold">Note</span>
                    <span style="color: red"> *รายการที่ต้องแก้ไข</span>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex style="padding-left: 32px; padding-right: 32px">
                    <textarea-form readonly :model="form.noteDetail" :rows="5"></textarea-form>
                  </v-flex>
                </v-layout>

                <v-layout px-12 mt-8>
                  <!-- row 1-->
                  <v-flex xs6 style="font-weight: bold;font-size: 18px" d-flex justify-start>
                    <span>เอกสารแนบเพิ่มเติม</span>
                  </v-flex>
                </v-layout>
                <v-layout px-12 my-4>
                  <v-flex shrink mr-4>
                    <v-btn dark style="width: 80px;background-color: #3E7662" @click="addDynamicFile('fileOther')">
                      <v-icon>add</v-icon>
                      <span class="font-weight-bold">เพิ่ม</span>
                    </v-btn>
                  </v-flex>
                  <v-flex>
                    <span style="color: #CC3D45;">*ให้ผู้วิจัยระบุชื่่อ file / เวอร์ชั่น / วันที่
                      ให้ตรงกับ บันทึกข้อความ และห้ามมีอักขระพิเศษ
                      (!@#$%^&*)</span>
                  </v-flex>
                </v-layout>
                <div v-for="(input, k) in form.fileOther" :key="'d' + k">
                  <v-layout>
                    <v-flex>
                      <v-layout>
                        <v-flex shrink mr-4 pt-2 style="text-align: right;font-weight: bold;">
                          <span>ชื่อไฟล์</span>
                        </v-flex>
                        <v-flex xs5>
                          <textfield-form hide-detail :model="input.title" @update-data="
                            updateDynamicFileData('fileOther', 'title', k, $event)"></textfield-form>
                        </v-flex>
                        <v-flex shrink pt-2 px-4 style="text-align: left;">
                          <span style="font-weight: bold;font-size: 16px;">ไฟล์</span>
                        </v-flex>
                        <v-flex>
                          <v-file-input accept=".pdf" prepend-icon="" :id="'fileProtocolModify' + k" :model="input.file"
                            outlined dense background-color="#FBF6F3" color="#3E7662" item-color="#3E7662" @change="
                              updateDynamicFileData('fileOther', 'file', k, $event)"></v-file-input>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex ml-4>
                      <v-btn dark style="background-color: #CC3D45; width: 80px"
                        @click="removeDynamicFile('fileOther', k)" :disabled="form.fileOther.length < 1">
                        <v-icon>remove</v-icon>
                        <span class="font-weight-bold">ลบ</span>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                  <v-flex v-if="input.filePath"><a :href="input.filePath" target="_blank"
                      style="color: #3e7662;text-align: center" class="link-hover">
                      <p>ไฟล์ที่อัพโหลด: {{ input.file }}</p>
                    </a>
                  </v-flex>
                </div>

                <!-- row 2 -->
                <v-layout pt-12 pb-12>
                  <v-flex xs6 pl-3 mr-6 class="font-weight-bold">
                    <v-text-field id="signature" dense placeholder="signature" append-icon="edit" color="#3E7662"
                      v-model="form.signature"></v-text-field>
                  </v-flex>
                  <v-flex>
                    <date-input-form id="reportDate" label="Date" :model="dateChange5"
                      @update-data="updateDate('reportDate', $event)"></date-input-form>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex d-flex justify-start>
                    <span>สำนักงานคณะอนุกรรมการพิจารณาโครงการวิจัย พบ.</span>
                  </v-flex>
                  <v-flex d-flex justify-end>
                    <span>หน้า 1 จาก 1 หน้า</span>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>

            <v-layout>
              <v-flex mb-12 style="text-align: right;">
                <v-layout>
                  <v-flex>
                    <v-btn style="color: #3E7662" class="mx-4" depressed @click="saveDraft"
                      color="#ffffff">บันทึกร่าง</v-btn>
                    <!--<v-btn style="color: #3E7662" class="mx-4" depressed  color="#ffffff" @click="$router.go(-1)">ย้อนกลับ</v-btn>-->
                    <v-btn depressed dark color="#3E7662" @click="save">
                      <v-icon class="mr-3">add</v-icon>
                      <span>บันทึกข้อมูล</span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <loading color="#527281" :active.sync="isLoading" :can-cancel="false" :is-full-page="fullPage"></loading>
      </v-flex>
    </v-layout>
  </v-content>
</template>

<script>
import TextfieldForm from "../../components/input/TextfieldForm";
import DateInputForm from "../../components/input/DateInputForm";
import TextareaForm from "../../components/input/TextareaForm";
import Swal from "sweetalert2";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { scroller } from "vue-scrollto/src/scrollTo";

export default {
  name: "FormRF06",
  components: {
    TextfieldForm,
    DateInputForm,
    TextareaForm,
    Loading,
  },
  data: () => ({
    isLoading: false,
    fullPage: true,
    form: {
      noteDetail: "",
      fileOther: [],
      researchName: "",
      researchCode: "",
      researchInvestor: "",
      mainResearcher: "",
      phoneNumber: "",
      email: "",
      firstDate: "",
      lastDate: "",
      startDate: "",
      endDate: "",
      hasIssue: "",
      hasWarning: "",
      hasImpact: "",
      summaryOfResult: "",
      resultFile: "",
      agencyBook: "",
      bookNumber: "",
      bookDate: "",
      signature: "",
      reportDate: "",
      accrualCeiling: "",
      totalParticipants: "",
      totalScreening: "",
      totalWithdrawn: "",
      totalDead: "",
      totalCompleteResearch: "",
      totalExpAdversEvents: "",
      totalExpSUSARs: "",
      anyProtocolFile: "",
      anyComplaintFile: "",
      totalGlobal: "",
      instituteBook: ""
    },
    dynamicDateChange: {
      fileOther: []
    },
    dateChange1: "",
    dateChange2: "",
    dateChange3: "",
    dateChange4: "",
    dateChange5: "",
    agencyBook: "",
    resultFile: "",
    anyProtocolFile: "",
    anyComplaintFile: "",
    agencyBookPath: "",
    resultFilePath: "",
    anyProtocolFilePath: "",
    anyComplaintFilePath: "",
    bookDate: "",
  }),
  created() {
    // Check Permission
    // if (
    //   !this.$route.query.researchID &&
    //   this.$route.query.status !== "อนุมัติ"
    // ) {
    //   this.$router.go(-1);
    // } else {
    // Set data User

    if (!this.$route.query.rfID) {
      this.form.researchCode = this.$route.query.researchCode;
      this.form.researchName = this.$route.query.nameResearch;
      this.form.researchInvestor = this.$route.query.budgetResource;
      this.form.mainResearcher = this.$route.query.thName;
      this.form.signature = this.$route.query.thName;

      //   this.updateDate("startDate", this.$route.query.startDate);
      //   this.updateDate("endDate", this.$route.query.endDate);
      this.updateDate("firstDate", this.$route.query.firstApprovalDate);
      this.updateDate("lastDate", this.$route.query.approvalDate);

      this.updateDate("reportDate", new Date().toISOString().slice(0, 10));
      this.form.phoneNumber = this.$route.query.mobilePhone;
      this.form.email = this.$route.query.email;
    }
    // this.getRF06(this.$route.query.researchID);
    if (this.$route.query.rfID) {
      this.getRF06(this.$route.query.rfID);
      this.isOnCreated = false;
    }
    // }
  },
  methods: {
    addDynamicFile(name) {
      this.form[name].push({ title: "", file: null });
    },
    updateDynamicFileData(name, type, index, value) {
      if (type === "file") {
        this.isLoading = true;
        this.$store
          .dispatch("Common/uploadPdf", { form: "rf-06other-file", file: value })
          .then((res) => {
            this.form[name][index].file = res.fileName;
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
          });
      } else {
        this.form[name][index][type] = value;
      }
    },
    removeDynamicFile(name, index) {
      this.form[name].splice(index, 1);
      this.$forceUpdate();
    },
    updateData(name, val) {
      this.form[name] = val;
    },
    updateDate(name, val) {
      if (name === "firstDate") {
        this.form.firstDate = val;
        this.dateChange1 = this.changeDate(val);
      }
      if (name === "lastDate") {
        this.form.lastDate = val;
        this.dateChange2 = this.changeDate(val);
      }
      if (name === "startDate") {
        this.form.startDate = val;
        this.dateChange3 = this.changeDate(val);
      }
      if (name === "endDate") {
        this.form.endDate = val;
        this.dateChange4 = this.changeDate(val);
      }
      if (name === "reportDate") {
        this.form.reportDate = val;
        this.dateChange5 = this.changeDate(val);
      }
      if (name === "bookDate") {
        this.form.bookDate = val;
        this.bookDate = this.changeDate(val);
      }
    },
    changeDate(val) {
      if (val != null) {
        //change date start
        let sub_year = val.substring(0, 4);
        let sub_month = val.substring(5, 7);
        let sub_day = val.substring(8, 10);
        sub_year = parseInt(sub_year) + 543;
        let changeDate = sub_day + "/" + sub_month + "/" + sub_year;
        return changeDate;
      } else {
        return null;
      }
    },
    showErrorFill(element, message) {
      Swal.fire({
        title: message,
        icon: "warning",
        confirmButtonColor: "#3E7662",
      }).then((result) => {
        const firstScrollTo = scroller();
        firstScrollTo("#" + element);
      });
    },

    getRF06(id) {
      this.isLoading = true;
      this.$store
        .dispatch("FormRF/getRF06", id)
        .then((res) => {
          if (res !== "don't have research summarize yet") {
            if (res.status !== "อนุมัติ") {
              this.form.instituteBook = res.instituteBook;
              this.form.fileOther = res.fileOther ?? [];
              this.form.noteDetail = res.noteDetail;
              this.form.researchCode = res.researchCode;
              this.form.researchName = res.researchName;
              this.form.researchInvestor = res.researchInvestor;
              this.form.mainResearcher = res.mainResearcher;
              this.form.signature = res.signature;
              this.form.phoneNumber = res.phoneNumber;
              this.form.email = res.email;
              this.updateDate("firstDate", res.firstDate);
              this.updateDate("lastDate", res.lastDate);
              this.updateDate("startDate", res.startDate);
              this.updateDate("endDate", res.endDate);
              this.form.hasIssue = res.hasIssue.toString();
              this.form.hasWarning = res.hasWarning.toString();
              this.form.hasImpact = res.hasImpact.toString();
              this.form.agencyBook = res.agencyBook;
              this.agencyBook = res.agencyBook;
              this.updateDate("bookDate", res.bookDate);
              this.form.resultFile = res.resultFile;
              this.resultFile = res.resultFile;
              this.agencyBookPath = res.agencyBookPath;
              this.resultFilePath = res.resultFilePath;
              this.form.bookNumber = res.bookNumber;
              this.form.summaryOfResult = res.summaryOfResult;
              this.form.signature = res.signature;

              this.form.accrualCeiling = res.accrualCeiling;
              this.form.totalParticipants = res.totalParticipants;
              this.form.totalScreening = res.totalScreening;
              this.form.totalWithdrawn = res.totalWithdrawn;
              this.form.totalDead = res.totalDead;
              this.form.totalCompleteResearch = res.totalCompleteResearch;
              this.form.totalExpAdversEvents = res.totalExpAdversEvents;
              this.form.totalExpSUSARs = res.totalExpSUSARs;
              this.form.anyProtocolFile = res.anyProtocolFile;
              this.form.anyComplaintFile = res.anyComplaintFile;
              this.form.totalGlobal = res.totalGlobal;
              this.anyComplaintFile = res.anyComplaintFile;
              this.anyComplaintFilePath = res.anyComplaintFilePath;
              this.form.anyProtocolFile = res.anyProtocolFile;
              this.anyProtocolFile = res.anyProtocolFile;
              this.anyProtocolFilePath = res.anyProtocolFilePath;
              this.fr = res.anyProtocolFilePath;

              this.updateDate("reportDate", res.reportDate);
            }
          }
          this.isLoading = false;
        })
        .catch((e) => {
          this.isLoading = false;
        });
    },
    save() {
      Swal.fire({
        title: "คุณต้องการบันทึกหรือไม่?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3E7662",
        cancelButtonColor: "rgb(189, 189, 189)",
        confirmButtonText: "บันทึก",
        cancelButtonText: "ยกเลิก",
      })
        .then((result) => {
          if (result.value) {
            this.form["researchID"] = this.$route.query.researchID;
            if (this.$route.query.rfID) {
              this.form["methods"] = "edit";
              this.form["rfID"] = this.$route.query.rfID;
            } else {
              this.form["methods"] = "save";
            }
            this.isLoading = true;
            this.$store
              .dispatch("FormRF/saveRF06", this.form)
              .then((res) => {
                this.isLoading = false;
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "บันทึกสำเร็จ",
                  showConfirmButton: false,
                  timer: 1500,
                }).then(() => {
                  this.$router.push("/profile");
                });
              })
              .catch((e) => {
                this.isLoading = false;
                if (e.response.data.errors) {
                  for (var k in e.response.data.errors) {
                    Swal.fire({
                      title: "เกิดข้อผิดพลาด",
                      text: e.response.data.errors[k][0],
                      icon: "warning",
                      confirmButtonColor: "#3E7662",
                    }).then(() => {
                      this.$vuetify.goTo(`#${k}`);
                    });
                  }
                }
                // if (e.response.data.errors.email[0] === 'The email must be a valid email address.') {
                //     Swal.fire({
                //         title: 'บันทึกไม่สำเร็จ',
                //         text: 'กรุณากรอก email ให้ถูกต้อง',
                //         icon: 'warning',
                //     }).then(() => {
                //         const firstScrollTo = scroller()
                //         firstScrollTo('#email')
                //         this.isLoading = false;
                //     })
                // }
              });
          } else {
            this.isLoading = false;
          }
        })
        .catch((e) => {
          this.isLoading = false;
        });
    },
    saveDraft() {
      Swal.fire({
        title: "คุณต้องการบันทึกแบบร่างหรือไม่?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3E7662",
        cancelButtonColor: "rgb(189, 189, 189)",
        confirmButtonText: "บันทึก",
        cancelButtonText: "ยกเลิก",
      })
        .then((result) => {
          if (result.value) {
            this.form["researchID"] = this.$route.query.researchID;
            if (this.$route.query.rfID) {
              this.form["methods"] = "edit";
              this.form["rfID"] = this.$route.query.rfID;
            } else {
              this.form["methods"] = "save";
              this.form["status"] = "บันทึกแบบร่าง";
            }
            this.isLoading = true;
            this.$store
              .dispatch("FormRF/saveRF06", this.form)
              .then((res) => {
                this.isLoading = false;
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "บันทึกแบบร่างสำเร็จ",
                  showConfirmButton: false,
                  timer: 1500,
                }).then(() => {
                  this.$router.push("/profile");
                });
              })
              .catch((e) => {
                this.isLoading = false;
                if (e.response.data.errors) {
                  for (var k in e.response.data.errors) {
                    Swal.fire({
                      title: "เกิดข้อผิดพลาด",
                      text: e.response.data.errors[k][0],
                      icon: "warning",
                      confirmButtonColor: "#3E7662",
                    }).then(() => {
                      this.$vuetify.goTo(`#${k}`);
                    });
                  }
                }
                // if (e.response.data.errors.email[0] === 'The email must be a valid email address.') {
                //     Swal.fire({
                //         title: 'บันทึกไม่สำเร็จ',
                //         text: 'กรุณากรอก email ให้ถูกต้อง',
                //         icon: 'warning',
                //     }).then(() => {
                //         const firstScrollTo = scroller()
                //         firstScrollTo('#email')
                //         this.isLoading = false;
                //     })
                // }
              });
          } else {
            this.isLoading = false;
          }
        })
        .catch((e) => {
          this.isLoading = false;
        });
    },
    onUpload(name, e) {
      this.$store
        .dispatch("Common/uploadPdf", { form: "rf06", file: e })
        .then((res) => {
          this.form[name] = res.fileName;
        })
        .catch((error) => { });
    },
    onUploadSummaryResult(e) {
      this.$store
        .dispatch("Common/uploadPdf", { form: "rf06", file: e })
        .then((res) => {
          this.form.resultFile = res.fileName;
        })
        .catch((error) => { });
    },
    onClickUpload(text) {
      document.getElementById(text).click();
    },
  },
};
</script>

<style scoped>
.link-hover:hover {
  cursor: pointer;
  text-decoration: underline;
}

.link-hover {
  color: #cc3d45;
  text-decoration: none;
}
</style>
