<template>
    <v-content>
        <v-layout pa-12 style="background-color: #FAF5F2">
            <v-flex xs12 pt-12 pl-12 style="text-align: left;">
                <v-layout>
                    <v-flex pt-12 pb-10>
                        <v-btn style="background-color: #3E7662;color: white" @click="$router.push('/flow-create')">
                            ขั้นตอนการเสนอโครงร่างการวิจัย
                        </v-btn>
                    </v-flex>
                </v-layout>
                <v-layout>
                    <v-flex style="font-size: 14px;line-height: 21px;cursor: pointer;" @click="$router.push('#')">
                        <span>กำหนดการส่งโครงร่างการวิจัยและเอกสารที่ต้องแนบ Gmail requirement</span>
                    </v-flex>
                </v-layout>
                <v-layout>
                    <v-flex style="font-size: 14px;line-height: 21px;cursor: pointer;" @click="$router.push('#')">
                        <span>กำหนดการประชุม</span>
                    </v-flex>
                </v-layout>
                <v-layout>
                    <v-flex style="font-size: 14px;line-height: 21px;cursor: pointer;" @click="$router.push('#')">
                        <span>กำหนดการอบรม</span>
                    </v-flex>
                </v-layout>
                <v-layout>
                    <v-flex style="font-size: 14px;line-height: 21px;cursor: pointer;" @click="$router.push('#')">
                        <span>การอุทธรณ์</span>
                    </v-flex>
                </v-layout>
                <v-layout>
                    <v-flex xs3 py-3 pt-12>
                        <v-divider style="background-color: #3E7662;border-width: 2px"></v-divider>
                    </v-flex>
                </v-layout>
            </v-flex>

            <v-flex xs12 pt-12 pl-12 style="text-align: left">
                <v-layout>
                    <v-flex
                        style="text-align: center;color: #3E7662;font-weight: bold;cursor: pointer; max-width: 500px; max-height:300px;">
                        <img v-if="banner.mainPicture" @click="readNews(banner.id)" :src="banner.mainPicture" alt="banner"
                            style="max-width: 520px;object-fit: cover" height="auto">
                    </v-flex>
                </v-layout>
                <v-layout>
                    <v-flex py-3 style="text-align: center;color: #3E7662;font-weight: bold;font-size: 18px">
                        <!--งานวิจัยที่เข้าข่ายการขอรับการรับรอง-->
                    </v-flex>
                </v-layout>
                <!--                <v-layout>-->
                <!--                    <v-flex style="text-align: center">-->
                <!--                        <v-btn tile color="#5FA88C" depressed min-width="10" class="px-2">-->
                <!--                            <v-icon color="#3E7662">chevron_left</v-icon>-->
                <!--                        </v-btn>-->
                <!--                        <v-btn tile color="#3E7662" depressed min-width="10" class="px-2">-->
                <!--                            <v-icon color="#5FA88C">chevron_right</v-icon>-->
                <!--                        </v-btn>-->
                <!--                    </v-flex>-->
                <!--                </v-layout>-->
            </v-flex>


        </v-layout>
        <v-layout px-12 pb-12 style="background-color: #FAF5F2">
            <v-flex px-12 style="font-weight: bold;font-size: 30px; text-align: left;">
                <span style="color: black">การพิจารณา</span> <span style="color: #3E7662">โครงร่างการวิจัย</span>
            </v-flex>
        </v-layout>
        <v-layout px-12 mt-n8 style="position: absolute;">
            <v-flex xs6>
                <v-layout mx-12 px-4 py-6 style="background-color: #3E7662;color: white;font-size: 20px;text-align: left">
                    <v-flex>
                        <span>
                            คณะอนุกรรมการพิจารณาโครงการวิจัย พบ. กำหนดประชุมพิจารณาโครงร่างการวิจัยประจำเดือน
                            <span style="color: #fff;font-weight: bold;">
                                {{ thisMonthMeeting[0] ? thisMonthMeeting[0].month : '' }}
                                {{ thisMonthMeeting[0] ? thisMonthMeeting[0].year : '' }}
                            </span>
                            ณ ห้องประชุม ระพี สาคริก ชั้น3 อาคารพระมงกุฎเกล้าเวชวิทยา วพม. เวลา 13.00 น. แบ่งเป็น
                            <span style="color: #fff;font-weight: bold;">
                                {{ thisMonthMeeting.length }} รอบ
                            </span>
                            ดังนี้
                        </span>
                    </v-flex>
                </v-layout>
                <v-layout mx-12 style="text-align:left;background-color: #544A44;color: white;font-size: 18px">
                    <v-flex>

                        <v-layout mt-4>
                            <v-flex>
                                <v-layout v-for="(item, i) in thisMonthMeeting" :key="i + '0'">
                                    <v-flex v-if="i <= 5">
                                        <span>รอบที่ {{ i + 1 }} {{ item.meetingDate }}</span>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                            <v-flex>
                                <v-layout v-for="(item, i) in thisMonthMeeting" :key="i + '0'">
                                    <v-flex v-if="i > 5 && i <= 12">
                                        <span>รอบที่ {{ i + 1 }} {{ item.meetingDate }}</span>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                        </v-layout>

                        <v-layout>
                            <v-flex xs3 pt-3>
                                <v-divider style="background-color: #3E7662;border-width: 2px"></v-divider>
                            </v-flex>
                        </v-layout>
                        <v-layout>
                            <v-flex pb-12 style="font-size: 20px; color: #3E7662;font-weight: bold;">
                                <span>
                                    ผลการพิจารณาโครงร่างการวิจัย ประจำเดือน
                                    {{ lastMonthMeeting[0] ? lastMonthMeeting[0].month : '' }}
                                    {{ lastMonthMeeting[0] ? lastMonthMeeting[0].year : '' }}
                                </span>
                            </v-flex>
                        </v-layout>

                        <div v-for="(item, i) in lastMonthMeeting" :key="i + '1'">
                            <v-layout>
                                <v-flex xs8>
                                    <span>รอบที่ {{ i + 1 }} {{ item.meetingDate }}</span>
                                </v-flex>
                                <v-flex xs4 style="text-align: right;">
                                    <span class="link-result" @click="openResult(item.pathResult)">
                                        <u>ผลการพิจารณา</u>
                                    </span>
                                </v-flex>
                            </v-layout>
                        </div>

                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex xs6>
                <v-layout class="text-end mr-12">
                    <v-flex>
                        <img src="../assets/meeting-banner.jpg" alt="" style="max-width: 520px;max-height: 400px"
                            width="100%" height="auto">
                    </v-flex>
                </v-layout>
                <v-layout class="text-end mr-12">
                    <v-flex pt-6>
                        <v-btn color="#3E7662" dark @click="$router.push('/all-result')">
                            <span>ผลการพิจารณาโครงร่างการวิจัย</span>
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
        <v-layout px-12 py-12 style="background-color: #544A44;height: 500px">
            <v-flex>
            </v-flex>
        </v-layout>

        <v-layout px-12 pt-12>
            <v-flex>
                <v-layout justify-center="">
                    <v-flex xs8 md10 sm12 px-12 style="text-align: left;color: #3E7662;font-size: 18px;font-weight: bold">
                        <p>
                            สำนักงานคณะอนุกรรมการพิจารณาโครงการวิจัย กรมแพทย์ทหารบก ได้มีการปรับเปลี่ยน SOP ฉบับ 7.0
                            และแบบฟอร์มต่างๆ
                            ใหม่ทั้งหมด
                            ซึ่งจะเริ่มนำแบบฟอร์มใหม่มาใช้ ตั้งแต่วันที่ 3 กรกฎาคม 2560 เป็นต้นไป โดยสามารถ Download
                            แบบฟอร์ม
                            ได้ที่เว็บไซต์ www.irbrta.pmk.ac.th หรือ www.amed.go.th/rtamed/irbrta
                        </p>
                        <span>จึงเรียนมาเพื่อโปรดทราบ</span>
                    </v-flex>
                </v-layout>
                <v-layout justify-center>
                    <v-flex xs3 pt-3 py-12 pt-12>
                        <v-divider style="background-color: #3E7662;border-width: 2px"></v-divider>
                    </v-flex>
                </v-layout>

                <v-layout pb-12 justify-center>
                    <v-flex xs8 md10 sm12>
                        <v-layout v-if="news1">
                            <v-flex xs6 px-12 style="text-align: left">
                                <v-layout>
                                    <v-flex>
                                        <v-layout>
                                            <v-flex mb-2 style="text-align: center">
                                                <a @click="readNews(news2.id)">

                                                    <v-img :src="news1.mainPicture" alt=""
                                                        style="max-width: 520px;max-height: 300px" width="100%"
                                                        height="auto" aspect-ratio="1.73" />
                                                </a>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout>
                                            <v-flex style="font-size: 18px;font-weight: bold;color: #3E7662">
                                                <a @click="readNews(news1.id)" style="color: #3E7662">{{ this.news1.topic
                                                }}</a>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout>
                                            <v-flex pt-3 style="font-size: 14px;color: #000000">
                                                <span>
                                                    {{ this.news1.shortDescription }}
                                                </span>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout>
                                            <v-flex pt-6 style="font-size: 12px;color: #544A44">
                                                <span>{{ this.news1.eventPlace }}</span>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                                <v-layout mt-6>
                                    <v-flex pt-6>
                                        <v-divider class="mt-4"
                                            style="background-color: #3E7662;border-width: 1px"></v-divider>
                                    </v-flex>
                                </v-layout>
                            </v-flex>

                            <v-flex xs6 px-12 style="text-align: left">
                                <v-layout>
                                    <v-flex>
                                        <v-layout>
                                            <v-flex mb-2 style="text-align: center">
                                                <a @click="readNews(news2.id)">

                                                    <v-img :src="news2.mainPicture" alt=""
                                                        style="max-width: 520px;max-height: 300px" width="100%"
                                                        height="auto" aspect-ratio="1.73" />
                                                </a>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout>
                                            <v-flex style="font-size: 18px;font-weight: bold;color: #3E7662">
                                                <a @click="readNews(news2.id)" style="color: #3E7662" class="one-line">{{
                                                    this.news2.topic }}</a>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout>
                                            <v-flex pt-3 style="font-size: 14px;color: #000000">
                                                <span class="two-line">
                                                    {{ this.news2.shortDescription }}
                                                </span>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout>
                                            <v-flex pt-6 style="font-size: 12px;color: #544A44" class="one-line">
                                                <span>{{ this.news2.eventPlace }}</span>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                                <v-layout mt-6>
                                    <v-flex pt-6>
                                        <v-divider class="mt-4"
                                            style="background-color: #3E7662;border-width: 1px"></v-divider>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                        </v-layout>

                        <v-layout mt-4 pr-12 style="text-align: right">
                            <v-flex>
                                <v-btn dark color="#3E7662" class="px-12" @click="$router.push('/all-news')">
                                    <span>ข่าวภาพและกิจกรรม</span>
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>

        <v-layout px-12 style="background-color: #FAF5F2">
            <v-flex xs12 pa-12 style="text-align: left">
                <v-layout>
                    <v-flex style="font-size: 30px;font-weight: bold">
                        ดาวน์โหลดแบบฟอร์ม
                    </v-flex>
                </v-layout>
                <v-layout>
                    <v-flex pb-4 style="font-size: 20px;color: #3E7662;font-weight: bold">
                        การส่งโครงร่างการวิจัยเพื่อพิจารณาครั้งแรก (Initial Submission)
                    </v-flex>
                </v-layout>
                <v-layout pb-8>
                    <v-flex style="font-size: 16px">
                        เอกสารประกอบการยื่นขอรับการพิจารณา (ใช้ Font TH Sarabun PSK 14)
                    </v-flex>
                </v-layout>

                <v-layout justify-center>
                    <v-flex xs8 md10 sm12>
                        <v-layout>
                            <v-flex xs10 class="d-flex align-center">
                                RF 01_2563 แบบรายงานการส่งโครงร่างการวิจัยเพื่อพิจารณา
                            </v-flex>
                            <v-flex xs2 style="text-align: right">
                                <v-btn color="#3E7662" dark @click="downloadForm('rf01')">
                                    <v-icon class="pr-3">cloud_download</v-icon>
                                    <span>Download</span>
                                </v-btn>
                            </v-flex>
                        </v-layout>
                        <v-layout>
                            <v-flex py-3>
                                <v-divider style="background-color: white"></v-divider>
                            </v-flex>
                        </v-layout>
                        <v-layout>
                            <v-flex xs10 class="d-flex align-center">
                                RF 08_1_2563 คู่มือการเขียนโครงร่างการวิจัย
                            </v-flex>
                            <v-flex xs2 style="text-align: right">
                                <v-btn color="#3E7662" dark @click="downloadForm('rf08')">
                                    <v-icon class="pr-3">cloud_download</v-icon>
                                    <span>Download</span>
                                </v-btn>
                            </v-flex>
                        </v-layout>
                        <v-layout>
                            <v-flex py-3>
                                <v-divider style="background-color: white"></v-divider>
                            </v-flex>
                        </v-layout>
                        <v-layout>
                            <v-flex xs10 class="d-flex align-center">
                                RF 09_1_2563 คู่มือการเขียนเอกสารชี้แจงข้อมูลและหนังสือแสดงเจตนายินยอม
                            </v-flex>
                            <v-flex xs2 style="text-align: right">
                                <v-btn color="#3E7662" dark @click="downloadForm('rf09_1')">
                                    <v-icon class="pr-3">cloud_download</v-icon>
                                    <span>Download</span>
                                </v-btn>
                            </v-flex>
                        </v-layout>
                        <v-layout>
                            <v-flex py-3>
                                <v-divider style="background-color: white"></v-divider>
                            </v-flex>
                        </v-layout>
                        <v-layout>
                            <v-flex xs10 class="d-flex align-center">
                                RF 10_2563 แบบประวัติผู้วิจัย
                            </v-flex>
                            <v-flex xs2 style="text-align: right">
                                <v-btn color="#3E7662" dark @click="downloadForm('rf10')">
                                    <v-icon class="pr-3">cloud_download</v-icon>
                                    <span>Download</span>
                                </v-btn>
                            </v-flex>
                        </v-layout>
                        <v-layout>
                            <v-flex py-3>
                                <v-divider style="background-color: white"></v-divider>
                            </v-flex>
                        </v-layout>
                        <v-layout>
                            <v-flex xs10 class="d-flex align-center">
                                RO 01_1_2563 หนังสือข้อตกลงรักษาความลับของข้อมูล
                            </v-flex>
                            <v-flex xs2 style="text-align: right">
                                <v-btn color="#3E7662" dark @click="downloadForm('ro01_1')">
                                    <v-icon class="pr-3">cloud_download</v-icon>
                                    <span>Download</span>
                                </v-btn>
                            </v-flex>
                        </v-layout>
                        <v-layout>
                            <v-flex py-3 style="text-align: right">
                                <v-divider style="background-color: white"></v-divider>
                            </v-flex>
                        </v-layout>
                        <v-layout>
                            <v-flex xs10 class="d-flex align-center">
                                RO 01_2_2563 หนังสือข้อตกลงในการดำเนินการวิจัย
                            </v-flex>
                            <v-flex xs2 style="text-align: right">
                                <v-btn color="#3E7662" dark @click="downloadForm('ro01_2')">
                                    <v-icon class="pr-3">cloud_download</v-icon>
                                    <span>Download</span>
                                </v-btn>
                            </v-flex>
                        </v-layout>
                        <v-layout>
                            <v-flex py-3>
                                <v-divider style="background-color: white"></v-divider>
                            </v-flex>
                        </v-layout>
                        <v-layout>
                            <v-flex xs10 class="d-flex align-center">
                                บันทึกข้อความจากกองหรือหน่วย จำนวน 1 ชุด (ตัวอย่าง)
                            </v-flex>
                            <v-flex xs2 style="text-align: right">
                                <v-btn color="#3E7662" dark @click="downloadForm('บันทึกข้อความจากกองหรือหน่วย')">
                                    <v-icon class="pr-3">cloud_download</v-icon>
                                    <span>Download</span>
                                </v-btn>
                            </v-flex>
                        </v-layout>
                        <v-layout>
                            <v-flex py-3 style="text-align: right">
                                <v-divider style="background-color: white"></v-divider>
                            </v-flex>
                        </v-layout>

                        <v-layout>
                            <v-flex py-3 style="text-align: right;color: #3E7662;font-weight: bold;cursor: pointer">
                                <span @click="downloadForm('orther')">ดาวน์โหลดแบบฟอร์มอื่นๆ...</span>
                            </v-flex>
                        </v-layout>
                        <v-layout>
                            <v-flex py-3>
                                <v-divider style="background-color: #3E7662; border-width: 1px"></v-divider>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>

        <loading color="#527281" :active.sync="isLoading" :can-cancel="false" :is-full-page="fullPage"></loading>

    </v-content>
</template>

<script>
import Loading from 'vue-loading-overlay';

export default {
    name: 'Homepage',
    components: {
        Loading
    },
    data: () => ({
        isLoading: false,
        fullPage: true,
        news1: '',
        news2: '',
        lastMonthMeeting: [],
        thisMonthMeeting: [],
        banner: [],
    }),
    created() {
        this.getNews();
        this.getAllNews();
        this.getLastMonthMeeting();
        this.getThisMonthMeeting();
    },
    methods: {
        downloadForm(name) {
            switch (name) {
                case 'rf01': window.open('https://service.irbrta-research.com/storage/แบบฟอร์มดาวน์โหลดทั้งหมด/RF 01_2563 แบบรายงานการส่งโครงร่างการวิจัยเพื่อพิจารณา.doc');
                    break;
                case 'rf02': window.open('https://service.irbrta-research.com/storage/แบบฟอร์มดาวน์โหลดทั้งหมด/RF 02_2563 แบบรายงานการแก้ไขโครงร่างการวิจัย.doc');
                    break;
                case 'rf03_1': window.open('https://service.irbrta-research.com/storage/แบบฟอร์มดาวน์โหลดทั้งหมด/RF 03_2563 แบบรายงานความก้าวหน้าของการวิจัย.doc');
                    break;
                case 'rf04_1': window.open('https://service.irbrta-research.com/storage/แบบฟอร์มดาวน์โหลดทั้งหมด/RF 04_1_2563 แบบรายงานเหตุการณ์ไม่พึงประสงค์จากยาเหตุการณ์ไม่คาดคิด.doc');
                    break;
                case 'rf04_2': window.open('https://service.irbrta-research.com/storage/แบบฟอร์มดาวน์โหลดทั้งหมด/RF 04_2_2563 แบบรายงานเหตุการณ์ไม่พึงประสงค์ (นอกเหนือจากยา).doc');
                    break;
                case 'rf05': window.open('https://service.irbrta-research.com/storage/แบบฟอร์มดาวน์โหลดทั้งหมด/RF 05_2563 แบบรายงานการไม่ปฏิบัติตามข้อกำหนด.doc');
                    break;
                case 'rf06': window.open('https://service.irbrta-research.com/storage/แบบฟอร์มดาวน์โหลดทั้งหมด/RF 06_2563 แบบรายงานแจ้งปิดสรุปผลการวิจัย.doc');
                    break;
                case 'rf07': window.open('https://service.irbrta-research.com/storage/แบบฟอร์มดาวน์โหลดทั้งหมด/RF 07_2563 แบบรายงานการยุติโครงการวิจัยก่อนกำหนด.doc');
                    break;
                case 'rf08': window.open('https://service.irbrta-research.com/storage/แบบฟอร์มดาวน์โหลดทั้งหมด/RF 08_1_2563 คู่มือการเขียนโครงร่างการวิจัย.doc');
                    break;
                case 'rf09_1': window.open('https://service.irbrta-research.com/storage/แบบฟอร์มดาวน์โหลดทั้งหมด/RF 09_1_2563 คู่มือการเขียนเอกสารชี้แจงข้อมูลและหนังสือแสดงเจตนายินยอม.doc');
                    break;
                case 'rf09_2': window.open('https://service.irbrta-research.com/storage/แบบฟอร์มดาวน์โหลดทั้งหมด/RF 09_2_2563 เอกสารชี้แจงข้อมูลสำหรับการรายงานกรณีศึกษา.doc');
                    break;
                case 'rf09_3': window.open('https://service.irbrta-research.com/storage/แบบฟอร์มดาวน์โหลดทั้งหมด/RF 09_3_2563 เอกสารชี้แจงข้อมูลแก่อาสาสมัครในโครงการวิจัยเกี่ยวกับมนุษยพันธุ์ศาสตร์.doc');
                    break;
                case 'rf10': window.open('https://service.irbrta-research.com/storage/แบบฟอร์มดาวน์โหลดทั้งหมด/RF 10_2563 แบบประวัติผู้วิจัย.doc');
                    break;
                case 'rf11': window.open('https://service.irbrta-research.com/storage/แบบฟอร์มดาวน์โหลดทั้งหมด/RF 11_2563 คำแนะนำการส่งโครงร่างการวิจัยเพื่อพิจารณา.pdf');
                    break;
                case 'rf12': window.open('https://service.irbrta-research.com/storage/แบบฟอร์มดาวน์โหลดทั้งหมด/RF 12_2563 แบบรายงานการแก้ไขเพิ่มเติมโครงร่างการวิจัย.doc');
                    break;
                case 'ro01_1': window.open('https://service.irbrta-research.com/storage/แบบฟอร์มดาวน์โหลดทั้งหมด/RO 01_1_2563 หนังสือข้อตกลงรักษาความลับของข้อมูล.doc');
                    break;
                case 'ro01_2': window.open('https://service.irbrta-research.com/storage/แบบฟอร์มดาวน์โหลดทั้งหมด/RO 01_2_2563 หนังสือข้อตกลงในการดำเนินการวิจัย.doc');
                    break;
                case 'ro13': window.open('https://service.irbrta-research.com/storage/แบบฟอร์มดาวน์โหลดทั้งหมด/RO 13_2563 แบบรายงานขอค้นเอกสารโครงการวิจัย.doc');
                    break;
                case 'ro16': window.open('https://service.irbrta-research.com/storage/แบบฟอร์มดาวน์โหลดทั้งหมด/RO 16_2563 แบบบันทึกการร้องเรียน.doc');
                    break;
                case 'บันทึกข้อความจากกองหรือหน่วย': window.open('https://service.irbrta-research.com/storage/สำหรับแพทย์, พยาบาล, นักศึกษา,สำหรับบริษัทยา/ตัวอย่างบันทึกข้อความ letter.doc');
                    break;
                case 'orther': window.open('http://www.irbrta.pmk.ac.th/index.php/from-download/entire-form');
                    break;
            }
        },
        getNews() {
            this.loading = true;
            this.$store.dispatch('Common/getNews').then(res => {
                if (res.length >= 2) {
                    this.news1 = res[0];
                    this.news2 = res[1];
                } else {
                    this.news1 = res[0];
                }
            })
            this.loading = false;
        },
        getAllNews(page) {
            this.loading = true;
            this.$store.dispatch('Common/getAllNews', page).then(res => {
                var item = res.data[Math.floor(Math.random() * res.data.length)];
                this.banner = item;
            });
            this.loading = false;
        },
        getLastMonthMeeting() {
            this.loading = true;
            this.$store.dispatch('Common/getLastMonthMeeting').then(res => {
                this.lastMonthMeeting = res;
            });
            this.loading = false;
        },
        getThisMonthMeeting() {
            this.loading = true;
            this.$store.dispatch('Common/getThisMonthMeeting').then(res => {
                this.thisMonthMeeting = res;
            });
            this.loading = false;
        },
        readNews(id) {
            this.$router.push({ name: 'ReadNews', query: { news_id: id } })
        },
        openResult(val) {
            window.open(val)
        }
    }
}
</script>

<style lang="scss">
.contect-hompage {
    background-color: #ffffff;
    padding-left: 150px;
    padding-right: 150px;
    padding-top: 100px;
    padding-bottom: 100px
}

.link-result {
    cursor: pointer;
}

.link-result:hover {
    color: #42b983;
}
</style>












