import Service from '../Service'
import AuthService from '../AuthService'


const Profile = {
    getResearch(page) {
        return AuthService.get('/api/show-paginate-research?page=' + page)
    },
    getAllResearchPaginate() {
        return AuthService.get('/api/show-all-research')
    },
    // getAllResearchPaginate(page) {
    //     return AuthService.get('/api/show-paginate-all-research?page='+page)
    // },
    getExpireDate(id) {
        return AuthService.get(`/api/calculate-expire-date/${id}`)
    },
    cancelResearch(data) {
        return AuthService.post('/api/cancel-research', {
            id: data.id,
            pdf: data.pdf
        })
    }
}

export default Profile;
