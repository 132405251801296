<template>
    <v-content>
       <v-layout pa-12 class="body">
           <v-flex>
               <v-layout pb-12
                         style="font-weight: bolder;
                        color: #3E7662">
                   <v-flex>
                        <span>แบบทดสอบ</span>
                   </v-flex>
               </v-layout>

                <v-card class="mx-12">
                    <v-layout py-5 style="background-color: #3E7662;font-weight: bold;color: white;font-size: 26px">
                        <v-flex xs2>
                            <span>บทที่</span>
                        </v-flex>
                        <v-flex xs4>
                            <span>เรื่อง</span>
                        </v-flex>
                        <v-flex xs2>
                            <span>จำนวนข้อ</span>
                        </v-flex>
                        <v-flex xs2>
                            <span>คะแนน</span>
                        </v-flex>
                        <v-flex xs2>
                        </v-flex>
                    </v-layout>

                    <v-layout v-for="(item,i) in exam" :key="i" py-5 :style="{'background': i %2 === 0 ? '#ffffff' : '#FAF5F2'}" style="color: #544A44; font-size: 18px; font-weight: bold">
                        <v-flex xs2>
                            <span>บทที่ {{item.chapter_id}}</span>
                        </v-flex>
                        <v-flex xs4 >
                            <a :href='item.link' target="_blank" style="color: #544A44">{{item.chapter}}</a>
                        </v-flex>
                        <v-flex xs2>
                            <span>{{item.questionNumber}}</span>
                        </v-flex>
                        <v-flex xs2 style="font-size: 26px;color: #3E7662">
                            <span v-if="point">{{point[i]}}%</span>
                        </v-flex>
                        <v-flex xs2 pr-2>
                            <v-btn color="#3E7662" dark @click="getExam(item.chapter_id)">
                                <span>ทำแบบทดสอบ</span>
                                <v-icon small>edit</v-icon>
                            </v-btn>
                        </v-flex>
                    </v-layout>

                    <v-layout justify-space-between py-12 mx-12 style="background-color: white;color: #3E7662;font-weight: bold">
                        <v-flex>
                            <v-layout>
                                <v-flex class="text-start" ml-12 mr-6 shrink style="font-size: 26px;">
                                    <span>ผลคะแนนรวม </span>
                                </v-flex>
                                <v-flex shrink class="text-start" style="font-size: 26px;">
                                    <input style="width: 120px;text-align: center" readonly :value=totalPoint> 
                                </v-flex>
                                <v-flex shrink class="text-start" ml-6> % </v-flex>
                            </v-layout>
                        </v-flex>
                        <v-flex>
                            <v-layout>
                                <v-flex mr-12 class="text-end" style="font-size: 16px">
                                    <v-btn color="#3E7662" :disabled="(totalPoint < 80 || !isPass) || (certificateStatus == 1 && !isExpired)" :dark="totalPoint >= 80 && isPass" @click="makeCertificate()">
                                        submit ผลคะแนน
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                </v-card>


           </v-flex>
       </v-layout>

        <loading
                color="#527281"
                :active.sync="isLoading"
                :can-cancel="false"
                :is-full-page="fullPage"
        ></loading>

    </v-content>
</template>

<script>
    import Swal from 'sweetalert2';
    import { mapState, mapGetters } from "vuex";
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';

    export default {
        name: "test-exam",
        components: {
            Loading
        },
        data:() => ({
            isLoading: false,
            fullPage: true,
            point: null,
            totalPoint: null,
            today:"",
            isPass:true,
            certificateName:"",
            certificateStatus:0,
            isExpired: false,
            certificate_create_at: "",
            remaining_date: "0",
            certificatePath:""
        }),
        computed: {
            ...mapState({
                exam: state => state.Exam.exam
            })

        },
        created() {
            this.getChapter();
            this.getPoint();
            // this.getToday();
            this.getUser();
            this.getCertificate();
        },
        methods: {
            getChapter() {
                this.isLoading = true;
                this.$store.dispatch('Exam/getChapter');
                    this.isLoading = false;
            },
            getExam(id){
                this.$router.push({name:'ChapterExam', query:{chapter_id: id}})
            },
            getPoint(){
                this.isLoading = true;
                this.$store.dispatch('Exam/getPoint').then(res=>{
                    this.point = (res.chapterPoint);
                    this.totalPoint=(res.totalPointPercent).toFixed(2);
                    // this.point.forEach(item => {
                    //     if(item < 75)
                    //         this.isPass = true;
                    // })
                    this.isLoading = false;
                })
            },
            makeCertificate(){
                this.isLoading = true;
                let certificateData = {
                    certificate_create_at: new Date().toISOString().slice(0, 10)
                }
                this.$store.dispatch('Exam/makeCertificate',certificateData).then(res =>{
                    // window.open(res);
                    // location.reload();
                    this.isLoading = false;
                    this.$router.push('/profile');
                })
            },
            getToday(){
                this.isLoading = true;
                this.$store.dispatch('Exam/getToday').then(res =>{
                    this.today = res;
                    this.isLoading = false;

                })

            },
            getUser(){
                this.isLoading = true;
                this.$store.dispatch('Auth/getUser').then(res=>{
                    this.certificateStatus = res.certificateStatus;
                    this.certificate_create_at =  res.certificate_create_at;
                    let certificate_expire_at = new Date(res.certificate_expire_at)
                    let now = new Date()
                    console.log(certificate_expire_at, now, certificate_expire_at < now)
                    if(new Date(res.certificate_expire_at) < new Date())
                      this.isExpired = true
                    this.certificateName = res.certificate;
                })
            },
            getCertificate(){
                this.isLoading = true;
                this.$store.dispatch('Exam/getCertificate').then(res =>{
                    this.certificatePath = res.certificate_path;
                    this.remaining_date = res.remaining_date;
                    this.isLoading = false;
                })
            },
            generateCertificate(){
                this.isLoading = true;
                window.open(this.certificatePath);
                this.isLoading = false;
            }
        },
    }
</script>

<style scoped>
    .body{
        background: #FAF5F2;
        color:white;
        font-size: 36px;
        font-weight: normal;
        text-align: center;
        line-height: 54px;
    }
    input{
        background: #EEEEEE;
        width: 80%;
        display: inline-block;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
    }
</style>
