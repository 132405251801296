var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-content',[_c('v-layout',{staticStyle:{"background-color":"#3e7662"},attrs:{"py-4":""}},[_c('v-flex',{staticClass:"font-weight-bold",attrs:{"ml-6":""}},[_c('span',{staticStyle:{"color":"white","font-size":"24px"}},[_vm._v(_vm._s(_vm.nameTable))])])],1),_c('v-layout',[_c('v-flex',[_c('v-divider',{staticStyle:{"background-color":"#544a44","border-width":"3px"}})],1)],1),_c('v-layout',[_c('v-flex',{staticClass:"table"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","items-per-page":9999999999},scopedSlots:_vm._u([(_vm.onEdit)?{key:"item",fn:function(ref){
var item = ref.item;
return [(item.edit === 'RF12')?_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.number))]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(item.nameResearch))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.groupID))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.version))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.status))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.no))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.created_at))]),_c('td',{staticClass:"text-center"},[_c('v-icon',{staticStyle:{"color":"#3e7662","cursor":"pointer"},attrs:{"color":"#3E7662","disabled":item.isOld === 1 ||
                  item.status === 'อนุมัติ' ||
                  item.status === 'อยู่ระหว่างการพิจารณา' ||
                  item.status === 'ผ่านการพิจารณา'},on:{"click":function($event){return _vm.$router.push({
                    name: 'FormRF12',
                    query: {
                      rfID: item.id,
                      researchID: item.item.research[0].id,
                      status: item.item.research[0].status,
                      researchCode: item.item.research[0].researchCode,
                      thName: item.item.research[0].user.thName,
                      mobilePhone: item.item.research[0].user.mobilePhone,
                      affiliation: item.item.research[0].user.affiliation,
                      nameResearch: item.item.research[0].thResearchName,
                    },
                  })}}},[_vm._v(" edit ")])],1)]):(item.edit === 'RF04_1')?_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.number))]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(item.nameResearch))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.groupID))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.version))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.status))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.no))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.created_at))]),_c('td',{staticClass:"text-center"},[_c('v-icon',{staticStyle:{"color":"#3e7662","cursor":"pointer"},attrs:{"color":"#3E7662","disabled":item.isOld === 1 ||
                  item.status === 'อนุมัติ' ||
                  item.status === 'อยู่ระหว่างการพิจารณา' ||
                  item.status === 'ผ่านการพิจารณา'},on:{"click":function($event){return _vm.$router.push({
                    name: 'FormRF04_1',
                    query: {
                      rfID: item.id,
                      researchID: item.item.research[0].id,
                      status: item.item.research[0].status,
                      researchCode: item.item.research[0].researchCode,
                      thName: item.item.research[0].user.thName,
                      mobilePhone: item.item.research[0].user.mobilePhone,
                      affiliation: item.item.research[0].user.affiliation,
                      nameResearch: item.item.research[0].thResearchName,
                    },
                  })}}},[_vm._v(" edit ")])],1)]):(item.edit === 'RF04_2')?_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.number))]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(item.nameResearch))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.groupID))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.version))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.status))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.no))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.created_at))]),_c('td',{staticClass:"text-center"},[_c('v-icon',{staticStyle:{"color":"#3e7662","cursor":"pointer"},attrs:{"color":"#3E7662","disabled":item.isOld === 1 ||
                  item.status === 'อนุมัติ' ||
                  item.status === 'อยู่ระหว่างการพิจารณา' ||
                  item.status === 'ผ่านการพิจารณา'},on:{"click":function($event){return _vm.$router.push({
                    name: 'FormRF04_2',
                    query: {
                      rfID: item.id,
                      researchID: item.item.research[0].id,
                      status: item.item.research[0].status,
                      researchCode: item.item.research[0].researchCode,
                      thName: item.item.research[0].user.thName,
                      mobilePhone: item.item.research[0].user.mobilePhone,
                      affiliation: item.item.research[0].user.affiliation,
                      nameResearch: item.item.research[0].thResearchName,
                    },
                  })}}},[_vm._v(" edit ")])],1)]):(item.edit === 'RF05')?_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.number))]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(item.nameResearch))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.groupID))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.version))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.status))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.no))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.created_at))]),_c('td',{staticClass:"text-center"},[_c('v-icon',{staticStyle:{"color":"#3e7662","cursor":"pointer"},attrs:{"color":"#3E7662","disabled":item.isOld === 1 ||
                  item.status === 'อนุมัติ' ||
                  item.status === 'อยู่ระหว่างการพิจารณา' ||
                  item.status === 'ผ่านการพิจารณา'},on:{"click":function($event){return _vm.$router.push({
                    name: 'FormRF05',
                    query: {
                      rfID: item.id,
                      researchID: item.item.research[0].id,
                      status: item.item.research[0].status,
                      researchCode: item.item.research[0].researchCode,
                      thName: item.item.research[0].user.thName,
                      mobilePhone: item.item.research[0].user.mobilePhone,
                      affiliation: item.item.research[0].user.affiliation,
                      nameResearch: item.item.research[0].thResearchName,
                    },
                  })}}},[_vm._v(" edit ")])],1)]):(item.edit === 'RF03')?_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.number))]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(item.nameResearch))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.groupID))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.version))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.status))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.no))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.created_at))]),_c('td',{staticClass:"text-center"},[_c('v-icon',{staticStyle:{"color":"#3e7662","cursor":"pointer"},attrs:{"color":"#3E7662","disabled":item.isOld === 1 ||
                  item.status === 'อนุมัติ' ||
                  item.status === 'อยู่ระหว่างการพิจารณา' ||
                  item.status === 'ผ่านการพิจารณา'},on:{"click":function($event){return _vm.$router.push({
                    name: 'FormRF03',
                    query: {
                      rfID: item.id,
                      researchID: item.item.research[0].id,
                      status: item.item.research[0].status,
                      researchCode: item.item.research[0].researchCode,
                      thName: item.item.research[0].user.thName,
                      mobilePhone: item.item.research[0].user.mobilePhone,
                      affiliation: item.item.research[0].user.affiliation,
                      nameResearch: item.item.research[0].thResearchName,
                    },
                  })}}},[_vm._v(" edit ")])],1)]):(item.edit === 'RF06')?_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.number))]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(item.nameResearch))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.groupID))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.version))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.status))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.no))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.created_at))]),_c('td',{staticClass:"text-center"},[_c('v-icon',{staticStyle:{"color":"#3e7662","cursor":"pointer"},attrs:{"color":"#3E7662","disabled":item.isOld === 1 ||
                  item.status === 'อนุมัติ' ||
                  item.status === 'อยู่ระหว่างการพิจารณา' ||
                  item.status === 'ผ่านการพิจารณา'},on:{"click":function($event){return _vm.$router.push({
                    name: 'FormRF06',
                    query: {
                      rfID: item.id,
                      researchID: item.item.research[0].id,
                      status: item.item.research[0].status,
                      researchCode: item.item.research[0].researchCode,
                      thName: item.item.research[0].user.thName,
                      budgetResource: item.item.research[0].budgetResource,
                      nameResearch: item.item.research[0].thResearchName,
                      mobilePhone: item.item.research[0].user.mobilePhone,
                      email: item.item.research[0].user.email,
                    },
                  })}}},[_vm._v(" edit ")])],1)]):(item.edit === 'RF07')?_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.number))]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(item.nameResearch))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.groupID))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.version))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.status))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.no))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.created_at))]),_c('td',{staticClass:"text-center"},[_c('v-icon',{staticStyle:{"color":"#3e7662","cursor":"pointer"},attrs:{"color":"#3E7662","disabled":item.isOld === 1 ||
                  item.status === 'อนุมัติ' ||
                  item.status === 'อยู่ระหว่างการพิจารณา' ||
                  item.status === 'ผ่านการพิจารณา'},on:{"click":function($event){return _vm.$router.push({
                    name: 'FormRF07',
                    query: {
                      rfID: item.id,
                      researchID: item.item.research[0].id,
                      status: item.item.research[0].status,
                      researchCode: item.item.research[0].researchCode,
                      thName: item.item.research[0].user.thName,
                      budgetResource: item.item.research[0].budgetResource,
                      nameResearch: item.item.research[0].thResearchName,
                      mobilePhone: item.item.research[0].user.mobilePhone,
                      email: item.item.research[0].user.email,
                    },
                  })}}},[_vm._v(" edit ")])],1)]):(item.edit === 'Corres')?_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.number))]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(item.corresName))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.groupID))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.status))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.no))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.created_at))]),_c('td',{staticClass:"text-center"},[_c('v-icon',{staticStyle:{"color":"#3e7662","cursor":"pointer"},attrs:{"color":"#3E7662"},on:{"click":function($event){return _vm.viewCorresFile(item.item)}}},[_vm._v(" visibility ")])],1)]):(item.edit === 'Susar')?_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.number))]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(item.nameResearch))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.groupID))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.version))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.status))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.no))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.created_at))]),_c('td',{staticClass:"text-center"},[_c('v-icon',{staticStyle:{"color":"#3e7662","cursor":"pointer"},attrs:{"color":"#3E7662","disabled":item.isOld === 1 ||
                  item.status === 'อนุมัติ' ||
                  item.status === 'อยู่ระหว่างการพิจารณา' ||
                  item.status === 'ผ่านการพิจารณา'},on:{"click":function($event){return _vm.$router.push({
                    name: 'FormSusar',
                    query: {
                      rfID: item.id,
                      researchID: item.item.researchID,
                      status: item.item.status,
                      researchCode: item.item.researchCode,
                      firstName: item.item.firstName,
                      lastName: item.item.lastName,
                      nameResearch: item.item.researchName,
                      startReport: item.item.startReport,
                      hasUnexpectedEvents: item.item.hasUnexpectedEvents,
                      hasDDLetter: item.item.hasDDLetter,
                      hasSafetyReport: item.item.hasSafetyReport,
                      hasMoreReport: item.item.hasMoreReport,
                      thName: item.item.signature
                    },
                  })}}},[_vm._v(" edit ")])],1)]):_vm._e()]}}:(_vm.nameTable === 'งานวิจัยทั้งหมด')?{key:"item",fn:function(ref){
                  var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.number))]),_c('td',{staticClass:"text-start",staticStyle:{"word-break":"break-all"}},[_vm._v(" "+_vm._s(item.researchName)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.status))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.version))])])]}}:(_vm.nameTable === 'ผู้เข้าร่วมวิจัย/ที่ปรึกษา')?{key:"item",fn:function(ref){
                  var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.number))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.name))]),_c('td',{staticClass:"text-left",staticStyle:{"word-break":"break-all"}},[_vm._v(" "+_vm._s(item.research)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.role))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.status))])])]}}:null],null,true)})],1)],1),_c('v-layout',[_c('v-flex',[_c('v-divider',{staticStyle:{"background-color":"#544a44","border-width":"3px"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }