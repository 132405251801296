import Vue from 'vue';
import Router from 'vue-router';

import Mainlayout from './views/Mainlayout';
import Homepage from './views/Homepage';
import FlowCreate from "./views/FlowCreate";
import ContactUs from "./views/ContactUs";
import DownloadForm from "./views/DownloadForm";
import SOPStandard from "./views/SOPStandard";
import Login from "./views/Login";
import ForgetPassword from "./views/ForgetPassword";
import ResetPassword from "./views/ResetPassword";
import Register from "./views/Register";
import ManualResearch from "./views/ManualResearch";
import FormCreateResearch from "./views/FormCreateResearch";
import Profile from "./views/Profile";
import FormRF02 from "./views/document/FormRF02";
import FormRF06 from "./views/document/FormRF06";
import FormRF03 from "./views/document/FormRF03";
import FormRF12 from "./views/document/FormRF12";
import FormRF04_1 from "./views/document/FormRF04_1";
import FormRF04_2 from "./views/document/FormRF04_2";
import FormRF05 from "./views/document/FormRF05";
import FormRF07 from "./views/document/FormRF07";
import TestExam from "./views/TestExam";
import ChapterExam from "./views/ChapterExam";
import FormResearch1 from "./views/formCreateResearch/FormResearch1";
import FormResearch2 from "./views/formCreateResearch/FormResearch2";
import FormResearch3 from "./views/formCreateResearch/FormResearch3";
import FormResearch4 from "./views/formCreateResearch/FormResearch4";
import ReadNews from "./views/ReadNews";
import AllNews from "./views/AllNews";
import AllResult from "./views/AllResult";
import HistoryResearch from "./views/HistoryResearch";
import Verify from "./views/Verify";
import AboutIRBRTA from "./views/AboutIRBRTA";
import VueRouter from 'vue-router'
import store from "./store"
import Vision from "./views/Vision";
import CoreValue from "./views/CoreValue";
import FormSusar from "./views/document/FormSUSAR";

Vue.use(Router);
Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Mainlayout',
        component: Mainlayout,
        children: [
            {
                path: '/',
                name: 'Homepage',
                component: Homepage,
            },
            {
                path: '/about-irbrta',
                name: 'AboutIRBRTA',
                component: AboutIRBRTA,
            },
            {
                path: '/vision',
                name: 'Vision',
                component: Vision,
            },
            {
                path: '/core-value',
                name: 'CoreValue',
                component: CoreValue,
            },
            {
                path: '/flow-create',
                name: 'FlowCreate',
                component: FlowCreate,
            },
            {
                path: '/contact-us',
                name: 'ContactUs',
                component: ContactUs,
            },
            {
                path: '/download-form',
                name: 'DownloadForm',
                component: DownloadForm,
            },
            {
                path: '/sop-standard',
                name: 'SOPStandard',
                component: SOPStandard,
            },
            {
                path: '/register',
                name: 'Register',
                component: Register,
            },
            {
                path: '/manual-research',
                name: 'ManualResearch',
                component: ManualResearch,
            },
            {
                path: '/form-create-research',
                name: 'FormCreateResearch',
                component: FormCreateResearch,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: '/profile',
                name: 'Profile',
                component: Profile,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: '/form-rf-02',
                name: 'FormRF02',
                component: FormRF02,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: '/form-rf-06',
                name: 'FormRF06',
                component: FormRF06,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: '/form-rf-03',
                name: 'FormRF03',
                component: FormRF03,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: '/form-rf-12',
                name: 'FormRF12',
                component: FormRF12,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: '/form-rf-04-1',
                name: 'FormRF04_1',
                component: FormRF04_1,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: '/form-rf-04-2',
                name: 'FormRF04_2',
                component: FormRF04_2,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: '/form-rf-05',
                name: 'FormRF05',
                component: FormRF05,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: '/form-rf-07',
                name: 'FormRF07',
                component: FormRF07,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: '/form-susar',
                name: 'FormSusar',
                component: FormSusar,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: '/test-exam',
                name: 'TestExam',
                component: TestExam,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: '/chapter-exam',
                name: 'ChapterExam',
                component: ChapterExam,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: '/form-research-1',
                name: 'FormResearch1',
                component: FormResearch1,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: '/form-research-2',
                name: 'FormResearch2',
                component: FormResearch2,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: '/form-research-3',
                name: 'FormResearch3',
                component: FormResearch3,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: '/form-research-4',
                name: 'FormResearch4',
                component: FormResearch4,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: '/read-news',
                name: 'ReadNews',
                component: ReadNews,
            },
            {
                path: '/all-news',
                name: 'AllNews',
                component: AllNews,
            },
            {
                path: '/all-result',
                name: 'AllResult',
                component: AllResult,
            },
            {
                path: '/history-research',
                name: 'HistoryResearch',
                component: HistoryResearch,
            },

        ],
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/forget-password',
        name: 'ForgetPassword',
        component: ForgetPassword,
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: ResetPassword,
    },
    {
        path: '/verify',
        name: 'Verify',
        component: Verify,
    },
];


const router = new VueRouter({
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    },
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requireAuth)) {
        if (!store.state.Auth.accessToken) {
            alert('ยังไม่ได้เข้าสู่ระบบ');
            next({
                name: 'Login'
            });
        } else {
            next();
        }
    } else {
        next();
    }
})



export default router

// export default new Router({
//     mode: 'history',
//     scrollBehavior (to, from, savedPosition) {
//         return { x: 0, y: 0 }
//     },
//     routes: [
//         {
//             path: '/',
//             name: 'Mainlayout',
//             component: Mainlayout,
//             children: [
//                 {
//                     path: '/',
//                     name: 'Homepage',
//                     component: Homepage,
//                 },
//                 {
//                     path: '/about-irbrta',
//                     name: 'AboutIRBRTA',
//                     component: AboutIRBRTA,
//                 },
//                 {
//                     path: '/flow-create',
//                     name: 'FlowCreate',
//                     component: FlowCreate,
//                 },
//                 {
//                     path: '/contact-us',
//                     name: 'ContactUs',
//                     component: ContactUs,
//                 },
//                 {
//                     path: '/download-form',
//                     name: 'DownloadForm',
//                     component: DownloadForm,
//                 },
//                 {
//                     path: '/sop-standard',
//                     name: 'SOPStandard',
//                     component: SOPStandard,
//                 },
//                 {
//                     path: '/register',
//                     name: 'Register',
//                     component: Register,
//                 },
//                 {
//                     path: '/manual-research',
//                     name: 'ManualResearch',
//                     component: ManualResearch,
//                 },
//                 {
//                     path: '/form-create-research',
//                     name: 'FormCreateResearch',
//                     component: FormCreateResearch,
//                 },
//                 {
//                     path: '/profile',
//                     name: 'Profile',
//                     component: Profile,
//                 },
//                 {
//                     path: '/form-rf-02',
//                     name: 'FormRF02',
//                     component: FormRF02,
//                 },
//                 {
//                     path: '/form-rf-06',
//                     name: 'FormRF06',
//                     component: FormRF06,
//                 },
//                 {
//                     path: '/form-rf-03',
//                     name: 'FormRF03',
//                     component: FormRF03,
//                 },
//                 {
//                     path: '/form-rf-12',
//                     name: 'FormRF12',
//                     component: FormRF12,
//                 },
//                 {
//                     path: '/form-rf-04-1',
//                     name: 'FormRF04_1',
//                     component: FormRF04_1,
//                 },
//                 {
//                     path: '/form-rf-04-2',
//                     name: 'FormRF04_2',
//                     component: FormRF04_2,
//                 },
//                 {
//                     path: '/form-rf-05',
//                     name: 'FormRF05',
//                     component: FormRF05,
//                 },
//                 {
//                     path: '/form-rf-07',
//                     name: 'FormRF07',
//                     component: FormRF07,
//                 },
//                 {
//                     path: '/test-exam',
//                     name: 'TestExam',
//                     component: TestExam,
//                 },
//                 {
//                     path: '/chapter-exam',
//                     name: 'ChapterExam',
//                     component: ChapterExam,
//                 },
//                 {
//                     path: '/form-research-1',
//                     name: 'FormResearch1',
//                     component: FormResearch1,
//                 },
//                 {
//                     path: '/form-research-2',
//                     name: 'FormResearch2',
//                     component: FormResearch2,
//                 },
//                 {
//                     path: '/form-research-3',
//                     name: 'FormResearch3',
//                     component: FormResearch3,
//                 },
//                 {
//                     path: '/form-research-4',
//                     name: 'FormResearch4',
//                     component: FormResearch4,
//                 },
//                 {
//                     path: '/read-news',
//                     name: 'ReadNews',
//                     component: ReadNews,
//                 },
//                 {
//                     path: '/all-news',
//                     name: 'AllNews',
//                     component: AllNews,
//                 },
//                 {
//                     path: '/all-result',
//                     name: 'AllResult',
//                     component: AllResult,
//                 },
//                 {
//                     path: '/history-research',
//                     name: 'HistoryResearch',
//                     component: HistoryResearch,
//                 },
//
//             ],
//         },
//         {
//             path: '/login',
//             name: 'Login',
//             component: Login,
//         },
//         {
//             path: '/verify',
//             name: 'Verify',
//             component: Verify,
//         },
//     ],
// })



