import axios from "axios";
import store from "../store";

import { domain } from "../services/Constants";

const http = new axios.create({
  baseURL: domain
});

const httpMethod = {
  get(url, options = {}) {
    if (!store.state.Auth.accessToken) {
      return Promise.reject("No access token.");
    }

    return http
    .get(url, {
      ...options,
      headers: {
        ...options.headers,
        Authorization: `Bearer ${store.state.Auth.accessToken}`
      }
    })
    .then(res => res.data);
  },
  post(url, data = null, options = {}) {
    if (!store.state.Auth.accessToken) {
      return Promise.reject("No access token.");
    }

    return http
    .post(url, data, {
      ...options,
      headers: {
        ...options.headers,
        Authorization: `Bearer ${store.state.Auth.accessToken}`
      }
    })
    .then(res => res.data);
  },
  put(url, data = null, options = {}) {
    if (!store.state.Auth.accessToken) {
      return Promise.reject("No access token.");
    }

    return http
    .put(url, data, {
      ...options,
      headers: {
        ...options.headers,
        Authorization: `Bearer ${store.state.Auth.accessToken}`
      }
    })
    .then(res => res.data);
  },
  delete(url, options = {}) {
    if (!store.state.Auth.accessToken) {
      return Promise.reject("No access token.");
    }

    return http
    .delete(url, {
      ...options,
      headers: {
        ...options.headers,
        Authorization: `Bearer ${store.state.Auth.accessToken}`
      }
    })
    .then(res => res.data);
  }
};

export default httpMethod;
