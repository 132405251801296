<template>
    <v-content>
        <v-layout>
            <v-flex pb-2 class="text-input font-weight-bold">
                <v-textarea
                        :id="id"
                        @input="onChange"
                        outlined
                        dense
                        solo
                        flat
                        :rows="rows"
                        :placeholder="placeholder"
                        :background-color="backgroundColor"
                        item-color="#3E7662"
                        color="#3E7662"
                        :value="model"
                        :label="label"
                        :readonly="readonly"
                        :disabled="disabled"
                        :hide-details="hideDetail"
                ></v-textarea>
            </v-flex>
        </v-layout>
    </v-content>
</template>

<script>
    export default {
        name: "TextareaForm",
        props: {
            placeholder: String,
            backgroundColor: {
                type: String,
                default: '#FBF6F3'
            },
            color: String,
            rows: Number,
            label: String,
            id: String,
            model: {
                required: false
            },
            readonly: Boolean,
            disabled: Boolean,
            hideDetail: Boolean,
        },
        methods: {
            onChange(val) {
                this.$emit("update-data", val);
            },
        }
    }
</script>

<style scoped>

</style>
