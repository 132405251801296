<template>
    <v-content class="content-readNews">
        <v-layout pt-12 style="padding-left: 100px;padding-right: 100px;">
            <v-flex>
                <!-- Nevigater -->
                <v-layout>
                    <v-flex shrink d-flex justify-start align-center class="nevigater-link" @click="$router.push('/all-news')">
                        <span>ข่าวสารทั้งหมด</span>
                    </v-flex>
                    <v-flex shrink d-flex justify-start align-center>
                        <v-icon color="#3E7662">arrow_right</v-icon>
                    </v-flex>
                    <v-flex shrink d-flex justify-start align-center style="color: #3E7662;font-weight: bold;">
                        <span>อ่านบทความ</span>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
        <v-layout mt-2 class="body" style="padding-left: 100px;padding-right: 100px;">
            <v-flex>
                <v-card tile style="border: solid 2px #3E7662" class="px-12 pt-6 pb-12 mb-12">
                    <!-- Name Topic -->
                    <v-layout>
                        <v-flex>
                            <h1>{{news.topic}}</h1>
                        </v-flex>
                    </v-layout>
                    <v-layout my-6 justify-center>
                        <v-flex style="text-align: center">
                            <!--                       <v-divider style="background-color: #544A44;border-width: 1px"></v-divider>-->
                            <v-img :src="news.mainPicture" style="width: 100%;max-width: 600px;height: auto;display: block;margin-left: auto;margin-right: auto;" alt=""/>
                        </v-flex>
                    </v-layout>
                    <!-- Content -->
                    <v-layout style="max-width: 600px;margin: auto" class="justify-center">
                        <v-flex class="content-v" v-html="news.description" />
                    </v-layout>
<!--                    <v-layout style="margin-bottom: 50px;margin-top: 50px">-->
<!--                        <v-flex>-->
<!--                            <v-divider style="background-color: #544A44;border-width: 1px"></v-divider>-->
<!--                        </v-flex>-->
<!--                    </v-layout>-->
                </v-card>
            </v-flex>
        </v-layout>
    </v-content>
</template>

<script>

    export default {
        name: 'ReadNews',
        components: {

        },
        data: () => ({
            news: [],

        }),
        created() {
            const news_id = this.$route.query.news_id;
            this.getNewsByID(news_id)
        },
        methods: {
            getNewsByID(id){
                this.loading = true;
                this.$store.dispatch('Common/getNewsByID',id).then(res => {
                    this.news = res
                })
                this.loading = false;
            },
        }
    }
</script>

<style lang="scss" scoped>
    .contect-readNews{
        background-color: #ffffff;
        padding-left: 150px;
        padding-right: 150px;
        padding-top: 100px;
        padding-bottom: 100px
    }

    .content-v {
        /deep/ img {
            width: 100%;
            max-width: 600px;
            height: auto;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }
</style>
