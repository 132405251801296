<template>
    <v-content style="background-color: #EFEAE7">
        <v-layout style="text-align: center">
            <v-flex pt-8>
                <span style="font-weight: bold;font-size: 36px; color: #3E7662;">คู่มือ</span>
            </v-flex>
        </v-layout>

        <v-layout>
            <v-flex style="background-color: #EFEAE7;padding: 2% 5% 2% 5%">
                <v-card>
                    <v-layout pa-8 justify-center style="background-color: #3E7662;">
                        <v-flex style="text-align: left;">
                            <span
                                style="font-weight: bold;font-size: 28px;color: white;">คู่มือการเขียนโครงร่างการวิจัย</span><br>
                            <span style="font-size: 14px;color: white;">(ใส่หมายเลขหน้าด้านบนส่วนกลางกระดาษ ทุกแผ่น)</span>
                        </v-flex>
                        <v-flex style="text-align: right;">
                            <span style="font-size: 14px;color: white;">Version……….Date…………………….</span><br>
                            <span style="font-size: 14px;color: white;">RF 08_1_2563</span>
                        </v-flex>
                    </v-layout>

                    <v-layout py-8 pl-8 pr-12>
                        <v-flex xs12>
                            <v-layout>
                                <v-flex style="text-align: left;">
                                    <span style="font-weight: bold;color: #3E7662">1. ชื่อโครงการ
                                        (ชื่อภาษาไทยและภาษาอังกฤษ)</span><br>
                                    <span>ประโยคหรือข้อความสั้นๆ บ่งถึงลักษณะหรือหัวข้อของงานวิจัยที่จะทำ</span>
                                </v-flex>
                            </v-layout>
                            <br>
                            <v-layout>
                                <v-flex style="text-align: left;">
                                    <span style="font-weight: bold;color: #3E7662">2. สาขาวิชาที่ทำการวิจัย</span><br>
                                    <span style="font-weight: bold;color: #3E7662">3. สาขาวิชาทางปรีคลินิกหรือทางคลินิก
                                        สาขาวิชาย่อยเช่น อายุรศาสตร์ฯลฯ</span><br>
                                    <span>ผู้วิจัยผู้วิจัยร่วมและที่ปรึกษา</span><br>
                                    <span>ระบุ ชื่อ ตำแหน่ง สถานที่ทำงาน สถานที่ที่จะติดต่อ เบอร์โทรศัพท์ และอีเมลล์</span>
                                </v-flex>
                            </v-layout>
                            <br>
                            <v-layout>
                                <v-flex style="text-align: left;">
                                    <span style="font-weight: bold;color: #3E7662">4. ปีงบประมาณที่ทำการวิจัย</span><br>
                                    <span style="font-weight: bold;color: #3E7662">5. สถานที่ทำการวิจัย</span><br>
                                    <span style="font-weight: bold;color: #3E7662">6.
                                        ความสำคัญของปัญหาที่จะทำการวิจัย</span><br>
                                    <span>สรุปขนาดและลักษณะของปัญหาที่ทำให้เกิดความสนใจทำการวิจัยเรื่องนั้น
                                        ข้อมูลพื้นฐานกับสถานการณ์ หรือสภาพแวดล้อมที่เกี่ยวข้องกับการวิจัย
                                        เพื่อให้ผู้พิจารณาสามารถเข้าใจสภาวะทำให้เกิดปัญหานั้นได้โดยมีตัวเลขและหลักฐานอ้างอิงที่มีความน่าเชื่อถือ</span>
                                </v-flex>
                            </v-layout>
                            <br>
                            <v-layout>
                                <v-flex style="text-align: left;">
                                    <span style="font-weight: bold;color: #3E7662">7. วัตถุประสงค์ของการวิจัย </span><br>
                                    <span>แจ้งวัตถุประสงค์ของการวิจัยเพื่อให้พิจารณาเข้าใจว่าคำถามของการวิจัยคืออะไรและการวิจัยจะตอบคำถามได้อย่างไร
                                        ถ้ามีวัตถุประสงค์ของการวิจัย หลายข้อ ควรระบุว่าข้อใดเป็นวัตถุประสงค์หลัก (1 ข้อ)
                                        และข้อใดเป็นวัตถุประสงค์รอง</span>
                                </v-flex>
                            </v-layout>
                            <br>
                            <v-layout>
                                <v-flex style="text-align: left;">
                                    <span style="font-weight: bold;color: #3E7662">8. ประโยชน์ที่จะได้จากการวิจัย</span><br>
                                    <span>ชี้แจงว่าผลการวิจัยจะสามารถนำไปใช้ในการแก้ปัญหาหรือมีส่วนในการแก้ปัญหาอย่างไร</span>
                                </v-flex>
                            </v-layout>
                            <br>
                            <v-layout>
                                <v-flex style="text-align: left;">
                                    <span style="font-weight: bold;color: #3E7662">9.
                                        การรวบรวมรายงานที่เกี่ยวข้องกับการวิจัยที่จะกระทำ</span><br>
                                    <span>การรวบรวมรายงานที่เกี่ยวข้องกับการวิจัยให้ครบถ้วน
                                        และนำมาสรุปให้ได้ใจความเพื่อดูว่างานวิจัยที่จะทำมีผู้ทำมามากน้อยเพียงใดงานวิจัยที่กระทำจะเพิ่มความรู้เดิมได้อย่างไร
                                        โดยจะต้องสรุปจากรายงานของการวิจัยในเรื่องนั้นที่ผู้รายงานไว้ก่อนแล้วพร้อมเอกสารอ้างอิง</span>
                                </v-flex>
                            </v-layout>
                            <br>
                            <v-layout>
                                <v-flex style="text-align: left;">
                                    <span style="font-weight: bold;color: #3E7662">10. แบบแผนการวิจัย</span><br>
                                    <span>แบบแผนการวิจัย ควรเลือกให้เหมาะสมกับคำถามการวิจัย
                                        มีความเชื่อถือได้และอยู่ในขีดความสามารถที่ผู้วิจัยจะกระทำได้
                                        เช่น การวิจัยแบบพรรณนา (Descriptive) หรือ Analytic observational
                                        หรือ Randomized Controlled trial</span>
                                </v-flex>
                            </v-layout>
                            <br>
                            <v-layout>
                                <v-flex style="text-align: left;">
                                    <span style="font-weight: bold;color: #3E7662">11.
                                        ลักษณะตัวอย่างหรือประชากรที่ทำการศึกษา</span><br>
                                    <span class="pl-4"><b>ประชากรเป้าหมาย</b></span><br>
                                    <span>รายละเอียดเกี่ยวกับประชากรเป้าหมายที่จะทำการศึกษาว่าเป็นใคร เช่น
                                        เด็กนักเรียนในกรุงเทพฯ
                                        ซึ่งควรเป็นประชากรกลุ่มเดียวกับที่นำผลการวิจัยไปใช้</span><br>
                                    <span class="pl-4"><b>การเลือกตัวอย่าง</b></span><br>
                                    <span>กำหนดวิธีและหลักเกณฑ์ในการเลือกตัวอย่างจากประชากรเป้าหมายให้ชัดเจน</span><br>
                                    <span class="pl-4"><b>ขนาดตัวอย่าง</b></span><br>
                                    <span>ข้อมูลเบื้องต้นจากการศึกษาของผู้อื่น เกี่ยวกับอุบัติการณ์ (Incidence) หรือความชุก
                                        (Prevalence)
                                        หรือความแตกต่างระหว่างกลุ่ม (ใส่หมายเลขอ้างอิงด้วย)
                                        สามารถนำมาคำนวณขนาดตัวอย่างที่เหมาะสมในการวิจัยได้
                                        การปรึกษากับนักสถิติก่อนที่จะทำการวิจัยจะสามารถแก้
                                        ปัญหาเกี่ยวกับขนาดตัวอย่างที่มากหรือน้อยเกินไปล่วงหน้า
                                        เพื่อให้งานวิจัยสามารถกระทำได้และมีคุณภาพดี</span><br>
                                </v-flex>
                            </v-layout>
                            <br>
                            <v-layout>
                                <v-flex style="text-align: left;">
                                    <span style="font-weight: bold;color: #3E7662">12. วิธีดำเนินการวิจัย </span><br>
                                    <span class="pl-4"><b> 12.1 การแบ่งกลุ่มเพื่อทำการศึกษาทำอย่างไร</b>
                                        การดำเนินการวิจัยระบุว่าจะทำอย่างไร ใครทำอะไร ทำกับใคร ที่ไหนบ้าง </span><br>
                                    <span class="pl-4"><b>12.2 การวัดผลทางการวิจัย</b>
                                        ระบุว่า ต้องการวัดผลอะไรบ้าง ใช้เครื่องมืออะไรในการวัด มีความเชื่อถือได้แค่ไหน
                                        มีการทดสอบความเชื่อถือได้ของเครื่องมืออย่างไร เช่น แบบสอบถาม ควรระบุวิธีการทดสอบ
                                        และการใช้แบบสอบถามไว้ด้วย </span><br>
                                    <span class="pl-4"><b> 12.3 คำจำกัดความ (Operational definition)</b>
                                        ระบุคำจำกัดความที่สำคัญ โดยเฉพาะอย่างยิ่ง Outcome ที่จะวัด ให้ชัดเจน เช่น
                                        การวินิจฉัยโรคแต่ละโรค การหายของโรค หมายถึง อะไร เป็นต้น</span><br>
                                </v-flex>
                            </v-layout>
                            <br>
                            <v-layout>
                                <v-flex style="text-align: left;">
                                    <span style="font-weight: bold;color: #3E7662">13. ผลการวิจัยที่ต้องการศึกษา</span><br>
                                    <span>ระบุว่า Primary outcome และ secondary outcome คืออะไร
                                        (ควรสอดคล้องกับวัตถุประสงค์)</span>
                                </v-flex>
                            </v-layout>
                            <br>
                            <v-layout>
                                <v-flex style="text-align: left;">
                                    <span style="font-weight: bold;color: #3E7662">14. การเก็บรวบรวมข้อมูล</span><br>
                                    <span>วิธีเก็บและบันทึกข้อมูลจะกระทำอย่างไร จะต้องใช้อุปกรณ์ช่วยเหลืออะไรบ้าง
                                        นำเสนอแบบฟอร์มเก็บข้อมูล(ถ้ามี) ถ้าเป็นแบบเก็บข้อมูลจากเวชระเบียน
                                        ต้องมีจดหมายขออนุญาต ผู้อำนวยการโรงพยาบาล เพื่อสืบค้นข้อมูลเวชระเบียนด้วย</span>
                                </v-flex>
                            </v-layout>
                            <br>
                            <v-layout>
                                <v-flex style="text-align: left;">
                                    <span style="font-weight: bold;color: #3E7662">15. การวิเคราะห์</span><br>
                                    <span>ระบุวิธีทางสถิติในการวิเคราะห์ข้อมูล
                                        สำหรับแต่ละวัตถุประสงค์และผลที่ต้องการวัด</span>
                                </v-flex>
                            </v-layout>
                            <br>
                            <v-layout>
                                <v-flex style="text-align: left;">
                                    <span style="font-weight: bold;color: #3E7662">16. ปัญหาด้านจริยธรรม</span><br>
                                    <span>ระบุปัญหาจริยธรรมที่อาจเกิดขึ้น ได้แก่ </span><br>
                                    <span>- การขอความยินยอมจากอาสาสมัครเป็นลายลักษณ์อักษร
                                        เพื่อให้อาสาสมัครตัดสินใจเข้าร่วมหรือไม่เข้าร่วมอย่างอิสระ</span><br>
                                    <span>-
                                        กระบวนการขอความยินยอมที่ไม่มีการบังคับหรือเสนอประโยชน์ที่ไม่เหมาะสมผู้ขอความยินยอมควรเป็นบุคคลที่มีความรู้ความเข้าใจในโครงการวิจัย
                                        สามารถตอบคำถามอาสาสมัครได้เป็นอย่างดี และไม่ควรเป็นแพทย์ผู้รักษา
                                        เพื่อให้อาสาสมัครสามารถตัดสินใจได้อย่างอิสระในการเข้าร่วมหรือปฏิเสธการเข้าร่วม
                                        ในโครงการวิจัย</span><br>
                                    <span>- ความเสี่ยงที่อาจเกิดขึ้นกับอาสาสมัครการป้องกัน
                                        และการวางแผนแก้ไขปัญหาที่อาจเกิดขึ้น</span><br>
                                    <span>- การรักษาความลับของข้อมูลการวิจัย</span><br>
                                </v-flex>
                            </v-layout>
                            <br>
                            <v-layout>
                                <v-flex style="text-align: left;">
                                    <span style="font-weight: bold;color: #3E7662">17.
                                        เพิ่มเติมข้อพิจารณาในเรื่องชุมชน</span>
                                    <span>(ในกรณีที่ทำการวิจัยในชุมชน)</span><br>
                                    <span>- ผลกระทบของการศึกษาวิจัยต่อชุมชนที่ทำการคัดเลือกอาสาสมัคร</span><br>
                                    <span>-
                                        ขั้นตอนในการปรึกษาหารือกับชุมชนที่เกี่ยวข้องในช่วงของการออกแบบการศึกษาวิจัย</span><br>
                                    <span>- อิทธิพลของชุมชนต่อการให้ความยินยอมของอาสาสมัครแต่ละบุคคล</span><br>
                                    <span>- การปรึกษาหารือชุมชนที่จะกระทำในระหว่างการศึกษาวิจัย</span><br>
                                    <span>- สิ่งที่การศึกษาวิจัยจะช่วยในการสร้างศักยภาพ เช่น การส่งเสริมบริการสุขภาพ
                                        การวิจัย และความสามารถในการตอบสนองความต้องการด้านสุขภาพของท้องที่</span><br>
                                    <span>-
                                        รายละเอียดเกี่ยวกับการจัดให้มีผลิตภัณฑ์ที่ประสบความสำเร็จในการศึกษาวิจัยในราคาที่สามารถจะซื้อหาได้
                                        แก่ชุมชนที่เกี่ยวข้องภายหลังเสร็จสิ้นการศึกษาวิจัย</span><br>
                                    <span>- วิธีการที่จะให้อาสาสมัครวิจัย
                                        หรือชุมชนที่เกี่ยวข้องได้รับผลของการศึกษาวิจัย</span><br>
                                </v-flex>
                            </v-layout>
                            <br>
                            <v-layout>
                                <v-flex style="text-align: left;">
                                    <span style="font-weight: bold;color: #3E7662">18. ระยะเวลาในการวิจัย
                                        และการบริหารจัดการ</span><br>
                                    <span>การจัดการเวลาในการวิจัย ควรระบุเวลาการทำงานแต่ละขั้นตอน
                                        ว่าจะเริ่มต้นและเสร็จสิ้นเมื่อใด </span><br>
                                    <span>- การเตรียมข้อมูลเบื้องต้น</span><br>
                                    <span>- ระยะเวลาดำเนินการวิจัยระยะเวลาการรวบรวมและวิเคราะห์ข้อมูล</span><br>
                                    <span>- ระยะเวลาการนำเสนอผลการวิจัย และการเขียนรายงาน</span><br>
                                    <span>บุคลากรที่จะใช้ในการวิจัยควรระบุว่ามีจำนวนเท่าใด ใครทำหน้าที่อะไร</span><br>
                                </v-flex>
                            </v-layout>
                            <br>
                            <v-layout>
                                <v-flex style="text-align: left;">
                                    <span style="font-weight: bold;color: #3E7662">19. งบประมาณในการวิจัย</span><br>
                                    <span>มีแหล่งที่มาของงบประมาณและจำแนกรายละเอียดของงบประมาณ เช่น</span><br>
                                    <span>- ค่าใช้จ่ายด้านอุปกรณ์การวิจัย</span><br>
                                    <span>- ค่าใช้จ่ายด้านบุคลากร</span><br>
                                    <span>- ค่าเดินทาง</span><br>
                                    <span>- ค่าวิเคราะห์ข้อมูล</span><br>
                                    <span>- ค่าครุภัณฑ์</span><br>
                                    <span>- ค่าตีพิมพ์ผลการวิจัย</span><br>
                                    <span>- อื่นๆ</span><br>
                                    <span><u>ควรระบุด้วยว่าใครจะเป็นผู้บริหารงบประมาณ
                                            สำนักงานบริหารงบประมาณอยู่ที่ไหน</u></span><br>
                                </v-flex>
                            </v-layout>
                            <br>
                            <v-layout>
                                <v-flex style="text-align: left;">
                                    <span style="font-weight: bold;color: #3E7662">20. เอกสารอ้างอิง</span>
                                    <span> ควรเขียนตามมาตรฐานการเขียนเอกสารอ้างอิง แบบ Vancouver</span><br>
                                    <span style="font-weight: bold;color: #3E7662">21. ภาคผนวก</span><br>
                                    <span>เอกสารที่เกี่ยวข้อง ซึ่งคิดว่าอาจจะเป็นประโยชน์ในการพิจารณาโครงการวิจัย</span><br>
                                    <span>แบบฟอร์มเก็บข้อมูล</span><br>
                                    <span>ประวัติผู้วิจัยและผู้ร่วมวิจัย</span><br>
                                    <span>อื่นๆ ได้แก่ สมุดบันทึก ไดอารี่ โฆษณา แผ่นพับ บัตรประจำตัวอาสาสมัคร
                                        เป็นต้น</span><br>
                                </v-flex>
                            </v-layout>
                            <br>
                            <v-layout pt-12>
                                <v-flex px-6 style="text-align: left;">
                                    <span>สำนักงานคณะอนุกรรมการพิจารณาโครงการวิจัย พบ.</span>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                </v-card>
            </v-flex>
        </v-layout>

        <v-layout>
            <v-flex mb-12 style="padding: 0 5% 2% 5%; text-align: right;">
                <v-layout>
                    <v-flex>
                        <v-btn style="color: #3E7662" class="mx-4" depressed color="#ffffff"
                            @click="$router.push('/')">ย้อนกลับ</v-btn>
                        <v-btn depressed dark color="#3E7662"
                            @click="$router.push({ name: 'FormResearch1', query: { id: 'create' } })">
                            <v-icon class="mr-3">add</v-icon>
                            <span>สร้างงานวิจัย</span>
                        </v-btn>
                    </v-flex>
                    {{ datauser.certificate_expire_at }}
                </v-layout>
            </v-flex>
        </v-layout>
    </v-content>
</template>

<script>
import { mapState } from "vuex";
import Swal from "sweetalert2";

export default {
    name: "ManualResearch",
    data: () => ({
        datauser: "",
        datenow: ""
    }),
    created() {
        this.clearResearchID();
        if (this.remainingDate === 0) {
            this.$router.push('/profile')
        }
        this.datauser = new Date(this.user.certificate_expire_at);
        this.datenow = new Date();
        if (this.datenow > this.datauser) {
            Swal.fire({
                title: "ใบ Certificate หมดอายุ ขอให้ทำการอบรมหรืออัปเดท GCP ใหม่",
                icon: "warning",
                confirmButtonText: "ตกลง",
                confirmButtonColor: "#3E7662",
                cancelButtonColor: "#d33",
            })
        }

    },
    computed: {
        ...mapState({
            user: (state) => state.Auth.user,
            researchID: state => state.FormResearch.research_id,
            remainingDate: state => state.Common.remainingDate
        })
    },
    methods: {
        clearResearchID() {
            this.$store.dispatch("FormResearch/clearResearchID")
        },
        async getUser() {
            this.datauser = await this.$store.dispatch('Auth/getUser')
        }
    }
}
</script>

<style scoped></style>
